import $ from "@isf/core-object-util/src/object-util";
import {storeTemplToPath} from "../util";
import appStore, {cacheStore, DataStore, routerStore} from "@isf/core-app-store";
import {resolveStore} from "../ui-schema-stores";
import {fileApi} from "../api";

export const createStores=(schema, context = {}, cacheStores, storesMappings)=> {
    const stores = $.get(schema, ['func', 'stores', 'data']);
    stores && Object.entries(stores).forEach(
        ([pathTempl, store]) => {
            const path = storeTemplToPath(pathTempl);
            const outerMapping = getOuterMapping(pathTempl,storesMappings);
            // const storeExists = appStore.storeExists('data.' + path);
            const cashedState = cacheStore.getCachedState('/'+(routerStore.get('path').join('/')), pathTempl, 'data');
            const storeExists = appStore.storeExists('data.' + pathTempl);
            if (!storeExists) {
                if (store.type) {
                    const StoreType = resolveStore(store.type);
                    const initObj = { path: pathTempl, state: cashedState || store.state || {}, fileApi };
                    const s = new StoreType(initObj);
                    if(cacheStores)
                        cacheStore.addStore('/'+(routerStore.get('path').join('/')), pathTempl, s.state, 'data')
                } else {
                    const cashedState = cacheStore.getCachedState('/'+(routerStore.get('path').join('/')), pathTempl, 'data');
                    let state = cashedState || store.state || store;
                    const initObj = {
                        path: context.refId ? context.refId + "." + pathTempl : pathTempl,
                        state/* : store *//* || {}*/, /*observable: true,*/
                        observableAsMap: true/* false */,
                        fileApi,
                        uploadFile,
                        storeById: pathTempl.indexOf("conf.module.v1.{id}.service.{id}.configuration.{id}") === -1
                    };
                    if(outerMapping){
                        $.set(initObj,'outerMapping', outerMapping);
                        $.set(initObj,'outerStore', getOuterStore(pathTempl, context, storesMappings))
                    }
                    const newStore = new DataStore(initObj);
                    if(cacheStores)
                        cacheStore.addStore('/'+(routerStore.get('path').join('/')), pathTempl, newStore.state, 'data')
                }

            }

        }
    );
};

const uploadFile = () => {};

const getOuterMapping = (pathTempl, outerStoresMappings =[]) => {
    for(let i=0; i<outerStoresMappings.length; i++){
        const outerConfig = outerStoresMappings[i];
        if($.get(outerConfig,'refStore') === pathTempl){
            return outerConfig;
        }
    }
};

const getOuterStore = (name, context, outerStoresMappings=[])=>{
    for(let i=0;i<outerStoresMappings.length;i++){
        const mapping = outerStoresMappings[i];
        if($.get(mapping,'refStore') === name){
            let storeName = $.get(mapping,'store');
            storeName = context.parentRefId
              ? context.parentRefId + "." + storeName
              : storeName;

            const outerStore = appStore.getDataStore(storeName);
            if(outerStore) {
                return outerStore
            }
        }
    }
};