import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import { action, observable } from "mobx";
import { injectIntl, FormattedMessage } from "@isf/localization";
import {
  ModalContainer,
  ModalBody,
  ModalFooter,
  ModalOKButton,
  ModalCancelButton,
} from "../../../modal";
import { Message, Button } from "../../../form";
import { LoadSpinner } from "../../../load-spinner/load-spinner-view";
import { ToggleButton, ToggleStore } from "../../../toggle";
import { DataStore } from "@isf/core-app-store";
import { resolveStore } from "@isf/react-util";
//import {getAllStoreSchema} from "../../../sdk/design-components/src/data-store";
//import { copyObj } from "./components/open-api/open-api-utils";
import { ModalHeader, BodyContentArray, SelectService } from "@isf/common-select-service";
import { ServiceSelectionSettingStore } from "./service-selection-setting-store";

@injectIntl
@observer
class ServiceSelectionSetting extends Component {
  constructor(props) {
    super(props);
    this.path = props.path;

    this.configStore = new ServiceSelectionSettingStore({
      setConf: (id, mapping) => this.setConfig(id, mapping, props.uiComponent, props.onLoad),
      selectedService: {
        name: null,
        context: null,
        contextPath: null,
        id: null,
        apiSchema: null
      }
    });

    this.dataStore = new DataStore({
      path: this.path + ".dataStore",
      state: {
        currentStep: 0
      },
      observableAsMap: true
    });

    this.toggleStore = props.toggleStore || resolveStore({id: this.path, type: ToggleStore});
    this.toggle = this.toggle.bind(this);
    this.openToggle = this.openToggle.bind(this);
  }

  @observable isLoading = false;

  @action
  setLoading() {
    this.isLoading = !this.isLoading;
  }

  @action
  clearStore() {
    this.dataStore.setState({
      currentStep: 0
    });
  }

  setConfig = (apiRequestBodyFilterSchema) => {
    const { handler, accessor, filterSettingStore } = this.props;
    const { dataStore } = this.configStore;
    const selectService = dataStore.get("select.service");

    let searchOperation;
    if (selectService.data.api && selectService.data.api.schema && selectService.data.api.schema.paths) searchOperation = selectService.data.api.schema.paths[`${selectService.contextPath}/search`];

    if (searchOperation) {
      filterSettingStore.set("error", null);
      filterSettingStore.set("service.id", selectService.id);
      filterSettingStore.set("service.contextPath", selectService.contextPath);
      filterSettingStore.set("service.description", selectService.data.description);
      if (apiRequestBodyFilterSchema) filterSettingStore.set("service.apiRequestBodyFilterSchema", apiRequestBodyFilterSchema.properties);

      const newFilterSetting = {
        service: {
          id: selectService.id,
          contextPath: selectService.contextPath
        }
      };
      handler.set(accessor, newFilterSetting);
    } else {
      filterSettingStore.set("error", "ui.filterSetting.service.noOperation");
      filterSettingStore.set("service.id", selectService.id);
      filterSettingStore.set("service.contextPath", selectService.contextPath);
      filterSettingStore.set("service.description", selectService.data.description);
      filterSettingStore.set("service.apiRequestBodyFilterSchema", null);
      handler.set(accessor, null);
    }
  };

  @action
  nextStep = async (selectFunc) => {
    this.setLoading();
    await selectFunc(
      () => this.setLoading()
    );
  };

  // @action
  // prevStep = () => {
  //   const currentStep = this.dataStore.get("currentStep");
  //   if (currentStep > 0) {
  //     this.dataStore.set("currentStep", currentStep - 1);
  //   }
  // };

  async openToggle() {
    //const {requestSchema} = this.props;
    this.configStore.clearStore();
    this.configStore.clearServicesStores();
    this.clearStore();
    this.toggleStore.set("active", true);
    this.setLoading();

    await this.configStore.servicesResponseStore.load()
      // .then(() => getAllStoreSchema(this.props.designContext, true))
      // .then((schema) => {
      //   const responseSchema = {
      //     "type": "object",
      //     "properties": copyObj($.get(schema, "properties"))
      //   };
      //
      //   this.configStore.dataStore.set("selectedService.requestSchema", schema);
      //   this.configStore.dataStore.set("selectedService.responseSchema", responseSchema);
      //   if (requestSchema) {
      //     return typeof requestSchema === "function" ? requestSchema() : requestSchema;
      //   }
      // })
      // .then((schema) => {
      //   if (requestSchema) {
      //     const {dataStore} = this.configStore;
      //     dataStore.set("selectedService.requestSchema", schema);
      //   }
      // })
      .then()
      .catch(e => console.error("Error", e))
      .finally(() => this.setLoading());
    return false;
  }

  toggle = (props) => {
    if (this.isLoading) return false;

    const buttonType = props["data-button-type"];
    if (buttonType !== "ok") {
      this.toggleStore.set("active", false);
      this.dataStore.set("currentStep", 0);
      return true;
    }

    const currentStep = this.dataStore.get("currentStep");
    const step = steps[currentStep];
    const selectFunc = this.configStore[step.func];
    this.nextStep(selectFunc)
      .finally(
        () => {
          if (this.dataStore.get("currentStep") < (steps.length - 1)) {
            this.dataStore.set("currentStep", currentStep + 1);
            return false;
          } else {
            this.toggleStore.set("active", false);
            return true;
          }
        }
      );
  };

  handleOpen(e) {
    e.stopPropagation();
  };

  render() {
    const { opener: propsOpener, openerContent, modalHeader, intl, modalDisabled } = this.props;

    const currentStep = this.dataStore.get("currentStep");
    const step = steps[currentStep];
    const disabledBack = this.isLoading || !currentStep;
    let disabledSelect = step.disabledFunc ? this.configStore[step.disabledFunc] : false;
    const isShowBack = currentStep !== 0;
    disabledSelect = this.isLoading || disabledSelect;

    return (
      <Fragment>
        <ModalContainer size={"xl"}
                        opener={
                          <ToggleButton {...propsOpener}
                                        onClick={this.handleOpen}
                                        style={modalDisabled ? {pointerEvents: "none"} : {}}>
                            {openerContent}
                          </ToggleButton>
                        }
                        toggleStore={this.toggleStore}
                        openToggle={this.openToggle}
                        toggle={this.toggle}>
          <ModalHeader configStore={this.configStore}
                       intl={intl}
                       title={<Message message={modalHeader && modalHeader.message} />}>
          </ModalHeader>
          <ModalBody onClick={this.handleOpen}>
            <LoadSpinner isLoading={this.isLoading} />
            <BodyContentArray
              intl={intl}
              allSteps={steps}
              stepIndex={currentStep}
              useHandler={this.useHandler}
              designContext={null}//{designContext}
              configStore={this.configStore}
            />
          </ModalBody>
          <ModalFooter>
            {isShowBack
              ? <Button disabled={disabledBack} onClick={this.prevStep}>
                {<FormattedMessage id={"ui.modal.backToService"}/>}
              </Button>
              : null
            }
            <ModalOKButton disabled={disabledSelect}>
              {currentStep === steps.length - 1
                ? <FormattedMessage id={"ui.modal.apply"}/>
                : <FormattedMessage id={"ui.modal.next"}/>
              }
            </ModalOKButton>
            <ModalCancelButton color="primary" outline>
              <FormattedMessage id={"ui.modal.cancel"}/>
            </ModalCancelButton>
          </ModalFooter>
        </ModalContainer>
      </Fragment>
    )
  }
}

export { ServiceSelectionSetting };

export const steps = [
  {header: "Services", component: SelectService, func: "selectService", disabledFunc: "serviceDisabled"}
];