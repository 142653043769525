import $ from "@isf/core-object-util";
import {routerStore} from "@isf/core-app-store";
import {hostnameInfo} from "@isf/common";
import {isID} from "./util";

// const getScope = (uiSchemaCursor) => {
//    const tag = uiSchemaCursor.tag;
//    if (tag === 'Menu' || tag === 'MenuItem') {
//       return 'new'; //'crud';
//    }
//    if (window.location.href.endsWith('/new')) {
//       return 'new';
//    }
//    return routerStore.get('query.scope') || 'saved'//'crud';
// }

const isPageEndsWithId = (contextPath) => {
  const pathArr = $.strToArray(contextPath, "/");
  const lastItem = pathArr[pathArr.length - 1];
  return isID(lastItem);
};

export const getScope = (contextPath) => {
  const isCurrentPage = typeof contextPath !== "string";
  if (isCurrentPage) {
    contextPath = routerStore.pathname;
  }

  if (contextPath.endsWith('/new')) {
    return 'new';
  }

  if (isPageEndsWithId(contextPath)) {
      return isCurrentPage
        ? (routerStore.get('query.scope') || "saved")
        : "saved";
  } else {
      return "global";
  }
};

// export const hasPermission = (permission, permissionType, userRoles) => {
//   // console.log('check permission type 0', permissionType, permission);
//   if (!permission[permissionType]) {
//     return false;
//   } 
//   const permittedRoles = permission[permissionType].roles.filter(pr => userRoles.filter(ur => ur.id === pr.id).length > 0);
//   if (permittedRoles.length > 0) {
//     return true;
//   }
// };

const PERMISSION_TYPES = ['read', 'write', 'execute'];

export const hasPermissions = (permissions) => {
   for (let i = 0; i < PERMISSION_TYPES.length; i++) {
      for (let j = 0; j < permissions.length; j++) {
         if (permissions[j][PERMISSION_TYPES[i]]) {
            return true;
         }
      }
   }
   return false;
};

const isNew = () => {
   if (window.location.href.endsWith('/new')) {
      return true;
   }
   return routerStore.get('query.scope') === undefined;
}

// export const userHasPermissions = (permissions, userRoles) => {
//   const permission = permissions[permissionType];
//   if (!permission) {
//     return false;
//   }
//   for(let i=0; permission; i++) {
//     const permittedRoles = permission.roles.filter(
//       pr => userRoles.filter(
//         ur => ur.id === pr.id ).length > 0
//     );
//     if (permittedRoles.length > 0) {
//       return true;
//     }
//   }
//   return false;
// }

// export const userHasPermission = (permissions, permissionType, userRoles, scope = 'crud', uiSchemaCursor) => {
//    // console.log('permissions', permissions, 'userRoles', userRoles);
//    // const permission = permissions[permissionType];
//    // if (!permission) {
//    //   return false;
//    // }
//    for (let j = 0; j < permissions.length; j++) {
//       const permission = permissions[j];
//       // console.log('permission', permission);
//       if (permission.scope === 'activity') {
//          if (permission.activityId !== scope) {
//             continue;
//          }
//       } else if (permission.scope !== scope) {
//          continue;
//       }
//       const permissionTypes = Object.keys(permission);
//       for (let i = 0; i < permissionTypes.length; i++) {
//          const perm = permission[permissionType];
//          if (!perm) {
//             continue;
//          }
//          const permittedRoles = permission[permissionType].roles.filter(
//             pr => userRoles.filter(
//                ur => ur.id === pr.id).length > 0
//          );
//          if (permittedRoles.length > 0) {
//             return true;
//          }
//       }
//    }
//    return false;
// };

export const userHasPermission = (permissions, permissionType, userStore, scope = 'crud', uiSchemaCursor, resourceId) => {//new uncomment for use Permissions
  // return true; 
  // console.log('resourceId', resourceId);
  const userPermissions =  userStore.getResourcePermissions(resourceId); //userStore.permissions;
  if (!permissions[permissionType] || !userPermissions) {
    return false;
  }
  const perms = permissions[permissionType];
  for(let j=0; j<perms.length; j++) {
    if( userPermissions.indexOf(perms[j].id) !== -1) {
      return true;
    }
  }
  //  for (let j = 0; j < permissions.length; j++) {
     
  //     // const permissionTypes = Object.keys(permission);
  //     // for (let i = 0; i < permissionTypes.length; i++) {
  //     //    const perm = permission[permissionType];
  //     //    console.log('perm', perm);
  //     //    if (!perm) {
  //     //       continue;
  //     //    }
  //     //    for(let i=0;i<perm.length;i++) {
  //     //       if (userPermissions.indexOf($.get(perm[i], 'id')) !== -1) {
  //     //          return true;
  //     //       }
  //     //    }
  //     // }
  //  }
   return false;
  //  return true;
};

// export const userHasReadAccess = (permissions, userRoles, scope = 'crud', uiSchemaCursor) => {
//    let permitted = false;
//    for (let i = 0; i < PERMISSION_TYPES.length; i++) {
//       console.log(PERMISSION_TYPES[i])
//       permitted = userHasPermission(permissions, PERMISSION_TYPES[i], userRoles, scope, uiSchemaCursor);
//       if (permitted) {
//          if (uiSchemaCursor.tag === 'Field') {
//             if (!userHasPermission(permissions, 'write', userRoles, scope, uiSchemaCursor)) {
//                $.set(uiSchemaCursor, 'props.input.props.readOnly', true);
//             }
//          }
//          // permitted = true;
//          break;
//       }
//    }
//    return permitted;
// }

export const userHasReadAccess = (permissions, userStore, scope = 'crud', uiSchemaCursor, resourceId) => {
  let permitted = false;
  for (let i = 0; i < PERMISSION_TYPES.length; i++) {
    permitted = userHasPermission(permissions, PERMISSION_TYPES[i], userStore, scope, uiSchemaCursor, resourceId);
    if (permitted) {
      if (uiSchemaCursor.tag === 'Field' || uiSchemaCursor.tag === 'WeekdaysPicker') {
        if (PERMISSION_TYPES[i] !== 'write' && !userHasPermission(permissions, 'write', userStore, scope, uiSchemaCursor, resourceId)) {
          $.set(uiSchemaCursor, 'props.input.props.readOnly', true);
          $.set(uiSchemaCursor, 'props.input.props.disabled', true);
        }
      }
      // permitted = true;
      break;
    }
  }
  return permitted;
};

export const hasReadPermission = (uiSchemaCursor, userStore, scope, resourceId) => {
   if (!userStore || hostnameInfo.isAdmin)
      return true; //for sandbox
  //  const userPermissions = userStore.getResourcePermissions(resourceId);
    let security = $.get(uiSchemaCursor, 'security');
   // let account
   // console.log('isNew', isNew(),routerStore.get('query.scope') );
   // const scope = getScope(uiSchemaCursor);
  const noAccessGlobal = uiSchemaCursor.noAccess && uiSchemaCursor.noAccess["global"];
  const securityExist = uiSchemaCursor.security && uiSchemaCursor.security["global"];
  if (!noAccessGlobal && !securityExist && scope === "global") {
    scope = "saved";
  }
  //  if ($.get(uiSchemaCursor, 'props.id') === '10d25d50-cfc9-4143-ad04-9026b5262629') {
  //   console.log('FIELD SECURITY', scope, security);
  // }
  //  console.log('permissions', scope, resourceId, userPermissions);
   if (uiSchemaCursor.noAccess && uiSchemaCursor.noAccess[scope]) {
      return false;
   }
   if (!security) {
      return true;
   }
   let permissions =[];// security[scope];
  //  console.log('PERMISSIONS', permissions);
  //  if (!permissions) {
  //    return true;
  //  }
  //  if (!Array.isArray(security)) { // TODO: to TRASH
  //     const po = [];
  //     Object.keys(security).forEach(key => {
  //        po.push(security[key]);
  //     });
  //     permissions = po;
  //  } else {
  //    console.log('PURE PERMISSIONS');
  //    permissions = security[scope];
  //  }
// console.log('scope', scope, uiSchemaCursor.tag);
   permissions = security[scope];
   if (!permissions) return true;

// console.log('secuirty[scope]', permissions);

  
   let permitted = false;
   if (scope === 'crud' || scope === 'new') {
      if (userHasReadAccess(permissions, userStore, scope, uiSchemaCursor, resourceId)) {
         permitted = true;
      }
      // if (userHasPermission(permissions, 'read', userRoles, 'crud', uiSchemaCursor)) {
      //   permitted = true;
      // }
      // if (userHasReadAccess(permissions, userRoles, 'new', uiSchemaCursor)) {
      //   permitted = true;
      // } else if (!permitted && userHasReadAccess(permissions, userRoles, 'crud', uiSchemaCursor)) {
      //   permitted = true;
      // }


   } else {
    // if ($.get(uiSchemaCursor, 'props.id') === '10d25d50-cfc9-4143-ad04-9026b5262629') {
    //   console.log('FIELD SECURITY 2', scope, permissions);
    // }
      for (let i = 0; i < PERMISSION_TYPES.length; i++) {
         permitted = userHasPermission(permissions, PERMISSION_TYPES[i], userStore, scope, uiSchemaCursor, resourceId);
         // if (uiSchemaCursor.tag === 'Button') {
         //   console.log('Button', uiSchemaCursor.props, permitted);
         // }
         if (permitted) {
            if (uiSchemaCursor.tag === 'Field' || uiSchemaCursor.tag === 'WeekdaysPicker') {
               if (PERMISSION_TYPES[i] !== 'write' && !userHasPermission(permissions, 'write', userStore, scope, uiSchemaCursor, resourceId)) {
                  $.set(uiSchemaCursor, 'props.input.props.readOnly', true);
                  $.set(uiSchemaCursor, 'props.input.props.disabled', true);
               }
            }
            // permitted = true;
            break;
         }
      }
   }
   // return true;
   return permitted;
   // if (!userStore)
   //   return true; //for sandbox
   //
   // let permissions = $.get(uiSchemaCursor, 'permissions');
   // // let account
   // // console.log('isNew', isNew(),routerStore.get('query.scope') );
   // const scope = getScope(uiSchemaCursor);
   //
   // if (uiSchemaCursor.noAccess && uiSchemaCursor.noAccess[scope]) {
   //   return false;
   // }
   // if (!permissions) {
   //   return true;
   // }
   //
   // if (!Array.isArray(permissions)) {
   //   const po = [];
   //   Object.keys(permissions).forEach(key => {
   //     po.push(permissions[key]);
   //   });
   //   permissions = po;
   // }
   //
   // let permitted = false;
   // const userRoles = userStore.currentAccount.roles;
   // if (scope === 'crud' || scope === 'new') {
   //   if (userHasReadAccess(permissions, userRoles, scope, uiSchemaCursor)) {
   //       permitted = true;
   //     }
   //   // if (userHasPermission(permissions, 'read', userRoles, 'crud', uiSchemaCursor)) {
   //   //   permitted = true;
   //   // }
   //   // if (userHasReadAccess(permissions, userRoles, 'new', uiSchemaCursor)) {
   //   //   permitted = true;
   //   // } else if (!permitted && userHasReadAccess(permissions, userRoles, 'crud', uiSchemaCursor)) {
   //   //   permitted = true;
   //   // }
   //
   //
   // } else {
   //   for (let i = 0; i < PERMISSION_TYPES.length; i++) {
   //     permitted = userHasPermission(permissions, PERMISSION_TYPES[i], userRoles, scope, uiSchemaCursor);
   //     // if (uiSchemaCursor.tag === 'Button') {
   //     //   console.log('Button', uiSchemaCursor.props, permitted);
   //     // }
   //     if (permitted) {
   //       if (uiSchemaCursor.tag === 'Field') {
   //         if(!userHasPermission(permissions, 'write', userRoles, scope, uiSchemaCursor)) {
   //           $.set(uiSchemaCursor, 'props.input.props.readOnly', true);
   //         }
   //       }
   //       // permitted = true;
   //       break;
   //     }
   //   }
   // }
   //
   // return permitted;
   // return userHasPermission(permissions, 'read', userRoles, scope);

   /*
     if (!Array.isArray(permissions)) {
       const po = [];
       Object.keys(permissions).forEach(key => {
         po.push(permissions[key]);
       });
       permissions = po;
     }
     // console.log('permissions', permissions);
     // console.log('userRoles', userRoles);
     // const permitted = true;
     for(let i=0 ; i<permissions.length; i++) {
       const permission = permissions[i];
       // console.log('permission', permission, uiSchemaCursor.tag);
       if (uiSchemaCursor.tag==='Field') {
         console.log('permission', permission, uiSchemaCursor.tag);
         if (uiSchemaCursor.tag === 'Field' && permission.write && !hasPermission(permission, 'write', userRoles)) {

           $.set(uiSchemaCursor, 'props.input.props.readOnly', true);
           console.log('SET READONLY', uiSchemaCursor);
         }
         if (!permission.read) {
           return true;
         }
         if(hasPermission(permission, 'read', userRoles)) {
           return true;
         }
       }
       if (permission && permission.scope === 'crud' && scope==='crud') {
         if (!permission.read || !permission.read.roles) {
           return true;
         } else if (permission.read && permission.scope === 'crud') {
           const permittedRoles = permission.read.roles.filter(pr => userRoles.filter(ur => ur.id === pr.id ).length > 0);
           // console.log('ELSE', permission.read.roles, userRoles, permittedRoles);
           return permittedRoles.length>0;
         }

       } else if (permission.scope === 'activity' && permission.activityId === scope) {
         const activityId = scope; //TODO
         console.log('activity permission', permission, 'userRoles', userRoles, uiSchemaCursor.tag);
         // const permitted = false;
         // const permittedWrite = false;
         if (uiSchemaCursor.tag === 'Field' && !hasPermission(permission, 'write', userRoles)) {

           $.set(uiSchemaCursor, 'props.input.props.readOnly', true);
           console.log('SET READONLY', uiSchemaCursor);
         }
         const permissionTypes = ['execute', 'read', 'write'];//Object.keys(permission);
         for(let j=0; j<permissionTypes.length; j++ ) {
           const permissionType = permissionTypes[j];
           console.log('check permission type 0', permissionType, permission);
           if (!permission[permissionType]) {
             continue;
           }
           console.log('check permission type', permissionType, 'permission', permission, 'userRoles', userRoles, 'ui', uiSchemaCursor.tag);
           const permittedRoles = permission[permissionType].roles.filter(pr => userRoles.filter(ur => ur.id === pr.id ).length > 0);
           if(permittedRoles.length > 0) {
             return true;
           }
         };
         // const permittedRoles = permission.read.roles.filter(pr => userRoles.filter(ur => ur.id === pr.id ).length > 0);
           // console.log('ELSE', permission.read.roles, userRoles, permittedRoles);
           // return permittedRoles.length>0;
         return false;
         // console.log('permission', permission.read, !permission.read , permission);
       } else {
         // if (scope === 'crud') {
         //   return true;
         // }
         return false;
       }  */
   // };
   // return true;
}

