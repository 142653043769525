import React, { Component } from "react";
import { observer } from "mobx-react";
import $ from "@isf/core-object-util/src/object-util";
//import { injectIntl } from "@isf/localization";
import { ItemGrid } from "../../../item-grid";
import { FilterPropertyCriteriaItem } from "./filter-property-criteria-item";

//@injectIntl
@observer
class FilterPropertyCriteria extends Component {

  id(childId) {
    return this.props.id + '.' + childId;
  }

  render() {
    const { handler, accessor, propPath, propType, propFormat/*, intl, localizedProp*/ } = this.props;
    const filterSettingDB = handler.get(accessor);
    // const filter = localizedProp
    //   ? filterSettingDB && $.toJS($.get(filterSettingDB, `filter.${propPath.join(".")}.${intl.locale}`))
    //   : filterSettingDB && $.toJS($.get(filterSettingDB, `filter.${propPath.join(".")}`));
    const filter = filterSettingDB && $.toJS($.get(filterSettingDB, `filter.${propPath.join(".")}`));

    const filterPropertyCriteria = filter && filter.map((item, index) => {
      const fullPropPath = propPath.slice();
      fullPropPath.push(index);
      return <FilterPropertyCriteriaItem key={this.id(index)}
                                         id={this.id(index)}
                                         handler={handler}
                                         accessor={accessor}
                                         propPath={fullPropPath}
                                         propType={propType}
                                         propFormat={propFormat} />
                                         //localizedProp={localizedProp} />
    });

    return (
      <ItemGrid xs="12" gridClassName="py-2">
        {filterPropertyCriteria}
      </ItemGrid>
    )
  }
}

export { FilterPropertyCriteria };