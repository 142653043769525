import React, {Component} from "react";
import $ from "@isf/core-object-util";
import Field from "../../form/field";
import {observer} from "mobx-react";
import {stringFormat} from "@isf/string-format";

@observer
class FilterField extends Component {
  constructor(props) {
    super(props);
    const {handler, operation} = props;
    this.handler = transformHandler(handler, operation);
  }

  componentDidMount() {
    changeLanguageObject(this.props)
  }

  componentDidUpdate(prevProps) {
    changeLanguageObject(this.props)
  }

  render() {
    const {props, handler} = this;
    const {accessor, localeStore, ...other} = props;
    const newAccessor = stringFormat(accessor, localeStore);

    return (
      <Field
          {...other}
          handler={handler}
          accessor={newAccessor}
          localeStore={localeStore}
      />
      );
  }
}

const changeLanguageObject = (props) => {
  const {handler, accessor, languages, localeStore} = props;
  if(accessor.includes("localeStore:locale")) {
    const arrAccessor = $.strToArray(accessor);
    const isLocalizedIndex = arrAccessor.findIndex(item => item.startsWith("{"));

    if(~isLocalizedIndex) {
      const {store} = handler;
      const path = arrAccessor.slice(0, isLocalizedIndex);
      store.get(path) && !store.get([...path, localeStore.locale]) && store.set(path, {});
      // let obj = store.get(path) || {};

      // if($.isArray(obj)) return;
      //
      // const objNames = $.keys(obj);
      //
      // objNames.forEach(id => {
      //   obj = $.get(obj, id);
      //
      //   if(!!obj && id !== localeStore.locale) {
      //     store && store.get([...path, id]) && store.remove([...path, id]);
      //     handler.set([...path, localeStore.locale], obj);
      //   }
      //
      //   handler.set(stringFormat(accessor, localeStore), undefined);
      // })

    }
    //
    // languages.forEach(el => {
    //
    //   if(el.id !== localeStore.locale) {
    //
    //     const oldAccessor = stringFormat(accessor, {localeStore: {locale: el.id}});
    //     handler.set(oldAccessor, undefined);
    //   }
    // });
  }
};

const transformHandler = (handler, operation) => {
  if(!handler) return handler;

  const newHandler = {
    ...handler,
    set(accessor, value) {
      if(value && operation === "like") {
        value = "%" + value + "%";
      }

      handler.set(accessor, value);
      accessor = $.strToArray(accessor);
      handler.set([...accessor.slice(0, -1), "operation"], operation);
    }
  };

  if(operation === "like") {
    newHandler["get"] = (accessor) => {
      let value = handler.get(accessor);
      return !!value ? value.substring(1, value.length - 1) : value;
    }
  }

  return newHandler;
};


export {FilterField};