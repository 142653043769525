import React from 'react';
import {observer} from "mobx-react";
import {Input} from "reactstrap";
import PropTypes from 'prop-types';

const setFilter = (e, {accessor, filterStore}) => {
  filterStore.filterBy = {accessor, value: e.target.value || ""};
};

export const FilterRow = observer(({columns, ...other}) => (
  <tr>
    {columns.map((column, i) => {
      return(
        column.filterable ? <FilterCell key={i} colId={i} column={column} {...other}/> : <td key={'empty_cell_' + i}/>
    )})}
  </tr>
));

const FilterCell = observer(({column, filterStore, tableStore}) => {
  return (
  <td>
    <Input type="text"
           //placeholder={column.header + ' filter...'}
           accessor={["filter", column.accessor]}
           onChange={(e) => { tableStore.selectionStore.clearStore(); setFilter( e, {accessor: column.accessor, filterStore: filterStore}); } }
    />
  </td>
)});

FilterRow.propTypes = {
  id: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired
};

FilterCell.propTypes = {
  id: PropTypes.string,
  column: PropTypes.object.isRequired,
  filterStore: PropTypes.object.isRequired
};