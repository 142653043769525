import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
`;

export const Wrapped = styled.div`
    float: left;
       width: 100%;
`;
export const HoverComponent = styled.div`
    float: right;
    text-align: center;
   // width: min-content;
    display: block;
    visibility:hidden;
    position: absolute;
    padding-left:${props=>props.hoverWidth+5}px;
        right:-${props=>props.hoverWidth}px;
${Wrapper}: hover & {
    visibility:visible;
    flex-direction: column;
        align-items: stretch;
        z-index:2;
        }
        `;

// export const HoverComponent = styled.div`
//     float: right;
//     text-align: center;
//    // width: min-content;
//     display: block;
//     position: absolute;
//     padding-left:${props=>props.hoverWidth+20}px;
//     visibility:visible;
//     right:-${props=>props.hoverWidth+15}px;
//     flex-direction: column;
//         align-items: stretch;
//         }
//         `;