import { action, computed } from "mobx";

import { DropdownStore } from "@isf/dropdown";

class IdentityStore extends DropdownStore {

  @action
  setValue(value) {

  }

  @computed
  get value() {
    return "user";
  }

}

export default IdentityStore;