import React from 'react';
import classNames from 'classnames';
import { PopoverHeader as PopoverHeaderBootstrap } from '@isf/bootstrap';

const PopoverHeader = (props) => {
  const { id, lineClamp, className, style, children } = props;

  return (
    <PopoverHeaderBootstrap id={id} className={className} style={style}>
      <div className={classNames({ 'text-line-clamp': lineClamp })} style={{ WebkitLineClamp: lineClamp }}>
        {children}
      </div>
    </PopoverHeaderBootstrap>
  );
};

export default PopoverHeader;
