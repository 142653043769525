import React, {Component} from 'react';
import {observer} from 'mobx-react';
import identityApi from './identity-api';
import userStore from './user-store';
import {DataStore, UIStore} from '@isf/core-app-store';
import {loadAvailableLocale} from '../localization/load-locale';
import {ReactComponent as LOGO_FULL} from '@isf/common-resources/src/images/logo-site.svg';
import './login-view.css';

@observer
class LoginView extends Component {

  constructor(props) {
    super(props);
    this.errorStore = new UIStore({
      path: 'login.status',
      state: {},
      observableAsMap: true
    });
    this.creds = new DataStore({
      path: 'login.user',
      state: {},
      observableAsMap: true
    });
  }

  usernameHandler = (e) => {
    this.errorStore.get('fail') && this.errorStore.set('fail', null);
    this.creds.set('username', e.target.value);
  }

  passwordHandler = (e) => {
    this.errorStore.get('fail') && this.errorStore.set('fail', null);
    this.creds.set('password', e.target.value);
  }

  submitHandler = async (e) => {
    e.preventDefault();
    const {user, fail} = await identityApi.login(this.creds.stateJS);
    if (user) {
      loadAvailableLocale();
      userStore.authorize(user);
    } else this.errorStore.set('fail', fail);
  }

  render() {
    return (
      <div className="internal-login-container">
        <div className="internal-login-content">
          <LOGO_FULL className="internal-login-logo" />
          <h3 className="internal-login-header">Консоль администратора ОАИС</h3>
          <form onSubmit={this.submitHandler} id="internal-login" className="internal-login-form">
            <div className="internal-login-form-error-container">
              {this.errorStore.get('fail') && (
                <div className="internal-login-form-error-content">Неверный логин или пароль</div>
              )}
            </div>
            <div className="internal-login-form-group">
              <label htmlFor="username" className="internal-login-form-label">Логин</label>
              <input
                type="text"
                name="username"
                autoComplete="username"
                id="username"
                className="internal-login-form-input"
                onChange={this.usernameHandler}
              />
            </div>
            <div className="internal-login-form-group">
              <label htmlFor="password" className="internal-login-form-label">Пароль</label>
              <input
                type="password"
                name="password"
                autoComplete="current-password"
                id="password"
                className="internal-login-form-input"
                onChange={this.passwordHandler}
              />
            </div>
            <button
              type="submit"
              form="internal-login"
              className="internal-login-form-button"
              disabled={this.errorStore.get('fail')}
            >
              Войти
            </button>
          </form>
        </div>
      </div>
    )
  }
}

export default LoginView;