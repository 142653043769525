const resolveProps=(element,tag,map)=>{
  switch(tag){
    case "BPMNDiagram":{
      return (
        {
         ...element
        }
      );
    }
    case "Shape":{
      return (
        {
          ...element
        }
      );
    }
    case "SVG":{
      const {resourceId,...other}=element;
      return (
        {
          ...other,
          resourceId: "SVG_"+element.resourceId,
          stencil:{
            id: "SVG"
          }
        }
      )
    }
    case "Link":{
      return (
        {
          ...element,
          points:getPoints(element.dockers),
          defaultConditionConf:true,
          connected:{
            resourceId:getConnectedId(element.resourceId,map)
          },
          connectTo:{
            resourceId:element.outgoing[0].resourceId
          }
        }
      )
    }
  }
};

const getPoints = (dockers) =>{
  let points=[];
  for(let i=1;i<dockers.length-1;i++){
    points.push(dockers[i].x,dockers[i].y)
  }
  return points.join(",")
};

const getConnectedId=(flowId,map)=>{
  for (let value of map.values()) {
    if(value.props.outgoing) {
      for (let i = 0; i < value.props.outgoing.length; i++) {
        if (value.props.outgoing[i].resourceId === flowId)
          return value.props.resourceId;
      }
    }
  }
};

const getChildrenBesidesFlows=(element,map)=>{
  const children=[];
  for(let i=0; i<element.childShapes.length;i++){
    if(element.childShapes[i].stencil.id!=="SequenceFlow"){
      children.push(elementConverter(element.childShapes[i],map))
    }
  }
  return children;
};

const getChildrenFlows=(element,map)=>{
  const children=[];
  for(let i=0; i<element.childShapes.length;i++){
    if(element.childShapes[i].stencil.id==="SequenceFlow"){
      children.push(elementConverter(element.childShapes[i],map))
    }
  }
  return children;
};


const getResolveObject=(element,tag,map)=>{
  switch(tag) {
    case "BPMNDiagram": {
      const children = getChildrenBesidesFlows(element,map);
      children.splice(0,0,getResolveObject(element,"SVG",map));
      const BPMNDiagram =  {
        tag:tag,
        props:resolveProps(element,tag,map),
        children:children
      };
      map.set(element.resourceId,BPMNDiagram);
      return BPMNDiagram;
    }

    case "Shape": {
      let children = [];
      if(element.stencil.id==="SubProcess") {
        children = getChildrenBesidesFlows(element, map);
        children.splice(0, 0, getResolveObject(element, "SVG", map));
      }
      const Shape =  {
        tag:tag,
        props:resolveProps(element,tag,map),
        children:children
      };
      map.set(element.resourceId,Shape);
      return Shape;
    }

    case "SVG":{
      const children = getChildrenFlows(element,map);
      const SVG = {
        tag:tag,
        props:resolveProps(element,tag,map),
        children:children
      };
      map.set(element.resourceId,SVG);
      return SVG;
    }

    case "Link":{
      const children = element.childShapes.map((child)=>{
        return  elementConverter(child,map)
      });
      const Link = {
        tag:tag,
        props:resolveProps(element,tag,map),
        children:children
      };
      map.set(element.resourceId,Link);
      return Link;
    }
  }
};
export const schemaConverterFromJSON=(schema,map)=>{
   return elementConverter(schema,map)
};

const getResolveTag=(stencilId)=>{
  return stencilId!=="SequenceFlow"?stencilId==="BPMNDiagram"?"BPMNDiagram":"Shape":"Link";
};


const elementConverter=(element,map)=>{
  const tag=getResolveTag(element.stencil.id);
  return getResolveObject(element,tag,map);
};