import React, { Component } from 'react';
import {observer} from 'mobx-react';
import {injectIntl} from '../localization';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import classNames from 'classnames';
import {ICON_MULTI} from '@isf/common-resources';

@injectIntl
@observer
class Icon extends Component {
  fetchPromise;

  constructor(props) {
    super(props);
    this.state = {
      svg: null
    };
    this.loadSvg = this.loadSvg.bind(this);
    this.transformSvg = this.transformSvg.bind(this);
    this.imageLoadError = this.imageLoadError.bind(this);
  }

  componentDidMount() {
    this.loadSvg();
  }

  componentWillUnmount() {
    this.fetchPromise && this.fetchPromise.cancel();
  }

  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src || this.props.size !== prevProps.size || this.props.width !== prevProps.width
      || this.props.height !== prevProps.height || this.props.color !== prevProps.color
      || this.props.colorInversion !== prevProps.colorInversion || this.props.className !== prevProps.className
      || (this.props.title && prevProps.title && this.props.title.message && prevProps.title.message
          && this.props.title.message.id !==  prevProps.title.message.id)) this.loadSvg();
  }

  loadSvg() {
    const {src} = this.props;

    if(src && src.startsWith('data:image/svg+xml;')) {
      const encodedSvg = src.slice(src.indexOf('base64,') + 7);
      let decodedSvg;
      try {
        decodedSvg = atob(encodedSvg);
      } catch (e) {
        this.setState({svg: defaultSvg});
      }
      decodedSvg && this.transformSvg(decodedSvg);
    } else if (src && !src.startsWith('data:') && src.endsWith('.svg')) {
      let fetchPromise = fetch(`/static/media/${src}`,
        {
          mode: 'no-cors',
          headers: {
            'Accept': 'image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8',
            'Sec-Fetch-Dest': 'image'
          },
        })
        .then(res => {
          if (res.ok && res.headers.get('content-type').includes('image')) return res.text();
          else return false;
        });

      fetchPromise = makeCancelable(fetchPromise);
      this.fetchPromise && this.fetchPromise.cancel && this.fetchPromise.cancel();
      this.fetchPromise = fetchPromise;
      this.fetchPromise
        .promise
        .then(text => {
          if (!text.isCanceled) {
            if (text) this.transformSvg(text);
            else this.transformSvg(defaultSvg);
          }
        }, e => {
          if (!e.isCanceled) {
            this.transformSvg(defaultSvg)
          }
        })
        .catch(e => console.error("Error load icon", e))
    }
  }

  transformSvg(text) {
    const {size, width, height, color, colorInversion, title, className, intl} = this.props;

    const firstIndex = text.indexOf('<svg');
    const lastIndex = text.lastIndexOf('</svg>');
    if (firstIndex !== -1 && lastIndex !== -1) {
      let svg = text.slice(firstIndex, lastIndex + 6);
      let sizeClassName = 'icon-sm';
      if (size) sizeClassName = `icon-${size}`;
      if (size === 'custom') {
        if (width) {
          const firstIndex = svg.indexOf('width="');
          if (firstIndex) {
            const lastIndex = svg.indexOf('"', firstIndex + 7);
            svg = `${svg.slice(0, firstIndex)}width="${width}"${svg.slice(lastIndex + 1)}`;
          }
        }
        if (height) {
          const firstIndex = svg.indexOf('height="');
          if (firstIndex) {
            const lastIndex = svg.indexOf('"', firstIndex + 8);
            svg = `${svg.slice(0, firstIndex)}height="${height}"${svg.slice(lastIndex + 1)}`;
          }
        }
      }
      const fullClassName = classNames(
        'component-icon', sizeClassName, className,
        {'icon-color-inversion': colorInversion, [`icon-${color}`]: color && color !== 'immutable'}
      );
      svg = `${svg.slice(0, 5)}class="${fullClassName}"${svg.slice(4)}`;
      if (title && title.message && title.message.id) {
        const message = intl.messages[title.message.id] === undefined
          ? intl.formatMessage({id: title.message.id, defaultMessage: ''})
          : intl.messages[title.message.id];
        if (message && message !== title.message.id) {
          const index = svg.indexOf('>') + 1;
          svg = `${svg.slice(0, index)}<title>${message}</title>${svg.slice(index)}`;
        }
      }

      this.setState({svg: svg});
    } else this.setState({svg: defaultSvg});
  }

  imageLoadError(e) {
    if (e.target.src !== ICON_MULTI) {
      e.target.onerror = null;
      e.target.src = ICON_MULTI;
    }
  }

  render() {
    const { src, size, width, height, colorInversion, children, className, title, intl, uiBaseStore, ...other } = this.props;
    let sizeClassName = 'icon-sm';
    if (size) sizeClassName = `icon-${size}`;
    let mutableProps = {};
    if (size === 'custom') {
      mutableProps = { style: {width: width, height: height }};
    }

    if (src && src.startsWith('data:image/svg+xml;') || (src && !src.startsWith('data:') && src.endsWith('.svg'))) {
      if (this.state.svg) {
        DOMPurify.addHook('afterSanitizeAttributes', function (node) {
          const href = node.getAttribute('xlink:href') || node.getAttribute('href');
          if (href && !href.startsWith('#')) {
            node.removeAttribute('xlink:href');
            node.removeAttribute('href');
          }
        });

        const clean = DOMPurify.sanitize(this.state.svg, {
          // allow all safe SVG elements and SVG Filters, no HTML or MathML
          USE_PROFILES: { svg: true, svgFilters: true },
          // extend the existing array of allowed tags and add <use> to allow-list
          ADD_TAGS: ['use'],
        });

        DOMPurify.removeHook('afterSanitizeAttributes');

        return (
          <>
            {parse(clean)}
          </>
        );
      }
      else return <div className={classNames(`d-inline-block icon-presentation component-icon`, sizeClassName, className)} {...mutableProps} />
    } else {
      let message;
      if (title && title.message && title.message.id) {
        const value = intl.messages[title.message.id] === undefined
          ? intl.formatMessage({id: title.message.id, defaultMessage: ''})
          : intl.messages[title.message.id];
        if (value && value !== title.message.id) message = value;
      }
      const imgSrc = src && !src.startsWith('data:')
        ? `/static/media/${src}`
        : src
          ? src
          : ICON_MULTI;

      return (
        <img
          src={imgSrc}
          onError={e => this.imageLoadError(e)}
          className={classNames("component-icon", sizeClassName, className, {"icon-color-inversion": colorInversion})}
          alt={message || "Icon"}
          title={message || null}
          {...mutableProps}
          {...other}
        />
      )
    }
  }
}

const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
        val => hasCanceled_ ? reject({isCanceled: true}) : resolve(val),
        error => hasCanceled_ ? reject({isCanceled: true}) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export default Icon;

const  defaultSvg = '<svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px"><path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z"/></svg>';