import $ from "@isf/core-object-util";
import {getSchemaOpenApiChild} from "./open-api-utils";

const isLocalizedField = (schema, path) => {
  schema = path ? getSchemaOpenApiChild(schema, path) : schema;
  const isObject = $.get(schema, "type") === "object";
  const hasProperties = !!$.get(schema, "properties");
  const hasAdditionalProp = !!$.get(schema, "additionalProperties");
  return isObject && hasAdditionalProp && !hasProperties;
};

export {isLocalizedField};
