const defaultAlertMessageOptions = {
  position: 'bottom-right',
  effect: 'slide',
  timeout: 3000
};

const defaultGlobalErrorMessageOptions = {
  position: 'bottom-right',
  effect: 'slide',
  timeout: 'none'
};

export {defaultAlertMessageOptions,defaultGlobalErrorMessageOptions};