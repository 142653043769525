import Store from "./store";
import $ from "@isf/core-object-util";
import {action, observable} from "mobx";
import appStore from "./app-store";

class CacheStore extends Store{

    cacheSize = 5;

    constructor(props) {
        super(props);
    }

    hasData(url){
        const storeData = this.state;
        for(let i=0;i<storeData.length;i++){
            if($.get(storeData[i], 'url') === url ){
                return true
            }
        }
        return false;
    };

    getUrlData(url, scope){
        const storeData = this.state;
        for(let i=0;i<storeData.length;i++){
            if($.get(storeData[i], 'url') === url){
                return scope ? $.get(storeData[i], scope): storeData[i]
            }
        }
    }

    @action
    addStore(url,storeName, store, scope = 'data'){
        let urlData = this.getUrlData(url, scope);
        if(!urlData){
            if(this.state.length === this.cacheSize){
                this.state.splice(0,1);
            }
            this.push([],{url:url,data: {}, ui:{}, mapIds:{}})
            urlData = this.getUrlData(url, scope);
        }
        if(!$.get(urlData, [storeName])) {
            $.set(urlData, [storeName], store)
        }
    }

    getCachedState(url, storeName, scope){
        const urlData = this.getUrlData(url, scope);
        const mapIds = $.get(this.getUrlData(url), 'mapIds');
        if(urlData){
            const resultStoreName = this.getResultStoreName(mapIds,storeName)
            return $.get(urlData, [resultStoreName]);
        }
    }

    getResultStoreName(mapIds, storeName){
        if(mapIds){
            const mapValue = $.get(mapIds,[storeName]);
            if(mapValue){
                return mapValue;
            }else {
                return storeName;
            }
        }
        return storeName;
    }

    @action
    cacheMapIds(url, mapIds){
        let urlData = this.getUrlData(url);
        urlData && urlData.set('mapIds', mapIds);
    }

    @action
    cacheStores(url, stores, scope){
        for(let storeName of $.keys(stores)) {
            const store = appStore.getStore([storeName], scope);
            if(store && store.state) {
                this.addStore(url, storeName, store.state, scope);
            }
        }
    }

}

const cacheStore = new CacheStore({
    scope: 'cache',
    path: [],
    state: []
    // state: observable([]),
    // observableAsMap:true
});

cacheStore.makeObservable({asMap: true});

export default cacheStore;