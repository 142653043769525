import React from 'react'
import {inject, observer} from "mobx-react";
import $ from "@isf/core-object-util"
import {WEEKDAYS} from "./weekdays-picker-constants";
import {injectIntl} from "react-intl";
import {observable} from "mobx";
import {Col} from "reactstrap";
import classNames from "classnames";
import {FormattedMessage} from "@isf/localization";

@injectIntl
@observer
class WeekdaysPicker extends React.Component {

    constructor(props) {
        super(props);
        const {dataStore, accessor} = props;
        if (dataStore && !dataStore.get(accessor)) dataStore.set(accessor, []);
        if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    }

    componentWillUnmount() {
        this.uiBaseStore && this.uiBaseStore.dispose();
    }

    removeNote = (array, day) => {
        for (let i = 0; i < array.length; i++) {
            let value = $.get(array[i], 'weekDay');
            if (value === $.get(day, 'weekDay')) {
                array.splice(i, 1);
                break;
            }
        }
    };

    isChecked = (array, day) => {
        //const array = this.getArray();
        for (let i = 0; i < array.length; i++) {
            let value = $.get(array[i], 'weekDay');
            if (value === $.get(day, 'weekDay')) {
                return true;
            }
        }
    };

    makeObservableArray=()=>{
        const {dataStore, accessor} = this.props;
        if(dataStore){
            if(accessor){
                const value = dataStore.get(accessor);
                if(!$.isObservableArray(value)){
                    dataStore.set(accessor,observable.array(value))
                }
            }else{
                const value = dataStore.state;
                if(!$.isObservableArray(value)){
                    dataStore.setState(observable.array(value))
                }
            }
        }
    };

    handleChange = (e, day) => {
        const {input} = this.props;
        if ((input && input.props && input.props.readOnly) || (input && input.props && input.props.disabled)) {
            return null;
        }
        if(!$.isObservableArray(this.getArray())){
            this.makeObservableArray();
        }
        const array = this.getArray()||[];
        const value = this.isChecked(array,day);
        if (!value) {
            array.push(day)
        } else {
            this.removeNote(array, day)
        }
        if(this.props.dataStore){
            let {onClick} = this.props;
            onClick && onClick(e, "WeekDaysPicker");
        }
    };

    getArray = () => {
        const {dataStore, accessor} = this.props;
        if (dataStore)
            return accessor ? dataStore.get(accessor) : dataStore.state;
        return []
    };

    render() {
        const {id, updateAction, className, input} = this.props;
        const array = this.getArray()||[];
        const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
          && this.uiBaseStore.classNameStore.getClassNames();
        const mutableProp = {};
        if ((input && input.props && input.props.readOnly) || (input && input.props && input.props.disabled)) {
            mutableProp.disabled = true;
        }

        return (
            <Col className={classNames("d-flex", className, computedClassNames)}>
                {
                    WEEKDAYS.map(day => {
                        const dayName = $.get(day, 'weekDay');
                        const checked = this.isChecked(array,day)
                        return (
                            <div key={dayName}>
                                <input type="checkbox" className="mr-2" id={id + '.' + dayName}
                                       checked={checked}
                                       {...mutableProp}
                                       onChange={(e) => this.handleChange(e, day, updateAction)}/>
                                <label htmlFor={id + '.' + dayName} className="mr-2">
                                    <FormattedMessage id={"ui.weekdaysPicker." + dayName.toLowerCase()}/>
                                </label>
                            </div>
                        )
                    })
                }
            </Col>
        )
    }
}

export {WeekdaysPicker}