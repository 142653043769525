import React from "react";
import {observer} from "mobx-react";
import {LocalizationProvider} from "../localization/src";
import DiagramRenderedView from "./diagram-renderer";
import styled from "styled-components";
import {ModalContainer, ModalHeader} from "../modal";
import {ModalBody} from "reactstrap";
import {ToggleStore} from "../toggle";
import {debugApi} from "./api/api";
import {schemaConverterFromJSON} from "./converters/converterFromJSON";
import { FormattedMessage } from "../localization";

const StyledDiagramDrawZone = observer(styled.div`
  width:100%;
  height:700px;
  position:relative;
  border: 1px solid darkgrey;
  overflow:auto;
`);

@observer
class DiagramModalView extends React.Component {
  constructor(props) {
    super(props);
    this.toggleStore = this.props.toggleStore || new ToggleStore({path: "toggleDiagramReadOnly"});
    // const t = this.toggleStore;
    // this.toggleStore.toggle = () => {
      
    // }
    // this.schemaDataStore = new SchemaDataStore({schema: schemaConverterFromJSON(NewDiagramConf,new Map())});
  };

  // toggle=async()=> {
  //   if(this.props.schemaDataStore.processInstanceId) {
  //     this.schemaDataStore.setSchema(await getSchema(this.props.schemaDataStore.processInstanceId));
  //     this.schemaDataStore.setProcessStatuses(await getExecuted(this.props.schemaDataStore.processInstanceId));
  //   }
  //   this.toggleStore.toggle();
  // };


  render() {
    const {localeStore} = this.props;
    return (
      <ModalContainer
        toggleStore={this.toggleStore}
        //opener={<Button color={"primary"} onClick={() =>this.toggle()}><FormattedMessage id={"ui.modal.businessProcess"}/></Button>}
        onModalOpen={handleOpen}
        size={"xl"}>
        <ModalHeader>
          <FormattedMessage id={"ui.modal.businessProcess"}/>
        </ModalHeader>
        <ModalBody>
          <LocalizationProvider localeStore={localeStore} id={"diagramDrawZone"}>
            <StyledDiagramDrawZone>
              <DiagramRenderedView uiSchema={this.toggleStore.schemaDataStore.schema} schemaDataStore={this.toggleStore.schemaDataStore}
                                   localeStore={localeStore}/>
            </StyledDiagramDrawZone>
          </LocalizationProvider>
        </ModalBody>
      </ModalContainer>
    )
  }
};

const getSchema = async (processInstanceId) => {
  if (processInstanceId) {
    return await debugApi.request({
      path: `process-instance/${processInstanceId}/bpmn-json`,
    })
      .then(response => {
        return schemaConverterFromJSON(response,new Map())
      })
  }
};

const getExecuted = async (processInstanceId) => {
  if (processInstanceId) {
    return await debugApi.request({
      path: `process-instance/${processInstanceId}/executions`,
    })
      .then(response => {
       return response.responseMap;
      })
  }
};

const handleOpen = (e) => {
  e.stopPropagation();
};


export default DiagramModalView;