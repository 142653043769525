import React, {Component} from "react";
import $ from "@isf/core-object-util";
import {Container, Field, useInputHandler} from "@isf/common";
import {Col, Row} from '@isf/bootstrap';
import {getMappingObjChild} from "../config-constants";
import {generateFieldPropsByModelSchema} from "../components-model-schema-creator";
// import {generateFieldInputPropsByModelSchema} from "@isf/components-config";

class PrimitiveMapping extends Component {
  constructor(props) {
    super(props);

    this.configStore = props.configStore;
    this.valueStore = props.valueStore;
    this.path = props.path ? props.path + ".primitiveMapping" : "primitiveMapping";

    this.useHandler = useInputHandler({store: this.valueStore});
    this.set = this.set.bind(this);
    this.id = this.id.bind(this);
  }

  id(accessor) {
    return this.path ? this.path + "." + accessor : accessor;
  }

  getInput() {
    const {props, configStore} = this;

    const {dataStore} = configStore;
    const destPath = dataStore.get('mapping.select.path');

    let schema = getMappingObjChild(configStore[props.treeProps.dataName], destPath);
    schema = schema && $.isObject(schema) ? $.toJS(schema) : {};

    let input = generateFieldPropsByModelSchema(schema);
    const options = createOptionsBySchema(schema);

    if (options) {
      input.props.type = "select";
      input.options = options;
    }

    if (input.props.type === "password") {
      input.props.type = "text";
    }

    return input;
  }

  set(accessor, value) {
    const {valueStore, configStore, props} = this;
    const {dataStore} = configStore;
    const destPath = dataStore.get('mapping.select.path');
    const schema = getMappingObjChild(configStore[props.treeProps.dataName], destPath);

    if (schema && $.isObject(schema) && $.get(schema, "type") === "boolean") {
      value = eval(value);
      value = typeof value === "boolean" ? value : undefined;
    }

    valueStore.set(accessor, value)
  }

  render() {
    const {props, useHandler} = this;
    const {intl, layout, accessor} = props;
    const input = this.getInput();

    return (
      <Container>
        <Row>
          <Col>
            <Field
              input={input}
              id={this.id(`value`)}
              layout={layout}
              label={{text: intl.formatMessage({id: 'ui.modal.value'}), props:{...layout.label.props}}}
              {...useHandler(accessor, this.set)}
            />
          </Col>
        </Row>

      </Container>
    );
  }
}

const createOptionsBySchema = (schema) => {
  let options;
  if(schema && $.isObject(schema) && $.isArray(schema.enum)) {
    options = schema.enum.map(it => ({value: it, text: it}));
    options.unshift({value: undefined, text: ""});

  } else if (schema && $.isObject(schema) && schema.type === "boolean") {
    options = [
      {value: undefined, text: ""},
      {value: true, text: "True"},
      {value: false, text: "False"}
    ];
  }

  return options;
};

export {PrimitiveMapping}