import React from 'react';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {Breadcrumb as _Breadcrumb} from '@isf/bootstrap';

@observer
class Breadcrumb extends React.Component{
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {children, tag = 'nav', listTag = 'div', className} = this.props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <_Breadcrumb tag={tag} listTag={listTag} className={classNames(className, computedClassNames)}>
        {children}
      </_Breadcrumb>
    )
  }
}

export {Breadcrumb};
