import React from "react";
import {CommonAlert, getAlertMessage, createAlertMessage} from "@isf/common";

// const defaultOptions = {
//   position: 'bottom-right',
//   effect: 'slide',
//   timeout: 3000
// };

// const createAlertMessage = (messages, type = "error", options = defaultOptions) => {
//   CommonAlert[type](getAlertMessage(messages), options);
// };

export {createAlertMessage};