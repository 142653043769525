import {runInAction} from "mobx";
import $ from "@isf/core-object-util";
import appStore from "@isf/core-app-store";
import {localeStore} from "@isf/core-localization";
import {createAlertMessage} from "../create-alert-message";
import {defaultGlobalErrorMessageOptions} from "@isf/common";
import {comparePathsForError} from "../actions/compare-paths-for-error";
import {default as downloadjs} from "downloadjs"
import {getErrorMessage, getHeader} from "../rest-response-handler/response-utils";

async function restHandler(context, responseInfo, response) {
  response = await response;
  const {action} = context;
  const messages = $.get(action,'messages');
  runInAction(() => {
    if (responseInfo.ok) {
      if(messages) {
        const messageId = $.get(messages, 'ok.id');
        if (messageId) {
          const message = $.get(localeStore.localeMessages, messageId);
          if (message) {
            createAlertMessage(message, "success");
          }
        }
      }

      if (getHeader(responseInfo, "content-type") && !getHeader(responseInfo, "content-type").startsWith("application/json")) {
        let contentDisposition = getHeader(responseInfo, "content-disposition"),
          fileName = "file";

        contentDisposition = typeof contentDisposition === "string" ? contentDisposition.split("filename=") : [];
        if (contentDisposition.length >= 2) {
          fileName = contentDisposition[1];
        }
        downloadjs(response, decodeURI(fileName));

      } else {
        const statusKeys = $.keys(this.responseMapping || {});
        if (statusKeys.length === 0) return;
        this.setResponseMapping(responseInfo.status, response);
      }
    }
    else {
      const errorGlobalStore = appStore.getStore('errorGlobalStore', 'ui');
      const {flowName} = context;
      if(flowName){
        errorGlobalStore.set('failedFlow',flowName);
      }

      const status = responseInfo.status;
      if (status === 500) {
        const {autoErrorHandling, errorHandleAction} = context.action;
        if(autoErrorHandling === false){
          if(errorHandleAction){
            context.engineContext.executeAction(errorHandleAction,context.callContext, undefined, context.flowName);
          }
        }else {
          if ($.isArray(response)) {
            response.forEach(error => {
              errorGlobalStore.push('errors', {...error, status: status})//before changed alerts
              //createAlertMessage(getErrorMessage(error,localeStore), "error",defaultGlobalErrorMessageOptions);
            });
          } else {
            errorGlobalStore.push('errors', {...response, status: status})//before changed alerts
            //createAlertMessage(getErrorMessage(response,localeStore), "error",defaultGlobalErrorMessageOptions);
          }
        }
      } else {
        if ($.isArray(response)) {
          if (status === 422){
            createAlertMessage(
                localeStore.localeMessages['ui.alerts.422'],
                'error'
            );
          }
          response.forEach(error => {
            if(status!==500 && status !== 422) {
              createAlertMessage(/*status !== 422
                ?*/ /*$.get(error, 'message')*/ getErrorMessage(error, localeStore, status)
                /*: localeStore.localeMessages['ui.alerts.422']*/,
                  'error'
              );
            }
            const path = error.path;//accessor of error
            const requestMapping = $.get(action,'request.mapping');
            let isStoreInsideMappings = false;
            const {refId} = context;
            for(let i=0;i<requestMapping.length;i++){
              const map = requestMapping[i];

              let pathToData;
              if(map.dest.parameterType === 'body') {
                pathToData = map.dest.value.value.split('.').slice(1).join('.');
              } else {
                pathToData = map.dest.value.value.split('.').join('.');
              }
              if (comparePathsForError(path,pathToData)) {
                if (map.source.variable) {
                  const newName = refId ? refId + "." + map.source.variable : map.source.variable;
                  const dataStore = appStore.getDataStore(newName);
                  if (dataStore) {
                    isStoreInsideMappings = true;
                    dataStore.addToErrorState(map.source.value.split('.').slice(1).join('.'), error)
                  }
                }
              }

            }
            // if(!isStoreInsideMappings){
            //   const storeName = action.request.store||'';
            //   const resultStore = appStore.getDataStore(storeName);
            //   if(resultStore){
            //     resultStore.addToErrorState(path, error)
            //   }else{
            //     errorGlobalStore.push('errors', {...error, status: status})
            //   }
            // }
          });
        } else {
          if(status!==500) {
            createAlertMessage(status !== 422
                ? /*$.get(error, 'message')*/ getErrorMessage(response, localeStore, status)
                : localeStore.localeMessages['ui.alerts.422'],
                'error'
            );
          }
          //console.log('unresolved error', response)
        }
      }
    }
  });
}

export {restHandler};