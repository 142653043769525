export const NewDiagramConf = {
  "bounds": {
    "lowerRight": {
      "x": 0.0,
      "y": 0.0
    },
    "upperLeft": {
      "x": 0.0,
      "y": 0.0
    }
  },
  "resourceId": "canvas",
  "stencil": {
    "id": "BPMNDiagram"
  },
  "stencilset": {
    "namespace": "http://b3mn.org/stencilset/bpmn2.0#",
    "url": "../editor/stencilsets/bpmn2.0/bpmn2.0.json"
  },
  "properties": {
    "process_id": "myProcess",
    "name": "My process",
    "process_namespace": "http://www.activiti.org/test",
    "messages": [],
    "executionlisteners": {
      "executionListeners": []
    },
    "eventlisteners": {
      "eventListeners": []
    },
    "signaldefinitions": [],
    "messagedefinitions": []
  },
  "childShapes": []
};