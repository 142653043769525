import React, { Component } from "react";
// import { inject } from "mobx-react";

import { localeStore } from "@isf/core-localization";
import UISchemaRenderer from "./ui-schema-renderer/ui-schema-renderer";

// const UISchemaView = inject('userStore') ((props) => {
//   console.log('ui schema view', props)
//   if (!props.uiSchema) {
//     return null;
//   }
//   return (
//     <UISchemaRenderer {...props}/>
//   );
// });

class UISchemaView extends Component {
  componentDidUpdate() {
    // console.log('UISchemaView', {locale: localeStore.currentLocale});
    const microfrontend = this.props.microfrontend;
    if (microfrontend && microfrontend.callbacks) {
      // console.log('render microfrontend', microfrontend, microfrontend.callbacks)
      try {
        // console.log('UISchemaView', {locale: localeStore.currentLocale});
        microfrontend.callbacks.render({locale: localeStore.currentLocale});
      } catch(e) {
        console.log(e);
      }

    }
  }

  render() {
    const { microfrontend } = this.props;
    // console.log('UISchemaView microfrontend', microfrontend);
    return (
      <UISchemaRenderer {...this.props}/>
    );
  }
}

export default UISchemaView;
