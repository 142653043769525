import {UIStore} from "@isf/core-app-store";
import {observable, computed, action} from "mobx";

class FilterStore extends UIStore {
  @observable _filterBy;
  @observable _isFilterActive = false;

  set filterBy({accessor, value}) {
    this._filterBy = {
      ...this._filterBy,
      [accessor]: String(value).toUpperCase()
    };
  }

  @computed get filterBy() {
    return this._filterBy
  }

  @action
  clearFilter() {
    this._filterBy = undefined;
  }

  @computed
  get isFilterActive() {
    return this._isFilterActive;
  }

  @action
  setIsFilterActive() {
    this._isFilterActive = !this._isFilterActive;
  }
}

Object.defineProperty(FilterStore, "name", {value: "FilterStore"});

export {FilterStore};