import React from 'react';
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import {CustomInput} from "reactstrap";

const handleOnChange = (props) => { 
    const {rowIndex, store, selection} = props;
    if(rowIndex < 0) {
        selection !== "single" && store.setSelectAll();
    } else {
        store.setCheckedRow(rowIndex, selection);
    }
}

const CheckBox = observer(props => {
    const {rowId,store, rowIndex} = props;
    return (
      <CustomInput
        id={rowId}
        type="checkbox"
        className="checkbox"
        checked={store.isSelectAll() || store.isRowChecked(rowIndex)}
        onChange={() => handleOnChange(props)}
      />
    );
});

CheckBox.propTypes = {
  id: PropTypes.string,
  store: PropTypes.object.isRequired,
  rowId: PropTypes.any.isRequired,
};

export default CheckBox;