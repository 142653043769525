import React from "react";
import PropTypes from "prop-types";
import { ToggleButton } from "../toggle";

const ModalCancelButton = ({children, ...other}) => {
  return (
    <ToggleButton data-button-type="cancel" color="primary" outline {...other}>
      {children ? children : 'Отмена'}
    </ToggleButton>
  );
}

ModalCancelButton.propsTypes = PropTypes.instanceOf(ToggleButton);

export { ModalCancelButton };