import { reaction, action, computed, observable, runInAction } from "mobx";

import appStore, { DataStore, routerStore } from "@isf/core-app-store";
import $ from "@isf/core-object-util";
import { engine } from "@isf/ui-schema";

class Page {

  get title() {
    return engine.schema ? engine.schema.title : undefined;
  }

  @computed
  get uiSchema() {
    return engine.schema ? engine.schema.ui : undefined;
  }

  @computed
  get schema() {
    return engine.schema;
  }

  @computed
  get microfrontend() {
    return engine.schema.microfrontend;
  }

  get securityScope() {
    return engine.pageTemplates ? engine.pageTemplates.securityScope : undefined;
  }
}

const pageStore = new Page();

export default pageStore;