import React, {Component} from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

@observer
class Header extends Component {
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    if (props.uiStoreType) this.uiStore = new props.uiStoreType(props);
  }

  componentWillUnmount() {
    this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {className, type, children} = this.props;
    const MyComponent = type;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <MyComponent className={classNames("d-flex align-items-center", className, computedClassNames)}>
        {children}
      </MyComponent>
    );
  }
}

export default Header;
