import {computed, reaction} from "mobx";
import {uuidv4} from "@isf/core-system-util";
import {getString, NOT_HIDDEN} from "../expression/utils"
import $ from "@isf/core-object-util/src/object-util";
import {UiDisposeStore} from "./ui-dispose-store";

const initialize = (props, id, store) => {
    store._hiddenStore = new UICommonHiddenStore(id, props);
};

class UINavItemStore /*extends UIStore*/ {

    constructor(props) {
        const id = 'uiButtonStore.' + uuidv4();
        /* super({path:id});*/
        initialize(props, id, this)
    }

    _hiddenStore;

    @computed
    get hiddenStore() {
        return this._hiddenStore;
    }

    dispose(){
        this._hiddenStore.dispose();
    }
}

class UICommonHiddenStore/* extends UIStore*/ extends UiDisposeStore{
    constructor(parentId, props) {
        super(props);
        const parentArrPath = parentId.split('.');
        parentArrPath.splice(1, 0, 'hidden');
        /* super({path:parentArrPath});*/
        this.localeStore = props.localeStore;
        this.map = $.objectToObservableMap({});
        initializeHiddenAutorun(props, this)
    }

    get = (path) => {
        return this.map.get(path)
    };

    set = (path, value) => {
        this.map.set(path, value)
    };
}


const initializeHiddenAutorun = (props, store) => {
    if (props.hidden === 'true' || props.hidden === 'false') {
        const result = props.hidden === "true";
        store.set('isHidden', result);
        checkActiveTab(props, result);

    } else if (props.hidden && !NOT_HIDDEN.includes(props.hidden)) {
        const {params, expression} = props.hidden;
        try {
            if (params && params.length !== 0) {

                setValue(props, store, getString(params, props, store));
                store.addAutorun(reaction(
                  getString.bind(undefined, params, props, store),
                  setValue.bind(undefined, props, store)
                ))

            } else {
                const result = !!eval(expression);
                store.set('isHidden', result);
                checkActiveTab(props, result);
            }


        } catch (e) {

            // createAlertMessage("Failed execute computed value" + e.message, "error"); //For future
            console.error(`Failed execute hidden value (NavItem id: ${props.id})`, e)
        }
        // const hiddenParams = props.hidden && props.hidden.params || [];
        // const hiddenExpression = props.hidden && props.hidden.expression || {};
        // store.addAutorun(autorun(() => {
        //     let declaredString = "";
        //     let result;
        //     if (hiddenParams.length !== 0) {
        //         declaredString = getDeclaredString(hiddenParams, store.localeStore, props.data, {refId: props.refId});
        //         //declaredString !== "let;" && store.set('isHidden', eval(declaredString + hiddenExpression))
        //         result = eval(declaredString + hiddenExpression);
        //     } else {
        //         //store.set('isHidden', eval(hiddenExpression));
        //         result = eval(hiddenExpression);
        //     }
        //
        //     result = !!result;
        //
        //     store.set('isHidden', result);
        //     checkActiveTab(props, result);
        //     // setActiveNavStoreIndex(props, store, result);
        // }));
    } else {
        checkActiveTab(props, false);
    }
};

const setValue = (props, store, declaredString) => {
    const hiddenExpression = props.hidden && props.hidden.expression;
    const result = !!eval(declaredString + hiddenExpression);
    store.set('isHidden', result);
    checkActiveTab(props, result);
};

const checkActiveTab = (props, hidden) => {
    setNavItemsHiddenByPropsId(props, hidden);
    if (hidden && props.navStore.isActive(`${props.id}`)) {
        changeActiveTab(props);

    } else if (!hidden && props.navStore.activeItemId === -1) {
        props.navStore.setActive(`${props.id}`, true);
    }
};


const changeActiveTab = (props) => {
    props.navStore.setActive(`${props.id}`, false);
    setActiveClosestIndex(props);
};


const setActiveClosestIndex = (props) => {
    const navItems = props.navStore.navItems;
    if (!$.isArray(navItems)) return;

    const currentIndex = navItems.findIndex(it => it.id === props.id);

    if (~currentIndex) {
        let i = 1;
        for (; currentIndex - i > -1 || currentIndex + i < navItems.length; i++) {
            if (currentIndex - i > -1 && !navItems[currentIndex - i].hidden) {
                props.navStore.setActive(`${navItems[currentIndex - i].id}`, true);
                break;
            }
            if (currentIndex + i < navItems.length && !navItems[currentIndex + i].hidden) {
                props.navStore.setActive(`${navItems[currentIndex + i].id}`, true);
                break;
            }
        }
    }
};

const setNavItemsHiddenByPropsId = (props, hidden) => {
    const navItems = props.navStore.navItems;
    if (!$.isArray(navItems)) return;

    const currentIndex = navItems.findIndex(it => it.id === props.id);
    if (~currentIndex) {
        navItems[currentIndex].hidden = hidden;
    }
};

export {UINavItemStore}