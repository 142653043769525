import {autorun, computed, observable} from 'mobx';
import objectUtil from '@isf/core-object-util';
import {uuidv4} from '@isf/core-system-util';
import {getDeclaredString,  NOT_COMPUTED_CLASS_NAME} from '../expression/utils';
import {UiDisposeStore} from "./ui-dispose-store";

const initialize = (props, id, store) => {
  store._classNameStore = new UIClassNameStore(id, props);
};

class UIBaseStore{
  constructor(props){
    const id = 'uiBaseStore.' + uuidv4();
    initialize(props, id, this);
  }

  _classNameStore;

  @computed
  get classNameStore() {
    return this._classNameStore;
  }

  dispose(){
    this._classNameStore.dispose();
  }
}

class UIClassNameStore extends UiDisposeStore {
  @observable
  _map = [];

  constructor(parentId, props) {
    super(props);
    this.localeStore = props.localeStore;
    initializeClassNameAutorun(props, this);
  }

  get = path => {
    return objectUtil.get(this._map, path);
  }

  set = (path, value) => {
    objectUtil.set(this._map, path, value);
  }

  getClassNames = () => {
    const arrayClassNames = objectUtil.toJS(this.get());
    if (arrayClassNames && Array.isArray(arrayClassNames)) return arrayClassNames.join(' ');
    else return null;
  }
}

const initializeClassNameAutorun = (props, store) => {
  if (props.computedClassName && !NOT_COMPUTED_CLASS_NAME.includes(props.computedClassName)) {
    const params = props.computedClassName.params || [];
    const expression = props.computedClassName.expression || {};
    store.addAutorun(autorun(() => {
      let declaredString = '';
      if (params.length !== 0) {
        declaredString = getDeclaredString(params, store.localeStore, props.data, {refId:props.refId});
        declaredString !== "let;" && store.set('0', eval(declaredString + expression));
      } else store.set('0', eval(expression));
    }));
  }
}

export {UIBaseStore};