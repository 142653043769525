import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer, inject} from "mobx-react";
import classNames from 'classnames';
import {TableContext} from "./table";
import CheckBox from "../table-checkbox";
import {isHeadCell} from "./table-cell";
import {TableHeadContext} from "./table-head";

export const TableRowContext = React.createContext({item: {}})


@inject("store")
@observer
class TableRow extends Component {
    constructor(props) {
        super(props);
        if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    }

    componentWillUnmount() {
        this.uiBaseStore && this.uiBaseStore.dispose();
    }

    // componentDidMount() {
    //     const rowIndex = this.props.rowIndex;
    //     this.props.store.selectionStore.setPageRow(rowIndex);
    // }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.item) !== JSON.stringify(this.props.item)) {
            this.props.store.selectionStore.clearStore();
        }
    }

    render() {
        const {children, className, item, store, rowIndex, rowId, selection, uiStoreType, computedClassName,
            localeStore, languages, uiBaseStore, ...other} = this.props;
        //const newStore = (dataStore && dataStore.state ? dataStore : store); //TODO
        const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
          && this.uiBaseStore.classNameStore.getClassNames();

        return (
            <TableContext.Consumer>
                {({columns, selection, choice}) => {
                    // if (columns.length === 0)
                    //     return (<tr><ResultTag></ResultTag></tr>);
                    return (
                        // <TableRowContext.Provider value={{item: {
                        //         store: newStore,
                        //         index: rowIndex
                        //     }}}>
                        <TableHeadContext.Consumer>
                            {
                                ({isTableHeadChild}) => {
                                    const ResultTag = isTableHeadChild ? 'th' : 'td';
                                    return (
                                        <tr className={classNames(className, computedClassNames)} scope={"row"} /*colSpan={columns.length}*/ {...other}>
                                            {/*{columns[0].type === "checkbox" &&*/}
                                            {(choice && !isTableHeadChild) &&
                                            <ResultTag style={{width: "60px"}}>
                                                <CheckBox selection={selection} rowId={rowId || 'configuredRowId'}
                                                          store={store.selectionStore} rowIndex={rowIndex }/>
                                            </ResultTag>}
                                            {children}
                                        </tr>
                                    )
                                }
                            }
                        </TableHeadContext.Consumer>
                    )
                }}
            </TableContext.Consumer>
        );
    }
}

TableRow.propTypes = {
    // id: PropTypes.string,
    // columns: PropTypes.arrayOf(PropTypes.object).isRequired
};

export {TableRow};
