import React from 'react';
import PropTypes from 'prop-types';
import {observer, inject} from "mobx-react";
import classNames from 'classnames';
import CheckBox from "../table-checkbox";
import {Message} from "../../../form/message";
import {injectIntl} from "react-intl";
import {TableContext} from "./table";
import {uuidv4} from "@isf/core-system-util";
import SortWrapper from "../old-table-view/sort-wrapper";
import appStore from "@isf/core-app-store";
import {localeStore} from "@isf/core-localization";
import $ from "@isf/core-object-util"

// const handleOnThClick = (column, store) => {
//     store.sortConfig = column.accessor;
// }

export const TableHeadContext = React.createContext({})

@injectIntl
@inject("store")
@inject("dataStore")
@observer
class TableHead extends React.Component {
    static contextType = TableContext;

    constructor(props) {
        super(props);
        if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
        if (props.uiStoreType) this.uiStore = new props.uiStoreType(props)
    }

    componentDidUpdate(prevProps) {
        const {dataStore, store} = this.props;
        if (dataStore && dataStore.state && store) {
            if (!store.get('columnDataTypes')) {
                const {columns} = this.context;
                columns.forEach(column => {
                    const columnData = dataStore.get(['0', column.accessor])
                      || dataStore.get([`${dataStore.state.length - 1}`, column.accessor]);
                    let columnDataType = columnData && typeof columnData;
                    if (typeof columnDataType === 'string' && stringIsDate(columnData)) columnDataType = 'string.Date';
                    columnDataType && store.set(`columnDataTypes.${column.accessor}`, columnDataType);
                })
            }
        }
    }

    componentWillUnmount() {
        this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
        this.uiBaseStore && this.uiBaseStore.dispose();
    }

    render() {
        const {className, store, children = [], id, intl, data, hidden, border, composite, externalActions,
            externalDependencies, externalContext, uiStoreType, localeStore, languages, computedClassName, uiBaseStore,
            dataStore, refId, ...other} = this.props;
        const tableStoreState = dataStore && dataStore.state && store && store.state;
        if (this.uiStore && this.uiStore.hiddenStore.get('isHidden')) return null;
        const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
          && this.uiBaseStore.classNameStore.getClassNames();
        //TODO change
        const newId = id ? id : uuidv4();
        return (
            <TableContext.Consumer>
                {({columns, choice, selection}) => (
                    <thead className={classNames(className, computedClassNames)} {...other}>
                    {/*<tr>*/}
                    {/*<th colSpan={columns.length}>*/}
                    <TableHeadContext.Provider
                      value={{
                          isTableHeadChild: true,
                          border: border,
                          tableStoreState: tableStoreState
                      }}
                    >
                        {choice &&
                        <tr>
                            <th style={{width: "60px"}} rowSpan={
                                (($.get(children, 'props.args.uiSchemaCursor.children') && $.get(children, 'props.args.uiSchemaCursor.children').length) ||
                                (children && children.length) || 1)+1}>
                                {selection !== "single" &&
                                    <CheckBox selection={selection} rowId={newId + '.configuredRowId'}
                                          store={store.selectionStore} rowIndex={-1}/>
                                }
                            </th>
                        </tr>}
                        {children}

                    </TableHeadContext.Provider>
                    {/*</th>*/}
                    {/*</tr>*/}
                    {((children && children.length === 0) ||
                        ($.get(children, 'props.args.uiSchemaCursor.children') && $.get(children, 'props.args.uiSchemaCursor.children').length === 0)) &&
                    <tr>
                        {columns.map((column, i) => {
                            const sortable = column.sortable;
                            let columnDataType = tableStoreState
                              && $.get(tableStoreState, ['columnDataTypes', column.accessor]);
                            if (column.type === 'checkbox') {
                                return (
                                    <th style={{width: "60px"}} scope="col" key={i} className={column.className}>
                                        {selection !== "single" &&
                                        <CheckBox rowId={newId + '.headerCheckBox'} store={store.selectionStore}
                                                  rowIndex={-1}/>
                                        }
                                    </th>
                                )
                            } else {
                                //    if (column.type === 'actions') {
                                //     return <th/>;
                                // }
                                return (
                                    <th
                                      scope="col"
                                      key={i}
                                      className={column.className}
                                      onClick={(e) => sortClick(e, column, data, columnDataType)}
                                    >
                                        <SortWrapper
                                          isSorted={sortable}
                                          columnProps={column}
                                          columnDataType={columnDataType}
                                        >
                                            {typeof column.header === 'object' ?
                                                <Message message={column.header.message}/> : column.header}
                                        </SortWrapper>
                                    </th>
                                )
                            }
                        })
                        }
                    </tr>
                    }
                    </thead>
                )
                }
            </TableContext.Consumer>
        )
    }
}

export const sortClick = (e, column, data, columnDataType) => {
    const isSortable = column.accessor && column.sortable && column.sort;
    const store = isSortable && appStore.getDataStore(column.sort.storeName);
    if (isSortable && store) {
        let accessor = `body.sort.${column.accessor}`;
        let before = store.get(accessor);
        if (column.sort.isLocalized) {
            before = store.get(accessor + ".{localeStore:locale}");
            accessor += "." + localeStore.locale;
            !before && (before = store.get(accessor))
        }
        store.set('body.sort', {});
        let sortDirection;
        if (before === undefined) sortDirection = columnDataType === 'string.Date' ? 'asc' : 'desc';
        else sortDirection = before === 'asc' ? 'desc' : 'asc';
        store.set(accessor, sortDirection);
        column.sort.actionName(data, undefined, "TableSort");
    }
};

const stringIsDate = (string) => {
    if (!isNaN(Date.parse(string))) {
        const dateFormat = /\d{4}\-\d{1,2}\-\d{1,2}/;
        return dateFormat.test(string);
    } else return false;
}

TableHead.propTypes = {
    id: PropTypes.string,
    tableName: PropTypes.string,
    // columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    // store: PropTypes.object.isRequired
};

export {TableHead};
