import React, { createContext, Component } from "react";
export const NavigationThemeContext = createContext('navStore');
import { NavbarStore } from "./navbar-store"
import {observer} from "mobx-react";
import classNames from "classnames";
import {getListIndex} from "../list-context/list-utils";

@observer
export class NavigationContainer extends Component {
  constructor(props) {
    super(props);
    let path = "navbar_" + props.accessor;
    const listIndex = getListIndex(props);
    if (listIndex) {
      path += `_${listIndex}`;
    }
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    if (props.uiStoreType) this.uiStore = new props.uiStoreType(props);
    this.navbarStore = new NavbarStore({
      path: path, state: {
        active: {
          0: true
        },
      }
    });
  }

  componentWillUnmount() {
    this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    if (this.uiStore && this.uiStore.hiddenStore.get('isHidden')) return null;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();
    return (
      <NavigationThemeContext.Provider value={this.navbarStore} >
        <div className={classNames(this.props.className, computedClassNames)}>
          {this.props.children}
        </div>
      </NavigationThemeContext.Provider>)
  }
}