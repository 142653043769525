import React from 'react';
import PropTypes from 'prop-types';
import {observer, inject, Provider} from "mobx-react";
import classNames from "classnames";
import Editor from "./table-cell-editor";
import CheckBox from "../table-checkbox";
import {DefaultTableActions} from "./table-default-actions"
import $ from "@isf/core-object-util";
import {injectIntl} from "react-intl";
import {sortClick, TableHeadContext} from "./table-head";
import SortWrapper from "../old-table-view/sort-wrapper";
import {TableContext} from "./table";

const handleOnDoubleClick = props => {
    const {rowId, colId, store, column,...other} = props;
    if (column.editable) {
        store.startEditCell({rowId, colId});
    }
};

export const getParentKey = (childKey) => (
    childKey.split('.').slice(0,childKey.split('.').length-1).join('.')
)

const tableHeadIsParent = (key, uiSchema) => {
    let path = key;
    while(path.split('.').length!==1) {
        const shortParentKey = getParentKey(path);
        const longParentLength = 'children.' + shortParentKey.split('.').join('.children.');
        const schema = $.get(uiSchema,longParentLength);
        if(schema && $.get(schema,'tag')==='TableHead'){
            return true;
        }
        path=shortParentKey;
    }
}

export const isHeadCell = (props) => {
    if($.get(props,'children.props.args.key')) {
        const cellKey = $.get(props, 'children.props.args.key').split('.').slice(1).join('.');
        const uiSchemaStore = $.get(props, 'children.props.args.uiSchema')
        return tableHeadIsParent(cellKey, uiSchemaStore)
    }
};

@injectIntl
@inject("store")
@observer
class TableCell extends React.Component{
    constructor(props) {
        super(props);
        if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    }

    componentWillUnmount() {
        this.uiBaseStore && this.uiBaseStore.dispose();
    }

    render() {
        return(
          <TableContext.Consumer>
              {({columns, selection}) => {
                  return (
                    <TableHeadContext.Consumer>
                        {
                            ({isTableHeadChild, border, tableStoreState}) => {
                                return (isTableHeadChild
                                  ? getTableHeadCellContent({
                                      ...this.props,
                                      columns: columns,
                                      border: border,
                                      uiBaseStore: this.uiBaseStore,
                                      tableStoreState: tableStoreState
                                    })
                                  : getTableCellContent({...this.props, columns: columns, uiBaseStore: this.uiBaseStore,}))
                            }
                        }
                    </TableHeadContext.Consumer>
                  )
              }}
          </TableContext.Consumer>
        )
    }
}

const getTableHeadCellContent = (props) =>{
    const {children, item, column, data, store, rowId, colId, colSpan, rowSpan, layoutActions, rowIndex, intl, tagName,
        className, textAlign, columns=[], border, uiBaseStore, computedClassName, localeStore, languages,
        tableStoreState, ...other} = props;
    const realColumnObject = columns.length > column && columns[column];
    const cellStyle = border ? {borderLeft: "1px solid #e2e8e2", borderTop: "1px solid #e2e8e2", boxSizing: 'border-box'} : {};
    const computedClassNames = uiBaseStore && uiBaseStore.classNameStore && uiBaseStore.classNameStore.getClassNames();
    const sortable = realColumnObject.sortable;
    let columnDataType = tableStoreState && $.get(tableStoreState, ['columnDataTypes', realColumnObject.accessor]);
    if (sortable) {
        return (
            <th
              {...other}
              key={column}
              scope="col"
              colSpan={colSpan}
              rowSpan={rowSpan}
              className={classNames(textAlign, className, computedClassNames)}
              style={cellStyle}
              onClick={(e) => sortClick(e, realColumnObject, data, columnDataType)}
            >
                {layoutActions}
                <SortWrapper isSorted={sortable} columnProps={realColumnObject} columnDataType={columnDataType}>
                    {children}
                </SortWrapper>
            </th>
        )
    }
    // else if(sortable){
    //     let sortStore;
    //     if (column.accessor && column.sortable && column.sort) {
    //         sortStore = appStore.getDataStore(column.sort.storeName);
    //     }
    //     return (
    //         <th scope="col" key={i} className={column.className}
    //             onClick={(e) => sortClick(e, column, sortStore, data)}>
    //             <SortWrapper isSorted={sortable} store={sortStore} accessor={column.accessor}>
    //                 {typeof column.header === 'object' ?
    //                     <Message message={column.header.message}/> : column.header}
    //             </SortWrapper>
    //         </th>
    //     )
    // }

    return (
        // <td {...other} aria-label={intl.formatMessage(props.id)}>
                        <th
                          {...other}
                          scope={'col'}
                          colSpan={colSpan}
                          rowSpan={rowSpan}
                          className={classNames(textAlign, className, computedClassNames)}
                          style={cellStyle}
                        >
                            {layoutActions}
                            {/*<TableRowContext.Consumer>*/}
                            {/*    {({item})=>{*/}
                            {/*        return (*/}
                            {/*        <Provider data={item || "no such data"}>*/}
                            {/*            <>*/}
                            {children}
                            {/*            </>*/}
                            {/*        </Provider>*/}
                            {/*    )}}*/}
                            {/*</TableRowContext.Consumer>*/}
                        </th>
    );
    // }
    //TODO: remove ''+ - temporary fix

    // let value = $.get(item, column.accessor);
    // if (value === null || value === undefined) {
    //     value = '';
    // }
    // return (
    //     <th scope="col" className={className} onDoubleClick={() => handleOnDoubleClick(props)}>
    //         {"" + value}
    //     </th>
    // )
}

const getTableCellContent = (props) => {
    const {children, item, column = {type: "default"}, data, store, rowId, colId, layoutActions, rowIndex, intl,
        tagName, className, textAlign, uiBaseStore, computedClassName, localeStore, languages, columns, childindex,
        ...other} = props;
    const headCell = isHeadCell(props);
    const scope = headCell ? 'col' : undefined;
    const ResultTag = headCell ? ' th' : 'td';
    const isBeingEdited = store.isBeingEdited({rowId, colId});
    const computedClassNames = uiBaseStore && uiBaseStore.classNameStore && uiBaseStore.classNameStore.getClassNames();
    const colIndex = colId || childindex || 0;
    const ariaLabel = columns[colIndex]
      ? typeof columns[colIndex].header === 'object' && columns[colIndex].header.message
          ? intl.messages[columns[colIndex].header.message.id] === undefined
            ? intl.formatMessage({id: columns[colIndex].header.message.id, defaultMessage: ''})
            : intl.messages[columns[colIndex].header.message.id]
          : columns[colIndex].header
      : null;
    if (isBeingEdited) {
        return (
            <ResultTag
              {...other}
              scope={scope}
              className={classNames(textAlign, className, computedClassNames)}
            >
                {layoutActions}
                <Editor accessor={[rowIndex, column.accessor]} rowId={rowId}
                        colId={colId} store={store} value={item[column.accessor]}/>
            </ResultTag>
        );
    }
    if (column.type === "checkbox") {
        return (
            <ResultTag
              {...other}
              scope={scope}
              className={classNames(textAlign, className, computedClassNames)}
              style={{width:"60px"}}
            >
                {layoutActions}
                <CheckBox rowId={rowId} store={store.selectionStore} rowIndex={rowIndex}/>
            </ResultTag>
        );
    } else if (column.type === "actions") {
        return (
            <DefaultTableActions/>
        );
    }
    return (
        <ResultTag
          {...other}
          scope={scope}
          className={classNames(textAlign, className, computedClassNames)}
          aria-label={ariaLabel}
        >
            {layoutActions}
            {/*<TableRowContext.Consumer>*/}
            {/*    {({item})=>{*/}
            {/*        return (*/}
            {/*        <Provider data={item || "no such data"}>*/}
            {/*            <>*/}
            {children}
            {/*            </>*/}
            {/*        </Provider>*/}
            {/*    )}}*/}
            {/*</TableRowContext.Consumer>*/}
        </ResultTag>
    );
    // }
    //TODO: remove ''+ - temporary fix
    let value = $.get(item, column.accessor);
    if (value === null || value === undefined) {
        value = '';
    }
    return (
        <ResultTag scope="col" className={className} onDoubleClick={() => handleOnDoubleClick(props)}>
            {"" + value}
        </ResultTag>
    )
};

TableCell.propTypes = {
    id: PropTypes.string,
    // item: PropTypes.object.isRequired,
    // column: PropTypes.object.isRequired,
    // store: PropTypes.object.isRequired,
    // rowId: PropTypes.any.isRequired,
    // colId: PropTypes.number.isRequired
};

export {TableCell};
