import React, { Component } from "react";
import { Message } from "./message"
import { FormattedDate, FormattedTime } from "react-intl";
import { observer, inject } from "mobx-react";
import { injectIntl } from "@isf/core-localization";
import $ from "@isf/core-object-util";
import appStore from "@isf/core-app-store";
import classNames from "classnames";

@inject((allStores, props, context) => {
   // const {data, externalDependencies} = allStores;
   if (allStores.externalDependencies) {
      return {
         ...props,
         // data: data,
         externalDependencies: allStores.externalDependencies
      };
   } else
      return props;
})
@injectIntl
@observer
class StaticText extends Component {
   constructor(props) {
      super(props);
      const {externalDependencies} = props;
      if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
      if (props.uiStoreType) this.uiStore = new props.uiStoreType(props);
      if(externalDependencies && $.get(props,'external.id') && $.get(props,'external.option')){
          const dependency = $.get(externalDependencies, $.get(props,'external.id'));
          const responseStoreName = $.get(dependency, 'responseStore');
          if(responseStoreName)
            this.responseExternalStore = appStore.getDataStore(responseStoreName);
      }
   }

   hasExternal(){
       const {props} = this;
       const {externalDependencies} = props;
       return externalDependencies && $.get(props,'external.id') && $.get(props,'external.option');
   }

   getExternalValue(computedValue){
       if(this.responseExternalStore){
           const {props} = this;
           const {externalDependencies, localeStore} = props;
           const dependency = $.get(externalDependencies, $.get(props,'external.id'));
           const searchedProp =  $.get(dependency,'searchedProp');
           let accessor = $.get(props,'external.option');
           if ($.get(props,'external.isLocalized')) {
             accessor += "." + localeStore.locale;
           }
           return computeExternalValue(this.responseExternalStore, searchedProp, computedValue, accessor);
       }
       return computedValue;
   }

   getStoreText() {
     const {data, accessor, dataStore} = this.props;
     let value;
     if(data) {
       const {store: listStore, index: listIndex} = data;
       const newPath = listIndex + "." + accessor;

       value = listStore
         ? listStore.data
           ? $.get(listStore.data, newPath)
           : listStore.get(newPath)
         : undefined;
     } else {
       value = dataStore.get(accessor);
     }

     return $.isObject(value) ? value.toString() : value;
   }

    componentWillUnmount() {
        this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
        this.uiBaseStore && this.uiBaseStore.dispose();
    }

   render() {
      const {className, textAlign, div, text, message, format, intl, onClick, dataStore, accessor} = this.props;
      if (this.uiStore && this.uiStore.hiddenStore.get("isHidden")) return null;
      const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
       && this.uiBaseStore.classNameStore.getClassNames();

     let staticText;
     if(dataStore && accessor) {
       staticText = getFormattedDateTime(format, this.getStoreText());

     } else if(this.props.computed && this.props.computed !== "false") {
       let computedValue = this.uiStore && this.uiStore.computedStore.get("value");
       computedValue = $.isObject(computedValue) ? computedValue.toString() : computedValue;
       if(this.hasExternal()){
           computedValue = this.getExternalValue(computedValue);
       }
       const computedText = intl.locale === "by" || intl.locale === "ru" ? "{{Выражение}}" : "{{Computed}}";
       staticText = computedValue === undefined && !this.uiStore
         ? computedText
         : getFormattedDateTime(format, computedValue);

     } else {
       staticText = <Message text={text} message={message} />;
     }

      return (
         <span className={classNames(textAlign, className, computedClassNames)} {...div} onClick={onClick}>
            {/*{this.props.computed && this.props.computed !== "false"*/}
            {/*  ? (computedValue === undefined && !this.uiStore*/}
            {/*    ? computedText*/}
            {/*    : getFormattedDateTime(format, computedValue))*/}
            {/*  : <Message text={text} message={message} />*/}
            {/*}*/}
           {staticText}
         </span>
      )
   }
}

//  {typeof computedValue === 'string' ? (computedValue === "" ? computedValue : getFormattedDateTime(format, computedValue)) :
//                computedValue === undefined && this.uiStore ? "" : this.props.computed && this.props.computed !== 'false' ? computedText :
//                   <Message text={text} message={message}/>}
const getFormattedDateTime = (type, computedValue) => {
  if(!computedValue) return computedValue;

   switch (type) {
      case "date": {
         // return <FormattedDate
         //          value={computedValue}
         //          day="numeric"
         //          month="long"
         //          year="numeric" />
         const date = !isNaN(Date.parse(computedValue)) && (new Date(computedValue));
         const formattedDate = date?date.toLocaleDateString():undefined;
         return(
            <>
            <span className="pr-2">
               {formattedDate}
            </span>
            </>
         )
      }
      // case "dateTime": {
      //    return (
      //       <>
      //          <span className="pr-2">
      //             <FormattedDate
      //               value={computedValue}
      //               day="numeric"
      //               month="long"
      //               year="numeric" />
      //          </span>
      //          <FormattedTime
      //             value={computedValue}
      //             hour="numeric"
      //             minute="numeric" />
      //       </>
      //    );
      // }
      case "dateTime": {
         const date = !isNaN(Date.parse(computedValue)) && (new Date(computedValue));
         const fullTimeArr = date.toLocaleTimeString().split(' ')||[];
         let resultTimeView;
         const timePart = $.get(fullTimeArr,'0');
         if(timePart){
           resultTimeView = timePart.slice(0,-3);
           if($.get(fullTimeArr,'1')){
               resultTimeView+=' '+$.get(fullTimeArr,'1');
           }
         }
         const formattedDate = date?date.toLocaleDateString()+' '+(resultTimeView || date.toLocaleTimeString()):undefined;
         return(
            <>
            <span className="pr-2">
               {formattedDate}
            </span>
            </>
         )
      }
      default :
         return (computedValue);
   }
   //    case "shortDateTime": {
   //       const date = !isNaN(Date.parse(computedValue)) && (new Date(computedValue));
   //       const formattedDate = date?date.toLocaleDateString()+' '+date.toLocaleTimeString().slice(0,-3):undefined;
   //       return(
   //       <>
   //          <span className="pr-2">
   //             {formattedDate}
   //          </span>
   //       </>
   //       )
   //    }
   //    default :
   //       return (computedValue);
   // }
};

const computeExternalValue = (store, searchedProp, computedValue, option) => {
  const responseArr = store.state || [];
  for (let i = 0; i < responseArr.length; i++) {
    const el = responseArr[i];
    if ($.get(el, searchedProp) === computedValue) {
      return $.get(el, option)
    }
  }
};

export default StaticText;