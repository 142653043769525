import React from "react";
import { ModalFooter as _ModalFooter} from "reactstrap";
import { observer } from "mobx-react";
import {LoadSpinner} from "../load-spinner/load-spinner-view";
import classNames from "classnames";

@observer
class ModalFooter extends React.Component{
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {children, isLoading, className, computedClassName, languages, localeStore, uiBaseStore,
      ...other} = this.props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <_ModalFooter className={classNames("p-relative", className, computedClassNames)} {...other}>
        <LoadSpinner isLoading={isLoading} hideSpinner/>
        {children}
      </_ModalFooter>
    );
  }
}

export { ModalFooter };