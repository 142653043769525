import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { injectIntl } from "@isf/core-localization";
import { URL_MERGE, referer } from "./conf";

/**
 * Link for merge in dropdown component
 */

@injectIntl
@inject("userStore")
@observer
class Merge extends Component {
  constructor(props) {
    super(props);
    //this.formRef = React.createRef();
  }

  render() {
    const {intl: { formatMessage }, userStore } = this.props;
    if(!(userStore.get("user.currentAccount.type") === "STR" && !userStore.get("user.currentAccount.orgId"))) return null;

    return (
      <div className="px-4"> {/** make working for IE11/quickfiх*/}
          <a href={URL_MERGE} className="d-inline-block w-100 text-center" >
            <span>{formatMessage({id: "ui.header.accounts.merge"})}</span>
          </a>
        </div>
    );
  };
}

export default Merge;