import React, {Component, Fragment} from 'react'
import {observer} from "mobx-react";
import {
  FormField,
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalOKButton,
  resolveStore,
  ToggleStore,
  useInputHandler,
  LoadSpinner,
  Signature,
  ModalCancelButton, SignVerifyView
} from "@isf/common";
import {injectIntl} from "@isf/localization";
import {SignGlobalStore} from "./sign-global-store";

const layout = {
  label: {
    props: {
      sm: 12,
      md: 7,
      lg: 7,
      size: 'sm'
    }
  },
  input: {
    layout: {
      col: {
        sm: 12,
        md: 5,
        lg: 5
      }
    },
    props: {
      bsSize: "sm"
    }
  }
};


/**
 * state: {
 *   actionConfig: {
 *     destination: "FLASH_DRIVE",            "string" ("FLASH_DRIVE" or "CARD")
 *     port: "8084"                           "number"
 *   }
 *   flow_index                               "number" index flow action
 *   state                                    "string" ("SLEEP", "EXECUTION" or "SETTINGS")
 * }
 */
@injectIntl
@observer
class SignView extends Component {
  constructor(props) {
    super(props);
    this.dataStore = new SignGlobalStore({
      path: 'globalSignStore', state: {
        actionConfig: {
          destination: "FLASH_DRIVE",
          port: 8084
        }
      }
    });
    this.dataStore.setDefaultState();
    this.useHandler = useInputHandler({store: this.dataStore});
    this.toggleStore = resolveStore({id: 'globalSign', type: ToggleStore});

    this.id = this.id.bind(this);
  }

  id(accessor) {
    return "global.sign.modal." + accessor;
  }

  openToggle = () => {
    return true;
  };

  closeModal = () => {
    const {dataStore, toggleStore} = this;
    // dataStore.set("loading", false);
    dataStore.setDefaultState();
    toggleStore && toggleStore.set && toggleStore.set('active', false);
  };

  toggle = (props) => {
    const buttonType = props['data-button-type'];
    const {dataStore, closeModal} = this;

    if (buttonType === 'cancel' || buttonType === undefined /*&& dataStore.get("cancelButton")*/) {
      const cancel = dataStore.cancelAction && dataStore.cancelAction();
      dataStore.set("loading", true);
      Promise.resolve(cancel)
        .finally(closeModal);
    }

    if (buttonType === 'ok') {
      // this.toggleStore.set('active', false);

      dataStore.set(["state"], "EXECUTION");
      if (typeof dataStore.action === "function") {
        dataStore.set("loading", true);
        const action = dataStore.action();
        Promise.resolve(action)
          .finally(closeModal);

      } else {
        this.toggleStore.set('active', false);
      }

      return false;
    }
  };

  hiddenField = () => {
    return !this.dataStore.get("showField");
  };

  render() {
    const {id, props, toggle, openToggle, useHandler, dataStore, toggleStore} = this;
    const {intl} = props;
    return (
      <Fragment>
        <ModalContainer
          size={'md'}
          toggleStore={toggleStore}
          toggle={toggle}
          openToggle={openToggle}
        >
          <ModalHeader>
            {intl.formatMessage({id: "ui.sign.modal.user.header"})}
          </ModalHeader>
          <ModalBody>
            <SignVerifyView intl={intl} props={props}/>
            <Signature store={dataStore} intl={intl}/>
            <FormField
              type={"select"}
              layout={layout}
              id={id("destination")}
              hidden={this.hiddenField}
              options={getDestinationOptions(intl)}
              handler={useHandler("actionConfig.destination")}
              label={intl.formatMessage({id: "ui.sign.modal.user.destination"})}
            />
            <FormField
              step={1}
              type={"number"}
              layout={layout}
              id={id("destination")}
              hidden={this.hiddenField}
              handler={useHandler("actionConfig.port")}
              label={intl.formatMessage({id: "ui.sign.modal.user.port"})}
            />
          </ModalBody>
          <Footer intl={intl} dataStore={dataStore} />
          <Loader intl={intl} dataStore={dataStore}/>
        </ModalContainer>
      </Fragment>
    )
  }
}

const Footer = observer(({dataStore, intl}) => {
  return (
    <ModalFooter>
      {dataStore.get(["signButton"])
      && <ModalOKButton size={"sm"}>
        {intl.formatMessage({id: "ui.sign.modal.user.button"})}
      </ModalOKButton>
      }
      {/*dataStore.get(["cancelButton"]) && */ <ModalCancelButton children={"Закрыть"} size={"sm"}/>}
    </ModalFooter>
  )
});


const Loader = observer(({intl, dataStore}) => {
  const loading = dataStore.get("loading");

  return loading
    ? <div style={{top: 0, left: 0}} className={"w-100 h-100 position-absolute d-flex flex-column justify-content-end"}>
        <LoadSpinner isLoading={loading} />
        {/*<span className={"text-center h-25"} style={{zIndex: "1001"}}>{intl.formatMessage({id: "ui.sign.modal.user.loader"})}</span>*/}
      </div>
    : null;
});

const CloseNull = () => null;

const getDestinationOptions = ({formatMessage}) => [
  {
    value: "FLASH_DRIVE",
    text: formatMessage({id: "ui.sign.modal.user.destination.flash_drive"})
  },
  {
    value: "CARD",
    text: formatMessage({id: "ui.sign.modal.user.destination.card"})
  }
];

export {SignView}
