import React, { Fragment } from "react";
import { observer } from "mobx-react";
import { IntlProvider, localeStore } from "@isf/core-localization";
import { Spinner } from 'reactstrap';

@observer
class LocalizationProvider extends React.Component {
  render() {
    const { children, id } = this.props;
    const store = this.props.localeStore || localeStore;

    if (store.initializing) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <Spinner type="border" color="primary" style={{ width: '64px', height: '64px' }}>
            {null}
          </Spinner>
        </div>
      );
    }

    const { locale, localeMessages } = store;
    return (
      <Fragment>
        <IntlProvider
          locale={locale}
          key={locale+id}
          messages={localeMessages}>
            {children}
         </IntlProvider>        
      </Fragment>
    );
  }
}

export default LocalizationProvider;