import React from "react"
import {observer} from "mobx-react"
import {
    ICON_CROSS, ICON_TIMER, ICON_BLEACH, ICON_CROSS_CIRCLE, ICON_ERROR, ICON_MESSAGE, ICON_REWIND, ICON_SIGNAL,
    ICON_THROW_COMPENSATION
} from "@isf/common-resources";
import {Message} from "../../../form/message";

export const ContentRoundShape = observer((props) => {
    return (
        <div>
        </div>
    )
});

export const TerminateEndEventContent = observer((props) => {
    const {width, height, ...other} = props.styleDefault;
    const halfWidth = width/2;
    const halfHeight = height/2;
    return (
        <div style={{width: width, height: height, borderRadius: "50%"}}>
            <div style={{
                height: halfHeight,
                width: halfWidth,
                boxShadow: `0px 0px 0px 3px ${props.borderColor} inset`,
                borderRadius: "50%",
                marginTop: halfHeight/2,
                marginLeft: halfWidth/2,
            }}/>
            <div style={{position: "absolute", top: 0, height: height, width: width, borderRadius: "50%"}}/>
        </div>
    )
})

export const ScriptTaskContent = observer((props) => {
    return (
        <div>
            <Message message={{id: props.message.id}}/>
        </div>
    )
});

export const UserTaskContent = observer((props) => {
    return (
        <div>
            <Message message={{id: props.message.id}}/>
        </div>
    )
});

export const ReceiveTaskContent = observer((props) => {
    return (
        <div>
            <Message message={{id: props.message.id}}/>
        </div>
    )
});

export const ServiceTaskContent = observer((props) => {
    return (
        <div>
            <Message message={{id: props.message.id}}/>
        </div>
    )
});

export const SubProcessContent = observer((props) => {
    return (
        <div>
            <Message message={{id: props.message.id}}/>
        </div>
    )
});

export const ParallelGatewayContent = observer((props) => {
    const {top, left, position, width, height, borderRadius, ...other} = props.styleDefault;
    return (
        <div style={{
            ...other,
            width: width * 1.42 / 2,
            height: height * 1.42 / 2,
            position: "absolute",
            left: width / 2 - width / 4 * 1.42,
            top: height / 2 - height / 4 * 1.42,
            transform: "rotate(45deg)",
            border: "1px solid " + props.borderColor,
        }}>
            <img src={ICON_CROSS} alt={"Parallel"} style={{
                width: width * 1.42 / 3,
                height: height * 1.42 / 3,
                position: "absolute",
                left: width * 1.42 / 12 - 1,
                top: width * 1.42 / 12 - 1,
            }}/>
        </div>
    )
});

export const ExclusiveGatewayContent = observer((props) => {
    const {top, left, position, width, height, borderRadius, ...other} = props.styleDefault;
    return (
        <div style={{
            ...other,
            width: width * 1.42 / 2,
            height: height * 1.42 / 2,
            position: "absolute",
            left: width / 2 - width / 4 * 1.42,
            top: height / 2 - height / 4 * 1.42,
            transform: "rotate(45deg)",
            border: "1px solid " + props.borderColor,
        }}>
            <img src={ICON_CROSS} alt={"Exclusive"} style={{
                width: width * 1.42 / 3,
                height: height * 1.42 / 3,
                position: "absolute",
                left: width * 1.42 / 12 - 1,
                top: width * 1.42 / 12 - 1,
                transform: "rotate(45deg)"
            }}/>
        </div>
    )
});

export const InclusiveGatewayContent = observer((props) => {
    const {top, left, position, width, height, borderRadius, ...other} = props.styleDefault;
    return (
        <div style={{
            ...other,
            width: width * 1.42 / 2,
            height: height * 1.42 / 2,
            position: "absolute",
            left: width / 2 - width / 4 * 1.42,
            top: height / 2 - height / 4 * 1.42,
            transform: "rotate(45deg)",
            border: "1px solid " + props.borderColor,
        }}>
            <div style={{
                width: width * 1.42 / 3,
                height: height * 1.42 / 3,
                position: "absolute",
                left: width * 1.42 / 12 - 1,
                top: width * 1.42 / 12 - 1,
                borderRadius: "50%",
                border: "3px solid " + props.borderColor,
            }}>
            </div>
        </div>
    )
});

export const BoundaryErrorEventContent = observer((props) => {
    const {width, height, borderRadius} = props.styleDefault;
    return (
        <div style={{width: width, height: height, borderRadius: "50%"}}>
            <img src={ICON_ERROR} alt={"Error"}
                 style={{height: height, width: width, borderRadius: "50%", padding: 1}}/>
            <div style={{position: "absolute", top: 0, height: height, width: width, borderRadius: "50%"}}/>
        </div>
    );
});

export const MessageEventContent = observer((props) => {
    const {width, height, borderRadius} = props.styleDefault;
    return (
        <div style={{width: width, height: height, borderRadius: "50%"}}>
            <img src={ICON_MESSAGE} alt={"Message"} style={{
                height: height,
                width: width,
                boxShadow: "0px 0px 0px 1px black inset",
                borderRadius: "50%",
                padding: 1
            }}/>
            <div style={{position: "absolute", top: 0, height: height, width: width, borderRadius: "50%"}}/>
        </div>
    );
});

export const BoundaryCancelEventContent = observer((props) => {
    const {width, height, borderRadius} = props.styleDefault;
    return (
        <div style={{width: width, height: height, borderRadius: "50%"}}>
            <img src={ICON_CROSS_CIRCLE} alt={"Cancel"}
                 style={{
                     height: height,
                     width: width,
                     boxShadow: "0px 0px 0px 1px black inset",
                     borderRadius: "50%",
                     padding: 2
                 }}/>
            <div style={{position: "absolute", top: 0, height: height, width: width, borderRadius: "50%"}}/>
        </div>
    );
});

export const CompensationEventContent = observer((props) => {
    const {width, height, borderRadius} = props.styleDefault;
    return (
        <div style={{width: width, height: height, borderRadius: "50%"}}>
            <img src={ICON_REWIND} alt={"Compensation"}
                 style={{
                     height: height,
                     width: width,
                     boxShadow: "0px 0px 0px 1px black inset",
                     borderRadius: "50%",
                     padding: 2,
                     paddingLeft: 0
                 }}/>
            <div style={{position: "absolute", top: 0, height: height, width: width, borderRadius: "50%"}}/>
        </div>
    );
});

export const BoundarySignalEventContent = observer((props) => {
    const {width, height, borderRadius} = props.styleDefault;
    return (
        <div style={{width: width, height: height, borderRadius: "50%"}}>
            <img src={ICON_SIGNAL} alt={"Signal"}
                 style={{
                     height: height,
                     width: width,
                     boxShadow: "0px 0px 0px 1px black inset",
                     borderRadius: "50%",
                     transform: 'rotate(180deg)',
                     paddingTop: 4
                 }}/>
            <div style={{position: "absolute", top: 0, height: height, width: width, borderRadius: "50%"}}/>
        </div>
    );
});

export const BoundaryTimerEventContent = observer((props) => {
    const {width, height, borderRadius} = props.styleDefault;
    return (
        <div style={{width: width, height: height, borderRadius: "50%"}}>
            <img src={ICON_TIMER} alt={"Timer"}
                 style={{width: width, height: height, borderRadius: "50%", padding: 2}}/>
            <div style={{position: "absolute", top: 0, height: height, width: width, borderRadius: "50%"}}/>
        </div>
    );
});

export const CatchTimerEventContent = observer((props) => {
    const {width, height, borderRadius} = props.styleDefault;
    return (
        <div style={{width: width, height: height, borderRadius: "50%"}}>
            <img src={ICON_TIMER} alt={"Timer"}
                 style={{width: width, height: height, borderRadius: "50%", padding: 3}}/>
            <div style={{position: "absolute", top: 0, height: height, width: width, borderRadius: "50%"}}/>
        </div>
    );
});


export const ThrowSignalEventContent = observer((props) => {
    const {width, height, borderRadius} = props.styleDefault;
    return (
        <div style={{width: width, height: height, borderRadius: "50%"}}>
            <img src={ICON_BLEACH} alt={"ThrowSignal"}
                 style={{width: width, height: height, borderRadius: "50%", paddingBottom: 4}}/>
            <div style={{position: "absolute", top: 0, height: height, width: width, borderRadius: "50%"}}/>
        </div>
    );
});

export const CatchSignalEventContent = observer((props) => {
    const {width, height, borderRadius} = props.styleDefault;
    return (
        <div style={{width: width, height: height, borderRadius: "50%"}}>
            <img src={ICON_SIGNAL} alt={"Signal"}
                 style={{
                     height: height,
                     width: width,
                     boxShadow: "0px 0px 0px 1px black inset",
                     borderRadius: "50%",
                     transform: 'rotate(180deg)',
                     paddingTop: 4
                 }}/>
            <div style={{position: "absolute", top: 0, height: height, width: width, borderRadius: "50%"}}/>
        </div>
    );
});

export const CompensationThrowingEventContent = observer((props) => {
    const {width, height, borderRadius} = props.styleDefault;
    return (
        <div style={{width: width, height: height, borderRadius: "50%"}}>
            <img src={ICON_THROW_COMPENSATION} alt={"Compensation"}
                 style={{
                     height: height,
                     width: width,
                     boxShadow: "0px 0px 0px 1px black inset",
                     borderRadius: "50%",
                     padding: 2,
                     paddingLeft: 0
                 }}/>
            <div style={{position: "absolute", top: 0, height: height, width: width, borderRadius: "50%"}}/>
        </div>
    );
});

export const NoneThrowingEventContent = observer((props) => {
    const {width, height, borderRadius} = props.styleDefault;
    return (
        <div style={{width: width, height: height, borderRadius: "50%"}}>
            <div style={{
                height: height - 6,
                width: width - 6,
                boxShadow: "0px 0px 0px 1px black inset",
                borderRadius: "50%",
                marginTop: 3,
                marginLeft: 3,
            }}/>
            <div style={{position: "absolute", top: 0, height: height, width: width, borderRadius: "50%"}}/>
        </div>
    );
});