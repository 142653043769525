import { registerBundle } from "@isf/core-localization";
registerBundle({
  code: 'ui',
  load: (locale) => import(`./messages/${locale}.json`)
}, true);

export { default as ICON_MULTI } from "./images/de9280c31fc448bcf557ef578f097f6f.svg";
export { default as ICON_EDIT } from "./images/49b4715dc92980562dd9873e90751b97.svg";
export { default as ICON_DELETE } from "./images/756d05cd38f091c47fe3bc6331e4f8e9.svg";
export { default as ICON_CROSS } from "./images/bc13e4d48e03723b2e2f26b40a1e4821.svg";
export { default as ICON_ARROW_RIGHT } from "./images/33c3f1302ed9bd8dac783b2ccbe910bc.svg";
export { default as ICON_SORT_UP }from "./images/22c0b25f37303bddf85ad11433946c5a.svg";
export { default as ICON_SORT_DOWN }from "./images/48a61ac59303bd71c2b7d403d5c87617.svg";
export { default as ICON_TOGGLE_DROPDOWN } from "./images/2df3389de5a1c9a6dd92676aa5613d56.svg";
export { default as ICON_ARROW_COLLAPSED } from "./images/23150cd0c39dd97cc1da36c46d9c84f1.svg";
export { default as ICON_ROTATE_RIGHT } from "./images/01dd0e4e438eef576757511eb1fcb1d9.svg";
export { default as ICON_ROTATE_LEFT } from "./images/b17b60bb4e7c2122ebabd889f59d6260.svg";
export { default as ICON_CYCLE } from "./images/7b9b6b327870130d5dc87a95b614bc3c.svg";
export { default as ICON_SETTINGS } from "./images/6df8c55f92c7ce25db7d1a4c4522faed.svg";
export { default as ICON_SEARCH } from "./images/be1f2f365cdef7b5bfb29199d1a37def.svg";
export { default as ICON_MAIL } from "./images/0a6d4ed2b0268d030ec40876f0b31654.svg";
export { default as ICON_BELL } from "./images/42958c6f56af0432c7daee8670ad0249.svg";
export { default as ICON_DESIGN_THEME } from "./images/e4a82ea8f5167f3133b28992cc509e29.svg";
export { default as ICON_USER } from "./images/d0d8c76e260e4450c3ba1c094aad7d11.svg";
export { default as ICON_USER_CIRCLE } from "./images/d3b6be84352474a4419bf555262c681d.svg";
export { default as ICON_ACCEPT } from "./images/80bf7ce6d566b8857df171bcccc2d0ac.svg"
export { default as ICON_FILTER } from "./images/6e069f74b8556a5fc9e26d65107af5c0.svg";
export { default as ICON_FILTER_COLOR } from "./images/6a1e9ed12d8197d970d173b94bc247d2.svg";
export { default as ICON_TIMER } from "./images/9e76c0c9472b245f91bc60e004df6766.svg";
export { default as ICON_SCRIPT } from "./images/a485a72d0678401518527ca2363cbf86.svg";
export { default as ICON_FILE_ATTACH } from "./images/546a7b080de1e5126b19d0ce08ab09a8.svg";
export { default as ICON_FILE_DOWNLOAD } from "./images/0c85c17e061206702274c98468b77802.svg";
export { default as ICON_FILE_SIGN } from "./images/fe0b9816c68b0dd60112fda3b951ff4a.svg";
export { default as ICON_FILE_DELETE } from "./images/e686d25befedc1a04c3ed995a550dbdb.svg";
export { default as ICON_SELECT_IMAGE } from "./images/ff8d2153e34bc4a371b7d76c87cb85f2.svg";
export { default as ICON_DEFAULT_IMAGE } from "./images/2b5df0cb7d09b464eca07c86d81b0183.svg";
export { default as GIF_LOADING } from "./images/loading.gif";
export { default as LOGO_FULL } from "./images/54717e3bbe2b2d2a824bad39126a5bdd.svg";
export { default as LOGO_SHORT } from "./images/157189e5c8aebe17303ff2aa796ceb6b.svg";
export { default as ICON_BLEACH} from "./images/eb9812975eb1a0bbb4e321fd7d76c989.svg";
export { default as ICON_THROW_COMPENSATION} from "./images/c5f752d45c8a6ca1bcda4437586d410c.svg";
export {default as ICON_ADD_COMPONENT} from './images/cb2c747851fb3ff1aae837b660f6c516.svg';
export { default as ICON_ADD_SQUARE } from "./images/d2d2ac011d80fa8c3334ebcb608a340b.svg";
export { default as ICON_MINUS_SQUARE } from "./images/55b9803b391674282cb9ee52d20357f7.svg";
export { default as ICON_CROSS_CIRCLE } from "./images/b3093a09c399743a5113af7b7816c605.svg";
export { default as ICON_ERROR } from "./images/d9e67d112e7919d16f9e574d9b3cfbd1.svg";
export { default as ICON_MESSAGE } from "./images/c4f34545cf545c9ecf6f8433b24f2485.svg";
export { default as ICON_REWIND } from "./images/1e75cdb401df1d7b080f5070d8f58791.svg";
export { default as ICON_SIGNAL } from "./images/63254af46af47c89df49a2499f0bfd13.svg";
export { default as ICON_SAD_SMILE } from "./images/sad.svg";
export { default as ICON_HAPPY_SMILE } from "./images/smile.svg";
export { default as ICON_NEUTRAL_SMILE } from "./images/confused.svg";
export { default as ICON_SHIELD } from "./images/shield.svg";
export { default as ICON_GET_GPS} from "./images/d3b0f2205939b841a6e026bd2d79c459.svg";
export { default as ICON_GET_GPS_COLOR} from "./images/4db02d90d439a4b8972053fd4cc69495.svg";
export { default as ICON_GPS} from "./images/b90a9d5a1636c3b8ea9a5bf625f2e690.svg";
export { default as ICON_FULL_SCREEN } from "./images/e68959aac5f24bc777919acc2a2dcd60.svg";
export { default as ICON_SEARCH_MARKER } from "./images/b556cbed68f7c27b4a63489b026c4483.svg";
export { default as ICON_EXPAND } from "./images/55b79d5eef856f7d7cf369fd1ba3b840.svg";
export { default as ICON_EXIT_EXPAND } from "./images/71d2ab6b61c1aeaa227a8ca48457b16f.svg";
export { default as ICON_MODULE } from "./images/f6b46d259752036dd8f61ae4cce2ff0f.svg";
export { default as ICON_CHECK_LOGO } from "./images/check_logo.svg";
export { default as ICON_FILE_CSV } from "./images/60bc59a2bc146fedaecb62bdb67c2133.svg";
export { default as ICON_FILE_XLSX } from "./images/13f5b900fecdadbf6e4a005c3e0a6733.svg";
export { default as ICON_FILE_PDF } from "./images/dc431f2b85088b9b6c4c9fae54a70d75.svg";
export { default as ICON_RHOMBUS } from "./images/77e30ce3828e67b453b943b72296197f.svg";
export { default as ICON_CIRCLE } from "./images/a8e1da4a17f8207ffce8279794502bb1.svg";
export { default as ICON_DASH } from "./images/39fe45a55620b3c489b3b023046e4e49.svg";
export { default as ICON_TRIANGLE } from "./images/eba643c7e22f2c5439d6fedb2a8b6ff6.svg";
export { default as ICON_STAR } from "./images/9f8287e59ce24c142cc54f5d86943da5.svg";
export { default as ICON_CHECK } from "./images/841bca33-ea54-403f-9e8f-3159ec977ba3.svg";
