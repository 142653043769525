import $ from "@isf/core-object-util";

const getErrorMessage = (error, localeStore, status) => {
  const userMessage = status === 403 ? error : $.get(error, 'userMessage');
  if(userMessage){
    const currentLocale = localeStore.locale;
    const message = $.get(userMessage, 'message');
    if(message){
      const value = $.get(message,currentLocale);
      if(value){
        return value;
      }
    }

  }
  return $.get(error, 'message')
};

const getHeader = (response, header) => {
  return response.headers.get(header);
};

export {getErrorMessage, getHeader};