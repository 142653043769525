import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import SelectServiceTable from "./select-service-table";
import { injectIntl } from "@isf/localization";

@injectIntl
@observer
class SelectServiceView extends Component {

  render() {
    const { serviceTableStore } = this.props.store;
    const { columns, intl } = this.props;

    return (
      <Fragment>
        <SelectServiceTable
          serviceTableStore={serviceTableStore}
          columns={columns}
          intl={intl}
        />
      </Fragment>
    );

  }
}

export default SelectServiceView;