import $ from "../../../../core/object-util/src/object-util";
import {autorun} from "mobx";
import {localeStore} from "@isf/core-localization";
import {getDeclaredString} from "../../../../ui-schema/src/expression/utils";
import appStore from "@isf/core-app-store";

export const loadExternalDependencies = async (props) => {
    const {externalDependencies, externalActions, data, children, externalContext, dataStoreObj} = props;
    if (externalDependencies && externalActions && data.length !== 0) {
        for (let action of externalActions) {
            const {actionName, func} = action;
            const dependencyObj = getActionDependency(actionName, externalDependencies);
            const dependencyId = $.get(dependencyObj, 'id');
            const dependency = $.get(dependencyObj, 'dependency');
            const dependentChild = getDependentChild(children, dependencyId);
            if(dependentChild) {
                const computedSchema = $.get(dependentChild, 'props.computed');
                const searchedValues = getSearchedValues(computedSchema, data, externalContext, dataStoreObj);
                if (searchedValues.length) {
                    createRequest(dependency, searchedValues);
                    await func();
                }
            }
        }
    }
}

const createRequest = (dependency, values) =>{
    const requestStoreName = $.get(dependency, 'requestStore');
    const searchedOption = $.get(dependency, 'searchedProp');
    const requestStore = appStore.getDataStore(requestStoreName);
    requestStore.set('body.filter',{[searchedOption]:[{operation:'in', value:values}]});
};

export const getActionDependency = (actionName, externalDependencies) => {
    for (let id in externalDependencies) {
        const dependency = $.get(externalDependencies, id + "") || externalDependencies.get(id);
        if (actionName === $.get(dependency, 'actionName')) {
            return {id: id, dependency: dependency};
        }
    }
};

export const getDependentChild = (children, dependencyId) => {
    if ($.isArray(children)) {
        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            const childDependent = _getDependentChild(child, dependencyId);
            // if ($.get(child, 'props.external.id') && $.get(child, 'props.external.id') === dependencyId) {
            //     return child
            // }
            // if ($.get(child, 'props.children')) {
            //     const result = getDependentChild($.get(child, 'props.children'), dependencyId);
            //     if (result)
            //         return result;
            // }
            if (childDependent) {
                return childDependent
            }
        }
    } else if (typeof children === "function") {
        return getDependentChild(children(), dependencyId);

    } if ($.isObject(children)) {
        return _getDependentChild(children, dependencyId);

    }
};

const _getDependentChild = (children, dependencyId) => {
    if ($.get(children, 'props.external.id') && $.get(children, 'props.external.id') === dependencyId) {
        return children
    }
    if ($.get(children, 'props.children')) {
        const result = getDependentChild($.get(children, 'props.children'), dependencyId);
        if (result)
            return result;
    }
};

const getSearchedValues = (computedSchema, data, externalContext, dataStoreObj) => {
    const resultValues = [];
    data.map((el,i) => {
        const computedValue = getComputedValue(computedSchema,externalContext, {store: dataStoreObj.store, index:i});
        if(resultValues.indexOf(computedValue) === -1 && computedValue !== undefined && computedValue !== null){
            resultValues.push(computedValue);
        }
    });
    return resultValues;
};

const getComputedValue = (computedSchema, externalContext, dataObj ) => {
    const {params, expression} = computedSchema;
    if (params && params.length !== 0) {
        let declaredString = "";
        declaredString = getDeclaredString(params, localeStore, dataObj, externalContext);
        return declaredString !== "let;" && eval(declaredString + expression);
    } else {
        return  eval(expression);
    }
};
