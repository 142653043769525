import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import { injectIntl } from "@isf/localization";
import { useInputHandler } from "@isf/react-util";
import { Container, Row } from "@isf/bootstrap";
import { Pagination, PaginationSize } from "@isf/pagination";
import { ServiceTableFilters } from "./service-table-filters";
import { ServicesTable } from "./services-table";
import { ServicesTableStore } from "./services-table-store";
import { ServicesTableRequestStore } from "./services-table-request-store";
import { defaultRequestState } from "./service-table-constants";

@injectIntl
@observer
class ServicesView extends Component {
  constructor(props) {
    super(props);
    this.path = ["services", "table"];

    this.requestStore = props.requestStore || new ServicesTableRequestStore({
      path: [...this.path, "request"],
      observableAsMap: true,
      state: defaultRequestState()
    });

    this.dataStore = props.dataStore || new ServicesTableStore({
      path: [...this.path, "response"],
      requestStore: this.requestStore
    });

    this.useHandler = useInputHandler({store: this.requestStore});
  }

  render() {
    const { intl, store, currentServiceHandler } = this.props;
    const handler = this.useHandler();
    const paginationOnClick = this.dataStore.load.bind(this.dataStore);

    return (
      <Fragment>
        <ServiceTableFilters intl={intl}
                             path={this.path}
                             useHandler={this.useHandler}
                             requestStore={this.requestStore}
                             paginationOnClick={paginationOnClick}
                             currentServiceHandler={currentServiceHandler} />
        <ServicesTable store={store} dataStore={this.dataStore} choice={true} />
        <Container>
          <Row className={"justify-content-between"}>
            <Pagination enumerable={true} handler={handler.handler} onClick={paginationOnClick} />
            <PaginationSize handler={handler.handler} onChange={paginationOnClick} />
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export {ServicesView};