import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {inject, observer, Provider} from "mobx-react";
import classNames from "classnames";
import {uuidv4} from "@isf/core-system-util";
import $ from "@isf/core-object-util";
import {loadExternalDependencies} from "./table-common-actions";
import {ListContext} from "../../../list-context/list-context"

@inject("dataStoreObj", "store")
@observer
class TableBody extends Component {
    constructor(props) {
        super(props);
        if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    }

    componentWillUnmount() {
        this.uiBaseStore && this.uiBaseStore.dispose();
    }

    componentDidMount() {
        const {store} = this.props;
        const data = this.getData();
        store.setPageRows(data);
    }

    componentDidUpdate() {
        const {store} = this.props;
        const data = this.getData();
        store.setPageRows(data);
        loadExternalDependencies({data:data,...this.props,});
    }

    getData() {
        const {store, dataStoreObj} = this.props;
        const accessor = dataStoreObj.index || [];
        return dataStoreObj.store && dataStoreObj.store.get(accessor)
            ? dataStoreObj.store.get(accessor)
            : store.data;
    }

    render() {
        const {children, className, store, dataStore, dataStoreObj, intl, id, externalActions, externalDependencies,
            externalContext, localeStore, languages, computedClassName, uiBaseStore, refId, ...other} = this.props;
        // const data = (dataStore && dataStore.state ? dataStore.state : store.data); //TODOno such data
        const data = this.getData();
        //TODO change
        const newId = id ? id : uuidv4();
        const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
          && this.uiBaseStore.classNameStore.getClassNames();

        const child = Array.isArray(children) ? children[0] : children;

        return (
     /*       <TableContext.Consumer>
                {({externalDependencies}) => {
                    return (*/
                        <tbody className={classNames(className, computedClassNames)} {...other}>
                        {$.isArray(data) && dataStoreObj.store && child ? //TODO create constructor variable && remove dataStore
                            data.map((item, i) => {
                                const dataRow = {
                                    store: dataStoreObj.store,//dataStore,
                                    index: dataStoreObj.index ? dataStoreObj.index + "." + i : i
                                };

                                return (
                                    <React.Fragment key={i}>
                                        <ListContext.Provider value={dataRow}>
                                            {React.cloneElement(child, {
                                                // ...props,
                                                item: item,
                                                rowIndex: i,
                                                rowId: newId + '.row.' + i,
                                                key: i,
                                                data: dataRow
                                            })}
                                        </ListContext.Provider>
                                    </React.Fragment>
                                );
                            }) : children
                        }
                        </tbody>
             /*       );
                }}
            </TableContext.Consumer>*/
        );

    }
};




TableBody.propTypes = {
    id: PropTypes.string,
    // store: PropTypes.object.isRequired,
    // columns: PropTypes.arrayOf(PropTypes.object).isRequired
};

export {TableBody};
