import $ from "@isf/core-object-util";
// import {restResponseHandler} from "./rest-response-handler/rest-response-handler";

const getRequestObj = (contextPath, id) => ({
  path: contextPath + "/" + "file/attach/" + id,
  options: {
    method: "put",
  },
});

const errorHandler = async ({request}, responseInfo, response) => {
  response = await response;

  if (!responseInfo.ok) {
    console.log("failed to attach file", response);
  }
};

function fileAttach(fileApi, store, path) {
  const fileAccessors = $.keys(store.stateFiles);

  fileAccessors.forEach(accessor => {
    if(store.getFileAccessor(accessor)){
      const id = store.get(accessor + ".id");
      const request = getRequestObj(path, id);
      const context = {request};

      fileApi.call(request, context, errorHandler)
        .catch(error => console.log("Attach file error", error));
    }
  });
}

export {fileAttach};