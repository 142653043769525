import React, {Component} from "react";
import {observer, Provider} from "mobx-react";
import classNames from "classnames";
import PageTemplateStore from "./page-template-store";

@observer
class PageTemplate extends Component {
  constructor(props) {
    super(props);
    this.store = new PageTemplateStore();
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {children, className} = this.props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <Provider store={this.store}>
        <div className={classNames('page-template', className, computedClassNames)}>
          {children}
        </div>
      </Provider>
    )
  }
}

export default PageTemplate;
