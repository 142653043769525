import React, {Component} from "react";
import {Container} from "../../bootstrap";
import {observer} from "mobx-react";
import classNames from "classnames";

@observer
class PageContent extends Component {
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {children, className} = this.props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <Container
        className={classNames("pt-3 flex-grow-1 page-content", className, computedClassNames)}
        fluid
      >
        {children}
      </Container>
    );
  }
}

export default PageContent;
