import $ from "@isf/core-object-util";

export const PARAMETERS = ['path', 'query', 'header', 'body'];
export const OPERATIONS = ['get', 'put', 'post', 'delete', 'options', 'head', 'patch', 'trace'];
export const PRIMITIVES = ["string", "integer", "number", "boolean"];
const LEVEL_REF = 10;
export const checkLevelRef  = (level) => {
  if (level > LEVEL_REF) throw `ref level ( > ${LEVEL_REF}) is too complicated`;
};

const getApiObject = (properties = {}) => ({
  "type": "object",
  properties
});

const copyObj = (object) => {
  if($.isArray(object)) {
    return object.slice();
  }

  let newObj = {};
  if ($.isObservableMap(object)) {
    object.forEach((value, key) => {
      newObj[key] = value;
    });
  } else {
    newObj = {...object};
  }

  return newObj;
};

const observableForEach = (obj, func) => {
  if ($.isObservableMap(obj)) {
    obj.forEach((value, key) => {
      func(value, key);
    })
  } else {
    for (let key in obj) {
      func(obj[key], key);
    }
  }
};

function getRefWithContextPath(obj, apiSchema, servicesStore, level = 0) {
  checkLevelRef(level);
  let refArr = [];
  if (!$.isObject(obj)) return [];

  let newLevel = level;
  const ref = $.get(obj, '$ref');
  if (typeof ref === "string") {
    let [serviceContextPath, pathToSchema] = ref.split("#");

    if(serviceContextPath && !isUploadService(serviceContextPath, "contextPath", servicesStore)) {
      return [ref];

    } else if(serviceContextPath) {
      const service = servicesStore.state.find(el => $.get(el, "contextPath") === serviceContextPath);
      const refSchema = pathToSchema
        ? $.get(service, ['api', 'schema', ...pathToSchema.replace(/^\//, "").split("/")])
        : $.get(service, 'modelSchema.modelSchema');
      newLevel++;

      return getRefWithContextPath(refSchema,  $.get(service, 'api.schema'), servicesStore, newLevel);

    } else {
      let path = ref.replace('#/', '');
      path = path.split('/');
      const refSchema = $.get(apiSchema, path);
      newLevel++;
      return getRefWithContextPath(refSchema, apiSchema, servicesStore, newLevel);
    }

  }

  if ($.isObject(obj)) {
    const keys = $.keys(obj);
    for (let key of keys) {
      const value = $.get(obj, key + "");

      if (isContainsRef(value)) {
        const arr = getRefWithContextPath(value, apiSchema, servicesStore, newLevel);
        refArr = mergeArray(refArr, arr, (el) => !refArr.includes(el));
      }
    }
  }

  return refArr;
}

function isUploadService(value, propName, servicesStore) {
  return !!servicesStore.state.find(obj => $.get(obj, propName) === value);
}

function mergeArray(firstArr, secondArr, filter) {
  const result = [...firstArr];

  secondArr.forEach(el => {
    if (filter(el)) {
      result.push(el)
    }
  });
  return result;
}

const getRequestRef = (path, method, apiSchema, servicesStore) => {
  if (!path || !method) return [];
  const defaultParameters = $.get(apiSchema, ['paths', path, 'parameters']) || [];
  const methodParameters = $.get(apiSchema, ['paths', path, method, 'parameters']) || [];
  const body = $.get(apiSchema, ['paths', path, method, 'requestBody']) || {};

  const obj = {
    defaultParameters,
    methodParameters,
    body
  };

  return getRefWithContextPath(obj, apiSchema, servicesStore);
};

const getResponseRef = (path, method, apiSchema, servicesStore) => {
  if (!path || !method) return [];
  const responses = $.get(apiSchema, ['paths', path, method, 'responses']);

  return getRefWithContextPath(responses, apiSchema, servicesStore);
};

async function getObjectWithoutRef(obj, apiSchema, servicesStore, listeners) {
  let contextPathArr = getRefWithContextPath(obj, apiSchema, servicesStore);
  let idArr = [], level = 0;

  while(contextPathArr.length > 0) {
    const data = await servicesStore.loadShortServicesInfoByProp(contextPathArr, "contextPath");
    idArr = data.map(info => $.get(info, "id"));
    await servicesStore.loadServicesPropByArrayOfId(idArr, "datasourceBindings");
    await servicesStore.loadServicesPropByArrayOfId(idArr, "modelSchema");
    await servicesStore.loadServicesPropByArrayOfId(idArr, "api");
    contextPathArr = getRefWithContextPath(obj, apiSchema, servicesStore);
    level++;
    checkLevelRef(level);
  }
  return getObjectWithoutRefNotAsync(obj, apiSchema, servicesStore, listeners);
}

async function getObjectWithoutRefOld(obj, apiSchema, servicesStore, listeners, level = 0) {
  checkLevelRef(level);

  if (!$.isObject(obj)) return obj;

  let newObj;
  let newLevel = level;
  if (typeof $.get(obj, '$ref') === "string") {
    const refSchema = await getRefSchema($.get(obj, '$ref'), apiSchema, servicesStore);
    newObj = copyObj(refSchema);
    newLevel++;
  } else {
    newObj = copyObj(obj);
  }

  if (newObj.properties) {

    const properties = newObj.properties;
    newObj.properties = {};

    const keys = $.keys(properties);
    for (let key of keys) {
      const value = $.get(properties, key);
      $.set(newObj.properties, key, value);

      while (isContainsRef($.get(newObj.properties, key))) {
        const objWithoutRef = await getObjectWithoutRefOld(value, apiSchema, servicesStore, [], newLevel);
        $.set(newObj.properties, key, objWithoutRef);
      }
    }
  } else if (typeof newObj === "object") {
    const keys = $.keys(newObj);
    for (let key of keys) {
      const value = $.get(newObj, key);
      $.set(newObj, key, value);

      while (isContainsRef($.get(newObj, key))) {
        const objWithoutRef = await getObjectWithoutRefOld(value, apiSchema, servicesStore, [], newLevel);
        $.set(newObj, key, objWithoutRef);
      }
    }
  }

  if (isContainsRef(newObj)) {
    newObj = await getObjectWithoutRefOld(newObj, apiSchema, servicesStore, [], newLevel);
  }

  Array.isArray(listeners) && listeners.forEach(listener => listener(newObj));
  return newObj
}

function isContainsRef(schema) {
  if (typeof schema !== "object") return false;
  if (typeof $.get(schema, '$ref') === "string") return true;

  const arrFlag = [];
  if ($.get(schema, 'properties')) {
    const properties = $.get(schema, 'properties');

    observableForEach(properties, (value, key) => {
      arrFlag.push(isContainsRef(value));
    })

  }
    // else if ($.get(schema, 'items.properties')) {
    //   const properties = $.get(schema, 'items.properties');
    //
    //   observableForEach(properties, (value, key) => {
    //     arrFlag.push(isContainsRef(value));
    //   })
    //
  // }
  else if (typeof schema === "object") {
    observableForEach(schema, (value, key) => {
      arrFlag.push(isContainsRef(value));
    })
  }

  return !!arrFlag.find(el => el === true);
}

const getRefSchema = async (ref, apiSchema, servicesStore) => {
  if (ref.startsWith('#/')) {
    let path = ref.replace('#/', '');
    path = path.split('/');

    return $.get(apiSchema, path);
  }

  let [serviceContextPath, pathToSchema] = ref.split("#");
  // const service = services.find(el => $.get(el, 'contextPath') === ref) || {};
  let service = servicesStore.state.find(el => $.get(el, 'contextPath') === serviceContextPath);
  if (!service) {
    service = await servicesStore.loadServiceByContextPath(serviceContextPath);
  }

  if (!service) {
    service = {};
    console.error(`${ref} service does not exist`);
  }
  return pathToSchema
    ? $.get(service, ['api', 'schema', ...pathToSchema.replace(/^\//, "").split("/")])
    : $.get(service, 'modelSchema.modelSchema');
};

const isIntegerNumber = (number) => {
  const type = typeof  number;
  if(type === "number") {
    return (number | 0) === number;

  } else if(type === "string") {
    let isInt = parseFloat(number);
    return !isNaN(number) && (isInt | 0) === isInt;
  }

  return false;
};

const getSuccessfulCode = (schema) => {
  schema = $.isObject(schema) ? schema : {};
  const codes = $.keys(schema);

  return codes.find(code => {
    if(isIntegerNumber(code) && code > 199 && code < 300) {
      return true;
    }
  });
};

const getContentTypes = (schema) => {
  if (!$.isObject(schema)) return [];

  return $.keys(schema)
};

const getContentTypeSchema = (data, contentType) => {
  if (!$.isObject(data)) return data;

  let appJson = {};

  if(contentType) {
    observableForEach(data, (value, key) => {
      if (key.startsWith(contentType)) {
        appJson = value;
      }
    });

  } else {
    const keys = $.keys(data);
    if(keys.length > 0) {
      appJson = $.get(data, [keys[0]]);
    }
  }

  return $.get(appJson, 'schema') ? $.get(appJson, 'schema') : appJson;
};

const getSchemaOpenApiChild = (data, newPath) => {
  newPath = $.strToArray(newPath);
  let child = data, property;

  for (let i = 0; i < newPath.length; i++) {

    property = getSchemaOpenApiProp(child);
    if (property) {
      child = $.get(property, [newPath[i]]);
    }
  }
  return child;
};

const getSchemaOpenApiProp = (parent) => {
  if ($.get(parent, 'properties')) {
    return $.get(parent, 'properties');
  }
  if ($.get(parent, 'items')) {
    const items = $.get(parent, 'items');
    if ($.get(items, 'properties')) {
      return $.get(items, 'properties');
    }
  }
};

const getSchemaOpenApiChildItems = (data, newPath) => {
  newPath = $.strToArray(newPath);
  let child = data, property;

  for (let i = 0; i < newPath.length; i++) {
    if (newPath[i] === 'items' && $.get(child, 'items')) {
      child = $.get(child, 'items');
      continue;
    }

    if ($.get(child, 'properties')) {
      property = $.get(child, 'properties');
    }

    if (property) {
      child = $.get(property, [newPath[i]]);
    }

  }
  return child;
};

const transformPath = (paths) => {
  const arrPath = [];

  observableForEach(paths, (pathObj, path) => {
    observableForEach(pathObj, (methodObj, method) => {
      if (OPERATIONS.includes(method)) {
        arrPath.push({
          id: `${path}.${method}`,
          path,
          method,
          description: $.get(methodObj, 'description'),
        });
      }
    });
  });

  return arrPath;
};

const getCounterListeners = (maxCounter, listeners) => {
  let counter = 0;
  return [
    (obj) => {
      counter++;
      if (counter === maxCounter) {
        Array.isArray(listeners) && listeners.forEach(listener => listener(obj));
      }
    }
  ];
};

const getResponseCodeSchema = (requestSchema) => {
  const requestBodySchema = getContentTypeSchema($.get(requestSchema, 'content')/*, "application/json"*/);
  let codeSchema = undefined;

  if ($.isObject(requestBodySchema)) {
    codeSchema = getApiObject({
      content: getApiObject({body: requestBodySchema})
    });
  }

  if ($.get(requestSchema, 'headers')) {
    const headers = $.get(requestSchema, 'headers') || {};
    const headersName = $.keys(headers);
    let headersSchema;

    for (let key of headersName) {
      if(!headersSchema) {
        headersSchema = getApiObject();
      }

      const schema = $.get(headers, [key, 'schema']);
      if($.isObject(schema)) {
        headersSchema.properties[key] = schema;
      }
    }

    if(headersSchema) {
      if(!codeSchema) {
        codeSchema = getApiObject();
      }

      codeSchema.properties["headers"] = headersSchema;
    }
  }

  return codeSchema;
};

const transformResponse = (responses) => {
  if (!$.isObject(responses)) {
    return {};
  }

  const result = getApiObject();
  const keys = $.keys(responses);
  for (let code of keys) {
    const response = $.get(responses, code);
    const codeSchema = getResponseCodeSchema(response);

    if(codeSchema) {
      result.properties[code] = codeSchema;
    }
  }

  return result;
};

const transformParameters = (pathParam, operationParam, requestBody) => {
  const parameters = {};
  PARAMETERS.forEach(el => parameters[el] = getApiObject());

  for (let parameter of operationParam) {
    const itemIn = $.get(parameter, 'in');
    const itemName = $.get(parameter, 'name');
    const schema = $.get(parameter, 'schema');

    if($.isObject(schema)) {
      parameters[itemIn].properties[itemName] = $.get(parameter, 'schema');
    }
  }

  for (let parameter of pathParam) {
    const itemIn = $.get(parameter, 'in');
    const itemName = $.get(parameter, 'name');
    const schema = $.get(parameter, 'schema');

    if (!parameters[itemIn].properties[itemName] && $.isObject(schema)) {
      parameters[itemIn].properties[itemName] = schema;
    }
  }

  PARAMETERS.forEach(el => {
    if (Object.keys(parameters[el].properties).length < 1)
      delete parameters[el]
  });

  if ($.get(requestBody, 'content')) {
    let bodySchema = getContentTypeSchema($.get(requestBody, 'content')/*, "application/json"*/);
    bodySchema = $.isObject(bodySchema) ? bodySchema : undefined;
    if (bodySchema) {
      parameters.body = bodySchema;
    }
  }

  return getApiObject(parameters);
};

const getRequestContentType = async (path, method, apiSchema, servicesStore) => {
  if (!path || !method) return;
  let body = $.get(apiSchema, ['paths', path, method, 'requestBody']);
  body = await getObjectWithoutRef(body, apiSchema, servicesStore);

  const content = $.get(body, 'content');
  const contentTypes = getContentTypes(content);
  return contentTypes.length > 0 ? contentTypes[0] : undefined;
};

const getResponseContentType = async (path, method, apiSchema, servicesStore) => {
  if (!path || !method) return;
  let responses = $.get(apiSchema, ['paths', path, method, 'responses']);
  responses = await getObjectWithoutRef(responses, apiSchema, servicesStore);

  const successfulCode = getSuccessfulCode(responses);
  const content = $.get(responses, [successfulCode, 'content']);
  const contentTypes = getContentTypes(content);
  return contentTypes.length > 0 ? contentTypes[0] : undefined;
};

const getRequestParameters = async (path, method, apiSchema, servicesStore, listeners) => {
  if (!path || !method) return {};
  let defaultParameters = $.get(apiSchema, ['paths', path, 'parameters']),
    methodParameters = $.get(apiSchema, ['paths', path, method, 'parameters']),
    body = $.get(apiSchema, ['paths', path, method, 'requestBody']);

  defaultParameters = await getObjectWithoutRef(defaultParameters, apiSchema, servicesStore);
  methodParameters = await getObjectWithoutRef(methodParameters, apiSchema, servicesStore);
  body = await getObjectWithoutRef(body, apiSchema, servicesStore);

  defaultParameters = $.isArray(defaultParameters) ? defaultParameters : [];
  methodParameters = $.isArray(methodParameters) ? methodParameters : [];
  body = $.isObject(body) ? body : {};

  const schema = transformParameters(defaultParameters, methodParameters, body);
  Array.isArray(listeners) && listeners.forEach(listener => listener(schema));
  return schema;
};

const getResponse = async (path, method, apiSchema, servicesStore, listeners) => {
  if (!path || !method) return {};
  let responses = $.get(apiSchema, ['paths', path, method, 'responses']);
  responses = await getObjectWithoutRef(responses, apiSchema, servicesStore);

  const schema = transformResponse(responses);
  Array.isArray(listeners) && listeners.forEach(listener => listener(schema));
  return schema;
};

const getParameterOpenApi = (name, inValue, required, schema, description) => ({
  name,
  in: inValue,
  description,
  required,
  schema,
});

const getRefSchemaNotAsync = (ref, apiSchema, servicesStore) => {
  if (ref.startsWith('#/')) {
    let path = ref.replace('#/', '');
    path = path.split('/');

    return $.get(apiSchema, path);
  }

  let [serviceContextPath, pathToSchema] = ref.split("#");
  // const service = services.find(el => $.get(el, 'contextPath') === ref) || {};
  let service = servicesStore.state.find(el => $.get(el, 'contextPath') === serviceContextPath);
  if (!service) {
    return {};
  }
  return pathToSchema
    ? $.get(service, ['api', 'schema', ...pathToSchema.replace(/^\//, "").split("/")])
    : $.get(service, 'modelSchema.modelSchema');
};

function getObjectWithoutRefNotAsync(obj, apiSchema, servicesStore, level = 0) {
  checkLevelRef(level);
  if (!$.isObject(obj)) return obj;

  let newObj;
  let newLevel = level;
  if (typeof $.get(obj, '$ref') === "string") {
    const refSchema = getRefSchemaNotAsync($.get(obj, '$ref'), apiSchema, servicesStore);
    newObj = copyObj(refSchema);
    newLevel++;
  } else {
    newObj = copyObj(obj);
  }

  if (newObj.properties) {

    const properties = newObj.properties;
    newObj.properties = {};

    const keys = $.keys(properties);
    for (let key of keys) {
      key += "";
      const value = $.get(properties, key);
      $.set(newObj.properties, key, value);

      while (isContainsRef($.get(newObj.properties, key))) {
        const objWithoutRef = getObjectWithoutRefNotAsync(value, apiSchema, servicesStore, [], newLevel);
        $.set(newObj.properties, key, objWithoutRef);
      }
    }
  } else if ($.isObject(newObj)) {
    const keys = $.keys(newObj);
    for (let key of keys) {
      key += "";
      const value = $.get(newObj, key);
      $.set(newObj, key, value);

      while (isContainsRef($.get(newObj, key))) {
        const objWithoutRef = getObjectWithoutRefNotAsync(value, apiSchema, servicesStore, [], newLevel);
        $.set(newObj, key, objWithoutRef);
      }
    }
  }

  if (isContainsRef(newObj)) {
    newObj = getObjectWithoutRefNotAsync(newObj, apiSchema, servicesStore, [], newLevel);
  }

  return newObj
}

const getRequestParametersNotAsync = (path, method, apiSchema, servicesStore) => {
  if (!path || !method) return {};
  let defaultParameters = $.get(apiSchema, ['paths', path, 'parameters']),
    methodParameters = $.get(apiSchema, ['paths', path, method, 'parameters']),
    body = $.get(apiSchema, ['paths', path, method, 'requestBody']) || {};

  defaultParameters = getObjectWithoutRefNotAsync(defaultParameters, apiSchema, servicesStore);
  methodParameters = getObjectWithoutRefNotAsync(methodParameters, apiSchema, servicesStore);
  body = getObjectWithoutRefNotAsync(body, apiSchema, servicesStore);

  defaultParameters = $.isArray(defaultParameters) ? defaultParameters : [];
  methodParameters = $.isArray(methodParameters) ? methodParameters : [];
  body = $.isObject(body) ? body : {};

  return transformParameters(defaultParameters, methodParameters, body);
};

const getResponseNotAsync = (path, method, apiSchema, servicesStore) => {
  if (!path || !method) return {};
  let responses = $.get(apiSchema, ['paths', path, method, 'responses']);
  responses = getObjectWithoutRefNotAsync(responses, apiSchema, servicesStore);

  return transformResponse(responses);
};

const pathParameter = (name, description, schema = {type: "string"}) =>
  getParameterOpenApi(name, "path", true, schema, description);


const requestBodySchema = (schema = {}, contentType = "application/json") => ({
  "content": {
    [contentType]: {
      schema,
    }
  }
});

const responsesSchemas = () => ({
  "NotFound": {
    "content": {
      "application/json": {
        "schema": {
          "required": [
            "code",
            "message"
          ],
          "type": "object",
          "properties": {
            "code": {
              "type": "string"
            },
            "message": {
              "type": "string"
            }
          }
        }
      }
    },
    "description": "Не найден"
  },
  "Unauthorized": {
    "content": {
      "application/json": {
        "schema": {
          "required": [
            "code",
            "message"
          ],
          "type": "object",
          "properties": {
            "code": {
              "type": "string"
            },
            "message": {
              "type": "string"
            }
          }
        }
      }
    },
    "description": "Неавторизованно"
  },
  "UnprocessableEntity": {
    "content": {
      "application/json": {
        "schema": {
          "type": "array",
          "items": {
            "required": [
              "code",
              "message"
            ],
            "type": "object",
            "properties": {
              "code": {
                "type": "string"
              },
              "message": {
                "type": "string"
              }
            }
          }
        }
      }
    },
    "description": "Запрос содержит невалидные данные"
  }
});

const getErrorResponse = (ref) => {
  const response = {
    "401": {
      "$ref": "#/components/responses/Unauthorized"
    },
    "404": {
      "$ref": "#/components/responses/NotFound"
    },
    "422": {
      "$ref": "#/components/responses/UnprocessableEntity"
    }
  };

  if(!ref) {
    response["401"] = responsesSchemas()["Unauthorized"];
    response["404"] = responsesSchemas()["NotFound"];
    response["422"] = responsesSchemas()["UnprocessableEntity"];

  }
  return response;
};

const responseParams = (schema, ref = false, contentType = "application/json") => {
  const errorResponse = getErrorResponse(ref);
  if (!schema) {
    return {
      "200": {
        "description": "Успешно",
      },
      ...errorResponse
    };
  }

  return {
    "200": {
      "description": "Успешно",
      "content": {
        [contentType]: {
          schema,
        }
      }
    },
    ...errorResponse
  }
};

export {
  observableForEach, getObjectWithoutRef, getRefSchema, getContentTypeSchema,
  getSchemaOpenApiChild, getSchemaOpenApiProp, getSchemaOpenApiChildItems,
  transformResponse, transformPath, transformParameters, getRequestParameters,
  getResponse, pathParameter, getParameterOpenApi, responseParams, requestBodySchema, copyObj,
  getCounterListeners, responsesSchemas,

  getRefWithContextPath, getRequestRef, getResponseRef,
  isUploadService, mergeArray, getRequestParametersNotAsync,
  getResponseNotAsync, getObjectWithoutRefNotAsync,

  getRequestContentType, getResponseContentType, getApiObject
}
