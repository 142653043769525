import React from 'react';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {BreadcrumbItem as _BreadcrumbItem} from '@isf/bootstrap';

@observer
class BreadcrumbItem extends React.Component{
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {children, tag = 'span', active, className, computedClassName, localeStore, languages, uiBaseStore, ...other
      } = this.props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <_BreadcrumbItem tag={tag} active={active} {...other} className={classNames(className, computedClassNames)}>
        {children}
      </_BreadcrumbItem >
    )
  }
}

export {BreadcrumbItem};
