import React from "react";
import PropTypes from 'prop-types';
import { observer } from "mobx-react";
import classNames from "classnames";

const SectionContent = observer((props) => {
  const { navStore, name, children, className } = props;
  if (!navStore) return [];
  const activated = navStore.wasActivated(name);
  const active = navStore.isActive(name);
  if (!active && !activated) {
    return null;
  }
  return (
    <div className={classNames(className, {"d-none": !active})}>
      {children}
    </div>
  );
});

SectionContent.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  navStore: PropTypes.object
};

export default SectionContent;