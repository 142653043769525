const isDateTimeFormat = (format) => (format === 'date-time' || format === 'date');
const isPasswordFormat = (format) => format === 'password';

const getInputType = (modelElement) => {
   switch (modelElement.type) {
      case 'string':
         if (isDateTimeFormat(modelElement.format)) {
            return { type: modelElement.format.replace(/-/, "") };
         } else if(isPasswordFormat(modelElement.format)) {
            return { type: 'password' };
         } {
            return { type: 'text' };
         }
      case 'boolean': return { type: 'switch' };
      case 'number': return { type: modelElement.type, step: '0.1', format: "float" };
      case 'integer': return { type: 'number', step: '1', format: "integer" };
      default: return { type: 'text' };
   }
};

const getDataType = (type, format) => {
   switch (type) {
      case 'string': return format ? "string." + format : "string";
      case 'boolean': return type;
      case 'number':
      case 'integer': return 'number';
      default: return 'string';
   }
};

const generateFieldPropsByModelSchema = (modelElement) => {
   const input = {
      props: {}
   };

   Object.assign(input, {dataType: getDataType(modelElement.type, modelElement.format)});
   Object.assign(input.props, getInputType(modelElement));
   Object.assign(input, modelElement.format ? {format: modelElement.format.replace('-', '')} : {});
   Object.assign(input.props, modelElement.pattern ? {pattern: modelElement.pattern} : {});
   Object.assign(input.props, modelElement.maxLength ? {maxLength: modelElement.maxLength} : {});
   Object.assign(input.props, modelElement.minLength ? {minLength: modelElement.minLength} : {});
   Object.assign(input.props, modelElement.minimum ? {min: modelElement.minimum} : {});
   Object.assign(input.props, modelElement.maximum ? {max: modelElement.maximum} : {});
   Object.assign(input, modelElement.exclusiveMinimum ? {exclusiveMinimum: modelElement.exclusiveMinimum} : (modelElement.type === 'number' || modelElement.type === 'integer') ? {exclusiveMinimum: false} : {});
   Object.assign(input, modelElement.exclusiveMaximum ? {exclusiveMaximum: modelElement.exclusiveMaximum} : (modelElement.type === 'number' || modelElement.type === 'integer') ? {exclusiveMaximum: false} : {});

   return input;
};

export {generateFieldPropsByModelSchema};