import React from 'react';
import { ICON_EDIT, ICON_DELETE } from "@isf/common-resources";

const DefaultTableActions = (props) => {
  return(
    <td>
      <img className="icon-w-15 h-auto cursor-pointer mr-2" src={ICON_EDIT} alt="Edit" />
      <img className="icon-w-15 h-auto cursor-pointer" src={ICON_DELETE} alt="Delete" />
    </td>
  );
};

export { DefaultTableActions };