import appStore, {UIStore} from "@isf/core-app-store";
import {action, autorun, computed} from "mobx";
import {storeTemplToPath} from "../util";
import $ from "@isf/core-object-util";
import {uuidv4} from "@isf/core-system-util";
import {getListData, getDeclaredString} from "./ui-common-store";
import {UiDisposeStore} from "./ui-dispose-store";
import {getScope} from "../ui-schema-security";


const initialize = (props, id, store) => {
  store._refComponentUIStore = new UIRefComponentUIStore(id, props);
};

class UiRefComponentStore/* extends UIStore */ {

  constructor(props) {
    const id = 'uiRefComponentStore.' + uuidv4();
    /* super({path:id});*/
    this._refId = $.get(props, 'refId');
    initialize(props, id, this)
  }

  _refId;
  _refComponentUIStore;

  @computed
  get ui() {
    return this._refComponentUIStore;
  }

  @computed
  get contextPath() {
    return this._refComponentUIStore.get("contextPath");
  }

  getSecurityScope() {
    const contextPath = this._refComponentUIStore.get("contextPath");
    return contextPath ? getScope("/" + contextPath) : undefined;
  }

  get schema() {
    const globalRefStore = appStore.getDataStore('globalRefsStore');
    return globalRefStore.get('refs.config.' + this._refId);
  }

  dispose(){
    this._refComponentUIStore.dispose();
  }
}

class UIRefComponentUIStore/* extends UIStore */ extends UiDisposeStore{
  constructor(parentId, props) {
    super(props);
    const parentArrPath = parentId.split('.');
    parentArrPath.splice(1, 0, 'ui');
    /* super({path:parentArrPath});*/
    this.localeStore = props.localeStore;
    this.map = $.objectToObservableMap({});
    initializeRefComponentUIAutorun(props, this)
  }

  get(path, pureJS) {
    const val = $.get(this.map, path);
    return pureJS ? $.toJS(val) : val;
  }

  @action
  set(path, value) {
    $.set(this.map, path, value);
  }

  @computed
  get stateJS() {
    return $.toJS(this.map);
  }

  @computed
  get state() {
    return this.map;
  }

}


const initializeRefComponentUIAutorun = (props, store) => {
  const refId = $.get(props, 'refId');
  store.addAutorun(autorun(() => {
    const globalRefStore = appStore.getDataStore('globalRefsStore');
    const contextPath = globalRefStore.get('refs.contextPath.' + refId);
    // const schema = $.get(ref, 'schema');
    store.set('contextPath', contextPath);
  }));
};
export {UiRefComponentStore}