import {action, computed, reaction, observable, observe} from "mobx"
import {getLinkConfig} from "../shapes/link/link-config";
import {schemaConverterToJSON} from "../converters/converterToJSON";
import $ from "@isf/core-object-util"

class SchemaDataStore {
  constructor(props){
    this._schema = props.schema;
  }

  @observable
  _schema;

  _processInstanceId;

  @observable
  _processStatuses;

  _stencilsOfConfShapes = ["UserTask", "ReceiveTask"];

  _stencilsOfGoTo = ["StartNoneEvent", "UserTask", "ReceiveTask", "ServiceTask", "ScriptTask", "Transaction", "SubProcess", "EndNoneEvent"];

  _stencilsOfGateways = ["ParallelGateway", "ExclusiveGateway", "InclusiveGateway"];

  isExecuted(id) {
    if (this.processStatuses && $.get(this.processStatuses,id+'')) {
      return !$.get(this.processStatuses,id+'.isActive')
    }
    return false;
  }

  isActivityActive(id){
    if(this.processStatuses && $.get(this.processStatuses,id+'')) {
      return $.get(this.processStatuses,id+'.isActive');
    }
    return false;
  }

  @action
  setProcessStatuses(statuses) {
    this._processStatuses = statuses;
  }

  @action
  setSchema(schema) {
    this._schema = schema;
  }

  @action
  setBpmnModelExt(extConfigs) {
    this.set('bpmnModelExt', extConfigs)
  }

  @action
  setProcessInstanceId(id) {
    this._processInstanceId = id;
  }

  getExtConfiguration(key) {
    return this.bpmnModelExt.get(key);
  }

  @action
  setExtConfiguration(key, value) {
    this.bpmnModelExt.set(key, value);
  }

  @computed
  get processStatuses() {
    return this._processStatuses;
  }

  @computed
  get processInstanceId() {
    return this._processInstanceId;
  }

  @computed
  get schema() {
    return this._schema;
  }

  @computed
  get bpmnModelExt() {
    return this.get('bpmnModelExt');
  }

  @computed
  get JSONSchema() {
    return schemaConverterToJSON(this.get('bpmnDiagram'), new Map());
  }

  @action
  setElementBpmnConfigExt(config) {
    this.bpmnModelExt.set(config.key, config.value);
  }

  @action
  deleteExtConfiguration(key) {
    if (this.bpmnModelExt.get(key)) {
      this.bpmnModelExt.delete(key);
    }
  }

  findElementInChildren(id, schema, resultId) {
    for (let i = 0; i < $.get(schema,'children').length; i++) {
      const result = this.getElementById(id, $.get(schema,'children')[i], resultId);
      if (result) {
        return result;
      }
    }
  };

  getElementById(id, schema, resultId) {
    if (!schema)
      schema = this.schema;
    if ($.get(schema,'props')) {
      resultId = !resultId ? [] : resultId;
      resultId.push($.get(schema,'props.resourceId'));
      if ($.get(schema,'props.resourceId') === id) {
        return {element: schema, path: resultId};
      } else
        return this.findElementInChildren(id, schema, resultId);
    } else
      return this.findElementInChildren(id, schema, resultId);
  };

  @action
  removeElementById(id) {
    const schema = this.schema;
    const parent = this.getElementById(this.getParentId(id), schema).element;
    for (let i = 0; i < parent.children.length; i++) {
      if (parent.children[i].props.resourceId === id) {
        parent.children.splice(i, 1);
        break;
      }
    }
  };


  @action
  getParentId(id, schema, parentId) {
    schema = schema ? schema : this.schema;
    parentId = parentId ? parentId : "";
    if (schema.props) {
      if (schema.props.resourceId === id) {
        return parentId;
      } else {
        for (let i = 0; i < schema.children.length; i++) {
          parentId = schema.props.resourceId;
          const result = this.getParentId(id, schema.children[i], parentId);
          if (result)
            return result;
        }
      }
    } else {
      for (let i = 0; i < schema.children.length; i++) {
        const result = this.getParentId(id, schema.children[i], parentId);
        if (result)
          return result;
      }
    }
  };

  @action
  changeShapeCoordinates(dragAndDropStore, schema, outgoing) {
    const id = dragAndDropStore.dragId;
    const dropX = dragAndDropStore.dropCoordinates.x;
    const dropY = dragAndDropStore.dropCoordinates.y;
    const startX = dragAndDropStore.coordinatesOnStartDrag.x;
    const startY = dragAndDropStore.coordinatesOnStartDrag.y;
    if (schema.props) {
      if (schema.props.resourceId === id || outgoing) {
        const width = schema.props.bounds.lowerRight.x - schema.props.bounds.upperLeft.x;
        const height = schema.props.bounds.lowerRight.y - schema.props.bounds.upperLeft.y;
        const oldX = schema.props.bounds.upperLeft.x;
        const oldY = schema.props.bounds.upperLeft.y;
        schema.props.bounds.upperLeft = {x: oldX + dropX - startX, y: oldY + dropY - startY};
        schema.props.bounds.lowerRight = {x: oldX + dropX + width - startX, y: oldY + dropY + height - startY};
        if (!outgoing) {
          this.getOutgoingNoFlowsElements(this.getElementById(id).element.props.outgoing).map(element => {
            this.changeShapeCoordinates(dragAndDropStore, element, true);
          });
        }
      } else {
        schema.children.map(child => {
          this.changeShapeCoordinates(dragAndDropStore, child);
        })
      }
    } else {
      schema.children.map(child => {
        this.changeShapeCoordinates(dragAndDropStore, child);
      })
    }
  };

  getBounds(id) {
    const {lowerRight, upperLeft} = this.getElementById(id).element.props.bounds;
    return {
      lowerRight: {
        x: lowerRight.x,
        y: lowerRight.y
      },
      upperLeft: {
        x: upperLeft.x,
        y: upperLeft.y
      }
    }
  }

  @action
  changeLinkPoints(id, point, oldX, oldY, dragAndDropStore, e) {
    const link = this.getElementById(id).element;
    const linkPoints = link.props.points.split(",");
    for (let i = 0; i < linkPoints.length; i = i + 2) {
      if (i / 2 === point) {
        linkPoints[i] = e.nativeEvent.layerX + oldX;
        linkPoints[i + 1] = e.nativeEvent.layerY + oldY;
        break;
      }
    }
    this.getElementById(id).element.props.points = linkPoints.join(",");
  };


  @action
  createLink(svgId, id, number, centerX, centerY, clickX, clickY) {
    const element = this.getElementById(svgId).element;
    const newLink = getLinkConfig(id, number, {x: clickX, y: clickY}, {x: centerX, y: centerY});
    element.children.push(newLink);
    const startElement = this.getElementById(id).element;
    startElement.props.outgoing.push({
      resourceId: newLink.props.resourceId
    });
  };

  @action
  removeCreatedLink(svgId) {
    const svgElement = this.getElementById(svgId).element;
    const createdLink = svgElement.children[svgElement.children.length - 1];
    const connected = this.getElementById(createdLink.props.connected.resourceId).element;
    const outgoing = connected.props.outgoing;
    for (let i = 0; i < outgoing.length; i++) {
      if (outgoing[i].resourceId === createdLink.props.resourceId) {
        outgoing.splice(i, 1);
        i--;
      }
    }
    svgElement.children.pop();
  };

  @action
  changeCoordinatesOfCreatedLink(id, currentX, currentY) {
    const element = this.getElementById(id).element;
    const points = element.props.points;
    element.props.points = points.split(",").splice(0, points.split(",").length - 2).join(",") + currentX + "," + currentY;
  };

  @action
  setLinkConnectTo(linkId, dropId) {
    const element = this.getElementById(linkId).element;
    element.props.points = "";
    element.props.connectTo.resourceId = dropId;
    element.props.outgoing.push({resourceId: dropId});
    element.props.target.resourceId = dropId;
  };


  getCoordinatesOfCenter = (id) => {
    const bounds = this.getElementById(id).element.props.bounds;
    const centerX = bounds.upperLeft.x + (bounds.lowerRight.x - bounds.upperLeft.x) / 2;
    const centerY = bounds.upperLeft.y + (bounds.lowerRight.y - bounds.upperLeft.y) / 2;
    return {centerX: centerX, centerY: centerY};
  };

  getMinValue = (values) => {
    let minValue = values[0];
    values.map((value) => {
      if (value < minValue)
        minValue = value;
    });
    return minValue;
  };

  getMaxValue = (values) => {
    let maxValue = values[0];
    values.map((value) => {
      if (value > maxValue)
        maxValue = value;
    });
    return maxValue;
  };

  @action
  setBounds(element) {
    let minX, minY, maxX, maxY;
    const connected = element.props.connected.resourceId;
    const connectTo = element.props.connectTo.resourceId;
    const connectedCenter = this.getCoordinatesOfCenter(connected);
    const connectToCenter = this.getCoordinatesOfCenter(connectTo);
    if (element.props.points === "") {
      minX = this.getMinValue([connectedCenter.centerX, connectToCenter.centerX]);
      minY = this.getMinValue([connectedCenter.centerY, connectToCenter.centerY]);
      maxX = this.getMaxValue([connectedCenter.centerX, connectToCenter.centerX]);
      maxY = this.getMaxValue([connectedCenter.centerY, connectToCenter.centerY]);
    }
    element.props.bounds.upperLeft.x = minX;
    element.props.bounds.upperLeft.y = minY;
    element.props.bounds.lowerRight.x = maxX;
    element.props.bounds.lowerRight.y = maxY;
  };

  @action
  changeBoundsOfLinks(svgId, dragId) {
    const svgElement = this.getElementById(svgId).element;
    svgElement.children.map(line => {
      if (line.props.connected.resourceId === dragId || line.props.connectTo.resourceId === dragId) {
        this.setBounds(line);
      }
    });
  };

  @action
  popOutgoing(id) {
    this.getElementById(id).element.props.outgoing.pop();
  };

  @action
  setDocker(link, number, coordinates) {
    link.props.dockers[number] = {
      x: coordinates.x,
      y: coordinates.y
    }
  };

  @action
  addChildShape(addedElement) {
    this.schema.children.push(addedElement);
  };

  getNumber(element, type, number) {
    if (!number)
      number = 0;
    let r = /\d+/;
    element.children.map((child) => {
      if (type === "Transaction" ? child.props.stencil.id === "SubProcess" : child.props.stencil.id === type) {
        const childIndex = +child.props.resourceId.match(r)[0];
        if (childIndex > number) {
          if (type === "Transaction") {
            if (child.props.properties.istransaction) {
              number = childIndex;
            }
          } else
            number = childIndex;
        }
      }
      number = this.getNumber(child, type, number);
    });
    return number;
  };

  @action
  changeBoundsOfDiagram(x, y) {
    const diagram = this.getElementById("canvas").element;
    const diagramLowerRight = diagram.props.bounds.lowerRight;
    const SVGLowerRight = diagram.children[0].props.bounds.lowerRight;
    if (diagramLowerRight.x < x + 5) {
      diagramLowerRight.x = x + 15;
      SVGLowerRight.x = x + 15;
    }
    if (diagramLowerRight.y < y + 5) {
      diagramLowerRight.y = y + 15;
      SVGLowerRight.y = y + 15;
    }
  };

  @action
  updateExtremeDockers = (id, element) => {
    const svgElement = this.getElementById(this.getParentId(id)).element.children[0];
    const shapeCenter = this.getCoordinatesOfCenter(id);
    const upperLeft = element.props.bounds.upperLeft;
    svgElement.children.map(child => {
      if (child.props.connected.resourceId === id) {
        this.setDocker(child, 0, {x: shapeCenter.centerX - upperLeft.x, y: shapeCenter.centerY - upperLeft.y});
      }
      if (child.props.connectTo.resourceId === id) {
        this.setDocker(child, child.props.dockers.length - 1, {
          x: shapeCenter.centerX - upperLeft.x,
          y: shapeCenter.centerY - upperLeft.y
        });
      }
    });
  };

  @action
  addVertex(link, index, x, y) {
    let pointsArr;
    if (link.props.points !== "") {
      pointsArr = link.props.points.split(",");
      pointsArr.splice(index, 0, x, y);
    } else {
      pointsArr = [x, y];
    }
    link.props.points = pointsArr.join(",");
  };

  @action
  changeInnerDockers(link) {
    const innerDockers = [];
    const arrPoints = link.props.points.split(",");
    for (let i = 0; i < arrPoints.length - 1; i = i + 2) {
      innerDockers.push({
        x: arrPoints[i],
        y: arrPoints[i + 1]
      })
    }
    link.props.dockers.splice(1, link.props.dockers.length - 2, ...innerDockers);
  };

  @action
  updateBoundsOfDiagram(dragAndDropStore) {
    const id = dragAndDropStore.dragId;
    const element = this.getElementById(id).element;
    const dropX = dragAndDropStore.dropCoordinates.x;
    const dropY = dragAndDropStore.dropCoordinates.y;
    const startX = dragAndDropStore.coordinatesOnStartDrag.x;
    const startY = dragAndDropStore.coordinatesOnStartDrag.y;
    const width = element.props.bounds.lowerRight.x - element.props.bounds.upperLeft.x;
    const height = element.props.bounds.lowerRight.y - element.props.bounds.upperLeft.y;
    const oldX = element.props.bounds.upperLeft.x;
    const oldY = element.props.bounds.upperLeft.y;
    this.changeBoundsOfDiagram(oldX + dropX + width - startX, oldY + dropY + height - startY);
  };

  @action
  removeLinksOfShape(id) {
    const parent = this.getElementById(this.getParentId(id)).element;
    const svg = parent.children[0];
    for (let i = 0; i < svg.children.length; i++) {
      const {connected, connectTo} = svg.children[i].props;
      if (connectTo.resourceId === id || connected.resourceId === id) {
        this.removeLink(svg.children[i].props.resourceId)
        i--;
      }
    }
  }

  @action
  removeOuterOutgoings(id, schema) {
    schema = schema ? schema : this.schema;
    schema.children.map(child => {
      if (child.props.outgoing) {
        for (let i = 0; i < child.props.outgoing.length; i++) {
          this.removeOuterOutgoings(id, child);
          if (child.props.outgoing[i].resourceId === id) {
            child.props.outgoing.splice(i, 1);
            i--;
          }
        }
      }
    })
  };

  @action
  removeOutgoings(outgoing) {
    this.getOutgoingNoFlowsElements(outgoing).map(out => {
      this.removeLinksOfShape(out.props.resourceId)
      this.removeElementById(out.props.resourceId)

    })
  };

  @action
  addChild(id, child) {
    this.getElementById(id).element.children.push(child);
  };

  @action
  changeSizeInnerSVG(element) {
    const bounds = element.props.bounds;
    if (element.children.length >= 1 && element.children[0].tag === "SVG")
      element.children[0].props.bounds = bounds;
  };

  @action
  removeLink(id) {
    const SVG = this.getElementById(this.getParentId(id)).element;
    for (let i = 0; i < SVG.children.length; i++) {
      if (SVG.children[i].props.resourceId === id) {
        SVG.children.splice(i, 1);
        break;
      }
    }
    const parent = this.getElementById(this.getParentId(SVG.props.resourceId)).element;
    for (let i = 1; i < parent.children.length; i++) {
      const outgoing = parent.children[i].props.outgoing;
      for (let j = 0; j < outgoing.length; j++) {
        if (outgoing[j].resourceId === id) {
          outgoing.splice(j, 1);
        }
      }
    }
    ;
  };

  @action
  addMarquee(shape) {
    if (!shape) {
      this.schema.children.push({
        tag: "Marquee",
        props: {
          resourceId: "marquee"
        },
        children: []
      });
    } else {
      shape.children.push({
        tag: "Marquee",
        props: {
          resourceId: "marquee"
        },
        children: []
      });
    }
  };

  @action
  removeMarquee() {
    this.removeElementById("marquee");
  };

  @action
  addOutgoing(id, outgoing) {
    const resultElement = this.getElementById(id).element;
    resultElement.props.outgoing.push(outgoing);
  }

  isFlow(resourceId) {
    return resourceId.indexOf("flow") !== -1;
  }

  getOutgoingNoFlowsElements(arrayOutgoing) {
    const resultArray = [];
    arrayOutgoing.map(outgoing => {
      if (!this.isFlow(outgoing.resourceId))
        resultArray.push(this.getElementById(outgoing.resourceId).element)
    });
    return resultArray;
  }

  getCoordinatesUpperLeft(id) {
    let x = 0, y = 0;
    let currentId = id;
    while (currentId !== 'canvas') {
      const resultElement = this.getElementById(currentId).element;
      x += resultElement.props.bounds.upperLeft.x;
      y += resultElement.props.bounds.upperLeft.y;
      currentId = this.getParentId(currentId);
    }
    return {x: x, y: y};
  }

  getActiveActivityTasks() {
    if (this._processStatuses) {
      const arrayOfActive = [];
      Object.keys(this._processStatuses).map(key => {
        if (this.processStatuses[key].isActive)
          arrayOfActive.push(key);
      });
      return arrayOfActive;
    }
    return [];
  }

  isConfigShape(resourceId) {
    const stencil = this.getElementById(resourceId).element.props.stencil.id;
    return this._stencilsOfConfShapes.indexOf(stencil) !== -1;
  }

  getAllConfShapes(array, schema) {
    let resultArray = array ? array : [];
    const resultSchema = schema ? schema : this.schema;
    if (this.isConfigShape(resultSchema.props.resourceId)) {
      resultArray.push(resultSchema)
    }
    resultSchema.children.map(child => {
      resultArray = this.getAllConfShapes(resultArray, child);
    });
    return resultArray;
  }

  getAllShapes(array, schema) {
    let resultArray = array ? array : [];
    const resultSchema = schema ? schema : this.schema;
    if (this.isConfigShape(resultSchema.props.resourceId)) {
      resultArray.push(resultSchema)
    }
    if (this.isGateway(resultSchema.props.resourceId)) {
      resultArray.push(resultSchema)
    }
    if (this.isPossibleGoToShape(resultSchema.props.resourceId)) {
      resultArray.push(resultSchema)
    }
    resultSchema.children.map(child => {
      resultArray = this.getAllShapes(resultArray, child);
    });
    return resultArray;
  }

  isGateway(resourceId) {
    return this._stencilsOfGateways.indexOf(resourceId) !== -1
  }

  isPossibleGoToShape(resourceId) {
    return this._stencilsOfGoTo.indexOf(this.getElementById(resourceId).element.props.stencil.id) !== -1
  }

  getNextConfTasks(id, tasks) {
    let resultTasks = tasks ? tasks : [];
    const currentElement = this.getElementById(id).element;
    currentElement.props.outgoing.map(out => {
      if (this.isFlow(out.resourceId)) {
        const resultLink = this.getElementById(out.resourceId).element;
        const linkOut = resultLink.props.connectTo;
        if (linkOut && this.getElementById(linkOut.resourceId)) {
          if (this.isGateway(this.getElementById(linkOut.resourceId).element.props.stencil.id)) {
            resultTasks = this.getNextConfTasks(linkOut.resourceId, resultTasks);
          } else {
            if (this.isPossibleGoToShape(linkOut.resourceId))
              resultTasks.push(this.getElementById(linkOut.resourceId).element);
          }
        }
      }
    });
    return resultTasks;
  };

  getLastLinkBetweenTasks = (perfId, taskId) => {
    let resultLinkId;
    const perfElement = this.getElementById(perfId).element;
    perfElement.props.outgoing.map(out => {
      if (this.isFlow(out.resourceId)) {
        const resultLink = this.getElementById(out.resourceId).element;
        const linkOut = resultLink.props.connectTo;
        if (linkOut && this.getElementById(linkOut.resourceId)) {
          if (this.isGateway(this.getElementById(linkOut.resourceId).element.props.stencil.id)) {
            resultLinkId = this.getLastLinkBetweenTasks(this.getElementById(linkOut.resourceId).element.props.resourceId, taskId);
          } else {
            if (this.getElementById(linkOut.resourceId).element.props.resourceId === taskId)
              resultLinkId = resultLink.props.resourceId;
          }
        }
      }
    });
    return resultLinkId;
  };

  @action
  addExpression = (linkId, expression) => {
    const linkElement = this.getElementById(linkId).element;
    if (linkElement.props.defaultConditionConf) {
      linkElement.props.properties.conditionsequenceflow = expression;
    }
  };

  @action
  dropExpression = (linkId) => {
    const linkElement = this.getElementById(linkId).element;
    if (linkElement.props.defaultConditionConf) {
      linkElement.props.properties.conditionsequenceflow = "";
    }
  };


  isEndEvent(resourceId) {
    return this.getElementById(resourceId).element.props.stencil.id === "EndNoneEvent";
  }

  isUserTask(resourceId) {
    return this.getElementById(resourceId).element.props.stencil.id === "UserTask";
  }

  isStartEvent(resourceId) {
    return this.getElementById(resourceId).element.props.stencil.id === "StartNoneEvent";
  }
}

export default SchemaDataStore;
