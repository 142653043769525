import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Nav } from '@isf/bootstrap';
import { MenuContext, MenuStore } from './menu-store';
import { SidebarContext } from '../../sidebar/src/sidebar';

@observer
class Menu extends Component {

  static contextType = MenuContext;

  constructor(props) {
    super(props);
    this.menuStore = props.menuStore || new MenuStore({
      path: 'menuStore' + props.id
    });
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const { children, direction, className } = this.props;
    const { parentIdMenuItems } = this.context || {};
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <Nav
        pills
        className={classNames(
          "menu bg-white", className, computedClassNames,
          {
            "nav-horizontal": direction === "horizontal",
            "flex-column": direction !== "horizontal"
          }
        )}
      >
        <SidebarContext.Consumer>
          {({ sidebarCheck }) => (
            <MenuContext.Provider
              value={{
                menuStore: this.menuStore,
                parentIdMenuItems: parentIdMenuItems ? [...parentIdMenuItems] : null,
                sidebarCheck: sidebarCheck
              }}
            >
              {children}
            </MenuContext.Provider>
          )}
        </SidebarContext.Consumer>
      </Nav>
    )
  }
}

export default Menu;