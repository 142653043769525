import React, { Fragment } from "react";
import { observer } from "mobx-react";
import { injectIntl } from "@isf/localization";
import { routerStore } from "@isf/core-app-store";
//import { ICON_ARROW_RIGHT, ICON_SEARCH } from "@isf/common-resources";

const headerSearchId = "mainView.header.searchId";

const Search = injectIntl( observer( (props) => {
  const { store, intl: { formatMessage } } = props;
  const isSearchActive = store.get(headerSearchId);
  const searchInput = React.createRef();

  const toggleSearch = () => {
    store.set(headerSearchId, !isSearchActive);
  };

  const handleSearchClick= () => {
    toggleSearch();
    routerStore.push(`/search/main/v1/${searchInput.current.value}`);
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      toggleSearch();
      routerStore.push(`/search/main/v1/${e.target.value}`);
    }
  };

  return (
    <Fragment>
      { isSearchActive
        ? <div className="d-flex align-items-center position-absolute header-search-active-container mr-4 my-auto">
            <Temp_icon_search
              //<img src={ICON_SEARCH}
              onClick={handleSearchClick}
              className="position-absolute d-block h-auto icon-w-23 cursor-pointer header-search-icon-search"
              title={formatMessage({id: "ui.header.search.search"})}
              alt={formatMessage({id: "ui.header.search.search"})} />
            <input type="text"
                   className="form-control header-search-input"
                   aria-label={formatMessage({id: "ui.header.search"})}
                   placeholder={formatMessage({id: "ui.header.search"})}
                   ref={searchInput}
                   onKeyPress={handleSearchKeyPress} />
            <span className="input-border-bottom-focus" />
            <Temp_icon_arrow_right
            //<img src={ICON_ARROW_RIGHT}
                 onClick={toggleSearch}
                 className="position-absolute h-auto icon-w-18 cursor-pointer header-search-icon-arrow"
                 title={formatMessage({id: "ui.header.search.collapse"})}
                 alt={formatMessage({id: "ui.header.search.collapse"})} />
          </div>
        : <Temp_icon_search
          //<img src={ICON_SEARCH}
               onClick={toggleSearch}
               className="d-block h-auto icon-w-23 mr-4 pr-2 my-auto box-sizing-content cursor-pointer icon-header-right"
               title={formatMessage({id: "ui.header.search.expand"})}
               alt={formatMessage({id: "ui.header.search.expand"})} />
      }
    </Fragment>
  );
}));

export { Search };

const Temp_icon_arrow_right = (props) => {
  return (
    <svg onClick={props.onClick} className={props.className} style={{fill: "#1d5c93"}} viewBox="0 0 268.832 268.832" height="18px" width="18px" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg">
      { props.title ? <title>{props.title}</title> : null }
      <path
        d="M265.171,125.577l-80-80c-4.881-4.881-12.797-4.881-17.678,0c-4.882,4.882-4.882,12.796,0,17.678l58.661,58.661H12.5
		c-6.903,0-12.5,5.597-12.5,12.5c0,6.902,5.597,12.5,12.5,12.5h213.654l-58.659,58.661c-4.882,4.882-4.882,12.796,0,17.678
		c2.44,2.439,5.64,3.661,8.839,3.661s6.398-1.222,8.839-3.661l79.998-80C270.053,138.373,270.053,130.459,265.171,125.577z"/>
    </svg>
  )
};

const Temp_icon_search = (props) => {
  return (
    <svg onClick={props.onClick} className={props.className} style={{fill: "#1d5c93"}} viewBox="0 0 20 20" height="23px" width="23px" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg">
      { props.title ? <title>{props.title}</title> : null }
      <path
        d="M17.9107 19.3621L13.8486 15.3C12.3925 16.4435 10.5612 17.1319 8.56591 17.1319C3.83508 17.1319 0 13.2968 0 8.56594C0 3.83508 3.83508 0 8.56591 0C13.2967 0 17.1318 3.83508 17.1318 8.56591C17.1318 10.418 16.5383 12.1275 15.5391 13.5286L19.6416 17.6311C20.1195 18.1089 20.1195 18.8836 19.6416 19.3614C19.1638 19.8399 18.3886 19.8399 17.9107 19.3621ZM12.8746 13.3219C12.934 13.1585 13.0208 13.0031 13.1524 12.8721C13.2729 12.7516 13.4124 12.6623 13.5611 12.6023C14.4538 11.4992 14.9904 10.0956 14.9904 8.56594C14.9904 5.0178 12.1141 2.14152 8.56594 2.14152C5.01783 2.14152 2.14149 5.0178 2.14149 8.56594C2.14149 12.1141 5.0178 14.9904 8.56591 14.9904C10.2259 14.9904 11.7341 14.3553 12.8746 13.3219Z"/>
    </svg>
  )
};