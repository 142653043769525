export const BoundarySignalConf = (number,coordinates)=>(
  {
    tag:"Shape",
    props:{
      bounds: {
        lowerRight: {
          x: coordinates.x + 30,
          y: coordinates.y + 30
        },
        upperLeft: {
          x: coordinates.x,
          y: coordinates.y
        }
      },
      resourceId: "boundarysignal" + number,
      childShapes: [],
      stencil: {
        id: "BoundarySignalEvent"
      },
      properties: {
        overrideid: "boundarysignal" + number,
        name: "Signal",
        cancelactivity: true,
        signalref: "",
        executionlisteners: {
          executionListeners: []
        }
      },
      outgoing: []
    },
    children:[]
  }
);