export const CatchSignalEventConf = (number, coordinates) => {
  return (
    {
      tag: "Shape",
      props:
        {
          bounds: {
            lowerRight: {
              x: coordinates.x + 35,
              y: coordinates.y + 35
            },
            upperLeft: {
              x: coordinates.x,
              y: coordinates.y
            }
          },
          resourceId: "signalintermediatecatchevent"+number,
          childShapes: [],
          stencil: {
            id: "CatchSignalEvent"
          },
          properties: {
            overrideid: "signalintermediatecatchevent"+number,
             name: "SignalCatchEvent",
             signalref: "",
            executionlisteners: {
              executionListeners: []
            }
          },
          outgoing: []
        },
      children: []
    }
  )
};