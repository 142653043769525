import { action } from "mobx";
import { Store } from "@isf/core-app-store";
//import { ApiCallActionStore } from "@isf/ui-schema/src/api-call-action/api-call-action-store";
import { ApiCallActionStore } from "../../../../../ui-schema/src/api-call-action/api-call-action-store";
import { serviceActionConfig } from "./service-table-constants";

import { getEnvVariable } from "@isf/core-system-util";
import { RestApi } from "@isf/core-rest";
const API_DEFAULT_CONF = {
  "schema": getEnvVariable('API_DEFAULT_SCHEMA'),
  "host": getEnvVariable('API_DEFAULT_HOST'),
  "port": getEnvVariable('API_DEFAULT_PORT'),
  "context": "api"
};
export const confApi = new RestApi({...API_DEFAULT_CONF, ...{context: 'api/conf'}});

class ServicesTableStore extends Store {

  constructor({path, requestStore}) {
    super({path, scope: 'data', state: [], observable: true});

    this.requestStore = requestStore;
  }

  onLoad(services=[]) {
    this.setState(services);
  }

  @action
  async load() {
    const apiCallAction = new ApiCallActionStore({action: serviceActionConfig, listData: undefined});
    const request = apiCallAction.getRequestOptions().requestConf;
    return confApi.call(
      request,
      {request},
      apiCallAction.restHandler
    );
  }
}

Object.defineProperty(ServicesTableStore, "name", {value: "ServicesTableStore"});

export { ServicesTableStore };