import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { FormattedMessage, injectIntl } from "@isf/localization";
import $ from "@isf/core-object-util";
import { ServicesStore, getRequestParametersNotAsync, getSchemaOpenApiChild } from "@isf/common-select-service";
import { Container, Row, Col, FormGroup, Label, Input } from "@isf/bootstrap";
import { StaticText } from "../../form";
import { Message } from "../../form/message";
import { FilterProperties, ServiceSelectionSetting } from "./components";
import { FilterSettingStore } from "./filter-setting-store";
import classNames from "classnames";

@injectIntl
@observer
class FilterSetting extends Component {
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    this.filterSettingStore = new FilterSettingStore({
      path: props.id + "filterSettingStore",
      accessor: props.accessor,
      state: {}
    });
    this.servicesStore = new ServicesStore({
      path: ["filterSettingStore.services"],
      state: observable.array([], { deep: false }),
    });
    this._serviceIdDB = null;
    this.filterSettingStore.set(`${props.accessor}.mode`, "readMode");
  }

  componentDidMount() {
    this._serviceIdDB = null;
    this.filterSettingStore.set("error", null);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  componentDidUpdate(prevProps) {
    if (!this.filterSettingStore.service && this.props.handler && this.props.accessor
          && $.get(this.props.handler.get(this.props.accessor), "service")
          && $.get(this.props.handler.get(this.props.accessor), "service.id")
          && $.get(this.props.handler.get(this.props.accessor), "service.id") !== this._serviceIdDB) {
      const id = $.get(this.props.handler.get(this.props.accessor), "service.id");
      const contextPath = $.get(this.props.handler.get(this.props.accessor), "service.contextPath")
      this._serviceIdDB = id;
      if (id && contextPath) {
        this.load(id, contextPath);
      }
    }
  }

  async load(serviceId, serviceContextPath) {
    if (serviceId && serviceContextPath) {
      const service = await this.servicesStore.loadServiceById(serviceId);
      if (service) {
        this.filterSettingStore.set("error", null);
        this.filterSettingStore.set("service.id", service.id);
        this.filterSettingStore.set("service.contextPath", service.contextPath);
        this.filterSettingStore.set("service.description", service.description);

        let searchOperation;
        if (service.api && service.api.schema && service.api.schema.paths) {
          searchOperation = service.api.schema.paths[`${serviceContextPath}/search`];
        }
        if (searchOperation) {
          const requestParamsObj = await getRequestParametersNotAsync(`${serviceContextPath}/search`, "post", $.get(service, "api.schema"), this.servicesStore);
          const apiRequestBodyFilterSchema = getSchemaOpenApiChild(requestParamsObj, "body.filter");
          if (apiRequestBodyFilterSchema) this.filterSettingStore.set("service.apiRequestBodyFilterSchema", apiRequestBodyFilterSchema.properties);
        }
      } else {
        this.filterSettingStore.set("error", "ui.filterSetting.service.removed");
        const {handler, accessor} = this.props;
        const service = handler.get(`${accessor}.service`);
        handler.set(`${accessor}`, {service: service});
      }
    }
  }

  render() {
    const { id, handler, accessor, nameButtonOpenModal, modalHeader, intl, modalDisabled, autoServiceSelection, className } = this.props;
    const serviceDB = $.toJS(handler.get(`${accessor}`)) && $.toJS(handler.get(`${accessor}.service`));
    const filterDB = $.toJS(handler.get(`${accessor}`)) && $.toJS(handler.get(`${accessor}.filter`));
    const service = $.toJS(this.filterSettingStore.service);
    const error = this.filterSettingStore.error;
    const filterSchema = this.filterSettingStore.filterSchema;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <Container fluid className={classNames("p-0", className, computedClassNames)}>
        <Row className="justify-content-center justify-content-md-between align-items-center">
          {!autoServiceSelection && (
            <Col xs="auto" className="pr-md-0">
              <ServiceSelectionSetting opener={{outline: true, color: "primary"}}
                                       openerContent={<Message message={nameButtonOpenModal && nameButtonOpenModal.message} />}
                                       path={`${accessor}`}
                                       modalHeader={modalHeader}
                                       handler={handler}
                                       accessor={accessor}
                                       filterSettingStore={this.filterSettingStore}
                                       modalDisabled={modalDisabled} />
            </Col>
          )}
          <Col xs="auto" className="mb-2 mb-md-0">
            <Row className="justify-content-start justify-content-md-end align-items-center no-gutters">
              <Col xs="auto">
                { error
                  && <span className="d-block text-danger">
                      <FormattedMessage id={error}/>
                    </span>
                }
                {!autoServiceSelection && (service || serviceDB)
                  && <span className="d-block">
                        <StaticText text={`${intl.formatMessage({id: "ui.filterSetting.service"})}: `}
                                    className="font-weight-semi-bold" />
                          { service && service.description && <StaticText text={service.description} className="pr-1"/> }
                          { (serviceDB && serviceDB.contextPath
                                && <StaticText text={`(${serviceDB.contextPath })`}
                                               className="font-weight-semi-bold text-primary" /> )
                            || (service.contextPath
                                && <StaticText text={`(${service.contextPath })`}
                                               className="font-weight-semi-bold text-primary" /> )}
                    </span>
                }
              </Col>
            </Row>
          </Col>
        </Row>
        { filterSchema
          && <Fragment>
              <Row>
                <Col xs="auto" className="mt-1 mb-2">
                  <FormGroup check>
                    <Label check className="mr-5">
                      <Input type="radio"
                             name={`${accessor}.mode`}
                             value="editMode"
                             checked={"editMode" === this.filterSettingStore.mode}
                             onChange={(event) => {
                               this.filterSettingStore.set(`${event.target.name}`, event.target.value);
                             }} />
                      <FormattedMessage id="ui.filterSetting.editMode" />
                    </Label>
                    <Label check>
                      <Input type="radio"
                             name={`${accessor}.mode`}
                             value="readMode"
                             checked={"readMode" === this.filterSettingStore.mode}
                             onChange={(event) => {
                               this.filterSettingStore.set(`${event.target.name}`, event.target.value);
                             }} />
                      <FormattedMessage id="ui.filterSetting.readMode" />
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              { (this.filterSettingStore.mode === "readMode" && filterDB && Object.keys(filterDB).length !== 0
                || this.filterSettingStore.mode === "editMode")
                && <FilterProperties id={id}
                                    handler={handler}
                                    accessor={accessor}
                                    filterSchema={filterSchema}
                                    mode={this.filterSettingStore.mode} />
              }
        </Fragment>
        }
      </Container>
    );
  }
}

export { FilterSetting };