import React from "react";
import {Draggable, Droppable} from "react-drag-and-drop"
import {observer} from "mobx-react";

export const DragAndDropWrapper = observer((props) => {
      const {store, path, onStartDrag, onDrop, onDrag, addRefToArray, onMouseUp, onDragExitLeave, onDragOver, onDragEnter, data} = props;
      // const ref = React.createRef();
      // addRefToArray(ref, path);
      return (
         <div /*ref={ref} */className={'DragItem'}>
            <Draggable
               onDragStart={() => handleStartDrag(path, store, data, onStartDrag)}
               // onDragEnter={(e) => handleDragEnter(e, store, onDragEnter)}
               onDrop={(e) => handleDrop(e, props, onDrop)}
               onDragLeave={(e) => handleExitLeave(e, onDragExitLeave)}
               onDragExit={(e) => handleExitLeave(e, onDragExitLeave)}
               // onDrag={(e) => handleDrag(e, store, onDrag)}
               onDragOver={(e) => handleDragOver(e, props, onDragOver)}
               onMouseUp={() => handleMouseUp(onMouseUp)}
            >
               <Droppable path={path}>
                  {props.children}
               </Droppable>
            </Draggable>
         </div>
      );
   }
);

const handleMouseUp = (onMouseUp) => {
   onMouseUp();
};

const handleStartDrag = (path, store, data, startDrag) => {
   startDrag(path, store, data);
};

const handleDrop = (e, props, drop) => {
   drop(e, props);
};

const handleDrag = (e, store, drag) => {
   drag(e, store);
};

const handleDragOver = (e, props, dragOver) => {
   dragOver(e, props)
};

const handleDragEnter = (e, store, dragEnter) => {
   dragEnter(e, store);
}

const handleExitLeave = (e, exitLeave) => {
   exitLeave(e)
};
