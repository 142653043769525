import {runInAction} from "mobx";
import appStore, {DataStore, routerStore} from "@isf/core-app-store";
import $ from "@isf/core-object-util/src/object-util";
import {storeTemplToPath} from "../util";
import Alert from "react-s-alert";
import {createAlertMessage} from "../create-alert-message";
import {injectIntl} from "@isf/core-localization";
import {localeStore} from "@isf/core-localization";
import {messageDescriptorPropTypes} from "react-intl/src/types";
import {defaultGlobalErrorMessageOptions} from "@isf/common";
import {getErrorMessage} from "./response-utils";

const createNewStore = (storePath, newPathname) => {
   const store = appStore.getDataStore(storePath);
   const state = store.state;
   let newStoreName = newPathname.split('/');
   newStoreName.shift();
   newStoreName = newStoreName.join('.');
   // new DataStore({path: newStoreName, state: state, observableAsMap: true});
   // appStore.remove('data.'+storePath);
};

export const restResponseHandler = async (context, responseInfo, response, intl) => {
   let {request, storePath, store, action, setterNoReload, /*intl,*/ fileAttach, actionName, flowName, globalExecuteActionsStore} = context;
   const executeActions = globalExecuteActionsStore.get('executeActions');
   let body = await response;
   // response.then((body) => {
   const {onSuccess} = action;
   const messages = $.get(action, 'messages');
   // runInAction(
   //   () => {

   if (responseInfo.ok) {
      // A crutch. if the status 204 and the storage contain an array, set an empty array.
      // Used when deleting an element from a table with table length === 1
      if (responseInfo.status === 204 && !body) {
         body = store && Array.isArray(store.state)
           ? []
           : body;
      }


      if (messages) {
         const messageId = $.get(messages, 'ok.id');
         if (messageId) {
            const message = $.get(localeStore.localeMessages, messageId);
            if (message) {
               createAlertMessage(message, "success");
            }
         }
      }
      if (request.options.method === 'post') {
         setterNoReload(true); //TODO
         // store = appStore.getDataStore(storePath);
         store = appStore.getDataStore(storePath);
         if(!(context.path || context.refId)) {
            const newPathname = routerStore.pathname.replace(/\/new$/, '/' + body.id) + routerStore.search;
            routerStore.replace(newPathname);
         }
         body && store.setState(body, {status: 'created'});
         $.set(store, 'isLoading', false);
         executeActions.set(actionName, false);
      } else {
         body && store.setState(body, {status: request.options.method});
         executeActions.set(actionName, false);
      }
      if (!action.force && onSuccess.endsWith('{id}')) {
         store.makeObservable({asMap: true});
      }
      fileAttach && fileAttach(store, request.path)
   } else {
      const status = responseInfo.status;
      const errorGlobalStore = appStore.getStore('errorGlobalStore', 'ui');
      if (flowName) {
         errorGlobalStore.set('failedFlow', flowName);
      }
      // if(status!==500) {
      //    createAlertMessage(status !== 422
      //      ? /*$.get(body, 'message')*/getErrorMessage(body,localeStore)
      //      : localeStore.localeMessages['ui.alerts.422'],
      //        'warning'
      //    );
      // }
      executeActions.set(actionName, false);
      if (status === 500) {
         const {autoErrorHandling, errorHandleAction} = context.action;
         if(autoErrorHandling === false){
            if(errorHandleAction){
               await context.engineContext.executeAction(errorHandleAction,context.callContext, undefined, context.flowName);
            }
         }else {
            if ($.isArray(body)) {
               body.forEach(error => {
                  errorGlobalStore.push('errors', {...error, status: status})//before changed alerts
                  //createAlertMessage(getErrorMessage(error,localeStore), "error",defaultGlobalErrorMessageOptions);
               });
            } else {
               errorGlobalStore.push('errors', {...body, status: status})//before changed alerts
               //createAlertMessage(getErrorMessage(body,localeStore), "error",defaultGlobalErrorMessageOptions);
            }
         }
      } else {
         if ($.isArray(body)) {
            body.forEach(error => {
               const path = error.path;
               let storeName = $.get(action, 'request.options.body');
               if (storeName) {
                  // storeName = storeTemplToPath(storeName);
                  // const resultStore = appStore.getDataStore(storeName);
                  storeName = context.refId ? context.refId + "." + storeName : storeName;
                  const resultStore = appStore.getDataStore(storeName);
                  resultStore.addToErrorState(path, error)
               }
            });
         } else {
            if(status!==500) {
               createAlertMessage(status !== 422
                   ? /*$.get(error, 'message')*/ getErrorMessage(body, localeStore, status)
                   : localeStore.localeMessages['ui.alerts.422'],
                   'error'
               );
            }
            //console.log('unresolved error', body)
         }
      }
   }
   // },
   // reason => console.log('apical error', reason)
// );
   // });
};
