import {computed} from "mobx";
import RestHandler from "./rest-handler";
import RestResource from "./rest-resource";

class RestApi {

   schema;

   host;

   port;

   context;

   handler;

   defaultOptions;

   constructor({schema, host, port, context, handler, defaultOptions}) {
      this.schema = schema || location.protocol.replace(':', '');
      this.host = host || location.hostname;
      this.port = port || location.port;
      this.context = context;
      this.handler = handler || new RestHandler();
      this.defaultOptions = defaultOptions || {
         credentials: 'include',
         headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}
      };
   }

   @computed
   get url() {
      const {schema, host, port, context} = this;
      return `${schema}://${host}${port ? ':' + port : ''}${context ? '/' + context : ''}`;
   }

   async request({path, pathParams, queryParams, options: requestOptions, handler = this.handler}, context, restHandler) {

      const url = buildRequestUrl({apiUrl: this.url, path, pathParams, queryParams});
      let response;
      const options = buildOptions(requestOptions, this.defaultOptions);
      try {
         response = await request({url, options});
         this.checkUnauthorized(response);
         return handler.handleResponse({
            request: {path, pathParams, queryParams, options},
            response,
            api: this
         }, context, restHandler);
      } catch (e) {
         // console.log(e);
         // handler.handleRequestError({
         //   url,
         //   path,
         //   pathParams,
         //   queryParams,
         //   options
         // },
         // e);
         return Promise.reject();
      }
   }


   async call({path, isApiUrl, pathParams, queryParams, options: requestOptions, handler = this.handler}, context, restHandler, actionName, globalExecuteActionsStore) {

      const url = buildRequestUrl({apiUrl: this.url, path, pathParams, queryParams, isApiUrl});
      let response;
      let options = buildOptions(requestOptions, this.defaultOptions);
      let executeActions;
      if (globalExecuteActionsStore) {
         executeActions = globalExecuteActionsStore.get('executeActions');
      }
      if (options.body instanceof FormData) {
         const headers = {...options.headers};
         delete headers["Content-Type"];
         delete headers["Accept"];
         options.headers = headers;
      }
      try {
         if (executeActions)
            executeActions.set(actionName, true);
         response = await request({url, options});
         this.checkUnauthorized(response);
         if (executeActions) {
            executeActions.set(actionName, false);
         }
         return handler.handleResponse({
            request: {path, pathParams, queryParams, options},
            response,
            api: this
         }, context, restHandler);
      } catch (e) {
         // console.log(e);
         try {
            await handler.handleRequestError({
              url,
              path,
              pathParams,
              queryParams,
              options,
              context
            },
            e);
         } catch (e) {
            console.error("handleRequestError error", e)
         }

         return Promise.reject();
      }
   }

   checkUnauthorized(response) {
      if (this.isUnauthorized(response)) {
         this.reloadPage(response);
      }
   }

   isUnauthorized(response) {
      const logoutUrl = response.headers.get('x-logout-url');
      const isStatus = response.status === 401;
      return logoutUrl && isStatus;
   }

   reloadPage(response) {
      window.location = response.headers.get('x-logout-url');
   }

   resource(path) {
      return new RestResource({path, api: this, handler: this.handler});
   }

}

const buildOptions = (requestOptions, defaultOptions) => {
   let options;
   if (!requestOptions) {
      options = defaultOptions;
   }
   else if (!defaultOptions) {
      options = requestOptions;
   }
   else options = {...defaultOptions, ...requestOptions};

   let locale;
   if (window && window.localStorage && window.localStorage.getItem('locale')) {
      locale = window.localStorage.getItem('locale');
   } else if (navigator && navigator.language) {
      locale = navigator.language.split(/[-_]/)[0];
      locale = locale === 'be' ? 'by' : locale;
   } else locale = 'ru';

   options = {
      ...options,
      headers: {
         ...options.headers,
         ['Accept-Language']: locale
      }
   };

   return options;
}

const buildRequestUrl = ({apiUrl, path, pathParams, queryParams, isApiUrl = true}) => {
   const contextPath = isApiUrl
     ? path.startsWith('/')
         ? apiUrl + path
         : `${apiUrl}/${path}`
     : path;
   return (
      `${contextPath}${pathParams
         ? '/' + paramsToPathStr(pathParams)
         : ''}${queryParams ? '?' + paramsToQueryStr(queryParams) : ''}`);
}

/**
 * Converts parameters object to query string
 * Example:
 * params = {
 *  "paramOne": "paramOneValue",
 *  "paramTwo": "paramTwoValue"
 * }
 * returns "paramOne=paramOneValue&paramTwo=paramTwoValue"
 * @param {*} params
 */
const paramsToQueryStr = (params) => (
   Object.keys(params).map(key => {
      if (Array.isArray(params[key])) {
         return params[key].map(item => `${key}=${item}`).join("&");
      }

      return `${key}=${params[key]}`;
   }).join('&')
)

/**
 * Converts parameters array to parameter path
 * Example:
 * params = [
 *  primitiveValue,
 *  {"paramOne": "paramOneValue"},
 *  {"paramTwo": "paramTwoValue"}
 * ]
 * returns "primitiveValue/paramOne/paramOneValue/paramTwo/paramTwoValue"
 * @param {*} params
 */
const paramsToPathStr = (pathParams) => (
   pathParams.map(pathParam =>
      typeof pathParam !== 'object' ? pathParam :
         Object.keys(pathParam).map(key => `${key}/${pathParam[key]}`).join('/')
   ).join('/')
);

/**
 * Executes HTTP request using fetch API
 * @param {*} param0
 */
const request = ({url, options}) => {
   try {
      const payload = fetch(url, options);
      // console.log('fetch options', url, options);
      return payload;
   } catch (e) {
      console.error('error in fetch', e)
   }
}

export default RestApi;
