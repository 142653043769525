import { Link } from "react-router-dom";

import React from "react";
import $ from "@isf/core-object-util";

import { routerStore } from "@isf/core-app-store";

//TODO: similar function declared in ui-engine/util, move to common (?)

// const templToPath = (template, split='/') => {
//   const p = routerStore.pathname.split('/');
//   const t = [...template.split(split)];
//   for(let i=0; i<p.length && i<t.length; i++) {
//     if (t[i] === '{{id}}') {
//       t[i] = p[i];
//     }
//   }
//   return t.join(split);
// };

export const templToPath = (template, split='/') => {
  const p = routerStore.pathname.split('/');
  if (!template.startsWith('/')) {
    template = '/' + template;
  }
  const t = template.split(split);
  for(let i=0; i<p.length && i<t.length; i++) {
    if (t[i] === '{id}') {
      t[i] = p[i];
    }
  }
  // t[0] = p[1];
  const path = t.join(split);
  return path;
};

const regexp = /\{(.*?)\}/g;

const ItemLink = ({item, accessor, to=''}) => {
  if (item.taskId && item.processInstanceId) {
    // console.log('itemlink', item);
    return (
      <Link to={`${item.link}?scope=${item.activitiId}&processInstanceId=${item.processInstanceId}`}>
        { item.activitiId || item.description || item.link/*$.get(item, accessor)*/}
      </Link>
    )
  }
  const parentLinkTo = templToPath(to);
  let linkTo = parentLinkTo.replace(regexp, (match, token) => {    
    return $.get(item, token);
  });
  linkTo = linkTo.replace(/\/\//g, "/");
  return (
    <Link to={linkTo}>
      {$.get(item, accessor)}
    </Link>
  )
}

export default ItemLink;