import _defineProperty from "/home/jenkins/agent/workspace/govby/frontend/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/home/jenkins/agent/workspace/govby/frontend/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { isObservable, isObservableObject, isObservableArray, isObservableMap, observable, extendObservable, toJS } from "mobx";

/**
 * Converts JS or observable object to mobx observable map
 * @param {*} o - observable or JS object
 */
var _objectToObservableMap = function objectToObservableMap(o) {
  if (!o || isObservableMap(o) || isObservable(o)) {
    return o;
  }
  if (isArray(o)) {
    // return o.map(e => isObservable(e) ? e : objectToObservableMap(e));
    return o.map(function (e) {
      return isObject(e) && !isObservableMap(e) ? _objectToObservableMap(e) : e;
    });
  }
  // just added
  // else if (isObservable(o)) {
  //   return o;
  // }
  var m = observable(new Map());
  for (var _i = 0, _Object$keys = Object.keys(o); _i < _Object$keys.length; _i++) {
    var k = _Object$keys[_i];
    if (typeof o[k] === 'object' /*&& !isObservable(o[k])*/) {
      var child = isObservable(o[k]) ? o[k] : o[k] ? _objectToObservableMap(o[k]) : o[k];
      // m.set(k, isObservable(child) ? toJS(child) : child );
      m.set(k, child);
    } else {
      m.set(k, o[k]);
    }
  }
  return m;
};

/**
 * Returns value by path
 *
 * @param { object } obj source object
 * @param { string[] } path value path
 * @returns { * } value
 */
var find = function find(obj, path) {
  var length = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : path.length;
  // const trackPath = [];
  // const tarckProps = [];
  // path = toJS(path);

  var prop = obj;
  var i;
  for (i = 0; prop && i < length; i++) {
    // console.log('-- get prop', prop, 'path:', '' + path[i]) 
    if (isArray(prop) && path[i] >= prop.length) {
      return undefined;
    }
    try {
      // const part = (path[i] + "").replace(/\[|\]/g, "");
      var part = typeof path[i] === "string" && path[i].length > 2 && path[i][0] === "[" ? path[i].slice(1, -1) : path[i];
      prop = isObservableMap(prop) ? prop.get(part) : prop[part];
      // trackPath.push(path[i]);
      // tarckProps.push(toJS(prop));
    } catch (e) {
      console.error('object util find failed on prop', prop, 'path:', path, 'obj', obj, '' + path[i], i);
      // console.log('trackPath', trackPath);
      // console.log('tarckProps', tarckProps);
      // console.log('APP STORE', appStore);
      // console.log('APP STORE uiSchema', appStore);
      try {
        throw new Error();
      } catch (e) {
        // console.log(e);
      }
      // throw e;
    }

    // console.log('-- found prop', prop, i, length)
  }
  if ((prop === null || prop === undefined) && i !== length) {
    return undefined;
  }
  // console.log('found prop modelSchema', prop) ;
  return prop;
};

/**
 * Splits value path to array by splitter
 *
 * @param { (string | string[]) } path
 * @param { string } splitter
 * @returns { string[] } path parts
 */
var strToArray = function strToArray(path) {
  var splitter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '.';
  if (path === undefined) {
    return [];
  }
  return typeof path === 'string' ? path.split(splitter) : path;
};

/**
 * Returns value by path
 *
 * @param { object } obj source object
 * @param { string | string[] } path value path
 * @param { string } splitter split character, '.' by default
 * @returns { * } value
 */
var get = function get(obj, path) {
  var splitter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '.';
  if (obj === undefined || obj === null) {
    return obj;
  }
  var parts = strToArray(path, splitter);
  return find(obj, parts);
};
var createPath = function createPath(obj, pathToCreate) {
  var path = strToArray(pathToCreate);
  var prop = obj;
  for (var i = 0; i < path.length - 1; i++) {
    var nextProp = isArray(prop) ? prop[parseInt(path[i])] : prop[path[i]];
    if (!nextProp) {
      prop[path[i]] = observable(isArray(prop) ? [] : {});
      newVal = true;
    }
    prop = prop[path[i]];
  }
  return newVal;
};

/**
* Creates an object with the same keys as object and values generated by running each own
* enumerable string keyed property of object thru iteratee.
* The iteratee is invoked with three arguments: (value, key, object).
 */
var mapKey = function mapKey(object, iteratee) {
  object = Object(object);
  var result = {};
  Object.keys(object).forEach(function (key) {
    var value = object[key];
    result[iteratee(value, key, object)] = value;
  });
  return result;
};

/**
 * Sets value by path. If deleteEmpty is set to true, checks empty nested properties of the passed object
 * and removes them
 * @param { object } obj source object
 * @param { string | string[] } path value path
 * @param { * } val value
 * @param { boolean } deleteEmpty flag to delete object empty branches, by default is true
 */
var set = function set(obj, path, val) {
  var deleteEmpty = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  var newVal = false;
  try {
    //TODO: remove try/catch
    var parts = strToArray(path);
    var prop = obj;
    for (var i = 0; i < parts.length - 1; i++) {
      // const part = (parts[i] + "").replace(/\[|\]/g, "");
      var part = typeof parts[i] === "string" && parts[i].length > 2 && parts[i][0] === "[" ? parts[i].slice(1, -1) : parts[i];
      var nextProp = isObservableMap(prop) ? prop.get(part) : isArray(prop) ? prop.length > +part ? prop[part] : undefined : prop[part]; //isArray(prop) ? prop[parseInt(parts[i])] : prop[parts[i]];

      if (!nextProp) {
        var v = void 0;
        if (parts.length - 1 === i) {
          v = {};
        } else {
          v = (parts[i + 1] + "").startsWith("[") ? [] : {};
        }
        // const v = isArray(prop) ? [] : {};
        if (isObservableMap(prop)) {
          v = isArray(v) ? observable(v) : _objectToObservableMap(v);
          // prop.set(parts[i], observable(new Map()));
          prop.set(part, v);
        } else if (isObservableArray(prop)) {
          v = isArray(v) ? observable(v) : _objectToObservableMap(v);
          if (prop.length < part && Number.isInteger(part - (prop.length - 1))) {
            var _prop;
            (_prop = prop).push.apply(_prop, _toConsumableArray(Array(part - (prop.length - 1)).fill(undefined)));
          }
          prop[part] = v;
        } else if (isObservable(prop)) {
          // prop[parts[i]] = observable({});
          if (!prop.hasOwnProperty(part)) {
            // console.log('set property', prop, parts[i]);
            extendObservable(prop, _defineProperty({}, part, v));
          }
        } else {
          prop[part] = v; // observable(isArray(prop) ? [] : {});
        }
        newVal = true;
      }
      prop = isObservableMap(prop) ? prop.get(part) : prop[part];
    }

    //TODO: logic below should be refactored
    var lastPart = parts[parts.length - 1];
    lastPart = typeof lastPart === "string" && lastPart.length > 2 && lastPart[0] === "[" ? lastPart.slice(1, -1) : lastPart;
    if (isObservableMap(prop)) {
      val = isArray(val) ? arrayValuesToObservableMap(val) : isObject(val) ? _objectToObservableMap(val) : val;
      prop.set(lastPart, val);
    } else if (isObservableArray(prop)) {
      if (prop.length - 1 < lastPart && Number.isInteger(lastPart - (prop.length - 1))) {
        var _prop2;
        (_prop2 = prop).push.apply(_prop2, _toConsumableArray(Array(lastPart - (prop.length - 1)).fill(undefined)));
      }
      prop[lastPart] = isObject(val) || isArray(val) ? observable(val) : val;
    } else if (isObservable(prop)) {
      if (prop[lastPart] !== undefined) {
        prop[lastPart] = isObject(val) || isArray(val) ? observable(val) : val;
      } else {
        if (!prop.hasOwnProperty(lastPart)) {
          extendObservable(prop, _defineProperty({}, lastPart, val));
        } else {
          prop[lastPart] = isObject(val) || isArray(val) ? observable(val) : val;
        }
      }
    } else {
      prop[lastPart] = val;
    }
  } catch (e) {
    console.error(e);
  }
  return newVal;
};
var arrayValuesToObservableMap = function arrayValuesToObservableMap(array) {
  var newArray = observable([]);
  for (var i = 0; i < array.length; i++) {
    var val = array[i];
    if (isObject(val)) {
      newArray[i] = _objectToObservableMap(val);
    } else {
      newArray[i] = val;
    }
    // newArray[i] = isObject(val) ? objectToObservableMap(val) : val;
  }
  return newArray;
};
var push = function push(obj, path, val) {
  var segments = strToArray(path);
  var prop = find(obj, segments);
  if (!prop) {
    set(obj, segments, []);
    prop = find(obj, segments);
  }
  prop.push(val);
  // if (isArray(prop)) {
  //   const v = isArray(val) ? val : isObject(val) ? objectToObservableMap(val) : val
  //   prop.push(v);
  // }
  // if (isObservableMap(prop) && isObject(val)) {
  //   prop.push(objectToObservableMap(val));
  // } else if (isObservable(prop)) {
  //   prop.push(observable(val));
  // } else {
  //   prop.push(val);
  // }
};
var contains = function contains(obj, path, val) {
  var _path = strToArray(path);
  var prop = find(obj, _path);
  if (!prop) return false;
  if (isArray(prop)) return prop.indexOf(val) > -1;
  return val === prop;
};
var clear = function clear(obj, path) {
  var _path = strToArray(path);
  var prop = find(obj, _path);
  if (isObservableMap(prop)) {
    prop.clear();
  }
};
var remove = function remove(obj, path, leaf) {
  var _path = strToArray(path);
  var _leaf = leaf === undefined ? _path[_path.length - 1] : leaf;
  var prop = find(obj, _path, leaf === undefined ? _path.length - 1 : _path.length);
  if (isObservableMap(prop)) {
    prop.delete(_leaf);
  } else if (Array.isArray(prop) || isObservableArray(prop)) {
    prop.splice(_leaf, 1);
  } else {
    delete prop[_leaf];
  }
};

/**
 * Checkes if value is empty
 * @param { * } val
 * @returns { boolean } true, if value is treated as empty
 */
var isEmpty = function isEmpty(val) {
  return val === '' || val === undefined || val === null;
};

/**
 * Removes empty properties within a given path
 * @param { object } obj source object
 * @param { string[] } path path
 * @param { object } prop property leaf object
 */
var del = function del(obj, path, prop) {
  //TDOD: simplify
  delete prop[path[path.length - 1]];
  var objPath = [obj];
  prop = obj;
  for (var _i2 = 0; _i2 < path.length - 1; _i2++) {
    prop = prop[path[_i2]];
    objPath.push(prop);
  }
  var i = path.length - 1;
  while (i > 0) {
    prop = objPath[i];
    if (Object.keys(prop).length === 0) {
      delete objPath[i - 1][path[i - 1]];
    } else {
      break;
    }
    i--;
  }
};
var entries = function entries(obj) {
  if (isObservableMap(obj)) {
    return obj.entries();
  }
  return Object.entries(obj);
};

// const spread = (obj1, obj2) => {
//   const obj = isObservableMap(obj1) ? observable(new Map()) : isObservable(obj1) ? observable({}) : {};
//   const obj1keys = keys(obj1);
//   obj1keys.forEach(obj1key => {
//     obj[obj1key] = obj1[obj1key];
//   });
//   const obj2keys = keys(obj2);
//   obj2keys.forEach(obj2key => {
//     obj[obj2key] = obj2[obj2key];
//   });
//   return obj;
// };

var spread = function spread(obj1, obj2) {
  var obj = {}; // isObservableMap(obj1) ? observable(new Map()) : isObservable(obj1) ? observable({}) : {};
  var obj1keys = keys(obj1);
  obj1keys.forEach(function (obj1key) {
    set(obj, [obj1key], get(obj1, [obj1key]));
  });
  var obj2keys = keys(obj2);
  obj2keys.forEach(function (obj2key) {
    set(obj, [obj2key], get(obj2, [obj2key]));
  });
  return obj;
};
var isEmptyObject = function isEmptyObject(obj) {
  return Object.entries(obj).length === 0 && obj.constructor === Object;
};
var includes = function includes(obj, path, value) {
  var parts = strToArray(path);
  var items = find(obj, parts);
  if (!isObservableArray(items)) {
    return items.includes(value);
  }
};
var clone = function clone(value) {
  return JSON.parse(JSON.stringify(toJS(value)));
};

// const keys = (obj) => {
//   if (isObservableMap(obj)) {
//     console.log('obj.keys()', obj.keys());
//     return obj.keys();
//   }
//   return Array.from(obj.keys && obj.keys() || Object.keys(obj));
// };

var keys = function keys(obj) {
  return Array.from(typeof obj.keys === "function" && obj.keys() || Object.keys(obj));
};
var isArray = function isArray(value) {
  return Array.isArray(value) || isObservableArray(value);
};
var isObject = function isObject(value) {
  return value !== null && typeof value === 'object';
};
var isFunction = function isFunction(value) {
  return typeof value === 'function';
};

// const isObservable = value => ( value === typeof 'object' );

export default {
  get: get,
  set: set,
  find: find,
  push: push,
  remove: remove,
  contains: contains,
  clear: clear,
  keys: keys,
  entries: entries,
  mapKey: mapKey,
  includes: includes,
  strToArray: strToArray,
  isArray: isArray,
  isObject: isObject,
  isFunction: isFunction,
  isEmptyObject: isEmptyObject,
  objectToObservableMap: _objectToObservableMap,
  spread: spread,
  clone: clone,
  isObservable: isObservable,
  isObservableObject: isObservableObject,
  isObservableMap: isObservableMap,
  isObservableArray: isObservableArray,
  toJS: toJS
};
export { strToArray };