import React, {Component} from "react";
import {computed} from "mobx";
import {observer} from "mobx-react";
import $ from "@isf/core-object-util";
import {injectIntl} from "@isf/core-localization";
import PropTypes from "prop-types";
import classNames from "classnames";
import {default as Select_, createFilter} from "react-select";
import {
  DEFAULT_OPTION_BINDING,
  getComponentOptions,
  getOptionBinding,
  getOptionsValue,
  sortOptions
} from "../select/select-actions";

const filterConfig = {
  ignoreCase: true,
  ignoreAccents: false,
  trim: false,
  matchFrom: 'any'
};

@injectIntl
@observer
class Select extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: ""
    };
  }

  @computed
  get options() {
    const {intl, input, localeStore} = this.props;
    const { locale } = localeStore || {};
    const {optionsStore, options} = input;
    let componentOptions = optionsStore ? optionsStore.state : options;
    if (!$.isArray(componentOptions)) return [];

    const optionBinding = getOptionBinding(input, localeStore) || DEFAULT_OPTION_BINDING;
    componentOptions = getComponentOptions(componentOptions, optionBinding, intl);
    if (input.sortOptions !== false) {
      componentOptions = sortOptions(componentOptions, locale);
    }

    return componentOptions;
  }

  handleOnChange = (event) => {
    const {handler, accessor, input, localeStore} = this.props;
    let value = null;

    if (event && event.value) {
      value = getOptionsValue(event.value, input, localeStore);
    }

    handler.set(accessor, value);
    if (input.props && input.props.onChange) {
      input.props.onChange();
    }
  };

  getValue = () => {
    const {props, options} = this;
    const {handler, accessor} = props;
    const value = handler.get(accessor);
    return options.find(it => it.value === `${value}`) || null;
  };

  loadingMessage = () => {
    const {intl: {formatMessage}} = this.props;
    return formatMessage({id: "ui.autocomplete.loading.message"});
  };

  noOptionsMessage = () => {
    const {intl: {formatMessage}} = this.props;
    return formatMessage({id: "ui.autocomplete.options.no"});
  };

  render() {
    const {handler, accessor, input, placeholder} = this.props;
    const {props: {isPopUpLabel, labelName}} = input;
    const value = handler.get(accessor);

    let mutableProps = {};
    if (!isPopUpLabel) mutableProps.placeholder = placeholder;
    if (isPopUpLabel) {
      mutableProps = {
        placeholder: null,
      }
    }

    return (
      <div className="w-100 position-relative">
        <Select_
          options={this.options}
          onChange={this.handleOnChange}
          isSearchable={true}
          value={this.getValue()}
          getValue={this.getValue}
          filterOption={createFilter(filterConfig)}
          inputValue={this.state.inputValue}
          onInputChange={inputValue => this.setState({inputValue})}
          isClearable
          menuPlacement="auto"
          loadingMessage={this.loadingMessage}
          noOptionsMessage={this.noOptionsMessage}
          {...mutableProps}
          className={classNames(
            {
              "autocomplete has-value": isPopUpLabel && (value || this.state.inputValue),
              "autocomplete": isPopUpLabel && !(value || this.state.inputValue)
            })}
        />
        {isPopUpLabel && <span className="input-label-focus" data-placeholder={labelName}/>}
      </div>
    );
  }
}

Select.propTypes = {
  accessor: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string]).isRequired,
  handler: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
};

export default Select;
