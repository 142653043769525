import React, { Component } from "react";
import { TreeView } from "./tree-view";
import TreeStore from "./tree-store";
import { observer } from "mobx-react";
import classNames from "classnames";

@observer
class TreeViewContainer extends Component {
  constructor(props) {
    super(props);
    this.store = new TreeStore({ path: this.props.id, state: {}, observable: true });
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {className, ...other} = this.props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();
    return (
      <TreeView
        store={this.store}
        className={classNames(className, computedClassNames)}
        {...other}
      />
    );
  }
}

export { TreeViewContainer };