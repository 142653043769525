import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { routerStore } from "@isf/core-app-store";
import { inject, observer } from "mobx-react";
import classNames from "classnames";

//TODO: similar function declared in ui-engine/util, move to common (?)

export const templToPath = (template, split = "/") => {
   if (template === "#") return "#";
   const p = routerStore.pathname.split("/");
   const [path, query] = template.split("?");
   const [shortPath, hash] = path.split("#");
   const t = shortPath.split(split);

   for (let i = 0; i < p.length && i < t.length; i++) {
      if (t[i] === "{id}") t[i] = p[i];
   }

   let result = t.join(split);
   query && (result += "?" + query);
   hash && (result += "#" + hash);

   return result;
};

const isStartFromHTTP = (address) => {
   return address.indexOf("http") === 0;
};

@observer
class Link extends React.Component {
   constructor(props) {
      super(props);
      if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
      if (props.uiStoreType) this.uiStore = new props.uiStoreType(props);
   }

   componentWillUnmount() {
      this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
      this.uiBaseStore && this.uiBaseStore.dispose();
   }

   render() {
      const { children, to, uiStoreType, className, data, computed, languages, localeStore, hidden, computedClassName,
         uiBaseStore, ...others } = this.props;
      if ((this.uiStore && this.uiStore.hiddenStore.get("isHidden")) || (typeof hidden === "boolean" && hidden)) return null;
      let toTempl = (this.uiStore && this.uiStore.computedStore.get("value")) || to;
      const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
        && this.uiBaseStore.classNameStore.getClassNames();
      if (!toTempl || typeof toTempl !== "string") {
         return <a className={classNames(className, computedClassNames)}>{children}</a>;
      }
      const address = templToPath(toTempl);
      if (toTempl.includes("/new/")) return null;//temporary: hide link
      const startsFromHTTP = isStartFromHTTP(address);
      if (startsFromHTTP) {
         return <a href={address} target="_blank" className={classNames(className, computedClassNames)}>{children}</a>
      }
      return (
         <RouterLink to={address} className={classNames(className, computedClassNames)} {...others}>
               {children}
         </RouterLink>
      );
   }
}
// props.children && props.children.length > 0
//    ? props.children
//    : <Button>{props.text}</Button>

export { Link };