import React, {Fragment, Component} from "react";
import {Table} from "@isf/table";
import {Pagination} from "@isf/pagination";
import {FilterForm} from "@isf/filter";
import {observer} from "mobx-react";
import PropTypes from 'prop-types';
import {observable} from "mobx"

@observer
class TableView extends Component {

  constructor(props) {
    super(props);
  }

  initTableData() {
    this.totalRows = this.props.tableViewData.totalRows;
    this.tableData = this.props.tableViewData.rows;

    this.props.store.paginationStore.setTotalRows(this.totalRows);
    this.props.store.data = this.tableData;
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.initTableData();
    }
  }

  componentDidMount() {
    this.initTableData();
  }

  render() { 
    const { pageable, filterable, isClient, columns, store, dataStore, id, selection } = this.props;
    const isFilterActive = store.filterStore.isFilterActive;
    const isPaginationEnabled = pageable && store.paginationStore.isPaginationEnabled;
    return (
      <Fragment>
        { filterable && !isClient && isFilterActive && <FilterForm columns={columns} store={store.filterStore}/>}
        <Table id={id + '.table'} columns={columns}
               className="table-striped table-hover"
               store={store.tableStore}
               selection={selection}
               tableName={"Bootstrap Table"}
               filterable={filterable}
               isClient={isClient}
               isFilterActive={isFilterActive}
               filterStore={store.filterStore}
               dataStore={dataStore}
        />
        { <Pagination store={store.paginationStore}/> }
      </Fragment>
    )
  }
}

TableView.propTypes = {
  isClient: PropTypes.bool,
  isFilterActive: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  store: PropTypes.object.isRequired
};

export {TableView};
