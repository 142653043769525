import React, { Component } from "react";
import { observer } from "mobx-react";
import { injectIntl, localeStore } from "@isf/core-localization";
import { resolveStore } from "@isf/react-util";
import { DropdownSelect } from "@isf/dropdown";
import LocaleSelectStore from "./locale-select-store";
import LocaleOption from "./locale-option";

@injectIntl
@observer
class LocaleSelect extends Component {

  constructor(props) {
    super(props);
    
    this.store = resolveStore({
      ...props,
      type: LocaleSelectStore
    });
    if (props.localeStore) this.store.localeStore = props.localeStore;
  }

  render() {
    const { intl: { formatMessage }, ...others } = this.props;
    const { availableLocales } = localeStore;
    const options = [
      { value: "head", header: formatMessage({id: "ui.header.language.title"}) }
    ];
    availableLocales.forEach(locale => {
      options.push({value: locale.id, name: locale.name})
    });
    return (
      <DropdownSelect
        id="mainView.header.localeSelectId"
        className="my-auto locale-select"
        options={options}
        caret={false}
        right={true}
        store={this.store}
        optionTag={LocaleOption}
        {...others}
      />
    );
  }  
}

export default LocaleSelect;