import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {injectIntl} from '@isf/core-localization';
import classNames from 'classnames';
import {Container, Row, Col} from '@isf/bootstrap';
import {ReactComponent as SvgIconAccept} from '@isf/common-resources/src/images/80bf7ce6d566b8857df171bcccc2d0ac.svg';

@injectIntl
@observer
class PrivacyBar extends Component {
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    this.state = {
      isHidden: true
    };
  }

  componentDidMount() {
    this.checkingCookies();
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  checkingCookies = () => {
    if (this.props.configMode || window.localStorage.getItem('acceptCookie') !== 'allowed') {
      this.setState({isHidden: false});
    } else if (!this.state.isHidden) this.setState({isHidden: true});
  }

  handleClick = () => {
    if (!this.props.configMode) {
      window.localStorage.setItem('acceptCookie', 'allowed');
      this.checkingCookies();
    }
  }

  render() {
    const {configMode, children, className, intl} = this.props;
    if (this.state.isHidden) return null;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <section
        className={classNames("privacy-bar-general slide-up", className, computedClassNames, {
          "privacy-bar": !configMode,
          "privacy-bar-config-mode": configMode
        })}
      >
        <Container fluid className="m-0 p-0">
          <Row>
            <Col xs={12} sm className="p-0">
              <div className="privacy-bar-content">
                {children}
              </div>
            </Col>
            <Col xs={12} sm="auto" className="d-flex d-sm-block justify-content-center mt-2 mt-sm-0 mr-sm-3 p-0">
              <button className="privacy-bar-button-accept" onClick={this.handleClick}>
                <SvgIconAccept className="icon-w-10 h-auto icon-current mr-1" />
                {intl.formatMessage({id: "sdk.ui.privacyBar.button.accept"})}
              </button>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export {PrivacyBar};