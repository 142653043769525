import {UIStore} from '@isf/core-app-store';
import {action} from 'mobx';
import $ from "@isf/core-object-util";
import { createContext } from "react";

class MenuStore extends UIStore {
  constructor(props) {
    super(props);
  }

  getIsActive(path) {
    return this.get(`${path}.isActive`);
  }

  getIsExpanded(path) {
    return this.get(`${path}.isExpanded`);
  }

  @action
  setIsExpanded(path, value) {
    this.set(`${path}.isExpanded`, value || !this.getIsExpanded(path));
  }

  @action
  setIsActive(path, value) {
    this.set(`${path}.isActive`, value || !this.getIsActive(path));
    this.state && this.state.forEach((value, key) => {
      if (key !== path && $.get(value, 'isActive')) $.set(value,'isActive', false);
    })
  }
}

const MenuContext = createContext(null);

export {MenuStore, MenuContext};
