import {UIStore} from '@isf/core-app-store';
import {computed, action} from 'mobx';

class CardStore extends UIStore {
    constructor(props) {
        super({path: props.path, state: {cardCollapsed: !!props.cardCollapsed}, observableAsMap: true});
    }

    @action
    setCardCollapsed(value) {
        this.set('cardCollapsed', value);
    }

    @computed
    get isCardCollapsed() {
        return this.get('cardCollapsed');
    }
}

Object.defineProperty(CardStore, 'name', {value: 'CardStore'});

export {CardStore};