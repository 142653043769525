import React, {Fragment} from "react";
import {DragAndDropWrapper} from "./drag-and-drop"
import {TreeView/*, BranchNodeContent, LeafNodeContent*/} from "@isf/tree";
import {observer} from "mobx-react";
import Theme from "@isf/tree/src/theme";

const DraggableBranchNodeContent = observer((props) => (
    <DragAndDropWrapper {...props}>
        <BranchNodeContent {...props}/>
    </DragAndDropWrapper>
));


const DraggableLeafNodeContent = observer((props) => (
        <DragAndDropWrapper {...props}>
            <LeafNodeContent {...props}/>
        </DragAndDropWrapper>
    )
);

const updateScroll = (props) => {
    const {store, path} = props;
    if (store.get('selected') === path) {
        const containerElement = props.refToScroll;
        const childNode = findChildNode(containerElement.current, path);
        if (childNode) {
            const selectedOffsetTop = childNode.offsetTop;
            const highestPanelClientHeight = props.refToScroll.current.clientHeight;
            const halfOfHeight = (highestPanelClientHeight-41)/2;
            if(halfOfHeight>selectedOffsetTop){
                containerElement.current.parentNode.scrollTop=0;
            }else{
                containerElement.current.parentNode.scrollTop=selectedOffsetTop-halfOfHeight;
            }
        }
        store.set('selected',undefined)
    }
};

const findChildNode = (element, path) => {
    const className = element.className;
    if (className === 'Droppable') {
        if (element.attributes.path.value === path) {
            return element;
        }
    }
    const children = element.childNodes;
    for (let i = 0; i < children.length; i++) {
        const result = findChildNode(children[i], path)
        if (result) {
            return result;
        }
    }
}

const LeafNodeContent = observer(props => {
    const {Selector} = props;
    return (
        //TODO: replace with css
        <div style={{display: 'inline-flex', alignItems: 'center'}}>
            <Selector {...props} />
            <LeafNodeLabel {...props} />
        </div>
    )
});


@observer
class LeafNodeLabel extends React.Component {
    componentDidMount() {
        updateScroll(this.props)
    }

    componentDidUpdate() {
        updateScroll(this.props)
    }

    render() {
        const {props} = this;
        const {activation, LabelContent, handleActiveNodeChange} = props;
        const active = isActive(props);
        return (
            <Theme.label active={active}
                         onClick={activation ? () => handleActiveNodeChange(props) : null}>
                <LabelContent {...props} />
            </Theme.label>
        )
    }
};

const isActive = ({store, path, activation}) => (
    activation && store.get && store.get(['active', path])
);

@observer
class BranchNodeContent extends React.Component {
    componentDidMount() {
        updateScroll(this.props)
    }

    componentDidUpdate() {
        updateScroll(this.props)
    }
    render() {
        const {props}=this;
        const {
            expanded, level, Theme, LabelContent, Selector,
            handleActiveNodeChange, handleExpandCollapse, activation
        } = props;
        const active = isActive(props);
        return (
            //TODO: replace with css
            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                <Theme.twistie expanded={expanded} level={level}
                               onClick={handleExpandCollapse}/>
                <Selector {...props} />
                <Theme.label
                    active={active}
                    level={level}
                    onClick={activation ? () => handleActiveNodeChange(props) : null}
                >
                    <LabelContent {...props} />
                </Theme.label>
            </div>
        )
    }
};

const DraggableTreeView = observer((props) => {
        return (<TreeView
            BranchNodeContent={DraggableBranchNodeContent}
            LeafNodeContent={DraggableLeafNodeContent}
            {...props}/>)
    }
);

export {DraggableTreeView};