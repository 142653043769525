import {action,computed} from "mobx"
import {ToggleStore,SchemaDataStore,NewDiagramConf,schemaConverterFromJSON,debugApi} from "@isf/common";
import {routerStore} from "@isf/core-app-store";

class BpToggleStore extends ToggleStore{

  constructor({path}){
    super({path})
    this._schemaDataStore = new SchemaDataStore({schema:schemaConverterFromJSON(NewDiagramConf,new Map())})
  }

  _schemaDataStore;


  @computed
  get schemaDataStore(){
    return this._schemaDataStore;
  }

    
  toggle=async()=> {
    const conf = await this.getServiceConf();
    const processInstanceId = routerStore.get('query.processInstanceId');

     this.schemaDataStore.setSchema(conf[0].businessProcess.bpmnDiagram);
     if (processInstanceId) {
      this.schemaDataStore.setProcessStatuses(await this.getExecuted(processInstanceId));
     } else {
      this.schemaDataStore.setProcessStatuses([]);
     }
    // this.schemaDataStore.setProcessStatuses([]);
     super.toggle();
  };

  getServiceConf = async () => {
    const context = routerStore.get('path.1');
    return debugApi.request({
      path: 'conf/service/search',
      options: {
        method: 'post',
        body: JSON.stringify({
          "context": [{
            "value": context,
            "operation": "equals"
          }
          ]
        })
      }
    });
  }

  getSchema = async (processInstanceId) => {
    if (processInstanceId) {
      return await debugApi.request({
        path: `process-instance/${processInstanceId}/bpmn-json`,
      })
        .then(response => {
          return schemaConverterFromJSON(response,new Map())
        })
    }
  };
  
  getExecuted = async (processInstanceId) => {
    if (processInstanceId) {
      return await debugApi.request({
        path: `process-instance/${processInstanceId}/executions`,
      })
        .then(response => {
         return response.responseMap;
        })
    }
  };


}
export const bpToggleStore = new BpToggleStore({path: 'bpToggle'});