import React from "react";
import { observer } from "mobx-react";
import { TabPane as _TabPane } from 'reactstrap';

const TabPane = observer((props) => {
  const { name, navStore, children } = props;
  !navStore.stateJS.active
  return (
    <_TabPane tabId={name}>
      {children}
    </_TabPane>
  )
});

export default TabPane;