const resolveInitialLocale = () => (
  localStorage.getItem("locale")          // return locale from browser store if "locale" key exists in the store
  ||
  // return locale from user agent (browser UI language).
  // If be return by
  (navigator.language.split(/[-_]/)[0] === 'be'
    ? 'by'
    : navigator.language.split(/[-_]/)[0]
  )
);

const loadLocaleIntlData = (locale) => {
  return import('react-intl/locale-data/' + locale);
}

export { resolveInitialLocale, loadLocaleIntlData };
