import React, {Fragment} from "react";
import {inject, observer} from "mobx-react";
import {injectIntl} from "react-intl";
import {Col, FormFeedback, FormGroup, FormText, Label} from "reactstrap";
import PropTypes from "prop-types";
import {resolveStore, useInputHandler} from "@isf/react-util";
import classNames from "classnames";
import Input from "./input";
import DateInput from "./date-input";
// import DateRangeInput from "./date-range-input";
import localeStore from "../../core/localization/src/locale-store";
import SwitchInput from "./switch-input"
import Autocomplete from "./autocomplete/autocomplete"
import {DataStore} from "@isf/core-app-store";
import {Message} from "./message";

import $ from "@isf/core-object-util";
import FileInput from "./file-input/file-input";
import {LocaleFieldInput} from "./lacole-field-input";
import {Rating} from "./rating";


const RU=("А-а-Б-б-В-в-Ґ-ґ-Г-г-Д-д-Е-е-Ё-ё-Є-є-Ж-ж-З-з-И-и-І-і-Ї-ї-Й-й-К-к-Л-л-М-м-Н-н-О-о-П-п-Р-р-С-с-Т-т-У-у-Ф-ф-Х-х-Ц-ц-Ч-ч-Ш-ш-Щ-щ-Ъ-ъ-Ы-ы-Ь-ь-Э-э-Ю-ю-Я-я").split("-");
const EN=("A-a-B-b-B-b-G-g-G-g-D-d-E-e-E-e-E-e-ZH-zh-Z-z-I-i-I-i-I-i-J-j-K-k-L-l-M-m-H-h-O-o-P-p-P-P-C-c-T-t-U-u-F-f-H-h-TS-ts-CH-ch-SH-sh-SCH-sch-'-'-Y-y-'-'-E-e-YU-yu-YA-ya").split("-");

const convertCyrToLat = (cyrString, RU, EN) => {
    let res = '';
    for(let i=0, l=cyrString.length; i<l; i++)
    {
        let s = cyrString.charAt(i), n = RU.indexOf(s);
        if(n >= 0 && n < EN.length) { res += EN[n]; }
        else { res += s; }
    }
    return res
};

const resolveInputComponent = ({input}) => {
    if (input.props.type === 'datetime') {
        return DateInput;
    }
    if (input.props.type === 'date') {
        return DateInput;
    }
    if (input.props.type === 'switch') {
        return SwitchInput;
    }
    if (input.props.type === 'autocomplete') {
        return Autocomplete;
    }
    if (input.props.type === "rating") {
        return Rating;
    }

    if (["file", "json", "OpenApi"].includes(input.props.type)) {
        return FileInput;
    }
    return Input;
};

const FieldInput = observer(({input, handler, intl, readOnly, accessor, error, languages, constructorView, localeStore, onChange, onAfterChange, uiStore}) => {
    const col = input.layout.col;
    const props = {input, handler, disabled: readOnly, accessor, onChange, onAfterChange, uiStore};
    const type = $.get(input, "props.type") === "text" ? "text" : "switch";
    const Input = resolveInputComponent(props);
    let placeholder = '', supportText = '';
    if (input.placeholder) {
        const message = input.placeholder.message;
        placeholder = intl.messages[message.id];
        if (placeholder === undefined || placeholder === "" || placeholder === " ") {
            placeholder = localeStore.getMessageFromLocale(message.id, "ru");
        }
    }
    if (input.supportText) {
        const message = input.supportText.message;
        supportText = intl.messages[message.id];
        if (supportText === undefined || supportText === "" || supportText === " ") {
            supportText = localeStore.getMessageFromLocale(message.id, "ru");
        }
    }
    if (languages) {

        return (
            <Col {...col} className="d-flex align-items-center">
                <LocaleFieldInput {...{input, handler, intl, readOnly, accessor, error, languages, localeStore, constructorView, placeholder, type}}/>
            </Col>
        )
    }

    if (col) {
        return (
            <Fragment>
                <Col
                    {...(input.props.type === "checkbox" || input.props.type === "switch") && input.props.isLabelRight ? {xs: 'auto'} : col}
                    className={classNames(
                        "d-flex align-items-center",
                        {'pr-0': (input.props.type === "checkbox" || input.props.type === "switch") && input.props.isLabelRight}
                    )}
                >
                    <div className="d-flex flex-column w-100 position-relative">
                        <Input {...props} placeholder={placeholder} localeStore={localeStore}/>
                        {/*{!!error &&*/}
                        {/*<FormFeedback style={{display: 'block'}}>{error.description}</FormFeedback>*/}
                        {/*}*/}
                        {/*<Button size='sm' color="link" outline onClick={()=>console.log('click')}>*/}
                        {/*   <img src={ICON_EDIT} className="icon-w-18 h-auto" />*/}
                        {/*</Button>*/}

                        {!readOnly &&
                            <>
                        {!!error &&
                            <FormFeedback style={{display: 'block'}}>{$.get(error, 'message') ? $.get(error, 'message') : $.get(error, 'description')}</FormFeedback>
                        }
                            {supportText && (<FormText color="muted">{supportText}</FormText>)}
                            </>
                        }
                    </div>
                </Col>
            </Fragment>
        )
    }
    return (
        <Input {...props} />
    );
});

const FieldLabel = observer(props => {
    const {label, intl} = props;
    const {text, message, required} = label;
    return (
        <Label {...label.props}>
            <div className={label.float}>
                <p className={'m-0'}>
                    <Message text={text} message={message}/>
                    {required &&
                    ' *'
                        /* <div style={{color:'red', marginLeft:5}}>{'*'}</div>*/
                    }
                </p>
            </div>
        </Label>
    );
});

const adjustOnChange = (props) => {
    let {input, onChange, data} = props;
    const inputOnChange = input && input.props && input.props.onChange;
    if (onChange && data) {
        if (typeof data === 'object') {
            onChange = onChange.bind(null, data, "Field");
        }
    }

    return (e) => {
        inputOnChange && inputOnChange(e);
        onChange && onChange(e, "Field");
    };
};

const adjustInputProps = (props, layout, inputId, handler, readOnly, uiStore) => {
    let {
        input, label, accessor, intl, localized, languages: lang, constructorView, localeStore, onChange,
        // Use for autocomplete
        onAfterChange,
    } = props;
    const languages = localized ? lang : undefined;
    if (languages) {
        const currentLocale = intl.locale;
        languages.sort((a, b) => a.lang === currentLocale ? -1 : 0);
    }
    const error = handler.store && handler.store.getErrorByAccessor
      && (["file"].includes(input && input.props && input.props.type) && typeof accessor === "string" && handler.store.getErrorByAccessor(accessor + ".id")
        || handler.store.getErrorByAccessor(accessor));

    input = {
        ...$.toJS(layout).input,
        ...input,
        props: {
            ...input.props,
            onChange: adjustOnChange(props)
        }
    };
    input.props.id = inputId;
    input.props.invalid = !!error;

    if (input.props.isPopUpLabel) {
        let labelName;
        if (label.message) {
            labelName = intl.formatMessage({
                id: label.message.id,
                defaultMessage: localeStore.getMessageFromLocale(label.message.id, "ru")
            })
        } else if (label.text) labelName = label.text;
        input.props.labelName = labelName;
    }
    return {
        input, handler, readOnly, intl, accessor, error, languages, constructorView, localeStore, onChange, uiStore,
        onAfterChange,
    };
};

const adjustLabelProps = (label, layout, id, inputId, intl, required) => {
    label = {...$.toJS(layout).label, ...label};
    label.props.id = label.id ? label.id : "lb_" + id;
    label.props.for = inputId;
    label.required = required;
    return {label, intl};
};

const convertToAutocomplete = (props, value, schema_RU, schema_EN) =>{
    const autocompleteCase = $.get(props,'autocomplete.case');
    const cyrToLat = $.get(props,'autocomplete.cyrToLat');
    let resultValue = value;
    if(cyrToLat && value){
        resultValue = convertCyrToLat(resultValue, schema_RU, schema_EN);
    }
    if(autocompleteCase && value){
        if(autocompleteCase === "upper") {
            return resultValue.toUpperCase();
        }
        else
            return resultValue.toLowerCase();
    }
    return resultValue;
};

const resolveInputHandler = (props) => {
    let {handler, store, accessor} = props;
    let RU_ = $.get(props,'autocomplete.cyrToLatSchema.RU');
    RU_ = typeof RU_ === "string" ? RU_.split("-") : RU;
    let EN_ = $.get(props,'autocomplete.cyrToLatSchema.EN');
    EN_ = typeof EN_ === "string" ? EN_.split("-") : EN;
    if (handler) {
        const setHandlerAction = handler.set;
        $.set(handler, 'set', (path, value) => {
            const errorStore = handler.store || store;
            if (errorStore && errorStore.getErrorByAccessor && errorStore.getErrorByAccessor(path)) {
                errorStore.removeErrorByAccessor(path);
            }
            setHandlerAction(path, convertToAutocomplete(props, value, RU_, EN_));
        });
    }
    if (props.data) {
        const {store: listStore, index: listIndex} = props.data;
        return {
            get: (path) => {
                const newPath = listIndex + "." + path;
                return listStore ? listStore.data ? $.get(listStore.data, newPath) : listStore.get(newPath) : handler.get(path);
            },
            set: (path, value) => {
                const newPath = listIndex + "." + path;
                const autocompleteValue = convertToAutocomplete(props, value, RU_, EN_);
                return listStore ? listStore.data ? $.set(listStore.data, newPath, autocompleteValue) : listStore.set(newPath, autocompleteValue) : handler.set(path, autocompleteValue);
            },
            store: {
                fileApi: listStore.fileApi
            }
        }
    }
    if (handler)
        return handler;
    if (!store) {
        throw new Error("No data store defined for field", props);
    }
    store = resolveStore({store, type: DataStore});
    return useInputHandler({accessor, store})().handler;
};

@injectIntl
@observer
class Field extends React.Component {
   constructor(props) {
      super(props);
      const {configsStore, ...other} = props;
      this.handler = resolveInputHandler(other);
      const validation = $.get(props, "validation");
      const accessor = $.get(props, "accessor");
      const handler = $.get(props, "handler");
      if(validation && validation!== "false" && accessor && handler){
         const store = $.get(handler, 'store');
         if (store){
            validation['refId'] = props.refId;
            store.addFrontValidation(accessor,validation)
         }
      }
      if (props.uiStoreType) {
         this.uiStore = new props.uiStoreType(props)
      }
   }

   componentWillUnmount() {
       this.uiStore && this.uiStore.dispose && this.uiStore.dispose()
   }

    render() {
        const {store} = this.handler;
        const {configsStore, templates, ...other} = this.props;
        const hidden = !this.uiStore && typeof (this.props.hidden) === "boolean" && this.props.hidden;
        const propsReadOnly = !this.uiStore && (this.props.readOnly === 'true');// && this.props.readOnly;
        if (this.uiStore && this.uiStore.hiddenStore.get('isHidden') || hidden) return null;
        const readOnly = (this.uiStore && this.uiStore.readOnlyStore.get('isReadOnly')) || propsReadOnly;
        let {id, label, input, handler, accessor, row, className, style, layout, intl, FieldLabel, FieldInput,
            localized} = this.props;
        const required = $.get(input, 'props.required');
        if (!layout) {
            layout = 'default'
        }
        if (this.props.data) {
            id = id + this.props.data.index;
        }

        // const error = store && store.getErrorByAccessor && store.getErrorByAccessor(accessor);
        // const resultLayout = /*accessor === 'filter' && $.get(label, 'hidden') ? filterLayoutWithoutLabel :*/ (templates && $.get(templates, layout + "")) || defaultLayout;
        const resultLayout = typeof (layout) === 'string' ? (templates && $.get(templates, layout + "")) || defaultLayout : layout;

        const inputId = input.id ? input.id : "in_" + id;
        return (
            <FormGroup
                id={id}
                row={$.toJS(resultLayout).row !== false}
                className={classNames(className, {
                    'flex-row-reverse justify-content-end':
                        input.props && (input.props.type === "checkbox" || input.props.type === "switch")
                            && input.props.isLabelRight,
                    'mb-0': localized,
                    'mx-n3-custom': !$.toJS(resultLayout).row
                })}
                style={style}
                onClick={this.props.onClick}
            >
                {!label.hidden &&
                <FieldLabel {...adjustLabelProps(label, resultLayout, id, inputId, intl, required)} />
                }
                {!$.toJS(resultLayout).row && <br/>}
                <FieldInput {...adjustInputProps(this.props, resultLayout, inputId, this.handler, readOnly, this.uiStore)}  />
                {/*adjustInputProps(input, resultLayout, inputId, this.handler, readOnly, intl, accessor, error)*/}
            </FormGroup>
        );
    }
}

// const Field = observer(props => {
//   const {configsStore,uiStore, ...other} = props;
//   if(uiStore && uiStore.hiddenStore.get('isHidden') || props.hidden) return null;
//   let { id, label, input, handler, accessor, row, className, FieldLabel, FieldInput } = props;
//   const inputId = input.id ? input.id : "in_" + id;
//
//   handler = resolveInputHandler(other);
//   return (
//     <FormGroup id={id} row={row} className={className} onClick={props.onClick}>
//       <FieldLabel {...adjustLabelProps(label, id, inputId)} />
//       <FieldInput {...adjustInputProps(input, inputId, handler, accessor)} />
//     </FormGroup>
//   );
// });

Field.propTypes = {
    id: PropTypes.string,               // field id
    label: PropTypes.object.isRequired, // field label props
    input: PropTypes.object.isRequired, // field input props
    FieldLabel: PropTypes.elementType,
    FieldInput: PropTypes.elementType,
    handler: PropTypes.object, //.isRequired,
    dataStore: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    accessor: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]).isRequired
};

const filterLayoutWithoutLabel = {
    input: {
        layout: {
            col: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            }
        },
        props: {
            type: "text"
        }
    },
};

const defaultLayout = {
    row: true,
    input: {
        layout: {
            col: {
                xs: 8,
                sm: 8,
                md: 7,
                lg: 6,
                xl: 6,
            }
        },
        props: {
            type: "text"
        }
    },
    label: {
        text: '',
        float: 'text-left',
        props: {
            xs: 4,
            sm: 4,
            md: 5,
            lg: 6,
            xl: 6
        }
    }
};

const defaultProps = {
    FieldLabel,
    FieldInput
};

Field.defaultProps = defaultProps;

export default Field;
