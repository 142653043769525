import $ from "@isf/core-object-util";

// const getFilterHistory = (action) => {
//   const filter = $.get(action, ["history", "filter"]);
//   return !filter ? {} : filter;
// };

/**
 * Compare two object.
 * @param {*} x
 * @param {*} y
 * @returns {boolean}
 */
const deepCompare = (x, y) => {
  if (x === y) {
    return true
  }

  const xType = typeof x;
  const yType = typeof x;
  const isObj = xType === 'object' && x && yType === 'object' && y;
  if (!isObj){
    return false;
  }


  // Handle arrays
  if (Array.isArray(x)) {
    if (!Array.isArray(y)) {
      return false;
    }

    const length = x.length;

    if (length !== y.length) {
      return false;
    }

    for (let i=0; i < length; i++) {
      if (!deepCompare(x[i], y[i])) {
        return false;
      }
    }
    return true;
  }

  const xProps = Object.keys(x);
  const yProps = Object.keys(y);
  const numKeys = xProps.length;
  if (yProps.length !== numKeys) {
    return false;
  }

  // Empty object on both sides?
  if (numKeys === 0) {
    return true;
  }

  // sort is a native call so it's very fast - much faster than comparing the
  // values at each key if it can be avoided, so do the sort and then
  // ensure every key matches at every index
  xProps.sort();
  yProps.sort();

  // Ensure perfect match across all keys
  for(let i = 0; i < numKeys; i++) {
    if (xProps[i] !== yProps[i]) {
      return false;
    }
  }

  // Ensure perfect match across all values
  for(let i = 0; i < numKeys; i++) {
    if (!deepCompare(x[xProps[i]], y[yProps[i]])) {
      return false;
    }
  }
  return true;
}

// If action call from on of this component
/**
 * Component name that call search request with filter from history
 * If the filter is changed, but the search button is not pressed,
 * we need to get the filter from the history.
 * If the filter has not changed, then it is equal to the history.
 * @type {string[]}
 */
const COMPONENT_WITH_HISTORY_FILTER = ["PaginationSize", "Pagination", "TableSort"];
// const FILTER_ACTION = ["Button", "Filter"];

const getSearchBody = (action, stateBody, componentName, requestObj) => {
  const bodyFilter = $.get(stateBody, "filter") || {};

  if (!$.get(action, ["history", "filter"])) {
    $.set(action, ["history", "filter"], bodyFilter);
  }

  const historyFilter = $.get(action, ["history", "filter"]);
  const newBody = {...stateBody};

  if (COMPONENT_WITH_HISTORY_FILTER.includes(componentName)) {
    newBody["filter"] = historyFilter;
    return newBody;
  }

  const isFilterEqual = deepCompare(bodyFilter, historyFilter);
  /**
   * If the filter is changed and the component name is not contained
   * in the 'COMPONENT_WITH_HISTORY_FILTER',
   * we need to update the history and set the pagination, if it exists, to 1
   */
  if (!isFilterEqual) {
    const paging = newBody["paging"];
    if($.isObject(paging) && $.keys(paging).length > 1 && "page" in paging) {
      requestObj.set("body.paging.page", 1);
      newBody["paging"]["page"] = 1;
    }
    $.set(action, ["history", "filter"], bodyFilter);
  }

  return newBody;
};


export {getSearchBody};
