import Store from "./store";
import {action, computed, observable} from "mobx";
import $ from "@isf/core-object-util";

class DataStore extends Store {

    @observable
    _errorState;

    constructor(args) {
        const {outerMapping, outerStore, ...other} = args;
        super({scope: 'data',  ...other});
        // this.makeObservable({asMap: true});
        this._stateFiles = {};
        this._errorState = $.objectToObservableMap({messages: {},frontValidation:{}});
        this._outerMapping = outerMapping;
        this._outerStore = outerStore;
        // this.fileApi = args.fileApi;
    }

    get fileApi(){
        return this._fileApi;
    }

    set fileApi(fileApi){
        this._fileApi = fileApi;
    }

    get(path, pureJS) {
        if(this._outerStore) {
            const resultPath = this.getResultPath(path);
            return this._outerStore.get(resultPath);
        }
        return super.get(path, pureJS);
    }

    set(path, value) {
        if(this.getErrorByAccessor(path)){
            this.removeErrorByAccessor(path)
        }
        // if (value instanceof FileList) {
        //    for (let file of value) {
        //       const id = uuidv4();
        //
        //       this.setFile(id, file);
        //       this.removeFile(this.get(path));
        //       super.set(path, id);
        //    }
        //    return;
        // }
        if(this._outerStore) {
            const resultPath = this.getResultPath(path);
            this._outerStore.set(resultPath, value);
        }else {
            super.set(path, value);
        }
    }

    get state() {
        if(this._outerStore)
            return this._outerStore.state;
        return super.state;
    }

    get stateJS() {
        if(this._outerStore) {
            const stateJS = this._outerStore.stateJS;
            return this.reverseState(stateJS);
        }
        return super.stateJS;
    }

    setState(state,invalidate){
        if(this._outerStore) {
            this.changeState(state);
        }else {
            super.setState(state, invalidate);
            this._errorState = $.objectToObservableMap({messages: {},frontValidation:{}});
        }
    }

    getResultPath(path){
        let resultPath = path;
        if(this._outerMapping){
            resultPath = typeof path === 'string' ? [path] : path;
            const pathsMappings = $.get(this._outerMapping,'pathsMappings')
            for(let i=0;i<pathsMappings.length;i++){
                const mapping = pathsMappings[i];
                if(this.pathsIsEquals(resultPath,$.get(mapping,'pathRef'))){
                    resultPath = $.get(mapping,'path');
                    break;
                }
            }
        }
        return resultPath;
    }

    pathsIsEquals(path1,path2){
        if(path1.length === path2.length){
            for(let i=0;i<path1.length;i++){
                if(path1[i]!==path2[i]){
                    return false;
                }
            }
            return true;
        }
    }

    get stateFiles() {
        return this._stateFiles;
    }

    setFileAccessor(accessor, value = true) {
        this._stateFiles[accessor] = value;
    }

    getFileAccessor(accessor) {
        return this._stateFiles[accessor];
    }

    removeFileAccessor(accessor) {
        delete this._stateFiles[accessor];
    }

    @computed
    get errorState() {
        return this._errorState;
    }

    @action
    setErrorState(errorState) {
        this._errorState = errorState;
    }

    @action
    addToErrorState(path,value){
        let resultPath = this.getResultPath(path);
        if(resultPath){
            if(Array.isArray(resultPath)){
                resultPath = ["messages", ...resultPath]
            }else {
                resultPath = "messages." + resultPath;
            }
        }else{
            resultPath = "messages";
        }
        if(this._outerStore) {
            $.set(this._outerStore.errorState,resultPath, value)
        }else {
            $.set(this._errorState, resultPath, value)
        }
    }

    @action
    addFrontValidation(path,value){
        let resultPath = this.getResultPath(path);
        if(resultPath){
            if(Array.isArray(resultPath)){
                resultPath = ["frontValidation", ...resultPath]
            }else {
                resultPath = "frontValidation." + resultPath;
            }
        }else{
            resultPath = "frontValidation";
        }
        if(this._outerStore) {
            $.set(this._outerStore.errorState,resultPath, value)
        }else {
            $.set(this._errorState, resultPath, value)
        }
    }

    getErrorByAccessor(accessor){
        let resultPath = this.getResultPath(accessor);
        if(resultPath){
            if(Array.isArray(resultPath)){
                resultPath = ["messages", ...resultPath]
            }else {
                resultPath = "messages." + resultPath;
            }
        }else{
            resultPath = "messages";
        }
        if (this._outerStore) {
            return $.get(this._outerStore.errorState, resultPath);
        }
        return $.get(this._errorState, resultPath);
    }



    @action
    removeErrorByAccessor(accessor){
        let resultPath = this.getResultPath(accessor);
        if(resultPath){
            if(Array.isArray(resultPath)){
                resultPath = ["messages", ...resultPath]
            }else {
                resultPath = "messages." + resultPath;
            }
        }else{
            resultPath = "messages";
        }

        if(this._outerStore) {
            $.remove(this._outerStore.errorState,resultPath)
        }else {
            $.remove(this._errorState, resultPath)
        }
    }

    // payload() {
    //    if (Object.keys(this._stateFiles).length < 1) {
    //       return JSON.stringify(this.stateJS);
    //    }
    //
    //    const data = new FormData();
    //    data.append("state", JSON.stringify(this.stateJS));
    //
    //    for (let fileId in this._stateFiles) {
    //       data.append(fileId, this._stateFiles[fileId]);
    //    }
    //    return data;
    // }

    transformState(state){
        const pathsMappings = $.get(this._outerMapping,'pathsMappings');
        pathsMappings.map(mapping=>{
            const refPath = $.get(mapping,'pathRef');
            const value = $.get(state,$.get(mapping,'pathRef'));
            $.remove(state,refPath);
            $.set(state,$.get(mapping,'path'),value);
        });
        return state;
    };

    reverseState(state){
        const pathsMappings = $.get(this._outerMapping,'pathsMappings');
        pathsMappings.map(mapping=>{
            const path = $.get(mapping,'path');
            const value = $.get(state,$.get(mapping,'path'));
            $.remove(state,path);
            $.set(state,$.get(mapping,'pathRef'),value);
        });
        return state;
    }

    changeState(state){
        const changedState = this.transformState(state);
        for(let key in changedState){
            this._outerStore.set(key,$.get(changedState,key));
        }
    }

}


Object.defineProperty(DataStore, "name", {value: "DataStore"});

export default DataStore;