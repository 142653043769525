import {UIStore} from "@isf/core-app-store";
import {observable, action, computed} from 'mobx';

class PageTemplateStore extends UIStore {
  
  @observable _active;

  constructor({path='pageTemplate'}={}) {
    super({path});
    this._active = this.setValue();
    this.set('isActive', this._active);
  }

  @action
  setActive(value) {
    this._active = value;
    this.set('isActive', value);
    localStorage.setItem('pageTemplateMenuActive', value);
    const body = document.body;
    if(this._active) {
      body.classList.add("body-overflow");
    } else {
      body.classList.remove("body-overflow");
    }
  }

  @computed
  get isActive() {
    return this._active;
  }

  setValue() {
    let value = true;
    let localStorageValue = localStorage.getItem('pageTemplateMenuActive');
    if(localStorageValue === "true") {
      value = true;
      if(screen.width <= 533) {
        const body = document.body;
        body.classList.add("body-overflow");
      }
    } else {
      if(localStorageValue === "false") {
        value = false;
      } else {
        if(screen.width <= 533) {
          value = false;
        }
      }
    }
    return value;
  }

}

export default PageTemplateStore;
