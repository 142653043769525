import { DataStore } from "@isf/core-app-store";

class ServicesTableRequestStore extends DataStore {
  constructor(props) {
    super(props);
  }

  getLike(path, pureJS) {
    const value = super.get(path, pureJS);
    if (value === undefined || value === null) return null;
    else return value.replace(/%/g, "");
  }

  setLike(path, value) {
    if (value === undefined || value === null) super.set(path, null);
    else super.set(path, `${value}%`);
  }
}

Object.defineProperty(ServicesTableRequestStore, "name", {value: "ServicesTableRequestStore"});

export { ServicesTableRequestStore };