import React from "react";
import PropTypes from 'prop-types';
import { NavLink as NavLink_ } from "reactstrap";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { inject } from "mobx-react";

const handleClick = (e, { navFor, navStore, toggle, onClick }) => {
  if (toggle) {
    navStore.toggleActive(navFor);
  } else {
    navStore.setActive(navFor, true);
  }
  onClick && onClick(e);
};

const NavLink = inject('navStore')(observer(props => {
  const { id, navStore, navFor, href = '#', toggle, onClick, ...other } = props;
  const active = navStore && navStore.isActive(navFor);
  //for query params in url
  const to = href === "#" ? window.location : href;
  return (
    <NavLink_
      className="h-100 d-inline-flex align-items-center"
      id={id}
      tag={Link}
      to={to}
      {...other}
      active={active}
      onClick={!(navFor && navStore) ? undefined :
        e => handleClick(e, { id, navStore, navFor, toggle, onClick })}
    />
  );
}));

NavLink.propTypes = {
  ...NavLink_.propTypes,
  navStore: PropTypes.object,
  navFor: PropTypes.string,
  toggle: PropTypes.bool
}

export { NavLink };