import {getDeclaredString} from "../expression/utils";
import { localeStore } from "@isf/core-localization";
import $ from "@isf/core-object-util";

export const computeExpression = (config, data, context) => {
    const params = $.toJS($.get(config, "params"));
    const expression = $.get(config, "expression");
    const refId = $.get(config, "refId");
    if (params && params.length !== 0) {
        let declaredString = "";
            declaredString = getDeclaredString(params, localeStore, data, refId?{refId:refId}:context);
            if(declaredString !== "let;")
                return eval(declaredString + expression);
    } else {
        return eval(expression);
    }
};