import {localeStore} from "@isf/core-localization";
import {restApi} from "@isf/ui-schema";

async function loadAvailableLocale() {
  const actionConfig = {"path": "/conf/language/v1"};

  const language = await restApi.request(actionConfig);
  if (Array.isArray(language)) {
    localeStore.availableLocales = language.map(it => ({
      id: it.code,
      lang: it.code,
      name: it.nativeName,
      locale: it.locale
    })).sort((itA, itB) => itA.name.localeCompare(itB.name));
    const { locale } = localeStore;
    const localeExist = localeStore.availableLocales.find((it) => it.id === locale);
    // if locale doesn't exist in availableLocales set 'en' locale
    if (!localeExist) {
      await localeStore.setLocale('en');
    }
  }
}

export {loadAvailableLocale};
