import React from 'react'

export const WEEKDAYS = [
    {
        weekDay: "MONDAY"
    },
    {
        weekDay: "TUESDAY"
    },
    {
        weekDay: "WEDNESDAY"
    },
    {
        weekDay: "THURSDAY"
    },
    {
        weekDay: "FRIDAY"
    },
    {
        weekDay: "SATURDAY"
    },
    {
        weekDay: "SUNDAY"
    }
];