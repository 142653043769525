import { observable } from "mobx";

class LocaleMessageStore {

    @observable showAllLanguages = false;

    setShowAllLanguages(value) {
        this.showAllLanguages = value;
    }

    isAllLanguagesShawn() {
        return this.showAllLanguages;
    }
}

export { LocaleMessageStore };