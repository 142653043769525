import {UIStore} from "@isf/core-app-store";

class SignVerifyStore extends UIStore {
    _action;
    _cancelAction;

    set action(action) {
        this._action = action
    }

    get action() {
        return this._action;
    }

    set cancelAction(cancelAction) {
        this._cancelAction = cancelAction
    }

    get cancelAction() {
        return this._cancelAction;
    }

    setDefaultState() {
        this.set(["loading"], false);
    }
}

export {SignVerifyStore};
