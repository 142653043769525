import { computed } from "mobx";
import RestHandler from "./rest-handler";
import RestResource from "./rest-resource";

class RestApi {

  schema;

  host;

  port;

  context;

  handler;

  defaultOptions;

  constructor({ schema, host, port, context, handler, defaultOptions }) {
    this.schema = schema || location.protocol.replace(':', '');
    this.host = host || location.hostname;
    this.port = port || location.port;
    this.context = context;
    this.handler = handler || new RestHandler();
    this.defaultOptions = defaultOptions || { credentials: 'include', headers: {'Accept': 'application/json', 'Content-Type':'application/json'}};
  }

  @computed
  get url() {
    const { schema, host, port, context } = this;
    return `${schema}://${host}${port ? ':' + port : ''}${context ? '/' + context : ''}`;
  }

  async request({ path, pathParams, queryParams, options: requestOptions, handler = this.handler}) {

    const url = buildRequestUrl({ apiUrl: this.url, path, pathParams, queryParams });
    let response;
    const options = buildOptions(requestOptions, this.defaultOptions);
    try {
      response = await request({ url, options });
      return handler.handleResponse({ request: { path, pathParams, queryParams, options }, response, api: this });
    } catch (e) {
      // console.log(e);
      // handler.handleRequestError({
      //   url,
      //   path,
      //   pathParams,
      //   queryParams,
      //   options
      // },
      // e);
      return Promise.reject();
    }
  }

  resource(path) {
    return new RestResource({path, api: this, handler: this.handler});
  }

}

const buildOptions = (requestOptions, defaultOptions) => {
  if (!requestOptions) {
    return defaultOptions;
  }
  if (!defaultOptions) {
    return requestOptions;
  }
  return {...requestOptions, ...defaultOptions};
}

const buildRequestUrl = ({ apiUrl, path, pathParams, queryParams }) => {
  const contextPath =
    path.startsWith('/')
      ? apiUrl + path
      : `${apiUrl}/${path}`;
  return (
    `${contextPath}${pathParams
      ? '/' + paramsToPathStr(pathParams)
      : ''}${queryParams ? '?' + paramsToQueryStr(queryParams) : ''}`);
}

/**
 * Converts parameters object to query string
 * Example:
 * params = {
 *  "paramOne": "paramOneValue",
 *  "paramTwo": "paramTwoValue"
 * }
 * returns "paramOne=paramOneValue&paramTwo=paramTwoValue"
 * @param {*} params
 */
const paramsToQueryStr = (params) => (
  Object.keys(params).map(key => {
    if (Array.isArray(params[key])) {
      return params[key].map(item => `${key}=${item}`).join("&");
    }

    return `${key}=${params[key]}`
  }).join('&')
)

/**
 * Converts parameters array to parameter path
 * Example:
 * params = [
 *  primitiveValue,
 *  {"paramOne": "paramOneValue"},
 *  {"paramTwo": "paramTwoValue"}
 * ]
 * returns "primitiveValue/paramOne/paramOneValue/paramTwo/paramTwoValue"
 * @param {*} params
 */
const paramsToPathStr = (pathParams) => (
  pathParams.map(pathParam =>
    typeof pathParam !== 'object' ? pathParam :
      Object.keys(pathParam).map(key => `${key}/${pathParam[key]}`).join('/')
  ).join('/')
);

/**
 * Executes HTTP request using fetch API
 * @param {*} param0
 */
const request = ({ url, options }) => {
  try {
    const payload = fetch(url, options);
    // console.log('fetch options', url, options);
    return payload;
  } catch(e){
    console.error('error in fetch', e)
  }
}

export default RestApi;