import React from "react";
import classNames from "classnames";
import { useDropzone } from 'react-dropzone';
import { FormGroup, Label } from "reactstrap";

const DropzoneInput = (props) => {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone();

  // const files = acceptedFiles.map(file => (
  //   <li key={file.path}>
  //     {file.path} - {file.size} bytes
  //   </li>
  // ));

  if (props.hidden) return null;

  return (
    <FormGroup>
      {props.label && <Label for={props.id}>{props.label}</Label>}
      <div className={classNames(
            "d-flex flex-column align-items-center dropzone-input-container",
            {"dropzone-input-is-drag-accept": props.isDragAccept,
              "dropzone-input-is-drag-reject": props.isDragReject,
              "dropzone-input-is-drag-active": props.isDragActive,
              "dropzone-input-default": !props.isDragAccept && !props.isDragReject && !props.isDragActive,
            })}
           {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
    </FormGroup>
  );
};

export default DropzoneInput;

