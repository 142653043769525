import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {CardHeader as _CardHeader} from '@isf/bootstrap';
import {CardContext} from './card';

@observer
class CardHeader extends Component {
    constructor(props) {
      super(props);
      if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    }

    static contextType = CardContext;

    componentWillUnmount() {
        this.uiBaseStore && this.uiBaseStore.dispose();
    }

    handleCollapseClick = (store) => {
      if (this.context.cardCollapsable) store.setCardCollapsed(!store.isCardCollapsed);
    };

    render() {
        const {hasHeader, cardCollapsable, cardCollapsableByIcon, store} = this.context;
        const {children, className} = this.props;
        if (!hasHeader) return null;
        const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
          && this.uiBaseStore.classNameStore.getClassNames();

        return (
            <_CardHeader className={classNames(
                          className, computedClassNames,
                          "d-flex justify-content-between align-items-center text-break",
                          {"cursor-pointer": cardCollapsable && !cardCollapsableByIcon})}
                         onClick={() => !cardCollapsableByIcon && this.handleCollapseClick(store)}>
                <div className="d-flex w-100 align-items-center">
                    {children}
                </div>
                {cardCollapsable
                    ? <span className={classNames(
                              "dropdown-toggle",
                              {"transform-rotate-0": store.isCardCollapsed,
                                "transform-rotate-180": !store.isCardCollapsed,
                                "cursor-pointer": cardCollapsable
                              })}
                            onClick={() => cardCollapsableByIcon && this.handleCollapseClick(store)}
                  />
                    : null
                }
            </_CardHeader>
        );
    }
  }

CardHeader.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any
};

export {CardHeader};