import React from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ICON_SORT_UP, ICON_SORT_DOWN } from "@isf/common-resources";
import appStore from "@isf/core-app-store";
import {localeStore} from "@isf/core-localization";

const SortWrapper = observer(({sortUp, isSorted, children, columnProps, columnDataType}) => {
  let store, currentSortDir;
  const isSortable = columnProps && columnProps.accessor && columnProps.sortable && columnProps.sort;

  if (isSortable) {
    store = appStore.getDataStore(columnProps.sort.storeName);
    currentSortDir = store && store.get(`body.sort.${columnProps.accessor}`);

    if (columnProps.sort.isLocalized && store) {
      currentSortDir = store.get(`body.sort.${columnProps.accessor}.{localeStore:locale}`);
      !currentSortDir && (currentSortDir = store.get(`body.sort.${columnProps.accessor}.${localeStore.locale}`))
    }

    if (currentSortDir && columnDataType === 'string.Date') currentSortDir = currentSortDir === 'desc' ? 'asc' : 'desc';
  }

  const down = currentSortDir === 'desc';
  const sorted = currentSortDir;

   return (
     isSorted
       ? <span className="d-inline-flex flex-nowrap align-items-center cursor-pointer">
          {children}
          <div className="d-inline-flex flex-column">
             <img src={ICON_SORT_UP}
                  alt="arrow"
                  className={classNames(
                    "icon-w-7 h-auto ml-1",
                    {"opacity-2": (sorted && down === undefined && sortUp)
                                    || (sorted && down === false)
                                    || !sorted
                    })} />
             <img src={ICON_SORT_DOWN}
                  alt="arrow"
                  className={classNames(
                    "icon-w-7 h-auto ml-1",
                    {"opacity-2": (sorted && down === undefined && !sortUp)
                                    || (sorted && down === true)
                                    || !sorted
                    })} />
          </div>
        </span>
        : <span>{children}</span>
  )
});

SortWrapper.propTypes = {
   id: PropTypes.string,
   // sortUp: PropTypes.bool.isRequired,
   // isSorted: PropTypes.bool.isRequired,
};

export default SortWrapper;
