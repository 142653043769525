import engine from "../ui-engine";
import $ from "@isf/core-object-util"

export const isNeedAsyncAction = (tag, props) => {
    return (tag === 'Field' && props.input && props.input.props.type === 'autocomplete') || (tag === 'Table' && props.externalDependencies);
};

export const getExternalActions = (props, refId) => {
    const externalActions = [];
    const externalDependencies = props.externalDependencies;
    for (let key in externalDependencies) {
        const dependency = $.get(externalDependencies,key) || externalDependencies.get(key);
        externalActions.push(
            {
                actionName:dependency.actionName,
                func: async (data, componentName) => {await engine.executeAction(dependency.actionName, {refId: refId}, data, undefined, componentName)}
            }
        )
    }
    return externalActions;
};
