import React from "react";
import {observer} from "mobx-react";
import classNames from 'classnames';
import {SectionContent} from "../sections/src";
import {NavigationThemeContext} from "./navigation-container";

@observer
export class NavigationSectionContent extends React.Component{
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {children, className, id} = this.props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <NavigationThemeContext.Consumer>
        {navStore =>
          <SectionContent
            name={id + ""} navStore={navStore} className={classNames(className, computedClassNames)}>
            {children}
          </SectionContent>
        }
      </NavigationThemeContext.Consumer>
    )
  }
}