import React, { Component } from "react";
import { Spinner } from "react-bootstrap";

class PageLoadingSpinner extends Component {

  render() {
    const {isLoading, color = "primary", animation = "border", width} = this.props;
    if (!isLoading) {
      return null;
    }
    return (
      <div className={"page-loading-spinner-wrapper"}>
        <div className={"position-absolute load-spinner-background-shadow w-100 h-100"}></div>
        <Spinner animation={animation} variant={color} style={{
          width: width,
          height: width,
          zIndex: 1001
        }}/>
      </div>
    );
  }
}

export default PageLoadingSpinner;