import React from 'react'
import {Container} from "reactstrap";
import {inject, observer} from "mobx-react";
import {injectIntl} from "react-intl";
import classNames from 'classnames';
import {LocalizationProvider} from "../../localization/src";

@injectIntl
@inject('userStore')
@observer
class RefComponent extends React.Component {
   constructor(props) {
      super(props);
      if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
      if (props.uiStoreType) this.uiStore = new props.uiStoreType(props)
   }

   componentWillUnmount() {
      this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
      this.uiBaseStore && this.uiBaseStore.dispose();
   }

   render() {
      const {props, uiStore} = this;
      const {context, refId, userStore, className} = props;
      const loadedSchema = uiStore && uiStore.contextPath && uiStore.schema;
      const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
        && this.uiBaseStore.classNameStore.getClassNames();
      return (
         <LocalizationProvider>
            <div className={classNames(className, computedClassNames)}>
               {loadedSchema && context.renderComponent({
                  refId,
                  schema: loadedSchema,
                  uiSchema: loadedSchema.ui,
                  uiSchemaCursor: loadedSchema.ui,
                  configurationId: loadedSchema.configurationId,
                  userStore: userStore,
                  scope: uiStore.getSecurityScope(),
                  context,
                  key: '0'
               })}
            </div>
         </LocalizationProvider>
      )
   }
}

export default RefComponent;