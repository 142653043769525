import { routerStore } from "@isf/core-app-store";
import $ from "@isf/core-object-util";
import {uuidv4} from "@isf/core-system-util";

export const templToPath = (template, split='/', ctxPath) => {
  let p = ctxPath ? '/'+ctxPath : routerStore.pathname;
  p = p.split('/');
  // console.log('router path', p);
  if (!template.startsWith('/')) {
    template = '/' + template;
  }
  const t = template.split(split);
  // console.log('template path', t);
  for(let i=0; i<p.length && i<t.length; i++) {
    if (t[i] === '{id}') {
      t[i] = p[i];
    }
  }
  const path = t.join(split);
  return path;
};

export const storeTemplToPath = (template, split='.', ctxPath) => {
  let p = ctxPath ? '/'+ctxPath : routerStore.pathname;
  p = p.split('/');
  const t = template.split(split);
  for(let i=0; i+1<p.length && i<t.length; i++) {
    if (t[i] === '{id}') {
      t[i] = p[i+1];
    }
  }
  const path = t.join(split);
  return path;
}

export const isID = (str) => {
  const ID_PATTERN = [/^@[a-zA-Z]+/, /^new$/, /^\d+$/, /^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$/];
  let flag = false;
  ID_PATTERN.forEach(it => {
    if (it.test(str)) {
      flag = true;
    }
  });

  return flag;
};

export const pathnameToTemplate = (pathname, split='-') => {
  let segments = pathname.split('/');
  // console.log('segments', segments);
  // segments = segments.slice(1, segments.length); 
  for(let i=4 /*i=1*/; i<segments.length; i++) {
    if (isID(segments[i])) {
      segments[i] = '{id}';
    }
    // if ( i % 2 == 0) {
    //   segments[i] = '{id}';
    // }
  }
  // console.log('pathnameToTemplate', pathname);
  const templ = segments.join(split);
  return templ;
};

export const isNewObjectPath = (path) => {
  const resultPath = path ? '/' + path : routerStore.pathname;
  const segments = resultPath.split('/');
  for(let i=4; i<segments.length; i=i+2) {
    if (segments[i] === 'new') {
      return true;
    }
  }
  return false;
};

export const getResultComputedFunction = (globalComputedStore,computedObject) => {
  const params = $.get(computedObject,'params')||[];
  const expression = $.get(computedObject,'expression');
  const expressionForFunction = makeExpressionForFunction(expression);
  const functions = globalComputedStore.get('functions')||{};
  for(let funcId of functions.keys()){
    const func = functions.get(funcId);
    const funcParams = $.toJS($.get(func,'params'))||[];
    if(compareParams(funcParams.slice(), params.slice())){
      const funcExpression = $.get(func,'expression')||"";
      if(funcExpression === expressionForFunction){
        return $.get(func,'function');
      }
    }
  }
  const newFunction = createNewFunction(params,expressionForFunction);
  $.set(functions,uuidv4()+'',{params:params,expression:expressionForFunction,function: newFunction});
  return newFunction
};

const makeExpressionForFunction = (expression) => {
  if(expression.indexOf('return')===-1){
    return'return '+ expression;
  }else{
    return expression;
  }
};

const compareParams = (funcParams, params) =>{
  if(funcParams.length===params.length){
    for(let i=0;i<params.length;i++){
      let overlap = false;
      for(let j=0;j<funcParams.length;j++){
        if($.get(params[i],'name') === $.get(funcParams[j],'name')){
          params.splice(i,1);
          funcParams.splice(j,1);
          overlap=true;
          i--;
          break;
        }
      }
      if(!overlap){
        return false
      }
    }
    return(funcParams.length===params.length && funcParams.length === 0)
  }else{
    return false;
  }

};

const createNewFunction = (params, expression) => {
  const resultParams = [];
  params.map(param=>{
    resultParams.push($.get(param,'name'))
  });
  resultParams.push(expression);
  return new Function(...resultParams)
};


