import { computed } from "mobx";
import { DataStore } from "@isf/core-app-store";

class FilterSettingStore extends DataStore {
  constructor(props) {
    super({...props, observableAsMap: true});
  }

  @computed
  get service() {
    return this.get("service");
  }

  @computed
  get filterSchema() {
    return this.get("service.apiRequestBodyFilterSchema");
  }

  @computed
  get error() {
    return this.get("error");
  }

  @computed
  get mode() {
    return this.get(`${this.accessor}.mode`);
  }
}

export { FilterSettingStore };