import { action, computed, observable } from "mobx";
import { addLocaleData as addLocaleIntlData } from "react-intl";

import { Store, UIStore } from "@isf/core-app-store";
import { resolveInitialLocale, loadLocaleIntlData } from "./locale-util";

class StatusStore extends UIStore {

  @action
  setInitializing(value) {
    this.set('initializing', value);
  }

  @action
  setSwitching(value) {
    this.set('switching', value);
  }

  // @computed
  get initializing() {
    return this.get('initializing');
  }

  @computed
  get switching() {    
    return this.get('switching');
  }

  @action
  setLocale(value) {
    this.set('locale', value);
  }

  @computed
  get locale() {
    return this.get('locale');
  }
}

class LocaleStore extends Store {

  bundleProviders = [];

  static _availableLocales = [];

  loadedBundles = {};
  
  loadLocaleIntlData;       // function to load locale intl data, taken from locale-util by default

  // loadLocaleMessages;       // function to load locale messages, taken from locale-util by default

  // initLocale;

  @observable
  _updated = 1;

  
  constructor(args) {
    super(args);
    this.loadLocaleIntlData = args.loadLocaleIntlData || loadLocaleIntlData;
    const locale = resolveInitialLocale();
    this.initLocale = locale; //used in design-locale-store
    this.status = new StatusStore({
      path: args.path,
      state: {
        locale: locale,
        initializing: true,
        switching: false
      }
    });
    // this.initialize();
  }

  // async initialize() {
  //   // this.status.setInitializing(true);
  //   // const initLocale = this.initLocale;
  //   // this.status.setLocale(initLocale);
  //   // await this.setLocale(initLocale);
  //   // this.setLocale(initLocale);
  //   // this._initialized = true;
  //   // this.addLocaleData(this.locale);
  //   // this.status.setInitializing(false);
  // }

  @action
  setInitialized(value) {
    this.status.setInitializing(!value);
  }

  async loadLocaleMessages(locale, provider) {
    // const messages = {};
    if (provider) {
      await this.loadLocaleProviderMessages(locale, provider)
      if (locale !== 'ru') {
        await this.loadLocaleProviderMessages('ru', provider)
      }
    } else {
      const promises = [];
      this.bundleProviders.forEach(
        provider => {
          promises.push(this.loadLocaleProviderMessages(locale, provider));
          if (locale !== 'ru') {
            promises.push(this.loadLocaleProviderMessages('ru', provider));
          }
        }
      );
      await Promise.all(promises);
    }
  }

  async loadLocaleProviderMessages(locale, provider) {
    let bundleMessages = {};
    try {     
      const messages = await provider.load(locale);      
      if (messages.default) { //temporary fix       
        Object.keys(messages).map(key => bundleMessages[key]=messages[key]);        
      } else {
        bundleMessages = messages;        
      }
      if (!this.findBundle(provider.code, locale)) {
        this.add({code: provider.code, locale, messages: bundleMessages});
        this._updated ++;
      }
      return bundleMessages;
    } catch(e) {
      console.error('Failed to load message bundle for ', provider, e);
    }
  }

  async addBundleProvider(bundleProvider, forceLoad) {
    this.bundleProviders.push(bundleProvider);    
    // await this.loadLocaleProviderMessages(this.locale, bundleProvider/*, messages*/)
    if (forceLoad) {
      await this.addLocaleData(this.locale, bundleProvider);
    }
  }

  @action
  async setLocale(locale) {
    // try {
    //   throw new Error('SET LOCALE');
    // } catch(e) {
    //   console.error(e)
    // }
    this.status.setSwitching(true);
    if (!this.isLocaleAdded(locale)) {
      await this.addLocaleData(locale);
    }
    this._setLocale(locale);
    this.changeLocaleListeners && this.changeLocaleListeners.forEach(listener => {
      try {
        listener({locale: this.currentLocale})
      } catch (e) {
        console.error(e);
      }
    });
    this.status.setSwitching(false);    
  }

  async addLocaleData(locale, provider) {    
    const localeData = await this.loadLocaleData(locale, provider);
    if(locale === "by") {
      localeData.intlData[0].locale = "by";
    }
    addLocaleIntlData(localeData.intlData);                         // add locale data to React Intl
  }

  isLocaleAdded(locale) { //TODO: change logic
    return ( this.get(['messages', locale]) !== undefined );
  }

  _setLocale(locale) {
    this.status.setLocale(locale);
    localStorage.setItem('locale', locale);  // set current locale (language) to browser local storage                        
  }

  async loadLocaleData(locale, provider) {
    const intlDataLoad = this.loadLocaleIntlData(locale === "by" ? "be" : locale);
    const messagesLoad = this.loadLocaleMessages(locale, provider);
    const localeData = {
      intlData: (await intlDataLoad).default,
      messages: await messagesLoad,
      locale
    }
    return localeData;
  }

  findBundle(code, locale) {
    if(this.state) ;   
    return this.bundles.find(
      bundle => (bundle.code === code && bundle.locale == locale)
    );
  }

  getMessage(msgKey, locale, bundleCode) {
    const bundle = this.findBundle(bundleCode, locale);   
    const message = bundle ? bundle.messages[msgKey] : undefined;  
    return message;    
  }

  getMessageFromLocale(msgKey, locale) {
    const bundles = this.bundles.filter(bundle => bundle.locale === locale && bundle.messages[msgKey]);
    if (!bundles.length) {
      return "";
    }
    return bundles[0].messages[msgKey];
  }
  get bundles() {
    return this.get();
  }

  @action
  setMessage(msgKey, value, locale) { //TODO: set to bundle
    this.set(['messages', locale || this.locale, msgKey], value);    
  }

  get switching() {    
    return this.status.switching;
  }

  get initializing() {
    return this.status.initializing;
  }

  get locale() { //depricated
    return this.status.locale//this.get('stat.locale');
  }

  get currentLocale() {    
    return this.availableLocales.find(l => l.id === this.locale).locale
  }

  get localeMessages() {
    if (this._updated);
    const bundles = this.bundles;
    if (!bundles) {
      return {};
    }    
    const localeBundles = bundles.filter(bundle => bundle.locale === this.locale);   
    let messages = {};
    localeBundles.forEach(bundle => {
      messages = {...messages, ...bundle.messages};
    });   
    return messages;
  }

  get availableLocales() {
    return LocaleStore._availableLocales;
    // return [
    //   { id:"en", lang: 'en', name:"English", locale: 'en_GB' },
    //   { id:"ru", lang: 'ru', name:"Русский", locale: 'ru_RU' },
    //   { id:"by", lang: 'by', name:"Беларуская", locale: 'by_RU'},
    //   // { id:"zh", lang: 'zh', name:"中國人", locale: 'zh_ZH' }
    // ];
  }

  set availableLocales(availableLocales) {
    LocaleStore._availableLocales = availableLocales;
  }

  getSortedLocales(currentLocale){
    const locales = this.availableLocales;
    locales.sort((a, b)=> a.lang === currentLocale? 1:0);
    return locales;
  }

  addLocaleChangeListener(listener) {
    if (! this.changeLocaleListeners ) {
      this.changeLocaleListeners = [];
    }
    this.changeLocaleListeners.push(listener);
  }

}

const localeStore = new LocaleStore({
  scope: 'localization',
  path: [],
  state: []
});

const registerBundle = async (bundleProvider, forceLoad) => {
  await localeStore.addBundleProvider(bundleProvider, forceLoad);  
}

// registerBundle({ 
//   code: 'core', 
//   load: (locale) => import(`./messages/messages_${locale}.json`)
// });

export const getMessage = (key) => localeStore.getMessage(key);

export { LocaleStore };
export { registerBundle };
export default localeStore;