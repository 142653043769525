import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, Provider } from "mobx-react";
import classNames from "classnames";
import { resolveStore } from "@isf/react-util";
import { TableStore } from '../table-store';
import { injectIntl } from "react-intl";
// import {observable} from "mobx";
// import TableHead from "../old-table-view/table-head";
// import $ from "@isf/core-object-util"
// import {FilterStore} from "@isf/filter";
import { LoadSpinner } from "../../../load-spinner/load-spinner-view";
import Select from 'react-select';
import appStore from "@isf/core-app-store";
import {localeStore} from "@isf/core-localization";
import $ from "@isf/core-object-util"

export const TableContext = React.createContext({columns:[]});

@injectIntl
@observer
class Table extends Component {

    constructor(props) {
        super(props);
        const {data, accessor, dataStore} = props;
        this.store = resolveStore({...props, type: TableStore});
        this.store.selectionStore.setRequestStore(props.requestStore);
        this.store.selectionStore.set('dataStoreRef', {
            store: props.dataStore,
            accessor: accessor
        });
        if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
        if (props.uiStoreType) this.uiStore = new props.uiStoreType(props);

        this.dataStoreObj = {
            store: dataStore,
            index: accessor ? accessor : undefined
        };

        if(data) {
            this.dataStoreObj.store = data.store;
            const index = this.dataStoreObj.index;
            this.dataStoreObj.index = index ? data.index + "." + index : data.index;
            this.store.selectionStore.set('dataStoreRef', {
                store: this.dataStoreObj.store,
                accessor: this.dataStoreObj.index
            });
        }

        this.onSortColumnChange = this.onSortColumnChange.bind(this);
        this.onSortTypeChange = this.onSortTypeChange.bind(this);
    }

    componentWillUnmount() {
        this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
        this.uiBaseStore && this.uiBaseStore.dispose();
    }

    componentDidMount() {
        this.store.selectionStore.clearStore();
    }

    onSortTypeChange(event) {
        const {value = "", label = ""} = !!event && event;
        const {columns} = this.props;
        let column = columns.find(el => el.sortable);
        const store = appStore.getDataStore(column.sort.storeName);
        if (store) {
            if ((value === 'asc') || (value === 'desc')) {
                let sort = store.get('body.sort');
                if (sort && sort.size) {
                    let sortedColumn = $.keys(sort)[0];
                    column = columns.find(col => col.accessor === sortedColumn);
                    if (column.sort.isLocalized) {
                        sortedColumn += "." + localeStore.locale;
                        store.set('body.sort', {});
                        store.set(`body.sort.${sortedColumn}`, value);
                    } else {
                        sort.set(sortedColumn, value);
                    }
                    column.sort.actionName(undefined, undefined, "TableSort");
                }
            } else {
                store.set('body.sort', {});
            }
        }
    }

    onSortColumnChange(event) {
        const {columns} = this.props;
        if (event) {
            const {value , label } = event;
            const column = columns.find(col => col.accessor === value);
            const store = column && appStore.getDataStore(column.sort.storeName);
            if (store) {
                let sort = store.get('body.sort');
                let accessor = `body.sort.${column.accessor}`;
                if (column.sort.isLocalized) {
                    accessor += "." + localeStore.locale;
                }
                let sortDirection = "asc";
                if (sort && sort.size) {
                    const prevSortedColumnName = $.keys(sort)[0];
                    const prevSortedColumn = columns.find(col => col.accessor === prevSortedColumnName);
                    let prevSortDirection;
                    if (prevSortedColumn.sort.isLocalized) {
                        prevSortDirection = store.get(`body.sort.${prevSortedColumnName}.${localeStore.locale}`) ||
                          store.get(`body.sort.${prevSortedColumnName}.{localeStore:locale}`);
                    } else {
                        prevSortDirection = sort.get(prevSortedColumnName);
                    }
                    if (prevSortDirection) {
                        sortDirection = prevSortDirection;
                    }
                }
                store.set('body.sort', {});
                store.set(accessor, sortDirection);
                column.sort.actionName(undefined, undefined, "TableSort");
            }
        } else {
            const column = columns.find(el => el.sortable);
            const store = appStore.getDataStore(column.sort.storeName);
            if (store) {
                store.set('body.sort', {});
                column.sort.actionName(undefined, undefined, "TableSort");
            }
        }
    }

    render() {
        const {dataStoreObj, props} = this;
        const {columns, className,  dataStore, hidden, choice, selection, /*isClient, intl, id, filterable,*/ children, externalDependencies, externalActions, externalContext, isConstructorMode , intl} = props;
        const resultChildren = getExternalChildren(children,externalActions,externalDependencies, externalContext);
        if (this.uiStore && this.uiStore.commonStore.hiddenStore.get('isHidden')) return null;
        const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
          && this.uiBaseStore.classNameStore.getClassNames();

        let sortableColumn = columns.find(el => el.sortable);

        const sortOptions = [
            {value: "asc", label: intl.formatMessage({id: "ui.table.sorting.order.asc"})},
            {value: "desc", label: intl.formatMessage({id: "ui.table.sorting.order.desc"})}
        ];

        const columnOptions = columns.filter(col => col.sortable)
          .map(col => ({
              "value": col.accessor,
              "label": intl.formatMessage({id: col.header.message.id, defaultMessage: ''})
          }));

        const customStyles = {
            placeholder: base => ({
                ...base,
                whiteSpace: 'nowrap',
            })
        }

        let sortOption = null;
        let columnOption = null;
        if (sortableColumn && sortableColumn.sort) {
            const store = appStore.getDataStore(sortableColumn.sort.storeName);
            if (store) {
                const sort = store.get('body.sort');
                if (sort && sort.size) {
                    columnOption = $.keys(sort)[0];
                    sortableColumn = columns.find(el => el.accessor === columnOption);
                    if (sortableColumn.sort.isLocalized) {
                        sortOption = store.get(`body.sort.${columnOption}.${localeStore.locale}`) ||
                          store.get(`body.sort.${columnOption}.{localeStore:locale}`);
                    } else {
                        sortOption = sort.get(columnOption);
                    }
                }
            }
        }

        return (
            <Provider dataStoreObj={dataStoreObj} dataStore={dataStore} store={this.store} externalDependencies={externalDependencies}>
                <TableContext.Provider value={{columns: columns, selection: selection, choice: choice}}>
                    {
                      sortableColumn && dataStore && !!dataStore.state.length &&
                      <div className={'table-sorting'}>
                          <div className={"row"}>
                              <div className={"col"}>
                                  <Select options={columnOptions}
                                          value={columnOptions.find(el => el.value === columnOption) || null}
                                          defaultValue={""}
                                          isClearable={true}
                                          placeholder={intl.formatMessage({id: "ui.table.sorting.column"})}
                                          onChange={this.onSortColumnChange}
                                          styles={customStyles}/>
                              </div>
                              <div className={"col"}>
                                  <Select options={sortOptions}
                                          value={sortOptions.find(el => el.value === sortOption) || null}
                                          defaultValue={""}
                                          placeholder={intl.formatMessage({id: "ui.table.sorting.order"})}
                                          onChange={this.onSortTypeChange}
                                          styles={customStyles}/>
                              </div>
                          </div>
                      </div>
                    }
                    <div className={classNames('position-relative mb-3', { 'table-responsive': !isConstructorMode })}>
                        {this.uiStore && <LoadSpinner isLoading={this.uiStore.loadStore.isLoading}/>}
                        <table className={classNames("table", className, computedClassNames)}>
                            {resultChildren}
                        </table>
                    </div>
                </TableContext.Provider>
            </Provider>
        );
    }
}

const getExternalChildren = (children, externalActions, externalDependencies, externalContext) => {
    if (externalActions && externalDependencies) {
        return React.Children.map(
          children,
          child => child && React.cloneElement(child, {
              externalActions: externalActions,
              externalDependencies: externalDependencies,
              externalContext: externalContext
          })
        );
    }
    return children;
};



const getResultColumns = (columns) => {
    return [choiceColumn,...columns];
};

const choiceColumn = {
    type: "checkbox",
    accessor: 'checkbox',
    sortable: false,
    filterable: false,
    editable: false,
    width: 45
};

Table.propTypes = {
    id: PropTypes.string,
    isClient: PropTypes.bool,
    className: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    store: PropTypes.object,
    dataStore: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    filterStore: PropTypes.object
};

export {Table};
