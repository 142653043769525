import React from 'react';
import {getCoordinatesOfLastConnection} from "./link-actions";

export const Arrowhead = (props) => {
  const {schemaDataStore} = props;
  let coordinatesOfConnection;
  if (props.connectTo.resourceId) {
    const connectToElement = schemaDataStore.getElementById(props.connectTo.resourceId).element;
    coordinatesOfConnection = getCoordinatesOfLastConnection(props.linkPoints, connectToElement);
  } else
    coordinatesOfConnection = {
      x: props.linkPoints.split(",")[props.linkPoints.split(",").length - 2],
      y: props.linkPoints.split(",")[props.linkPoints.split(",").length - 1]
    };
  const arrowheadPoints = getArrowHeadPoints(props.linkPoints, coordinatesOfConnection);
  if(arrowheadPoints) {
    return (
      <polyline
        points={arrowheadPoints}
        fill={props.color}
        stroke={props.color}
        transform={"rotate(" + (getTransformAngle(props.linkPoints) / Math.PI * 180) + "," + getPointOfTransform(arrowheadPoints.split(",")) + ")"}
      />
    );
  }
  return null;
};

const getPointOfTransform =(arrPoints)=> (
  arrPoints[arrPoints.length - 2] + "," + arrPoints[arrPoints.length - 1]
);

const getTransformAngle = (points) => {
  const arr = points.split(",");
  return getAngle([parseInt(arr[arr.length - 4], 10),
    parseInt(arr[arr.length - 3], 10),
    parseInt(arr[arr.length - 2], 10),
    parseInt(arr[arr.length - 1], 10)])
};

const getAngle = (arr) => {
  if (arr[0] <= arr[2] && arr[1] <= arr[3]) {
    return Math.atan((arr[3] - arr[1]) / (arr[2] - arr[0]));
  } else if (arr[0] >= arr[2] && arr[1] <= arr[3]) {
    return Math.atan((arr[0] - arr[2]) / (arr[3] - arr[1])) + Math.PI / 2;
  } else if (arr[0] >= arr[2] && arr[1] >= arr[3]) {
    return Math.atan((arr[1] - arr[3]) / (arr[0] - arr[2])) + Math.PI;
  } else {
    return Math.atan((arr[2] - arr[0]) / (arr[1] - arr[3])) + Math.PI + Math.PI / 2
  }
};

const getArrowHeadPoints = (points, coordinatesOfConnection) => {
  if(coordinatesOfConnection) {
    const X = coordinatesOfConnection.x;
    const Y = coordinatesOfConnection.y;
    return (X + "," + Y + "," + (X - 8) + "," + (Y - 3) + "," + (X - 8) + "," + (+Y + 3) + "," + X + "," + Y);
  }
  return ""
};

