export const BoundaryMessageConf = (number, coordinates) => (
  {
    tag: "Shape",
    props: {
      bounds: {
        lowerRight: {
          x: coordinates.x + 30,
          y: coordinates.y + 30
        },
        upperLeft: {
          x: coordinates.x,
          y: coordinates.y
        }
      },
      resourceId: "boundarymessage" + number,
      childShapes: [],
      stencil: {
        id: "BoundaryMessageEvent"
      },
      properties: {
        overrideid: "boundarymessage" + number,
        name: "Message",
        cancelactivity: true,
        messageref: "",
        executionlisteners: {
          executionListeners: []
        }
      },
      outgoing: []
    },
    children: []
  }
);