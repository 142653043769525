import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer, inject} from 'mobx-react';
import classNames from 'classnames';
import {Card as _Card} from 'reactstrap';
import {resolveStore} from '@isf/react-util';
import {CardStore} from './card-store';

const CardContext = React.createContext();

@observer
class Card extends Component {
    constructor(props) {
        super(props);
        if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
        if (props.uiStoreType) this.uiStore = new props.uiStoreType(props);
        let id = props.id;
        if (props.data) id += props.data.index;
        this.store = resolveStore({...props, id: id, type: CardStore});
        if (props.defaultCollapsed) this.store.setCardCollapsed(props.defaultCollapsed);
        // this.store = this.props.store ? this.props.store : new CardStore();
    }

    componentWillUnmount() {
        this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
        this.uiBaseStore && this.uiBaseStore.dispose();
    }

    render() {
        const {children, style, className, spacingClassNames, uiStoreType, computedClassName, hidden, languages,
            localeStore, uiBaseStore, ...other} = this.props;
        if (this.uiStore && this.uiStore.hiddenStore.get('isHidden')) return null;
        const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
          && this.uiBaseStore.classNameStore.getClassNames();

        return (
            <_Card style={style} className={classNames(spacingClassNames || "mb-3", className, computedClassNames)}>
                <CardContext.Provider value={{...other, store: this.store}}>
                    {children}
                </CardContext.Provider>
            </_Card>
        );
    }
}

Card.propTypes = {
    id: PropTypes.string,
    store: PropTypes.instanceOf(CardStore)
};

export {CardContext, Card};