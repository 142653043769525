import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {CardFooter as _CardFooter} from 'reactstrap';
import {CardContext} from './card';

@observer
class CardFooter extends Component {
    constructor(props) {
        super(props);
        if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    }

    componentWillUnmount() {
        this.uiBaseStore && this.uiBaseStore.dispose();
    }

    static contextType = CardContext;

    render() {
        const {hasFooter} = this.context;
        const {children, className} = this.props;
        if (!hasFooter) return null;
        const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
          && this.uiBaseStore.classNameStore.getClassNames();

        return (
            <_CardFooter className={classNames(className, computedClassNames)}>
                {children}
            </_CardFooter>
        );
    }
}

CardFooter.propTypes = {
    id: PropTypes.string,
    children: PropTypes.any
};

export {CardFooter};