
export const stores = {

};

export const registerStore = ({type, store}) => {
  // console.log('registerStore', type, store);
  stores[type] = store;
}

export const resolveStore = (type) => {
  // console.log('resolveStore', type, stores[type]);
  return stores[type];
}