import React from "react";
import PropTypes from "prop-types";
import { HoverComponent, Wrapper, Wrapped } from "./wrapover-styles";
import { DefaultHoverComponent} from "./default-hover-component";

const Wrapover = (props) => {
  const { id, children, hoverComponent,hoverWidth} = props;
  return (
    <Wrapper id={id} className="wrapper">
      <Wrapped className="wrapped">
        {children}
      </Wrapped>
        <HoverComponent hoverWidth={hoverWidth ? hoverWidth : 15}>{ hoverComponent ? hoverComponent : DefaultHoverComponent(props) }</HoverComponent>
    </Wrapper>
  )
};

Wrapover.propTypes = {
  hoverComponent: PropTypes.element
};

export default Wrapover;