import styled from "styled-components";

const Sticky = styled.div`
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 995;
  height: fit-content;
  min-height: 2rem;
`;

export default Sticky;