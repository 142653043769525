import React, { Fragment } from "react";
import { observer } from "mobx-react";
import PropTypes from 'prop-types';
import { toJS } from "mobx";
import TreeStore from "./tree-store";
import Theme from "./theme";

import { useUIStore } from "@isf/react-util";
import { CustomInput } from "@isf/bootstrap";

// export const useTreeStore = (args) => (
//   () => useStore({
//     ...args,
//     type: TreeStore
//   })
// );

const useTreeStore = (args) => useUIStore({
  ...args,
  type: TreeStore
});

const isBranchNode = ({ data }) => (
  typeof data === 'object'
);

const buildChildPath = ({ data, path, pathSplitter }) => (
  path ? (path + pathSplitter + data.key) : data.key
);

const buildChildId = ({ data, id, pathSplitter }) => (
  id ? (id + pathSplitter + data.key) : data.key
);

const descendants = (parent) =>
  parent 
    ? Array.from(parent.keys && parent.keys() || Object.keys(parent)).map(
      key => ({
        key,
        data: parent.get ? parent.get(key + "") : parent[key + ""]
      })
      )
    : [];

const isActive = ({ store, path, activation }) => (
  activation && store.get && store.get(['active', path])
);

const isExpanded = (props) => {
  const { store, path, level } = props;
  const expanded = store.get && store.get(['expanded', path]);
  if (expanded !== undefined) return expanded;
  const pathParts = path.split('.');
  const curPath = ['defaultExpandDeep'];
  for (let i = 0; i < pathParts.length; i++) {
    curPath.push(pathParts[i]);
    if (store.get(curPath) >= level) {
      return true;
    }
  }
  return false;
}

const doRenderChildren = (props) => {
  const { store, path } = props;
  return isExpanded(props) || store.get && (store.get(['expanded', path]) !== undefined);
}

const handleExpandCollapse = (props) => {
  const { store, path } = props;
  isExpanded(props)
    ? store.set(['expanded', path], false) //store.remove('expanded', path)
    : store.set(['expanded', path], true);
};

const handleActiveNodeChange = (props) => {
  props.store.setActive(props);
};

const handleSelectedChange = ({ store, path, selection }) => { //TODO: put in store
  if (selection === 'single') {
    const selected = store.get('selected', true);
    if(!selected || Object.keys(selected)[0] !== path)
      store.clear('selected');
  }
  store.get(['selected', path])
    ? store.remove('selected', path)
    : store.set(['selected', path], true);
}

const Node = observer(props => {
  const { BranchNode, LeafNode, isBranchNode } = props;
  if (isBranchNode(props)) {
    return <BranchNode {...props} />;
  }
  return <LeafNode {...props} />;
});

const Selector = observer((props) => {
  const { store, selection, path, handleSelectedChange, id } = props;
  const checked = store.get(['selected', path]) || false;
  return (
    selection
      ? <CustomInput id={id}
        type="checkbox"
        onChange={() => handleSelectedChange(props)}
        checked={checked}/>
      : null
  )
});

const BranchNode = observer(props => {
  const { id, level, Theme, BranchNodeContent, BranchNodeChildren, handleExpandCollapse } = props;
  const expanded = isExpanded(props);
  const renderChildren = doRenderChildren(props);
  return (
    <Theme.li id={id + '.' + level}>
      <BranchNodeContent
        {...props}
        expanded={expanded}
        handleExpandCollapse={() => handleExpandCollapse(props)}
      />
      {renderChildren ?
        <BranchNodeChildren
          {...props}
          id={id}
          expanded={expanded} />
        : null}
    </Theme.li>
  );
});

export const BranchNodeContent = observer((props) => {
  const { expanded, level, Theme, LabelContent, Selector,
    handleActiveNodeChange, handleExpandCollapse, activation } = props;
  const active = isActive(props);
  return (
    //TODO: replace with css
    <div style={{display: 'inline-flex', alignItems: 'center'}}>
      <Theme.twistie expanded={expanded} level={level}
        onClick={handleExpandCollapse} />
      <Selector {...props} />
      <Theme.label
        active={active}
        level={level}
        onClick={activation ? () => handleActiveNodeChange(props) : null}
      >
        <LabelContent {...props} />
      </Theme.label>
    </div>
  )
});

const childrenNodes = (props) => {
  const { id, level, data, buildChildPath, buildChildId } = props;
  const l = level + 1;
  const desc = props.descendants ? props.descendants : descendants;  
  return desc(data).map(d =>
    <Node
      {...props}
      // id={id + '.' + d.key}
      id={buildChildId({ ...props, data: d})}
      key={d.key}
      dataKey={d.key}
      data={d.data}
      level={l}
      path={buildChildPath({ ...props, data: d})}
      />
  );
};

const BranchNodeChildren = observer((props) => {
  const { expanded, Theme } = props;
  return (
    <Theme.ul expanded={expanded}>
      {childrenNodes(props)}
    </Theme.ul>
  );
});

const LeafNode = observer((props) => {
  const { id, level, Theme, LeafNodeContent } = props;
  return (
    <Theme.li leaf id={'' + id + '.' + level}>
      <LeafNodeContent {...props} />
    </Theme.li>
  );
});

export const LeafNodeContent = observer(props => {
  const { LeafNodeLabel, Selector, hideEmpty, data } = props;
  if (hideEmpty && !data) return null;
  else return (
    //TODO: replace with css
    <div style={{display: 'inline-flex', alignItems: 'center'}}> 
      <Selector {...props} />
      <LeafNodeLabel {...props} />
    </div>
  )
});

const LeafNodeLabel = observer(props => {
  const { activation, LabelContent, handleActiveNodeChange } = props;
  const active = isActive(props);
  return (
    <Theme.label active={active}
      onClick={activation ? () => handleActiveNodeChange(props) : null}>
      <LabelContent {...props} />
    </Theme.label>
  )
});

const LabelContent = observer(props => {
  const { Theme, data, dataKey, isBranchNode } = props;
  if (isBranchNode(props)) {
    return (
      <Fragment>
        <Theme.leafKey>
          {dataKey}
          {/* {objectUtil.isObservableMap(data) ? ' om' : objectUtil.isObservable(data) ? ' o' : ''} */}
        </Theme.leafKey>
      </Fragment>
    )
  }
  return (
    <Fragment>
      <Theme.leafKey>{dataKey}</Theme.leafKey>{' : '}
      <Theme.leafValue>{'' + data}
        {/* {objectUtil.isObservable(data) ? ' o' : ''} */}
      </Theme.leafValue>
    </Fragment>
  )
});

const TreeView = observer((props) => {
  const { id, noRootNode, Theme, Node, path='', className } = props;
  if (noRootNode)
    return (
      <Theme.root id={id} className={className}>
        <Theme.ul noBorder expanded>
          {childrenNodes({ ...props, path, level: 1 })}
        </Theme.ul>
      </Theme.root>
    );
  return (
    <Theme.root id={id} className={className}>
      <Node {...props} id={id + '.root'} path={path} level={1} />
    </Theme.root>
  );
});

TreeView.propTypes = {
  id: PropTypes.string,               // component id
  noRootNode: PropTypes.bool,         // if true, root node is not rendered
  selection: PropTypes.oneOf(['single', 'multiple']),
  activation: PropTypes.bool,
  data: PropTypes.oneOfType([         // root data object or array
    PropTypes.object,
    PropTypes.array
  ]).isRequired,
  store: PropTypes.object,
  handleActiveNodeChange: PropTypes.func,
  handleExpandCollapse: PropTypes.func,      // hook for expand/collapse event
  handleSelectedChange: PropTypes.func,
  buildChildPath: PropTypes.func,
  buildChildId: PropTypes.func,
  descendants: PropTypes.func,               // returns children of the given object
  isBranchNode: PropTypes.func,
  Theme: PropTypes.object,                   // styled components theme
  Node: PropTypes.elementType,               // react component to render any node in a tree
  Selector: PropTypes.elementType,           // react component to render checkbox in a tree
  LeafNode: PropTypes.elementType,           // react component to render leaf node in a tree
  BranchNode: PropTypes.elementType,         // react component to render branch node in a tree
  BranchNodeChildren: PropTypes.elementType, // react component to render branch node children in a tree
  BranchNodeContent: PropTypes.elementType,  // react component to render branch node content like label, controls, etc
  LeafNodeContent: PropTypes.elementType,    // react component to render leaf node content like label, controls, etc
  LeafNodeLabel: PropTypes.elementType,
  LabelContent: PropTypes.elementType,
  pathSplitter: PropTypes.string
};

TreeView.defaultProps = {
  expanded: true,
  Theme,
  Node,
  Selector,
  BranchNode,
  BranchNodeChildren,
  BranchNodeContent,
  LeafNode,
  LeafNodeContent,
  LeafNodeLabel,
  LabelContent,
  buildChildPath,
  buildChildId,
  descendants,
  isBranchNode,
  handleActiveNodeChange,
  handleExpandCollapse,
  handleSelectedChange,
  noRootNode: false,
  activation: false,
  store: {},
  pathSplitter: '.'
};

export { TreeView, useTreeStore, Selector };
