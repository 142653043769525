import { buildStoreName } from './ui-schema-renderer-utils';

const buildExternalDependencies = (externalDependencies, refId) => {
  if (typeof externalDependencies !== 'object' || !externalDependencies) {
    return externalDependencies;
  }

  const copy = JSON.parse(JSON.stringify(externalDependencies));
  const keys = Object.keys(copy);
  keys.forEach((key) => {
    if (typeof copy[key].requestStore === 'string') {
      copy[key].requestStore = buildStoreName(copy[key].requestStore, refId);
    }
    if (typeof copy[key].responseStore === 'string') {
      copy[key].responseStore = buildStoreName(copy[key].responseStore , refId);
    }
  });

  return copy;
};

export { buildExternalDependencies }
