import React from "react";
import { Provider, observer } from "mobx-react";
import classNames from "classnames";
import { useToggleStore } from "../toggle";

const canClose = (props) => {
  const { toggle } = props;
  return ( // magic
    toggle === undefined
      ? true
      : (args, active) => (
          !active
          || (typeof toggle === "function"
                      ? toggle(args)
                      : !!toggle
            )
        )
  )
};

const resolveToggleStore = (props) => {
  const { id, toggleStore: storeOrPath } = props;
  if ((typeof storeOrPath === "string") || storeOrPath === undefined) {
    return useToggleStore({
      path: storeOrPath || id,
      canToggle: canClose(props)
    });
  }
  Object.assign(storeOrPath, {canToggle: canClose(props)});
  return storeOrPath;
};

const ModalContent = observer(props => {
  const { /*toggleStore: storeOrPath,*/ size, children } = props;
  const toggleStore = resolveToggleStore(props);
  return (
    <Provider toggleStore={toggleStore}>
      <div className={classNames("modal-content modal-dialog", {[`modal-${size}`]: size})}>
        {children}
      </div>
    </Provider>
  );
});

ModalContent.propTypes = {};

export { ModalContent };