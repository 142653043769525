import React from "react";
import {observer} from "mobx-react";
import {NavItem as NavItem_} from "reactstrap";

// const NavItem = (props) => {
//   const { navStore, name, ...other } = props;
//   return (
//     <NavItem_ {...other}/>
//   );
// };
@observer
class NavItem extends React.Component {
  constructor(props) {
    super(props);
    props.navStore && props.navStore.pushNavItem({id: props.id});
    if (props.uiStoreType) {
      this.uiStore = new props.uiStoreType(props);
    }
  }

  componentWillUnmount() {
    const {id, navStore} = this.props;
    navStore && navStore.removeNavItem(id);
  }

  render() {
    const {id, navStore, name, uiStoreType, hidden, message, languages, localeStore, ...other} = this.props;
    if (this.uiStore && this.uiStore.hiddenStore.get("isHidden")) return null;
    return <NavItem_ {...other}/>;
  }
}

export {NavItem};