import React, { Fragment } from "react";
import { observer } from "mobx-react";
import { ModalHeader as ModalHeader_ } from "../../../../modal";

const ModalHeader = (props) => {
  const { configStore, intl, title = intl.formatMessage({id: "ui.button.paramConfig"})} = props;
  const resultPath = buildSelectedPath(configStore.dataStore, intl);
  return (
    <ModalHeader_ >
        <Fragment>
          <p className="h4">{title}</p>
          {resultPath}
        </Fragment>
    </ModalHeader_>
  );
};

const buildSelectedPath = (dataStore, intl) => {
  const serviceContext = dataStore.get("select.service.context");
  const serviceName = dataStore.get("select.service.name");
  const servicePath = dataStore.get("select.service.path");
  const serviceMethod = dataStore.get("select.service.method");
  let resultPath = intl.formatMessage({id:"ui.modal.selectedService"}) + ": ";
  let path = intl.formatMessage({id:"ui.modal.path"}) + ": ";
  let method = intl.formatMessage({id:"ui.modal.method"}) + ": ";
  if (serviceName) resultPath += serviceName.charAt(0) === "/" ? serviceName : "/" + serviceName;
  if (serviceContext) resultPath += serviceContext.charAt(0) === "/"
                                      ? "(" + serviceContext + ")"
                                      : "(" + "/" + serviceContext + ")";
  if (servicePath) path += servicePath.charAt(0) === "/" ? servicePath : "/" + servicePath;
  if (serviceMethod) method += serviceMethod;

  return (
    <>
      <p className="h6">{resultPath}</p>
      <p className="h6">{path}</p>
      <p className="h6">{method}</p>
    </>
  )
};

const BodyContentArray = observer((props) => {
  const { stepIndex, allSteps, ...other } = props;
  const Component = resolveComponent(allSteps, stepIndex);
  const componentProps = (allSteps[stepIndex] && allSteps[stepIndex].props) || {};

  return (
    <Component {...props} {...other} {...componentProps} />
  );
});

const resolveComponent = (steps, index) => (
  steps[index] ? steps[index].component : steps[0].component
);

export { ModalHeader, BodyContentArray };