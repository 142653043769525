import { computed, observable } from "mobx";
// import deepmerge from "deepmerge";

import objectUtil, { strToArray } from "@isf/core-object-util";
import appStore from "./app-store";

const toAbsValuePath = (storeAbsPath, valuePath) => {
 return [...storeAbsPath, ...strToArray(valuePath)];  
};

const assignArgs = (target, args) => {
  for (const key in args) {
    target[key] = args[key];
  }
};
class Store {

  @observable
  _state =1;

  // @computed
  get state() {
    if (this._state)  {};
    // let state;

    // const path = [this.scope, ...this.path];

    // state = this.app.get(path);
    // if (!state) {
    //   state = this._state;
    // }
    
    // if (this._boundToPath) {     
    //   const path = [this.scope, ...this.path];
    //   state = this.app.get(path);
    //   if (!state) console.error('Warning: Undefined bounded state for store ' + path.join('.'), 'appStore: ', this.app);      
    // } else {
    //   state = this._state;
    //   if (!state) console.error('Warning: Undefined state for store ' + this.path.join('.'), 'appStore: ', this.app);
    // }
    
    // console.log('return state ' + this.absPath.join('.') + ":", this.app.get(this.absPath));
    return this.app.get(this.absPath); //this._state;
  }

  @computed
  get absPath() {
    if(this.storeId)
      return [this.scope, this.storeId]
    return [this.scope, ...this.path];
  }

  constructor( args ) {
    const {path, bindToPath, scope, name, observableAsMap, observable: asObservable, state, app=appStore, storeById, ...other} = args;
    if (path === undefined) {
      throw new Error('Store path is not defined, expected contructor argument {path:"<store_path>", ...<optional_params>}');
    }
    // const absPath = scope + '.' + normalizePath(path);
    // if (appStore.storeExists(absPath)) {
    //   throw new Error('Store with given path already exists ' + absPath);
    // }
    definePropertyReadOnly(this, 'path', strToArray(path));
    definePropertyReadOnly(this, 'name', name);
    definePropertyReadOnly(this, 'app', app);
    definePropertyReadOnly(this, 'scope', scope);
    this.observable = asObservable;
    this.observableAsMap = observableAsMap;
    if(storeById){
      definePropertyReadOnly(this, 'storeId', this.app.addStoreToIdMap(path));
    }
    // if (!bindToPath) {
    //   this.setState(state ? state : this.initState ? this.initState() : {});
    // }
    assignArgs(this, other);
    // this._boundToPath = bindToPath;
    // let _state =  observableAsMap ? objectUtil.objectToObservableMap(state) : asObservable ? observable(state) : state;
    
    if (state === undefined) {
      const existingState = this.app.get(this.absPath);
      // if (existingState === undefined) {
      //     this.setState({});
      // }      
      // this.setState(existingState || {});
      this.setState(existingState);
      //}
    } else {
      // console.log('+++ STATE');
      // this._defaultState = objectUtil.clone(state);
      this.setState(state);
    }

    // this.setState(state || {});
    
    // this.bind();
    this.app.bindStore(this);
    
    // if (!bindToPath) {
    //   this.setState(state ? state : this.initState ? this.initState() : {});
    // } else {      
    // }

    // app.bindStore(args, this);   
    // app.bindStore(this);   
  }

  getAbsValuePath(path) {
    return toAbsValuePath(this.absPath, path);
  }

  get(path, pureJS) {
    if (this._state)  {};
    if (!path) {
      return this.state;
    }
    // const val = objectUtil.get(this.state, path);
    const val = this.app.get(this.getAbsValuePath(path));
    return pureJS ? objectUtil.toJS(val) : val;
  }

  get stateJS () {
    return objectUtil.toJS(this.state);
  }

  set(path, value) {
    // this._state ++;
    this.app.set(toAbsValuePath(this.absPath, path), value);
    // const s =this.app.get(toAbsValuePath(this.absPath, path));
    // JSON.stringify(s);
    
    // objectUtil.set(this.state, path, value);
  }

  setState(state, invalidate) {
    let newState = state;
    if (invalidate) {
      const currentState = this.state; //this.stateJS; //this.stateJS;//this.app.get(this.absPath);
      // newState = deepmerge(objectUtil.toJS(currentState), state );
      if (objectUtil.isArray(state) && objectUtil.isArray(currentState)) {
        newState = state; //[...currentState, ...state];
      } else if (objectUtil.isObject(state) && objectUtil.isObject(currentState)) { 
        // console.log('MERGE STATES', {currentState, state});
        newState = {};
        objectUtil.keys(currentState).forEach(key => {
          // const value = currentState[key];
          key += "";
          const value = objectUtil.get(currentState, key);
          // console.log('value', value)
          let newValue;
          if (objectUtil.get(state, key) && objectUtil.isObservableMap(value)) {
            newValue = objectUtil.objectToObservableMap(objectUtil.get(state, key));
          } else if (objectUtil.get(state, key) && objectUtil.isObservable(value)) {
            newValue = observable(objectUtil.get(state, key));
          } else if (objectUtil.get(state, key)) {
            newValue = objectUtil.get(state, key);
          }
          newState[key] = newValue;
          // console.log('newState[key] ' + key, newState[key]);
        });
        objectUtil.keys(state).forEach(key => {
          key += "";
          newState[key] = newState[key] || objectUtil.get(state, key)
        });
        // newState = {...currentState, ...state};
        // console.log('MERGED STATE', newState);        
      } else if (state === undefined) {
        newState = currentState;
      }
      // console.log('UPDATE STATE', {currentState, state});
      // console.log('newState', objectUtil.toJS(newState));
    }
    this._updateState(newState);
    // if (invalidate) {
    //   console.log('INVALIDATING STATE', this._defaultState);
      this.app.invalidateStates();
    // }   
  }

  update() {
    this._state ++;
  }
  
  _updateState(state) {
    // this._defaultState = (state === undefined) ? undefined : objectUtil.clone(state);
    this._state = this._state+1;
    const newState = this.prepareNewValue(state);
    // const npath = normalizePath(path);
  
    // console.log('... updating state: ', this.absPath,  this.app.get(this.absPath), newState);
    this.app.set(this.absPath, newState);
    this.app.invalidateStates(this.absPath);

    // Object.entries(this.app._stores).forEach( ([spath, store]) => {
    //   if (spath.startsWith(npath)) {
    //     console.log('++ SET++', path);
    //   }
    // });
  }

  push(path, value) {
    let newValue = this.prepareNewValue(value);
    this.app.push(toAbsValuePath(this.absPath, path), newValue);
    // objectUtil.push(this.state, path, value);
  }

  prepareNewState(state) {
    // console.log('prepareNewState', this.path.join('.'), state);
    if (objectUtil.isObservableMap(state)) {
      this.observableAsMap = true;
      return state;
    }
    if (objectUtil.isObservable(state)) {
      this.observable = true;
      return state;
    }
    return this.prepareNewValue(state);
  }

  prepareNewValue(value) {
    let newValue = value;
    if (objectUtil.isObject(value)) {
      if (this.observableAsMap) {
        newValue = objectUtil.objectToObservableMap(value);
      } else if (this.observable) {       
        newValue = observable(value);
      } else {
        // newValue = observable.box(value);
      }
    } 
    return newValue;
  }

  add(value) {
    let newValue = this.prepareNewValue(value);
    // console.log('adding new value', newValue);
    this.app.add(this.absPath, newValue);
    // objectUtil.push(this.state, [], newValue);
  }

  del(path, prop) {
    // objectUtil.del(this.state, path, prop);
    this.app.del(toAbsValuePath(this.absPath, path), prop);
  }

  remove(path, leaf) {
    // objectUtil.remove(this.state, path, leaf);
    this.app.remove(toAbsValuePath(this.absPath, path), leaf);
  }

  contains(path, value) {
    return objectUtil.contains(this.state, path, value);
  }

  clear(path) { //TODO: clear for any state type
    if (path) {
      objectUtil.clear(this.state, path);
    } else {
      if (objectUtil.isObservableMap(this.state)) {
        this.state.clear();
      }
    }    
  }

  keys(path) {
    const value = this.get(path);    
    return objectUtil.isObject(value) ? objectUtil.keys(value) : []; 
  }
  
  getStore(path) {
    const store = this.app.getStore([this.scope, ...this.path, ...objectUtil.strToArray(path)]);    
    if (store) {      
      return store;
    }
    const state = objectUtil.find(this.state, path);
    return new Store({path: [...this.path, ...objectUtil.strToArray(path)], scope: this.scope, state, app: this.app})
  }

  makeObservable({path, asMap}={}) {    
    this.app.makeObservable({
      path: ( path ? this.getAbsValuePath(path) : this.absPath ),
      asMap: asMap
    });
    if (!path) {
      this.observableAsMap = asMap;
      this.observable = !asMap;
    }
  }

}

const definePropertyReadOnly = (target, property, value) => {
  Object.defineProperty(target, property, {
    value, writable: false,
    enumerable: false
  });
};

export default Store;
