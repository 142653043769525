import React from "react";
import PropTypes from "prop-types";
import { ToggleButton } from "../toggle";

const ModalOKButton = ({children, ...other}) => {
  return (    
    <ToggleButton data-button-type="ok" color="primary" {...other}>
       {children ? children : 'Применить'}
    </ToggleButton>
  );
}

ModalOKButton.propsTypes = PropTypes.instanceOf(ToggleButton);

export { ModalOKButton };