import React from "react";
import PropTypes from "prop-types";
import { ModalBody as _ModalBody} from "reactstrap";
import { observer } from "mobx-react";
import {LoadSpinner} from "../load-spinner/load-spinner-view";
import classNames from "classnames";

@observer
class ModalBody extends React.Component{
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {children, isLoading, className, computedClassName, languages, localeStore, uiBaseStore,
      ...other} = this.props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <_ModalBody className={classNames(className, computedClassNames)} {...other}>
        <LoadSpinner isLoading={isLoading}/>
        {children}
      </_ModalBody>
    );
  }
}


ModalBody.propsTypes = {
  toggleStore: PropTypes.object.isRequired
}

export { ModalBody };