import React from "react";
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';

const sendFilterRequest = (store) => {
  store.setIsFilterActive();
};

const FilterForm = (props) => {
  const {columns, store} = props;
  return (
    <Form method="post">
      {columns.map(column => (
        column.id==='checkbox' ? null : 
        <FormGroup key={column.accessor}>
          <Label for={column.accessor}>{column.Header}</Label>
          <Input type="text" name={column.Header}
                 id={column.accessor}
                 placeholder={"Filter " + column.accessor}
          />
        </FormGroup>
        ))}
      <Button onClick={() => sendFilterRequest(store)}>Submit</Button>
    </Form>
  );
};

FilterForm.propTypes = {
  id: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object)
};

export { FilterForm };
