import React from 'react'
import {observer} from "mobx-react";
import {UIStore} from "@isf/core-app-store";
import {localeStore} from "@isf/core-localization";
import $ from '@isf/core-object-util'
import {
   Card,
   CardBody,
   CardHeader,
   ModalBody,
   ModalContainer,
   ModalHeader, StaticText,
   ToggleStore, FormattedMessage
} from "@isf/common";
import {autorun} from "mobx";

@observer
class GlobalErrorView extends React.Component {
   constructor(props) {
      super(props);
      this.errorStore = new UIStore({path: 'errorGlobalStore', state: {errors: []}, observableAsMap: true});
      this.toggleModalStore = new ToggleStore({path: 'errorGlobalToggleStore'});
      autorun(() => {
         if (this.errorStore.get('errors').length !== 0) {
            this.toggleModalStore.set('active', true)
         }
      })
   }

   toggle = () => {
      this.toggleModalStore.set('active', false);
      console.log(this.errorStore.get('errors'));
      this.errorStore.set('errors',[])
   };

   render() {
      if (this.errorStore.get('errors').length !== 0)
         return (
            <>
               <ModalContainer size={'md'} toggleStore={this.toggleModalStore} toggle={this.toggle}>
                  <ModalHeader>
                     <FormattedMessage id={"ui.warning.error"}/>
                  </ModalHeader>
                  <ModalBody>
                     {this.errorStore.get('errors').map((error,i) => {
                        return (
                           <>
                              <Card hasHeader cardCollapsable defaultCollapsed={true} id={"GlobalError"+i}>
                                 <CardHeader>
                                    <h5>{$.get(error, 'status')}</h5>
                                 </CardHeader>
                                 <CardBody>
                                    {/*{$.get(error, 'description') || $.get(error, 'message')}*/}
                                    {getErrorMessage(error,localeStore)}
                                 </CardBody>
                              </Card>

                           </>
                        )
                     })}
                  </ModalBody>
               </ModalContainer>
            </>
         );
      return null;
   }
}
const getErrorMessage=(error, localeStore)=>{
   const userMessage = $.get(error, 'userMessage');
   if(userMessage){
      const currentLocale = localeStore.locale;
      const message = $.get(userMessage, 'message')
      if(message){
         const value = $.get(message,currentLocale);
         if(value){
            return value;
         }
      }

   }
   return $.get(error, 'description') || $.get(error, 'message');
}

export {GlobalErrorView}