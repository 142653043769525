import { UIStore } from "@isf/core-app-store";
import objectUtil from "@isf/core-object-util";

class TreeStore extends UIStore {

  // deprecated
  getActive() {
    const active = this.get(['active']);
    if (!active) return undefined;
    const keys = objectUtil.keys(active);
    if (!keys.length) return undefined;
    if (this.get(['active', keys[0]])) return keys[0];
    return undefined;
  }

  getActivePath() {
    const active = this.get(['active']);
    if (!active) return undefined;
    const keys = objectUtil.keys(active);
    if (!keys.length) return undefined;
    if (this.get(['active', keys[0]])) return keys[0];
    return undefined;
  }

  setActive({ path }) {
    const activePath = ['active', path];
    const alreadyActive = this.get(activePath);
    // this.clear(['active']);
    if (!alreadyActive) {
      this.clear(['active']);
      this.set(activePath, true);
    }
  }

  selected() {
    return this.get('selected')
  }

}

Object.defineProperty(TreeStore, "name", {value: "TreeStore"});

export default TreeStore;