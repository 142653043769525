import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {inject, observer, Provider} from "mobx-react";
import classNames from "classnames";
import {FilterStore} from "@isf/filter";
import {resolveStore} from "@isf/react-util";
import TableHead from "./table-head";
import TableBody from "./table-body";
import {TableStore} from '../table-store';
import {injectIntl} from "react-intl";
import { ICON_FILTER_COLOR } from "@isf/common-resources";
import {LoadSpinner} from "../../../load-spinner/load-spinner-view";

@injectIntl
@observer
class Table extends Component {

  constructor(props) {
    super(props);
    // this.dataStore = resolveStore({store: props.dataStore, type: DataStore});

    const {data, accessor, dataStore} = props;
    this.store = resolveStore({...props, type: TableStore});
    this.store.selectionStore.setRequestStore(props.requestStore);
    this.store.selectionStore.set('dataStoreRef', {
      store: props.dataStore,
      accessor: accessor
    });
    this.filterStore = resolveStore({store: props.filterStore, type: FilterStore}); //this.props.filterStore ? this.props.filterStore : new FilterStore();
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    if (props.uiStoreType) this.uiStore = new props.uiStoreType(props)

    this.dataStoreObj = {
      store: dataStore,
      index: accessor ? accessor : undefined
    };

    if(data) {
      this.dataStoreObj.store = data.store;
      const index = this.dataStoreObj.index;
      this.dataStoreObj.index = index ? data.index + "." + index : data.index;
      this.store.selectionStore.set('dataStoreRef', {
        store: this.dataStoreObj.store,
        accessor: this.dataStoreObj.index
      });
    }
  }

  componentWillUnmount() {
    this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  setFilterActive = (filterStore) => {
    if (filterStore.isFilterActive) {
      filterStore.clearFilter();
    }
    filterStore.setIsFilterActive();
  };

  isTableFilterable(columns) {
    for (const column of columns) {
      if (column.filterable) {
        return true;
      }
    }
    return false;
  }

  componentDidMount() {
    this.store.selectionStore.clearStore();
  }

  render() {
    const {dataStoreObj, props} = this;
    const {columns, className, isClient, dataStore, intl, selection, choice, extConfiguration, id, filterable, isConstructorMode/*, children*/} = props;
    const resultColumns = choice ? getResultColumns(columns) : columns;
    const showFilter = isClient ? filterable && this.isTableFilterable(resultColumns) : filterable;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();
    return (
      // <Provider dataStore={dataStore} dataStoreObj={dataStoreObj} >
      <div className={classNames('position-relative mb-3', { 'table-responsive': !isConstructorMode })}>
          {this.uiStore && <LoadSpinner isLoading={this.uiStore.isLoading}/>}
          {showFilter &&
          <img src={ICON_FILTER_COLOR} alt="filter"
               className="position-absolute h-auto icon-w-18 icon-filter cursor-pointer"
               onClick={() => this.setFilterActive(this.filterStore)}/>}
          <table className={classNames("table", className, computedClassNames)}>
            <TableHead store={this.store} filterStore={this.filterStore} columns={resultColumns} isClient={isClient}
                       id={id + '.head'} selection={selection}/>
            <TableBody columns={resultColumns} store={this.store} isClient={isClient} dataStore={dataStore} intl={intl}
                       id={id + '.body'} selection={selection} extConfiguration={extConfiguration} dataStoreObj={dataStoreObj}/>
          </table>
        </div>
      // </Provider>
    );
  }
}

const getResultColumns = (columns) => {
  // columns.unshift(choiceColumn);
  return [choiceColumn, ...columns];
};

const choiceColumn = {
  type: "checkbox",
  accessor: 'checkbox',
  sortable: false,
  filterable: false,
  editable: false,
  width: 45
};

Table.propTypes = {
  id: PropTypes.string,
  isClient: PropTypes.bool.isRequired,
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  store: PropTypes.object,
  dataStore: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  filterStore: PropTypes.object
};

export {Table};
