import React, {Component} from "react";
import { Navbar, NavItem, NavLink, NavbarStore, SectionContainer, SectionContent, Toggle, ToggleStore, TableView, TableViewStore, FormField } from "@isf/common";
import { Header } from "@isf/elementary";
import { Row, Col } from "@isf/bootstrap";
import { resolveStore, useInputHandler } from "@isf/react-util";
import { rolesColumns, accountsColumns, layout } from "./groups-constants";
import { GroupStore } from "./groups-store";
import { Button } from "reactstrap";
import GroupDataModal from "./group-data-modal";
import { observer } from "mobx-react";
import $ from "@isf/core-object-util";

const groupsNavStore = resolveStore({ path: 'groupsNav', type: NavbarStore, state: { active: { roles: true } } });
const rolesTableViewStore = resolveStore({ path: 'rolesTableViewStore', type: TableViewStore });
const accountsTableViewStore = resolveStore({ path: 'accountsTableViewStore', type: TableViewStore });
const rolesToggleStore = resolveStore({store: 'rolesToggleStore', type: ToggleStore});
const accountsToggleStore = resolveStore({store: 'accountsToggleStore', type: ToggleStore});

@observer
class GroupsView extends Component {

  groupStore;

  constructor(props) {
    super(props);
    this.groupStore = props.groupStore ? props.groupStore : new GroupStore();
    if(this.groupStore.getId() !== "new") {
      this.groupStore.load();
    }
    this.useHandler = useInputHandler({store: this.groupStore});
    this.id = this.id.bind(this);
  }

  id(childId) {
    return this.props.id + '.' + childId;
  }

  addNewRole = (roles) => {
    this.groupStore.addNewRole(roles);
  }

  addAccount = (accounts) => {
    this.groupStore.addNewAccount(accounts);
  }

  handleSaveGroup() {
    this.groupStore.save();
  }

  handleRolesButtonClick() {
    this.groupStore.loadRoles();
  }

  handleAccountsButtonClick() {
    this.groupStore.loadAccounts();
  }

  handleDeleteRolesButtonClick() {
    const roles = $.toJS(rolesTableViewStore.tableStore.data).filter((item, index)=> rolesTableViewStore.tableStore.selectionStore.selected.includes(index));
    this.groupStore.deleteGroupRoles(roles);
    rolesTableViewStore.tableStore.selectionStore.clearStore();
  }

  handleDeleteAccountsButtonClick() {
    const accounts = $.toJS(accountsTableViewStore.tableStore.data).filter((item, index)=> accountsTableViewStore.tableStore.selectionStore.selected.includes(index));
    this.groupStore.deleteGroupAccounts(accounts);
    accountsTableViewStore.tableStore.selectionStore.clearStore();
  }

  excludeGroupItemsFromAllItems(allItems, excludedItems) {
    let groupItemsIds = [];
    excludedItems.forEach(item => {
      groupItemsIds.push(item.id);
    });
    return allItems.filter(item => !groupItemsIds.includes(item.id));
  }

  render() {
    const rolesData = this.groupStore.getTableViewData(this.groupStore.roles, rolesTableViewStore);
    const accountsData = this.groupStore.getTableViewData(this.groupStore.accounts, accountsTableViewStore);
    const filteredAllRoles = this.excludeGroupItemsFromAllItems(this.groupStore.allRoles, this.groupStore.roles);
    const filteredallAccounts = this.excludeGroupItemsFromAllItems(this.groupStore.allAccounts, this.groupStore.accounts);
    return (
      <div>
        <Header type="h3" text="Группа"/>
        <FormField
          id={this.id('groupId')}
          type="input"
          label={"Идентификатор"}
          handler={this.useHandler('id')}
          disabled
          layout={layout}
        />
        <FormField
          id={this.id('groupName')}
          type="input"
          label={"Название группы"}
          handler={this.useHandler('name')}
          layout={layout}
        />
        <FormField
          id={this.id('groupDescription')}
          type="input"
          label={"Описание группы"}
          handler={this.useHandler('description')}
          layout={layout}
        />
        <Button color="primary" size="sm" onClick={(e)=>this.handleSaveGroup(e)}>Сохранить</Button>
        <hr/>
        { this.groupStore.getId() !== "new" && <>
          <Navbar id="groupNav" pills>
            <NavItem>
              <NavLink navFor="roles" href="#" navStore={groupsNavStore}>Роли</NavLink>
            </NavItem>
            <NavItem>
              <NavLink navFor="accounts" href="#" navStore={groupsNavStore}>Учетные записи</NavLink>
            </NavItem>
          </Navbar>
          <Row>
            <Col>
              <SectionContainer>
                <SectionContent name="roles" navStore={groupsNavStore}>
                  <Row>
                    <Col>
                      <TableView 
                        id="rolesTableView" 
                        pageable={true}
                        filterable={true}
                        store={rolesTableViewStore} 
                        tableViewData={rolesData} 
                        isClient={true} 
                        columns={rolesColumns}/>
                      <Toggle toggleStore={rolesToggleStore}>
                        <Button size="sm" onClick={()=>this.handleRolesButtonClick()}>Добавить</Button>
                      </Toggle>{" "}
                      { rolesTableViewStore.tableStore.selectionStore.selected.length > 0 && <Button size="sm" onClick={()=>this.handleDeleteRolesButtonClick()}>Удалить</Button> }
                      <GroupDataModal id="allRoles" groupStore={this.groupStore} groupData={filteredAllRoles} columns={rolesColumns} toggleStore={rolesToggleStore} header="Роли" onChange={this.addNewRole} />
                    </Col>
                  </Row>
                </SectionContent>
                <SectionContent name="accounts" navStore={groupsNavStore}>
                  <Row>
                    <Col>
                      <TableView 
                        id="accountsTableView" 
                        pageable={true}
                        filterable={true}
                        store={accountsTableViewStore} 
                        tableViewData={accountsData} 
                        isClient={true} 
                        columns={accountsColumns}/>
                      <Toggle toggleStore={accountsToggleStore}>
                        <Button onClick={()=>this.handleAccountsButtonClick()} size="sm">Добавить</Button>
                      </Toggle>{" "}
                      { accountsTableViewStore.tableStore.selectionStore.selected.length > 0 && <Button size="sm" onClick={()=>this.handleDeleteAccountsButtonClick()}>Удалить</Button> }
                      <GroupDataModal id="allAccounts" groupStore={this.groupStore} groupData={filteredallAccounts} columns={accountsColumns} toggleStore={accountsToggleStore} header="Аккаунты" onChange={this.addAccount} />
                    </Col>
                  </Row>
                </SectionContent>
              </SectionContainer>
            </Col>
          </Row>
        </> }
      </div>
    )
  }
}

export { GroupsView };
