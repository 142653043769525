import React, {Component, Fragment} from "react";
import Input from "./input";
import {observer} from "mobx-react";
import $ from "@isf/core-object-util";
import {resolveStore} from "@isf/react-util";
import {DropdownSelect, DropdownStore} from "../dropdown/src";
import LocaleOption from "../localization/src/locale-option";
import {LocaleSwitch as LocaleImage} from "../localization/src";
import {Col, Container, FormFeedback, FormGroup} from "reactstrap";
import {ExpandedLocaleField} from "./expanded-locale-field/expanded-locale-field";

const getInputProps = ({input, readOnly, handler, accessor, locale}) => {
  const id = $.get(locale, "id");

  return {
    props: {
      input: {
        ...input,
        props: {
          ...input.props,
          id: input.props.id + "_" + id
        }
      },
      handler,
      disabled: readOnly,
      accessor: accessor + "." + id
    },
    id: id,
    locale: $.get(locale, "locale")
  }
};

class LocaleFieldInput extends Component {

  render() {
    const {props} = this;
    let {type, input, handler, readOnly, accessor, error, languages, constructorView, placeholder} = props;
    if(!Array.isArray(languages) || languages.length === 0) {
      return null;
    }
    if (type === "switch") {
      return (
        <LocaleSwitchFieldInput {...props}/>
      )
    }
    if(/*!constructorView &&*/ input.props.type ==='text'){
      return (
          <ExpandedLocaleField {...props}/>
      )
    }

    languages = languages.map(item => getInputProps({input, readOnly, accessor, handler, locale: item}));

    return (
      <Container fluid={true}>
        {languages.map(({id, props, locale}) => (
          <FormGroup row={true} key={locale}>
            <div className="d-flex align-items-center w-100">
              <Input {...props} placeholder={placeholder}/>
              <LocaleImage name={id} />
            </div>
            {!!error && <FormFeedback style={{display: 'block'}}>{$.get(error,'description')}</FormFeedback>}
          </FormGroup>
        ))}
      </Container>
    );
  }
}

@observer
class LocaleSwitchFieldInput extends Component {
  constructor(props) {
    super(props);
    const {languages, localeStore} = props;
    this.id = $.get(props, "input.props.id") + ".field.localeSwitch";
    const currentLocale = (localeStore && localeStore.locale)
        || (Array.isArray(languages) && languages.length > 0 && languages[0].id);

    this.localeSwitchStore = resolveStore({
      id: this.id,
      type: DropdownStore,
      state: {value: currentLocale}
    });
  }

  render() {
    const {id, props, localeSwitchStore} = this;
    let {input, handler, intl, readOnly, accessor, error, languages, placeholder} = props;

    const locale = languages.find(item => item.id === localeSwitchStore.value);
    const switchProps = getInputProps({input, readOnly, accessor, handler, locale});

    return (
      <Fragment>
        <div className="d-flex align-items-start w-100 form-group">
          <Input {...switchProps.props} placeholder={placeholder}/>
          <LocaleSwitch id={id} intl={intl} store={localeSwitchStore} languages={languages}/>
        </div>
        {!!error && <FormFeedback style={{display: 'block'}}>{$.get(error,'description')}</FormFeedback>}
      </Fragment>
    );
  }
}

const LocaleSwitch = observer(({id, intl, store, languages}) => {
  const options = [
    { value: "head", header: intl.formatMessage({id: "ui.header.language.title"}) }
  ];
  languages.forEach(locale => {
    options.push({value: locale.id, name: locale.name})
  });

  return (
    <DropdownSelect
      id={id}
      options={options}
      store={store}
      optionTag={LocaleOption}
      className="ml-2"
    />
  );
});

export {LocaleFieldInput};