import { UIStore } from "@isf/core-app-store";
import { observable, computed, action } from "mobx";

class ExpandCollapseStore extends UIStore {
    @observable expanded;

    constructor(props) {
        super({...props, observableAsMap: true});
        this.expanded = props.expanded ? props.expanded : false
    }

    @action
    setExpanded(value) {
        this.expanded = value;
    }

    @computed
    get isExpanded() {
        return this.expanded;
    }
}

export { ExpandCollapseStore };