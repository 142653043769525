import { computed, action } from "mobx";
import $ from "@isf/core-object-util";

class ServiceTableViewStore {

  constructor(tableViewStore, data) {
    this._tableViewStore = tableViewStore;
    this._tableStore = tableViewStore.tableStore;
    this._paginationStore = tableViewStore.paginationStore;
    this._filterStore = tableViewStore.filterStore;
    this._data = data;
  }

  set data(data) {
    this._data = data;
  }

  get data() {
    return this._data;
  }

  get paginationStore() {
    return this._paginationStore;
  }

  get tableStore() {
    return this._tableStore;
  }

  get filterStore() {
    return this._filterStore;
  }

  get tableViewStore() {
    return this._tableViewStore;
  }

  get selectedData() {
    const { tableStore } = this;
    const { data, selectionStore } = tableStore;
    const selectedRows = selectionStore.selected;
    return selectedRows.length > 0 ? data.get(selectedRows[0]) : {};
  }

  @action
  setIdTable(id) {
    const { tableStore, paginationStore } = this;

    let checked;
    this.data.forEach((item, index) => {
      if ($.get(item, 'id') === id) {
        checked = index;
      }
    });

    if (checked === undefined) return;

    const currentPage = Math.ceil((checked + 1) / paginationStore.rowsPerPage);
    const index = checked % paginationStore.rowsPerPage;

    paginationStore.currentPage = currentPage;
    tableStore.data = this.pageData.rows;
    if (!tableStore.selectionStore.isRowChecked(index)) {
      tableStore.selectionStore.setCheckedRow(index);
    }
  }

  @action
  clearChecked() {
    const { tableStore, paginationStore } = this;
    paginationStore.currentPage = 1;
    tableStore.data = this.pageData.rows;
    tableStore.selectionStore.clearStore();
  }

  @action
  clearFilter() {
    if (this.filterStore.isFilterActive) {
      this.filterStore.clearFilter();
      this.filterStore.setIsFilterActive();
    }
  }

  @computed
  get pageData() {
    return getPageEmulator(
      {
        startItem: this.paginationStore.startItem,
        endItem: this.paginationStore.endItem,
        sortBy: this.tableStore.sortBy,
        order: this.tableStore.order,
        filterBy: this.filterStore.filterBy
      }, this._data
    );
  }
}


const sortFunction = (a, b, accessor, order) => {
  const value = a[accessor] > b[accessor];
  return value ? order : -order;
};

const filterFunction = (data, filterBy) => {
  const filterCols = filterBy;
  const accessors = Object.keys(filterBy);
  return data.filter(column => {
    const array = accessors.filter(accessor => {
      return String(column[accessor]).toUpperCase().startsWith(filterCols[accessor]);
    }
    );
    return array.length >= accessors.length;
  });
};

const getPageEmulator = ({ startItem, endItem, sortBy, order, filterBy }, dataClient) => {
  let data = dataClient;
  if (startItem === undefined || endItem === undefined || startItem > endItem) return data;
  if (filterBy) data = filterFunction(data, filterBy);
  const totalRows = data.length;
  if (sortBy) data = data.sort((a, b) => sortFunction(a, b, sortBy, order));
  const result = [];
  if (data.length < endItem) endItem = data.length;
  for (let i = startItem; i < endItem; i++) {
    result.push(data[i]);
  }
  return { rows: result, totalRows };
};

export { ServiceTableViewStore };