import React, { Component } from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";
import $ from "@isf/core-object-util";
//import { useInputHandler } from "@isf/react-util";
import { DataStore } from "@isf/core-app-store";
import { Col, Container, Row } from "@isf/bootstrap";
import { Button, FormField } from "../../../../form";
import { inputWithoutLabel/*, defaultRequestFilters*/ } from "./service-table-constants";

@observer
class ServiceTableFilters extends Component {
  constructor(props) {
    super(props);
    this.path = $.strToArray(props.path).join('.');
    this.id = this.id.bind(this);
    //this.setCurrentModule = this.setCurrentModule.bind(this);
    //this.setCurrentService = this.setCurrentService.bind(this);

    this.dataStore = new DataStore({
      path: this.id("dataStore"),
      state: {
        currentModule: false,
        currentService: false
      },
      observableAsMap: true
    });
    //this.useHandlerComponent = useInputHandler({store: this.dataStore});
    this.useHandler = this.useHandler.bind(this);
  }

  id(id) {
    return this.path + ".filters." + id;
  }

  useHandler(accessor, set) {
    const { useHandler, requestStore } = this.props;
    const handler = useHandler(accessor, set);
    handler.handler.get = requestStore.getLike.bind(requestStore);
    return handler;
  }

  // setCurrentModule(path, value) {
  //   const { requestStore } = this.props;
  //
  //   this.dataStore.set(path, value);
  //   requestStore.set("body.filter", defaultRequestFilters());
  //
  //   if (value) requestStore.set('body.filter.contextPath.0.value', "/" + moduleName + "/%");
  // }

  // setCurrentService(path, value) {
  //   const { requestStore } = this.props;
  //
  //   this.dataStore.set(path, value);
  //   requestStore.set("body.filter", defaultRequestFilters());
  //
  //   if (value) requestStore.set('body.filter.name.0.value', serviceName);
  // }

  @computed
  get isCurrentModule() {
    return this.dataStore.get("currentModule");
  }

  render() {
    const { intl, requestStore, paginationOnClick/*, currentServiceHandler = this.useHandlerComponent('currentService', this.setCurrentService)*/ } = this.props;

    const isCurrentModule = this.isCurrentModule;

    return (
      <Container>
        <Row className="align-items-center">
          <Col>
            <Row>
              <Col xs="12" lg>
                <FormField
                  id={"services.table.filter.name"}
                  label={intl.formatMessage({id: "ui.filter.name"})}
                  handler={this.useHandler('body.filter.name.0.value', requestStore.setLike)}
                  layout={inputWithoutLabel}
                  isPopUpLabel
                />
              </Col>
              <Col xs="12" lg>
                <FormField
                  id={"services.table.filter.contextPath"}
                  label={intl.formatMessage({id: "ui.filter.contextPath"})}
                  handler={this.useHandler('body.filter.contextPath.0.value', requestStore.setLike)}
                  hidden={isCurrentModule}
                  layout={inputWithoutLabel}
                  isPopUpLabel
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg>
                <FormField
                  id={"services.table.filter.description"}
                  label={intl.formatMessage({id: "ui.filter.description"})}
                  handler={this.useHandler('body.filter.description.0.value', requestStore.setLike)}
                  layout={inputWithoutLabel}
                  isPopUpLabel
                />
              </Col>
              <Col xs="12" lg>
                <FormField
                  id={"services.table.filter.version"}
                  label={intl.formatMessage({id: "ui.filter.version"})}
                  handler={this.useHandler('body.filter.version.0.value', requestStore.setLike)}
                  layout={inputWithoutLabel}
                  isPopUpLabel
                />
              </Col>
            </Row>
          </Col>
          <Col xs="12" md="auto">
            <Row>
              <Col xs="12" md>
                <Button onClick={paginationOnClick} outline color="primary">
                  {intl.formatMessage({id: "ui.filter.find"})}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export {ServiceTableFilters};