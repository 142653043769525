import { observable, action, runInAction } from "mobx";

import objectUtil from "@isf/core-object-util";
import {uuidv4} from "../../system-util/src";
// import Store from "./store";

export const normalizePath = (path) => objectUtil.isArray(path) ? path.join('.') : path;

const INIT_STATE = () => ({
  data: {},
  ui: {},
  localization: {}
});

const INIT_STORES = () => ({});
class AppStore {

  _state = INIT_STATE();
  
  _stores = INIT_STORES();

  _mapIds = new Map();

  constructor(router) {
    this.router = router;   
  }

  get state() {
    return this._state;
  }

  get location() {
    return this.router.location;
  }

  get(path) {    
    return objectUtil.get(this._state, path);
  }

  set(path, value) {
    objectUtil.set(this._state, path, value);    
  }

  push(path, value) {
    objectUtil.push(this._state, path, value);
  }

  add(path, value) {
    objectUtil.push(this._state, path, value);
  }

  clear(path) {
    objectUtil.clear(this._state, path);
  }
  remove(path, leaf) {
    objectUtil.remove(this._state, path, leaf);
    // this.app.remove(toAbsValuePath(this.absPath, path), leaf);
  }

  toggle(path) {
    const value = objectUtil.get(this._state, path);
    objectUtil.set(this._state, path, !value);   
  }

  makeObservable({ path, asMap}) {
    const value = objectUtil.get(this._state, path);
    if (objectUtil.isObservable(value)) {
      return;
    }
    if (asMap) {
      if (objectUtil.isObservableMap(value)) {
        return;
      }
      objectUtil.set(this._state, path, objectUtil.objectToObservableMap(value) );
      return;
    } 
    // if (objectUtil.isObservable(value)) {
    //   return;
    // }
    if (objectUtil.isObject(value)) {
      objectUtil.set(this._state, path, observable(value));
      return;
    }
    objectUtil.set(this._state, path, value);
  }

  invalidateStates(path) {
    const npath = normalizePath(path);
    runInAction(() => {
      Object.entries(this._stores).forEach(([spath, store]) => {
        if (spath.startsWith(npath)) {
          if (spath.indexOf('bundles') == -1) {
            store._state++;           
          }
        }
      });
    });
  }
  // createStore = (args) => {
  //   let { StoreClass=Store } = args;
  //   const store = new StoreClass({...args, app: this});   
  //   return this.bindStore(args, store);
  // }

  // invalidateStates() { //TODO: optimize
  //   // return;
  //   Object.entries(this._stores).forEach( 
  //     ([path, store]) => {
  //       if (store.state === undefined) {
  //         // console.log('invalidate state for store:', path, store, 'default', store._defaultState);
  //         store._updateState(store._defaultState);
  //       }
  //     }        
  //   )
  // }

  // rebind = (store, newPath) => {
  //   delete this._stores[store.absPath.join('.')];
  //   this._stores[newPath] = store;
  // }

  bindStore = (store) => {
    this._stores[store.absPath.join('.')] = store;
  }

  // bindStore = (/*args,*/ store) => {   
  //   let { path, state = {}, scope='data' } = store; //args;
  //   path = path ? objectUtil.strToArray(path) : [];
  //   objectUtil.set(this._state, [scope, ...path], state);

  //   const absPath = [scope, ...path].join('.');
    
  //   let { observableAsMap, observable } = store; //args;
  //   if (observableAsMap) {
  //     store.makeObservable({asMap: true});
  //   } else if (observable) {
  //     store.makeObservable({asMap: false});
  //   }
  //   Object.entries(this._stores).forEach( 
  //     ([path, store]) => {        
  //       if ( path !== absPath && path.startsWith(absPath) ) {
  //         const data = appStore.get(path);
  //         store.setState(data);
  //       } else if (path !== absPath && absPath.startsWith(path)) {
  //         // const data = appStore.get(absPath);
  //         // appStore.set(path, data);
  //       }
  //     }
  //   );
  //   this._stores[absPath] = store;
  //   return store;
  // }

  getStore = (path, scope) => {
    // const spath = objectUtil.isArray(path) ? path.join('.') : path;
    // try {   
    //   throw new Error('get store ' + path);
    // } catch(e) {console.warn(e)}
    let spath = normalizePath(path);
    if (scope) {
      spath = scope + '.' + spath;
    }
    return this._stores[spath];
  }

  getDataStore = (path) => {
    if(this._mapIds.get(path)){
      return this._stores['data.' + this._mapIds.get(path)];
    }
    const spath = normalizePath(path);
    return this._stores['data.' + spath];
  }

  addStoreToIdMap = (pathTempl) => {
    const storeId = uuidv4();
    this._mapIds.set(typeof pathTempl==='string'?pathTempl:pathTempl.join('.'),storeId);
    return storeId
  }

  storeExists(path) {
    const spath = normalizePath(path); 
    return !!this._stores[spath];
  }

  getOrCreateStore = ({path}) => {
    let store = this._stores[path]; 
    if (!store) {
      store = this.createStore({path});
    }
    return store;
  }

  get routerStore() {
    return this.getStore('router');
  }

  destroy() {
    Object.keys(this._stores).forEach(store => delete this._stores[store]);
    this._state = INIT_STATE();  
    this._stores = INIT_STORES();
  }

  // @action
  destroyData() {
    this._state.data = {};  
    const dataStores = Object.keys(this._stores).filter( 
      (key) => key.startsWith('data') 
    );
    dataStores.forEach(store => delete this._stores[store]);   
  }

  destroyScope(scope) {
    if (objectUtil.isObservableMap(this._state[scope])) {
      this._state[scope].clear();
    } else {
      this._state[scope] = {};
    }  
    const dataStores = Object.keys(this._stores).filter( 
      (key) => key.startsWith(scope) 
    );
    dataStores.forEach(store => delete this._stores[store]);
    // this._state = INIT_STATE();  
    // this._stores = INIT_STORES();
  }

}

const appStore = new AppStore();

export default appStore;