import styled from 'styled-components';

const SplitterWrapper = styled.div`
  .Resizer {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
     background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }

  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .Resizer.horizontal {
    height: 0.85rem;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }

  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }

  .Resizer.vertical {
    width: 0.85rem;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }

  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Pane1 {
    display: flex;
    overflow: auto;
    min-height: 0;
    min-width: 19%;
    max-width: ${props => props.maxWidth}
    
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 6px;
      background-color: #F5F5F5;
    }

   &::-webkit-scrollbar
    {
      width: 7px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar:horizontal
    {
      height: 6px;
    }

    &::-webkit-scrollbar-thumb
    {
      border-radius: 6px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: gray;
    }
  }

  .SplitPane.showApi.vertical .Pane1{
    display: block;
  }


  .Pane2 {
    display: flex;
    overflow: auto;
    min-height: 0;
    min-width: 19%;
    // background-color: white;
    max-width: ${props => props.maxWidth}

    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 6px;
      background-color: #F5F5F5;
    }

   &::-webkit-scrollbar
    {
      width: 7px;
      background-color: #F5F5F5;
    }
    
   &::-webkit-scrollbar:horizontal
    {
      height: 6px;
    }

    &::-webkit-scrollbar-thumb
    {
      border-radius: 6px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: gray;
    }
  }
`;

export { SplitterWrapper };
