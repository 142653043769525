import React from 'react';
import SplitPane from 'react-split-pane';
import PropTypes from 'prop-types';
import {SplitterWrapper} from './style-wrapper';
import {observer} from "mobx-react";
import classNames from "classnames";

@observer
class Splitter extends React.Component{
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {children, className, wrapperClassName, maxSize = "0", uiBaseStore, ...other} = this.props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <SplitterWrapper className={wrapperClassName}>
        <SplitPane maxSize={maxSize} className={classNames(className, computedClassNames)} {...other}>
          {children}
        </SplitPane>
      </SplitterWrapper>
    );
  }
}

Splitter.propTypes = {
  defaultSize: PropTypes.string,
  maxWidth: PropTypes.number,
  split: PropTypes.string.isRequired,
  ...SplitPane.propTypes
};

export default Splitter;
