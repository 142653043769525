import React from "react";
import PropTypes from "prop-types";
import { Modal as Modal_ } from "reactstrap";
import { inject, observer } from "mobx-react";

const Modal = inject("toggleStore")(observer(
  (props) => {
    const { toggleStore, children, uiStoreType, loadDataAction, body, footer, header, languages, localeStore,
            ...other } = props;
    const isOpen = toggleStore.isActive();
    return (
      <Modal_ isOpen={isOpen} style={{ overflow: 'clip visible' }} {...other}>
        {children}
      </Modal_>
    );
  }
));

Modal.propsTypes = {
  toggleStore: PropTypes.object.isRequired
};

export { Modal };