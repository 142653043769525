import React from 'react'
import {observer} from "mobx-react";
import {Spinner} from "@isf/bootstrap";

export const LoadSpinner = observer(props => {
   const {isLoading, color = "primary", type = "border", size, width, hideSpinner} = props;
   if (isLoading) {
      return (
         <div
            className="d-flex justify-content-center align-items-center w-100 h-100 position-absolute top-0 left-0 overflow-hidden">
            {!hideSpinner &&
            <Spinner color={color} type={type} size={size} className="position-relative overflow-hidden load-spinner"
                     style={{width: width, height: width}}/>
            }
            <div className="position-absolute w-100 h-100 load-spinner-background-shadow"></div>
         </div>
      )
   }
   return null;
});