import React, {Fragment} from "react"
import {observer} from "mobx-react";
import {LocaleMessageStore} from "./expanded-locale-field-store";
import {Row, Col, Collapse, FormGroup, Label, Container} from "@isf/bootstrap";
import {LocaleSwitch} from "../../localization/src";
import {ICON_TOGGLE_DROPDOWN} from "@isf/common-resources";
import $ from "@isf/core-object-util/src/object-util";
import Input from "../input";

@observer
class ExpandedLocaleField extends React.Component {

    constructor(props) {
        super(props);
        this.localeMessageStore = new LocaleMessageStore();
    }
    getLocale=(availableLocales,lang)=>{
        for(let i=0;i<availableLocales.length;i++){
            if($.get(availableLocales[i],'lang')===lang){
                return availableLocales[i];
            }
        }
    }
    render() {
        const {localeStore, label, hidden, ...other} = this.props;
        const currentLocale = localeStore.locale;
        const availableLocales = localeStore.getSortedLocales(currentLocale);
        const isOpen = this.localeMessageStore.isAllLanguagesShawn();
        if (hidden) return null;
        return (
            <Fragment>
                <Container fluid={true} className="px-0">
                    <LocaleField main key={currentLocale} {...other} label={label} locale={this.getLocale(availableLocales,currentLocale)}
                                 localeStore={localeStore} localeMessageStore={this.localeMessageStore}/>
                    <Collapse isOpen={isOpen}>
                        {availableLocales.map(locale => {
                            if (locale.id !== currentLocale) {
                                return (
                                    <LocaleField key={locale.id} {...other} label={""} locale={this.getLocale(availableLocales,locale.id)}
                                                 localeStore={localeStore}
                                                 localeMessageStore={this.localeMessageStore}/>
                                );
                            }
                        })}
                    </Collapse>
                </Container>
            </Fragment>
        )
    }
}

const handleChange = ({e, locale, messageId, localeStore}) => {
    localeStore.setMessage(messageId, e.target.value, locale);
};

const handleImgClick = (localeMessageStore) => {
    const isOpen = localeMessageStore.isAllLanguagesShawn();
    localeMessageStore.setShowAllLanguages(!isOpen);
};

const LocaleField = observer((props) => {
    const {localeStore, locale, label, id, layout = "default",input, type, main, templates, readOnly, accessor, handler, propsHandleChange = handleChange, localeMessageStore, ...other} = props;
    const messageId=$.get(input,'message.id');
    // const value = localeStore.getMessage(messageId, locale.id);
    const switchProps = getInputProps({input, readOnly, accessor, handler, locale});
    return (
        <FormGroup row={true} className="no-gutters">
            {/*<Label {...resultLayout.label.props} htmlFor={`in_${id}.${locale}`}*/}
            {/*       id={`lb_${id}.${locale}`}>{label}</Label>*/}
            <Col {...input.col} className="d-flex align-items-center p-0">
                <div className="input-container">
                    <Input {...switchProps.props} type={type}
                           // onChange={(e) => propsHandleChange({e, locale, messageId, localeStore, ...other})}
                           // value={value || ""} id={`in_${id}.${locale}`}
                    />
                </div>
                <LocaleSwitch name={locale.id} onClick={() => main ? handleImgClick(localeMessageStore) : undefined}
                              main={main}/>
                {main ? <img onClick={() => handleImgClick(localeMessageStore)} src={ICON_TOGGLE_DROPDOWN} alt="arrow"
                             className="cursor-pointer ml-1 icon-w-7"/> :
                    <img src={ICON_TOGGLE_DROPDOWN} className="ml-1 icon-w-7 opacity-0" alt="arrow"/>}
            </Col>
        </FormGroup>
    );
});

const getInputProps = ({input, readOnly, handler, accessor, locale}) => {
    const id = $.get(locale, "id");
    return {
        props: {
            input: {
                ...input,
                props: {
                    ...input.props,
                    id: input.props.id + "_" + id
                }
            },
            handler,
            disabled: readOnly,
            accessor: accessor + "." + id
        },
        id: id,
        locale: $.get(locale, "locale")
    }
};

export {ExpandedLocaleField}