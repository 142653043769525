import $ from "@isf/core-object-util";
import {getParamData} from "./utils";

const createJSFunctionByConfig = (config) => {
  const declaration = $.get(config, "declaration");
  const params = $.get(declaration, "params"),
    expression = $.get(declaration, "expression");

  try {
    const names = $.isArray(params)
      ? params.map(item => $.get(item, "name"))
      : [];
    const newFunc = new Function("$", ...names, expression);
    return newFunc.bind(undefined, $);

  } catch (e) {
    console.error("Function create error", e);
  }

};

const getFunctionArguments = (config, localeStore, data, context) => {
  const params = $.get(config, ["declaration", "params"]);
  let args = [];
  if($.isArray(params)) {
    const {locale} = localeStore;

    args = params.map(param => {
      let pathArray = param.path ? $.strToArray(param.path) : [];
      const storeName = param.storeName;
      param.isLocalized && pathArray.push(locale);

      return getParamData({data, pathArray, storeName, context});
    });
  }

  return args;
};

export {createJSFunctionByConfig, getFunctionArguments};