import {lazy} from 'react';
import {registerComponent} from '@isf/ui-schema/src/ui-schema-components';
import {handleError, handleModuleImport} from '@isf/dynamic-import-handler';

const ServiceDesignView = lazy(() => import('@isf/sdk-design-components/src/designers/service/service-design-view')
  .then(module => handleModuleImport(module, '@isf/sdk-design-components/src/designers/service/service-design-view', 'ServiceDesignView'))
  .catch(handleError));

const PageTemplateDesignView = lazy(() => import('@isf/sdk-design-components/src/designers/page-template/page-template-design-view')
  .then(module => handleModuleImport(module, '@isf/sdk-design-components/src/designers/page-template/page-template-design-view'))
  .catch(handleError));

const DesignView = lazy(() => import('@isf/sdk-design-components/src/design-view/design-view')
  .then(module => handleModuleImport(module, '@isf/sdk-design-components/src/design-view/design-view'))
  .catch(handleError));

export const registerSdkComponents = () => {
  registerComponent('ServiceDesignView', ServiceDesignView);
  registerComponent('DesignView', DesignView);
  registerComponent('PageTemplateDesignView', PageTemplateDesignView);
};

registerSdkComponents();