import { RestApi, RestHandler } from "@isf/core-rest";
// import { getEnvVariable } from "@isf/core-system-util";
import { getEnvApiConfig } from "@isf/core-rest";

class LoginHandler extends RestHandler {

  handleResponse = async ({ response }) => {
    if (response.ok) {
      const user = await this.readBody(response);
      return { user };
    } else if (response.status >= 400) {
      const fail = await this.readBody(response) || {};      
      return { fail };
    }
    return {}; 
  }  
}

class GetUserHandler extends RestHandler {
  handleResponse = async ({ response }) => {
    if (response.ok) {
      const user = await this.readBody(response);
      return user;
    }
    return undefined; 
  }  
}

class IdentityApi extends RestApi {

  constructor() {
    super({
      ...getEnvApiConfig('IDENTITY'),     
      defaultOptions: {
        credentials: "include",
        mode: 'cors',
        method: "GET"
      }
    });    
  }

  async login(creds) {     
    const result = await identityApi.call({
      path: '/login',
      handler: new LoginHandler(),
      options: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(creds)
      }
    });
    return result;
  }

  async getUser() {
    const user = await identityApi.call({
      path: 'user',
      handler: new GetUserHandler()
    });
    return user;
  }
}

const identityApi = new IdentityApi();
export default identityApi;