import bpUrlReaction from './bp-url-reaction';
import { initializePage } from '../ui-engine';

const reactionList = [
  bpUrlReaction,
  initializePage
]

/**
 * Execute list of reaction. If reaction return true then stop the following
 * @param pathname
 * @returns {Promise<void>}
 */
const urlReaction = async (pathname) => {
  for (const reaction of reactionList) {
    const stopList = await reaction(pathname)
    if (stopList) {
      return;
    }
  }
}

export default urlReaction;
