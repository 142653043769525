import { RestApi } from "@isf/core-rest";
import { getEnvVariable } from "@isf/core-system-util";
import { FileRestHandler } from "@isf/common";
import {CallActionRestHandler} from "./api-call-action/call-action-rest-handler";

const API_DEFAULT_CONF = {
  "schema": getEnvVariable('API_DEFAULT_SCHEMA'),
  "host": getEnvVariable('API_DEFAULT_HOST'),
  "port": getEnvVariable('API_DEFAULT_PORT'),
  "context": getEnvVariable('API_DEFAULT_CONTEXT') + '/conf'
}

const identityURL = () => {
  const schema = getEnvVariable('API_IDENTITY_SCHEMA');
  const host = getEnvVariable('API_IDENTITY_HOST');
  const port = getEnvVariable('API_IDENTITY_PORT');
  return `${schema}://${host}:${port}`;
}

const confApi = new RestApi({ ...API_DEFAULT_CONF /*, ...{context: 'api/conf'}*/ });
const userApi = new RestApi({ ...API_DEFAULT_CONF, ...{ context: 'api' } });
const restApi = new RestApi({ ...API_DEFAULT_CONF, ...{ context: 'api' } });
const fileApi = new RestApi({
  ...API_DEFAULT_CONF,
  ...{ context: 'api' },
  handler: new FileRestHandler()
});
const callActionApi = new RestApi({
  ...API_DEFAULT_CONF,
  ...{ context: 'api' },
  handler: new CallActionRestHandler()
});
export {confApi, userApi, restApi, fileApi, callActionApi, identityURL, API_DEFAULT_CONF};