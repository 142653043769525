import React from "react";
import {Draggable} from "react-drag-and-drop"
import {observer} from "mobx-react";


export const DraggableWrapper = observer((props) => {
    const {store, path, onStartDrag ,onDrop,onDrag,addRefToArray,onMouseUp, data} = props;
    return (
      <Draggable
        onMouseDown={(e) => handleStartDrag(path, store, data,e, onStartDrag)}
        // onDrop={(e) => handleDrop(e, {store,path,data:props.children.props.args.uiSchemaCursor},onDropFull)}
        //  onDrag={(e) => handleDrag(e, store,onDrag)}
      >
        {props.children}
      </Draggable>
    );
  }
);

const handleMouseUp=(onMouseUp)=>{
  onMouseUp();
};

const handleStartDrag=(path,store,data,e,startDrag)=>{
  startDrag(path,store,data,e);
};

const handleDrop=(e, props,drop)=>{
  drop(e, props);
};

const handleDrag=(e, store,drag)=>{
  drag(e, store);
};


