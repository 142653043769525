import { reaction } from "mobx";

import { routerStore } from "@isf/core-app-store";
import { initializePage, urlReaction } from "@isf/ui-schema";

// import userStore from "./identity/user-store";

//TODO: put page initialization logic here

// userStore.initialize();

const initUI = () => {
  urlReaction(routerStore.pathname);
  reaction(
    () => routerStore.pathname,
    pathname => {
      urlReaction(pathname);
    }
  )
};

export { initUI };
