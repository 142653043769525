import React from "react"
import {observer, Provider} from "mobx-react";
import Button from "../../../form/button";
import {ToggleStore} from "../../../toggle"
import {Container} from "reactstrap";
import {DataStore} from "@isf/core-app-store";

@observer
class TableRecordEditor extends React.Component {
   constructor(props) {
      super(props);
      this.dummyStore = new DataStore({path:'dummyRecordStore',state:{},observableAsMap:true});
      this.dataStore = props.dataStore;
      // this.toggleStore = new ToggleStore({path: props.id + '.toggleRecordStore'})
   }

   render() {
      return (
         <Provider handler={{
               get:(path)=>this.dummyStore.get(path),
               set:(path,value)=>this.dummyStore.set(path,value)
         }} dummyStore={this.dummyStore} dataStore={this.dataStore}>
            <Container fluid>
               {this.props.children}
            </Container>
         </Provider>

      )
   }
}

export {TableRecordEditor}