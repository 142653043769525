import React from "react";
import { observer } from "mobx-react";
import { TabContent as _TabContent } from 'reactstrap';
import $ from "@isf/core-object-util"

const TabContent = observer((props) => {
  const { children, navStore } = props;
  let active = ""
  if (navStore) active = navStore.stateJS && navStore.stateJS.active
  else { active = props.children[0].props.navStore.stateJS.active }
  let activeTab = ""
  active && $.keys(active).map(key => { if (active[key]) { activeTab = key } })
  return (
    <_TabContent activeTab={activeTab}  >
      {children}
    </_TabContent>)
});

export default TabContent;