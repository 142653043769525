import React from "react";
import {observer} from "mobx-react";
import $ from "@isf/core-object-util";
import Select from "./select";
import AsyncSelect from "./async-select";

const Autocomplete = observer((props) => {
  if ($.get(props, "input.props.autocomplete.type") === "select") {
    return (
      <Select {...props}/>
    );
  }

  return (
    <AsyncSelect {...props} />
  )
});


export default Autocomplete;