import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from "mobx-react";
import TableRow from "./table-row";

@observer
class TableBody extends Component {

  componentDidMount() {
    const {store} = this.props;
    const data = this.getData();
    store.setPageRows(data);
    store.selectionStore.clearStore();
  }

  componentDidUpdate(prevProps) {
    const {store} = this.props;
    const data = this.getData();
    store.setPageRows(data);
  }

  getData(prevProps) {
    const {store, dataStoreObj} = this.props;
    const accessor = dataStoreObj.index || [];
    return dataStoreObj.store && dataStoreObj.store.get(accessor)
      ? dataStoreObj.store.get(accessor)
      : store.data;
  }

  render() {
    const {props} = this;
    const {store, columns, intl, id} = props;
    const data = this.getData();

    return (
      <tbody>
      {data && data.length > 0 ?
        data.map((item, i) => {
          return (
            <TableRow key={i} rowId={id + '.row.' + i} rowIndex={i} item={item} {...props} />
          );
        }) :
        <tr>
          <td colSpan={columns.length}/>
          <td/>
        </tr>

      }
      </tbody>
    );

  }
}


TableBody.propTypes = {
  id: PropTypes.string,
  store: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default TableBody;
