import appStore, {routerStore, Store} from "@isf/core-app-store";
import {dataWrapper, localeWrapper, routerWrapper} from "./data-wrapper";
import {restHandler} from "./rest-handler";
import {storeTemplToPath} from "../util";
import { localeStore } from "@isf/core-localization";
import $ from "@isf/core-object-util";
import {action} from "mobx";
import deepmerge from "deepmerge";
import {getSearchBody} from "./search-request";
import {getLocalPort} from "./localhost-port";
import {computeExpression} from "../actions/compute-expression";

class ApiCallActionStore {
  _action = {};
  _requestObj = {};
  _listData;
  _setterNoReload;
  _componentName;
  _context = {};

  constructor({action, context, listData, setterNoReload, componentName}) {
    this.context = context;
    this.action = action;
    this._context = context;
    this.listData = listData;
    this.restHandler = restHandler.bind(this);
    this.setterNoReload = setterNoReload;
    this.componentName = componentName;
  }

  set action(action) {
    this._action = action;
    let requestObj = {};

    if (action.request.store) {
      //const storeName = storeTemplToPath(action.request.store);
      const storeName = action.request.store;
      const refId = this.context && this.context.refId;
      const newStoreName = refId ? refId + "." + storeName : storeName;
      requestObj = appStore.getDataStore(newStoreName);
    }

    this.requestObj = requestObj;
  }

  get action() {
    return this._action;
  }

  set listData(data) {
    if (!data || !(data.store instanceof Store)){
      this._listData = dataWrapper({});
      return;
    }
    const {store: listStore, index: listIndex} = data;

    const listData = listStore.data ? $.get(listStore.data, listIndex+"") : listStore.get(listIndex+"");
    this._listData = dataWrapper(listData);
  }

  get listData() {
    return this._listData;
  }

  set requestObj(obj) {
    this._requestObj = dataWrapper(obj);
  }

  get requestObj() {
    return this._requestObj;
  }

  get requestMapping() {
    return this.action.request.mapping;
  }

  get responseMapping() {
    return this.action.response;
  }

  set setterNoReload(setterNoReload) {
    this._setterNoReload = setterNoReload;
  }

  get setterNoReload() {
    return this._setterNoReload;
  }

  set componentName(componentName) {
    this._componentName = componentName;
  }

  get componentName() {
    return this._componentName;
  }

  get context() {
    return this._context;
  }

  set context(context) {
    this._context = context;
  }

  getContentType(type) {
    return $.get(this.action, ["contentType", type]);
  }

  getStore(storeName) {
    let store;
    const refId = this.context && this.context.refId;

    if (storeName === "pageUrl") {
      if(refId){
        const globalRefsStore = appStore.getDataStore('globalRefsStore');
        const params = globalRefsStore.get('refs.params.' + refId);
        // if(params) {
          store = dataWrapper(params);
        // }
        // else{
        //   store = routerWrapper(routerStore);
        // }
      } else {
        store = routerWrapper(routerStore);
      }
    } else if (storeName === "localeStore") {
      store = localeWrapper(localeStore);

    } else if (storeName === "userStore") {
      store = appStore.getStore("user", "user");

    } else if (storeName === "objectItemStore") {
      store = this.listData;

    } else {
      // storeName = storeTemplToPath(storeName);
      storeName = refId ? refId + "." + storeName : storeName;
      store = appStore.getDataStore(storeName);
    }

    return store;
  }

  getMappingValue(variable, value, isListArrayChild) {
    if (variable) {
      const mapStore = this.getStore(variable);
      let path = value.split('.').slice(1);
      const index = path.lastIndexOf("[]");
      if (isListArrayChild && index !== -1) {
        let arr = mapStore.get(path.slice(0, index));
        if (!$.isArray(arr)) {
          return;
        }

        const childPath = path.slice(index + 1);
        arr = arr
          .map(it => $.get(it, childPath))
          .filter(it => it !== undefined && it !== null);
        return arr.length ? arr : undefined;

      } else {
        return mapStore.get(path);
      }
    }

    return value
  }

  createArrays(destPath, store = this.requestObj) {
    destPath.forEach((segment, index) => {
      if (segment.startsWith("[") && segment.endsWith("]")) {
        const path = destPath.slice(0, index);

        if (!store.get(path)) {
          store.set(path, []);
        }
      }
    });
  }

  requestMap(outerContext) {
    /*this.requestMapping.forEach(map => {*/
    let noExecute = false;
    for( let i=0;i<this.requestMapping.length;i++) {
      const map = this.requestMapping[i];
      const destPath = [map.dest.parameterType];

      if (destPath[0] === "body") {
        destPath.push(...map.dest.value.value.split('.').slice(1))
      } else {
        destPath.push(...map.dest.value.value.split('.'))
      }

      let isListArrayChild = false;
      if (destPath.lastIndexOf("[]") !== -1) {
        destPath.pop();
        isListArrayChild = true;
      }
      const value = this.getMappingValue(map.source.variable, map.source.value, isListArrayChild);

      if(map.source.variable) {
        const mapStore = this.getStore(map.source.variable);
        const path = map.source.value.split('.').slice(1);
        const frontValidationConfig = mapStore && $.get(mapStore.errorState, ["frontValidation", ...path]);
        if (frontValidationConfig && !value && outerContext) {
          const isRequired = computeExpression(frontValidationConfig, outerContext.data, outerContext.context);
          if (isRequired) {
            mapStore.addToErrorState(path, {message: localeStore.localeMessages["ui.alerts.required"] || "Поле обязательно для заполнения"});
            noExecute = true;
          }
        }
      }
      const newDestPath = destPath.map(segment => segment.replace(/\[|\]/g, ""));
      this.createArrays(destPath);
      if (destPath[0] === 'body' && value === undefined) {
        continue;
      }
      this.requestObj.set(newDestPath, value);
    }
    return noExecute;
   /* })*/
  }

  path() {
    let path = this.action.request.path;
    const pathObj = this.requestObj.get("path", true) || {};

    // if(!this.action.request.isApiUrl) {
    //   path = path.replace("{port}", getLocalPort())
    // }

    $.keys(pathObj).forEach(parameter => {
      const paramValue = $.get(pathObj, parameter);
      path = path.replace(`{${parameter}}`, `${paramValue}`);
    });

    return path;
  }

  queryParams() {
    const query = this.requestObj.get("query", true) || {};
    const queryParams = $.get(this.action, "request.queryParams") || {};

    if ($.keys(query).length === 0 && $.keys(queryParams).length === 0) return;
    const queryResponse = {
      ...queryParams,
      ...query
    };
    $.keys(queryResponse).forEach(key => {
      if ($.get(queryResponse, [key]) === undefined) {
        $.remove(queryResponse, key);
      }
    });

    return queryResponse;
  }

  createBodyByContentType(body, contentType) {
    contentType = !contentType ? contentType : contentType.toLowerCase();
    if(!contentType || contentType.startsWith("application/json")) {
      return JSON.stringify(body);

    } else if(contentType.startsWith("multipart/form-data")) {
      let form = document.createElement("form");
      form.className = "d-none";
      // const formData = new FormData();
      body = $.isObject(body) ? body : {};
      const keys = $.keys(body);
      let input;
      keys.forEach(key => {
        const value = $.get(body, key);
        if(!$.isObject(value) && value !== undefined) {
          input = document.createElement("input");
          input.setAttribute("name", `${key}`);
          input.setAttribute("value", value);
          input.setAttribute("type", "hidden");
          form.appendChild(input);
          // formData.append(key + "", value)
        }
      });

      input = document.createElement("input");
      input.setAttribute('type',"submit");
      input.setAttribute('value',"Submit");
      form.appendChild(input);

      return form;
      // return formData;
    }
  }

  body() {
    let body = this.requestObj.get("body", true);

    if (this.action.request.path.split("/").includes("search")) {
      body = body ? body : {};

      if(JSON.stringify(body.sort)===JSON.stringify({})){
        delete body['sort'];
      }
      body.filter = body.filter
        ? this.clearFilter(body.filter)
        : {};

      body.sort = body.sort
        ? this.setLocalizedSort(body.sort)
        : body.sort;

      if (this.action.disableCache !== true) {
        body = getSearchBody(this.action, body, this.componentName, this.requestObj);
      }
    }

    const contentType = this.getContentType("request");
    return this.createBodyByContentType(body, contentType);
  }

  options() {
    const options = {
      ...this.action.request.options
    };

    // let headers = options.headers
    //   ? {...options.headers}
    //   : {'Accept': 'application/json', 'Content-Type': 'application/json'},
    //   contentType = this.getContentType("request");
    //
    // if (contentType) {
    //   headers["Content-Type"] = contentType;
    // }
    //
    // contentType = this.getContentType("response");
    // if (contentType) {
    //   headers["Accept"] = contentType;
    // }
    //
    // if($.keys(headers).length > 0) {
    //   options["headers"] = headers;
    // }
    return options;
  }

  setLocalizedSort(sort) {
    if ($.isObject(sort)) {
      const keys = $.keys(sort);
      if (keys.length === 1 && $.isObject(sort[keys[0]]) && $.get(sort, [keys[0], "{localeStore:locale}"])) {
        sort = {
          [keys[0]]: {
            [localeStore.locale]: $.get(sort, [keys[0], "{localeStore:locale}"])
          }
        }
      }
    }

    return sort;
  }

  clearFilter(schema) {
    for (let filterName in schema) {
      if ($.isArray(schema[filterName])) {
        schema[filterName] = schema[filterName].filter(item => !!item && ![undefined, null, ""].includes(item.value));

      } else if($.isObject(schema[filterName])) {
          schema[filterName] = this.clearFilter(schema[filterName]);
      }
    }

    return schema;
  }

  getRequestOptions(outerContext) {
    const noExecute = this.requestMap(outerContext);
    const requestConf = {
      path: this.path(),
      options: this.options(),
      isApiUrl: this.action.request.isApiUrl
    };

    const method = $.get(requestConf, "options.method");
    const body = this.body();
    const contentType = this.getContentType("request");
    if(contentType && contentType.startsWith("multipart/form-data")) {
      body.setAttribute("action", requestConf.path);
      body.setAttribute("method", method);
      if (!noExecute) {
        document.body.appendChild(body);
        body.submit();
      }
      return { noExecute:noExecute };
    }
    const queryParams = this.queryParams();

    if (["post", "put", "delete"].includes(method)) {
      requestConf["options"]["body"] = body;
    }

    if (queryParams) {
      requestConf["queryParams"] = queryParams;
    }
    return {requestConf: requestConf, noExecute:noExecute};
  }

  getResponseValue(body, path, contentType, isListArrayChild) {
    contentType = !contentType ? contentType : contentType.toLowerCase();
    if(!contentType || contentType.startsWith("application/json")) {
      if (path !== "body") {

        path = path.split('.').slice(1);
        const index = path.lastIndexOf("[]");

        if (isListArrayChild && index !== -1) {
          let arr = $.get(body, path.slice(0, index));
          if (!$.isArray(arr)) {
            return;
          }

          const childPath = path.slice(index + 1);
          arr = arr
            .map(it => $.get(it, childPath))
            .filter(it => it !== undefined && it !== null);
          return arr.length ? arr : undefined;

        } else {
          return $.get(body, path);
        }
      }

      return body;

    } else if(contentType.startsWith("multipart/form-data")) {
      if (path !== "body") {
        path = path.split('.').slice(1);
        const firstNode = path.shift();
        const value = body.get(firstNode);
        return $.get(value, path);
      }

      const responseValue = {};
      for(let [name, value] of body) {
        $.get(responseValue, [name], value);
      }
      return responseValue;
    }
  }

  setResponseMapping(status, body, context) {
    const mappingByStatus = $.get(this.responseMapping, status + "");
    if (!$.isArray(mappingByStatus)) return;

    const contentType = this.getContentType("response");

    mappingByStatus.forEach(map => {
      const destPath = map.dest.value.value.split('.').slice(1);

      let isListArrayChild = false;
      if (destPath.lastIndexOf("[]") !== -1) {
        destPath.pop();
        isListArrayChild = true;
      }

      const store = this.getStore(map.dest.value.variable);
      let value = this.getResponseValue(body, map.source.value, /*contentType*/ undefined, isListArrayChild);
      const newDestPath = destPath.map(segment => segment.replace(/\[|\]/g, ""));
      this.createArrays(destPath);
      const setMerge = map.dest.value.setMerge;
      if(setMerge) {
        this.setMerge(store, newDestPath, value);

      } else {
        newDestPath.length === 0
          ? store.setState(value)
          : store.set(newDestPath, value, this);
      }
    });
  }

  @action
  setMerge(store, newDestPath, value, postfixPath = []) {
    const pathToObj = [...newDestPath, ...postfixPath];
    const obj = store.get(pathToObj);

    if($.isArray(obj) && $.isArray(value)) {
      const firstValue = $.get(obj, "0");
      const type = this.getTypeObj(firstValue);

      value.forEach((item, index) => {
        if(type === "primitive") {
          store.push(pathToObj, item);

        // } else if(type === "array") {
        //   this.setMerge(store, newDestPath, item, [...postfixPath, index]);

        } else if(type === "object") {
          const indexReal = obj.findIndex(objItem => $.get(objItem, "id") === $.get(item, "id"));
          if(~indexReal) {
            this.setMerge(store, newDestPath, item, [...postfixPath, indexReal])

          } else {
            store.push(pathToObj, item);
          }
        }
      });


    } else if($.isObject(obj) && $.isObject(value)) {
      const keys = $.keys(value);
      keys.forEach(key => {
        const valueObj = $.get(value, key);
        const type = this.getTypeObj(valueObj);

        if(type === "object" || type === "array") {
          this.setMerge(store, newDestPath, valueObj, [...postfixPath, key])
        } else if(type === "primitive") {
          store.set([...pathToObj, key], valueObj);
        }
      });
    }
  }

  getTypeObj(obj) {
    let type = "primitive";

    if(obj == undefined) {
      return;
    } else if($.isObject(obj)) {
      type = "object";
    } else if($.isArray(obj)) {
      type = "array";
    }

    return type
  }

  @action
  setMergeArray(storeArray, newDestPath, valueArray, postfixPath) {


  }
}

export {ApiCallActionStore};
