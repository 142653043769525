import React from "react";
import {observer} from "mobx-react";
import {injectIntl} from "@isf/core-localization";
import classNames from "classnames";
import parse from 'html-react-parser';
// import DOMPurify from 'dompurify';
// import Handlebars from "handlebars";

@injectIntl
@observer
class HtmlContent extends React.Component{
    constructor(props){
        super(props);
        if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
        if (props.uiStoreType) this.uiStore = new props.uiStoreType(props);
    }

    componentWillUnmount() {
        this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
        this.uiBaseStore && this.uiBaseStore.dispose();
    }

    // getRenderedTemplate = (template, data) => {
    //     const templateFn = Handlebars.compile(template);
    //     return templateFn(data);
    // };

    render(){
        const { html, localized, intl, className/*, templating, data*/ } = this.props;
        if (this.uiStore && this.uiStore.hiddenStore.get("isHidden")) return null;
        let htmlContent;
        const computedValue = this.uiStore && this.uiStore.computedStore.get('value');
        if (computedValue) htmlContent = computedValue;
        else if (localized && html.id) htmlContent = intl.messages[html.id] && intl.formatMessage(html);
        else htmlContent = html;
        const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
          && this.uiBaseStore.classNameStore.getClassNames();
        // if (templating) {
        //     let dataJSON = data;
        //     if (typeof(data) === "string") dataJSON = JSON.parse(data);
        //     return ReactHtmlParser(this.getRenderedTemplate(htmlContent, dataJSON));
        // }

        return (
          <div className={classNames(className, computedClassNames)}>
              {/*{parse(DOMPurify.sanitize(htmlContent))}*/}
              {parse(htmlContent || '')}
          </div>
        )
    }
}

export default HtmlContent;

// templateTest = `
// <h4>Hej {{firstName}}</h4>
//     <p>How are you?</p>
//     <ul>
//         {{#each payments}}
//         <li>{{name}} - {{amount}}</li>
//         {{/each}}
//     </ul>`;
// dataTest = `{
//        "firstName": "Theodor",
//              "payments": [
//                 { "name": "Payment 1", "amount": 200 },
//                { "name": "Payment 2", "amount": 400 }
//               ]
//         }`;