import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {CardSubtitle as _CardSubtitle} from 'reactstrap';

@observer
class CardSubtitle extends Component {
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {children, className} = this.props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return(
      <_CardSubtitle className={classNames(className, computedClassNames)}>
        {children}
      </_CardSubtitle>
    );
  }
}

CardSubtitle.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any
};

export {CardSubtitle};