import React from "react"
import {observer} from "mobx-react"
import {getShapeParams} from "./shape-params";
import styled from "styled-components";
import {ICON_MULTI} from "@isf/common-resources";

const StyledMultiinstanceIcon = styled.div`
  position:absolute;
        top:11px;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%)
`;

@observer
class Shape extends React.Component {

  render() {
    const params = getShapeParams({isActive:false, ...this.props});
    return (
      <div style={params.styles}>
        {this.props.children}
        {params.icon && <img src={params.icon} alt={"icon"} className="icon-w-18 h-auto position-relative mt-1 ml-1"/>}
        {this.props.properties.multiinstance_collection &&
        <StyledMultiinstanceIcon>
          <img src={ICON_MULTI} alt={"multi"} style={{transform: this.props.properties.multiinstance_type==="Parallel"?"rotate(90deg)":"",  width: 10}}/>
        </StyledMultiinstanceIcon>
        }
        <div style={{textAlign: "center"}}>
          {params.content}
        </div>
      </div>
    );
  }
}

export default Shape;

export const shapeDefaultConfig = () => {
  return {
    uiSchema: {
      tag: 'Shape',
      props: {
        text: "Shape",
        upperLeft: {
          x: 0,
          y: 0
        },
        lowerRight: {
          x: 0,
          y: 0
        }
      }
    }
  };
};