import { routerStore } from "@isf/core-app-store";
import { restApi } from "../api";

const UUID_PATTERN = /^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$/;

/**
 * Build new query params
 * @param {string} scope - activityId in bp
 * @param {string} taskId - taskId in bp
 * @param {string} processInstanceId - processInstanceId in bp
 * @returns {string}
 */
const buildQueryParams = ({ scope, taskId, processInstanceId }) => {
  const search = routerStore.search;
  const searchParams = new URLSearchParams(search.slice(1));
  searchParams.delete('scope');
  searchParams.delete('taskId');
  searchParams.delete('processInstanceId');

  if (scope && taskId && processInstanceId) {
    searchParams.set('scope', scope);
    searchParams.set('taskId', taskId);
    searchParams.set('processInstanceId', processInstanceId);
  }

  return searchParams.toString();
}

/**
 * Concatenate path and query params
 * @param {string} path
 * @param {string} query
 * @returns {string}
 */
const buildPath = (path, query) => {
  const queryParams = buildQueryParams(query);
  return queryParams
    ? `${path}?${queryParams}`
    : path;
}

/**
 * Load bp info by processInstanceId
 * @param {string} processInstanceId
 * @returns {Promise<*>}
 */
const loadBPInfo = async (processInstanceId) => {
  return await restApi.request({
    path: `/bp-mgt/search/x-acc`,
    options: {
      method: "post",
      body: JSON.stringify({
        filter: {
          processInstanceId: [
            {
              value: processInstanceId,
              operation: "equals"
            }
          ]
        }
      })
    }
  });
}

/**
 * Check bp info in query params. Get info about bp and make redirect if necessary
 * 1. If bp params isn't exist or invalid - make nothing
 * 2. If bp params exist and valid:
 * 2.1 Bp process is completed - redirect to page without bp params
 * 2.2 Bp process is active and userTasks is empty - make nothing
 * 2.3 Bp process is active and userTasks length 1.
 * The user task is the same as in the parameters - make nothing
 * 2.4 Bp process is active and userTasks length 1.
 * The user task is different as in the parameters - redirect to page with user task params
 * 2.5 Bp process is active and userTasks length more than 1.
 * All user task is different. - make nothing
 * 2.6 Bp process is active and userTasks length more than 1.
 * One of all user task is the same as in the parameters - make nothing
 * @param {string} pathname
 * @returns {Promise<boolean>}
 */
const bpUrlReaction = async (pathname) => {
  const processInstanceId = routerStore.get("query.processInstanceId"),
    scope = routerStore.get("query.scope"),
    queryTaskId = routerStore.get("query.taskId");

  if (!UUID_PATTERN.test(processInstanceId) || !UUID_PATTERN.test(queryTaskId) || !scope) {
    return false;
  }

  const result = await loadBPInfo(processInstanceId);
  const bpInfo = result.length
    ? result[0]
    : {};
  const { isProcessEnded, userTasks } = bpInfo;

  if (isProcessEnded) {
    document.location = buildPath(pathname, {});
    return true
  }

  if (!isProcessEnded && Array.isArray(userTasks) && userTasks.length === 1) {
    const { activityId, taskId } = userTasks[0];
    if (scope !== activityId || queryTaskId !== taskId ) {
      // debugger;
      const userTaskQuery = { scope: activityId, taskId, processInstanceId }
      document.location = buildPath(pathname, userTaskQuery);
      return true;
    }
  }

  return false;
}

export default bpUrlReaction;
