const SERVICES_COLUMNS = [
  {
    "type": "default",
    "header": {
      "message": {
        "id": "ui.filter.name"
      }
    },
    "sortable": false,
  },
  {
    "type": "default",
    "header": {
      "message": {
        "id": "ui.filter.contextPath"
      }
    },
    "sortable": false,
  },
  {
    "type": "default",
    "header": {
      "message": {
        "id": "ui.filter.description"
      }
    },
    "sortable": false,
  },
  {
    "type": "default",
    "header": {
      "message": {
        "id": "ui.filter.version"
      }
    },
    "sortable": false,
  }
];

const STATIC_TEXT_CONF = {
  "name": {
    "id": "services.table.name",
    "computed": {
      "params": [
        {
          "name": "name",
          "path": "name"
        }
      ],
      "expression": "name"
    }
  },
  "contextPath": {
    "id": "services.table.contextPath",
    "computed": {
      "params": [
        {
          "name": "contextPath",
          "path": "contextPath"
        }
      ],
      "expression": "contextPath"
    }
  },
  "description": {
    "id": "services.table.description",
    "computed": {
      "params": [
        {
          "name": "description",
          "path": "description"
        }
      ],
      "expression": "description"
    }
  },
  "version": {
    "id": "services.table.version",
    "computed": {
      "params": [
        {
          "name": "version",
          "path": "version"
        }
      ],
      "expression": "version"
    }
  },
};

const defaultRequestFilters = () => ({
  "name": [{
    "value": "",
    "operation": "like"
  }],
  "version": [{
    "value": "",
    "operation": "like"
  }],
  "description": [{
    "value": "",
    "operation": "like"
  }],
  "contextPath": [{
    "value": "",
    "operation": "like"
  }],

});

const defaultRequestState = () => ({
  "body": {
    "filter": defaultRequestFilters(),
    "paging": {
      "page": 1,
      "limit": 5,
      "enumerable": true
    }
  }
});

const serviceActionConfig = ({
  "api": "conf",
  "type": "apiCall",
  "request": {
    "path": "/configuration/v1/search",
    "store": "component.services.table.request",
    "mapping": [],
    "options": {
      "method": "post"
    }
  },
  "response": {
    "200": [
      {
        "dest": {
          "type": "path",
          "value": {
            "type": "variable",
            "value": "body",
            "variable": "component.services.table.response"
          }
        },
        "source": {
          "type": "path",
          "value": "body.data",
          "parameterType": "content"
        }
      },
      {
        "dest": {
          "type": "path",
          "value": {
            "type": "primitive",
            "value": "body.body.paging.page",
            "variable": "component.services.table.request"
          }
        },
        "source": {
          "type": "path",
          "value": "body.paging.page",
          "parameterType": "content"
        }
      },
      {
        "dest": {
          "type": "path",
          "value": {
            "type": "primitive",
            "value": "body.body.paging.total",
            "variable": "component.services.table.request"
          }
        },
        "source": {
          "type": "path",
          "value": "body.paging.total",
          "parameterType": "content"
        }
      }
    ]
  }
});

const inputWithoutLabel = {
  input: {
    layout: {
      col: {}
    },
    props: {
      bsSize: "sm"
    }
  },
  label: {
    props: {className: 'd-none'}
  },
};

const inputSwitch = {
  label: {
    props: {
      sm: 7,
      md: 7,
      lg: 9,
      xl: 9,
      size: 'sm'
    }
  },
  input: {
    layout: {
      col: {
        sm: 5,
        md: 5,
        lg: 3,
        xl: 3
      }
    },
    props: {
      bsSize: "sm"
    }
  }
};

const layout = {
  button: {
    cancel: {
      size: 'sm',
    },
    ok: {
      size: 'sm',
      color: 'primary'
    }
  },
  input: {
    label: {
      props: {
        sm: 12,
        md: 6,
        lg: 3,
        size: 'sm'
      }
    },
    input: {
      layout: {
        col: {
          sm: 12,
          md: 6,
          lg: 9
        }
      },
      props: {
        bsSize: "sm"
      }
    }
  }
};

export {
  SERVICES_COLUMNS, STATIC_TEXT_CONF, defaultRequestState, serviceActionConfig,
  defaultRequestFilters, inputWithoutLabel, inputSwitch, layout
};