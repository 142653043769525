import React from 'react';
import PropTypes from 'prop-types';
import { observer } from "mobx-react";
import Editor from "./table-cell-editor";
import CheckBox from "../table-checkbox";
import { DefaultTableActions } from "./table-default-actions"

import $ from "@isf/core-object-util";

const handleOnDoubleClick = props => {
  const { rowId, colId, store, column } = props;
  if (column.editable) {
    store.startEditCell({ rowId, colId });
  }
};

const TableCell = observer((props) => {
  const { item, column, store, rowId, colId, rowIndex, selection, extConfiguration, intl } = props;
  const isBeingEdited = store.isBeingEdited({ rowId, colId });
  const ariaLabel = typeof column.header === 'object' && column.header.message
      ? intl.messages[column.header.message.id] === undefined
        ? intl.formatMessage({id: column.header.message.id, defaultMessage: ''})
        : intl.messages[column.header.message.id]
      : column.header;

  if(extConfiguration && $.get(extConfiguration,column.accessor)){
    const result = $.get(item,column.accessor);
    if($.get(extConfiguration,[column.accessor,result+""]))
      return <td>{React.cloneElement($.get(extConfiguration,[column.accessor,result+""]),{data:item, rowIndex:rowIndex})}</td>
    else
      return <td></td>
  }
  if (isBeingEdited) {
    return (
      <td>
        <Editor accessor={[rowIndex, column.accessor]} rowId={rowId}
          colId={colId} store={store} value={$.get(item, [column.accessor])} />
      </td>
    );
  }
  if (column.type === "checkbox") {
    return (
      <td style={{width:"60px"}}>
        <CheckBox selection={selection} rowId={rowId} store={store.selectionStore} rowIndex={rowIndex}/>
      </td>
    );
  } else if(column.type === "actions") {
    return (
      <DefaultTableActions />
    );
  }
  if (column.tagCell) {
    const Cell = column.tagCell;
    return (
      <td aria-label={ariaLabel}>
        <Cell item={item} accessor={column.accessor} rowId={rowId}/>
      </td>
    );
  }
  //TODO: remove ''+ - temporary fix
  let value = $.get(item, column.accessor);
  if (value === null || value === undefined) {
    value = '';
  }
  return (
    <td scope="col" onDoubleClick={() => handleOnDoubleClick(props)} aria-label={ariaLabel}>
      {"" + value}
    </td>
  )
});

TableCell.propTypes = {
  id: PropTypes.string,
  item: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  rowId: PropTypes.any.isRequired,
  colId: PropTypes.number.isRequired
};

export default TableCell;
