import {computed, action, observable} from "mobx";
import $ from "@isf/core-object-util";
import {DataStore} from "@isf/core-app-store";
import {TableStore} from "@isf/table";
import {TableViewStore} from "@isf/table-view";
import {TreeStore} from "@isf/tree";
import {
  transformPath, getRequestParameters, ServiceTableViewStore, defaultRequestState, ServicesTableStore,
  ServicesTableRequestStore, ServicesStore, MappingStore, getRequestContentType, defaultTreeStoreState, getMappingObj
} from "@isf/common-select-service";
import {resolveStore} from "@isf/react-util";

class ServiceOperationConfigStore {

  constructor(props) {
    this.init(props);
  }

  init(props) {
    const {setConf, selectedService} = props;
    this.path = ["serviceOperationConfigStore"];
    this.setConf = setConf;

    this._dataStore = new DataStore({
      path: [...this.path, "dataStore"].join("."),
      state: {
        selectedService: selectedService
      },
    });
    this._mappingStore = new MappingStore({
      path: [...this.path, "mappingStore"].join('.'),
      state: {
        request: getMappingObj(this.dataStore.get('selectedService.requestSchema'))
      },
      configStore: this,
      observableAsMap: true
    });
    this._serviceTableStore = new TableStore({
      path: [...this.path, "servicesTableStore"].join("."),
      state: {}
    });
    this._pathTableStore = new ServiceTableViewStore(
      new TableViewStore(),
      []
    );
    this._documentRequestTreeStore = new TreeStore({
      path: [...this.path, "documentRequestTreeStore"],
      state: defaultTreeStoreState()
    });
    this._parameterTreeStore = new TreeStore({
      path: [...this.path, "parameterTreeStore"],
      state: {
        ...defaultTreeStoreState(),
        defaultExpandDeep: [
          {path: ["body", "filter"], value: 3},
          {path: ["body"], value: 2},
          {path: ["path"], value: 2},
          {path: ["query"], value: 2},
        ],
      },
    });
    this._servicesStore = new ServicesStore({
      path: [...$.strToArray(this.path), "services"],
      state: observable.array([], { deep: false }),
    });

    this._servicesResponseStore = resolveStore({
      type: ServicesTableStore,
      store: "component.services.table.response"
    });
    this._servicesRequestStore = resolveStore({
      state: defaultRequestState(),
      type: ServicesTableRequestStore,
      store: "component.services.table.request"
    });
    this._servicesRequestStore.makeObservable({asMap: true});
  }

  get dataStore() {
    return this._dataStore;
  }

  get mappingStore() {
    return this._mappingStore;
  }

  get serviceTableStore() {
    return this._serviceTableStore;
  }

  get pathTableStore() {
    return this._pathTableStore;
  }

  get documentRequestTreeStore() {
    return this._documentRequestTreeStore;
  }

  get parameterTreeStore() {
    return this._parameterTreeStore;
  }

  get servicesStore() {
    return this._servicesStore;
  }

  get servicesRequestStore() {
    return this._servicesRequestStore;
  }

  get servicesResponseStore() {
    return this._servicesResponseStore;
  }

  get servicesSelectedData() {
    const selectedRows = this.serviceTableStore.selectionStore.selected;
    return selectedRows.length > 0 ? this.servicesResponseStore.get(`${selectedRows[0]}`) : undefined;
  }

  getServiceInfo(id) {
    if (!id) return;
    return this.servicesStore.state.find(item => $.get(item, "id") === id);
  }

  getServiceId() {
    if (this.servicesSelectedData) return $.get(this.servicesSelectedData, "id");
  }

  async loadNewService(...listeners) {
    const serviceId = this.getServiceId();
    const serviceInfo = this.getServiceInfo(serviceId);
    if (serviceInfo) {
      listeners.forEach(listener => listener(serviceInfo));
      return;
    }

    return await this.servicesStore.loadServiceById(serviceId, listeners);
  }

  @action
  setSelectedService(service) {
    this.dataStore.set("select.service", {
      id: $.get(service, "id"),
      name: $.get(service, "name"),
      context: $.get(service, "context"),
      contextPath: $.get(service, "contextPath"),
      data: service
    });
  }

  setPathTableData = (data) => {
    if (this.dataStore.get("select.service.id", true) !== $.get(data, "id")) {
      this.pathTableStore.clearChecked();
      this.clearSelectedParameter();

      this.setSelectedService(data);
      this.pathTableStore.data = transformPath($.get(data, "api.schema.paths"));
    }
  };


  @action
  selectService = async (...listeners) => {
    await this.loadNewService(this.setPathTableData, ...listeners);
  };

  @action
  async loadRequestSchemaWithoutRef(path, method) {
    const apiSchema = this.dataStore.get("select.service.data.api.schema");

    const requestData = await getRequestParameters(path, method, apiSchema, this.servicesStore);
    this.dataStore.set("processVariables.requestData", requestData);

    const requestContentType = await getRequestContentType(path, method, apiSchema, this.servicesStore);
    this.dataStore.set(["select", "service", "contentType"], {
      request: requestContentType
    });
  }

  @action
  async setSelectedPathMethod(path, method, listeners) {
    await this.loadRequestSchemaWithoutRef(path, method);

    this.dataStore.set('select.service.path', path);
    this.dataStore.set('select.service.method', method);
    this.mappingStore.set('request', getMappingObj(this.dataStore.get('processVariables.requestData')));

    Array.isArray(listeners) && listeners.forEach(listener => listener());
  }

  @action
  selectPathMethod = async (...listeners) => {
    const data = this.pathTableStore.selectedData;
    const path = $.get(data, "path"),
      method = $.get(data, "method");

    const oldPath = this.dataStore.get("select.service.path");
    const oldMethod = this.dataStore.get("select.service.method");

    if (path !== oldPath || method !== oldMethod) {
      this.clearSelectedParameter();
      await this.setSelectedPathMethod(path, method, listeners);
    } else {
      Array.isArray(listeners) && listeners.forEach(listener => listener());
    }
  };

  @action
  selectParameters = (...listeners) => {
    const select = this.dataStore.get('select');
    const responseInfo = this.dataStore.get('responsesData.info') || {};
    const context = this.dataStore.get('selectedService.context');
    const mapping = this.mappingStore.getMappingObj(select.service, context, responseInfo);

    this.setConf && this.setConf(mapping);
    Array.isArray(listeners) && listeners.forEach(listener => listener());
  };

  @action
  clearSelectedParameter() {
    this.mappingStore.set("requestBody", {});
    this.documentRequestTreeStore.set('selected', []);
    this.parameterTreeStore.set('selected', []);
  }

  @action
  clearServicesStores() {
    this.servicesRequestStore.setState(defaultRequestState());
    this.serviceTableStore.selectionStore.clearStore();
  }

  @action
  clearStore() {
    this.pathTableStore.clearChecked();
    this.pathTableStore.clearFilter();
    this.clearSelectedParameter();
    this.dataStore.remove("select");
  }

  @action
  async setCurrentData(confExt, requestStoreName = "parameterTreeModel") {
    const id = $.get(confExt, 'id'),
      contextPath = $.get(confExt, 'contextPath'),
      method = $.get(confExt, 'method'),
      body = $.get(confExt, 'body');

    if (id === undefined || contextPath === undefined || method === undefined)
      return false;

    let service = this.servicesStore.state.find(item => $.get(item, 'id') === id);
    if (!service) {
      service = await this.servicesStore.loadServiceById(id);
    }

    if (!service) return false;
    this.setSelectedService(service);
    this.pathTableStore.data = transformPath($.get(service, 'api.schema.paths'));

    if (this.pathTableStore.data.length < 1 || !this.pathTableStore.data.find(el => el.id === `${contextPath}.${method}`))
      return false;

    this.pathTableStore.setIdTable(`${contextPath}.${method}`);
    await this.setSelectedPathMethod(contextPath, method);
    this.mappingStore.getRequestMappingSchema(JSON.parse(body), requestStoreName);

    return true;
  }

  // @computed
  get parameterTreeModel() {
    return this._dataStore.get('processVariables.requestData');
  }

  @computed
  get requestMappingTreeModel() {
    return this.mappingStore.get('request');
  }

  selectedServiceSchema(dataName) {
    return this._dataStore.get(`selectedService.${dataName}`);
  }

  getTreeSelectedData = (store) => {
    const selected = store.selected();
    if ($.isArray(selected) && selected.length) {
      return $.get(selected, "0.path");
    }
  };

  @computed
  get pathDisabled() {
    const selectedRows = this.pathTableStore.tableStore.selectionStore.selected;
    return selectedRows.length !== 1;
  }

  @computed
  get serviceDisabled() {
    const selectedRows = this.serviceTableStore.selectionStore.selected;
    return selectedRows.length !== 1;
  }
}

export { ServiceOperationConfigStore };