import $ from "@isf/core-object-util";

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function validateFileType(file, input) {
  let accept = input && input.props && input.props.accept;
  accept = typeof accept === "string" ? $.strToArray(accept, ",") : [];
  if (accept.length > 0 && file) {
    const type = file.type;

    let flag = false,
      regex;

    accept.forEach(it => {
      if (it === "*/*") {
        flag = true;

      } else {
        regex = escapeRegExp(it);
        regex = regex.endsWith("/\\*") ? regex.replace("/\\*", "/.*") : regex;
        flag = (new RegExp("^" + regex + "$")).test(type) ? true : flag;
      }
    });

    return flag;
  }

  return true;
}

const FILE_SIZE_UNIT = {
  "MB": 2,
  "KB": 1,
  "Bytes": 0
};

function validateFileSize(file, input) {
  const fileProps = input && input.props && input.props.file || {};
  const sizeUnit = fileProps.sizeUnit;
  let size = fileProps.size;

  if (typeof size === "number" && file && typeof FILE_SIZE_UNIT[sizeUnit] === "number") {
    size = size * Math.pow(1024, FILE_SIZE_UNIT[sizeUnit]);
    return file.size <= size;
  }

  return true;
}

const VALIDATE_FUNC = [
  {
    func: validateFileType,
    messageId: "ui.alerts.file.invalid.extension"
  }, {
    func: validateFileSize,
    messageId: "ui.alerts.file.invalid.size"
  }
];

export {VALIDATE_FUNC};