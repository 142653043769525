import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {CardBody as _CardBody, Collapse} from '@isf/bootstrap';
import {LoadSpinner} from '../load-spinner/load-spinner-view';
import {CardContext} from './card';

@observer
class CardBody extends React.Component {
   constructor(props) {
      super(props);
      if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
      if (props.uiStoreType) this.uiStore = new props.uiStoreType(props);
   }

   static contextType = CardContext;

   componentWillUnmount() {
      this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
      this.uiBaseStore && this.uiBaseStore.dispose();
   }

   render() {
      const {store, cardCollapsable} = this.context;
      const {children, className} = this.props;
      const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
        && this.uiBaseStore.classNameStore.getClassNames();

      if (cardCollapsable) {
         return (
            <div className="position-relative">
               <Collapse isOpen={!store.isCardCollapsed}>
                  {this.uiStore && <LoadSpinner isLoading={this.uiStore.isLoading}/>}
                  <_CardBody className={classNames(className, computedClassNames)}>
                     {children}
                  </_CardBody>
               </Collapse>
            </div>
         );
      } else return (
         <div className="position-relative">
            <_CardBody className={classNames(className, computedClassNames)}>
               {this.uiStore && <LoadSpinner isLoading={this.uiStore.isLoading}/>}
               {children}
            </_CardBody>
         </div>
      );
   }
}

CardBody.propTypes = {
   className: PropTypes.string,
   children: PropTypes.any
};

export {CardBody};