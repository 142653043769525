export const getLinkConfig = (id,number, points, docker) => (
  {
    tag: "Link",
    props: {
      bounds: {
        lowerRight: {
          x: 0,
          y: 0
        },
        upperLeft: {
          x: 0,
          y: 0
        }
      },
      resourceId: "flow" + number,
      stencil: {
        id: "SequenceFlow"
      },
      dockers: [
        {
          x: docker.x,
          y: docker.y
        },
        {
          x: 0,
          y: 0
        }
      ],
      childShapes: [],
      points: points.x + "," + points.y,
      defaultConditionConf:true,
      connected: {
        resourceId: id,
      },
      connectTo: {
        resourceId: undefined
      },
      outgoing: [],
      target: {
        resourceId: undefined
      },
      properties: {
        overrideid: "flow" + number,
        conditionsequenceflow:""
      },
    },
    children: []
  });