import {createAlertMessage} from "@isf/common";
import {localeStore} from "@isf/core-localization";

export function handleModuleImport(module, moduleName, exportName) {
  if (module) {
    if (exportName) {
      if (module.hasOwnProperty(`${exportName}`)) return {default: module[`${exportName}`]};
      else createAlertMessage(
          `${localeStore.localeMessages['ui.dynamicImportHandler.error.startError']} '${moduleName}' ('${exportName}' - ${localeStore.localeMessages['ui.dynamicImportHandler.error.namedExport']})`,
          'error'
        );
        return {default: function() {return null}};
    }
    else if (module.hasOwnProperty('default')) return module;
    else {
      createAlertMessage(
        `${localeStore.localeMessages['ui.dynamicImportHandler.error.startError']} '${moduleName}' (${localeStore.localeMessages['ui.dynamicImportHandler.error.defaultExport']})`,
        'error'
      );
      return {default: function() {return null}};
    }
  } else {
    createAlertMessage(
      `${localeStore.localeMessages['ui.dynamicImportHandler.error.startError']} '${moduleName}' (${localeStore.localeMessages['ui.dynamicImportHandler.error.moduleNotFound']})`,
      'error'
    );
    return {default: function() {return null}};
  }
}

export function handleError(error) {
  let notification;
  if (typeof(error) === 'object' && error.message) notification = error.message;
  else notification = error;
  createAlertMessage(notification, 'error');
}
