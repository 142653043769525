import React from 'react';
import {inject, observer} from 'mobx-react';
import classNames from 'classnames';

@observer
class Div extends React.Component{
    constructor(props) {
        super(props);
        if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
        if (props.uiStoreType) this.uiStore = new props.uiStoreType(props);
    }

    componentWillUnmount() {
        this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
        this.uiBaseStore && this.uiBaseStore.dispose();
    }

    render() {
        const {children, className, uiStoreType, hidden, computedClassName, languages, localeStore, uiBaseStore,
            ...other} = this.props;
        if (this.uiStore && this.uiStore.hiddenStore.get("isHidden")) return null;
        const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
          && this.uiBaseStore.classNameStore.getClassNames();

        return (
            <div className={classNames(className, computedClassNames)} {...other}>
                {children}
            </div>
        )
    }
}

export default Div;