import React, { Component } from "react";
import { observer } from "mobx-react";
import $ from "@isf/core-object-util/src/object-util";
import { ItemGrid } from "../../../item-grid";
import { FilterPropertiesItem } from "./index";

@observer
class FilterProperties extends Component {

  id(childId) {
    return this.props.id + '.' + childId;
  }

  getEachFilterProperty = (filterProperties, properties, path = [] ) => {
    const { handler, accessor, mode } = this.props;
    for (let prop in properties) {
      if (properties[prop].type === "array") {
        const fullPropertyPath = path.slice();
        fullPropertyPath.push(prop);
        if (mode !== "readMode" || mode === "readMode" && $.toJS(handler.get(`${accessor}.filter.${fullPropertyPath.join('.')}`))) {
          filterProperties.push(
            <FilterPropertiesItem key={this.id(fullPropertyPath.join('.'))}
                                  id={this.id(fullPropertyPath.join('.'))}
                                  handler={handler}
                                  accessor={accessor}
                                  propPath={fullPropertyPath}
                                  propDescription={properties[prop].description
                                    || properties[prop].items.properties.value.description
                                    || (properties[prop].items.properties.value.oneOf
                                        && properties[prop].items.properties.value.oneOf[0].description)}
                                  propType={properties[prop].items.properties.value.type
                                    || (properties[prop].items.properties.value.oneOf
                                        && properties[prop].items.properties.value.oneOf[0].type)}
                                  propFormat={properties[prop].items.properties.value.format
                                    || (properties[prop].items.properties.value.oneOf
                                        && properties[prop].items.properties.value.oneOf[0].format)} />
          );
        }
      } else if (properties[prop].type === "object" && properties[prop].additionalProperties && !properties[prop].properties) {
          // return;
          // const fullPropertyPath = path.slice();
          // fullPropertyPath.push(prop);
          // if (mode !== "readMode" || mode === "readMode" && $.toJS(handler.get(`${accessor}.filter.${fullPropertyPath.join('.')}`))) {
          //   filterProperties.push(
          //     <FilterPropertiesItem key={this.id(fullPropertyPath.join('.'))}
          //                           id={this.id(fullPropertyPath.join('.'))}
          //                           handler={handler}
          //                           accessor={accessor}
          //                           propPath={fullPropertyPath}
          //                           propDescription={properties[prop].description
          //                           || properties[prop].additionalProperties.items.properties.value.oneOf[0].description}
          //                           propType={properties[prop].additionalProperties.items.properties.value.oneOf[0].type}
          //                           propFormat={properties[prop].additionalProperties.items.properties.value.oneOf[0].format}
          //                           localizedProp={true}/>
          //   );
          // }
      } else if (properties[prop].type === "object" && !properties[prop].additionalProperties && properties[prop].properties) {
        const newPath = path.slice();
        newPath.push(prop);
        this.getEachFilterProperty(filterProperties, properties[prop].properties, newPath );
      }

    }
  };

  render() {
    const { filterSchema } = this.props;
    let filterProperties = [];
    if (filterSchema) {
      this.getEachFilterProperty(filterProperties,  $.toJS(filterSchema), []);
    }

    return (
      <ItemGrid xs="12" gridClassName="py-2">
        {filterProperties}
      </ItemGrid>
    )
  }
}

export { FilterProperties };