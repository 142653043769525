import React from "react";
import { Helmet as Helmet_, HelmetProvider } from "react-helmet-async";
import { observer } from "mobx-react";
import { DEFAULT_THEME } from "./themes";
import $ from '@isf/core-object-util';

// const Helmet = observer(({ helmetStore }) => {
//   if (helmetStore.value === 'noTheme') return null;
//   let linksTheme;
//   if ((helmetStore.value).includes(';$')) linksTheme = helmetStore.value.split(";$");
//   else {
//     helmetStore.setValue(DEFAULT_THEME);
//     if ((helmetStore.value).includes(';$')) linksTheme = helmetStore.value.split(";$");
//     else linksTheme = [helmetStore.value];
//   }
//   return (
//     <HelmetProvider>
//       <Helmet_>
//         {
//           linksTheme && Array.isArray(linksTheme) && linksTheme.length !== 0
//           && linksTheme.map(link => <link key="link" rel="stylesheet" type="text/css" href={link} />)
//         }
//       </Helmet_>
//     </HelmetProvider>
//   );
// });

const Helmet = observer(({ helmetStore }) => {
  if (helmetStore.value === 'noTheme') return null;

  return (
    <HelmetProvider>
      <Helmet_>
        {
          helmetStore.style && Array.isArray(helmetStore.style)
          && helmetStore.style.sort((a, b) => $.get(a, "load_order") - $.get(b, "load_order"))
            .map(style => <style type="text/css" key={style.id}>{style && style.css}</style>)
        }
      </Helmet_>
    </HelmetProvider>
  );
});

export { Helmet };
