
import { restApi } from "../api";
import { getApplicationHostName } from "../app-info";

const loadMicrofrontends = async () => {
  const microfrontends = await restApi.request({
    path: "/confapp/microfrontend/v1/search",
    options: {
      method: "post",
      body: JSON.stringify({
        filter: {
          "app": {
            "host_name": [{
              value: getApplicationHostName(),
              operation: "equals"
            }]
          }
        }
      })
    }
  });
  return microfrontends;
}

let microfrontendConf = undefined;

const getMicrofrontendConf = async () => {
  if (microfrontendConf) {
    return microfrontendConf;
  } 
  microfrontendConf = {};

  const mfs = await loadMicrofrontends();
  if (!mfs || !mfs.data) {

    return;
  }
  mfs.data.forEach(
    mf => {
      microfrontendConf[mf.page_context_path] = {
        schema: {
          ui: {
            tag: 'div',
            props: {
              id: "page-content"
            }
          },
          func: {
            onLoad: [
              {
                type: 'loadScript',
                src: mf.js_bundle_path,
                microfrontend: mf.page_context_path
              }
            ]
          }
        }
      }
    }
  );
  return microfrontendConf;
}
/*
const microfrontendConf = {
  "/aismv/edms": {
    // callbacks: {

    // },
    schema: {
      ui: {
        tag: 'div',
        props: {
          id: "page-content"
        }
      },
      func: {
        onLoad: [
          {
            type: 'loadScript',
            src: '/aismv/edms/js/edms.js',
            microfrontend: '/aismv/edms'
          }
        ]
      }
    }
  },
  "/aismv/cmail": {
    // callbacks: {

    // },
    schema: {
      ui: {
        tag: 'div',
        props: {
          id: "page-content"
        }
      },
      func: {
        onLoad: [
          {
            type: 'loadScript',
            src: '/aismv/cmail/js/cmail.js',
            microfrontend: '/aismv/cmail'
          }
        ]
      }
    }
  },
  
  "/aismv/dfs": {
    // callbacks: {

    // },
    schema: {
      ui: {
        tag: 'div',
        props: {
          id: "page-content"
        }
      },
      func: {
        onLoad: [
          {
            type: 'loadScript',
            src: '/aismv/dfs/js/dfs.js',
            microfrontend: '/aismv/dfs'
          }
        ]
      }
    }
  },

  "/aismv/router-admin": {
    // callbacks: {

    // },
    schema: {
      ui: {
        tag: 'div',
        props: {
          id: "page-content"
        }
      },
      func: {
        onLoad: [
          {
            type: 'loadScript',
            src: '/aismv/router-admin/js/routerAdmin.js',
            microfrontend: '/aismv/router-admin'
          }
        ]
      }
    }
  },

  "/aismv/mailpers-admin": {
    // callbacks: {

    // },
    schema: {
      ui: {
        tag: 'div',
        props: {
          id: "page-content"
        }
      },
      func: {
        onLoad: [
          {
            type: 'loadScript',
            src: '/aismv/mailpers-admin/js/mailpersAdmin.js',
            microfrontend: '/aismv/mailpers-admin'
          }
        ]
      }
    }
  },

  "/aismv/mailsec-admin": {
    // callbacks: {

    // },
    schema: {
      ui: {
        tag: 'div',
        props: {
          id: "page-content"
        }
      },
      func: {
        onLoad: [
          {
            type: 'loadScript',
            src: '/aismv/mailsec-admin/js/mailsecAdmin.js',
            microfrontend: '/aismv/mailsec-admin'
          }
        ]
      }
    }
  },
  "/aismv/mailsec": {
    // callbacks: {

    // },
    schema: {
      ui: {
        tag: 'div',
        props: {
          id: "page-content"
        }
      },
      func: {
        onLoad: [
          {
            type: 'loadScript',
            src: '/aismv/mailsec/js/mailsec.js',
            microfrontend: '/aismv/mailsec'
          }
        ]
      }
    }
  }
  
}
*/

window.registerMicrofrontend = (mfCode, render) => {
  console.log('register microfrontend: ', microfrontendConf, mfCode);
  if (mfCode === 'aismv') {
    mfCode = "/aismv/edms"
  }
  microfrontendConf[mfCode].callbacks = { render }
}

// const getMicrofrontendSchema = async (location) => {
//   const microfrontend = Object.entries(microfrontendConf).find(entry => {
//     const [path, schema] = entry;
//     return location.startsWith(path)
//   })
//   return microfrontend ? microfrontend[1].schema : undefined;
// }

const getMicrofrontend = async (location) => {
  if (!location) {
    return;
  }
  const microfrontendConf = await getMicrofrontendConf();
  const microfrontend = Object.entries(microfrontendConf).find(entry => {
    const [path] = entry;
    return location.startsWith(path)
  })
  return microfrontend ? microfrontend[1] : undefined;
}

export { getMicrofrontend };


