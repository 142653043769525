import { UIStore } from "@isf/core-app-store";
import { observable, computed, action } from "mobx";
import { SelectionStore } from "./old-table-view/selection-store";
import $ from "@isf/core-object-util";

class TableStore extends UIStore {
  @observable _data = [];
  @observable _sortBy;
  @observable _order = 1;

  constructor({ id = "noId", path = 'tableStore', data = [] } = {}, ) {
    super({ path })
    this._selectionStore = new SelectionStore({
      // path: `table.selectionStore.${id}`,
      path:path +".selectionStore",
      state: {
        tableRef:this
      }
    });
    this.data = data;
  }

  get selectionStore() {
    return this._selectionStore;
  }

  set data(data) {
    this._data = data;
  }

  set sortConfig(accessor) {
    if (this._sortBy === accessor) {
      this._order = (-this._order);
    }
    this._sortBy = accessor;
  }

  @computed get sortBy() {
    return this._sortBy;
  }

  @computed get order() {
    return this._order;
  }

  @computed get data() {
    return this._data;
  }

  startEditCell({ rowId, colId }) {
    this.set(['edit', rowId, colId], true);
  }

  endEditCell({ rowId, colId }) {
    this.set(['edit', rowId, colId], false);
    this.clearInitialValue({ rowId, colId });
  }

  isBeingEdited({ rowId, colId }) {
    return this.get(['edit', rowId, colId]);
  }

  setInitialValue({ rowId, colId }, value) {
    this.set(['initial', rowId, colId], value);
  }

  getInitialValue({ rowId, colId }) {
    return this.get(['initial', rowId, colId]);
  }

  clearInitialValue({ rowId, colId }) {
    return this.clear(['initial', rowId, colId]);
  }

  @action
  setCellValue(accessor, value) {
    $.set(this.data, accessor, value);
  }

  setPageRows(data) {
    const {selectionStore} = this;
    const length = $.isArray(data) ? data.length : 0;

    const pageRows = [...Array(length).keys()];
    selectionStore.setPageRows(pageRows);
  }

  getCellValue(accessor) {
    return this.data.filter((row, index) => index === accessor[0])[0][accessor[1]];
  }
}

Object.defineProperty(TableStore, "name", {value: "TableStore"});

export { TableStore };
