import Store from "./store";
import { action } from "mobx";
import routerStore from "./router-store";
import cacheStore from "./cache-store";

class UIStore extends Store {

  constructor(args) {
    const {state, ...other} = args;
    const cachedState = cacheStore.getCachedState('/'+(routerStore.get('path').join('/')),args.path,'ui');
    super({...other, state:cachedState || state, observableAsMap: true, scope: 'ui'});
    // this.makeObservable({asMap: true});
  }

  @action
  toggle(path) {
    this.set(path, !this.get(path));
  }

}

export default UIStore;