import appStore,{UIStore} from "@isf/core-app-store";
import {uuidv4} from "@isf/core-system-util";
import {computed} from "mobx";

class UILoadStore extends UIStore {

   constructor(props) {
      let id = 'UILoadStore.'+uuidv4();
      if(props.id){
         const parentArrPath = props.id.split('.');
         parentArrPath.splice(1,0,'load');
         id = parentArrPath;
      }
      super({path:id});
      this.executeActionsStore = appStore.getStore('globalExecuteActionsStore','ui');
      this.loadDataAction = props.loadDataAction;
   }


   @computed
   get isLoading(){
      if(this.loadDataAction && this.executeActionsStore) {
         const executeActions = this.executeActionsStore.get('executeActions');
         if(typeof this.loadDataAction === 'string') {
            return executeActions.get(this.loadDataAction);
         }else{
            for(let i=0;i<this.loadDataAction.length;i++){
               const isExecute = executeActions.get(this.loadDataAction[i]);
               if(isExecute){
                  return isExecute;
               }
            }
            return false;
         }
      }
      return false;
   }
}

export {UILoadStore}