import $ from '@isf/core-object-util'
import {restApi, engine} from "@isf/ui-schema";
import {DropdownStore} from "@isf/dropdown";
import {action, computed, observable} from "mobx";
import {localeStore} from "@isf/core-localization";
import { forceClearLocalStorage } from "@isf/ui-schema/src/ui-engine";

const DEFAULT_THEME_ID = "002a8fbc-d490-4b32-9a73-4204f32f9b44";

forceClearLocalStorage()

class ThemeStore extends DropdownStore  {

  _curState = observable.object({
    styleId: observable.box(
      /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/.test(localStorage.getItem("bootstrapTheme"))
        ? localStorage.getItem("bootstrapTheme")
        : this.defaultThemeId
    ),
    style: null
  }, {}, {deep: false});

  @action
  _setValue(value) {
    localStorage.setItem("bootstrapTheme", value);
    this._curState.styleId.set(localStorage.getItem("bootstrapTheme"));
  }

  async setValue(value) {
    await this.loadStyle(value);
    this._setValue(value);
  }

  @computed
  get value() {
    return this._curState.styleId.get();
  }

  @computed
  get style() {
    return this._curState.style
  }

  get defaultThemeId() {
    return $.get(engine.pageTemplates, "default_theme.id") || $.get(engine.pageTemplates, "themes.0.id");
  }

  get themes() {
    return $.get(engine.pageTemplates, "themes") || [];
  }

  get themesOption() {
    if (!Array.isArray(this.themes)) return null;
    return this.themes.map(theme => ({
      value: $.get(theme, "id"),
      name: $.get(theme, "name." + localeStore.locale) || $.get(theme, "name.ru")
    }));
  }

  getStyle(id) {
    const {themes} = this;
    const theme = themes.find(theme => $.get(theme, "id") === id);
    return $.get(theme, "style");
  }

  setStyle(id, style) {
    const {themes} = this;
    const theme = themes.find(theme => $.get(theme, "id") === id);
    theme && $.set(theme, "style", style);
    this._curState.style = style;
  }

  async initialize() {
    const {value, defaultThemeId} = this;
    let id = value;

    if (!id) {
      id = defaultThemeId || DEFAULT_THEME_ID;
      this._setValue(id);

    }

    await this.loadStyle(id);
  }

  async loadStyle(id, level = -1) {
    if (!this.getStyle(id)) {
      let style = await restApi.request({path: '/confapp/theme/v1/' + id + "/style"});

      if (Array.isArray(style) && style.length !== 0) {
        style = style.sort((itA, itB) => $.get(itA, "load_order") - $.get(itB, "load_order"));
        this.setStyle(id, style)

      } else {
        await this.loadDefaultStyle(id, ++level);

      }

    } else {
      this._curState.style = this.getStyle(id);
    }
  }

  async loadDefaultStyle(id, level = 0) {
    if(level > 0) return;
    await this.loadStyle(DEFAULT_THEME_ID, level);
    this.setStyle(id, this.getStyle(DEFAULT_THEME_ID));
  }
}

const themeStore = new ThemeStore({
  path: "HelmetStore"
});

export {themeStore};