import React, {Fragment} from "react"
import styled from "styled-components"
import {observer} from "mobx-react"

const StyledSVG = styled.svg.attrs((props)=>({
    height:props.svgHeight,
    width:props.svgWidth
}))`
  // width:100%;
  position: absolute;
`;

export const SVG = observer((props) => {
  const {upperLeft, lowerRight} = props.bounds;
  const height = lowerRight.y - upperLeft.y;
  const width = lowerRight.x - upperLeft.x;
  return (
    <Fragment>
      <StyledSVG svgHeight={height} svgWidth={width}>
        {props.children}
      </StyledSVG>
    </Fragment>
  );
});

export const svgDefaultConfig = () => {
  return {
    uiSchema: {
      tag: 'SVG',
      props: {
        text: "SVG",
        height: 0,
        width: 0
      }
    }
  };
};
