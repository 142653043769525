import $ from "@isf/core-object-util/src/object-util";
import { localeStore } from "@isf/core-localization";

const getOptionBinding = (input, localeStore) => {
  const {optionBinding} = input;
  if (!optionBinding || !localeStore) return optionBinding;
  const {locale} = localeStore;

  return {
    value: optionBinding.valueLocalized ? optionBinding.value + "." + locale : optionBinding.value,
    name: optionBinding.nameLocalized ? optionBinding.name + "." + locale : optionBinding.name,
    valueOnly: optionBinding.valueOnly
  }
};

const getComponentOptions = (options = [], bindings, intl) => {
  const resultOptions = [];
  let label = '';
  for (let i = 0; i < options.length; i++) {
    const option = $.get(options[i], bindings.name);
    if ($.isObject(option)) {
      const id = $.get(options[i], [bindings.name, "message"]);
      label = intl.messages[id];
      if (!label || label === "" || label === " ") {
        label = localeStore.getMessageFromLocale(id, "ru");
      }
    } else if (option === null){
      label = $.get(options[i], bindings.name.split(".").slice(0, -1).concat("ru"));
    } else {
      label = option;
    }

    label = (label == null || typeof label === "boolean") ? "" : label;

    resultOptions.push({
      value: `${$.get(options[i], bindings.value)}`,
      label: `${label}`
    });
  }
  return resultOptions;
};

const sortOptions = (options, locale, name = 'label') => {
  const correctedLocale = locale === 'by' ? 'be' : (locale || 'ru');
  return options.sort((a, b) => (
    (a[name] || '').localeCompare(
      b[name] || '',
      locale,
      { sensitivity: 'case', caseFirst: 'upper', numeric: true }
    )
  ));
};

const getOptionsValue = (value, input, localeStore) => {
  if (!input.optionsStore && !input.options) return value;

  const optionBinding = getOptionBinding(input, localeStore) || DEFAULT_OPTION_BINDING;
  let optionObj = input.optionsStore && input.optionsStore.state || input.options;
  optionObj = $.isArray(optionObj)
    ? optionObj.find(item => `${$.get(item, optionBinding.value)}` === value)
    : undefined;

  return optionBinding.valueOnly
    ? $.get(optionObj, optionBinding.value)
    : optionObj;
};


const DEFAULT_OPTION_BINDING = {
  valueOnly: true,
  value: 'value',
  name: 'text'
};

export {
  DEFAULT_OPTION_BINDING,

  getComponentOptions,
  getOptionBinding,
  getOptionsValue,
  sortOptions
}