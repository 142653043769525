import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { injectIntl } from '@isf/core-localization';
import classNames from 'classnames';
import { routerStore } from '@isf/core-app-store';
import { NavItem } from '@isf/bootstrap';
import { Link } from '../../links/link';
import { Message } from '../../form/message';
import { Icon } from '../../icon';
import { MenuContext, MenuStore } from './menu-store';
import { ICON_RHOMBUS/*, ICON_CIRCLE, ICON_DASH, ICON_TRIANGLE, ICON_STAR*/ } from '@isf/common-resources';
/**
 * Crutch for menu item.
 */
import { initializePage } from "../../../ui-schema/src/ui-engine";

@injectIntl
@inject("store")
@observer
class MenuItem extends Component {

  static contextType = MenuContext;

  constructor(props, context) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    if (props.uiStoreType) this.uiStore = new props.uiStoreType(props);
    this.menuStore = context.menuStore || new MenuStore({
      path: 'menuStore' + props.id,
      state: {
        [props.id]: {
          isActive: false,
          isExpanded: false
        }
      }
    });

    this.handleToggleSubMenu = this.handleToggleSubMenu.bind(this);
    this.updateMenuItem = this.updateMenuItem.bind(this);

    this.updateMenuItem();
  }

  componentDidMount() {
    this.updateMenuItem();
  }

  componentDidUpdate() {
    this.updateMenuItem();
  }

  componentWillUnmount() {
    this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  handleToggleSubMenu() {
    const {url, id} = this.props;
    const {sidebarCheck} = this.context || {};

    this.menuStore.setIsExpanded(id);
    if (url) {
      this.menuStore.setIsActive(id);
      sidebarCheck && sidebarCheck();
    }

    /**
     * Crutch for menu item.
     * microfrontend app has second browser history,
     * if url is changed routerStore is not update.
     *
     */
    const isNewUrl = routerStore.pathname === url && routerStore.pathname !== window.location.pathname;
    if (!url || url === "#" || !isNewUrl) return;

    initializePage(routerStore.pathname);
  };

  updateMenuItem() {
    const {url, id} = this.props;
    const {parentIdMenuItems} = this.context || {};

    if (url && (routerStore.pathname.startsWith(url + "/") || routerStore.pathname === url)
      && !this.menuStore.getIsActive(id)) {
      if (this.uiStore && this.uiStore.hiddenStore.get('isHidden')) return;
      parentIdMenuItems && parentIdMenuItems.forEach(id => this.menuStore.setIsExpanded(id, true));
      this.menuStore.setIsActive(id, true);
    }
  }

  // getIconByLevel = (level) => {
  //   switch (level) {
  //     case 1: return ICON_RHOMBUS;
  //     case 2: return ICON_CIRCLE;
  //     case 3: return ICON_DASH;
  //     case 4: return ICON_TRIANGLE;
  //     default: return ICON_STAR;
  //   }
  // }

  render() {
    const {
      store: {isActive}, message, url, iconSrc, iconSize, containedSubmenu, children, intl: {formatMessage}, id,
      className
    } = this.props;
    const {parentIdMenuItems, sidebarCheck} = this.context || {};
    if (this.uiStore && this.uiStore.hiddenStore.get('isHidden')) return null;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <NavItem
        key={id}
        className={classNames("mw-100 position-relative", className, computedClassNames)}
        title={!isActive && message ? formatMessage({id: message.id}) : ""}
      >
        <Link
          to={url || "#"}
          className={classNames(
            "d-flex align-items-center position-relative nav-link overflow-hidden",
            {"active": url && this.menuStore.getIsActive(id) && !containedSubmenu}
          )}
          onClick={this.handleToggleSubMenu}
        >
          {iconSrc && (
            <div className="menu-item-icon-wrapper">
              <Icon
                src={iconSrc}
                className="menu-item-icon menu-item-icon-oais-new mr-0"
                size={iconSize}
                title={{message: {id: message.id}}}
              />
            </div>
          )}
          {!isActive && !iconSrc && (
            <div className="menu-item-icon-wrapper">
              <img
                src={ICON_RHOMBUS /*this.getIconByLevel(parentIdMenuItems && parentIdMenuItems.length)*/}
                className="menu-item-icon-oais-new mx-auto icon-w-10"
              />
            </div>
          )}
          <span className={classNames(
            "nav-link-title menu-item-title", {
              "containedSubmenu": containedSubmenu,
              "isMenuItemsExpanded": this.menuStore.getIsExpanded(id),
              "nav-link-title-hidden": !isActive
            })}
          >
            <Message message={message}/>
          </span>
        </Link>
        {children.length !== 0 && (
          <div className={classNames(
            "sub-menu-container", {
              "sub-menu-container-expanded": this.menuStore.getIsExpanded(id),
              "sub-menu-container-hidden": !this.menuStore.getIsExpanded(id)
            })}
          >
            <MenuContext.Provider
              value={{
                menuStore: this.menuStore,
                parentIdMenuItems: parentIdMenuItems ? [...parentIdMenuItems, id] : [id],
                sidebarCheck: sidebarCheck
              }}
            >
              {children}
            </MenuContext.Provider>
          </div>
        )}
      </NavItem>
    )
  }
}

export default MenuItem;
