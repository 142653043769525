/** took FROM sdk/design-components/ui-schema/ui-schema-design-draggable-full-actions */

const descendants = (parent) => (
  Array.from(parent.keys && parent.keys() || Object.keys(parent)).map(
    key => ({
      key,
      data: parent.get ? parent.get(key) : parent[key]
    }))
);

const getParentElementByPath = (path, data) => {
  let buffData = data.state;
  let parent = {};
  for (let i = 0; i < path.split(".").length - 1; i++) {
    parent = buffData;
    descendants(buffData).map((element) => {
      if (element.key === "children") {
        buffData = element.data[path.split(".")[i + 1]];
      }
    })
  }
  return parent;
};

const deleteDragElementsFromArray = (path) => {
  for (let i = 0; i < arrayOfRefs.length; i++) {
    if (arrayOfRefs[i].path.indexOf(path) !== -1) {
      arrayOfRefs.splice(i, 1);
      i--;
    }
  }
};

const removeElementByPath = (path, designContext) => {
  const uiSchemaStore = designContext.uiSchemaStore || designContext; //TODO: fix trash
  deleteDragElementsFromArray(path);
  descendants(getParentElementByPath(path, uiSchemaStore)).map((element) => {
    if (element.key === "children") {
      element.data.splice(+path.split(".")[path.split(".").length - 1], 1);
    }
  });
  const modelPath = designContext.schemaCursorStore ? designContext.schemaCursorStore.modelPath : undefined; //TODO: fix trash
  if (modelPath) {
    // TODO: fix trash
    designContext.modelSchemaPropsStore.removeNode(modelPath.join ? modelPath.join('.') : modelPath);
  }
};

export { removeElementByPath };