import React from 'react';
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import {Input} from "reactstrap";

const handleOnChangeInputValue = (e, props) => {
    const {store, accessor} = props;
    store.setCellValue(accessor, e.target.value);
}

const handleOnFocus = (e, props) => {
    const {store, rowId, colId} = props;
    store.setInitialValue({rowId, colId}, e.target.value);
};

const handleOnBlur = (props) => {
    const {store, rowId, colId} = props;
    store.endEditCell({rowId, colId});
};

const handleOnKeyDown = (e, props) => {
    const {store, rowId, colId, accessor} = props;
    if (e.key === 'Enter') {
        store.endEditCell({rowId, colId});
    } else if (e.key === 'Escape') {
        store.setCellValue(accessor, store.getInitialValue({rowId, colId}));
        store.endEditCell({rowId, colId});
    }
};

const Editor = observer(props => {
    const {accessor, value} = props;
    return (
      <Input autoFocus value={value} accessor={accessor}
             onKeyDown={(e) => handleOnKeyDown(e, props)}
             onFocus={(e) => handleOnFocus(e, props)}
             onBlur={() => handleOnBlur(props)}
             onChange={(e)=> handleOnChangeInputValue(e, props)}
      />
    );
});

Editor.propTypes = {
    accessor: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    store: PropTypes.object.isRequired,
    rowId: PropTypes.number.isRequired,
    colId: PropTypes.number.isRequired
  };

export default Editor;