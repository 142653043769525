import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {injectIntl} from '@isf/core-localization';
import classNames from 'classnames';

@injectIntl
@observer
class Form extends Component {
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    if (props.uiStoreType) this.uiStore = new props.uiStoreType(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillUnmount() {
    this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.onSubmit();
  }

  render() {
    const {children, className, intl, onSubmit, uiStoreType, hidden, computedClassName, localeStore, languages,
      uiBaseStore, ...other} = this.props;
    if (this.uiStore && this.uiStore.hiddenStore.get('isHidden')) return null;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <form
        className={classNames(className, computedClassNames)}
        onSubmit={this.onSubmit}
        {...other}
      >
        {children}
        <input type="submit" value={intl.formatMessage({id: "ui.form.inputSubmit.title"})} className="d-none" />
      </form>
    );
  }
}

export {Form};