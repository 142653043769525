import React, { Fragment } from "react";
import delete_icon from '../images/delete.png';
import settings from '../images/settings-15.png';
import { removeElementByPath } from "./hover-component-actions";

export const DefaultHoverComponent = (props) => {
  const path = props.children.props.children.props.args.key;
  const designContext = props.children.props.children.props.args.designContext;

  //const isShowGrid= props.children.props.children.props.args.designSettingsStore.getShowGrid();
  return (
    <Fragment>
      <div>
        <img src={settings} alt="Settings" />
      </div>
      <div>
        {/*<img src={delete_icon} alt="Delete" onClick={()=>isShowGrid?removeElementByPath(path,designContext):removeElementByPathWithEmptyParents(path,designContext)}/>*/}
        <img src={delete_icon} alt="Delete" onClick={() => removeElementByPath(path, designContext)} />
      </div>
    </Fragment>
  )
};