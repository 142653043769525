import { FilterStore } from "@isf/filter";
import { PaginationStore } from "@isf/pagination";
import { TableStore } from "@isf/table";

class TableViewStore /*extends UIStore*/ {

    constructor(props) {
      // super();
      const {path = 'defaultPath',data} = props || {};
      this._tableStore = new TableStore({
        path: path+'.table.tableStore',
        state: {}
      });
      
      this._paginationStore = new PaginationStore({
        path: path+'.pagination.paginationStore',
        state: {}
      });
      
      this._filterStore = new FilterStore({
        path: path+'.filter.filterStore',
        state: {}
      });

      this.data = data;
  }
    
    set data(data) {
      this.tableStore.data = data;
    }

    get data() {
      return this.tableStore.data;
    }
    
    get paginationStore() {
      return this._paginationStore;
    }
  
    get tableStore() {
      return this._tableStore;
    }
  
    get filterStore() {
      return this._filterStore;
    }

  }
  
  export { TableViewStore };
  