import { useCallback } from "react";
import appStore, { DataStore } from "@isf/core-app-store";


const useStore = (args, scope) => {
  // return useCallback( () => {   
    const { path, type } = args;
    const store = appStore.getStore(scope + '.' + path);
    if (store) {
      return store;
    }
    return new type({
      ...args
    });
  // })()
};

const useUIStore = (args) => {
  return useStore(args, 'ui')
};

const useDataStore = (args) => {
  return useStore({type: DataStore, ...args}, 'data')
};

const getStore = (store) => (
  (typeof store === 'string' ) ? useDataStore({path:store}) : store
);

const useAccessor = (s) => {
  const store = getStore(s);
  const get = (path) => store.get(path);
  const set = (path, value) => store.set(path, value);
  return (path) => {
    return {
      dataStore: store,
      accessor: path,
      get,
      set
    }
  }
}

const makeAccessor = (s) => {
  const store = getStore(s);
  const get = (path) => store.get(path);
  const set = (path, value) => store.set(path, value);
  return path => {
    return {
      dataStore: store,
      accessor: path,
      get,
      set
    }
  }
}

// const useStore = ({ type, path, state }) => {
//   const [store] = useState( () => {    
//     const store = appStore.getStore('ui.' + path);    
//     if (store) {
//       return store;
//     }

//     return new type({
//       path,
//       state
//     });
//   });
//   return store;
// };

export { /*makeAccessor, useAccessor,*/ useDataStore, useUIStore };