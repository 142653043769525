import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Nav } from "reactstrap";
import classNames from "classnames";
import { useUIStore } from "@isf/react-util";
import { NavbarStore } from "./navbar-store";
import {observer} from "mobx-react";
import {getListIndex} from "../list-context/list-utils";

const useNavStore = (args) => useUIStore({
  ...args,
  type: NavbarStore
});
@observer
class Navbar extends Component {

  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    this.id = props.id;

    const listIndex = getListIndex(props);
    if (listIndex !== undefined) {
      this.id += `_${listIndex}`;
    }
    //   this.navStore =
  //     (typeof props.navStore === 'string')
  //       ? useUIStore({
  //         ...args,
  //         type: NavbarStore
  //       })
  //       : props.navStore;
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {id, props} = this;
    const {children, navbarType, className, computedClassName, localeStore, languages, uiBaseStore, ...other} = props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();
    return (
      <Nav
        pills={navbarType !== 'pills' ? false : true}
        tabs={navbarType !== 'tabs' ?false : true}
        className={classNames(className, computedClassNames)}
        {...other}
        id={id}
        /*navStore={this.navStore}*/
      >
        {children}
      </Nav>
    );
  }
};

Navbar.propTypes = {
  toggleMode: PropTypes.oneOf(['single', 'multiple']),
  store: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export { Navbar, useNavStore };