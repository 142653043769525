import { getMicrofrontend } from "../microfrontend";

const loadedScripts = {};

const addScriptElement = (src) => {
  let script = document.createElement('script')
  script.setAttribute('src', src);
  document.body.appendChild(script);
  return script;
}

const loadScript = (action) => {
  if (!loadedScripts[action.src]) {
    const script = addScriptElement(action.src);
    loadedScripts[action.src]  = true;
    script.onload = async () => {
      try {
        const microfrontend = await getMicrofrontend(action.microfrontend);
        microfrontend.callbacks.render();
      } catch (e) {
        console.log(e);
      }
    };
  }
  
}

export { loadScript };