import React, {Component} from "react";
import {observer, inject} from "mobx-react";
import classNames from "classnames";
import {HeaderLeftItems} from "./header-left-items";
import {HeaderRightItems} from "./header-right-items";
import {Navbar} from 'reactstrap';

@inject('store')
@observer
class Header extends Component {
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

    componentWillUnmount() {
        this.uiBaseStore && this.uiBaseStore.dispose();
    }

  render() {
    const {store, className, children} = this.props;
    const {isActive} = store;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <div className={classNames("header-container", className, computedClassNames)}>
        <Navbar
          className={classNames(
            "h-100 justify-content-end py-0 bg-white border-bottom",
            {"header-margin-sidebar-active": isActive,
              "header-margin-sidebar-inactive": !isActive
          })}
          light
          expand="md"
        >
          <HeaderLeftItems store={store} children={children} />
          <HeaderRightItems store={store} />
        </Navbar>
      </div>
    );
  }
}

export default Header;
