import React from "react";
import { observer } from "mobx-react";

const MenuToggle = observer((props) => {
    const { store } = props;
    const setMenuActive = () => {
        store.setActive(!store.isActive);
    };
    return (
        <span className="navbar-toggler-icon cursor-pointer" onClick={setMenuActive} />
    );
});

export { MenuToggle };