import styled from 'styled-components';

const StyledScrollbar = styled.div`
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 6px;
      background-color: #F5F5F5;
    }

   &::-webkit-scrollbar
    {
      width: 7px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
      border-radius: 6px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: gray;
    }
`;

export { StyledScrollbar };
