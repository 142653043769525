class RestResource {

  path; // resource path

  api;

  handler;

  constructor({ api, path, handler }) {
    this.api = api;
    this.path = path;
    this.handler = handler;
  }

  request({ pathParams, queryParams, options }) {
    return this.api.request({ pathParams, queryParams, options });
  }

  post({ body, pathParams, queryParams, headers, handler=this.handler }) {
    return this.api.request({
      resourcePath: this.path,
      handler,
      pathParams,
      queryParams,
      options: {
        body: (typeof body === 'string' ? body : JSON.stringify(body)),
        headers: headers || {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }, 
        method: 'POST'
      }
    });
  }

  get({ key, pathParams = [], queryParams, headers, handler=this.handler } = {}) {
    return this.api.request({
      resourcePath: this.path,
      handler,
      pathParams: key ? [key, ...pathParams] : pathParams,
      queryParams,
      options: {
        headers: headers || {
          'Accept': 'application/json'
        },        
        method: 'GET' 
      }
    });
  }

  put({ key, pathParams = [], queryParams = {}, headers, handler=this.handler }) {
    return this.api.request({
      resourcePath: this.path,
      handler,
      pathParams: key ? [key, ...pathParams] : pathParams,
      queryParams,
      options: { 
        headers: headers || {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }, 
        method: 'PUT'
      }
    });
  }

  delete() {

  }

}

export default RestResource;