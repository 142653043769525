import styled, { css } from "styled-components";

import { ICON_ARROW_COLLAPSED } from "@isf/common-resources";

// const ul = styled.ul`
//   ${({ expanded, level }) =>
//     css`
//       list-style-type: none;
//       padding-left: 5px;
//       margin-left: 0px;
//       margin-block-start: 0em;
//       ${level === 0 ? '': 'border-left-style: solid;'}
//       border-width: 1px;
//       border-color: lightgrey;
//       margin-left:5px;
//       :hover {
//         border-color: grey;        
//       }
//       ${expanded ? "": "display:none;"}      
//     `};
// `;

const NODE_SHIFT = 0;
const UL_BORDER_WIDTH = 1;
const ROOT_MARGIN_LEFT = 0;

const ul = styled.ul`
  ${({ expanded, noBorder }) =>
    css`
      list-style-type: none;
      padding-left: 5px;
      margin-left: 0px;
      margin-block-start: 0em;
      ${noBorder ? "":          
      `border-width: ${UL_BORDER_WIDTH}px;
      border-left-style: solid;
      border-color: lightgrey;
      margin-left:5px;` }
      ${expanded ? "": "display:none;"}      
    `};
`;

const root = styled.ul`
  ${({ expanded = true }) =>
    css`
      list-style-type: none;
      padding-left: 0px;
      margin-left: ${ROOT_MARGIN_LEFT}px;
      // border: solid 1px; 
      ${expanded ? "": "display:none;"}      
    `};
`;

const label = styled.span`
  ${({active}) =>
    css`
      font-family: monospace;
      cursor: default;
      ${active ? 'font-weight: bold;' : ''};      
    `};
`;

// const label = styled.span`
//   ${({selected, level}) =>
//     css`
//       font-family: monospace;
//       cursor: default;
//       ${selected ? 'font-weight: bold;' : ''};
//       margin-left: ${labelMarginLeft(level)}px;  
//     `};
// `;

// const leaf = styled.span`
//   ${({selected}) =>
//     css`
//       font-family: monospace;
//       cursor: default;
//       ${selected ? 'font-weight: bold;' : ''};
//       margin-left: ${PROP_LABEL_MARGIN_LEFT}px;  
//     `};
// `;

const leafKey = styled.span`
  ${() =>
    css`
      font-family: monospace;
      margin-left: 0px;
      word-break: break-word !important;
      overflow-wrap: break-word !important;  
    `};
`;

const leafValue = styled.span`
  ${() =>
    css`
      font-family: monospace;
      color: brown;
      font-weight: black;
      word-break: break-word !important;
      overflow-wrap: break-word !important;
    `};
`;

const li = styled.li`
  ${({leaf}) =>
    css`
      list-style-type: none;
      padding-left: ${leaf? 20 : 5}px;
      margin-left: 0px;
      margin-block-start: 0em;
    `};
`;
// list-style-image: url(${arrowDown});

// const node = styled.div`
//   ${({ hasControls, hasChildren }) =>
//     css`
//       margin-left: ${hasControls ? (hasChildren ? '20px' : '36px') : (hasChildren ? '1px' : '17px')};
//       display: inline-block;
//     `};
// `;

// const content = styled.div`
// ${({ expanded, level }) =>
//     css`
//     ${expanded ? "": "display:none;"} 
//     margin-left: ${level*15}px; 
// `};
// `;

const openBracket = styled.span`
  ${() =>
    css`
      color: blue;
    `};
`;

const closeBracket = styled.span`
  ${() =>
    css`
      color: blue;
      margin-left: 3px;
    `};
`;

const checkbox = styled.input`
${() =>
    css`
      margin-top: 3px;
  `};
`;

const twistie = styled.span`
  ${({ expanded, level }) =>
    css`
      background-image: url(${ICON_ARROW_COLLAPSED});
      opacity: 1;
      width: 9px;
      height: 9px;
      flex-shrink: 0;
      display: inline-block;
      :hover {
        cursor: pointer;
      }
      margin-left: ${-level*NODE_SHIFT}px;
      margin-right: 5px;
      transition: transform .3s ease-in-out;
      transform: ${expanded ? "rotate(90deg)" : 0};
    `};
`;

const circle = styled.span`
  ${() =>
  css`
      opacity: 1;
      width: 9px;
      height: 9px;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      display: inline-block;
      margin-left: 0px;
      margin-right: 5px;
      border: 1px solid #2b2b2b;
      border-radius: 50%;
    `};
`;


export default { root, ul, li, twistie, label, /*leaf,*/ leafKey, leafValue, openBracket, checkbox, closeBracket, circle };