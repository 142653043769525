export const getOperationOptions = ({formatMessage}) => {
  return [
    { value: "equals", text: formatMessage({id: "ui.filterSetting.criterion.operation.equals"}) },
    { value: "not_equals", text: formatMessage({id: "ui.filterSetting.criterion.operation.notEquals"}) },
    //{ value: "in", text: formatMessage({id: "ui.filterSetting.criterion.operation.in"}) },
    //{ value: "is_null", text: formatMessage({id: "ui.filterSetting.criterion.operation.isNull"}) },
    { value: "gt", text: formatMessage({id: "ui.filterSetting.criterion.operation.gt"}) },
    { value: "lt", text: formatMessage({id: "ui.filterSetting.criterion.operation.lt"}) },
    { value: "gte", text: formatMessage({id: "ui.filterSetting.criterion.operation.gte"}) },
    { value: "lte", text: formatMessage({id: "ui.filterSetting.criterion.operation.lte"}) },
    { value: "like", text: formatMessage({id: "ui.filterSetting.criterion.operation.like"}) },
    { value: "likeStrictOption", text: formatMessage({id: "ui.filterSetting.criterion.operation.likeStrictOption"}) }
  ];
};

export const getOperationOptionsByType = (type, propFormat, intl) => {
  const operationOptions = getOperationOptions(intl);
  switch (type) {
    case "string":
      if (propFormat === "date" || propFormat === "date-time") {
        return operationOptions.filter(item =>
          ["", "equals", "not_equals", "in", "gt", "lt", "gte", "lte"].indexOf(item.value) !== -1 );
      }
      else return operationOptions.filter(item =>
        ["", "like", "likeStrictOption", "equals", "not_equals", "in"].indexOf(item.value) !== -1 );
    case "integer":
    case "number":
      return operationOptions.filter(item =>
        ["", "equals", "not_equals", "in", "gt", "lt", "gte", "lte"].indexOf(item.value) !== -1 );
    case "boolean":
      return operationOptions.filter(item => ["", "equals", "not_equals", "in"].indexOf(item.value) !== -1 );
    default:
      return operationOptions.filter(item => ["", "like", "equals", "not_equals", "in"].indexOf(item.value) !== -1 );
  }
};

export const getValueOptionsForBooleanType = ({formatMessage}) => {
  return [
    { value: true, text: formatMessage({id: "ui.filterSetting.criterion.booleanType.valueOptions.true"}) },
    { value: false, text: formatMessage({id: "ui.filterSetting.criterion.booleanType.valueOptions.false"}) }
  ];
};

export const layout = {
  label: {
    props: {
      xs: 12,
      sm: 12,
      md: 5,
      lg: 5,
      xl: 5
    }
  },
  input: {
    layout: {
      col: {
        xs: 12,
        sm: 12,
        md: 7,
        lg: 7,
        xl: 7
      }
    }
  }
};