import { computed, action } from "mobx";

import { UIStore } from "@isf/core-app-store";


class DropdownStore extends UIStore {
  constructor(props) {
    super(props);
  }
  @action
  setValue(value) {
    this.set('value', value);
  }

  @computed
  get value() {
    return this.get('value');
  }

  @action
  toggleOpen() {
    const isOpen = this.get("isOpen");
    this.set("isOpen", !isOpen);
  }

  @computed
  get isOpen() {
    return this.get("isOpen");
  }

}

Object.defineProperty(DropdownStore, "name", {value: "DropdownStore"});

export default DropdownStore;