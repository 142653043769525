import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import { Message } from "../../form/message";
import { DropdownItem as _DropdownItem } from "@isf/bootstrap";
import { Link } from "../../links/link";
import classNames from "classnames";

const DropdownOption = ({ option }) => (
  <Fragment>
    <Message text={option.name} message={option.message} />
  </Fragment>
);

@inject("store")
@observer
class DropdownItem extends Component {

  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    if (props.uiStoreType) this.uiStore = new props.uiStoreType(props);
    this.store = props.store;
    this.handleItemClick = this.handleItemClick.bind(this);
  }

  handleItemClick({ value, e }) {
    e.stopPropagation();
    this.store.setValue(value);
  }

  renderOption(option, { optionTag: DropdownOption }) {
    if (option.divider) return null;
    if (option.header) return option.header;
    return <DropdownOption option={option} />;
  }

  componentWillUnmount() {
    this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const { option, to, customOnClick = this.handleItemClick, uiStoreType, computed, className, uiBaseStore,
      ...other } = this.props;
    const toHref = (this.uiStore && this.uiStore.computedStore.get("value")) || to;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();
    return (
      <_DropdownItem
        disabled={option.disabled}
        divider={option.divider}
        header={!!option.header}
        tag={toHref && Link}
        to={toHref}
        className={classNames(className, computedClassNames)}
        onClick={(e) => customOnClick({ e, value: option.value, store: this.store, ...other })}>
        {this.renderOption(option, this.props)}
      </_DropdownItem>
    );
  }
}

DropdownItem.propTypes = {
  id: PropTypes.string,
  option: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    header: PropTypes.string,
    divider: PropTypes.bool
  }).isRequired,
  itemTag: PropTypes.elementType,
  optionTag: PropTypes.elementType,
  store: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
};

DropdownItem.defaultProps = {
  itemTag: DropdownItem,
  optionTag: DropdownOption
};

export { DropdownItem };