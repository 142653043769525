export const ThrowCompensationEventConf = (number, coordinates) => {
   return (
      {
         tag: "Shape",
         props:
            {
               bounds: {
                  lowerRight: {
                     x: coordinates.x + 35,
                     y: coordinates.y + 35
                  },
                  upperLeft: {
                     x: coordinates.x,
                     y: coordinates.y
                  }
               },
               resourceId: "compensationintermediatethrowevent"+number,
               childShapes: [],
               stencil: {
                  id: "ThrowNoneEvent"
               },
               properties: {
                  overrideid: "compensationintermediatethrowevent"+number,
                  name: "CompensationThrowingEvent",
                  executionlisteners: {
                     executionListeners: []
                  }
               },
               outgoing: []
            },
         children: []
      }
   )
};