export const CatchMessageEventConf = (number, coordinates) => {
   return (
      {
         tag: "Shape",
         props:
            {
               bounds: {
                  lowerRight: {
                     x: coordinates.x + 35,
                     y: coordinates.y + 35
                  },
                  upperLeft: {
                     x: coordinates.x,
                     y: coordinates.y
                  }
               },
               resourceId: "messageintermediatecatchevent"+number,
               childShapes: [],
               stencil: {
                  id: "CatchMessageEvent"
               },
               properties: {
                  overrideid: "messageintermediatecatchevent"+number,
                  name: "MessageCatchEvent",
                  messageRef: "",
                  executionlisteners: {
                     executionListeners: []
                  }
               },
               outgoing: []
            },
         children: []
      }
   )
};