import {lazy} from 'react';
import {Label, FormGroup} from 'reactstrap';
import {GroupsView} from '@isf/admin';

import {
    Container,
    Row,
    Col,
    Field,
    FilterField,
    Form,
    Input,
    DateInput,
    StaticText,
    Nav,
    Menu,
    Dropdown,
    DropdownItem,
    Button,
    PageTemplate,
    Header as HeadPanel,
    PageContainer,
    PageFooter,
    PageContent,
    MenuItem,
    Sidebar,
    PageBody,
    ItemLink,
    Link,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Pagination,
    PaginationSize,
    CardTitle,
    CardSubtitle,
    DiagramReadOnlyView,
    ModalContainer,
    ModalHeader,
    ModalBody,
    ModalFooter,
    NavigationContainer,
    Navbar,
    NavigationItem,
    SectionContainer,
    NavigationSectionContent,
    ImageModalView,
    ObjectContainer,
    Item,
    ItemGrid,
    _Table,
    _TableHead,
    _TableBody,
    _TableRow,
    _TableCell,
    Icon,
    TableRecordEditor,
    Breadcrumb,
    BreadcrumbItem,
    Splitter,
    ConfiguredImage,
    DiagramView,
    RefComponent,
    WeekdaysPicker,
    ReportRating, Div
} from '@isf/common';

import {TreeViewContainer} from "@isf/tree";

import {Header} from "@isf/elementary";

// import { Link } from "react-router-dom";

import {
   Table
} from "@isf/table"
//import { UITextStore } from "./ui-stores/ui-text-store";
import {UIFieldStore} from "./ui-stores/ui-field-store";
import { /*UIButtonStore,*/ UICommonStore} from "./ui-stores/ui-common-store";
import {UILoadStore} from "./ui-stores/ui-load-store";
import {FilterSetting} from "@isf/filter-setting";
import {ExpandCollapse, ExpandCollapseHeader, ExpandCollapseToggle, ExpandCollapseBody} from "@isf/expand-collapse";
import {PageHeader, PageTitle, PageSubtitle} from "@isf/page-header";
import {UINavItemStore} from "./ui-stores/ui-navitem-store";
import {UIExtendedStore, UiRefComponentStore, UIPopoverStore} from "./ui-stores";
import {ApiSelection} from "@isf/api-selection";
import {PrivacyBar} from "@isf/privacy-bar";
import {handleError, handleModuleImport} from '@isf/dynamic-import-handler';
import { Overlay, OverlayTrigger, Popover, PopoverHeader, PopoverBody } from '@isf/popover';
import {HtmlContent} from "@isf/html-content";

export const components = {
  'Container': {component: Container, store: UIExtendedStore},
  'Row': {component: Row},
  'Col': {component: Col},
  'Field': {component: Field, store: UIFieldStore},
  'Input': {component: Input},
  'DateInput': {component: DateInput},
  'Label': {component: Label},
  'FormGroup': {component: FormGroup},

  'Filter': {component: FilterField/*Field*/, store: UIFieldStore},
  'Form': {component: Form, store: UICommonStore},
  'Card': {component: Card, store: UICommonStore},
  'CardBody': {component: CardBody, store: UILoadStore},
  'CardHeader': {component: CardHeader},
  'CardFooter': {component: CardFooter},
  'CardTitle': {component: CardTitle},
  'CardSubtitle': {component: CardSubtitle},
  'HtmlContent': {component: HtmlContent, store: UICommonStore},
  'Div':{component: Div, store: UICommonStore},
  'DiagramReadModal': {component: DiagramReadOnlyView},
  'ReportRating': {component: ReportRating},
  'Text': {component: StaticText, store: UICommonStore},
  'Link': {component: Link, store: UICommonStore},
  'Nav': {component: Nav},
  'Menu': {component: Menu},
  'SimpleTable': {component: Table, store: UILoadStore},
  'TableRecordEditor': {component: TableRecordEditor},
  'Button': {component: Button, store: UICommonStore},
  'Dropdown': {component: Dropdown},
  "DropdownItem": {component: DropdownItem, store: UICommonStore},
  'PageTemplate': {component: PageTemplate},
  'PageContainer': {component: PageContainer},
  'PageContent': {component: PageContent},
  'HeadPanel': {component: HeadPanel},
  'PrivacyBar': {component: PrivacyBar},
  'PageFooter': {component: PageFooter},

  'MenuItem': {component: MenuItem, store: UICommonStore},
  'Sidebar': {component: Sidebar},
  'PageBody': {component: PageBody},
  'ItemLink': {component: ItemLink},
  'Header': {component: Header},

  'GroupsView': {component: GroupsView},
  'Image': {component: ImageModalView},
  'Pagination': {component: Pagination},
  'PaginationSize': {component: PaginationSize},

  'Modal': {component: ModalContainer, store: UILoadStore},
  'ModalHeader': {component: ModalHeader},
  'ModalBody': {component: ModalBody},
  'ModalFooter': {component: ModalFooter},

  'Item': {component: Item, store: UICommonStore},
  'ItemGrid': {component: ItemGrid, store: UILoadStore},
  'Table': {component: _Table, store: UIExtendedStore},
  'TableHead': {component: _TableHead, store: UICommonStore},
  'TableBody': {component: _TableBody},
  'TableRow': {component: _TableRow},
  'TableCell': {component: _TableCell},
  // 'DropdownToggle': { component },
  // 'DropdownMenu': { component }
  'NavContainer': {component: NavigationContainer, store: UICommonStore},
  'Navbar': {component: Navbar},
  'NavItem': {component: NavigationItem, store: UINavItemStore},
  'SectionContainer': {component: SectionContainer},
  'NavSection': {component: NavigationSectionContent},
  'ObjectContainer': {component: ObjectContainer},
  'Icon': {component: Icon},
  'Breadcrumb': {component: Breadcrumb},
  'BreadcrumbItem': {component: BreadcrumbItem},
  'ApiContainer': {
    component: lazy(() => import('@isf/swagger')
      .then(module => handleModuleImport(module, '@isf/swagger', 'ApiContainer'))
      .catch(handleError)),
    store: UILoadStore
  },
  'ApiItem': {
    component: lazy(() => import('@isf/swagger')
      .then(module => handleModuleImport(module, '@isf/swagger', 'ApiInfo'))
      .catch(handleError))
  },
  'ApiInfo': {
    component: lazy(() => import('@isf/swagger')
      .then(module => handleModuleImport(module, '@isf/swagger', 'ApiItemModeWrapper'))
      .catch(handleError))
  },
  'ApiOutline': {
    component: lazy(() => import('@isf/swagger')
      .then(module => handleModuleImport(module, '@isf/swagger', 'ApiOutline'))
      .catch(handleError))
  },
  'SplitterVertical': {component: Splitter},
  'SplitterHorizontal': {component: Splitter},
  'Chart': {
    component: lazy(() => import('@isf/chart')
      .then(module => handleModuleImport(module, '@isf/chart', 'Chart'))
      .catch(handleError))
  },
  'Map': {
    component: lazy(() => import('@isf/map')
      .then(module => handleModuleImport(module, '@isf/map', 'Map'))
      .catch(handleError))
  },
  'MapLeaflet': {
    component: lazy(() => import('@isf/map-leaflet')
      .then(module => handleModuleImport(module, '@isf/map-leaflet', 'MapLeaflet'))
      .catch(handleError))
  },
  'TreeView': {component: TreeViewContainer},
  'ConfiguredImage': {component: ConfiguredImage, store: UICommonStore},
  'DiagramView': {component: DiagramView},
  'ReportBuilder': {
    component: lazy(() => import('@isf/report-builder')
      .then(module => handleModuleImport(module, '@isf/report-builder', 'ReportBuilder'))
      .catch(handleError))
  },
  'FilterSetting': {component: FilterSetting},
  'ExpandCollapse': {component: ExpandCollapse, store: UICommonStore},
  'ExpandCollapseHeader': {component: ExpandCollapseHeader},
  'ExpandCollapseToggle': {component: ExpandCollapseToggle, store: UICommonStore},
  'ExpandCollapseBody': {component: ExpandCollapseBody},
  'PageHeader': {component: PageHeader},
  'PageTitle': {component: PageTitle},
  'PageSubtitle': {component: PageSubtitle},
  'RefComponent': { component: RefComponent, store: UiRefComponentStore },
  'Calendar': {
    component: lazy(() => import('@isf/calendar')
      .then(module => handleModuleImport(module, '@isf/calendar', 'CalendarView'))
      .catch(handleError))
  },
  'WeekdaysPicker' : {component: WeekdaysPicker},
  'Carousel': {
    component: lazy(() => import('@isf/carousel/src')
      .then(module => handleModuleImport(module, '@isf/carousel', 'CarouselView'))
      .catch(handleError))
  },
  'ApiSelection' : {component: ApiSelection},
  'Overlay' : {component: Overlay},
  'OverlayTrigger' : {component: OverlayTrigger},
  'Popover' : {component: Popover, store: UIPopoverStore},
  'PopoverHeader' : {component: PopoverHeader},
  'PopoverBody' : {component: PopoverBody},
};

export const LOCALIZED_COMPONENTS = ["Field", "Filter", "Text", "ItemGrid"];
export const LIST_COMPONENTS_CLONE_REDUCERS = ["TableBody", "ItemGrid"];
export const LIST_CLONE_COMPONENTS = ["TableRow", "Item"];
export const LIST_UI_COMPONENTS = Object.keys(components);

export const resolveComponent = (tagName) => {
   return (components[tagName] ? components[tagName].component : tagName)
};

export const resolveComponentStore = (tagName) => {
   return (components[tagName] ? components[tagName].store : undefined)
};

export const registerComponent = (tagName, component) => {
   if (!components[tagName]) {
      components[tagName] = {component};
   }
};

const moduleInfo = {
   name: 'isf-core',
   version: '0.1.0'
};

export default {moduleInfo};
