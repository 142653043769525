import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { ModalHeader as ModalHeader_ } from "reactstrap";
import { ModalContext } from "./modal-container";
import classNames from "classnames";

@inject("toggleStore")
@observer
class ModalHeader extends React.Component{
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  componentWillUnmount() {
      this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {toggleStore, children, toggle, isLoading, className, computedClassName, localeStore, languages,
      uiBaseStore, ...other} = this.props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <ModalContext.Consumer>
        {value =>
          <ModalHeader_
            tag="span"
            toggle={() =>value ? value(other) : toggleStore.toggle(other, true)}
            className={classNames(className, computedClassNames)}
            {...other}
          >
            {children}
          </ModalHeader_>
        }
      </ModalContext.Consumer>
    );
  }
}

ModalHeader.defaultProps = {
   toggle: true
};

ModalHeader.propsTypes = {
   toggleStore: PropTypes.object.isRequired,
   toggle: PropTypes.bool
};

export {ModalHeader};