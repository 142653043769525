import React, { Component } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { CardBody, Collapse } from "@isf/bootstrap";
import { ExpandCollapseContext } from "./expand-collapse";

@observer
class ExpandCollapseBody extends Component {
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  static contextType = ExpandCollapseContext;

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {store, isSticky, initialOffsetHeight} = this.context;
    const {children, className} = this.props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <Collapse isOpen={store.isExpanded}
                // style={isSticky
                //         ? {
                //             maxHeight: `calc(100vh - ${initialOffsetHeight}px - 56px - 15px)`,
                //             overflowY: "auto",
                //             overflowX: "hidden"
                //           }
                //         : {}}
      >
        <CardBody className={classNames("px-0 py-2 expand-collapse-body", className, computedClassNames)}>
          {children}
        </CardBody>
      </Collapse>
    )
  }
}

export { ExpandCollapseBody };