import appStore from "./app-store";

export { default } from "./app-store";
export { default as routerStore } from "./router-store";
export { default as Store } from "./store";
export { default as UIStore } from "./ui-store";
export { default as cacheStore } from "./cache-store";
export { default as DataStore } from "./data-store";
export { normalizePath } from "./path-util";

export const storeExists = (path) => appStore.storeExists(path);
