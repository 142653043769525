import React from "react"
import {
    BoundaryTimerEventContent,
    CatchTimerEventContent,
    ContentRoundShape,
    ExclusiveGatewayContent,
    InclusiveGatewayContent,
    ParallelGatewayContent,
    ReceiveTaskContent,
    ScriptTaskContent,
    ServiceTaskContent,
    SubProcessContent,
    UserTaskContent,
    BoundaryCancelEventContent,
    BoundaryErrorEventContent,
    BoundarySignalEventContent,
    CatchSignalEventContent,
    CompensationEventContent,
    CompensationThrowingEventContent,
    MessageEventContent,
    NoneThrowingEventContent,
    ThrowSignalEventContent,
    TerminateEndEventContent
} from "./shapes-content";
import { ICON_SETTINGS, ICON_SCRIPT, ICON_USER, ICON_MAIL } from "@isf/common-resources";

export const getShapeParams = (props) => {
  const stencilId = props.stencil.id;
  const isActive = props.isActive;
  const styleDefault = {
    height: props.bounds.lowerRight.y - props.bounds.upperLeft.y,
    width: props.bounds.lowerRight.x - props.bounds.upperLeft.x,
    left: props.bounds.upperLeft.x,
    top: props.bounds.upperLeft.y,
    // backgroundColor: props.schemaDataStore.isExecuted(props.resourceId)?"#c0f0cd":"white",
     backgroundColor:"white",
    borderRadius: "10px",
    position: "absolute",
    boxShadow:" 7px 7px 5px rgba(0,0,0,0.3)",
    display: "block",
  };
  const borderColor = props.schemaDataStore.isExecuted(props.resourceId)?"#1d8a3a" : props.schemaDataStore.isActivityActive(props.resourceId)? "red":"black";
  switch (stencilId) {
    case "EndNoneEvent": {
      return (
        {
          styles: {
            ...styleDefault,
            border:"3px solid "+borderColor ,
            borderRadius: "50%",
          },
          content: <ContentRoundShape {...props}/>
        }
      );
    }
      case "EndTerminateEvent": {
          return (
              {
                  styles: {
                      ...styleDefault,
                      boxShadow: "0px 0px 0px 3px " + borderColor + " inset",
                      borderRadius: "50%",
                  },
                  content: <TerminateEndEventContent {...props} styleDefault={styleDefault} borderColor={borderColor}/>
              }
          );
      }
    case "StartNoneEvent": {
      return (
        {
          styles: {
            ...styleDefault,
            border:"1px solid "+borderColor ,
            borderRadius: "50%",
          },
          content: <ContentRoundShape {...props}/>
        }
      );
    }
    case "ScriptTask": {
      return (
        {
          styles: {
            ...styleDefault,
            border:"1px solid "+borderColor ,
          },
          content: <ScriptTaskContent {...props}/>,
          icon:ICON_SCRIPT
        }
      );
    }
    case "UserTask": {
      return (
        {
          styles: {
            ...styleDefault,
            border:"1px solid "+borderColor ,
          },
          content: <UserTaskContent {...props}/>,
          icon : ICON_USER
        }
      );
    }
    case "ReceiveTask": {
      return (
        {
          styles: {
            ...styleDefault,
            border:"1px solid "+borderColor ,
          },
          content: <ReceiveTaskContent {...props}/>,
          icon : ICON_MAIL
        }
      );
    }
    case "ServiceTask": {
      return (
        {
          styles: {
            ...styleDefault,
            border:"1px solid "+borderColor ,
          },
          content: <ServiceTaskContent {...props}/>,
          icon: ICON_SETTINGS
        }
      );
    }
    case "SubProcess": {
      return (
        {
          styles: {
            ...styleDefault,
            border:"2px solid "+borderColor ,
          },
          content: <SubProcessContent {...props}/>
        }
      );
    }
    case "ParallelGateway": {
      const {backgroundColor,boxShadow,...other} = styleDefault;
      return (
        {
          styles: {
            ...other,
          },
          content: <ParallelGatewayContent {...props} styleDefault={styleDefault} borderColor={borderColor}/>
        }
      )
    }
    case "ExclusiveGateway": {
      const {backgroundColor,boxShadow, ...other} = styleDefault;
      return (
        {
          styles: {
            ...other,
          },
          content: <ExclusiveGatewayContent {...props} styleDefault={styleDefault} borderColor={borderColor}/>
        }
      )
    }
    case "InclusiveGateway": {
      const {backgroundColor,boxShadow, ...other} = styleDefault;
      return (
        {
          styles: {
            ...other,
          },
          content: <InclusiveGatewayContent {...props} styleDefault={styleDefault} borderColor={borderColor}/>
        }
      )
    }
    case "BoundaryTimerEvent": {
      return (
        {
            styles: {
                ...styleDefault,
                boxShadow: "0px 0px 0px 1px " + borderColor + " inset",
                borderRadius: "50%",
            },
          content: <BoundaryTimerEventContent {...props} styleDefault={styleDefault} borderColor={ borderColor }/>
        }
      );
    }
      case "BoundaryErrorEvent": {
          return (
              {
                  styles: {
                      ...styleDefault,
                      boxShadow: "0px 0px 0px 1px " + borderColor + " inset",
                      borderRadius: "50%",
                  },
                  content: <BoundaryErrorEventContent {...props} styleDefault={styleDefault} borderColor={borderColor}/>
              }
          );
      }
      case "CatchMessageEvent":
      case "BoundaryMessageEvent": {
          return (
              {
                  styles: {
                      ...styleDefault,
                      boxShadow: "0px 0px 0px 1px " + borderColor + " inset",
                      borderRadius: "50%",
                  },
                  content: <MessageEventContent {...props} styleDefault={styleDefault} borderColor={borderColor}/>
              }
          );
      }
      case "BoundaryCancelEvent": {
          return (
              {
                  styles: {
                      ...styleDefault,
                      boxShadow: "0px 0px 0px 1px " + borderColor + " inset",
                      borderRadius: "50%",
                  },
                  content: <BoundaryCancelEventContent {...props} styleDefault={styleDefault} borderColor={borderColor}/>
              }
          );
      }
      case "BoundaryCompensationEvent": {
          return (
              {
                  styles: {
                      ...styleDefault,
                      boxShadow: "0px 0px 0px 1px " + borderColor + " inset",
                      borderRadius: "50%",
                  },
                  content: <CompensationEventContent {...props} styleDefault={styleDefault} borderColor={borderColor}/>
              }
          );
      }
      case "BoundarySignalEvent": {
          return (
              {
                  styles: {
                      ...styleDefault,
                      boxShadow: "0px 0px 0px 1px " + borderColor + " inset",
                      borderRadius: "50%",
                  },
                  content: <BoundarySignalEventContent {...props} styleDefault={styleDefault} borderColor={borderColor}/>
              }
          );
      }
      case "CatchTimerEvent": {
          return (
              {
                  styles: {
                      ...styleDefault,
                      boxShadow: "0px 0px 0px 1px " + borderColor + " inset",
                      borderRadius: "50%",
                  },
                  content: <CatchTimerEventContent {...props} styleDefault={styleDefault} borderColor={borderColor}/>
              }
          );
      }
      case "ThrowSignalEvent": {
          return (
              {
                  styles: {
                      ...styleDefault,
                      boxShadow: "0px 0px 0px 1px " + borderColor + " inset",
                      borderRadius: "50%",
                  },
                  content: <ThrowSignalEventContent {...props} styleDefault={styleDefault} borderColor={borderColor}/>
              }
          );
      }
      case "CatchSignalEvent": {
          return (
              {
                  styles: {
                      ...styleDefault,
                      boxShadow: "0px 0px 0px 1px " + borderColor + " inset",
                      borderRadius: "50%",
                  },
                  content: <CatchSignalEventContent {...props} styleDefault={styleDefault} borderColor={borderColor}/>
              }
          );
      }
      case "ThrowNoneEvent": {
          return (
              {
                  styles: {
                      ...styleDefault,
                      boxShadow: "0px 0px 0px 1px " + borderColor + " inset",
                      borderRadius: "50%",
                  },
                  // content: <CompensationEventContent {...props} styleDefault={styleDefault} borderColor={borderColor}/>
                  content: isCompensation(props) ?
                      <CompensationThrowingEventContent {...props} styleDefault={styleDefault} borderColor={borderColor}/> :
                      <NoneThrowingEventContent {...props} styleDefault={styleDefault} borderColor={borderColor}/>
              }
          );
      }
      default: {
          return (
              {
                  styles: {...styleDefault}
              }
          )
      }
  }
};

const isCompensation = (props) => {
    const resourceId = props.resourceId;
    return (resourceId.indexOf('compensationintermediatethrowevent')!==-1)
};