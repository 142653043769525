import React from "react";
import PropTypes from "prop-types";
import { ToggleButton } from "../toggle";

const ModalDeleteButton = ({children, ...other}) => {
   return (
      <ToggleButton data-button-type="delete" color="danger" {...other}>
         {children ? children : 'Удалить'}
      </ToggleButton>
   );
};

ModalDeleteButton.propsTypes = PropTypes.instanceOf(ToggleButton);

export { ModalDeleteButton };