import RestErrorStore from './rest-error-store';

class RestHandler {

  handleResponse = ({ request, response, api }, context, responseHandler) => {
    // if (response.ok) {
      const body = this.readBody(response);
      // console.log('handleResponse body', body);
    if(responseHandler)
      return responseHandler(context, response, body);
    else{
      return body
    }

      //
    // }
    // this.handleResponseError({ request, response, api });
    // throw new Error('api call failed for ' + request.path);
    // return undefined;
  }

  handleResponseError = ({ request, response, api }) => {
    // if (response.status === 401) {
    //   return this.handleUnauthorizedError({ request, response, api });
    // }
    // throw new Error( response.statusText );
    console.log(response)
    return response;
    // return {
    //   status: response.status,
    //   statusText: response.statusText,
    //   body: await readBody(response)
    // };
  }

  handleUnauthorizedError = ({ response }) => {
    // console.log('handleResponseError: response',response);
  }

  handleRequestError = async (request, e ) => {
    console.error('Request failed with error:', e, ', request', request);
    return {};
  }

  /**
   * Handles HTTP status 500 - Server error
   * @param {*} response
   */
  handleResponseServerError = ({ response }) => {
    console.log('Status 500', response);
  }

  /**
   * Handles HTTP status 401 - Server error
   * @param {*} response
   */
  handleResponseServerError = ({ response }) => {
    console.log('Status 401', response);
  }

  /**
   * Handles HTTP status 422 - Unprocessable entity
   * @param {*} response
   */
  handleResponseUnprocessableEntity = ({ response }) => {
    console.log('Status 422', response);
  }

  readBody = (response) => readBody(response);

}

const readBody = async (response) => {
  if (isJsonBody(response)) {
    return await response.json();
  } else if (isTextBody(response)) {
    return await response.text();
  } else if (isNotJsonBody(response)) {   //For file
    return await response.blob();
  } else if(isWithoutContentType(response)) {
    try {
      const body = await response.text();
      return body && JSON.parse(body);
    } catch (e) {
      console.log('Unsupported body type', response);
    }

  } else {
    console.log('Unsupported body type',response);
    // throw new Error('Unsupported body type');
  }
}

const isJsonBody = (response) => {
  const contentType = getContentType(response);
  return contentType && contentType.startsWith('application/json');
};

/**
 * Use for file (all response will be download)
 * @param response
 * @returns {*}
 */
const isNotJsonBody = (response) => {
  const contentType = getContentType(response);
  return contentType && !contentType.startsWith('application/json');
};

const isTextBody = (response) => {
  const contentType = getContentType(response);
  return contentType && (
    contentType.startsWith('text/plain')
    || contentType.startsWith('text/html')
    || (response.type.startsWith('basic')
      && !contentType.startsWith('application/pdf')
      && !contentType.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    )
  );
};

const isWithoutContentType = (response) => {
  const contentType = getContentType(response);
  return !contentType;
};

const getContentType = (response) => {
  return response.headers.get('content-type')
};

export default RestHandler;