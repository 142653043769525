import React, {Component, Fragment} from "react";
import {observer} from "mobx-react";
import {observable, action, computed} from "mobx";
import $ from "@isf/core-object-util";
import {
  Navbar, NavItem, NavLink, SectionContainer, SectionContent, Sticky, NavbarStore, ModalOKButton,
  useInputHandler, resolveStore, FormField, ModalContainer, ModalCancelButton, ModalHeader, ModalBody,
  ModalFooter, ToggleStore, FormattedMessage
} from "@isf/common";
import {Col, Row} from '@isf/bootstrap';
import {TreeView} from "@isf/tree";
import {DataStore} from "@isf/core-app-store";
import {layout, getMappingObj, getMappingObjChild, mappingFunction, ModelOutlineWrapper, requestProps, isLastPropMapping} from "./config-constants"
import {getSchemaOpenApiChild, getSchemaOpenApiChildItems, observableForEach} from "./open-api-utils";
import {isLocalizedField} from "./localization-field";
import {PrimitiveMapping} from "./config-mapping-components/primitive-mapping";
import { localeStore } from "@isf/core-localization";

@observer
class ConfigRequestAndResponse extends Component {
  constructor(props) {
    super(props);

    this.store = props.store;
    this.configNavStore = new NavbarStore({
      path: ['configOperationNavStore'],
      state: {
        active: {
          configRequest: true
        }
      }
    });
  }


  render() {
    const { configNavStore, store } = this;
    const { request, response, intl, id, jsonProps,
      requestProps: reqProps, responseProps: resProps, configVariablesProps: newVarProps,
      showNewVariable = true, showResponse = true, ...other } = this.props;

    const { mappingProps: reqMap, treeProps: reqTree, title: reqNavTitle, ...otherReqProps } = reqProps || {};
    const { mappingProps: resMap, treeProps: resTree, title: resNavTitle, ...otherResProps } = resProps || {};
    const { mappingProps: newVarMap, treeProps: newVarTree, title: newVarNavTitle,...otherNewVarProps } = newVarProps || {};

    return (
      <Fragment>
        {/*<Sticky>*/}
        {/*  <Navbar id="ConfigRequestAndResponse" pills>*/}
        {/*    <NavItem>*/}
        {/*      <span>*/}
        {/*        <NavLink href="#" navFor="configRequest" navStore={configNavStore}>*/}
        {/*          {reqNavTitle || intl.formatMessage({ id: "ui.modal.formRequestTab" })}*/}
        {/*        </NavLink>*/}
        {/*      </span>*/}
        {/*    </NavItem>*/}
        {/*  </Navbar>*/}
        {/*</Sticky>*/}
        <Row>
          <Col>
            {/*<SectionContainer>*/}
            {/*  <SectionContent name="configRequest" navStore={configNavStore}>*/}
                <ConfigTreeView
                  {...other}
                  intl={intl}
                  {...requestProps.otherProps}
                  {...otherReqProps}
                  treeProps={{
                    ...requestProps.treeProps,
                    ...reqTree
                  }}
                  mappingProps={{
                    ...requestProps.mappingProps,
                    ...reqMap
                  }}
                  layout={layout}
                />
            {/*  </SectionContent>*/}
            {/*</SectionContainer>*/}
           </Col>
        </Row>
      </Fragment>
    );
  }

}


@observer
class ConfigTreeView extends Component {

  constructor(props) {
    super(props);
    this.store = props.store;
    this.path = `${props.requestOrResponse}.configTreeView.configModalTree`;

    this.mappingStore = this.store.mappingStore;
    this.toggleStore = new ToggleStore({ path: this.path });
    this.additionalToggleStore = resolveStore({ id: this.path + ".additionalProperties", type: ToggleStore });
    this.additionalDataStore = new DataStore({
      path: this.path + ".additionalProperties.dataStore",
      state: {},
      observableAsMap: true
    });

    this.actionTree = {
      obj: {
      },
      array: {
        value: {
          plus: {func: this.addArrayValue, disabled: false},
          clear: {func: this.clearArrayValue, disabled: false},
          minus: {func: this.removeArrayValue, disabled: false}
        }
      },
      primitive: {
        edit: {func: this.setElement, disabled: false},
        clear: {func: this.clearValue, disabled: false},
      },
      additionalProperties: {
        value: {
          plus: {func: this.addAdditionalValue, disabled: this.disabledAdditionalValue},
          clear: {func: this.clearAdditionalValue, disabled: false},
        }
      }
    };
  }

  @action
  setTypeSelectedElement(path, type) {
    const { dataStore } = this.store;
    dataStore.set('mapping.select', { path, type })
  }

  @action
  addArrayValue = (requestOrResponse, path) => {
    const { dataName } = this.props.treeProps;
    const {mappingStore} = this.store;
    mappingStore.addArrayValue(requestOrResponse, this.store[dataName], path);
  };

  @action
  addAdditionalValue = (requestOrResponse, path) => {
    const {additionalDataStore, additionalToggleStore} = this;
    if(this.disabledAdditionalValue(requestOrResponse, path)) return;
    additionalDataStore.setState({
      requestOrResponse: requestOrResponse,
      path: path,
    });
    additionalToggleStore.toggle();
  };

  disabledAdditionalValue = (requestOrResponse, path) => {
    const { dataName } = this.props.treeProps;
    let schema = this.store[dataName];
    const arrPath = $.strToArray(path);
    schema = getSchemaOpenApiChildItems(schema, arrPath.slice(0, -1));
    const mappingSchema = this.store.mappingStore.get([requestOrResponse, ...arrPath]) || {};
    const names = $.keys(mappingSchema);
    const maxProperties = $.get(schema, "maxProperties");
    const intMax = parseFloat(maxProperties),
      isInt = !isNaN(maxProperties) && (intMax | 0) === intMax;

    return !((isInt && +maxProperties > names.length) || maxProperties === undefined);
  };

  @action
  removeArrayValue = (requestOrResponse, path) => {
    const item = this.mappingStore.get([requestOrResponse, ...path]);
    const array = this.mappingStore.get([requestOrResponse, ...path.slice(0, -1)]);
    array.remove(item);

    let newPath = this.transformPath(path);
    const itemBody = this.mappingStore.get([`${requestOrResponse}Body`, ...newPath]);
    const arrayBody = this.mappingStore.get([`${requestOrResponse}Body`, ...newPath.slice(0, -1)]);
    if (arrayBody) arrayBody.remove(itemBody);

    this.removeProp([`${requestOrResponse}Body`, ...newPath.slice(0, -1)]);
  };

  transformPath = path => {
    let newPath = path;
    const indexAP = path.indexOf("additionalProperties");
    if (indexAP !== -1) {
      newPath = [...path.slice(0, indexAP), ...path.slice(indexAP + 1)];
    }

    const indexArr = newPath.indexOf("items");
    if (indexArr !== -1) {
      newPath = [...newPath.slice(0, indexArr), ...newPath.slice(indexArr + 1)];
    }

    return newPath;
  }

  removeProp = (path) => {
    const data = this.mappingStore.get(path);
    if ($.isArray(data) && data.length === 0) {
      const obj = this.mappingStore.get([...path.slice(0, - 1)]);
      $.remove(obj, path[path.length - 1]);
      this.removeProp([...path.slice(0, -1)])
    } else if ($.isObject(data) && !$.isArray(data)) {
      let count = 0;
      observableForEach(data, (value, key) => {
        if (value !== undefined) count++;
      });

      if (count === 0) {
        if (path[path.length - 1] === 'body'
          && (path[path.length - 2] === 'requestBody' || path[path.length - 2] === 'responseBody')) {
          this.mappingStore.set(path, undefined);
          return;
        }

        const obj = this.mappingStore.get([...path.slice(0, - 1)]);
        $.remove(obj, path[path.length - 1]);
        this.removeProp([...path.slice(0, -1)]);
      }
    }
  }

  @action
  removeNewVariable = (requestOrResponse, path) => {
    this.mappingStore.remove([requestOrResponse, ...path]);

    if (requestOrResponse === "response") {
      const { mappingProps } = this.props;
      this.store.dataStore.remove(["responsesData", "schema", ...path]);
      this.store.documentRequestTreeStore.setState({
        expanded: [],
        selected: []
      });
    }

    if (requestOrResponse === "configVariables") {
      this.store.dataStore.remove(["variablesData", "schema", ...path]);
      this.mappingStore.remove(["response", ...path]);
      this.store.documentRequestTreeStore.setState({
        expanded: [],
        selected: []
      });
    }
  };


  @action
  clearObject = (requestOrResponse, path) => {
    const obj = this.mappingStore.get([requestOrResponse, ...path]);

    observableForEach(obj, (value, key) => {
      const newPath = [...path, key];
      if ($.isArray($.get(value, "source"))) {
        this.clearSource(requestOrResponse, newPath);
      }

      if (typeof $.get(value, "value") === "string" || typeof $.get(value, "value") === "number"
          || typeof $.get(value, "value") === "boolean") {
        this.clearValue(requestOrResponse, newPath);
      }

      if ($.isArray($.get(value, "items"))) {
        this.clearArrayValue(requestOrResponse, [...newPath, "items"]);
      }

      if (typeof value === "object") {
        this.clearObject(requestOrResponse, newPath);
      }
    })
  };

  @action
  clearSource = (requestOrResponse, path) => {
    this.mappingStore.set([requestOrResponse, ...path, "source"], undefined);
  };

  @action
  clearValue = (requestOrResponse, path) => {
    this.mappingStore.set([requestOrResponse, ...path], { value: undefined });

    let newPath = this.transformPath(path);
    this.mappingStore.set([`${requestOrResponse}Body`, ...newPath], undefined);
    this.removeProp([`${requestOrResponse}Body`, ...newPath.slice(0, -1)]);
  };

  @action
  clearArrayValue = (requestOrResponse, path) => {
    this.mappingStore.set([requestOrResponse, ...path], []);

    let newPath = this.transformPath(path);
    this.mappingStore.set([`${requestOrResponse}Body`, ...newPath], undefined);
    this.removeProp([`${requestOrResponse}Body`, ...newPath.slice(0, -1)]);
  };

  @action
  clearAdditionalValue = (requestOrResponse, path) => {
    this.mappingStore.set([requestOrResponse, ...path], {});

    let newPath = this.transformPath(path);
    this.mappingStore.set([`${requestOrResponse}Body`, ...newPath], undefined);
    this.removeProp([`${requestOrResponse}Body`, ...newPath.slice(0, -1)]);
  };

  @action
  setElement = (requestOrResponse, path, type) => {
    this.setTypeSelectedElement(path, type);
    this.toggleStore.toggle();
  };

  isItems = (path) => {
    const { dataName } = this.props.treeProps;
    const lastItems = path[path.length - 1];

    if (isNaN(parseInt(lastItems))) return false;

    const schema = getMappingObjChild(this.store[dataName], path.slice(0, -2));
    return $.get(schema, "type") === "array";
  };

  render() {
    const { intl, treeProps, customComponent, height="50vh", resolveAction = defaultResolveAction, ...other } = this.props;
    const { storeName, dataName, treeModelName, title, height: treeHeight, ...otherTreeProps } = treeProps;
    const context = {
      data: this.store[dataName]
    };

    const Component = customComponent ? customComponent : () => null;
    const TitleTag = title.tag || "h5";

    return (
      <Fragment>
        <div className={'p-2 d-flex flex-column'} style={{ height }}>
          <ModelOutlineWrapper className="flex-grow-1" height={treeHeight}>
            <TitleTag {...title.props} >{intl.formatMessage({
              id: title.id,
              value: title.value,
              defaultMessage: localeStore.getMessageFromLocale(title.id, "ru")
            })}</TitleTag>
            <TreeView
              store={this.store[storeName]}
              data={this.store[treeModelName]}
              noRootNode
              addAction={this.actionTree}
              isItems={this.isItems}
              resolveAction={resolveAction}
              requestOrResponse={this.props.requestOrResponse}
              intl={this.props.intl}
              context={context}
              {...otherTreeProps}
            />
            <ConfigAdditionalProperties
              intl={intl}
              treeProps={treeProps}
              configStore={this.store}
              layout={this.props.layout}
              dataStore={this.additionalDataStore}
              toggleStore={this.additionalToggleStore}
            />
          </ModelOutlineWrapper>
          <Component
            setElement={this.setElement}
            {...this.props}
          />
        </div>
        <ConfigSubModalTree
          intl={intl}
          path={this.path}
          store={this.store}
          treeProps={{ dataName }}
          toggleStore={this.toggleStore}
          mappingFunction={mappingFunction}
          {...other}
        />
      </Fragment>
    );
  }
}

@observer
class ConfigAdditionalProperties extends Component {
  constructor(props) {
    super(props);
    const {path, dataStore, toggleStore, configStore} = props;

    this.path = configStore.path.join('.') + ".additionalProperties";
    this.toggleStore = toggleStore;
    this.dataStore = dataStore;
    this.useHandler = useInputHandler({store: dataStore});
  }

  id = (id) => {
    return this.path + "." + id
  };

  @computed
  get okDisabled() {
    const {dataStore} = this;
    return !dataStore.get("propsName");
  }

  @action
  clearComponent() {
    this.dataStore.setState({});
  }

  @action
  createProperty() {
    const {props, dataStore} = this;
    const {treeProps, configStore} = props;
    const {dataName} = treeProps;
    const propsName = dataStore.get("propsName"),
      requestOrResponse = dataStore.get("requestOrResponse"),
      path = dataStore.get("path");

    configStore.mappingStore.addAdditionalValue(requestOrResponse, configStore[dataName], path, propsName);
  }

  @action
  toggle = (props) => {
    const buttonType = props['data-button-type'];

    if (buttonType !== 'ok') {
      this.clearComponent();
      this.toggleStore.set('active',false);
      return true;
    }

    this.createProperty();
    this.clearComponent();
    return true;
  };

  render() {
    const {id, props, toggle, toggleStore, useHandler} = this;
    const {intl, layout, size = "lg"} = props;
    const okDisabled = this.okDisabled;

    return (
      <ModalContainer
        size={size}
        toggle={toggle}
        opener={<Fragment />}
        toggleStore={toggleStore}
      >
        <ModalHeader>
          <p className="h4">
            {intl.formatMessage({
              id: "ui.modal.config.additionalProperties.add",
              defaultMessage: localeStore.getMessageFromLocale("ui.modal.config.additionalProperties.add", "ru")
            })}
          </p>
        </ModalHeader>
        <ModalBody>
          <FormField
            type="input"
            layout={layout}
            id={id('propsName')}
            handler={useHandler('propsName')}
            label={intl.formatMessage({
              id: "ui.modal.config.additionalProperties",
              defaultMessage: localeStore.getMessageFromLocale("ui.modal.config.additionalProperties", "ru")
            })}
          />
        </ModalBody>
        <ModalFooter>
          <ModalOKButton disabled={okDisabled} {...layout.button.ok}>
            <FormattedMessage id={"ui.modal.apply"} />
          </ModalOKButton>
          <ModalCancelButton {...layout.button.cancel} >
            <FormattedMessage id={"ui.modal.cancel"} />
          </ModalCancelButton>
        </ModalFooter>
      </ModalContainer>
    );
  }
}

@observer
class ConfigSubModalTree extends Component {

  @observable warningDisabledFlag = false;

  constructor(props) {
    super(props);

    this.store = props.store;
    this.propsNavStore = new NavbarStore({
      path: ['configSubModal', 'NavStore'],
      state: {
        active: {
          addValue: true
        }
      }
    });

    this.valueStore = new DataStore({
      path: ['configSubModal', 'DataStore'],
      state: {
        value: undefined,
        expression: undefined
      },
      observableAsMap: true
    });

    this.toggle = this.toggle.bind(this);
    this.openToggle = this.openToggle.bind(this);
  }

  @action
  warningDisabled = (flag) => {
    return;
    const type = this.store.dataStore.get('mapping.select.type');
    if (type === "array" || type === "primitive") {
      this.warningDisabledFlag = flag;
      return
    }

    this.warningDisabledFlag = false;
  };

  @action
  closeClick = () => {
    const {store, propsNavStore} = this;
    const {mappingProps} = this.props;
    propsNavStore.setActive('addValue', true);
    store[mappingProps.storeName].set("selected", []);
    this.valueStore.setState({
      value: undefined,
      expression: undefined
    });
  };

  changeSelectedPath(path) {
    if (!$.isArray(path)) return path;
    const {store} = this;
    const {dataStore} = store;
    const newPath = [];

    path.forEach((item, index) => {
      let value = item;
      if (item === "items") {
        value = dataStore.get(`itemsIndex.${path.slice(0, index + 1)}`) || 0;
      }
      newPath.push(value);
    });
    return newPath;
  }

  @action
  onClick = () => {
    const { treeProps, mappingProps, mappingFunction, toggleStore, requestOrResponse } = this.props;
    const { dataStore, mappingStore } = this.store;

    const selected = this.store.getTreeSelectedData(this.store[mappingProps.storeName]);
    const selectedWithoutItems = mappingProps["useIndex"] ? this.changeSelectedPath(selected) : selected;
    const destPath = dataStore.get('mapping.select.path');
    const type = dataStore.get('mapping.select.type');
    const mappingSettings = dataStore.get(["mapping", "additionalSetting"]);

    if (mappingProps.customConfigFunc) mappingProps.customConfigFunc(selected);

    if (type === "newSchema") {//TODO remove not use
      const schema = getSchemaOpenApiChildItems(this.store[mappingProps.dataName], selected);

      if (requestOrResponse === "request") {
        this.store.setProcessVariablesRequest("properties." + destPath, schema);
      } else if (requestOrResponse === "response") {
        this.store.setProcessVariablesResponse("properties." + destPath, schema);
      }

      mappingStore[mappingFunction[type]](requestOrResponse, destPath, getMappingObj(schema));
    }

    if (type === "variable") {
      mappingStore[mappingFunction[type]](requestOrResponse, destPath, selectedWithoutItems, "source", mappingSettings);
      mappingStore[mappingFunction[type]](requestOrResponse, destPath, "variable", "type");
    }

    if (type === "primitive") {
      if (this.propsNavStore.isActive('addMapping')) {
        mappingStore[mappingFunction[type]](requestOrResponse, destPath, selectedWithoutItems, "source", mappingSettings);

      } else {
        let value = this.valueStore.get('value');
        try {
          if (this.propsNavStore.isActive('expression')) {
            value = eval(this.valueStore.get("expression"));
            if (value === undefined || (!$.isArray(value) && $.isObject(value))) return;
          }

        } catch (e) {
          return;
        }
        mappingStore[mappingFunction[type]](requestOrResponse, destPath, value, "value");
      }
    }

    if (type === "array" || type === "localizeField") {
      mappingStore[mappingFunction[type]](requestOrResponse, destPath, selectedWithoutItems);
    }

    if (type === "object") {
      const schema = getSchemaOpenApiChildItems(this.store[mappingProps.dataName], selected);
      const destSchema = getMappingObjChild(this.store[treeProps.dataName], destPath);

      mappingStore[mappingFunction[type]](requestOrResponse, destPath, destSchema, selectedWithoutItems, schema);
    }
  };

  setSettingsValue(mapping) {
    const {dataStore} = this.store;
    const settings = $.get(mapping, ["settings"]);

    const isBase64File = $.get(settings, ["type"]) === "file_d" && $.get(settings, ["format"]) === "base64";
    const isLinkFile = $.get(settings, ["type"]) === "file_d" && $.get(settings, ["format"]) === "link";
    const isTransient = $.get(settings, ["transient"]);
    const isLocal = $.get(settings, ["local"]);

    isBase64File && dataStore.set('mapping.additionalSetting.file_d.base64', true);
    isLinkFile && dataStore.set('mapping.additionalSetting.file_d.base64', true);
    isTransient && dataStore.set('mapping.additionalSetting.variable.transient', true);
    isLocal && dataStore.set('mapping.additionalSetting.variable.local', true);
  }


  @action
  openToggle() {
    const {store, props} = this;
    const {requestOrResponse} = props;
    const {dataStore, mappingStore} = store;

    let destPath = dataStore.get(["mapping", "select", "path"]);
    destPath = $.strToArray(destPath);
    const destType = dataStore.get(["mapping", "select", "type"]);
    const mapping = mappingStore.get([requestOrResponse, ...destPath]);

    dataStore.set("mapping.additionalSetting", $.objectToObservableMap({}));

    if(destType === "primitive") {
      const value = $.get(mapping, ["value"]);
      this.valueStore.set("value", value);
      value !== undefined && this.propsNavStore.setActive("addValue", true);
    }

    return true;
  }

  @action
  toggle(props) {
    const buttonType = props['data-button-type'];
    if (buttonType !== 'ok') {
      this.props.toggleStore.set('active',false);
      this.closeClick();
      return true;
    }

    this.onClick();
    this.closeClick();
    return true;
  }

  render() {
    const {props, toggle, openToggle, valueStore, propsNavStore} = this;
    const { size, toggleStore, layout } = props;
    let disabled;

    if (propsNavStore.isActive('addValue')) {
      disabled = valueStore.get('value') === undefined ;
    }

    return (
      <ModalContainer
        size={size || "xl"}
        opener={<Fragment />}
        toggleStore={toggleStore}
        toggle={toggle}
        openToggle={openToggle}
      >
        <ModalHeader onClick={this.closeClick}>
          <ConfigTreeViewHeader
            store={this.store}
            {...props}
          />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <ConfigSubModalBody
                store={this.store}
                warningDisabled={this.warningDisabled}
                propsNavStore={this.propsNavStore}
                valueStore={valueStore}
                {...props}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>

          <ModalOKButton disabled={disabled} {...layout.button.ok}>
            <FormattedMessage id={"ui.modal.apply"} />
          </ModalOKButton>
          <ModalCancelButton {...layout.button.cancel} >
            <FormattedMessage id={"ui.modal.cancel"} />
          </ModalCancelButton>
        </ModalFooter>
      </ModalContainer>
    );
  }
}

const ConfigTreeViewHeader = observer((props) => {
  const {intl, store, treeProps} = props;
  const {dataStore} = store;
  const schema = store[treeProps.dataName];

  const destPath = dataStore.get('mapping.select.path'),
    description = $.get(getSchemaOpenApiChild(schema, destPath), "description"),
    path = $.isArray(destPath) ? destPath.join('.') : destPath,
    name = destPath[destPath.length - 1];

  return (
    <Fragment>
      <p className="h4">
        <FormattedMessage id={"ui.modal.config.setMapping"} values={{name: name}}/>
      </p>
      {path && <p className="h6">{intl.formatMessage({
        id: "ui.modal.operation.path",
        defaultMessage: localeStore.getMessageFromLocale("ui.modal.operation.path", "ru")
      })} {path}</p>}
      {description && <p className="h6">{intl.formatMessage({
        id: "ui.modal.description",
        defaultMessage: localeStore.getMessageFromLocale("ui.modal.description", "ru")
      })} : {description}</p>}
    </Fragment>
  );
});

@observer
class ConfigSubModalBody extends Component {
  constructor(props) {
    super(props);

    this.store = props.store;
    this.useHandler = useInputHandler({store: this.store.dataStore});
  }

  render() {
    const { dataStore } = this.store;
    const { propsNavStore, intl, mappingProps, treeProps, layout, valueStore } = this.props;
    const { navValueDisabled } = mappingProps;
    const type = dataStore.get('mapping.select.type');
    const navDisabled = navValueDisabled || type !== "primitive";

    return (
      <Fragment>
        {/*<Sticky>*/}
        {/*  <Navbar id="configSubModalNav" pills>*/}
        {/*    <NavItem>*/}
        {/*      <span>*/}
        {/*        <NavLink href="#" navFor="addValue" navStore={propsNavStore} disabled={navDisabled}>*/}
        {/*          {intl.formatMessage({ id: "ui.modal.passValue" })}*/}
        {/*        </NavLink>*/}
        {/*      </span>*/}
        {/*    </NavItem>*/}
        {/*  </Navbar>*/}
        {/*</Sticky>*/}
        <Row>
          <Col>
            {/*<SectionContainer>*/}
            {/*  <SectionContent name="addValue" navStore={propsNavStore}>*/}
                <PrimitiveMapping
                  intl={intl}
                  layout={layout}
                  accessor="value"
                  treeProps={treeProps}
                  path={"configSubModal"}
                  valueStore={valueStore}
                  configStore={this.store}
                />
            {/*  </SectionContent>*/}
            {/*</SectionContainer>*/}
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const defaultResolveAction = (props, isArrayItems) => {
  const {addAction: actions, data, dataKey, path, context} = props;
  const action = {};

  const schema = getMappingObjChild(context.data, path);
  const isLocalized = isLocalizedField(schema);

  if(dataKey === "additionalProperties") {
    action.type = "additionalProperties";
    action.plus = actions.additionalProperties.value.plus;
    action.clear = actions.additionalProperties.value.clear;

  } else if(isLocalized) {
    action.type = "localizeField";
    action.edit = actions.additionalProperties.edit;
    action.clear = actions.additionalProperties.clear;
  }

  if (isArrayItems) {
    action.minus = actions.array.value.minus;
  }
  if ($.isArray(data)) {
    action.plus = actions.array.value.plus;
    action.clear = actions.array.value.clear;
  }
  if ($.isArray($.get(data, "items"))) {
    action.type = "array";
    action.edit = actions.array.edit;
    action.clear = actions.array.clear;
  }
  if ($.get(data, "type") === "variable") {
    action.type = "variable";
    action.edit = actions.newVariable.edit;
    if (!$.get(data, "isNoDelete")) {
      action.delete = actions.newVariable.delete;
    }
  }

  if (action.edit || action.plus) {
    return action;
  }
  if (action.action) {
    return action;
  }

  if(!isLastPropMapping(props)){
    action.type = "object";
    action.edit = actions.obj.edit;
    action.clear = actions.obj.clear;
  } else {
    action.type = "primitive";
    action.edit = actions.primitive.edit;
    action.clear = actions.primitive.clear
  }

  return action;
};

export {ConfigRequestAndResponse};
