import $ from "@isf/core-object-util/src/object-util";
import appStore, {routerStore} from "@isf/core-app-store";
import {storeTemplToPath} from "../util";
import {localeWrapper} from "../api-call-action/data-wrapper";

const getListData = (data) => {
  const {store: listStore, index: listIndex} = data;
  return listStore.data ? $.get(listStore.data, `${listIndex}`) : listStore.get(`${listIndex}`);
};

// const getStoreName = (defaultName, name, split = ".") => {
//   const p = defaultName.split(split);
//   const t = name.split(split);
//   for(let i = p.length - 1; i > 0; i++) {
//     if (p[i] === '{id}') {
//       t[i] = "new";
//       break;
//     }
//   }
//   return t.join(split);
// };

const getDataStore = (name, context) => {

  let store;

  if (name === "pageUrl") {

    if(context.refId){
      const globalRefsStore = appStore.getDataStore('globalRefsStore');
      const params = globalRefsStore.get('refs.params.' + context.refId);
      // if(params) {
        store = {
          get: (path) => $.get(params,path),
          set: (path,value) => $.set(params,path,value)
        }
      // }
      // else{
      //   store = routerStore;
      // }
    }else{
      store = routerStore;
    }
  } else if (name === "localeStore") {
    store = localeWrapper(appStore.getStore("localization"));

  } else if (name === "userStore") {
    store = appStore.getStore("user", "user");

  } else if (name === 'pageTemplateStore') {
    store = appStore.getStore('pageTemplate', 'ui');

    // } else if (storeName === "objectItemStore") {
    //     store = this.listData;

  } else {
    // let newStoreName = storeTemplToPath(name, '.');
    let newName = context.refId ? context.refId + "." + name : name;
    store = appStore.getDataStore(newName);
    // store = appStore.storeExists("data." +newStoreName)
    //   ? appStore.getDataStore(newStoreName)
    //   : appStore.getDataStore(getStoreName(name, newStoreName, '.'));
  }

  return store;
};

const getParamData = (props, pureJS) => {
  const {data, pathArray, storeName, context, extConfiguration, locale} = props;
  let value = '';

  if(data){
    const listData = getListData(data);
    value = $.get(listData, pathArray);
  } if(storeName) {
    const s = getDataStore(storeName, context); //appStore.getDataStore(storeTemplToPath(storeName, '.'))

    value = s && s.get(pathArray);
  }
  if (extConfiguration) {
    const selectedValueConfig = $.get(extConfiguration, 'selectedValue');
    const selectedPropertyConfig = $.get(extConfiguration, 'property');
    const selectedValuePathArray = $.get(selectedValueConfig, 'path') ? $.strToArray($.get(selectedValueConfig, 'path')) : [];
    $.get(selectedValueConfig, 'isLocalized') && selectedValuePathArray.push(locale);
    const selectedValue = getParamData({
      pathArray: selectedValuePathArray,
      storeName: $.get(selectedValueConfig, 'storeName'),
      context,
      locale
    });
    const optionsStore = getDataStore(storeName, context);
    const options = optionsStore.get(pathArray) || [];
    const propertyPathArr = $.get(selectedPropertyConfig, 'path') ? $.get(selectedPropertyConfig, 'path').split('.') : [];
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const searchedValue = $.get(option, [$.get(selectedPropertyConfig, 'storeName'), ...propertyPathArr]);
      if (searchedValue === selectedValue) {
        value = option;
        break;
      }
    }
  }

  // if (value == undefined) value = "";

  return pureJS
    ? JSON.stringify($.toJS(value, {recurseEverything: true}))
    : value;
};

const getDeclaredString = (params, localeStore, data, context={}) => {
  let declaredString = 'let ';
  const {locale} = localeStore;
  const refsLoadedStore = appStore.getStore('globalLoadedRefsStore', 'ui');
  /*const refStoreState = refsLoadedStore.stateJS;*///stateJS for rerun autorun on load ref component
  params.map(param => {
    let pathArray = param.path ? $.strToArray(param.path) : [];//param.path.split('.');
    const paramName = param.name;
    const refId = param.refId;
    const storeName = param.storeName;
    const extConfiguration = param.extConfiguration;
    param.isLocalized && pathArray.push(locale);
    let extendedContext = context;
    if(refId){
        extendedContext = {...context, refId:refId}
    }
    const value = getParamData({data, pathArray, storeName, context : extendedContext, extConfiguration, locale}, true);
    declaredString += paramName + " = " + value + " ,";
  });
  declaredString = declaredString.substr(0, declaredString.length - 1) + ';';
  return declaredString;
};

const getParamsValue = (params, localeStore, data, context={}) => {
  const {locale} = localeStore;
  const resultValuesArray=[];
  params.map(param => {
    let pathArray = param.path ? $.strToArray(param.path) : [];//param.path.split('.');
    const storeName = param.storeName;
    param.isLocalized && pathArray.push(locale);
    const value = getParamData({data, pathArray, storeName, context}, true);
    resultValuesArray.push(value);
  });
  return resultValuesArray;
};


const getString = (params, props, store) => {
  return getDeclaredString(params, store.localeStore, props.data, {refId: props.refId});
};

const NOT_COMPUTED = ["condition", "false"];
const NOT_HIDDEN = ["condition"];
const NOT_READ_ONLY = ["condition"];
const NOT_RESTRICTIONS = ["condition", "false"];
const NOT_COMPUTED_CLASS_NAME = ["condition", "false"];

export {
  getDataStore, getParamData, getListData, getDeclaredString, getParamsValue,
  getString,
  NOT_COMPUTED, NOT_HIDDEN, NOT_READ_ONLY, NOT_RESTRICTIONS, NOT_COMPUTED_CLASS_NAME
}
