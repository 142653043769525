import React from 'react';
import {observer} from 'mobx-react';
import styled from "styled-components";
import classNames from 'classnames';
import {injectIntl} from "@isf/core-localization";
import DiagramRenderedView from "./diagram-renderer";
import SchemaDataStore from "./stores/schema-data-store";
import {schemaConverterFromJSON} from './converters/converterFromJSON';
import {NewDiagramConf} from './new-diagram-conf';
import {getEnvVariable} from "../../core/system-util/src";
import {RestApi} from "../../core/rest/src";
import $ from "@isf/core-object-util";
import appStore from "@isf/core-app-store";
import {debugApi} from "./api/api";
import {computed} from "mobx";
import {ICON_CYCLE} from "@isf/common-resources";

const StyledDiagramDrawZone = observer(styled.div`
  width:100%;
  height:700px;
  position:relative;
  border: 1px solid darkgrey;
  overflow:auto;
`);


@observer
class DiagramView extends React.Component {
   constructor(props) {
      super(props);
      this.schemaDataStore = new SchemaDataStore({schema: $.objectToObservableMap(schemaConverterFromJSON(NewDiagramConf, new Map()))});
      loadBpmnDiagram(this.schemaDataStore, this.props.configurationId)
        .then(e => loadProcessStatuses(this.schemaDataStore, this.processInstanceId))
        .catch(error => console.error("ERROR loading bpmn diagram", error));
      this.oldProcessInstanceId = this.processInstanceId;
      if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
   }

   updateSchemas = () => {
      loadProcessStatuses(this.schemaDataStore, this.processInstanceId)
        .catch(error => console.error("ERROR loading bpmn diagram", error));
   };

   @computed
   get processInstanceId() {
      const {mapping} = this.props;
      if (mapping && mapping.params && mapping.params.length) {
         const param = mapping.params[0];
         const storeName = param.storeName === 'pageUrl'
           ? 'router'
           : param.storeName;
         const store = appStore.getStore(storeName) || appStore.getDataStore(param.storeName);
         const storeProcessId = store && store.get(`${param.path}`);
         if (storeProcessId)
            return storeProcessId;
      }
      const routerStoreState = appStore.routerStore.state;
      return $.get(routerStoreState, 'query.processInstanceId');
   }

   componentWillUnmount() {
      this.uiBaseStore && this.uiBaseStore.dispose();
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.oldProcessInstanceId !== this.processInstanceId) {
         this.updateSchemas();
         this.oldProcessInstanceId === this.processInstanceId;
      }
   }

   render() {
      const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
        && this.uiBaseStore.classNameStore.getClassNames();
      return (
         <div className={classNames(this.props.className, computedClassNames)}>
            <StyledDiagramDrawZone>
               <img src={ICON_CYCLE} className={"svg-icon cursor-pointer"} alt={"Cycle"}
                    style={{position: 'absolute', width: 30, height: 30, zIndex: 999}}
                    onClick={(e) => this.updateSchemas()}/>
                    <DiagramWrapper schemaDataStore={this.schemaDataStore} />
            </StyledDiagramDrawZone>
         </div>
      )
   }
}

const DiagramWrapper = observer(({schemaDataStore}) => {
   return (
     <DiagramRenderedView uiSchema={schemaDataStore.schema}
                          schemaDataStore={schemaDataStore}/>
   );
});

const loadProcessStatuses = async (schemaDataStore, processInstanceId) => {
   let schema = await getExecuted(processInstanceId);
   schema = $.objectToObservableMap(schema);
   schemaDataStore.setProcessStatuses(schema);
};

const loadBpmnDiagram = async (schemaDataStore, confId) => {
   const response = await getSchema(confId);
   if (response) {
      const bpmnDiagram = $.objectToObservableMap(response.businessProcess.bpmnDiagram);
      schemaDataStore.setSchema(bpmnDiagram || $.objectToObservableMap(schemaConverterFromJSON(NewDiagramConf, new Map())));
   }
};

const getSchema = async (confId) => {
   if (confId) {
      return await confApi.request({
         path: `/configuration/v1/${confId}`,
      })
         .then(response => {
            if (response.bp === 'Y') {
               return response
            }
            return undefined;
            // return schemaConverterFromJSON(response,new Map())
         })
   }
};

const getExecuted = async (processInstanceId) => {
   if (processInstanceId) {
      return await debugApi.request({
         path: `process-instance/${processInstanceId}/executions`,
      })
         .then(response => {
            return response.responseMap;
         })

   }
   return {};
};

const API_DEFAULT_CONF = {
   "schema": getEnvVariable('API_DEFAULT_SCHEMA'),
   "host": getEnvVariable('API_DEFAULT_HOST'),
   "port": getEnvVariable('API_DEFAULT_PORT'),
   "context": "api"
};

const confApi = new RestApi({...API_DEFAULT_CONF, ...{context: 'api/conf'}});
export {DiagramView};
