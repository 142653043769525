import $ from '@isf/core-object-util';

const getListIndex = (props) => {
  if (typeof props.data === "object" && props.data) {
    return props.data.index
  }
};

const ascListSort = (a, b, accessor, isLocalized, localeStore) => {
  if (isLocalized) {
    accessor = `${accessor}.${localeStore.locale}`;
  }
  const aValue = $.get(a, accessor);
  const bValue = $.get(b, accessor);

  if (aValue > bValue) {
    return 1;
  }
  if (aValue < bValue) {
    return -1;
  }

  return 0;
};

const descListSort = (a, b, accessor, isLocalized, localeStore) => {
  if (isLocalized) {
    accessor = `${accessor}.${localeStore.locale}`;
  }
  const aValue = $.get(a, accessor);
  const bValue = $.get(b, accessor);

  if (aValue < bValue) {
    return 1;
  }
  if (aValue > bValue) {
    return -1;
  }

  return 0;
};

export { getListIndex, ascListSort, descListSort }
