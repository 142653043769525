import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import { action, observable } from "mobx";
import classNames from "classnames";
import { injectIntl, FormattedMessage } from "@isf/localization";
import {ModalContainer, ModalBody, ModalFooter, ModalOKButton, ModalCancelButton} from "../../../modal";
import { Button } from "../../../form";
import { LoadSpinner } from "../../../load-spinner/load-spinner-view";
import { ToggleButton, ToggleStore } from "../../../toggle";
import { DataStore } from "@isf/core-app-store";
import { resolveStore } from "@isf/react-util";
import { ModalHeader, BodyContentArray, SelectService, SelectPathView, SelectParameterView } from "@isf/common-select-service";
import { ServiceOperationConfigStore } from "./service-operation-config-store";

@injectIntl
@observer
class ServiceOperationConfig extends Component {
  constructor(props) {
    super(props);
    this.path = props.path;

    this.configStore = new ServiceOperationConfigStore({
      setConf: (id, mapping) => this.setConfig(id, mapping, props.uiComponent, props.onLoad),
      selectedService: {
        service: null,
        operation: null
      }
    });

    this.dataStore = new DataStore({
      path: this.path + ".dataStore",
      state: {
        currentStep: 0
      },
      observableAsMap: true
    });

    this.toggleStore = props.toggleStore || resolveStore({id: this.path, type: ToggleStore});
    this.toggle = this.toggle.bind(this);
    this.openToggle = this.openToggle.bind(this);
  }

  @observable isLoading = false;

  @action
  setLoading() {
    this.isLoading = !this.isLoading;
  }

  @action
  clearStore() {
    this.dataStore.setState({
      currentStep: 0
    });
  }

  setConfig = (mapping) => {
    const {handler, accessor, dataContainer} = this.props;
    const {dataStore, mappingStore} = this.configStore;

    const selectService = dataStore.get("select.service");
    Object.entries(selectService.data).forEach(([key, value]) => {
      dataContainer.set(`service.${key}`, value);
    });

    let selectServicePath = selectService.path;
    if (mappingStore.stateJS.requestBody && mappingStore.stateJS.requestBody.path) {
      Object.entries(mappingStore.stateJS.requestBody.path).forEach(([key, value]) => {
        if (value) selectServicePath = selectServicePath.replace(`{${key}}`, value);
      })
    }

    dataContainer.set('operation.contextPath', selectServicePath);
    dataContainer.set('operation.method', selectService.method);
    if (mappingStore.stateJS.requestBody && mappingStore.stateJS.requestBody.body) {
      dataContainer.set('operation.body', mappingStore.stateJS.requestBody.body);
    } else dataContainer.set('operation.body', '');

    handler.set(`${accessor}.id`, selectService.id);
    handler.set(`${accessor}.contextPath`, selectService.path);
    handler.set(`${accessor}.method`, selectService.method);
    if (mapping && mapping.request && mapping.request.mappings) {
      handler.set(`${accessor}.body`, JSON.stringify(mapping.request.mappings));
    } else handler.set(`${accessor}.body`, null);
  };

  @action
  nextStep = async (selectFunc) => {
    this.setLoading();
    await selectFunc(
      () => this.setLoading()
    );
  };

  @action
  prevStep = () => {
    const currentStep = this.dataStore.get("currentStep");
    if (currentStep > 0) {
      this.dataStore.set("currentStep", currentStep - 1);
    }
  };

  async openToggle() {
    const {handler, accessor} = this.props;

    this.configStore.clearStore();
    this.configStore.clearServicesStores();
    this.clearStore();
    this.toggleStore.set('active', true);
    this.setLoading(true);

    await this.configStore.servicesResponseStore.load()
      .then(() => this.configStore.setCurrentData(handler.get(accessor)))
      .then(flag => {
          if (flag) this.dataStore.set("currentStep", steps.length - 1);
          else this.configStore.clearStore();
      })
      .catch(e => console.error("Error", e))
      .finally(() => this.setLoading(false));
  }

  toggle = (props) => {
    if (this.isLoading) return false;

    const buttonType = props["data-button-type"];
    if (buttonType !== "ok") {
      this.toggleStore.set("active", false);
      this.dataStore.set("currentStep", 0);
      return true;
    }

    const currentStep = this.dataStore.get("currentStep");
    const step = steps[currentStep];
    const selectFunc = this.configStore[step.func];
    this.nextStep(selectFunc)
      .finally(
        () => {
          if (this.dataStore.get("currentStep") < (steps.length - 1)) {
            this.dataStore.set("currentStep", currentStep + 1);
            return false;
          } else {
            this.toggleStore.set("active", false);
            return true;
          }
        }
      );
  };

  handleOpen(e) {
    e.stopPropagation();
  };

  render() {
    const { opener: propsOpener, openerContent, intl, modalDisabled, className } = this.props;

    const currentStep = this.dataStore.get("currentStep");
    const step = steps[currentStep];
    const disabledBack = this.isLoading || !currentStep;
    let disabledSelect = step.disabledFunc ? this.configStore[step.disabledFunc] : false;
    const isShowBack = currentStep !== 0;
    let backButtonLabel = <FormattedMessage id={"ui.modal.back"} />;
    if (currentStep === 1) backButtonLabel = <FormattedMessage id={"ui.modal.backToService"} />;
    if (currentStep === 2) backButtonLabel = <FormattedMessage id={"ui.modal.backToOperation"} />;
    disabledSelect = this.isLoading || disabledSelect;

    return (
      <ModalContainer size={"xl"}
                      opener={
                        <ToggleButton {...propsOpener}
                                      onClick={this.handleOpen}
                                      className={classNames("mb-0", className)}
                                      style={modalDisabled ? {pointerEvents: "none"} : {}}>
                          {openerContent}
                        </ToggleButton>
                      }
                      toggleStore={this.toggleStore}
                      openToggle={this.openToggle}
                      toggle={this.toggle}>
        <ModalHeader configStore={this.configStore}
                     intl={intl}
                     title={intl.formatMessage({id: "ui.serviceOperationConfig.header"})}>
        </ModalHeader>
        <ModalBody onClick={this.handleOpen}>
          <LoadSpinner isLoading={this.isLoading} />
          <BodyContentArray
            intl={intl}
            allSteps={steps}
            stepIndex={currentStep}
            useHandler={this.useHandler}
            configStore={this.configStore}
          />
        </ModalBody>
        <ModalFooter>
          {isShowBack && (
            <Button disabled={disabledBack} onClick={this.prevStep}>
              {backButtonLabel}
            </Button>
          )}
          <ModalOKButton disabled={disabledSelect}>
            {currentStep === steps.length - 1
              ? <FormattedMessage id={"ui.modal.apply"}/>
              : <FormattedMessage id={"ui.modal.next"}/>
            }
          </ModalOKButton>
          <ModalCancelButton color="primary" outline>
            <FormattedMessage id={"ui.modal.cancel"}/>
          </ModalCancelButton>
        </ModalFooter>
      </ModalContainer>
    )
  }
}

export { ServiceOperationConfig };

export const steps = [
  {header: "Services", component: SelectService, func: "selectService", disabledFunc: "serviceDisabled"},
  {header: "Paths", component: SelectPathView, func: "selectPathMethod", disabledFunc: "pathDisabled"},
  {header: 'Parameters', component: SelectParameterView, func: 'selectParameters'}
];