import React, {Component} from "react";
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import {Col, Row} from "react-bootstrap";
import {Container} from "reactstrap";
import $ from "@isf/core-object-util"
import {GIF_LOADING} from "@isf/common-resources";

@observer
class ObjectContainer extends Component {
   constructor(props) {
      super(props);
   }

   render() {
      const {children, style, dataStore} = this.props;
      return (
         <Container fluid style={{
            ...style,
            position: "relative",
            marginTop: '5px',
            marginBottom: '5px',
            paddingTop: '5px',
            paddingBottom: '5px'
         }}>
            <Loader dataStore={dataStore} children={children}/>
         </Container>
      );
   }
}

ObjectContainer.propTypes = {
   id: PropTypes.string,
};

const Loader = observer(({dataStore, children}) => {
   if (!dataStore)
      return children;
   else {
      // console.log(dataStore.get('isLoading'))
      const isLoader = dataStore.isLoading;
      if (isLoader) {
         return (
            <>
               <div
                  className={"d-flex justify-content-center align-items-center"}
                  style={{
                     position: "absolute",
                     top: 0,
                     left: 0,
                     right: 0,
                     bottom: 0,
                     backgroundColor: "grey",
                     zIndex: 1,
                     opacity: "0.15"
                  }}>
                  <img src={GIF_LOADING} alt={"...Loading"} style={{width: 50, height: 50}}/>
               </div>
               {children}
            </>
         )
      } else
         return <>{children}</>;
   }
});

export {ObjectContainer};