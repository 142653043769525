import {observer} from "mobx-react";
import React, {Component, Fragment} from "react";
import $ from "@isf/core-object-util";
import styled from "styled-components";
import {Input, StyledScrollbar, SwitchInput} from "@isf/common";
import {CustomInput} from "@isf/bootstrap";
import {getSchemaOpenApiProp, observableForEach, PRIMITIVES} from "../open-api/open-api-utils";
import {isLocalizedField} from "./localization-field";
import {ICON_ADD_COMPONENT, ICON_ADD_SQUARE, ICON_CROSS_CIRCLE, ICON_DELETE, ICON_MINUS_SQUARE} from "@isf/common-resources";

export const layout = {
  label: {
    props: {
      sm: 12,
      md: 6,
      lg: 5,
      size: 'sm'
    }
  },
  input: {
    layout: {
      col: {
        sm: 12,
        md: 6,
        lg: 7
      }
    },
    props: {
      bsSize: "sm"
    }
  },
  button: {
    ok: {
      size: "sm",
      color: "primary"
    },
    cancel: {
      size: "sm",
      color: "primary",
      outline: true
    },
    outline: {
      yes: {outline: true},
      no: {outline: false}
    }
  }
};

export const getColumnsPath = ({formatMessage}) => [
  {
    type: "checkbox",
    accessor: 'checkbox',
    sortable: false,
    filterable: false,
    editable: false,
    width: 45
  }, {
    header: formatMessage({id: 'ui.modal.contextPath'}),//'Path',
    editable: false,
    accessor: 'path',
    filterable: true,
    sortable: true
  }, {
    header: formatMessage({id: 'ui.modal.description'}),//'Description',
    editable: false,
    accessor: 'description',
    filterable: true,
    sortable: true
  }, {
    header: formatMessage({id: 'ui.modal.method'}),//'Operation',
    editable: false,
    accessor: 'method',
    filterable: true,
    sortable: true
  }
];

const ADDITIONAL_NODE_NAME = ["additionalProperties", "items"];

export const getMappingObjChild = (data, newPath) => {
  let child = data, property;

  for (let i = 0, pathLength = newPath.length; i < pathLength; i++) {

    if(ADDITIONAL_NODE_NAME.includes(newPath[i])) {

      child = $.get(child, newPath[i]);
      i = i < pathLength - 1 ? (i + 1) : i;
      continue;
    }

    property = getSchemaOpenApiProp(child);

    if (property) {
      child = $.get(property, [newPath[i]]);
    }
  }
  return child;
};

export const defaultTreeStoreState = () => {
  return {
    defaultExpandDeep: [],
    expanded: [],
    selected: []
  };
};

export const getMappingObj = (schema) => {
  let mappingObj = getMappingObjByType(schema);
  if(mappingObj) return mappingObj;

  const mappingSchema = {};
  const prop = getSchemaOpenApiProp(schema);

  observableForEach(prop || schema, (value, key) => {

    mappingObj = getMappingObjByType(value);
    if(mappingObj) {
      mappingSchema[key] = mappingObj;

    } else if ($.isObject(value) && $.get(value, "properties")) {
      mappingSchema[key] = getMappingObj(value);
    }
  });
  return mappingSchema;
};

const getMappingObjByType = (schema) => {
  if(!schema) return schema;
  let type = $.get(schema, "type");

  if (PRIMITIVES.includes(type)) {
    return {
      "value": undefined,
    };
  } else if (type === "array") {
    return {
      "items": [],
      "source": undefined,
      "setMerge": false
    }
  } if(type === "object" && $.get(schema, "additionalProperties")) {
    return {"additionalProperties": {}};
  }

  let oneOf = $.get(schema, "oneOf");
  if(!type && $.isArray(oneOf)) {
    oneOf = oneOf.length > 0 ? oneOf[0] : undefined;
    return getMappingObjByType(oneOf);
  }
};

export const mappingFunction = {
  "primitive": "setPrimitive",
  "object": "setObjSource",
  "array": "setArraySource",
  "localizeField": "setArraySource",
  "variable": "setMappingValue",
  "newSchema": "setMappingValue",
};

export const ModelOutlineWrapper = styled(StyledScrollbar)`
  width: 100%;
  overflow: auto;
  backgroundColor: red; 
  height: ${props => props.height ? props.height : "20vh"};
`;

@observer
class ArraySetMerge extends Component{
  constructor(props) {
    super(props);
    this.get = this.get.bind(this);
    this.set = this.set.bind(this);
  }

  get(path) {
    const {data} = this.props;
    return $.get(data, path);
  }

  set(path, value) {
    const {data} = this.props;
    return $.set(data, path, value);
  }

  render() {
    const {props, get, set} = this;
    const {intl, id} = props;

    return (
      <Fragment>
        <span className={"mx-2"}>
          {intl.formatMessage({id: "ui.modal.mapping.setMerge"})}
        </span>
        <SwitchInput
          input={{
            props: {
              type: "switch",
              id: id +  ".switch.set.merge",
              invalid: false,
            }
          }}
          className={"d-inline-flex"}
          handler={{
            get: get,
            set: set
          }}
          accessor={"setMerge"}
        />
      </Fragment>
    );
  }
}

const StyledIcon = styled.span`
  display: inline;
  opacity: 0.5;
  padding-left: 4px;
  cursor: pointer;
  &:hover {
    opacity: ${disabled => disabled ? 0.5 : 1};
  }
`;

const LabelContentAction = observer(props => {
  const {
    Theme, data, dataKey, isBranchNode, addAction, resolveAction,
    requestOrResponse, isItems, setMerge, getLabel
  } = props;
  const action = resolveAction(props, isItems(props.path));

  let string = "";
  if ($.isArray($.get(data, "source"))) {
    const array = $.get(data, "source");
    string += " - " + `source : ${array.join(".")}`;
  }

  let value = $.get(data, "value");
  if (value !== undefined && (!$.isObject(value) || $.isArray(value))) { /*typeof value === "string" || $.isArray(value)*/
    value = $.isArray(value) ? $.toJS(value) : value;
    string += " - " + `value : ${JSON.stringify(value)}`;
  }

  const isNewVariable = $.get(data, "type") === "variable";
  const isDisabledConfig = $.get(data, "type") === "disabled";
  const remove = isNewVariable ? addAction.newVariable.remove : addAction.array.value.remove;
  const isArray = $.isArray($.get(data, "items")) && typeof $.get(data, "setMerge") === "boolean";

  const label = getLabel ? getLabel(props) : dataKey;

  if (isBranchNode(props)) {
    return (
      <Fragment>
        <Theme.leafKey>
          {label}
          {isDisabledConfig
            ? null
            : <Fragment>
              {action.edit
                ? <StyledIcon data-toggle="tooltip" title={props.intl.formatMessage({id: "ui.modal.config.edit"})} disabled={true}>
                  <img src={ICON_ADD_COMPONENT} onClick={() => action.edit.func(requestOrResponse, props.path, action.type)} style={{width: "16px"}}/>
                </StyledIcon>
                : null
              }
              {action.plus
                ? <StyledIcon
                  data-toggle="tooltip"
                  title={props.intl.formatMessage({id: "ui.modal.config.plus"})}
                  disabled={action.plus.disabled && action.plus.disabled(requestOrResponse, props.path)}
                >
                  <img src={ICON_ADD_SQUARE} className="icon-w-15 h-auto" onClick={() => action.plus.func(requestOrResponse, props.path)} />
                </StyledIcon>
                : null
              }
              {action.minus
                ? <StyledIcon data-toggle="tooltip" title={props.intl.formatMessage({id: "ui.modal.config.minus"})}>
                  <img src={ICON_MINUS_SQUARE}
                       onClick={() => action.minus.func(requestOrResponse, props.path)}
                       className="icon-w-15 h-auto" />
                </StyledIcon>
                : null
              }
              {action.clear
                ? <StyledIcon data-toggle="tooltip" title={props.intl.formatMessage({id: "ui.modal.config.clear"})}>
                  <img src={ICON_CROSS_CIRCLE} onClick={() => action.clear.func(requestOrResponse, props.path)}
                       style={{width: 13, height: 13}}/>
                </StyledIcon>
                : null
              }
              {action.delete
                ?
                <StyledIcon data-toggle="tooltip" title={props.intl.formatMessage({id: "ui.modal.config.delete"})}>
                  <img src={ICON_DELETE}
                       onClick={() => action.delete.func(requestOrResponse, props.path)}
                       className="icon-w-18 h-auto cursor-pointer" />
                </StyledIcon>
                : null
              }
            </Fragment>
          }
          {isArray && setMerge
          &&  <ArraySetMerge {...props}/>
          }
          {string}
        </Theme.leafKey>
      </Fragment>
    )
  }
  return (
    <Fragment>
      <Theme.leafKey>{dataKey}</Theme.leafKey>{' : '}
      <Theme.leafValue>
        {/* data === undefined
              ? ''
              :  */'' + data
        }
      </Theme.leafValue>
    </Fragment>
  )
});

const getArrayPath = (props, accessor) => {
  const {store} = props;
  const index = indexArrayPath(props, accessor);
  if (!~index) return;
  return store.get([accessor, `${index}`]);
};

export const Selector = observer((props) => {
  const {selection, handleSelectedChange, id} = props;
  const selected = getArrayPath(props, "selected") || {};
  const checked = $.get(selected, "value") || false;
  return (
    selection
      ? <CustomInput id={id}
                     type="checkbox"
                     onChange={() => handleSelectedChange(props)}
                     checked={checked}/>
      : null
  )
});

export const LeafNode = observer((props) => {
  return null;
  const {id, level, Theme, LeafNodeContent} = props;
  if (props.dataKey === "type" && typeof props.data === "string") return null;

  return (
    <Theme.li leaf id={'' + id + '.' + level}>
      <LeafNodeContent {...props} />
    </Theme.li>
  );
});

export const LabelContentItems = observer(props => {
  const {Theme, data, dataKey, isBranchNode, getLabel} = props;
  let label = $.get(data, 'description')
    ? `${dataKey} : ${$.get(data, 'description')}`
    : dataKey;

  if (getLabel) {
    label = getLabel(props);
  }

  if (isBranchNode(props)) {
    return (
      <Fragment>
        <Theme.leafKey>
          {dataKey === "items"
            ? <ItemsIndexComponent {...props} label={label}/>
            : label
          }
        </Theme.leafKey>
      </Fragment>
    )
  }
  return null;
});

const ItemsIndexComponent = observer(props => {
  const {Theme, context, path, label} = props;
  const {useHandler} = context;

  return (
    <Theme.leafKey /*onClick={() => handlePropertyValueClick(props)}*/>
      <div className={"d-flex align-items-center text-nowrap"}>
        <span className={"d-block mr-2"}>{label}</span>
        <Input
          bs={false}
          input={{
            props: {
              type: "number",
              format: "integer",
              minimum: 0,
              style: {width: "50px",height: "21px"}
            }
          }}
          {...useHandler(`itemsIndex.${path}`)}
        />
      </div>
    </Theme.leafKey>
  );
});

export const isSelectRequest = ({path}) => {
  path = $.strToArray(path);
  return true/*!path.includes("items")*/;
};

export const isLastPropMapping = (props) => {
  const {data} = props;
  const keys = $.keys(data);
  let isLastProp = false;
  const items = $.get(data, "items");
  const additionalProperties = $.get(data, "additionalProperties");

  if($.isArray(items) || $.isObject(additionalProperties)) return false;

  keys.forEach(key => {
    const schema = $.get(data, key);

    if(key === "source" && ($.isArray(schema) || schema === undefined)) {
      isLastProp = true;

    } else if(key === "value" && ($.isArray(schema) || !$.isObject(schema))) {
      isLastProp = true;
    }
  });

  return isLastProp;
};

export const isBranchNodeMapping = ({data, dataKey}) => (
  $.isObject(data) && !($.isArray(data) && (dataKey === "source" || dataKey === "value"))
);

export const handleSelectedChange = (props) => {
  const {store, selection} = props;
  const isSelected = existArrayPath(props, "selected");

  if (selection === 'single') {
    if (!isSelected) {
      store.set("selected", []);
    }
  }
  isSelected
    ? removeArrayPath(props, "selected")
    : pushArrayPath(props, "selected", true)
};

const existArrayPath = (props, accessor) => {
  const index = indexArrayPath(props, accessor);
  return !!~index;
};

const indexArrayPath = (props, accessor) => {
  const {store, path} = props;
  const array = store.get(accessor);
  if (!$.isArray(array)) return -1;

  return array.findIndex(pathArr => path.length === $.get(pathArr, "path").length && path.join() === $.get(pathArr, "path").join());
};

const removeArrayPath = (props, accessor) => {
  const {store} = props;
  const index = indexArrayPath(props, accessor);
  store.remove([accessor, `${index}`]);
};

const pushArrayPath = (props, accessor, value) => {
  const {store, path} = props;
  store.push(accessor, {path, value});
};

export const handleExpandCollapse = (props) => {
  const {store, path} = props;
  isExpanded(props)
    ? setArrayPath(props, "expanded", false)
    : setArrayPath(props, "expanded", true);
};

const isExpanded = (props) => {
  const {store, path, level} = props;
  const expanded = getArrayPath(props, "expanded");
  if (expanded) return $.get(expanded, "value");
  const curPath = [];
  for (let i = 0; i < path.length; i++) {
    curPath.push(path[i]);
    const defaultExp = getArrayPath({store, path: curPath}, "defaultExpandDeep");
    if (defaultExp && $.get(defaultExp, "value") >= level) {
      return true;
    }
  }
  return false;
};

const setArrayPath = (props, accessor, value) => {
  const {store, path} = props;
  const index = indexArrayPath(props, accessor);

  if (!~index) {
    pushArrayPath(props, accessor, value);
    return;
  }

  store.set([accessor, index], {path, value});
};

export const descendantsOpenApiSchemaItems = (parent) => {
  if ($.get(parent, 'properties')) {
    return getChildren($.get(parent, 'properties'));
  }

  if ($.get(parent, 'items')) {
    const items = $.get(parent, 'items');
    return [{key: "items", data: items}];
  }

  if ($.get(parent, 'additionalProperties')) {
    const items = $.get(parent, 'additionalProperties');
    return [{key: "additionalProperties", data: items}];
  }

  return [];
};

export const getChildren = (parent) => Array.from(typeof parent.keys === "function" && parent.keys() || Object.keys(parent)).map(
  key => ({
    key,
    data: parent.get ? parent.get(key) : parent[key]
  })
);

export const buildChildPath = ({data, path}) => (
  path ? [...path, data.key] : [data.key]
);

export const buildChildId = ({data, id}) => (
  id ? id + ".child." + data.key : data.key
);

export const BranchNodeContent = observer((props) => {
  const {
    expanded, level, Theme, LabelContent, handleExpandCollapse,
    Selector, data, isLastProp, useLastProp
  } = props;
  const isSelect = props.isSelect || (() => false);
  const isPrimitive = isLastProp
    ? isLastProp(props)
    : PRIMITIVES.includes($.get(data, "type")) || isLocalizedField(data);

  return (
    <div style={{display: 'inline-flex', alignItems: 'center'}}>
      {isPrimitive && useLastProp
        ? <Theme.circle />
        : <Theme.twistie expanded={expanded} level={level}
                         onClick={handleExpandCollapse}/>
      }
      {isSelect(props)
        ? <Selector {...props} />
        : null
      }
      <Theme.label level={level}>
        <LabelContent {...props} />
      </Theme.label>
    </div>
  )
});

export const BranchNode = observer(props => {
  const {id, level, Theme, BranchNodeContent, BranchNodeChildren, handleExpandCollapse} = props;
  const expanded = isExpanded(props);
  const renderChildren = doRenderChildren(props);
  return (
    <Theme.li id={id + '.' + level}>
      <BranchNodeContent
        {...props}
        expanded={expanded}
        handleExpandCollapse={() => handleExpandCollapse(props)}
      />
      {renderChildren ?
        <BranchNodeChildren
          {...props}
          id={id}
          expanded={expanded}/>
        : null}
    </Theme.li>
  );
});

const doRenderChildren = (props) => {
  return isExpanded(props);
};


export const requestProps = {
  otherProps: {
    requestOrResponse: "request",
  },
  treeProps: {
    storeName: "parameterTreeStore",
    dataName: "parameterTreeModel",
    treeModelName: "requestMappingTreeModel",
    id: "requestParameters",
    title: {
      id: 'ui.modal.requestParams'
    },
    LabelContent: LabelContentAction,
    BranchNode,
    BranchNodeContent: BranchNodeContent,
    useLastProp: true,
    isLastProp: isLastPropMapping,
    Selector,
    isBranchNode: isBranchNodeMapping,
    handleSelectedChange,
    handleExpandCollapse,
    buildChildPath: buildChildPath,
    buildChildId,
    LeafNode: LeafNode,
  },
  mappingProps: {
    storeName: "documentRequestTreeStore",
    dataName: "documentRequestTreeModel",
    treeModelName: "documentRequestTreeModel",
    isTreeSelected: "isDocumentRequestTreeSelected",
    additionalSettings: true,
    useIndex: true,
    id: "requestSchema",
    title: {
      id: 'ui.modal.bpVariables'
    },
    LabelContent: LabelContentItems,
    BranchNodeContent: BranchNodeContent,
    useLastProp: true,
    BranchNode,
    Selector,
    handleSelectedChange,
    handleExpandCollapse,
    descendants: descendantsOpenApiSchemaItems,
    buildChildPath: buildChildPath,
    buildChildId,
    isSelect: isSelectRequest,
  }
};