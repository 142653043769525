import React, {Component, Fragment} from "react";
import {observer} from "mobx-react";
import {injectIntl} from "@isf/localization";
import {localeStore} from "@isf/core-localization";
//import { UICommonStore } from "@isf/ui-schema";
import {UICommonStore} from "../../../../../ui-schema/";
import {
  _Table as Table,
  _TableBody as TableBody,
  _TableCell as TableCell,
  _TableHead as TableHead,
  _TableRow as TableRow
} from "@isf/table";
import {StaticText} from "../../../../form";
import {SERVICES_COLUMNS, STATIC_TEXT_CONF} from "./service-table-constants";
import {ListContext} from "../../../../list-context/list-context";

@injectIntl
@observer
class ServicesTable extends Component {
  constructor(props) {
    super(props);
    this.dataStore = props.dataStore;
  }

  render() {
    const {choice, store, isClient = true, id = "services-table", columns = SERVICES_COLUMNS, selection = "single"} = this.props;
    return (
      <Fragment>
        <Table id={id}
               store={store}
               choice={choice}
               columns={columns}
               isClient={isClient}
               dataStore={this.dataStore}
               selection={selection}
               className={"table-striped table-hover"}>
          <TableHead/>
          <TableBody>
            <TableRow>
              <ListContext.Consumer>
                {data =>
                  <Fragment>
                    <TableCell colId={0}>
                      <StaticText data={data}
                                  uiStoreType={UICommonStore}
                                  localeStore={localeStore}
                                  {...STATIC_TEXT_CONF["name"]} />
                    </TableCell>
                    <TableCell colId={1}>
                      <StaticText data={data}
                                  uiStoreType={UICommonStore}
                                  localeStore={localeStore}
                                  {...STATIC_TEXT_CONF["contextPath"]} />
                    </TableCell>
                    <TableCell colId={2}>
                      <StaticText data={data}
                                  uiStoreType={UICommonStore}
                                  localeStore={localeStore}
                                  {...STATIC_TEXT_CONF["description"]} />
                    </TableCell>
                    <TableCell colId={3}>
                      <StaticText data={data}
                                  uiStoreType={UICommonStore}
                                  localeStore={localeStore}
                                  {...STATIC_TEXT_CONF["version"]} />
                    </TableCell>
                  </Fragment>

                }
              </ListContext.Consumer>
            </TableRow>
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

export {ServicesTable};