import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Provider, observer } from "mobx-react";
import { ModalBody, ModalFooter } from 'reactstrap';
import classNames from 'classnames';
import { ModalHeader } from './modal-header';
import { ToggleButton, useToggleStore, ToggleStore } from "../toggle";
import { Modal } from "./modal";
import {LoadSpinner} from '../load-spinner/load-spinner-view';

export const ModalContext = React.createContext(null);

const canClose = (props) => {
  const { toggle, openToggle } = props;
  if(toggle === undefined && openToggle === undefined) {
    return true;
  } else if (openToggle === undefined) {
    return (args, active) => (
      !active ||
      (typeof toggle === 'function'
          ? toggle(args)
          : !!toggle
      )
    )
  } else if (toggle === undefined) {
    return (args, active) => (
      active ||
      (typeof openToggle === 'function'
          ? openToggle(args)
          : !!openToggle
      )
    )
  }

  return (args, active) => {
    if (!active) {
      return (typeof openToggle === 'function'
          ? openToggle(args)
          : !active
      );
    }

    return (typeof toggle === 'function'
        ? toggle(args)
        : !!toggle
    )
  };

  // return ( // magic
  //   toggle === undefined
  //     ? true
  //     : (args, active) => (
  //       !active ||
  //       (typeof toggle === 'function'
  //           ? toggle(args)
  //           : !!toggle
  //       )
  //     )
  // )
};

const resolveComponent = (tag, Wrapper) => {
  if (typeof tag === 'string')
    return () => (
      <Wrapper >
        {tag}
      </Wrapper>
    );
  if (React.isValidElement(tag)) {
    return () => tag;
  }
  if (typeof tag === 'function') {
    const Tag = tag;
    return (props) => <Tag {...props} />;
  }
  return () => null;
}

const Opener = (props) => {
  return (
    <ToggleButton {...props} />
  );
}

const resolveComponents = ({ opener, header, body, footer }) => {
  return {
    Opener: resolveComponent(opener, Opener),
    Header: resolveComponent(header, ModalHeader),
    Body: resolveComponent(body, ModalBody),
    Footer: resolveComponent(footer, ModalFooter)
  }
}

const resolveToggleStore = (props) => {
  const { id, toggleStore: storeOrPath } = props;
  if ((typeof storeOrPath === 'string') || storeOrPath === undefined) {
    return useToggleStore({
      path: storeOrPath || id || "defaultToggleStore",
      canToggle: canClose(props)
    });
  }
  Object.assign(storeOrPath, { canToggle: canClose(props) });
  return storeOrPath;
}

@observer
class ModalContainer extends React.Component{
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    if (props.uiStoreType) {
      this.uiStore = new props.uiStoreType(props);
    }
  }

  componentWillUnmount() {
    this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {id, children, onModalOpen, toggleStore: storeOrPath, openToggle, className,
      computedClassName, uiStoreType, uiBaseStore, languages, localeStore, ...other} = this.props;
    const toggleStore = resolveToggleStore(this.props);
    const {Opener, Header, Body, Footer} = resolveComponents(this.props);
    const isOpened = toggleStore.isActive();
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
       <Provider toggleStore={toggleStore}>
         <ModalContext.Provider value={this.props.toggle}>
           <Fragment>
             <Opener onClick={onModalOpen} />
             {isOpened ?
                <Modal className={classNames(className, computedClassNames)} id={id} {...other}>
                  {children && children.length!==0?
                     this.uiStore?childrenWithLoading(children,this.uiStore):children :
                     <Fragment>
                       <Header />
                       <Body />
                       <Footer />
                     </Fragment>}
                </Modal>
                : null}
           </Fragment>
         </ModalContext.Provider>
       </Provider>
    );
  }
}

const childrenWithLoading = (children,uiStore)=>{
  return children.map(child=>{
    return React.cloneElement(child, {isLoading: uiStore.isLoading })
  })

};

// const ModalContainer = observer(props => {
//   const { id, children, onModalOpen, toggleStore: storeOrPath, openToggle, ...other } = props;
//   const toggleStore = resolveToggleStore(props);
//   const {
//     Opener,
//     Header,
//     Body,
//     Footer
//   } = resolveComponents(props);
//   const isOpened = toggleStore.isActive();
//   return (
//     <Provider toggleStore={toggleStore}>
//       <ModalContext.Provider value={props.toggle}>
//       <Fragment>
//         <Opener onClick={onModalOpen} />
//         {isOpened ?
//           <Modal   id={id} {...other}>
//             {children && children.length!==0?
//               children :
//               <Fragment>
//                 <Header />
//                 <Body />
//                 <Footer />
//               </Fragment>}
//           </Modal>
//           : null}
//       </Fragment>
//       </ModalContext.Provider>
//     </Provider>
//   );
// });

ModalContainer.propTypes = {
  toggleStore: PropTypes.oneOfType([
    PropTypes.instanceOf(ToggleStore),
    PropTypes.string
  ]).isRequired,
  close: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func
  ]),
  opener: PropTypes.node,
  header: PropTypes.node,
  body: PropTypes.node,
  footer: PropTypes.node
}

export { ModalContainer };