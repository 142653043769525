import React from "react";
import {observer} from "mobx-react";
import classNames from "classnames";
import {NavigationThemeContext} from "./navigation-container";
import {NavItem} from "./nav-item"
import {NavLink} from "./nav-link"

@observer
export class NavigationItem extends React.Component{
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {children, className, computedClassName, uiBaseStore, ...other} = this.props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <NavigationThemeContext.Consumer>
        {navStore =>
          <NavItem navStore={navStore} className={classNames(className, computedClassNames)} {...other}>
            <NavLink href="#" navFor={other.id + ''} navStore={navStore}>
              {children}
            </NavLink>
          </NavItem>
        }
      </NavigationThemeContext.Consumer>
    );
  }
}
