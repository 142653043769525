import { autorun, computed } from 'mobx';
import $ from '@isf/core-object-util';
import { uuidv4 } from '@isf/core-system-util';
import { getDeclaredString, NOT_READ_ONLY } from '../expression/utils';
import { UiDisposeStore } from './ui-dispose-store';

const initialize = (props, id, store) => {
  store._disabledStore = new UIPopoverDisabledStore(id, props);
};

class UIPopoverStore {
  constructor(props) {
    const id = `uiPopoverStore.${uuidv4()}`;
    initialize(props, id, this);
  }

  _disabledStore;

  @computed
  get disabledStore() {
    return this._disabledStore;
  }

  dispose(){
    this._disabledStore.dispose();
  }
}

class UIPopoverDisabledStore extends UiDisposeStore {
  constructor(parentId, props){
    super(props);
    const parentArrPath = parentId.split('.');
    parentArrPath.splice(1, 0, 'disabled');
    this.localeStore = props.localeStore;
    this.map = $.objectToObservableMap({});
    initializeDisabledAutorun(props, this);
  }

  get = (path) => {
    return this.map.get(path);
  }

  set = (path, value) => {
    this.map.set(path, value);
  }
}

const initializeDisabledAutorun = (props,store) => {
  if (props.disabled === 'true' || props.disabled === 'false') store.set('isDisabled', eval(props.disabled));
  else if (props.disabled && !NOT_READ_ONLY.includes(props.disabled)) {
    const disabledParams = props.disabled && props.disabled.params || [];
    const disabledExpression = props.disabled && props.disabled.expression || {};
    store.addAutorun(autorun(() => {
      let declaredString = '';
      if (disabledParams.length !== 0) {
        declaredString = getDeclaredString(disabledParams, store.localeStore, props.data, { refId: props.refId });
        if (declaredString !== 'let;') store.set('isDisabled', eval(declaredString + disabledExpression));
      }
    }))
  }
};

export { UIPopoverStore }
