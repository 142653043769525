import {UIStore} from "@isf/core-app-store";
import {observable, action, computed} from "mobx";
import $ from "@isf/core-object-util";

class SelectionStore extends UIStore {

  @observable pageRows = [];
  @observable checkedRows = [];
  @observable selectAll = false;
  _requestStore = undefined;

  @action
  clearStore() {
    // this.pageRows = [];
    this.checkedRows = [];
    this.selectAll = false;
    if(this.requestStore){
      this.requestStore.setState([]);
    }
  }

  @action
  setPageRow(rowId) {
    this.pageRows.push(rowId);
  }

  @action
  setPageRows(rows) {
    rows = rows || [];
    this.pageRows = rows;

    const oldLength = this.checkedRows.length;
    this.checkedRows = this.checkedRows.filter(index => rows.includes(index));
    if(rows.length > 0) {
      this.selectAll = this.checkedRows.length === rows.length;
    }

    if(oldLength !== this.checkedRows.length && this.requestStore) {
      const stateArray = [];
      this.checkedRows.map(rowIndex => {
        if (!isNaN(+rowIndex) && +rowIndex >= 0) {
          stateArray.push($.get(this.dataStoreRef, `${rowIndex}`))
        }
      });

      this.requestStore.setState(stateArray);
    }
  }

  get dataStoreRef() {
    const dataStore = this.get("dataStoreRef.store");
    if(!dataStore) return;
    const accessor = this.get("accessor");
    return accessor
      ? dataStore.get(accessor)
      : dataStore.get([]);

  }

  @action
  setCheckedRow(rowId, selection) {
    if (this.checkedRows.includes(rowId)) {
      if (this.selectAll) {
        this.selectAll = !this.selectAll;
        // if(this.requestStore)
          // this.requestStore.set("items",[])
          // this.requestStore.setState([])
      }
      this.checkedRows = this.checkedRows.filter(item => item !== rowId);
      // if(this.requestStore){
      //   const note = this.get('dataStoreRef').get(`${rowId}`);
      //   // const items = this.requestStore.get('items');
      //   const items = this.requestStore.state;
      //   for(let i=0;i<items.length;i++) {
      //     if (JSON.stringify(items[i]) === JSON.stringify(note)) {
      //       console.log("true", JSON.stringify(items[i]) === JSON.stringify(note))
      //       items.splice(i, 1);
      //       break;
      //     }
      //   }
      // }
    } else {
      // if (this.requestStore) {
      //   // let items = this.requestStore.get('items');
      //   let items = this.requestStore.state;
      //   if (!items) {
      //     // this.requestStore.set('items', []);
      //     this.requestStore.setState([]);
      //     items=[];
      //   }
      //
      //   items.push(this.get('dataStoreRef').get(`${rowId}`));
      //   this.requestStore.setState(items);
      //   // this.requestStore.set('items',items);
      // }

        if (selection === "single") {
          this.checkedRows = [];
        }

      this.checkedRows.push(rowId);
      if (this.pageRows.length === this.checkedRows.length) {
        this.selectAll = !this.selectAll;
      }
    }

    if (this.requestStore) {
      const stateArray = [];
      this.checkedRows.map(rowIndex => {
        if (!isNaN(+rowIndex) && +rowIndex >= 0) {
          stateArray.push($.get(this.dataStoreRef, `${rowIndex}`))
          // stateArray.push(this.get('dataStoreRef').get(`${rowIndex}`))
        }
      });

      this.requestStore.setState(stateArray);
    }
  }

  @action
  setRequestStore(requestStore) {
    this._requestStore = requestStore;
  }

  @action
  setSelectAll() {
    if (!this.selectAll) {
      this.checkedRows = this.pageRows;
      if(this.requestStore) {
        const stateArray = [];
        this.pageRows.map(rowIndex => {
          if (!isNaN(+rowIndex) && +rowIndex >= 0) {
            stateArray.push($.get(this.dataStoreRef, `${rowIndex}`))
            // stateArray.push(this.get('dataStoreRef').get(`${rowIndex}`))
          }
        });
        this.requestStore.setState(stateArray)
      }
    } else {
      this.checkedRows = [];
      if(this.requestStore){
        this.requestStore.setState([])
      }
    }
    this.selectAll = !this.selectAll;
  }

  isSelectAll() {
    return this.selectAll;
  }

  isRowChecked(rowId) {
    return this.checkedRows.includes(rowId);
  }

  @computed
  get selected() {
    return this.checkedRows;
  }

  @computed
  get requestStore() {
    return this._requestStore;
  }
}

export {SelectionStore};
