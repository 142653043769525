import appStore, { DataStore } from "@isf/core-app-store";

const useStore = (args, scope) => { 
    const { path, type } = args;
    const store = appStore.getStore(scope + '.' + path);
    if (store) {
      return store;
    }
    return new type({
      ...args
    });  
};

const useDataStore = (args) => {
  return useStore({type: DataStore, ...args}, 'data')
};

const getStore = (store) => (
  (typeof store === 'string' ) ? useDataStore({path:store}) : store
);

const makeInputHandler = ({ actor, store, get, set }) => {
  const s = actor ? actor : store && getStore(store);
  const handler = {
    get: get || ( (accessor) => s.get(accessor) ),
    set: set || ( (accessor, value) => s.set(accessor, value) ),
     store:s
  };
  return handler;
}

const useInputHandler = (args) => {
  const { accessor, actor, store } = args;
  const s = actor ? actor : store && getStore(store);
  const handler = makeInputHandler(args);
  if (accessor) {
    return  ( () => ({handler, accessor}) );
  }
  return  ( (accessor, set) => ({handler: {...handler, set: set ? set.bind(s) : handler.set.bind(s)}, accessor}) );
}

export { useInputHandler, makeInputHandler };