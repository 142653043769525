import React from 'react'
import {DataStore} from "@isf/core-app-store";
import {action, observable, runInAction} from "mobx";
import {ICON_DEFAULT_IMAGE} from "@isf/common-resources";
import {
    fileObj,
    getAddFileRequestObj,
    getRemoveFileRequestObj,
    getRequestBody,
    removeListener,
    validateRemoveResponse,
    restHandler
} from "../form/file-input/file-input-constans";
import $ from "@isf/core-object-util/src/object-util";

class ImageStore extends DataStore {

    @observable _image = ICON_DEFAULT_IMAGE;
    @observable _position = {x: 0.5, y: 0.5};
    @observable _scale = 1;
    @observable _rotate = 0;
    @observable _borderRadius = 0;
    @observable _imageSize = 200;
    @observable _croppedImage = ICON_DEFAULT_IMAGE;
    @observable _savedImage = ICON_DEFAULT_IMAGE;
    @observable _savedRotate = 0;
    @observable _savedScale = 1;
    @observable _fileName = 'image.jpg';
    schemaCursorStore;
    editor;

    constructor(props) {
        super(props);
        this.schemaCursorStore = props.schemaCursorStore;
    }

    get uiCursor() {
        return this.schemaCursorStore ? this.schemaCursorStore.uiSchemaCursor : null;
    }

    handleNewImage = (e) => {
        this.savedRotate = this.rotate;
        this.savedScale = this.scale;
        this.rotate = 0;
        this.scale = 1;
        this._fileName =  e.target.files[0].name;
        let reader = new FileReader();
        reader.onload = (e) => {
            this.image = e.target.result;
        };
        reader.readAsDataURL(e.target.files[0]);
        //this.croppedImage = URL.createObjectURL(e.target.files[0])
    };

    handleScale = e => {
        const scale = parseFloat(e.target.value);
        this.scale = scale;
    };

    handleBorderRadius = e => {
        this.borderRadius = parseInt(e.target.value);
        this.uiCursor.set('props.borderRadius', this.borderRadius);
    };

    handleSize = e => {
        this.imageSize = e.target.value.replace(/\D/, '');
        this.uiCursor.set('props.imageSize', this.imageSize);
    };

    handlePositionChange = position => {
        this.position = position;
    };

    rotateLeft = e => {
        e.preventDefault();
        this.rotate = this.rotate - 90;
    };

    rotateRight = e => {
        e.preventDefault();
        this.rotate = this.rotate + 90;
    };

    @action
    onSaveImage = () => {
        this.savedRotate = this.rotate;
        this.savedScale = this.scale;
        if (this.editor) {
            // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
            // drawn on another canvas, or added to the DOM.
            //const canvas = this.editor.getImage()

            // If you want the image resized to the canvas size (also a HTMLCanvasElement)
            const canvasScaled = this.editor.getImageScaledToCanvas();

            this.croppedImage = canvasScaled.toDataURL('image/png', 1);
            this.uploadFiles(this.croppedImage);
            //let formData = new FormData();
            //formData.append('file', canvasScaled.toDataURL('image/jpeg', 10));
        }
    };

    @action
    uploadFiles(files) {
        this.fileDisabled = true;
        const imgBlob = this.base64ImageToBlob(files);
        const imgFiles = [];
        imgFiles.push(new File([imgBlob], this._fileName, {type: imgBlob.type, lastModified: Date.now()}));
        const body = getRequestBody(imgFiles);
        const requestObj = getAddFileRequestObj(this.state.contextPath, body);
        const context = {
            listener: this.setFile,
            files: imgFiles
        };

        this.state.handler.store.fileApi
          .call(requestObj, context, restHandler)
          .catch(error => console.log("Failed load file", error))
          .finally(() => this.fileDisabled = false);
    }

    setFile = async (context, responseInfo, data) => {
        if (responseInfo.ok) {
            const isDelete = await this.removeFile();
            if (!isDelete) {
                console.log("Failed delete file");
                return;
            }

            runInAction(() => {
                this.state.handler.set(this.state.accessor, fileObj(context.files[0], data/*, $.get(data, "size")*/));
                this.state.handler.store.setFileAccessor(this.state.accessor, true);
            })
        } else {
            console.log("Failed add file", responseInfo.status, data);
        }
    };

    @action
    async removeFile() {
        const fileId = this.state.handler.get(this.state.accessor + ".id");

        if (!fileId) {
            this.state.handler.set(this.state.accessor, undefined);
            this.state.handler.store.setFileAccessor(this.state.accessor, false);
            return true;
        }

        const requestObj = getRemoveFileRequestObj(this.state.contextPath, fileId);
        const context = {listener: removeListener, validateAction: validateRemoveResponse};

        const result = await this.state.handler.store.fileApi
          .call(requestObj, context, restHandler);

        if (result) {
            this.state.handler.set(this.state.accessor, undefined);
            this.state.handler.store.setFileAccessor(this.state.accessor, false);
        }

        return result;
    }

    downLoadLink() {
        const value = this.state.handler.get(this.state.accessor);

        if ($.get(value, "id") && this.state.handler.store.fileApi) {
            let url = this.state.handler.store.fileApi.url;
            const contextPath = this.state.contextPath + "/" + "file/" + $.get(value, "id");

            url = contextPath.startsWith("/")
              ? url + contextPath
              : url + "/" + contextPath;
            return url;
        }

    }

    base64ImageToBlob(str) {
        // extract content type and base64 payload from original string
        const pos = str.indexOf(';base64,');
        const type = str.substring(5, pos);
        const b64 = str.substr(pos + 8);

        // decode base64
        const imageContent = atob(b64);

        // create an ArrayBuffer and a view (as unsigned 8-bit)
        const buffer = new ArrayBuffer(imageContent.length);
        const view = new Uint8Array(buffer);

        // fill the view, using the decoded base64
        for(let n = 0; n < imageContent.length; n++) {
            view[n] = imageContent.charCodeAt(n);
        }

        // convert ArrayBuffer to Blob
        const blob = new Blob([buffer], { type: type });

        return blob;
    }

    @action
    onCancelImage = () => {
        this.image = this.savedImage;
        this.croppedImage = this.savedImage;
        this.scale = this.savedScale;
        this.rotate = this.savedRotate;
    };

    setEditorRef = (editor) => this.editor = editor;

    handleDrop = (dropped) => {
        this.image = dropped[0];
    };

    get image() {
        return this._image;
    }

    set image(value) {
        this._image = value;
    }

    get position() {
        return this._position;
    }

    set position(value) {
        this._position = value;
    }

    get scale() {
        return this._scale;
    }

    set scale(value) {
        this._scale = value;
    }

    get rotate() {
        return this._rotate;
    }

    set rotate(value) {
        this._rotate = value;
    }

    get imageSize() {
        return this._imageSize;
    }

    set imageSize(value) {
        this._imageSize = value;
    }

    get borderRadius() {
        return this._borderRadius;
    }

    set borderRadius(value) {
        this._borderRadius = value;
    }

    get croppedImage() {
        return this._croppedImage;
    }

    set croppedImage(value) {
        this._croppedImage = value;
    }

    get savedImage() {
        return this._savedImage;
    }

    set savedImage(value) {
        this._savedImage = value;
    }

    get savedScale() {
        return this._savedScale;
    }

    set savedScale(value) {
        this._savedScale = value;
    }

    get savedRotate() {
        return this._savedRotate;
    }

    set savedRotate(value) {
        this._savedRotate = value;
    }

    @action
    set(path, value) {
        this.uiCursor.set(path, value);
    }

    get(path) {
        return this.uiCursor.get(path);
    }

}

Object.defineProperty(ImageStore, "name", {value: "ImageStore"});

export {ImageStore};