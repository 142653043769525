import React from "react";

import {Droppable} from "react-drag-and-drop"
import {observer} from "mobx-react";

export const DroppableWrapper = observer((props) => {
    const { path, onDrop, onDragOver} = props;
    return (
      <div className="col">
        <Droppable path={path}
                   onDragOver={(e)=> handleDragOver(e, props, onDragOver)}
                   onDrop={(e) => handleDrop(e, props, onDrop)}>
          {props.children}
        </Droppable>
      </div>
    );
  }
);

const handleDrop = (e, props, drop) => {
  drop(e, props);
};

const handleDragOver = (e, props, dragOver) => {
   dragOver(e, props);
};


