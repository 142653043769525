const DEFAULT_THEME = "/themes/custom-styles-for-all.css;$/themes/oais-new.css";

const THEMES = [
  { "value": "/themes/custom-styles-for-all.css;$/themes/argon-dashboard.css", "name": "argon dashboard" },
  { "value": "/themes/custom-styles-for-all.css;$/themes/bootstrap-cerulean.css", "name": "Bootstrap cerulean" },
  { "value": "/themes/custom-styles-for-all.css;$/themes/bootstrap-lux.css", "name": "Bootstrap lux" },
  { "value": "/themes/custom-styles-for-all.css;$/themes/bootstrap-pulse.css", "name": "Bootstrap pulse" },
  { "value": "/themes/custom-styles-for-all.css;$/themes/bootstrap-sandstone.css", "name": "Bootstrap sandstone" },
  { "value": "/themes/custom-styles-for-all.css;$/themes/bootstrap-sketchy.css", "name": "Bootstrap sketchy" },
  { "value": "/themes/custom-styles-for-all.css;$/themes/bootstrap-slate.css", "name": "Bootstrap slate" },
  { "value": "/themes/custom-styles-for-all.css;$/themes/material-kit.css", "name": "material kit" },
  { "value": "/themes/custom-styles-for-all.css;$/themes/comfortable.css", "name": "comfortable" },
  { "value": "/themes/custom-styles-for-all.css;$/themes/visually-impaired.css", "name": "visually-impaired" },
  { "value": "/themes/custom-styles-for-all.css;$/themes/inversion.css", "name": "inversion" },
  { "value": "/themes/custom-styles-for-all.css;$/themes/oais-old.css", "name": "OAIS-old" },
  { "value": "/themes/custom-styles-for-all.css;$/themes/oais-dark.css", "name": "OAIS-dark" },
  { "value": "/themes/custom-styles-epolsoft.css;$/themes/oais-new-epolsoft.css", "name": "EpolSoft" },
  { "value": "/themes/custom-styles-for-all.css;$/themes/oais.css", "name": "OAIS" },
  { "value": "/themes/custom-styles-for-all.css;$/themes/oais-new.css", "name": "OAIS new" },
  { "value": "/themes/custom-styles-for-all.css;$/themes/oais-flat.css", "name": "OAIS flat" },
  { "value": "/themes/custom-styles-for-all.css;$/themes/bootstrap-v4.3.1.css", "name": "default bootstrap-v4.3.1" }
  //  { "value": "/themes/bootstrap-1.css", "name":"default custom bootstrap-v4.3.1" },
  // { "value": "/themes/material-dashboard.css", "name": "material dashboard" },
  // { "value": "/themes/now-ui-kit.css", "name": "now ui kit" },
  // { "value": "/themes/paper-kit.css", "name": "paper kit" },//
];

export { DEFAULT_THEME, THEMES };