import React, { Fragment } from "react";
import { observer, Provider } from "mobx-react";
import ReactAvatarEditor from "react-avatar-editor";
import { resolveStore } from "@isf/react-util";
import { injectIntl } from "@isf/core-localization";
import { ToggleButton, useToggleStore } from "../toggle";
import { Col, Container, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { ModalCancelButton, ModalOKButton } from "../modal"
import Button from "../form/button";
import { ImageStore } from "./image-store";
import { ModalContainer } from "../modal/modal-container";
import { ICON_ROTATE_LEFT, ICON_ROTATE_RIGHT, ICON_SELECT_IMAGE } from "@isf/common-resources";
import { FormattedMessage } from "../localization";

@injectIntl
@observer
class ImageModalView extends React.Component {

  constructor(props) {
    super(props);
    this.imageStore = resolveStore({
      ...props,
      path: "image" + this.ID(),
      type: ImageStore,
      observableAsMap: true,
      state: {
        handler: props.handler,
        accessor: props.accessor,
        contextPath: props.contextPath
      }
    });
    this.id = this.id.bind(this);
    this.accessor = props.accessor;
    let path = props.path ? props.path : "props.accessor";
    path += this.ID();
    this.toggleStore = useToggleStore({path})
  }

  ID = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
  };

  id(childId) {
    return this.props.id + '.' + childId;
  }

  toggle = (props) => {
    const buttonType = props['data-button-type'];
    if (buttonType === 'cancel') {
      this.imageStore.onCancelImage();
      return true;
    }
    if (buttonType === 'ok') {
      this.imageStore.onSaveImage();
    }
    return true;
  };

  openModal = () => {
    this.imageStore.savedImage = this.imageStore.image;
    this.imageStore.image = this.props.src;
  };

  render() {
    const { input: {imageSaved, imageView}, src, disabled, intl } = this.props;
    const uploadId = "imageUpload" + this.ID();
    return (
      <Provider toggleStore={this.toggleStore}>
        <Fragment>
          <div className="w-100"
               style={{
                 marginBottom: !disabled &&"-56px",
                 maxWidth:`${imageView && imageView.maxWidth}px`}}>
            <img
              src={src  /*|| ICON_DEFAULT_IMAGE */}
              style={{
                width:"100%",
                objectFit: "cover",
                maxWidth:`${imageView && imageView.maxWidth}px`,
                height:`${imageView && imageView.height}px`,
                borderRadius:`${imageSaved && imageSaved.rounding}%`}}/>
            <div style={{
              'position': 'relative',
              "width": "fit-content",
              "margin": "0 auto",
              'top': '-50px'
            }}>
              {
                !disabled &&
                <ToggleButton style={{
                  'borderRadius': '50%', 'width': '40px', 'height': '40px',
                  'padding': '0px'
                }} color="light" className={"btn-light"} size="sm" onClick={this.openModal}>
                  <img src={ICON_SELECT_IMAGE} style={{'width': '40px', 'height': '40px'}}/>
                </ToggleButton>
              }
            </div>
          </div>
          <ModalContainer  size="xl" toggleStore={this.toggleStore}
                           toggle={this.toggle}>
            <ModalHeader>
              <FormattedMessage id={"ui.modal.selectImage"}
                                defaultMessage={'Выбрать изображение'}/>
            </ModalHeader>
            <ModalBody>
              <Container fluid>
                <Row>
                  <Col xs="auto" className="mx-auto">
                    <div style={{width:"fit-content", marginBottom: "-56px"}}>
                      <ReactAvatarEditor
                        crossOrigin="anonymous"
                        ref={this.imageStore.setEditorRef}
                        scale={parseFloat(this.imageStore.scale)}
                        width={(imageSaved && imageSaved.width) || 720}
                        height={(imageSaved && imageSaved.height) || 405}
                        position={this.imageStore.position}
                        onPositionChange={this.imageStore.handlePositionChange}
                        rotate={parseFloat(this.imageStore.rotate)}
                        border={0}
                        borderRadius={imageSaved && imageSaved.rounding*imageSaved.width || 0}
                        image={this.imageStore.image}
                        className="editor-canvas mx-3"
                        style={{
                          cursor: 'normal',
                          border: '1px dashed #CCC',
                          height: "calc(100% - 32px)",
                          maxWidth: "calc(100% - 32px)",
                          maxHeight: "calc(100% - 32px)"
                        }}
                      />
                      <div style={{
                        position: 'relative',
                        width: "fit-content",
                        margin: "0 auto",
                        top: '-56px'
                      }}>
                        {
                          !disabled &&
                          <Fragment>
                            <input type="file" id={uploadId} accept="image/*" style={{display: 'none'}}
                                   onChange={this.imageStore.handleNewImage}/>
                            <label htmlFor={uploadId}>
                              <img src={ICON_SELECT_IMAGE} className={"icon-w-50 image-square cursor-pointer"} style={{backgroundColor: "#e6e6e6"}}/>
                            </label>
                          </Fragment>
                        }
                      </div>
                    </div>
                  </Col>
                  <Col xs="auto" className="mx-auto">
                    <Row>
                      <Button color="light" className={"btn-light"}
                              onClick={this.imageStore.rotateRight}>
                        <img src={ICON_ROTATE_RIGHT} className="icon-w-25 h-auto mr-2"
                             alt={"Rotate right"}/>
                        <FormattedMessage id={"ui.avatar.right"}/>
                      </Button>
                    </Row>
                    <Row>
                      <Button color="light" className={"btn-light"}
                              onClick={this.imageStore.rotateLeft}>
                        <img src={ICON_ROTATE_LEFT} className="icon-w-25 h-auto mr-2" alt={"Rotate left"}/>
                        <FormattedMessage id={"ui.avatar.left"}/>
                      </Button>
                    </Row>
                    <input
                      type="range"
                      onChange={this.imageStore.handleScale}
                      min="1"
                      max="3"
                      step="0.01"
                      defaultValue={this.imageStore.scale}
                    />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              <ModalOKButton onClick={this.imageStore.onSaveImage}>
                <FormattedMessage id={"ui.modal.apply"} defaultMessage={'Применить'}/>
              </ModalOKButton>
              <ModalCancelButton>
                <FormattedMessage id={"ui.modal.cancel"} defaultMessage={'Отмена'}/>
              </ModalCancelButton>
            </ModalFooter>
          </ModalContainer>
        </Fragment>
      </Provider>
    )
  }
}

export {ImageModalView};