import React, {Component, Fragment} from "react";
import { observer } from "mobx-react";
import { /*injectIntl,*/ FormattedMessage } from "@isf/localization";
import $ from "@isf/core-object-util/src/object-util";
import { Row, Col, Button } from "@isf/bootstrap";
import { Item } from "../../../item-grid";
import { StaticText } from "../../../form";
import { FilterPropertyCriteria } from "./index";

//@injectIntl
@observer
class FilterPropertiesItem extends Component {

  handleClick = () => {
    const { handler, accessor, propPath, propType/*, localizedProp, intl*/ } = this.props;
    // const filterCriteria = localizedProp
    //   ? handler.get(`${accessor}.filter.${propPath.join('.')}.${intl.locale}`)
    //   : handler.get(`${accessor}.filter.${propPath.join('.')}`);
    const filterCriteria = handler.get(`${accessor}.filter.${propPath.join('.')}`);
    const defaultSettings = {
      operation: "equals", //this.getDefaultOperation(),
      value: null //this.getDefaultValue()
    };
    if (propType === "boolean") {
      defaultSettings["value"] = true;
    }
    if (!filterCriteria) {
      // if (localizedProp) handler.set(`${accessor}.filter.${propPath.join('.')}.${intl.locale}`, [defaultSettings]);
      // else handler.set(`${accessor}.filter.${propPath.join('.')}`, [defaultSettings]);
      handler.set(`${accessor}.filter.${propPath.join('.')}`, [defaultSettings]);
    } else filterCriteria.push($.objectToObservableMap(defaultSettings));
  };

  // getDefaultOperation = () => {
  //   const { propType, propFormat } = this.props;
  //   switch (propType) {
  //     case "string":
  //       if (propFormat === "date" || propFormat === "date-time") return "equals";
  //       else return "like";
  //     case "integer":
  //     case "number":
  //     case "boolean":
  //       return "equals";
  //     default:
  //       return "like";
  //   }
  // };

  // getDefaultValue = () => {
  //   const { propType, propFormat } = this.props;
  //   switch (propType) {
  //     case "string":
  //       if (propFormat === "date" || propFormat === "date-time") return null;
  //       else return "%%";
  //     case "integer":
  //     case "number":
  //     case "boolean":
  //       return null;
  //     default:
  //       return null;
  //   }
  // };

  render() {
    const { id, handler, accessor, propPath, propDescription, propType, propFormat/*, localizedProp*/ } = this.props;

    const propFinalName = propPath.join('.');
    return (
      <Item className="py-0 border-0 box-shadow">
        <Row className="justify-content-center justify-content-md-between align-items-center">
          <Col xs="12" md="9" lg="10" className="my-2 my-md-0">
            { propDescription
                ? <Fragment>
                    <StaticText text={propDescription} className="pr-1" />
                    <StaticText text={`(${propFinalName})`} className="text-primary font-weight-semi-bold text-break" />
                  </Fragment>
                : <StaticText text={`(${propFinalName})`} className="ml-md-3 text-primary font-weight-semi-bold text-break" />

            }
          </Col>
          <Col xs="auto" md="3" lg="2">
            <Row className="justify-content-start justify-content-md-end align-items-center no-gutters">
              <Col xs="auto">
                <Button color="success"
                        className="mt-2 mr-md-3 filter-setting-button-action"
                        outline
                        onClick={this.handleClick}>
                  <FormattedMessage id={"ui.filterSetting.criterion.add"} />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <FilterPropertyCriteria id={id}
                                handler={handler}
                                accessor={accessor}
                                propType={propType}
                                propFormat={propFormat}
                                propPath={propPath} />
                                {/*localizedProp={localizedProp} />*/}
      </Item>
    )
  }
}

export { FilterPropertiesItem };