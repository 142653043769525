import React from 'react';
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import {FilterRow} from "./table-filter";
import SortWrapper from "./sort-wrapper";
import CheckBox from "../table-checkbox";
import { Message } from "../../../form/message";
import {injectIntl} from "react-intl";

const handleOnThClick = (column, store) => {
  store.sortConfig = column.accessor;
};

const TableHead = injectIntl(observer((props) => {
    const { store, isClient, columns, filterStore, selection, id } = props;
    const isFilterActive = filterStore.isFilterActive;
    return (
      <thead>
      <tr>
          {columns.length===0? <th scope="col" key={'defaultKey'}>Table header</th>:
          columns.map((column, i) => {
            if(column.type === 'checkbox') {
              return (
                <th style={{width:"60px"}} scope="col" key={i}>
                  {selection !== "single" &&
                    <CheckBox rowId={id + '.headerCheckBox'} store={store.selectionStore} rowIndex={-1}/>
                  }
                </th>
              )
            } else if(column.type === 'actions') {
              return <th/>;
            }
            if(column.sortable) {
              return (
                <th scope="col" key={i}
                    onClick={() => handleOnThClick(column, store)}>
                    <SortWrapper
                      sortUp={store.order === -1}
                      isSorted={store.sortBy === column.accessor}
                      >
                      {typeof column.header === 'object' ? column.header.message ? <Message message={column.header.message}/>:column.header : column.header}
                    </SortWrapper>
                </th>
              )
            }
            return (
              <th scope="col" key={i}>
                {typeof column.header === 'object' ? column.header.message ? <Message message={column.header.message}/>:column.header : column.header}
              </th>
            )
          })
        }
      </tr>
      {isClient && isFilterActive ? <FilterRow {...props} tableStore={store}/> : null}
      </thead>
    )
  }
));

TableHead.propTypes = {
  id: PropTypes.string,
  isClient: PropTypes.bool.isRequired,
  tableName: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  store: PropTypes.object.isRequired
};

export default TableHead;
