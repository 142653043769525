import React from "react";
import PropTypes from "prop-types";
import {observer} from "mobx-react"
import { isReactComponent } from "@isf/react-util";
import Shape,{shapeDefaultConfig} from "./shapes/shape/shape";
import Link,{linkDefaultConfig} from "./shapes/link/link"
import {SVG , svgDefaultConfig} from "./shapes/svg/svg";
import {BPMNDiagram , BPMNDiagramDefaultConfig} from "./shapes/diagram-form";
import {Marquee, marqueeDefaultConfig} from "./shapes/marquee/marquee";
import $ from '@isf/core-object-util'

const components = {
  'BPMNDiagram' : { editor:null, component: BPMNDiagram, name: 'DiagramForm', newConfig: BPMNDiagramDefaultConfig, categories: ['output'] },
  'Shape': { editor:null, component: Shape, name: 'Shape', newConfig: shapeDefaultConfig, categories: ['output'] },
  'Link': {editor:null, component: Link, name: 'Link', newConfig: linkDefaultConfig, categories: ['output'] },
  'SVG':{editor:null, component: SVG, name: 'SVG', newConfig: svgDefaultConfig, categories: ['output'] },
  'Marquee':{editor:null, component: Marquee, name: 'Marquee', newConfig: marqueeDefaultConfig, categories: ['output']}
};

const resolveComponent = (tagName) => (components[tagName] ? components[tagName].component : tagName);

const DiagramRenderer = observer((props) => {
  const context = buildRenderingContext(props);
  const uiSchemaCursor = $.toJS(props.uiSchema);
  return context.renderComponent({
    ...props,
    uiSchemaCursor,
    context,
    key: '0'
  });
});

DiagramRenderer.propTypes = {
  context: PropTypes.shape({
    resolveComponent: PropTypes.func,
    renderComponent: PropTypes.func,
    renderChildrenComponents: PropTypes.func
  })
};

const resolveComponentProps = (args) => {
  const { Component, uiSchemaCursor, key, changeHandler,schemaDataStore,areaRef,localeStore } = args;
  let props = $.get(uiSchemaCursor,'props');
  const injectDataStore = isReactComponent(Component);
  return {
    key,
    localeStore,
    schemaDataStore,
    areaRef,
    ...props,
    ...(injectDataStore ? { handler: changeHandler } : {})
  };
};

const renderComponent = (args) => {
  const { uiSchemaCursor, context } = args;
  const Component = context.resolveComponent($.get(uiSchemaCursor,'tag'));
  return (
    <Component {...context.resolveComponentProps({...args, Component})}>
      {context.renderChildrenComponents(args)}
    </Component>
  );
};

const renderChildrenComponents = (args) => {
  const { uiSchemaCursor, context, key } = args;
  const children = $.get(uiSchemaCursor,'children');
  return children && children.map((child, i) => (
    context.renderComponent({
      ...args,
      uiSchemaCursor: child,
      key: key ? (key + '.' + i) : i
    })
  ));
};


const buildRenderingContext = ({ context }) => {
  return {
    ...defaultRenderingContext,
    ...context
  };
};

const defaultRenderingContext = {
  resolveComponent,
  resolveComponentProps,
  renderComponent,
  renderChildrenComponents
};


const DiagramRenderedView = observer((props) => {
  return(
  <DiagramRenderer {...props} uiSchema={props.schemaDataStore.schema}
                   context={defaultRenderingContext}
  />
  );
});
export default DiagramRenderedView;