import React from "react";
import { URL_LOGIN, referer } from "./conf";

class Login extends React.Component {

  componentDidMount() {
    this.submitButton.click();
  }

  render() {
    return (
      <form action={URL_LOGIN} style={{display: 'none'}}>
        <input type="hidden" name="Referer" value={referer()} />
        <input type="submit" ref={ref => this.submitButton = ref} value="Войти" />
      </form>
    );
  }
};

export default Login;