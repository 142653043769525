import React, {Component, Fragment, lazy, Suspense} from 'react';
import {observable, action, runInAction} from "mobx";
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import {handleError, handleModuleImport} from '../../dynamic-import-handler';
import {Input as Input_} from 'reactstrap';
import DropzoneInput from '../dropzone-input';
import {injectIntl} from "@isf/core-localization";
import $ from "@isf/core-object-util";
import {
  ICON_FILE_ATTACH,
  ICON_FILE_DOWNLOAD,
  ICON_FILE_DELETE,
  ICON_FILE_SIGN,
  ICON_SHIELD
} from "@isf/common-resources";
import {
  getAddFileRequestObj,
  getRequestBody,
  readFile,
  readOpenApiFile,
  fileObj,
  restHandler,
  getRemoveFileRequestObj,
  removeListener,
  validateRemoveResponse,
} from "./file-input-constans";
import { ImageModalView } from "../../image";
import {LoadSpinner} from "../../load-spinner/load-spinner-view";
import {signFile} from "./file-signature";
import appStore, {UIStore} from "@isf/core-app-store";
import {createAlertMessage} from "../../alert/alert-message";
import {localeStore} from "@isf/core-localization";
import {VALIDATE_FUNC} from "./validation-file";

const ApiValidationErrorsModal = lazy(() => import('../../swagger/src/swagger-parser')
  .then(module => handleModuleImport(module, '../../swagger/src/swagger-parser', 'ApiValidationErrorsModal'))
  .catch(handleError));

const HTMLInput = ({children, ...other}) => {
  return (
    <input {...other} />
  );
};

const resolveHTMLInput = ({type}) => {
  return HTMLInput;
};

@injectIntl
@observer
class FileInput extends Component {
  constructor(props) {
    super(props);
    if (props.input.dataType === 'string.OpenApi') {
      this.errorStore = new UIStore({path: `openApiValidation.errorStore.${props.input.props.id}`, state: {errors: null}, observableAsMap: true});
    }
  }

  @observable fileDisabled = false;
  @observable fileName;
  @observable isLoading = false;

  @action
  setLoading() {
    this.isLoading = !this.isLoading;
  }

  setDisabledField() {
    this.fileDisabled = true;
    this.setLoading();
  }

  setEnabledField() {
    this.fileDisabled = false;
    this.setLoading();
  }

  @action
  uploadFiles(files) {
    const {handler, input} = this.props;
    const {contextPath} = input;

    const body = getRequestBody(files);
    const requestObj = getAddFileRequestObj(contextPath, body);
    const context = {
      listener: this.setFile,
      handler: handler,
      files: files
    };

    handler.store.fileApi
      .call(requestObj, context, restHandler)
      .catch(error => {
        console.log("Failed load file", error);
      })
      .finally(() => {
        this.setEnabledField();
      });
  }


  setFile = async (context, responseInfo, data) => {
    if (responseInfo.ok) {
      const isDelete = await this.removeFile();
      if (!isDelete) {
        console.log("Failed delete file");
        return;
      }

      runInAction(() => {
        const {handler, accessor, input} = this.props;
        const {store} = handler;
        handler.set(accessor, fileObj(context.files[0], data/*, $.get(data, "size")*/));
        store && store.setFileAccessor && store.setFileAccessor(accessor, true);
        if (input && input.props && input.props.onChange) {
          input.props.onChange();
        }
      })
    } else {
      console.log("Failed add file", responseInfo.status, data);
    }
  };

  @action
  async removeFile() {
    const {handler, accessor, input} = this.props;
    const {store} = handler;
    const fileId = handler.get(accessor + ".id");

    if (!fileId) {
      handler.set(accessor, null);
      store && store.setFileAccessor && store.setFileAccessor(accessor, false);
      return true;
    }

    const {contextPath} = input;
    const requestObj = getRemoveFileRequestObj(contextPath, fileId);
    const context = {listener: removeListener, validateAction: validateRemoveResponse};

    const result = await store.fileApi
      .call(requestObj, context, restHandler);

    if (result) {
      handler.set(accessor, null);
      store && store.setFileAccessor && store.setFileAccessor(accessor, false);
    }

    return result;
  }

  @action
  setJSON(json) {
    try {
      const jsonObj = JSON.parse(json);
      const {handler, accessor, input} = this.props;
      handler.set(accessor, jsonObj);
      if (input && input.props && input.props.onChange) {
        input.props.onChange();
      }

    } catch (e) {
      console.log("Invalid JSON schema", e);
    }

    this.setEnabledField();
  }

  @action
  async loadFile(files) {
    const {handler, accessor, input, intl} = this.props;
    if (!files) {
      handler.set(accessor, undefined);
      return;
    }

    if (input.dataType === "string.json") {
      if (files.length) this.fileName = files[0].name;
      readFile(files[0], this.setJSON.bind(this), this.setEnabledField.bind(this));
      return;
    }

    if (input.dataType === "string.OpenApi") {
      this.errorStore.set('errors', null);
      const response = await readOpenApiFile(files[0], this.setEnabledField.bind(this), intl.formatMessage);
      if (response && response.errors) this.errorStore.set('errors', response.errors);
      else if (response && response.isValid) {
        this.fileName = files[0].name;
        this.setJSON(response.result);
      }
      return;
    }

    const {store} = handler;
    if (store && store.fileApi) {
      this.uploadFiles(files)
    }
  }

  downLoadLink() {
    const {handler, accessor, input} = this.props;
    const {store} = handler;
    const value = handler.get(accessor);

    if ($.get(value, "id") && store && store.fileApi) {
      let url = store.fileApi.url;
      const contextPath = input.contextPath + "/" + "file/" + $.get(value, "id");

      url = contextPath.startsWith("/")
        ? url + contextPath
        : url + "/" + contextPath;
      return url;
    }

  }

  validateFile(files) {
    const {input} = this.props;
    const file = files[0];
    if (file instanceof File) {
      let messageId;
      for (let i = 0; i < VALIDATE_FUNC.length; i++) {
        if (!VALIDATE_FUNC[i].func(file, input)) {
          messageId = VALIDATE_FUNC[i].messageId;
          break;
        }
      }

      if (messageId) {
        createAlertMessage(localeStore.localeMessages[messageId]);
        return false;
      }
    }

    return true;
  }

  handleOnChange = async (e) => {
    this.setDisabledField();

    const files = e.target.files;
    if (!(files instanceof FileList) || files.length === 0) {
      this.setEnabledField();
      return;
    }

    if (!this.validateFile(files)) {
      this.setEnabledField();
      return;
    }
    await this.loadFile(files);
    // if (this.props.input.props.onChange) {
    //   this.props.input.props.onChange(e);
    // }
  };

  handleDeleteFile = (e) => {
    this.setDisabledField();
    const {input} = this.props;

    this.removeFile()
      .then(isDelete => {
        if (isDelete) {
          if (input && input.props && input.props.onChange) {
            input.props.onChange();
          }
        }
      })
      .catch(error => {
        console.log("Failed remove file", error);
      })
      .finally(() => {
        this.setEnabledField();
      });
  };

  openSignModal() {
    const toggleStore = appStore.getStore("globalSign.ToggleStore", "ui");
    toggleStore && toggleStore.toggle && toggleStore.toggle();
  }

  handleSignFile = () => {

    const {handler, accessor, input} = this.props;
    const {store} = handler;

    if (store && store.fileApi) {
      this.setDisabledField();
      const props = {
        store,
        accessor,
        contextPath: input && input.contextPath,
        onChange: input && input.props && input.props.onChange
      };

      const globalSign = appStore.getStore("globalSignStore", "ui");

      globalSign.setDefaultState();

      globalSign.set(["signature"], handler.get(accessor + ".signature"));
      globalSign.set(["accessor"], accessor);
      globalSign.set(["cancelButton"], true);
      globalSign.set(["handler"], handler);
      globalSign.set(["contextPath"], input && input.contextPath);
      const closeAction = this.setEnabledField.bind(this);

      globalSign.action = () => signFile(props, closeAction);
      globalSign.cancelAction = closeAction;

      this.openSignModal();
    }
  };

  handleSignShow = () => {
    const {handler, accessor, input} = this.props;
    const {store} =handler;
    const globalSign = appStore.getStore("globalSignStore", "ui");
    this.setDisabledField();

    globalSign.setDefaultState();

    globalSign.set(["signature"], handler.get(accessor + ".signature"));
    globalSign.set(["fileId"], handler.get(accessor + ".id"));
    globalSign.set(["signButton"], false);
    globalSign.set(["cancelButton"], true);
    globalSign.set(["showField"], false);
    globalSign.set(["handler"], handler);
    globalSign.set(["accessor"], accessor);
    globalSign.set(["contextPath"], input && input.contextPath);

    globalSign.action = undefined;
    globalSign.cancelAction = this.setEnabledField.bind(this);

    this.openSignModal()
  };

  isSign() {
    const {handler, accessor} = this.props;
    const signature = handler.get(accessor + ".signature");
    return $.isArray(signature) && signature.length;

  }

  render() {
    if (this.isLoading) return <LoadSpinner isLoading={this.isLoading} size="sm" />;
    const {handler, accessor, input, bs = true, bsSize, placeholder, intl} = this.props;
    let {disabled} = this.props;
    let link = this.downLoadLink();
    const fileName = handler.get(accessor + ".name") || this.fileName;
    const fileId = handler.get(accessor + ".id");

    if (this.fileDisabled) {
      disabled = this.fileDisabled;
    }

    if(input.props && input.props.disabled) {
      disabled = input.props.disabled;
    }

    if(input.props && input.props.readOnly) {
      disabled = input.props.readOnly;
    }

    if (input.props.type === "json" || input.props.type === "OpenApi") {
      input.props.type = "file";
    }

    if (input.fileInput && input.fileInput === "dropzone") {
      return <DropzoneInput/>;
    }

    if (input.fileInput && input.fileInput === "image") {
      return <ImageModalView handler={handler} accessor={accessor} src={link} contextPath={input.contextPath} {...this.props} />
    }

    const InputComponent = bs ? Input_ : resolveHTMLInput(input.props);
    if (bsSize) input.props.bsSize = bsSize;

    const {file, hiddenDownloadImg,...other} = input.props;
    const isSignFile = this.isSign();
    const signImg = isSignFile ? ICON_SHIELD : ICON_FILE_SIGN;
    const signTitle = "ui.field.file." + (isSignFile ? "signatureFile" : "signFile");

    return (
      <Fragment>
        <div className={"d-flex align-items-center"}>
          { !disabled &&
            <InputComponent
              disabled={disabled}
              {...other}
              placeholder={placeholder}
              onChange={this.handleOnChange}
              className="d-none w-auto">
            </InputComponent>
          }
          {fileName && (
            <span
              style={{paddingLeft: '0.6rem', paddingRight: '0.6rem'}}
              className="text-break"
            >
              {fileName}
            </span>
          )}
          {link && !hiddenDownloadImg
            ? <Fragment>
              <a className="mr-2 " href={link} target={"_blank"}>
                <img className='wrap-svg-icon-add img-color-like-link' src={ICON_FILE_DOWNLOAD}
                     alt={intl.formatMessage({ id: "ui.field.file.downloadFile" })}
                     title={intl.formatMessage({id: "ui.field.file.downloadFile"})}/>
              </a>
              {/*<img className='mr-2 cursor-pointer wrap-svg-icon-add' src={ICON_FILE_DELETE} title={intl.formatMessage({id: "ui.field.file.deleteFile"})} />*/}
            </Fragment>
            : null
          }
          {fileId && !disabled
            ? <span className="mr-2 cursor-pointer" onClick={this.handleDeleteFile}>
                <img className='wrap-svg-icon-add img-color-like-link' src={ICON_FILE_DELETE}
                     alt={intl.formatMessage({ id: "ui.field.file.deleteFile" })}
                     title={intl.formatMessage({id: "ui.field.file.deleteFile"})}/>
              </span>
            : null
          }
          {input.showSign && fileId && !disabled
            ? <span className="mr-2 cursor-pointer" onClick={this.handleSignFile}>
                <img className='wrap-svg-icon-add img-color-like-link' src={signImg}
                     alt={intl.formatMessage({ id: signTitle })}
                     title={intl.formatMessage({id: signTitle})}/>
              </span>
            : null
          }
          {input.showSign && fileId && disabled && isSignFile
            ? <span className="mr-2 cursor-pointer" onClick={this.handleSignShow}>
                <img className='wrap-svg-icon-add img-color-like-link' src={ICON_SHIELD}
                     alt={intl.formatMessage({ id: "ui.field.file.signatureFile" })}
                     title={intl.formatMessage({id: "ui.field.file.signatureFile"})}/>
              </span>
            : null
          }

          { !disabled &&
            <label
              className="cursor-pointer mb-0"
              htmlFor={input.props.id}
              style={{
                paddingLeft: !fileName ? '0.6rem' : null,
                paddingRight: '0.6rem',
              }}
            >
              <img className={'wrap-svg-icon-add img-color-like-link'} src={ICON_FILE_ATTACH}
                   alt={intl.formatMessage({ id: "ui.field.file.attachFile" })}
                   title={intl.formatMessage({id: "ui.field.file.attachFile"})}/>
            </label>
          }
        </div>
        {this.errorStore && this.errorStore.get("errors") && (
          <Suspense fallback={null}>
            <ApiValidationErrorsModal
              errors={this.errorStore.get("errors")}
              id={input.props.id}
              title={intl.formatMessage({id: "ui.api.view.validation.header.fileUpload"})}
            />
          </Suspense>
        )}
      </Fragment>
    )
  }

}

FileInput.propTypes = {
  bsSize: PropTypes.string,
  inputProps: PropTypes.object,
  handler: PropTypes.object.isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string]).isRequired,
};

export default FileInput;
