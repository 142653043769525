import {UIStore} from "@isf/core-app-store";

class SignGlobalStore extends UIStore {
  _action;
  _cancelAction;

  set action(action) {
    this._action = action
  }

  get action() {
    return this._action;
  }

  set cancelAction(cancelAction) {
    this._cancelAction = cancelAction
  }

  get cancelAction() {
    return this._cancelAction;
  }

  setDefaultState() {
    this.set(["state"], "SLEEP");
    this.set(["flow_index"], -1);
    this.set(["signature"], undefined);
    this.set(["signButton"], true);
    this.set(["cancelButton"], false);
    this.set(["showField"], true);
    this.set(["loading"], false);
  }
}

export {SignGlobalStore};