import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";

// const LocaleSwitch = ({ name, onClick, main}) => {
//   const getImage = (name) => {
//     let image;
//     try {
//       image = require("../images/" + name + ".svg");
//     } catch (ex) {
//       return "";
//     }
//     return image.default;
//   };
//
//   const imgSource = getImage(name);
//   const className = main?"h-auto icon-w-30 ml-2 cursor-pointer":"h-auto icon-w-30 ml-2";
//   return (
//     <Fragment>
//       <img src={imgSource}
//            alt={name}
//            title={name}
//            className={className} onClick={onClick}/>
//     </Fragment>
//   );
// };
const LocaleSwitch = injectIntl(({ name, onClick, main, intl}) => {
  const getLocale = (name) => {
    return intl.formatMessage({id:'ui.localization.'+name});
  };

  const locale = getLocale(name);
  const className = main?"h-auto icon-w-35 ml-2 cursor-pointer":"h-auto icon-w-35 ml-2";
  return (
     <Fragment>
        <div onClick={onClick} className={className}>
          {locale}
        </div>
     </Fragment>
  );
});

LocaleSwitch.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string
};

export default LocaleSwitch;