import { action, computed } from "mobx";

import { DropdownStore } from "@isf/dropdown";
import { localeStore } from "@isf/core-localization";

class LocaleSelectStore extends DropdownStore {

  set localeStore(localeStore) {
    this._localeStore = localeStore;
  }

  get localeStore() {
    return this._localeStore || localeStore;
  }

  @action
  setValue(value) {
    this.localeStore.setLocale(value);
  }

  @computed
  get value() {
    return this.localeStore.locale;
  }
}

Object.defineProperty(LocaleSelectStore, "name", {value: "LocaleSelectStore"});

export default LocaleSelectStore;