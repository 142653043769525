import { action, computed, toJS } from "mobx";

import { Store } from "@isf/core-app-store";

import { getEnvVariable } from "@isf/core-system-util";
import { default as engine, forceClearLocalStorage, loadApplication } from "@isf/ui-schema/src/ui-engine";
import identityApi from "./identity-api";
import { initUI } from "../init";
import { themeStore } from "../theme/theme";
import { hostnameInfo } from "@isf/common";

import { loadAvailableLocale } from "../localization/load-locale";
import { restApi } from "@isf/ui-schema";
import { resolveInitialLocale } from "@isf/core-localization/src/locale-util";

const nativeIdP = getEnvVariable("NATIVE_IDP");

class UserStore extends Store {

  _intervalSync;

  constructor() {
    super({
      path: 'user',
      scope: "user",
      state: {
        authenticated: false,
        initialized: false
      },
      observableAsMap: true
    });
  }


  async syncUser() {
    const mvdSynced = this.get("user.currentAccount.mvdSynced");
    const egrSynced = this.get("user.currentAccount.egrSynced");

    if (mvdSynced !== false && egrSynced !== false) {
      clearInterval(this._intervalSync);
      return;
    }
    const user = await identityApi.getUser();

    if (!user) {
      this.set('initialized', true);
      return;
    }

    this.setUser(user);
  }

  async initialize() {
    await loadAvailableLocale();

    const user = await identityApi.getUser();

    if (user && user.currentAccount && !user.currentAccount.defaultLanguage) {
      let lang = resolveInitialLocale() || 'ru';

      restApi.request({
        path: "/umgt/account/v1/{accountId}/x-current-account",
        options: {
          method: "put",
          body: JSON.stringify({
            defaultLanguage: lang
          })
        }
      });
    }

    if (!user && nativeIdP === 'enabled') {
      this.set('initialized', true);
      return;
    }

    this.authorize(user);
  }

  async authorize(user) {
    hostnameInfo.isAdmin = Boolean(
      user && user.currentAccount && user.currentAccount.isAdmin
    );

    clearInterval(this._intervalSync);
    this._intervalSync = setInterval(this.syncUser.bind(this), 10000);

    await loadApplication();

    const configurationId = engine.getTemplateConfigurationId();
    const permissions = await engine.loadTemplPermissions();

    this.setResourcePermissions(configurationId, permissions); //TODO: use configuration id
    this.setPermissions(permissions);

    await themeStore.initialize();

    this.setUser(user);
    initUI();
  }

  @action
  setUser(user) {
    this.set('user', user);
    this.set('initialized', true);
  }

  @action
  setPermissions(permissions) {
    this.set('permissions', permissions);
  }

  setResourcePermissions(resourceId, permissions) {
    this.set(['resourcePermissions', resourceId], permissions);
  }

  getResourcePermissions(resourceId) {
    return this.get(['resourcePermissions', resourceId]);
  }

  addResourcePermission(resourceId, permission) {
    this.push(['resourcePermissions', resourceId],permission);
  }

  @computed
  get isLoginRequired() {
    return (this.initialized && !this.authenticated);
  }

  @computed
  get permissions() {
    return this.get('permissions');
  }

  @computed
  get authenticated() {
    return !!this.get('user');
  }

  @computed
  get initialized() {
    return this.get('initialized');
  }

  @computed
  get firstName() {
    return this.get('user.currentAccount.firstName');
  }

  @computed
  get lastName() {
    return this.get('user.currentAccount.lastName');
  }

  @computed
  get fullName() {
    return (this.firstName || "") + ' ' + (this.lastName || "");
  }

  @computed
  get currentAccount() {
    return this.get('user.currentAccount', true);
  }

  @computed
  get accounts() {
    const accounts = this.get('user.accounts');
    if (accounts) {
      return toJS(accounts);
    }
    return [];
  }

  @computed
  get authTypeCode() {
    return this.get("user.authTypeCode");
  }

  @computed
  get email() {
    return this.get('user.email');
  }

  get isStrongUser() {
    const { authTypeCode } = this;
    const accountType  = this.get('user.currentAccount.accountType.accountType');
    return accountType === "p" && authTypeCode === 'STR'
  }

  @computed
  get isInactiveAccount() {
    return this.isStrongUser && this.get('user.currentAccount.profileReady') === false;
  }

  @computed
  get isLAXInactiveAccount() {
    return this.authTypeCode === "LAX" && this.get("user.currentAccount.profileReady") === false;
  }

  @computed
  get getIdpName() {
    return this.get("user.idPName");
  }

  async switchAccount(id) {
    const path = `/switch?id=${id}`;
    await identityApi.request({ path });
    window.location.href = '/';
  }

}

const userStore = new UserStore();
userStore.initialize();

export default userStore;
