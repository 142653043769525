import { observable, action, computed } from "mobx";
import { THEMES, DEFAULT_THEME } from "./themes";
import { DropdownStore } from "@isf/dropdown";
class HelmetStore extends DropdownStore {

  @observable _css = localStorage.getItem("bootstrapTheme") || DEFAULT_THEME;

  @action
  setValue(value) {
    localStorage.setItem("bootstrapTheme", value);
    this._css = localStorage.getItem("bootstrapTheme");
  }

  @computed
  get value() {
    return this._css;
  }

}

export { HelmetStore };