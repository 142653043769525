import appStore, {UIStore} from "@isf/core-app-store";
import {autorun, computed, action, reaction} from "mobx";
import {storeTemplToPath} from "../util";
import $ from "@isf/core-object-util";
import {createAlertMessage} from "../create-alert-message";
import {uuidv4} from "@isf/core-system-util";
import {initializeStoreStructure} from "./ui-common-store";
import {
  getString,
  getListData,
  getDeclaredString,
  NOT_HIDDEN,
  NOT_READ_ONLY,
  NOT_COMPUTED,
  NOT_RESTRICTIONS
} from "../expression/utils";
import {UiDisposeStore} from "./ui-dispose-store";


const initialize = (props,id, store) => {
  store._computedStore = new UIFieldComputedStore(id,props);
  store._hiddenStore = new UIFieldHiddenStore(id,props);
  store._readOnlyStore = new UIFieldReadOnlyStore(id,props);
  store._restrictionsStore = new UIFieldRestrictionsStore(id, props);
};

class UIFieldStore /*extends UIStore */{

  constructor(props) {
    const id = 'uiFieldStore.'+uuidv4();
   /* super({path:id});*/
    initialize(props,id,this)
  }

  _computedStore;

  _hiddenStore;

  _readOnlyStore;

  _restrictionsStore;

  @computed
  get computedStore() {
    return this._computedStore;
  }

  @computed
  get hiddenStore() {
    return this._hiddenStore;
  }

  @computed
  get readOnlyStore() {
    return this._readOnlyStore;
  }

  @computed
  get restrictionsStore() {
    return this._restrictionsStore;
  }

  dispose(){
    this._computedStore.dispose();
    this._hiddenStore.dispose();
    this._readOnlyStore.dispose();
    this._restrictionsStore.dispose();
  }

}

class UIFieldComputedStore/* extends UIStore */extends UiDisposeStore{
  constructor(parentId,props){
    super(props);
    const parentArrPath = parentId.split('.');
    parentArrPath.splice(1,0,'computed');
   /* super({path:parentArrPath});*/
    this.localeStore = props.localeStore;
    this.map = $.objectToObservableMap({});
    initializeComputedAutorun(props,this)
  }

  get=(path)=>{
    return this.map.get(path)
  };

  set=(path, value)=>{
    this.map.set(path,value)
  }
}

// const getString = (props, store) => {
//   const {params} = props.computed;
//   /*const refsLoadedStore = appStore.getStore('globalLoadedRefsStore', 'ui');*/
//   if (params.length !== 0) {
//     return {value:getDeclaredString(params, store.localeStore, props.data, {refId: props.refId})/*,changedRefs:refsLoadedStore.stateJS*/};//changedRefs for rerun autorun on load ref component
//   }
// };

const setValue = (props, declaredString) => {
  const {expression} = props.computed;
  const result = eval(declaredString + expression);
  if (props.data) {
    const listData = getListData(props.data);
    $.set(listData, props.accessor, result)

  } else {

    const newStoreName = props.refId ? props.refId + "." + props.storeName : props.storeName;
    const s = appStore.getDataStore(newStoreName);
    s.set(props.accessor, result)
  }
};

const initializeComputedAutorun = (props, store) => {
  if (props.computed && !NOT_COMPUTED.includes(props.computed)/*props.computed !== 'condition'*/) {
    const {params, expression} = props.computed;
    try {
      if (params && params.length !== 0) {
        setValue(props, getString(params, props, store));
        store.addAutorun(reaction(
          getString.bind(undefined, params, props, store),
          setValue.bind(undefined, props)
        ))

      } else {
        const newStoreName = props.refId ? props.refId + "." + props.storeName : props.storeName;
        const s = appStore.getDataStore(newStoreName);
        s.set(props.accessor, eval(expression))
      }

    } catch (e) {

      // createAlertMessage("Failed execute computed value" + e.message, "error"); //For future
      console.error("Failed execute computed value", e)
    }
  }
};

class UIFieldHiddenStore/* extends UIStore */ extends UiDisposeStore {
  constructor(parentId, props) {
    super(props);
    const parentArrPath = parentId.split('.');
    parentArrPath.splice(1, 0, 'hidden');
    /*super({path:parentArrPath});*/
    this.localeStore = props.localeStore;
    this.map = $.objectToObservableMap({});
    initializeHiddenAutorun(props, this);
  }

  get = (path) => {
    return this.map.get(path)
  };

  set = (path, value) => {
    this.map.set(path, value)
  };
}


const initializeHiddenAutorun =(props,store)=>{
  if(props.hidden === 'true' || props.hidden === 'false'){
    store.set('isHidden', eval(props.hidden))
  }else if (props.hidden && !NOT_HIDDEN.includes(props.hidden)/*props.hidden.expression*/) {
    const hiddenParams = props.hidden && props.hidden.params || [];
    const hiddenExpression = props.hidden && props.hidden.expression || {};
    store.addAutorun(autorun(() => {
      let declaredString = "";
      if (hiddenParams.length !== 0) {
        declaredString = getDeclaredString(hiddenParams, store.localeStore, props.data, {refId:props.refId});
        declaredString !== "let;" && store.set('isHidden', eval(declaredString + hiddenExpression))
      }else{
        store.set('isHidden', eval(hiddenExpression));
      }

    }));
  }
};

class UIFieldReadOnlyStore/* extends UIStore */extends UiDisposeStore{
  constructor(parentId,props){
    super(props);
    const parentArrPath = parentId.split('.');
    parentArrPath.splice(1,0,'readOnly');
    /*super({path:parentArrPath});*/
    this.localeStore = props.localeStore;
    this.map = $.objectToObservableMap({});
    initializeReadOnlyAutorun(props,this)
  }

  get=(path)=>{
    return this.map.get(path)
  }

  set=(path, value)=>{
    this.map.set(path,value)
  }
}


const initializeReadOnlyAutorun =(props,store)=>{
  if(props.readOnly === 'true' || props.readOnly === 'false'){
    store.set('isReadOnly', eval(props.readOnly))
  }else if (props.readOnly && !NOT_READ_ONLY.includes(props.readOnly)/*props.readOnly.expression*/) {
    const readOnlyParams = props.readOnly && props.readOnly.params || [];
    const readOnlyExpression = props.readOnly && props.readOnly.expression || {};
    store.addAutorun(autorun(() => {
      let declaredString = "";
      if (readOnlyParams.length !== 0) {
        declaredString = getDeclaredString(readOnlyParams, store.localeStore, props.data, {refId:props.refId});
        declaredString !== "let;" && store.set('isReadOnly', eval(declaredString + readOnlyExpression))
      }

    }))
  }
};

class UIFieldRestrictionsStore extends UiDisposeStore{
  constructor(parentId, props) {
    super(props);
    this.localeStore = props.localeStore;
    this.map = $.objectToObservableMap({});
    initializeRestrictionsAutorun(props, this);
  }

  get = path => {
    return this.map.get(path);
  }

  set = (path, value) => {
    this.map.set(path, value);
  }
}

const initializeRestrictionsAutorun = (props, store) => {
  if (props.minDate && !NOT_RESTRICTIONS.includes(props.minDate)) initializeRestrictionsItem(props, store, "minDate");
  if (props.maxDate && !NOT_RESTRICTIONS.includes(props.maxDate)) initializeRestrictionsItem(props, store, "maxDate");
  if (props.min && !NOT_RESTRICTIONS.includes(props.min)) initializeRestrictionsItem(props, store, "min");
  if (props.max && !NOT_RESTRICTIONS.includes(props.max)) initializeRestrictionsItem(props, store, "max");
};

const initializeRestrictionsItem = (props, store, path) => {
  if (props[path]) {
    const params = props[path] && props[path].params || [];
    const expression = props[path] && props[path].expression || {};
    store.addAutorun(autorun(() => {
      let declaredString = "";
      if (params.length !== 0) {
        declaredString = getDeclaredString(params, store.localeStore, props.data, {refId:props.refId});
        declaredString !== "let;" && store.set(path, eval(declaredString + expression))
      } else store.set(path, eval(expression));
    }));
  }
};

export {UIFieldStore}