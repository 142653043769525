import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { Toggle } from "./toggle";

const ToggleButton = (props) => {
  if (props.hidden) {
    return null;
  }
  return (
    <Toggle>
      <Button {...props} />
    </Toggle>
  );
}

ToggleButton.propsTypes = PropTypes.instanceOf(Button);

export { ToggleButton };