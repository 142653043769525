import React from "react";
import { observer, Provider } from "mobx-react";
import { Router } from "react-router-mobx";
import { BrowserRouter } from "react-router-dom";

import { routerStore } from '@isf/core-app-store';
import { LocalizationProvider } from "@isf/localization";
import { Helmet } from "@isf/common";
import { getEnvVariable } from "@isf/core-system-util";

import Login from "./header/identity/login";
import LoginView from "./identity/login-view";

import "./conf";
import userStore from "./identity/user-store";
import { PageView } from "./page";
import { themeStore } from "./theme/theme";

const nativeIdP = getEnvVariable("NATIVE_IDP");

@observer
class App extends React.Component {
  render() {
    return (
      <>
        <Helmet helmetStore={themeStore}/>
        <Router component={BrowserRouter} routerStore={routerStore.mobxStore}>
          <>
            {
              userStore.isLoginRequired ?
                (
                  nativeIdP === "enabled" ?
                    <LoginView/>
                    : <Login/>
                )
                :
                <LocalizationProvider>
                  <>
                    <Provider userStore={userStore} helmetStore={themeStore}>
                      <PageView/>
                    </Provider>
                  </>
                </LocalizationProvider>
            }
          </>
        </Router>
      </>
    );
  }
}

export default App;