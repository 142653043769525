import {DataStore} from "@isf/core-app-store";
import {action, computed} from "mobx";
import $ from "@isf/core-object-util/src/object-util";
import {observableForEach} from "./open-api-utils";
// import {filterSchemeModel} from "./filter";
import {getMappingObj, getMappingObjChild, isLastPropMapping} from "./config-constants";
// import {isLocalizedField} from "./localization-field";

const ADDITIONAL_SETTINGS = {
  "base64": {
    "type": "file_d",
    "format": "base64"
  },
  "fileLink": {
    "type": "file_d",
    "format": "link"
  },
  "transient": {
    "transient": true
  },
  "local": {
    "local": true
  },
  "transLocal": {
    "transient": true,
    "local": true
  }
};

class MappingStore extends DataStore {

  // PRIMITIVE_TYPE = ["string", "number", "boolean"];
  _configStore;

  constructor({configStore, ...props}) {
    super(props);
    this._configStore = configStore;
  }

  get configStore() {
    return this._configStore;
  }

  @action
  setMappingValue = (requestOrResponse, destPath, value, type, mappingSettings) => {
    let path = $.strToArray(destPath);
    path = path.map(item => `${item}`);
    if (type) {
      this.set([requestOrResponse, ...path, type], value);
      const transform = this.getMappingTransform(mappingSettings, "variable");
      !!transform && this.set([requestOrResponse, ...path, "settings"], transform);
      return;
    }

    this.set([requestOrResponse, ...path], value);
  };

  getMappingTransform(mappingSettings, type) {
    let name  = undefined;
    const isBase64File = $.get(mappingSettings, ["file_d", "base64"]);
    const isLinkFile = $.get(mappingSettings, ["file_d", "link"]);
    const isTransientVar = $.get(mappingSettings, ["variable", "transient"]);
    const isLocalVar = $.get(mappingSettings, ["variable", "local"]);

    if(type === "primitive") {
      if (isBase64File) {
        name = "base64";

      } else if (isLinkFile) {
        name = "fileLink";
      }

    } else if(type === "variable") {

      if (isTransientVar && isLocalVar) {
        name = "transLocal";

      } else if (isTransientVar) {
        name = "transient";

      } else if (isLocalVar) {
        name = "local";
      }
    }

    return ADDITIONAL_SETTINGS[name];
  }

  getConfigTransform(transform) {
    const type = $.get(transform, ["type"]);
    const format = $.get(transform, ["format"]);
    if(type === "file_d" && ["base64", "link"].includes(format)) {
      return {file_d: {[format]: true}}
    }
  }

  getSettings(mapping, type) {
    if(type === "variable") {
      return {
        "variable": {
          transient: $.get(mapping, ["dest", "transient"]),
          local: $.get(mapping,  ["dest", "local"])
        }
      };
    } else if(type === "primitive") {
      return this.getConfigTransform($.get(mapping, ["source", "transform"]));
    }
  }

  @action
  setPrimitive = (requestOrResponse, destPath, value, type, mappingSettings) => {
    let path = $.strToArray(destPath);
    path = path.map(item => `${item}`);
    this.set([requestOrResponse, ...path], { [type]: value });

    let newPath = path;
    const indexAP = path.indexOf("additionalProperties");
    if (indexAP !== -1) {
      newPath = [...path.slice(0, indexAP), ...path.slice(indexAP + 1)];
    }
    const indexArr = newPath.indexOf("items");
    if (indexArr !== -1) {
      if (!this.get([`${requestOrResponse}Body`, ...newPath.slice(0, indexArr)])) {
        this.set([`${requestOrResponse}Body`, ...newPath.slice(0, indexArr)], [])
      }
      newPath = [...newPath.slice(0, indexArr), ...newPath.slice(indexArr + 1)];
    }
    this.set([`${requestOrResponse}Body`, ...newPath], value);

    const transform = this.getMappingTransform(mappingSettings, "primitive");
    !!transform && this.set([requestOrResponse, ...path, "settings"], transform);
  };

  @action
  setArraySource = (requestOrResponse, destPath, sourcePath) => {
    this.setMappingValue(requestOrResponse, destPath, sourcePath, "source");
  };

  @action
  setObjSource = (requestOrResponse, destPath, destSchema, sourcePath, sourceSchema) => {
    // const properties = $.get(sourceSchema, "properties");
    // destPath = $.strToArray(destPath);
    //
    // observableForEach(properties, (value, key) => {
    //   if ($.get(destSchema, ['properties', key])) {
    //     const newPath = [...sourcePath, key];
    //     const isLocalized = isLocalizedField(value);
    //
    //     if(isLocalized) {
    //       if(filterSchemeModel(value, $.get(destSchema, ['properties', key])).length === 0) {
    //         this.setArraySource(requestOrResponse, [...destPath, key], newPath);
    //       }
    //
    //     } else if ($.get(value, "type") === "object") {
    //       this.setObjSource(requestOrResponse, [...destPath, key], $.get(destSchema, ['properties', key]), newPath, value);
    //
    //     } else if ($.get(value, "type") === "array") {
    //
    //       if (filterSchemeModel(value, $.get(destSchema, ['properties', key])).length === 0) {
    //         this.setArraySource(requestOrResponse, [...destPath, key], newPath);
    //       }
    //     } else {
    //
    //       if (filterSchemeModel(value, $.get(destSchema, ['properties', key])).length === 0) {
    //         this.setPrimitive(requestOrResponse, [...destPath, key], newPath, "source");
    //       }
    //     }
    //   }
    // })
  };

  getRequestMapSource = (type, value, id, context, responses, transform) => {
    if (type === "path") {
      return {
        type,
        id: value[0] === context ? id : responses[value[0]],
        variable: value[0],
        value: ["body", ...value.slice(1)].join('.'),
        transform: transform
      };
    }

    return { type, id, value };
  };

  getRequestMapDest = (type, valueType, path) => {
    if (type === "path") {
      return {
        type,
        parameterType: path[0],
        value: {
          type: valueType,
          value: !["body", "serviceParameters"].includes(path[0])
            ? [...path.slice(1)].join('.')
            : ["body", ...path.slice(1)].join('.')/*path.join('.'),*/
        }
      }
    }
  };

  getPrimitiveMap(schema) {
    let primitiveType = undefined;
    const source = $.get(schema, "source");
    const type = $.get(schema, "type");
    const additionalProperties = $.get(schema, "additionalProperties");

    if (isLastPropMapping({data: schema})) {
      const value = $.get(schema, "value");
      if ($.isArray(value) || !$.isObject(value)/*this.PRIMITIVE_TYPE.includes(typeof(value))*/) {
        primitiveType = value !== undefined ? "value" : "empty";
      }

      if ($.isArray(source) && type !== "variable") {
        primitiveType = "source";
      }
    } else if ($.isArray(source) && type !== "variable" && $.isObject(additionalProperties)) {
      primitiveType = "source";
    }

    // } else if (type !== "variable" && $.isObject(additionalProperties)) {
    //   primitiveType = $.isArray(source) ? "source" : "empty";
    // }

    return primitiveType;
  }

  getRequestMapping(schema, id, context, responses, path = []) {
    const mapping = [];
    const getSource = this.getRequestMapSource;
    const getDest = this.getRequestMapDest;

    observableForEach(schema, (value, key) => {
      let map = { source: {}, dest: {} };
      const primitiveType = this.getPrimitiveMap(value);

      // if (key === "source" && $.isArray(value)) {
      // if(primitiveType === "source") {
      //   map.source = getSource("path", value, id, context, responses);
      //   map.dest = getDest("path", "primitive", path);
      // }

      // if (key === "value" && (this.PRIMITIVE_TYPE.includes(typeof(value)) || $.isArray(value))) {
      if(primitiveType && primitiveType !== "empty") {
        const valueObj = $.get(value, [primitiveType]);
        const valueJS = $.isArray(valueObj) ? $.toJS(valueObj) : valueObj;
        const transform = $.toJS($.get(value, "settings"));
        const type = primitiveType === "source" ? "path" : "value";
        map.source = getSource(type, valueJS, id, context, responses, transform);
        map.dest = getDest("path", "primitive", [...path, key]);
      }

      if ($.isArray($.get(value, 'items'))) {
        const childPath = path ? [...path, key] : [key];

        if ($.get(value, 'source')) {
          map.source = getSource("path", $.get(value, 'source'), id, context, responses);
          map.dest = getDest("path", "array", childPath);

        }

        if ($.get(value, 'items').length > 0) {
          $.get(value, 'items').forEach((el, index) => {
            mapping.push(
              ...this.getRequestMapping({ [`[${index}]`]: el }, id, context, responses, childPath)
            )
          });
        }
      }

      if ($.isObject(value) && !$.isArray($.get(value, 'items')) && !primitiveType) {
        const childPath = path ? [...path, key] : [key];
        key === "additionalProperties" && childPath.pop();
        this.getRequestMapping(value, id, context, responses, childPath).forEach(el => mapping.push(el))
      }

      if (map.dest.type) {
        mapping.push(map);
      }
    });
    return mapping;
  }


  getResponseMapSource = (path, transform) => {
    return {
      type: "path",
      parameterType: path[0],
      value: [...path.slice(1)].join('.'),
      transform: transform
    };
  };

  getResponseMapDest = (type, valueType, path, setMerge, settings) => {
    if (type === "path") {
      const mapDest = {
        type,
        ...settings,
        value: {
          type: valueType,
          variable: path[0],
          value: ["body", ...path.slice(1)].join('.'),
        }
      };

      if(valueType === "array") {
        mapDest.value["setMerge"] = setMerge;
      }

      return mapDest;
    }

  };

  getResponseMapping(schema, path = []) {
    const mapping = {};
    const getSource = this.getResponseMapSource;
    const getDest = this.getResponseMapDest;

    observableForEach(schema, (value, key) => {
      const map = { source: {}, dest: {} };
      const childPath = path ? [...path, key] : [key];
      key === "additionalProperties" && childPath.pop();
      const primitiveType = this.getPrimitiveMap(value);

      // if (key === "source" && $.isArray(value)) {
      if(primitiveType === "source") {
        const valueObj = $.get(value, [primitiveType]);
        const transform = $.toJS($.get(value, ["settings"]));
        const status = valueObj[0];

        if (!mapping[status]) mapping[status] = [];

        map.source = getSource(valueObj.slice(1), transform);
        map.dest = getDest("path", "primitive", [...path, key]);
        mapping[status].push(map);
      }

      if ($.get(value, 'type') === "variable") {
        const status = $.get(value, 'source')[0];
        const settings = $.toJS($.get(value, ["settings"]));

        if (!mapping[status]) mapping[status] = [];

        map.source = getSource($.get(value, 'source').slice(1));
        map.dest = getDest("path", "variable", childPath, undefined, settings);
        mapping[status].push(map);
      }

      if ($.isArray($.get(value, 'items'))) {
        let arrayMap = {};

        if ($.get(value, 'source')) {
          const status = $.get(value, 'source')[0];
          const setMerge = $.get(value, 'setMerge');

          arrayMap[status] = [{
            source: getSource($.get(value, 'source').slice(1)),
            dest: getDest("path", "array", childPath, setMerge)
          }];

        }

        if ($.get(value, 'items').length > 0) {
          $.get(value, 'items').forEach((el, index) => {
            const childMap = this.getResponseMapping({ [`[${index}]`]: el }, childPath);

            for (let status in childMap) {
              if (arrayMap[status]) {
                arrayMap[status].push(
                  ...childMap[status]
                );
                continue;
              }

              arrayMap[status] = childMap[status];
            }
          });
        }

        for (let status in arrayMap) {
          if (!mapping[status]) mapping[status] = [];

          mapping[status].push(...arrayMap[status]);
        }
      }

      if ($.isObject(value) && !$.isArray($.get(value, 'items')) && $.get(value, 'type') !== "variable" && !primitiveType) {
        const childMap = this.getResponseMapping(value, childPath);

        for (let status in childMap) {
          if (!mapping[status]) mapping[status] = [];

          mapping[status].push(...childMap[status]);
        }
      }
    });
    return mapping;
  }

  getConfigVariablesMapping(schema, id, context, responses, path = []) {
    const mapping = [];
    const getSource = this.getRequestMapSource;
    const getDest = this.getResponseMapDest;

    observableForEach(schema, (value, key) => {
      let map = { source: {}, dest: {} };
      const primitiveType = this.getPrimitiveMap(value);

      if(primitiveType && primitiveType !== "empty") {
        const valueObj = $.get(value, [primitiveType]);
        const valueJS = $.isArray(valueObj) ? $.toJS(valueObj) : valueObj;
        const transform = $.toJS($.get(value, "settings"));
        const type = primitiveType === "source" ? "path" : "value";
        map.source = getSource(type, valueJS, id, context, responses, transform);
        map.dest = getDest("path", "primitive", [...path, key]);
      }

      // if (key === "source" && $.isArray(value)) {
      //   map.source = getSource("path", value, id, context, responses);
      //   map.dest = getDest("path", "primitive", path);
      // }
      //
      // if (key === "value" && ($.isArray(value) || (!$.isObject(value) && value !== undefined))) {
      //   // this.PRIMITIVE_TYPE.includes(typeof(value))) {
      //   map.source = getSource("value", value, id, context, responses);
      //   map.dest = getDest("path", "primitive", path);
      // }

      if ($.get(value, 'type') === "variable") {
        const settings = $.toJS($.get(value, ["settings"]));
        map.source = getSource("path", $.get(value, "source"), id, context, { [$.get(value, "source")[0]]: id });
        map.dest = getDest("path", "variable", [key], undefined, settings);
      }

      if ($.isObject(value) && !$.isArray($.get(value, 'items')) && $.get(value, 'type') !== "variable" && !primitiveType) {
        const childPath = path ? [...path, key] : [key];
        this.getConfigVariablesMapping(value, id, context, responses, childPath)
          .forEach(el => mapping.push(el))
      }

      if (map.dest.type) {
        mapping.push(map);
      }
    });
    return mapping;
  }

  @action
  addAdditionalValue(requestOrResponse, schema, path, propsName) {
    const arrPath = $.strToArray(path);
    const itemSchema = getMappingObjChild(schema, arrPath);

    this.set([requestOrResponse, ...arrPath, propsName], getMappingObj(itemSchema));
  }

  @action
  addArrayValue = (requestOrResponse, schema, path) => {//TODO
    const arrPath = $.strToArray(path);
    const itemSchema = getMappingObjChild(schema, arrPath);

    const array = this.get([requestOrResponse, ...arrPath]);
    array.push(getMappingObj(itemSchema));
  };

  createAdditionalItemsPath(path, type, dataStore) {
    let arrPath = path.slice();
    let shortPath = [];

    arrPath.forEach(segment => {
      const isAdditionalProps = getMappingObjChild(this.configStore[dataStore],[...shortPath, "additionalProperties"]);
      if(isAdditionalProps) {
        shortPath.push("additionalProperties");
      }

      if (segment.startsWith("[") && segment.endsWith("]")) {
        shortPath.push("items");
        segment = segment.slice(1, -1);
      }

      shortPath.push(segment);
    });

    return shortPath;
  }

  @action
  createArrayItemsAndAdditionalProps(path, type, dataStore) {
    // const strPath = path.join('.');
    if (/*strPath.includes(".[") ||*/path.includes("items") || path.includes("additionalProperties")) {
      let arrPath = path.slice();
      let index, existItems, count, newPath;

      for (let i = 1, pathLength = arrPath.length; i < pathLength; i++) {

        if(arrPath[i - 1] === "items") {
          index = parseInt(arrPath[i]);
          if (!isNaN(index)) {
            newPath = arrPath.slice(0, i);

            existItems = this.get([type, ...newPath]).length - 1;
            count = index - existItems;

            while (count > 0) {
              this.addArrayValue(type, this.configStore[dataStore], [...newPath]);
              count--;
            }
          }

        } else if(arrPath[i - 1] === "additionalProperties") {
            const shortPath = arrPath.slice(0, i);
            if(!this.get([type, ...shortPath, arrPath[i]])) {
              this.addAdditionalValue(type, this.configStore[dataStore], shortPath, arrPath[i])
            }
        }

        // if (arrPath[i].startsWith("[") && arrPath[i].endsWith("]")) {
        //   index = parseInt(arrPath[i].slice(1, -1));
        //
        //   if (!isNaN(index)) {
        //     newPath = this.changeDestPath(arrPath.slice(0, i));
        //
        //     existItems = this.get([type, ...newPath, "items"]).length - 1;
        //     count = index - existItems;
        //
        //     while (count > 0) {
        //       this.addArrayValue(type, this.configStore[dataStore], [...newPath, "items"]);
        //       count--;
        //     }
        //   }
        //
        // } else if(arrPath[i] === "additionalProperties" && pathLength - 1 !== i) {
        //
        //   const shortPath = arrPath.slice(0, i + 1);
        //   this.addAdditionalValue(type, this.configStore[dataStore], shortPath, arrPath[i + 2])
        // }
      }
    }
  }

  changeDestPath(destPath) {
    const path = [];
    destPath.forEach(item => {
      if (item.startsWith("[") && item.endsWith("]")) {
        path.push("items");
        item = item.slice(1, -1);
      }
      path.push(item);
    });

    return path;
  }

  getRequestMappingSchema = (mappings, storeName = "parameterTreeModel") => {
    if (!$.isArray(mappings)) return;

    mappings.forEach(map => {
      const dest = $.get(map, "dest");
      const parameterType = $.get(dest, "parameterType");
      const value = $.get(dest, "value.value");

      let destPath = !["body", "serviceParameters"].includes(parameterType)
        ? [parameterType, ...value.split('.')]
        : [parameterType, ...value.split('.').slice(1)];

      destPath = this.createAdditionalItemsPath(destPath, "request", storeName);
      this.createArrayItemsAndAdditionalProps(destPath, "request", storeName);

      if ($.get(dest, "type") === "path") {
        const sourcePath = $.get(map, "source.variable")
          ? [$.get(map, "source.variable"), ...$.get(map, "source.value").split('.').slice(1)]
          : $.get(map, "source.value");

        // const path = this.changeDestPath(destPath);
        const path = destPath;

        if ($.get(dest, "value.type") === "array") {
          this.setArraySource("request", path, sourcePath);

        } else {
          const type = $.get(map, "source.type") === "path" ? "source" : "value";
          // const transform = this.getConfigTransform($.get(map, ["source", "transform"]));
          const settings = this.getSettings(map, "primitive");
          this.setPrimitive("request", path, sourcePath, type, settings);
        }
      }
    });
  };

  getResponseMappingSchema = (mappings, status = "", storeName = "documentResponseTreeModel") => {
    if (!$.isArray(mappings)) return;

    mappings.forEach(map => {
      const dest = $.get(map, "dest");

      let destPath = [$.get(dest, "value.variable"), ...$.get(dest, "value.value").split('.').slice(1)];

      destPath = this.createAdditionalItemsPath(destPath, "response", storeName);
      this.createArrayItemsAndAdditionalProps(destPath, "response", storeName);


      if ($.get(dest, "type") === "path") {
        const sourcePath = [status, $.get(map, "source.parameterType"), ...$.get(map, "source.value").split('.')];

        // const path = this.changeDestPath(destPath);
        const path = destPath;
        // destPath.replace(/(\.\[)/gi, '.items.').replace(/(\])/gi, '');

        if ($.get(dest, "value.type") === "array") {
          this.setArraySource("response", path, sourcePath);
          const setMerge = $.get(dest, "value.setMerge");
          this.setMappingValue("response", path, setMerge, "setMerge");

        } else if ($.get(dest, "value.type") === "variable") {
          const settings = this.getSettings(map, "variable");
          this.setPrimitive("response", path, sourcePath, "source");
          this.setMappingValue("response", path, "variable", "type", settings);

        } else {
          // const settings = this.getConfigTransform($.get(map, ["source", "transform"]));
          const settings = this.getSettings(map, "primitive");
          this.setPrimitive("response", path, sourcePath, "source", settings);
        }
      }
    });
  };

  getConfigVariablesSchema = (mappings, id) => {
    if (!$.isArray(mappings)) return;

    mappings.forEach(map => {
      const dest = $.get(map, "dest"),
        type = $.get(map, "source.type") === "path" ? "source" : "value";

      const sourcePath = type === "source"
        ? [$.get(map, "source.variable"), ...$.get(map, "source.value").split('.').slice(1)]
        : $.get(map, "source.value");

      const path = [$.get(dest, "value.variable"), ...$.get(dest, "value.value").split('.').slice(1)];

      if ($.get(map, "source.id") === id && $.get(dest, "value.type") === "variable") {
        const settings = this.getSettings(map, "variable");
        this.setPrimitive("configVariables", path, sourcePath, "source");
        this.setMappingValue("configVariables", path, "variable", "type", settings);

      } else {
        const settings = this.getSettings(map, "primitive");
        this.setPrimitive("configVariables", path, sourcePath, type, settings);
      }
    });
  };

  getMappingObj(service, context, responses) {
    const id = $.get(service, "id"),
      path = $.get(service, "path"),
      method = $.get(service, "method"),
      contextPath = $.get(service, "contextPath"),
      contentType = $.get(service, "contentType");

    return {
      // serviceId: id,
      configurationId: id,
      path: path,
      contextPath: contextPath,
      method: method,
      request: {
        mappings: this.getRequestMapping(this.get('request'), id, context, responses)
      },
      response: {
        mappings: this.getResponseMapping(this.get('response'))
      },
      conf_variables: {
        mappings: this.getConfigVariablesMapping(this.get('configVariables'), id, context, responses)
      }
    }
  }
}

export {MappingStore};