import React, { Component } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { CardHeader, Row } from "@isf/bootstrap";
import { Button, Message } from "../../form"
import { ExpandCollapseContext } from "./expand-collapse";

@observer
class ExpandCollapseHeader extends Component {
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {children, className} = this.props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <CardHeader className={classNames("px-0 pb-0 pt-2 expand-collapse-header", className, computedClassNames)}>
        <Row className="no-gutters justify-content-between align-items-center">
          {children}
        </Row>
      </CardHeader>
    )
  }
}

@observer
class ExpandCollapseToggle extends Component {
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    if (props.uiStoreType) this.uiStore = new props.uiStoreType(props);
  }

  static contextType = ExpandCollapseContext;

  componentWillUnmount() {
    this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  handleCollapseClick = (store) => {
    store.setExpanded(!store.isExpanded);
  };

  render() {
    if (this.uiStore && this.uiStore.hiddenStore.get('isHidden')) return null;

    const {store} = this.context;
    const {children, message, className, uiBaseStore, ...other} = this.props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <Button {...other}
              className={classNames("expand-collapse-toggle-button", className, computedClassNames)}
              onClick={() => this.handleCollapseClick(store)}
      >
        {children}
        <span>
            <Message message={message} />
        </span>
      </Button>
    );
  }
}


export { ExpandCollapseHeader, ExpandCollapseToggle };