import React, {Component} from 'react';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {Row as _Row} from 'reactstrap';

@observer
class Row extends Component {
    constructor(props) {
        super(props);
        if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    }

    componentWillUnmount() {
        this.uiBaseStore && this.uiBaseStore.dispose();
    }

    render() {
        const {children, className, justifyContent, computedClassName, languages, localeStore, uiBaseStore,
            ...other} = this.props;
        const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
          && this.uiBaseStore.classNameStore.getClassNames();

        return (
          <_Row className={classNames(justifyContent, className, computedClassNames)} {...other}>
              {children}
          </_Row>
    )
    }
}

export {Row};