class UiDisposeStore{

    _disposeArray;

    constructor(props) {
        this._disposeArray=[];
    }

    addAutorun = (func) => {
        this._disposeArray.push(func);
    }

    get disposeArray(){
        return this._disposeArray;
    }

    dispose(){
        this.disposeArray.map(func=>{func()});
        this._disposeArray = [];
    }
}

export {UiDisposeStore}