import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import classNames from "classnames";
import { ExpandCollapseStore } from "./expand-collapse-store";
import { Card } from "@isf/bootstrap";

const ExpandCollapseContext = React.createContext();

@inject("store")
@observer
class ExpandCollapse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialOffsetTop: null,
      initialOffsetLeft: null,
      initialOffsetHeight: null,
      initialOffsetWidth: null,
      isSticky: false
    };
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    if (props.uiStoreType) this.uiStore = new props.uiStoreType(props)
    this.expandCollapseStore = new ExpandCollapseStore({
      path: props.id + "ExpandCollapseStore",
      state: {}
    });
    if (props.isExpanded) this.expandCollapseStore.setExpanded(true);
    this.ref = React.createRef();
  }

  componentWillUnmount() {
    this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  // UNSAFE_componentWillMount() {
  //   window.addEventListener('scroll', this.handleScroll);
  // }
  //
  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.handleScroll);
  // }

  // handleScroll = () => {
  //   if (this.ref.current) {
  //     const offsetTop = this.ref.current.getBoundingClientRect().top;
  //     const offsetLeft =  document.documentElement.clientWidth - this.ref.current.getBoundingClientRect().right;
  //     const offsetHeight = this.ref.current.getBoundingClientRect().height;
  //     const { initialOffsetTop, initialOffsetHeight, isSticky } = this.state;
  //     if ((!initialOffsetTop && !initialOffsetHeight)
  //           || (initialOffsetTop !== offsetTop)) {
  //       this.setState({
  //         initialOffsetTop: offsetTop,
  //         initialOffsetLeft: offsetLeft,
  //         initialOffsetHeight: offsetHeight
  //       });
  //     }
  //     if (initialOffsetHeight !== offsetHeight) {
  //       this.setState({
  //         initialOffsetHeight: offsetHeight
  //       });
  //     }
  //     if (window.scrollY >= (initialOffsetTop || offsetTop) && !this.state.isSticky) {
  //       this.setState({isSticky: true});
  //     }
  //     if (window.scrollY < (initialOffsetTop || offsetTop) && this.state.isSticky) {
  //       this.setState({isSticky: false});
  //     }
  //   }
  // };

  render() {
    const { children, className, store: { isActive } } = this.props;
    const { isSticky, initialOffsetLeft, initialOffsetHeight } = this.state;

    if (this.uiStore && this.uiStore.hiddenStore.get('isHidden')) return null;
    const style = isSticky
                    ? { right: initialOffsetLeft,
                        width: document.documentElement.clientWidth - (2 * initialOffsetLeft + (isActive ? 16 * 16 : 16 * 4)) }
                    : { left: 0, width: "auto" }
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <Fragment>
        <Card innerRef={this.ref}
              className={classNames(
                "mb-3 shadow-none expand-collapse", className, computedClassNames,
                // {"expand-collapse-sticky": isSticky,
                //   "expand-collapse-sidebar-active": isSticky && isActive,
                //   "expand-collapse-sidebar-inactive": isSticky && !isActive,
                // }
              )}
              // style={style}
        >
          <ExpandCollapseContext.Provider value={{
            store: this.expandCollapseStore,
            isSticky: isSticky,
            initialOffsetHeight: initialOffsetHeight}}>
            {children}
          </ExpandCollapseContext.Provider>
        </Card>
        { isSticky
          && <div className="fix-pos-fixed" style={{height: initialOffsetHeight + 16}} />
        }
      </Fragment>
    )
  }
}

export { ExpandCollapse, ExpandCollapseContext };