import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import TableCell from "./table-cell";

@observer
class TableRow extends Component {

  // componentDidMount() {
  //   const rowIndex = this.props.rowIndex;
  //   this.props.store.selectionStore.setPageRow(rowIndex);
  // }
  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.item) !== JSON.stringify(this.props.item)) {
      this.props.store.selectionStore.clearStore();
    }
  }
  render() {
    const {columns, ...other} = this.props;
    return (
      <tr>
        { columns.map( (column, i) => <TableCell key={i} colId={i} column={column} {...other}/>) }
      </tr>
    );
  }
}

TableRow.propTypes = {
  id: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default TableRow;
