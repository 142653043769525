import React, { Fragment } from "react";
import { Nav } from "@isf/bootstrap";
//import { ICON_BELL, ICON_MAIL } from "@isf/common-resources";
import {inject, observer} from "mobx-react";
import {injectIntl, LocaleSelect} from "@isf/localization";
import { Search } from "./search";
import UserIdentity from "./identity/user-identity";
import { HelmetThemes } from "../../hemlet/src/hemlet-themes";

import {ModalCancelButton, ModalFooter, ModalBody, ModalHeader, ModalContainer} from "../../modal";
import {ToggleStore} from "../../toggle";
import {observable} from "mobx";

const HeaderRightItems = observer(({ store }) => {
  return (
    <Fragment>
      <Nav navbar className="h-100 align-items-center position-relative ml-auto header-right-items">
        {/*<Search store={store} />*/}
        {/*<img src={ICON_BELL} className="cursor-pointer my-auto mr-3 icon-header-right" />*/}
        {/*<img src={ICON_MAIL} className="cursor-pointer my-auto mr-3 icon-header-right" />*/}

        <SynchronizedUserModal/>
        <HelmetThemes />
        <LocaleSelect />
        <UserIdentity />
      </Nav>
    </Fragment>
  );
});

@injectIntl
@inject("userStore")
@observer
class SynchronizedUserModal extends React.Component {
  @observable synchronizationStage = "SLEEP"; //SLEEP, SYNCHRONIZATION

  constructor(props) {
    super(props);
    this.toggleStore = new ToggleStore({path: "userSynchronization.ToggleStore"});
  }

  checkSynchronized() {
    const {userStore} = this.props;
    const mvdSynced = userStore.get("user.currentAccount.mvdSynced");
    const egrSynced = userStore.get("user.currentAccount.egrSynced");
    const localeStorageName =
      document.location.hostname + "."
      + userStore.get("user.currentAccount.id")
      + ".userSynchronization";
    const userSynchronization = localStorage.getItem(localeStorageName);

    if ((mvdSynced === false || egrSynced === false) && userSynchronization !== "true") {
      this.synchronizationStage = "SYNCHRONIZATION";
      this.toggleStore.set("active", true);
      localStorage.setItem(localeStorageName, "true");

    } else if (mvdSynced !== false && egrSynced !== false && userSynchronization === "true") {
      this.synchronizationStage = "SLEEP";
      this.toggleStore.set("active", true);
      localStorage.removeItem(localeStorageName);
    }
  }

  componentDidMount() {
    this.checkSynchronized();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.checkSynchronized();
  }

  render() {
    const {props, toggleStore} = this;
    const {intl, userStore} = props;
    const mvdSynced = userStore.get("user.currentAccount.mvdSynced");
    const egrSynced = userStore.get("user.currentAccount.egrSynced");
    const bodyIntlId = this.synchronizationStage === "SYNCHRONIZATION"
      ? "ui.synchronizationUserModal.modelBody.text.sync"
      : "ui.synchronizationUserModal.modelBody.text.sleep";
    const className = this.synchronizationStage === "SYNCHRONIZATION"
      ? "text-warning"
      : "text-primary";


    return (
      <ModalContainer toggleStore={toggleStore} size={"sm"}>
        <ModalHeader>
          {intl.formatMessage({id: "ui.synchronizationUserModal.header.text"})}
        </ModalHeader>
        <ModalBody>
          <span className={className}>
            {intl.formatMessage({id: bodyIntlId})}
          </span>
        </ModalBody>
        <ModalFooter>
          <ModalCancelButton>
            {intl.formatMessage({id: "ui.synchronizationUserModal.button.cancel"})}
          </ModalCancelButton>
        </ModalFooter>
      </ModalContainer>
    );
  }
}

export { HeaderRightItems };