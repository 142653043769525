import React, { Fragment } from "react";
import { observer } from "mobx-react";
import { injectIntl, localeStore } from "@isf/core-localization";

const FormattedMessage = injectIntl(observer((props) => {
  const { message, intl } = props;
  return (
    <Fragment>
      {intl.messages[message.id] && intl.messages[message.id] !== '' ?
        intl.messages[message.id]
        :
        localeStore.getMessageFromLocale(message.id, "ru")}
    </Fragment>
  )
}));

const Text = observer(({ text }) => (
  <Fragment>
    {text}
  </Fragment>
));

const Message = observer((props) => {
  const { message, text } = props;
  return (
    <Fragment>
      {
        message
          ? <FormattedMessage message={message} />
          : <Text text={text} />
      }
    </Fragment>
  );
});

export { Message };