import { computed, action, observable } from "mobx"
import $ from "@isf/core-object-util";
import { DataStore } from "@isf/core-app-store";
import { TableStore } from "@isf/table";
import { TableViewStore } from "@isf/table-view";
import { transformPath, getRequestParameters, getRequestParametersNotAsync, getResponse, getSchemaOpenApiChild,
  ServiceTableViewStore, defaultRequestState, ServicesTableStore, ServicesTableRequestStore, ServicesStore
} from "@isf/common-select-service";
import {resolveStore} from "@isf/react-util";

class ServiceSelectionSettingStore {

  constructor(props) {
    this.path = ["serviceSelectionSettingStore"];
    this._servicesResponseStore = resolveStore({
      type: ServicesTableStore,
      store: "component.services.table.response"
    });
    this._servicesRequestStore = resolveStore({
      state: defaultRequestState(),
      type: ServicesTableRequestStore,
      store: "component.services.table.request"
    });
    this._servicesRequestStore.makeObservable({asMap: true});
    this._servicesStore = new ServicesStore({
                                path: [...$.strToArray(this.path), "services"],
                                state: observable.array([], { deep: false }),
                          });
    this.init(props);
  }

  init(props) {
    const { designContext, setConf, selectedService } = props;

    this.designContext = designContext;
    this.setConf = setConf;

    this._dataStore = new DataStore({
      path: [...this.path, "dataStore"].join("."),
      state: {
        selectedService: selectedService || {
          name: designContext.get("name"),
          context: designContext.serviceName,
          contextPath: designContext.serviceContextPath,
          id: designContext.get("id"),
          requestSchema: designContext.modelSchemaStore && designContext.modelSchemaStore.state,
          responseSchema: designContext.modelSchemaStore && designContext.modelSchemaStore.state,
          apiSchema: designContext.openApiStore && designContext.openApiStore.state,
        },
        itemsIndex: $.objectToObservableMap({})
      },
    });

    this._serviceTableStore = new TableStore({
      path: [...this.path, "servicesTableStore"].join("."),
      state: {}
    });

    this._pathTableStore = new ServiceTableViewStore(
      new TableViewStore(),
      []
    );
  }

  get dataStore() {
    return this._dataStore;
  }

  get serviceTableStore() {
    return this._serviceTableStore;
  }

  get servicesStore() {
    return this._servicesStore;
  }

  get servicesRequestStore() {
    return this._servicesRequestStore;
  }

  get servicesResponseStore() {
    return this._servicesResponseStore;
  }

  get servicesSelectedData() {
    const selectedRows = this.serviceTableStore.selectionStore.selected;
    return selectedRows.length > 0 ? this.servicesResponseStore.get(`${selectedRows[0]}`) : undefined;
  }

  getServiceInfo(id) {
    if (!id) return;
    return this.servicesStore.state.find(item => $.get(item, "id") === id);
  }

  getServiceId() {
    if (this.servicesSelectedData) {
      return $.get(this.servicesSelectedData, "id");
    }
  }

  async loadNewService(...listeners) {
    const serviceId = this.getServiceId();
    const serviceInfo = this.getServiceInfo(serviceId);
    if (serviceInfo) {
      listeners.forEach(listener => listener(serviceInfo));
      return;
    }

    return await this.servicesStore.loadServiceById(serviceId, listeners);
  }

  get pathTableStore() {
    return this._pathTableStore;
  }

  @action
  setSelectedService(service) {
    this.dataStore.set("select.service", {
      id: $.get(service, "id"),
      name: $.get(service, "name"),
      context: $.get(service, "context"),
      contextPath: $.get(service, "contextPath"),
      data: service
    });
  }

  setPathTableData = (data) => {
    if (this.dataStore.get("select.service.id", true) !== $.get(data, "id")) {
      this.pathTableStore.clearChecked();
      this.clearSelectedParameter();

      this.setSelectedService(data);
      this.pathTableStore.data = transformPath($.get(data, "api.schema.paths"));
    }
  };

  @action
  selectService = async (...listeners) => {
    await this.loadNewService(this.setPathTableData, ...listeners);
    const selectService = this.dataStore.get("select.service");
    let searchOperation;
    if (selectService.data.api && selectService.data.api.schema && selectService.data.api.schema.paths) searchOperation = selectService.data.api.schema.paths[`${selectService.contextPath}/search`];
    if (searchOperation) {
      const requestParamsObj = await getRequestParametersNotAsync(`${selectService.contextPath}/search`, "post", $.get(selectService, "data.api.schema"), this.servicesStore);
      const apiRequestBodyFilterSchema = getSchemaOpenApiChild(requestParamsObj, "body.filter");
      this.setConf && this.setConf(apiRequestBodyFilterSchema);
    } else this.setConf && this.setConf();
  };

  @action
  async loadRequestSchemaWithoutRef(path, method) {
    const apiSchema = this.dataStore.get("select.service.data.api.schema");

    const requestData = await getRequestParameters(path, method, apiSchema, this.servicesStore);
    this.dataStore.set("processVariables.requestData", requestData);

    const responseData = await getResponse(path, method, apiSchema, this.servicesStore);
    this.dataStore.set("processVariables.responseData", responseData);
  }

  @action
  async setSelectedPathMethod(path, method, listeners) {
    await this.loadRequestSchemaWithoutRef(path, method);

    this.dataStore.set("select.service.path", path);
    this.dataStore.set("select.service.method", method);
    this.setConf && this.setConf();
    Array.isArray(listeners) && listeners.forEach(listener => listener());
  }

  @action
  selectPathMethod = async (...listeners) => {
    const data = this.pathTableStore.selectedData;
    const path = $.get(data, "path"),
      method = $.get(data, "method");

    const oldPath = this.dataStore.get("select.service.path");
    const oldMethod = this.dataStore.get("select.service.method");

    if (path !== oldPath || method !== oldMethod) {
      this.clearSelectedParameter();
      await this.setSelectedPathMethod(path, method, listeners);
    } else {
      this.setConf && this.setConf();
      Array.isArray(listeners) && listeners.forEach(listener => listener());
    }
  };

  @action
  clearSelectedParameter() {
  }

  @action
  clearServicesStores() {
    this.servicesRequestStore.setState(defaultRequestState());
    this.serviceTableStore.selectionStore.clearStore();
  }

  @action
  clearStore() {
    this.pathTableStore.clearChecked();
    this.pathTableStore.clearFilter();
    this.clearSelectedParameter();
    this.dataStore.remove("select");
  }

  @computed
  get pathDisabled() {
    const selectedRows = this.pathTableStore.tableStore.selectionStore.selected;
    return selectedRows.length !== 1;
  }

  @computed
  get serviceDisabled() {
    const selectedRows = this.serviceTableStore.selectionStore.selected;
    return selectedRows.length !== 1;
  }
}

export { ServiceSelectionSettingStore };