import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import { TreeView } from "./tree-view";
import PropTypes from 'prop-types';
import $ from "@isf/core-object-util";
import { Selector } from "./tree-view"

class CustomTreeView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      tagNode,
      tagLeafNode,
      tagBranchNode,
      tagBranchNodeContent,
      tagLeafNodeContent,
      tagLeafNodeLabel,
      tagLabelContent,
      descendants,
      ...other
    } = this.props;

    return (
      <TreeView
        {...other}
        Node={tagNode}
        LeafNode={tagLeafNode}
        BranchNode={tagBranchNode}
        BranchNodeContent={tagBranchNodeContent}
        LeafNodeContent={tagLeafNodeContent}
        LeafNodeLabel={tagLeafNodeLabel}
        LabelContent={tagLabelContent}
        descendants={descendants ? descendants : descendantsDefault}
        pathSplitter={","}
      />
    );
  }
}

const BranchNodeContentDefault = observer((props) => {
  const {
    expanded, level, Theme, LabelContent,
    isSelect, handleExpandCollapse
  } = props;

  return (
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      <Theme.twistie expanded={expanded} level={level}
                     onClick={handleExpandCollapse} />
      {typeof isSelect === 'function' && isSelect(props)
        ? <Selector {...props} />
        : null
      }
      <Theme.label level={level} >
        <LabelContent {...props} />
      </Theme.label>
    </div>
  )
});

const LabelContentDefault = observer(props => {
  const { Theme, data, dataKey, isBranchNode } = props;
  const description = $.get(data, 'description');
  if (isBranchNode(props)) {
    return (
      <Fragment>
        <Theme.leafKey>
          {dataKey}
          {/*{props.level === 2*/}
          {/*  ? <Fragment>*/}
          {/*    <Theme.leafValue>*/}
          {/*      {' - store'}*/}
          {/*    </Theme.leafValue>*/}
          {/*  </Fragment>*/}
          {/*  : null*/}
          {/*}*/}
          {typeof description === "string" && " : " + description}
        </Theme.leafKey>
      </Fragment>
    )
  }
  return (
    <Fragment>
      <Theme.leafKey>{dataKey}</Theme.leafKey>{' : '}
      <Theme.leafValue>{'' + data}
      </Theme.leafValue>
    </Fragment>
  )
});

const getChildren = (parent) => Array.from(parent.keys && parent.keys() || Object.keys(parent)).map(
  key => ({
    key,
    data: parent.get ? parent.get(key) : parent[key]
  })
);

const descendantsDefault = (parent) => {
  if ($.get(parent, 'properties')) {
    return getChildren($.get(parent, 'properties'));
  }

  if ($.get(parent, 'items')) {
    const items = $.get(parent, 'items');
    return [{ key: "items", data: items }];
  }

  return [];
};

CustomTreeView.propTypes = {
  id: PropTypes.string,               // component id
  noRootNode: PropTypes.bool,         // if true, root node is not rendered
  expanded: PropTypes.bool,
  selection: PropTypes.oneOf(['single', 'multiple']),
  activation: PropTypes.bool,
  data: PropTypes.oneOfType([         // root data object or array
    PropTypes.object,
    PropTypes.array
  ]).isRequired,
  store: PropTypes.object,
  handleActiveNodeChange: PropTypes.func,
  handleExpandCollapse: PropTypes.func,      // hook for expand/collapse event
  handleSelectedChange: PropTypes.func,
  buildChildPath: PropTypes.func,
  descendants: PropTypes.func,               // returns children of the given object
  isBranchNode: PropTypes.func,
  Theme: PropTypes.object,                   // styled components theme
  tagNode: PropTypes.elementType,               // react component to render any node in a tree
  tagLeafNode: PropTypes.elementType,           // react component to render leaf node in a tree
  tagBranchNode: PropTypes.elementType,         // react component to render branch node in a tree
  tagBranchNodeContent: PropTypes.elementType,  // react component to render branch node content like label, controls, etc
  tagLeafNodeContent: PropTypes.elementType,    // react component to render leaf node content like label, controls, etc
  tagLeafNodeLabel: PropTypes.elementType,
  tagLabelContent: PropTypes.elementType,
  pathSplitter: PropTypes.string
};

CustomTreeView.defaultProps = {
  tagBranchNodeContent: BranchNodeContentDefault,
  tagLabelContent: LabelContentDefault,
  descendantsDefault,
};

export { CustomTreeView };
