import React, {Fragment} from "react"
import styled from "styled-components"
import {observer} from "mobx-react"

const StyledMarquee = styled.div.attrs(props=>({
  style:{
    top:props.top,
    left:props.left,
    height:props.height,
    width:props.width
  }
}))`
  position:absolute;
  border:1px dashed black;
  background-color:#c5e7f0;
  opacity:0.3;
`;

export const Marquee = observer((props) => {
  const { dragAndDropStore} = props.diagramStore;
  const {currentPointMarquee} = dragAndDropStore;
  if(currentPointMarquee) {
    const {top,left,width,height} = dragAndDropStore.marqueeBounds;
    return (
      <StyledMarquee top={top} left={left} width={width} height={height}/>
    );
  }
  return null;
});

export const marqueeDefaultConfig = () => {
  return {
    uiSchema: {
      tag: 'Marquee'
    }
  };
};
