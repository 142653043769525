import { UIStore } from "@isf/core-app-store";
import { computed, action, observable } from "mobx";

const getPageRange = (lastPage, currentPage, delta ) => {
  const left = currentPage - delta;
  const right = currentPage + delta + 1;
  const range = [];
  for (let i = 1; i <= lastPage; i++) {
    if (i === 1 || i === lastPage || i >= left && i < right) {
      range.push(i);
    }
  }
  return range;
};

const getPageRangeWithDots = (range) => {
  const rangeWithDots = [];
  let l;  
  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push('...');
      }
    }
    rangeWithDots.push(i);
    l = i;
  }
  return rangeWithDots;
};

class PaginationStore extends UIStore {
  
  @observable currentPage = 1;
  @observable _rowsPerPage = 5;
  @observable totalRows = 5;

  @computed
  get isPaginationEnabled() {
    return this.totalRows > this._rowsPerPage;
  }

  @computed
  get rowsPerPage() {
    return this._rowsPerPage;
  }

  @computed
  get endItem() {
    const { currentPage, rowsPerPage } = this;
    return currentPage * rowsPerPage;
  }

  @computed
  get startItem() {
    const { endItem, rowsPerPage } = this;
    return endItem - rowsPerPage;
  }

  @computed
  get lastPage() {
    const { totalRows, rowsPerPage } = this;
    const pagesCount = Math.ceil(totalRows / rowsPerPage);
    return (pagesCount > 1) ? pagesCount : 1;
  }

  @computed
  get previousPage() {
    const { currentPage } = this;
    return currentPage > 0 ? (currentPage - 1) : 0;
  }
  
  @computed
  get nextPage() {
    const { lastPage, currentPage } = this;
    return (lastPage === currentPage) ? currentPage : currentPage + 1;
  }

  @computed
  get pages() {
    const { lastPage, currentPage } = this;
    const range = getPageRange(lastPage, currentPage, 2);
    const rangeWithDots = getPageRangeWithDots(range);
    return rangeWithDots;
  };

  @action
  setTotalRows(rows) {
    this.totalRows = rows;
    if(this.currentPage > this.lastPage) {
      this.currentPage = this.lastPage;
    }
  }

  @action
  setCurrentPage(page) {
    this.currentPage = page;
  }

  @action
  setRowsPerPage(rowsPerPage) {
    this._rowsPerPage = rowsPerPage;
    if(this.currentPage > this.lastPage) {
      this.currentPage = this.lastPage;
    }
    this.setCurrentPage(1);
  }
}

export { PaginationStore, getPageRange, getPageRangeWithDots };
