import React, { Fragment } from "react";
import { observer, inject } from "mobx-react";
import { injectIntl } from "@isf/localization";
import { DropdownSelect } from "@isf/dropdown";
import { THEMES } from "./themes";
//import { ICON_DESIGN_THEME } from "@isf/common-resources";

// const HelmetThemes = injectIntl( inject("helmetStore")( observer(({ helmetStore, intl: { formatMessage }, ...others }) => {
//   const indexThemeOptionHead = THEMES.findIndex(current => current.value === "head");
//   if (indexThemeOptionHead !== -1) {
//     THEMES[indexThemeOptionHead].header = formatMessage({id: "ui.header.theme.title"});
//   } else {
//     THEMES.unshift({ value: "head", header: formatMessage({id: "ui.header.theme.title"}) });
//   }
//   return (
//     <DropdownSelect
//       id="mainView.header.helmetThemesId"
//       className="my-auto helmet-themes"
//       options={THEMES}
//       caret={false}
//       right={true}
//       store={helmetStore}
//       optionTag={ThemeOptions}
//       {...others}
//     />
//   )
// })));

const HelmetThemes = injectIntl( inject("helmetStore")( observer(({ helmetStore, intl: { formatMessage }, ...others }) => {
  if (!Array.isArray(helmetStore.themesOption)) return null;
  const options = helmetStore.themesOption;
  options.unshift({ value: "head", header: formatMessage({id: "ui.header.theme.title"}) });

  return (
    <DropdownSelect
      id="mainView.header.helmetThemesId"
      className="my-auto helmet-themes"
      options={options}
      caret={false}
      right={true}
      store={helmetStore}
      optionTag={ThemeOptions}
      {...others}
    />
  )
})));

export { HelmetThemes };

const ThemeOptions = injectIntl(({ option, checked, intl: { formatMessage } }) => {
  if (!option) return null;
  return (
    <Fragment>
      { checked
        ? <Temp_icon_design_theme
          //<img src={ICON_DESIGN_THEME}
            className="d-block h-auto icon-w-23"
            title={formatMessage({id: "ui.header.theme.expand"})}
            alt={formatMessage({id: "ui.header.search.expand"})} />
        : <span>{option.name}</span>
      }
    </Fragment>
  );
});

const Temp_icon_design_theme = (props) => {
  return (
    <svg className={props.className} style={{fill: "#1d5c93"}} viewBox="0 0 488.47 488.47" height="23px" width="23px" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg">
      { props.title ? <title>{props.title}</title> : null }
      <path
        d="m488.47 78.366c0-43.207-35.166-78.366-78.381-78.366-22.45 0-43.871 9.658-58.763 26.5l-169.367 191.91c-41.879-11.428-88.126-1.325-121.661 31.37-37.014 36.083-32.199 73.537-27.965 106.58 4.457 34.667 8.303 64.61-27.727 99.739-3.786 3.696-5.396 9.077-4.234 14.248 1.148 5.157 4.889 9.36 9.883 11.089 12.641 4.397 30.902 7.035 52.145 7.035 53.486 0 125.904-16.782 175.171-64.819 23.985-23.385 37.193-54.236 37.193-86.877 0-10.496-1.828-20.655-4.465-30.587l191.666-169.084c16.845-14.875 26.505-36.292 26.505-58.738zm-272.216 323.438c-51.384 50.078-128.87 59.155-172.28 55.265 27.175-38.214 22.509-74.521 18.649-104.598-3.98-30.941-7.126-55.384 18.991-80.84 37.744-36.813 96.895-36.784 134.64 0 18.037 17.587 27.98 40.718 27.98 65.146.001 24.338-9.927 47.425-27.98 65.027zm225.513-287.577-183.931 162.26c-5.592-9.555-12.039-18.692-20.264-26.706-7.904-7.711-16.69-13.744-25.795-19.001l162.431-184.069c9.093-10.284 22.167-16.186 35.881-16.186 26.385 0 47.851 21.462 47.851 47.843 0 13.712-5.903 26.783-16.173 35.859z"/>
    </svg>
  )
};