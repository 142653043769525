import React, {Component, Fragment} from "react";
import { ServicesView } from "../computed-table";
import SelectServiceView from "../select-service/select-service-view";
import {getColumnsPath} from "./config-constants";
import {observer} from "mobx-react";
import {ConfigRequestAndResponse} from "./config-mapping-parameter";

class SelectService extends Component {
  render() {
    const { intl, configStore } = this.props;
    const args = {
      store: configStore.serviceTableStore,
      dataStore: configStore.servicesResponseStore,
      requestStore: configStore.servicesRequestStore
    };

    return (
      <ServicesView {...args} intl={intl} />
    );
  }
}

class SelectPathView extends Component {

  render() {
    const { configStore, intl } = this.props;

    return (
      <Fragment>
        <SelectServiceView
          store={{serviceTableStore: configStore.pathTableStore}}
          columns={getColumnsPath(intl)}
          intl={intl}
        />
      </Fragment>
    );
  }
}

@observer
class SelectParameterView extends Component {

  render() {
    const { intl, configStore, layout, ...other } = this.props;

    return (
      <Fragment>
        <ConfigRequestAndResponse
          store={configStore}
          layout={layout}
          intl={intl}
          {...other}
        />
      </Fragment>
    );
  }
}

export { SelectService, SelectPathView, SelectParameterView };