import {UIStore} from "@isf/core-app-store";
import {autorun, computed} from "mobx";
import {uuidv4} from "@isf/core-system-util";
import {UILoadStore} from "./ui-load-store";
import {UICommonStore} from "./ui-common-store";

const initialize=(props,id,store)=>{
    store._commonStore = new UICommonStore({...props});
    store._loadStore = new UILoadStore({...props});
};

class UIExtendedStore /*extends UIStore */{

    constructor(props) {
        const id = 'uiExtendStore.' + uuidv4();
        /*super({path: id});*/
        initialize(props, id, this)
    }

    _commonStore;

    _loadStore;

    @computed
    get commonStore(){
        return this._commonStore;
    }

    @computed
    get loadStore(){
        return this._loadStore;
    }

    dispose(){
        this._commonStore.dispose();
    }
}

export {UIExtendedStore}