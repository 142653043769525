import React, { Component } from "react";
import { observer } from "mobx-react";
import { injectIntl, FormattedMessage } from "@isf/localization";
import $ from "@isf/core-object-util/src/object-util";
import { Row, Col, Button } from "@isf/bootstrap";
import { Item } from "../../../item-grid";
import { FormField } from "../../../form";
//import { FilterField } from "@isf/filter";
import { getOperationOptionsByType, getValueOptionsForBooleanType, layout } from "../filter-setting-constant";

@injectIntl
@observer
class FilterPropertyCriteriaItem extends Component {

  id(childId) {
    return this.props.id + '.' + childId;
  }

  handleClick = () => {
    const { handler, accessor, propPath/*, localizedProp, intl*/ } = this.props;
    const filterSettingDB = handler.get(accessor);

    // if (localizedProp) {
    //   const indexFilterCriteriaItem = propPath[propPath.length - 1];
    //   const pathCriteriaItem = propPath.slice(0, propPath.length - 1);
    //   $.remove(filterSettingDB, `filter.${pathCriteriaItem.join(".")}.${intl.locale}.${indexFilterCriteriaItem}`);
    // }
    // else
    $.remove(filterSettingDB, `filter.${propPath.join(".")}`);

    propPath.pop();
    this.removeParent(propPath, filterSettingDB);
  };

  removeParent = (propPath, filterSettingDB) => {
    //const { localizedProp, intl } = this.props;

    if (propPath && propPath.length !== 0) {
      // const criteria = localizedProp
      //   ? $.toJS($.get(filterSettingDB, `filter.${propPath.join(".")}.${intl.locale}`))
      //   : $.toJS($.get(filterSettingDB, `filter.${propPath.join(".")}`));
      const criteria = $.toJS($.get(filterSettingDB, `filter.${propPath.join(".")}`));

      if (criteria && Array.isArray(criteria) && criteria.length === 0) {
        // if (localizedProp) {
        //   $.remove(filterSettingDB, `filter.${propPath.join(".")}.${intl.locale}`);
        //   const criteriaAllLocale = $.toJS($.get(filterSettingDB, `filter.${propPath.join(".")}`));
        //   if (criteriaAllLocale && typeof criteriaAllLocale === "object"  && Object.keys(criteriaAllLocale).length === 0) {
        //     $.remove(filterSettingDB, `filter.${propPath.join(".")}`);
        //   }
        // } else
        $.remove(filterSettingDB, `filter.${propPath.join(".")}`);

        propPath.pop();
        this.removeParent(propPath, filterSettingDB);
      } else if (criteria && typeof criteria === "object"  && Object.keys(criteria).length === 0) {
        $.remove(filterSettingDB, `filter.${propPath.join(".")}`);

        propPath.pop();
        this.removeParent(propPath, filterSettingDB);
      }
    }

    const filter = $.toJS($.get(filterSettingDB, "filter"));
    if (filter && typeof filter === "object"  && Object.keys(filter).length === 0) {
      $.remove(filterSettingDB, "filter");
    }
  };

  render() {
    const { handler, accessor, propPath, propType, propFormat, intl/*, localizedProp*/ } = this.props;

    const operationOptions = getOperationOptionsByType(propType, propFormat, intl);
    let typeCriterionValue, mutableProps = {};
    if (propType === "string") {
      if (propFormat === "date") typeCriterionValue = "date";
      else if (propFormat === "date-time") typeCriterionValue = "datetime";
      else typeCriterionValue = "text";
    }
    else if (propType === "boolean") {
      typeCriterionValue = "select";
      mutableProps["options"] = getValueOptionsForBooleanType(intl);
    } else if (propType === "integer") {
      typeCriterionValue = "number";
      mutableProps["format"] = "integer";
    } else typeCriterionValue = propType;

    // const localeStore = {
    //   locale: intl.locale
    // };

    // let indexFilterCriteriaItem, pathCriteriaItem;
    // if (localizedProp) {
    //   indexFilterCriteriaItem = propPath[propPath.length - 1];
    //   pathCriteriaItem = propPath.slice(0, propPath.length - 1);
    // }

    return (
      <Item className="py-0">
        <Row className="justify-content-between align-items-center">
          <Col xs="12" md>
            <FormField
              id={this.id("criterion.operation")}
              type="select"
              label={<FormattedMessage id={"ui.filterSetting.criterion.operation"}/>}
              handler={{
                handler: {
                  set: (path, value) => {
                    const currentValue = handler.get(`${accessor}.filter.${propPath.join(".")}.value`);
                    let newOperation = value || null, newValue = currentValue || null;
                    if (value === "like") {
                      if (currentValue) {
                        if (!currentValue.startsWith("%")) newValue = `%${newValue}`;
                        if (!currentValue.endsWith("%")) newValue = `${newValue}%`;
                      } else newValue = "%%";
                    } else if (value === "likeStrictOption") {
                      if (currentValue) {
                        if (!currentValue.endsWith("%")) newValue = `${newValue}%`;
                        if (currentValue.startsWith("%")) newValue = newValue.substring(1);
                      } else newValue = "%";
                      newOperation = "like";
                    } else if (propType === "string") {
                        if (currentValue && currentValue.startsWith("%")) newValue = newValue.substring(1);
                        if (currentValue && currentValue.endsWith("%")) newValue = newValue.substring(0, newValue.length - 1);
                    }
                    handler.set(path, newOperation);
                    if (newValue !== currentValue) handler.set(`${accessor}.filter.${propPath.join(".")}.value`, newValue);
                  },
                  get: (path) => {
                    const currentOperation = handler.get(path);
                    if (currentOperation === "like") {
                      const currentValue = handler.get(`${accessor}.filter.${propPath.join(".")}.value`);
                      if (currentValue && currentValue.endsWith("%") && !currentValue.startsWith("%")) return "likeStrictOption";
                      else if (currentValue === "%") return "likeStrictOption";
                      else return "like";
                    } else return handler.get(path);
                  }
                },
                accessor: `${accessor}.filter.${propPath.join(".")}.operation`
              }}
              options={operationOptions}
              className={"mb-md-0"}
              layout={layout} />
          </Col>
          <Col xs="12" md>
            {/*{ !localizedProp*/}
            {/*  && <FormField*/}
            <FormField id={this.id('criterion.value')}
                       type={typeCriterionValue}
                       label={<FormattedMessage id="ui.filterSetting.criterion.value"/>}
                       handler={{
                          handler: {
                            set: (path, value) => {
                              let newValue = value || "";
                              if (propType === "string" && !propFormat) {
                                const currentOperation = handler.get(`${accessor}.filter.${propPath.join(".")}.operation`);
                                if (currentOperation === "like") {
                                  const currentValue = handler.get(path);
                                  if (currentValue === "%") newValue = `${newValue}%`;
                                  else if (currentValue && currentValue.startsWith("%") && currentValue.endsWith("%")) newValue = `%${newValue}%`;
                                  else if (currentValue && currentValue.endsWith("%")) newValue = `${newValue}%`;
                                }
                              } else if (propType === "boolean") {
                                newValue = JSON.parse(value.toLowerCase());
                              }
                              handler.set(path, newValue);
                            },
                            get: (path) => {
                              if (propType === "string" && !propFormat) {
                                const currentOperation = handler.get(`${accessor}.filter.${propPath.join(".")}.operation`);
                                if (currentOperation === "like") {
                                  const currentValue = handler.get(path);
                                  if (currentValue === "%") return "";
                                  else if (currentValue && currentValue.startsWith("%") && currentValue.endsWith("%")) return currentValue.substring(1, currentValue.length - 1) || "";
                                  else if (currentValue && currentValue.endsWith("%")) return currentValue.substring(0, currentValue.length - 1);
                                  else return currentValue;
                                } else return handler.get(path);
                              } else return handler.get(path);
                            }
                          },
                          accessor: `${accessor}.filter.${propPath.join(".")}.value`
                        }}
                        layout={layout}
                        className={"mb-md-0"}
                        {...mutableProps} />
            {/*}*/}
            {/*{ localizedProp*/}
            {/*  && <FilterField  id={'criterion.value.localized'}*/}
            {/*                   label={{*/}
            {/*                     text: <FormattedMessage id="ui.filterSetting.criterion.value.localized"/>*/}
            {/*                   }}*/}
            {/*                   input={{*/}
            {/*                     props: {onChange: handler.onChange}*/}
            {/*                   }}*/}
            {/*                   handler={handler}*/}
            {/*                   accessor={`${accessor}.filter.${pathCriteriaItem.join(".")}.`*/}
            {/*                            + "${localeStore:locale}" + `.${indexFilterCriteriaItem}.value`}*/}
            {/*                   localeStore={localeStore}*/}
            {/*  />*/}
            {/*}*/}
          </Col>
          <Col xs="12" md="auto">
            <Row className="justify-content-center justify-content-md-end align-items-center no-gutters">
              <Col xs="auto">
                <Button color="danger"
                        className="mt-2 filter-setting-button-action"
                        outline
                        onClick={this.handleClick}>
                  <FormattedMessage id={"ui.filterSetting.criterion.delete"} />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Item>
    )
  }
}

export { FilterPropertyCriteriaItem };