import React, {Component, Fragment, lazy, Suspense} from 'react';
import {computed} from "mobx";
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import {Input as Input_} from 'reactstrap';
// import DateInput from './date-input';
//import DropzoneInput from './dropzone-input';
import {injectIntl} from "@isf/core-localization";
// import { CustomInput as Input_} from 'reactstrap';
import $ from "@isf/core-object-util";
import {ICON_CROSS, ICON_ACCEPT} from "@isf/common-resources";
import {handleError, handleModuleImport} from "../dynamic-import-handler/src";
import {
    DEFAULT_OPTION_BINDING,
    getComponentOptions,
    getOptionBinding,
    getOptionsValue,
    sortOptions
} from "./select/select-actions";

const HtmlEditorView = lazy(() => import('@isf/html-editor')
  .then(module => handleModuleImport(module, '@isf/html-editor', 'HtmlContentEditor'))
  .catch(handleError));
// import HtmlEditorView from "../html-editor/src/html-editor-view";

const HTMLInput = ({children, ...other}) => {
    return (
        <input {...other} />
    );
};

const HTMLSelect = (props) => {
    return (
        <select {...props} />
    );
};

const resolveHTMLInput = ({type}) => {
    if (type === 'select') {
        return HTMLSelect;
    }
    return HTMLInput;
};

@injectIntl
@observer
class Input extends Component {

    constructor(props) {
        super(props);
        if (props.input.props.isUncontrolledComponent) this.inputRef = React.createRef();
    }

    onBlur = () => {
        const {handler, accessor} = this.props;
        const value = handler.get(accessor);
        if (typeof value === "string") {
            handler.set(accessor, value.trim());
        }

    };

    // readFile(file, onLoad) {
    //   if (!(file instanceof File) || file.type !== "application/json") {
    //     return;
    //   }
    //   const reader = new FileReader();
    //   reader.readAsText(file);
    //   reader.onload = function() {
    //     onLoad(reader.result);
    //   };
    // }
    //
    // setJSON(json) {
    //   try {
    //     const jsonObj = JSON.parse(json);
    //     const { handler, accessor } = this.props;
    //     handler.set(accessor, jsonObj);
    //   } catch (e) {
    //   }
    // }

    getValue(event, editor) {
        const {input, localeStore} = this.props;
        const {optionsStore} = input;
        const optionBinding = getOptionBinding(input, localeStore);
        let value;
        if (event.target) {
            value = event.target.value !== '' ? event.target.value : undefined;
        }
        // if (input.props.type === "boolean") {
        //     value = !!value;
        if (input.props.type === "checkbox") {
            if (event.target) {
                value = event.target.checked;
            }
            value = !!value;
        } else if (input.props.type === "number") {
            value = input.props.format === "integer" ? parseInt(value) : parseFloat(value);
            value = isNaN(value) ? null : value;
        }
        if (input.props.type === "htmlEditor") {
            value = editor.data.get();
            // } else if (input.props.type === "file") {
            //   value = event.target.files;
        } else if ((optionsStore && optionBinding) || (input.options)) {
            value = getOptionsValue(value, input, localeStore);
        }
        if (value === undefined) value = null;
        return value;
    };

    handleOnChange = (e, editor) => {
        const {handler, accessor/*, input*/} = this.props;
        const value = this.getValue(e, editor);
        // if (input.dataType === "string.json") {
        //   this.readFile(value[0], this.setJSON.bind(this));
        // }

        handler.set(accessor, value);
        if (this.props.input.props.onChange) {
            this.props.input.props.onChange(e);
        }
    };

    handleOnClickCancel = () => {
        const {handler, accessor, input, localeStore} = this.props;
        const {optionsStore, props: {type}} = input;
        const optionBinding = type === "select" && getOptionBinding(input, localeStore);
        let value = handler.get(accessor);
        if (optionsStore && optionBinding) {
            value = $.get(value, optionBinding.value);
        }
        this.inputRef.current.value = value || "";
    };

    handleOnClickApply = () => {
        const {handler, accessor, input, localeStore} = this.props;
        const {optionsStore, props: {type}} = input;
        const optionBinding = type === "select" && getOptionBinding(input, localeStore);
        let value = this.inputRef.current.value;
        if ((optionsStore && optionBinding) || (input.options)) {
            value = getOptionsValue(value, input, localeStore);
        }
        if (value === "" || value === undefined) value = null;
        handler.set(accessor, value);
        this.handleOnClickCancel();
    };

    @computed
    get options() {
        const {intl, input, localeStore} = this.props;
        const { locale } = localeStore || {};
        const optionBinding = getOptionBinding(input, localeStore) || DEFAULT_OPTION_BINDING;
        let options = input.optionsStore
          ? input.optionsStore.state
          : input.options;

        options = $.isArray(options) ? getComponentOptions(options, optionBinding, intl) : null;
        if (options && input.sortOptions !== false) {
            options = sortOptions(options, locale);
        }

        return options;
    }

    renderOptions = ({id, options, addDefaultOption = true}) => {
        return (
            <Fragment>
                {addDefaultOption ? <option key={id + "DefaultOptions"} value=""/> : null}
                {options.map((option, index) => {
                        return (
                            <option
                                key={id + $.get(option, ["value"]) || index}
                                value={$.get(option, ["value"])}>
                                {
                                    $.get(option, ["label"])
                                }
                            </option>
                        )
                    }
                )}
            </Fragment>
        );
    };

    render() {
        const {
            input, handler, accessor, bs = true, bsSize, disabled = false,
            placeholder, intl: {formatMessage}, uiStore, localeStore
        } = this.props;
        const {props: {isUncontrolledComponent, isPopUpLabel, isLabelRight, labelName, toTrim, nullable, pattern,
            ...inputProps}} = input;
        let value = handler.get(accessor);

        const options = this.options;
        const optionBinding = getOptionBinding(input, localeStore);

        if (input.optionsStore && optionBinding && value) {
            value = optionBinding.valueOnly
                ? value
                : $.get(value, optionBinding.value);
        }

        // if (["file", "json"].includes(input.props.type)) {
        //   if (!input.fileInput || input.fileInput === "button") {
        //     input.props.type = "file";
        //   } else {
        //     return <DropzoneInput />;
        //   }
        // }
        if (input.props.type === 'htmlEditor') {
            return (
              <Suspense fallback={null}>
                <HtmlEditorView handler={handler}
                                   accessor={accessor}
                                   data={value}
                                   onChange={this.handleOnChange}
                                   readOnly={input.props.readOnly || disabled}
                                   isUncontrolledComponent={isUncontrolledComponent}
                                   inputRef={this.inputRef}/>
              </Suspense>
            );
        }
        const InputComponent = bs ? Input_ : resolveHTMLInput(input.props);
        if (bsSize) input.props.bsSize = bsSize;

        // if (input.props.type === "file") {
        //   return (
        //     <InputComponent
        //       disabled={disabled}
        //       {...input.props}
        //       placeholder={placeholder}
        //       onChange={this.handleOnChange}>
        //     </InputComponent>
        //   )
        // }
        let mutableProps;
        if (!isUncontrolledComponent) {
            mutableProps = {
                value: value === undefined || value === null ? "" : value,
                onChange: this.handleOnChange
            };
        } else {
            mutableProps = {
                defaultValue: value,
                innerRef: this.inputRef
            };
            // if (inputProps.type === "select" && value && this.inputRef.current) {
            //   this.inputRef.current.value = value
            // }
            if (this.inputRef && this.inputRef.current) {
                this.inputRef.current.value = value || "";
            }
        }

        if (!isPopUpLabel) mutableProps.placeholder = placeholder;
        if(input.props.type === 'checkbox'){
            mutableProps.checked = handler.get(accessor);
        }

        if(toTrim && (input.props.type === 'text' || input.props.type === "textarea")) {
            mutableProps.onBlur = this.onBlur;
        }

        if (uiStore && uiStore.restrictionsStore && uiStore.restrictionsStore.get('min')) {
            mutableProps.min = uiStore.restrictionsStore.get('min');
        }
        if (uiStore && uiStore.restrictionsStore && uiStore.restrictionsStore.get('max')) {
            mutableProps.max = uiStore.restrictionsStore.get('max');
        }

        return (
            <div className="input-container h-fit-content">
                <InputComponent
                    disabled={disabled}
                    {...inputProps}
                    className={classNames(
                        input.props.className,
                        {
                            "uncontrolled-component": isUncontrolledComponent && !(input.props.readOnly || disabled),
                            "has-value": isPopUpLabel && value
                        })}
                    {...mutableProps}>
                    {options && this.renderOptions({
                        id: input.id,
                        options,
                        addDefaultOption: !!input.optionsStore
                    })}
                </InputComponent>
                {input.props.readOnly || disabled
                    ? <Fragment>
                        {isPopUpLabel && <span className="input-label-focus" data-placeholder={labelName}/>}
                    </Fragment>
                    : <Fragment>
                        {input.props.type !== 'checkbox' && <span className="input-border-bottom-focus"/>}
                        {isPopUpLabel && <span className="input-label-focus" data-placeholder={labelName}/>}
                        {isUncontrolledComponent
                        && <Fragment>
                            <img src={ICON_CROSS}
                                 className="position-absolute uncontrolled-component-icons-clear icon-w-13 h-auto cursor-pointer"
                                 title={formatMessage({id: "ui.field.isUncontrolledComponent.action.cancel"})}
                                 alt={formatMessage({id: "ui.field.isUncontrolledComponent.action.cancel"})}
                                 onClick={this.handleOnClickCancel}/>
                            <img src={ICON_ACCEPT}
                                 className="position-absolute uncontrolled-component-icons-write icon-w-13 h-auto cursor-pointer"
                                 title={formatMessage({id: "ui.field.isUncontrolledComponent.action.apply"})}
                                 alt={formatMessage({id: "ui.field.isUncontrolledComponent.action.apply"})}
                                 onClick={this.handleOnClickApply}/>
                        </Fragment>
                        }
                    </Fragment>
                }
            </div>
        );
    }

}

Input.propTypes = {
    bsSize: PropTypes.string,
    inputProps: PropTypes.object,
    handler: PropTypes.object.isRequired,
    accessor: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string]).isRequired,
};

export default Input;