import {RestHandler} from "@isf/core-rest";
import {showErrorMessage} from "@isf/common/form/file-input/file-signature";

class CallActionRestHandler extends RestHandler {

  handleRequestError = async (request, e) => {
    showErrorMessage("ui.fetch.localhost.ERR_CONNECTION_REFUSED");
    return {};
  }
}

export {CallActionRestHandler}