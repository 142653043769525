/**
 * Asynchronously waits for the specified number of milliseconds,
 * should be used with a promise or in async / await context. 
 * Example: 
 * 
 * async someFunc = () => {
 *  // ... some code before sleep
 *  await sleep(1000);
 *  // ... following code executes with delay in one second
 * }
 * 
 * @param {*} ms milliseconds 
 */

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export { sleep };