import { RouterStore as ReactRouterMobxStore} from "react-router-mobx";
import {withRouter} from "react-router"
import { reaction, computed } from "mobx";

// import appStore from "./app-store";
import Store from "./store";

const reactRouterMobxStore = new ReactRouterMobxStore();

// const onRouteChange = (routerStore) => {
//   const pathname = routerStore.get("pathname");
//   const m = matchPath(pathname, {
//     path: "/menu/:id",   
//     exact: true,
//     strict: false
//   });
// }

const initialize = (routerStore) => {
  reaction(
    () => reactRouterMobxStore.location.pathname,
    pathname => {
      routerStore.set(['path'], pathStrToArray(pathname));
      routerStore.set(['pathname'], pathname);
      // onRouteChange(routerStore);
    }
  );
  reaction(
    () => reactRouterMobxStore.location.query,
    query => {
      routerStore.set('query', query);
    }
  );
  reaction(
    () => reactRouterMobxStore.location.hash,
    hash => {
      routerStore.set('hash', hash);
    }
  ); 
  routerStore.set('pathname', reactRouterMobxStore.location.pathname);
}

class RouterStore extends Store {

  constructor(args) {
    super(args);
    initialize(this);
  }  

  get location() {
    return this._location;
  }

  @computed
  get pathname() {
    return this.get(['pathname']);
  }

  @computed
  get search() {
    return reactRouterMobxStore.location.search;
  }

  push(path) {
    reactRouterMobxStore.push(path);
  }

  replace(path, state) {
    reactRouterMobxStore.history.replace(path, state);    
  }

  get mobxStore() {
    return reactRouterMobxStore;
  }

}

const pathStrToArray = path => (
  path.split('/').filter(e => e !== '')
);

// const routerStore = appStore.createStore({
//     scope: 'router',
//     StoreClass: RouterStore,
//     state: {
//       path: [],
//       query: '',
//       hash: ''
//     }
//   }
// );

const routerStore = new RouterStore({
  scope: 'router',
  path: [],
  state: {
    path: [''],
    query: '',
    hash: ''
  }
}
);

routerStore.makeObservable({asMap: true});

export default routerStore;