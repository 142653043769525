export const BoundaryTimerConf = (number, coordinates) => {
  return (
    {
      tag: "Shape",
      props:
        {
          bounds: {
            lowerRight: {
              x: coordinates.x + 30,
              y: coordinates.y + 30
            },
            upperLeft: {
              x: coordinates.x,
              y: coordinates.y
            }
          },
          resourceId: "boundarytimer"+number,
          childShapes: [],
          stencil: {
            id: "BoundaryTimerEvent"
          },
          properties: {
            overrideid: "boundarytimer"+number,
            name: "Timer",
            cancelactivity: true,
            timertype:"timerdurationdefinition",
            timerdurationdefinition: "",
            timercycledefinition: "",
            executionlisteners: {
              executionListeners: []
            }
          },
          outgoing: []
        },
      children: []
    }
  )
};