import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";
import { DropdownSelect } from "@isf/dropdown";
import IdentityStore from "./identity-store";
import Logout from "./logout";
import Merge from "./merge";
import Login from "./login";
import { injectIntl } from "@isf/localization";
import {hostnameInfo} from "../../../hostname/hostname-type";
import { ICON_CHECK_LOGO} from "@isf/common-resources";

const identityStore = new IdentityStore({
  path: "identity.select"
});

const IdentitySelect = inject("userStore")(
  observer(({ userStore, ...props }) => {
    if (!userStore.authenticated) {
      return null;
    }
    // const options = [
    //   { value: "user", name: userStore.fullName },
    //   { value: "logout", name: "Выйти" }
    // ]
    const accounts = userStore.accounts;
    const currentAccount = userStore.currentAccount;
    const options = [
      { id: currentAccount.id + "current",
        value: "user",
        name: userStore.fullName,
        currentAccountName: currentAccount.name,
        email: userStore.email
      }
    ];

    const isLaxAccount = userStore.authTypeCode === "LAX";

    if (!hostnameInfo.isAdminHost) {
      accounts.forEach(account => {
        if (currentAccount.id !== account.id) {
          options.push({
            id: currentAccount.id,
            value: account.id,
            name: account.name,
            type: "account",
            disabled: isLaxAccount
          })
        }
      });
      options.push({id: "merge", value: "merge", name: "Привязать учетную запись"});
    }
    options.push({ id: "logout", value: "logout", name: "Выйти" });
    return (
      <DropdownSelect
        id="mainView.header.userIdentityId"
        className={"my-auto dropdown-accounts" + (isLaxAccount ? " lax-account-dropdown" : "")}
        store={identityStore}
        options={options}
        caret={false}
        right={true}
        optionTag={IdentityOption}
        {...props}
      />
    );
  }));

  const handleOptionOnClick = (option, userStore) => {
    if (userStore.authTypeCode !== "LAX") {
      userStore.switchAccount(option.value);
    }
  };

  const IdentityOption = injectIntl(inject("userStore") ( ({ option, checked, userStore, intl: { formatMessage } }) => {
    if (option.value === "merge") return <Merge />;
    if (option.value === "logout") return <Logout />;
    if (option.type === "account") {
      return (
        <div onClick={() => handleOptionOnClick(option, userStore)} /*id={option.id}*/
             className="dropdown-item text-wrap">
          <Temp_icon_user
          //<img src={ICON_USER_CIRCLE}
               className="image-square icon-w-30 mr-2"
               alt={option.name}/>
            <span>{option.name}</span>
        </div>
      );
    }
    return (
      <Fragment>
        { checked
          ? <Temp_icon_user
            // <img src={ICON_USER_CIRCLE}
              className="d-block image-square icon-w-30 icon-h-30"
              title={formatMessage({id: "ui.header.accounts.list.expand"})}
              alt={formatMessage({id: "ui.header.accounts.list.expand"})} />
          : <div className="text-wrap">
              <Temp_icon_user
              //<img src={ICON_USER_CIRCLE}
                className="d-block image-square icon-w-80 icon-h-80 mx-auto my-2"
                alt={option.name} />
              <AccountName name={option.name} userStore={userStore} formatMessage={formatMessage}/>
              <span className="d-block px-4 text-center font-weight-semi-bold">{option.currentAccountName}</span>
              <span className="d-block pb-1 px-4 text-center small text-secondary">{option.email}</span>
              { userStore.accounts.length > 1 && !hostnameInfo.isAdminHost
                ? <span className="d-block my-2 pt-2 px-4 border-top text-center small">
                    {formatMessage({id: "ui.header.accounts.list"})}
                  </span>
                : null
              }
            </div>
        }
      </Fragment>
    )
  }));

const AccountName = observer(({name, userStore, formatMessage}) => {
  if (userStore.authTypeCode !== "LAX" && !hostnameInfo.isAdminHost) {
    return (
      <span className="d-block px-4 text-center font-weight-bold">
        {name}
        <img src={ICON_CHECK_LOGO}
             className="image-square icon-md ml-2 show-text"
             title={formatMessage({id: "ui.header.accounts.checkLogo"})}
             alt={formatMessage({id: "ui.header.accounts.checkLogo"})}
             style={{"pointerEvents": "auto"}}
        />
      </span>
    )
  }

  return (
    <span className="d-block px-4 text-center font-weight-bold">
      {name}
    </span>
  )
});

const UserIdentity = inject("userStore")(
  observer(({ userStore, ...props }) => {
    // if (!userStore.authenticated) {
    //   return null;
    // }
    return (
      <Fragment>
        <Login />
        {/* <Logout/> */}
        <IdentitySelect {...props} />
      </Fragment>
    )
  }));

export default UserIdentity;

const Temp_icon_user = (props) => {
  return (
    <svg className={props.className} style={{fill: "#1d5c93"}} viewBox="0 0 512 512" height="30px" width="30px" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg">
      { props.title ? <title>{props.title}</title> : null }
      <path
        d="m512 256c0-141.488281-114.496094-256-256-256-141.488281 0-256 114.496094-256 256 0 140.234375 113.539062 256 256 256 141.875 0 256-115.121094 256-256zm-256-226c124.617188 0 226 101.382812 226 226 0 45.585938-13.558594 89.402344-38.703125 126.515625-100.96875-108.609375-273.441406-108.804687-374.59375 0-25.144531-37.113281-38.703125-80.929687-38.703125-126.515625 0-124.617188 101.382812-226 226-226zm-168.585938 376.5c89.773438-100.695312 247.421876-100.671875 337.167969 0-90.074219 100.773438-247.054687 100.804688-337.167969 0zm0 0"/>
      <path
        d="m256 271c49.625 0 90-40.375 90-90v-30c0-49.625-40.375-90-90-90s-90 40.375-90 90v30c0 49.625 40.375 90 90 90zm-60-120c0-33.085938 26.914062-60 60-60s60 26.914062 60 60v30c0 33.085938-26.914062 60-60 60s-60-26.914062-60-60zm0 0"/>
    </svg>
  )
};