import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import { injectIntl } from "@isf/core-localization";

const getImage = (name) => {
  let image;
  try {
    image = require("../images/" + name + ".svg");
  } catch (ex) {
    return "";
  }
  return typeof image === "string" ? image : image.default; //TODO: fix this
};

const LocaleOption = injectIntl(({ option, checked, intl: { formatMessage } }) => {
  if (!option) {
    return null;
  }
  const { value, name } = option; 
  const imgSource = getImage(value);
  return (
    <Fragment>
      { checked
        ?
        <span className="text-uppercase" title={formatMessage({id: "ui.header.language.expand"})}>
          {name.substr(0, 3)}
        </span>
        // <img src={imgSource}
        //      className="h-auto icon-w-30 cursor-pointer"
        //      title={formatMessage({id: "ui.header.language.expand"})}
        //      alt={name} />
        : <Fragment>
            <img src={imgSource}
                 className="h-auto icon-w-30 mr-2 cursor-pointer"
                 title={name}
                 alt={name} />
            <span>{name}</span>
          </Fragment>
      }
    </Fragment>
  );
});

LocaleOption.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.string.isRequired,
    name: PropTypes.string
  }),
  checked: PropTypes.bool
};

export default LocaleOption ;


