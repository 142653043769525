import { UIStore } from "@isf/core-app-store";
import { useUIStore } from "@isf/react-util";

class ToggleStore extends UIStore {
  toggle(args, force) {
    const active = this.get('active');
    const doToggle = force || (
      (typeof this.canToggle === 'function') 
        ? this.canToggle(args, active) 
        : this.canToggle === undefined ? true : !!this.canToggle);
    if (!doToggle)
      return;
    this.set(['active'], !active);
  }  
  isActive() {
    return this.get('active');
  }
}

Object.defineProperty(ToggleStore, "name", {value: "ToggleStore"});

const useToggleStore = (args) => useUIStore({
  ...args,
  state: {
    active: false
  },
  type: ToggleStore
});

export { ToggleStore, useToggleStore };