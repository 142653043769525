import React, { Component } from "react";
import { observer } from "mobx-react";
import { action, observable } from "mobx";
import _Rating from "react-rating";

@observer
class Rating extends Component {
  constructor(props) {
    super(props);
  }

  @observable
  newRating = null;

  @action
  setNewRating(value) {
    this.newRating = value;
  }

  handleOnClick = (e) => {
    const { handler, accessor } = this.props;
    const oldValue = handler.get(accessor);
    if (oldValue && oldValue === e) handler.set(accessor, null);
    else handler.set(accessor, e);
    if (this.props.input.props.onChange) {
      this.props.input.props.onChange(e);
    }
  };

  handleOnHover = (value) => {
    if (value) {
      const newRating = parseFloat(value.toFixed(1));
      this.setNewRating(newRating);
    } else this.setNewRating(null);
  };

  render() {
    const { input, handler, accessor, disabled = false  } = this.props;
    const { start = 0, stop = 5, step = 1, fractions = 1, width = 20 } = input;
    const value = handler.get(accessor);
    let rating;
    if (value) rating = parseFloat(value.toFixed(1));

    return(
      <div className="position-relative w-fit-content">
        <_Rating initialRating={value}
                 onClick={this.handleOnClick}
                 onHover={this.handleOnHover}
                 readonly={input.props.readOnly || disabled}
                 start={start - 1}
                 stop={stop}
                 step={step}
                 fractions={fractions}
                 quiet={false}
                 direction={"ltr"}
                 emptySymbol={<Star width={`${width}px`} />}
                 fullSymbol={<Star isActive width={`${width}px`} />}
        />
        {(this.newRating || rating)
          && <span className="position-absolute ml-1 rating-badge">
                {this.newRating || rating}
            </span>
        }
      </div>
    )
  }
}

export { Rating };

const Star = ({isActive, width = "20px"}) => {
  const fill = isActive ? "#fc6" : "#e3e3e3";
  return (
    <svg viewBox="0 0 19.481 19.481" width={width} height={width}>
      <path
        fill={fill}
        d="m10.201,.758l2.478,5.865 6.344,.545c0.44,0.038 0.619,0.587 0.285,0.876l-4.812,4.169 1.442,6.202c0.1,0.431-0.367,0.77-0.745,0.541l-5.452-3.288-5.452,3.288c-0.379,0.228-0.845-0.111-0.745-0.541l1.442-6.202-4.813-4.17c-0.334-0.289-0.156-0.838 0.285-0.876l6.344-.545 2.478-5.864c0.172-0.408 0.749-0.408 0.921,0z"
      />
    </svg>
  )
};