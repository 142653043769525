//ui-schema-renderer
import React, {Suspense} from "react";
import PropTypes from "prop-types";
import $ from "@isf/core-object-util";
import {useInputHandler} from "@isf/react-util";
import appStore from "@isf/core-app-store";
import engine from "../ui-engine";
import {
  LIST_CLONE_COMPONENTS,
  LIST_COMPONENTS_CLONE_REDUCERS,
  LIST_UI_COMPONENTS,
  resolveComponent,
  resolveComponentStore
} from "../ui-schema-components";
import {storeTemplToPath} from "../util";
import {getScope, hasReadPermission} from "../ui-schema-security";
import {inject} from "mobx-react";

import {bpToggleStore} from "../bp-toggle";
// import objectUtil from "@isf/core-object-util/src/object-util";
import {localeStore} from "@isf/core-localization";
import {getExternalActions, isNeedAsyncAction} from "./ui-schema-render-actions";
import {UIBaseStore} from "../ui-stores";
import {ListContext as ListContext_} from "@isf/common/list-context/list-context";
import { buildStoreName } from "./ui-schema-renderer-utils";
import { buildExternalDependencies } from "./table-external-utils";

const UISchemaRenderer = inject('userStore')((props) => {
  const context = buildRenderingContext(props);
  const uiSchemaCursor = props.uiSchema;
  // console.log('UISchemaRenderer', props);
  // if (!uiSchemaCursor.tag) {
  //   return null;
  // }
  // const changeHandler = makeInputHandler({store: props.dataStore});
  return context.renderComponent({
    ...props,
    uiSchemaCursor,
    configurationId: props.schema ? props.schema.configurationId : undefined,
    // changeHandler,
    context,
    key: '0'
  });
});

UISchemaRenderer.propTypes = {
  context: PropTypes.shape({
    resolveComponent: PropTypes.func,
    renderComponent: PropTypes.func,
    renderChildrenComponents: PropTypes.func
  })
}

const resolveInputHandler = (props) => {
  let {handler, store, accessor} = props;
  if (handler)
    return handler;
  if (!store) {
    throw new Error("No data store defined for field", props);
  }
  store = resolveStore({store, type: DataStore});
  return useInputHandler({accessor, store})().handler;
};

function WrapUiStoreType (uiStoreType, refId, props){
  return new uiStoreType({...props, refId: refId})
}

const mapComponentProps = ({tag, props: realProps, propsMap}, uiStoreType, refId, Component) => {
  const props = { ...realProps };

  if (props && (typeof Component === 'function' || tag !== Component)) {
    props['uiBaseStore'] = refId && tag !== 'RefComponent'
      ? WrapUiStoreType.bind({}, UIBaseStore, refId)
      : UIBaseStore;
  }

  if(uiStoreType && props){
    props['uiStoreType'] = refId && tag !== 'RefComponent'
      ? WrapUiStoreType.bind({}, uiStoreType, refId)
      : uiStoreType;
  }

  if(props.id || props.id === 0) {

    //TODO rewrite. If a component inside a RefComponent or List (Table, ItemGrid) needs to update the ID
    if(["Field", "Filter", 'Table', 'TableHead', 'TableBody'].includes(tag)) {
      props.id = refId ? refId + "_" + props.id : props.id;
      if(refId){
        props['refId'] = refId;
      }
    }
    if(tag === "Button"){
      props.actionName = $.get(propsMap,'events.onClick.action.ref');
    }
  }

  if (!props || !propsMap) {
    return props;
  }

  if (tag === "Table" && Array.isArray(props.columns)) {
    props.columns = props.columns.map(it => {
      if ($.isObject(it.sort) && typeof it.sort.actionName === "string") {
        const actionName = it.sort.actionName;
        const storeName = it.sort.storeName;
        return {
          ...it,
          sort: {
            ...it.sort,
            storeName: buildStoreName(storeName, refId),
            actionName: (data, componentName) => {
              engine.executeAction(actionName, {refId: refId}, data, undefined, componentName)
            }
          }
        }
      }

      return it;
    });
  }

  const {stores, events, handler, components, optionsStore, crudaction} = propsMap;
  if(isNeedAsyncAction(tag, props)){
    if(tag === 'Table'){
      props['externalActions'] = getExternalActions(props, refId);
      props['externalContext'] = {refId:refId};
      props['externalDependencies'] = buildExternalDependencies(props.externalDependencies, refId);
    }else {
      events && Object.entries(events).forEach(
          ([event, eventHandler]) => {
            props[event] = async (data, componentName) => {
              await engine.executeAction(eventHandler.action, {refId: refId}, data, undefined, componentName)
            }
          }
      );
    }
  }else {
      events && Object.entries(events).forEach(
          ([event, eventHandler]) => {
              props[event] = (data, componentName) => {
                  engine.executeAction(eventHandler.action, {refId: refId}, data, undefined, componentName)
              }
          }
      );
  }

  if(props.layout) {
    props['templates'] = engine.resolveTemplates(refId);
  }

  if (crudaction === 'show_bp') {
    props['onClick'] = () => {
      bpToggleStore.toggle();
    }
  }

  stores && Object.entries(stores).forEach(
    ([storeKey, path]) => {
      // const store = appStore.getDataStore(storeTemplToPath(path, '.'));
      const newPath = buildStoreName(path, refId);
      const store = appStore.getDataStore(newPath);
      if (!store) {
        console.error('map store: store not found for path: ' + path + ':' + storeTemplToPath(path, '.'), "found stores: ", appStore._stores, "stores in schema:", stores);
        throw new Error('Store not found for path: ' + path + ':' + storeTemplToPath(path, '.'));
      }
      props[storeKey] = store;
    }
  );

  if(propsMap.handler){
    props['storeName'] = propsMap.handler;
  }


  if (handler) {
    const path = storeTemplToPath(handler, '.');
    //const store = appStore.getDataStore(path);
    const newHandler = buildStoreName(handler, refId);
    const store = appStore.getDataStore(newHandler);
    if (!store) {
      console.error('map store: no store found for handler: ' + handler + ':' + path, "found stores: ", appStore._stores);
      throw new Error('map store: no store found for handler: ' + handler + ':' + path);
    }
    props['handler'] = useInputHandler({
      accessor: props.input?props.input.accessor:undefined,
      store: store
    })().handler
  }
  components && Object.entries(components).forEach(
    ([path, descriptor]) => {
      const Component = resolveComponent(descriptor.component);
      const ClousureComponent = props => <Component {...props} {...descriptor.props}/>;
      $.get(props, path)[descriptor.prop] = ClousureComponent;
    }
  );
  if (optionsStore && props.input) {
    // console.log('OPTIONS STORE', appStore.getDataStore(optionsStore));
    const newOptionsStore = buildStoreName(optionsStore, refId);
    const newRequestOptionsStore = buildStoreName($.get(propsMap,'optionRequest.requestStore'), refId);
    props.input.optionsStore = appStore.getDataStore(newOptionsStore);
    props.input.requestOptionsStore = appStore.getDataStore(newRequestOptionsStore);

    // console.log('OPTIONS', props['options']);
  }
  return props;
};

const resolveComponentProps = (args) => {
  const {uiSchemaCursor, store, key, refId, Component, childindex} = args;
  const props = mapComponentProps(uiSchemaCursor, store, refId, Component);
  const additionalProps = LIST_UI_COMPONENTS.includes(uiSchemaCursor.tag)
    ? {
        languages: localeStore.availableLocales,
        localeStore: localeStore
      }
    : undefined;
  return {
    key,
    ...props,
    ...additionalProps,
    childindex
  };
};

const renderComponent = (args) => {
  // console.log('remderComponent', args.configurationId);
  let {uiSchemaCursor, context, userStore, scope} = args;
  try {
    const permitted = hasReadPermission(uiSchemaCursor, userStore, scope, uiSchemaCursor.configurationId || args.configurationId);
    if (!permitted) {
      return null;
    }
    const tag = uiSchemaCursor.tag;
    const Component = context.resolveComponent(tag);
    const store = context.resolveComponentStore(tag);
    if (!Component) {
      console.error(args);
      throw new Error("Component is not defined for tag " + tag + " at path " + args.key);
    }
    const resolveComponentProps = context.resolveComponentProps({...args, store, Component});
    if(tag === 'RefComponent'){
      resolveComponentProps['context'] = context;
    }

    if (uiSchemaCursor.configurationId && uiSchemaCursor.configurationId !== args.configurationId) {
      scope = getScope();
    }

    const ListContext = args.ListContext || (LIST_COMPONENTS_CLONE_REDUCERS.includes(tag) ? ListContext_ : undefined),
      isLazyComponent = Component.$$typeof && Component.$$typeof.toString().includes('Symbol(react.lazy)');

    const children = context.renderChildrenComponents({
      ...args,
      scope: scope,
      ListContext: ListContext,
      configurationId: uiSchemaCursor.configurationId || args.configurationId
    });

    if (args.ListContext && !LIST_CLONE_COMPONENTS.includes(tag)) {
      if (isLazyComponent) {
        return (
          <ListContext.Consumer key={resolveComponentProps.key}>
            {(data) =>
              <Suspense fallback={null} key={`${resolveComponentProps.key}.0`}>
                <Component {...resolveComponentProps} key={`${resolveComponentProps.key}.0.0`} data={data}>
                  {children}
                </Component>
              </Suspense>
            }
          </ListContext.Consumer>
        );
      } else {
        return (
          <ListContext.Consumer key={resolveComponentProps.key}>
            {(data) =>
              <Component {...resolveComponentProps} key={`${resolveComponentProps.key}.0`} data={data}>
                {children}
              </Component>
            }
          </ListContext.Consumer>
        );
      }
    }

    if (isLazyComponent) {
      return (
        <Suspense fallback={null} key={resolveComponentProps.key}>
          <Component {...resolveComponentProps} key={`${resolveComponentProps.key}.0`}>
            {children}
          </Component>
        </Suspense>
      );
    } else {
      return (
        <Component {...resolveComponentProps}>
          {children}
        </Component>
      );
    }
  } catch (e) {
    console.error(e);
    // return null;
    return (
      <div>
        Возникла ошибка при отрисовке компонента
        <br/>
        {uiSchemaCursor.tag}
        <br/>
        {JSON.stringify(uiSchemaCursor.props, true)}
      </div>
    );
  }
}

const renderChildrenComponents = (args) => {
  const {uiSchemaCursor, context, key} = args;
  const children = uiSchemaCursor.children;
  return children && children.map((child, i) => (
    context.renderComponent({
      ...args,
      uiSchemaCursor: child,
      key: key ? (key + '.' + i) : i,
      childindex: i
    })
  ));
}

// const isPropInPropTypes = (component, prop) => (
//   !!(component.propTypes && component.propTypes[prop])
// );

const buildRenderingContext = ({context}) => {
  return {
    ...defaultRenderingContext,
    ...context
  };
}

const defaultRenderingContext = {
  resolveComponent,
  resolveComponentStore,
  resolveComponentProps,
  renderComponent,
  renderChildrenComponents
}

export {defaultRenderingContext as defaultContext};

export default UISchemaRenderer;
