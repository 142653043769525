export const rolesColumns = [
    {
      type: "checkbox",
      accessor: 'checkbox',
      sortable: false,
      filterable: false,
      editable: false,
      width: 45
    },
    /*{
      header: "Идентификатор",
      editable: false,
      accessor: 'id',
      filterable: false,
      sortable: true
    },*/
    {
      header: "Роль",
      editable: false,
      accessor: 'name',
      filterable: true,
      sortable: true
    },
    {
      header: "Описание",
      editable: false,
      accessor: 'description',
      filterable: true,
      sortable: true
    }/*,
    {
      type: "actions",
      accessor: 'actions',
      sortable: false,
      filterable: false,
      editable: false,
      width: 45,
      Cell: 
    }*/
  ];

export const accountsColumns = [
  {
    type: "checkbox",
    accessor: 'checkbox',
    sortable: false,
    filterable: false,
    editable: false,
    width: 45
  },
  /*{
    header: "Идентификатор",
    editable: false,
    accessor: 'id',
    filterable: false,
    sortable: true
  },*/
  {
    header: "Имя",
    editable: false,
    accessor: 'firstName',
    filterable: true,
    sortable: true
  },
  {
    header: "Фамилия",
    editable: false,
    accessor: 'lastName',
    filterable: true,
    sortable: true
  },
  
  {
    header: "Время последнего входа в систему",
    editable: false,
    accessor: 'lastLogin',
    filterable: false,
    sortable: true
  }

];

export const layout = {
  label: {
    props: {
      sm: 12,
      md: 6,
      lg: 3,
      size: 'sm'
    }
  },
  input: {
    layout: {
      col: {
        sm: 12,
        md: 6,
        lg: 9
      }
    },
    props: {
      bsSize: "sm"
    }
  }
};