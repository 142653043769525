import React from 'react';
import {inject, observer} from "mobx-react";
import classNames from "classnames";
import {Container, Row} from "reactstrap";
import $ from "@isf/core-object-util";
// import {Pagination, PaginationStore} from "../pagination/src";
// import {resolveStore} from "@isf/react-util";
// import objectUtil from "@isf/core-object-util";
// import {Item} from "./item";
import {LoadSpinner} from "../load-spinner/load-spinner-view";
import { descListSort, ascListSort } from '../list-context/list-utils';

const ItemGridContext = React.createContext();

// const paginationStore = resolveStore({path: 'itemGridPaginationStore', type: PaginationStore});

// const ItemGrid = observer((props) => {
@observer
class ItemGrid extends React.Component {
   constructor(props) {
      super(props);
      if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
      if (props.uiStoreType) this.uiStore = new props.uiStoreType(props)

      const {data, accessor, dataStore} = props;
      this.dataStoreObj = {
         store: dataStore,
         index: accessor ? accessor : undefined
      };

      if(data) {
         this.dataStoreObj.store = data.store;
         const index = this.dataStoreObj.index;
         this.dataStoreObj.index = index ? data.index + "." + index : data.index;
/*         this.store.selectionStore.set('dataStoreRef', {
            store: this.dataStoreObj.store,
            accessor: this.dataStoreObj.index
         });*/
      }
   }

   componentWillUnmount() {
      this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
      this.uiBaseStore && this.uiBaseStore.dispose();
   }

   render() {
      const {props, dataStoreObj} = this;
      const {children, dataStore, xl, lg, md, sm, xs, heightAlignment, className, gridClassName,
         noPaddingBetweenItems, sort, localeStore, languages} = props;
      // const children = objectUtil.get(props.children.props.args.uiSchemaCursor, 'children');
      // const childrenCount = children.length;
      //  const args = props.children[0].props.args;
      // if(hasPagination) {
      //   paginationStore.setTotalRows(childrenCount);
      // }

      const accessor = dataStoreObj.index || [];
      const data = dataStoreObj.store && dataStoreObj.store.get(accessor)
        ? dataStoreObj.store.get(accessor)
        : [];
      let sortData = data;

      if (sort && sort.accessor && $.isArray(sortData)) {
         const sortFunc = sort.orderBy === 'desc'
           ? descListSort
           : ascListSort;

         sortData = sortData.sort((a, b) => sortFunc(a, b, sort.accessor, sort.isLocalized, localeStore));
      }
      // const data = dataStore && $.isArray(dataStore.state) && dataStore.state || [];
      const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
        && this.uiBaseStore.classNameStore.getClassNames();

      const child = Array.isArray(children) ? children[0] : children;

      return (
        <Container fluid className={classNames("p-0", className, computedClassNames)}>
           <Row noGutters={noPaddingBetweenItems} className="grid-container position-relative" /*style={{display: display}}}*/>
              {this.uiStore && (dataStore && /*dataStore.state*/sortData ? /*dataStore.get([])*/sortData.length !== 0 : props.children.length !== 0) &&
              <LoadSpinner isLoading={this.uiStore.isLoading}/>}
              <ItemGridContext.Provider value={{
                 xl: xl,
                 lg: lg,
                 md: md,
                 sm: sm,
                 xs: xs,
                 heightAlignment: heightAlignment, /*size: size, style: {minWidth: minWidth}*/
                 gridClassName: gridClassName,
                 noPaddingBetweenItems: noPaddingBetweenItems
              }}>
                 {dataStore && child && $.isArray(sortData) ? sortData.map((item, i) => {
                    const realIndex = data.indexOf(item);

                    return (
                      <React.Fragment key={i}>
                         {React.cloneElement(child, {
                            data: {
                               store: dataStoreObj.store,
                               index: dataStoreObj.index
                                 ? dataStoreObj.index + "." + realIndex
                                 : realIndex
                            }
                         })}
                      </React.Fragment>
                    )
                 }) : children}
              </ItemGridContext.Provider>
           </Row>
        </Container>
      );
   }
}

const Child = observer(({args, uiSchemaCursor, k}) => {
   return (
     <>
        {args.context.renderComponent({
           ...args,
           uiSchemaCursor,
           key: k
        })}
     </>
   );
});

export {ItemGridContext, ItemGrid};
