import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {TableView} from '@isf/common';

const getColumns = ({formatMessage}) => [
  {
    type: 'checkbox',
    accessor: 'checkbox',
    sortable: false,
    filterable: false,
    editable: false,
    width: 45
  }, {
    header: formatMessage({id: 'ui.modal.contextPath'}),//'Path',
    editable: false,
    accessor: 'path',
    filterable: true,
    sortable: true
  }, {
    header: formatMessage({id: 'ui.modal.description'}),//'Description',
    editable: false,
    accessor: 'description',
    filterable: true,
    sortable: true
  }, {
    header: formatMessage({id: 'ui.modal.method'}),//'Operation',
    editable: false,
    accessor: 'method',
    filterable: true,
    sortable: true
  }
];

@observer
class SelectServiceTable extends Component {
  render() {
    const { serviceTableStore, selection = 'single', columns, intl } = this.props;
    return (
      <div>
        <TableView
          pageable={true}
          filterable={true}
          selection={selection}
          store={serviceTableStore.tableViewStore}
          tableViewData={serviceTableStore.pageData}
          isClient={true}
          columns={columns || getColumns(intl)}//!cols || cols === columns ? getColumns(intl) : cols}
          tableName={"Services Table"}
        />
      </div>
    );

  }
}

export default SelectServiceTable;