import {Vector} from "./vector";
import $ from "@isf/core-object-util"

const getCoordinatesOfCenter = (element) => {
  const upperLeftConnected = $.get(element,'props.bounds.upperLeft');
  const lowerRightConnected = $.get(element,'props.bounds.lowerRight');
  const X = $.toJS($.get(upperLeftConnected,'x')) + ($.toJS($.get(lowerRightConnected,'x')) - ($.toJS($.get(upperLeftConnected,'x')))) / 2;
  const Y = $.toJS($.get(upperLeftConnected,'y')) + ($.toJS($.get(lowerRightConnected,'y')) - ($.toJS($.get(upperLeftConnected,'y')))) / 2;
  return {x: X, y: Y};
};

export const getPoints = (schemaDataStore, props) => {
  const connectedElement = schemaDataStore.getElementById(props.connected.resourceId).element;
  const connectedCenter = getCoordinatesOfCenter(connectedElement);
  let points = connectedCenter.x + "," + connectedCenter.y + (props.properties.points !== "" ? "," + props.properties.points : "");
  if (props.connectTo.resourceId) {
    const connectToElement = schemaDataStore.getElementById(props.connectTo.resourceId).element;
    const connectToCenter = getCoordinatesOfCenter(connectToElement);
    points += "," + connectToCenter.x + "," + connectToCenter.y;
  }
  return points;
};

export const getCoordinatesOfLastConnection = (points, connectTo) => {
  const arrPoints = points.split(",");
  const center = getLastCoordinates(arrPoints);
  const preLastCoordinates = getPreLastCoordinates(arrPoints);
  const stencil = $.get(connectTo,'props.stencil');
  const bounds = $.get(connectTo,'props.bounds');
 return getCoordinatesOfConnection(stencil,bounds,center,preLastCoordinates,connectTo);
};

const getCoordinatesOfFirstConnection = (points, connected) => {
  const arrPoints = points.split(",");
  const center = getFirstCoordinates(arrPoints);
  const secondCoordinates = getSecondCoordinates(arrPoints);
  const {stencil,bounds} = connected.props;
  return getCoordinatesOfConnection(stencil,bounds,center,secondCoordinates,connected);
};

const getCoordinatesOfConnection = (stencil,bounds,center,coordinates,element)=>{
  if (stencil.id === "StartNoneEvent" || stencil.id === "EndNoneEvent") {
    const radius = (bounds.lowerRight.x - bounds.upperLeft.x) / 2;
    const connection = findIntersect(new Vector(center.x, center.y), +radius, new Vector(coordinates.x,coordinates.y));
    return {x: connection.x, y: connection.y}
  }
  return  getConnectionRectShape(element,{x:center.x,y:center.y},{x:coordinates.x,y:coordinates.y});
};

const getLastCoordinates = (arrPoints) => (
  {x: +arrPoints[arrPoints.length - 2], y: +arrPoints[arrPoints.length - 1]}
);

const getPreLastCoordinates=(arrPoints)=>(
  {x: +arrPoints[arrPoints.length - 4], y: +arrPoints[arrPoints.length - 3]}
);

function findIntersect(origin, radius, otherLineEndPoint) {
  let v = otherLineEndPoint.subtract(origin);
  let lineLength = v.length();
  if (lineLength === 0) throw new Error("Length has to be positive");
  v = v.normalize();
  return origin.add(v.multiplyScalar(radius));
}

const getFirstCoordinates = (arrPoints) => (
  {x: +arrPoints[0], y: +arrPoints[1]}
);

const getSecondCoordinates=(arrPoints)=>(
  {x: +arrPoints[2], y: +arrPoints[3]}
);


const getConnectionRectShape =(connectTo,p3,p4)=>{
  const {upperLeft,lowerRight} = $.toJS($.get(connectTo,'props.bounds'));
  const width = lowerRight.x-upperLeft.x;
  const height = lowerRight.y-upperLeft.y;
  const arrayOfCoordinates=[
    [{x:upperLeft.x,y:upperLeft.y},{x:upperLeft.x + width,y:upperLeft.y},p3,p4],
    [{x:upperLeft.x,y:upperLeft.y},{x:upperLeft.x ,y:upperLeft.y + height},p3,p4],
    [{x:upperLeft.x,y:upperLeft.y + height},{x:upperLeft.x + width ,y:upperLeft.y + height},p3,p4],
    [{x:upperLeft.x + width,y:upperLeft.y},{x:upperLeft.x + width ,y:upperLeft.y + height},p3,p4],
  ];
  for(let i=0;i<arrayOfCoordinates.length;i++){
    if(getPointOfIntersection(...arrayOfCoordinates[i])){
      return getPointOfIntersection(...arrayOfCoordinates[i]);
    }
  }
};


function getPointOfIntersection(p1, p2, p3, p4)
{
  let d = (p1.x - p2.x) * (p4.y - p3.y) - (p1.y - p2.y) * (p4.x - p3.x);
  let da = (p1.x - p3.x) * (p4.y - p3.y) - (p1.y - p3.y) * (p4.x - p3.x);
  let db = (p1.x - p2.x) * (p1.y - p3.y) - (p1.y - p2.y) * (p1.x - p3.x);

  let ta = da / d;
  let tb = db / d;

  if (ta >= 0 && ta <= 1 && tb >= 0 && tb <= 1)
  {
    let dx = p1.x + ta * (p2.x - p1.x);
    let dy = p1.y + ta * (p2.y - p1.y);

    return {x:dx,y:dy};
  }

  return null;
}

export const getVisiblePoints = (element,schemaDataStore) =>{
  const connectedElement=schemaDataStore.getElementById(element.props.connected.resourceId).element;
  const connectToElement=schemaDataStore.getElementById(element.props.connectTo.resourceId).element;
  let linkPoints = getPoints(schemaDataStore, element.props);
  const firstConnection= getCoordinatesOfFirstConnection(linkPoints,connectedElement);
  const lastConnection= getCoordinatesOfLastConnection(linkPoints,connectToElement);
  return  addConnectionCoordinatesToPoints(linkPoints,firstConnection,lastConnection);
};

const addConnectionCoordinatesToPoints=(points,first,last)=>{
  const arrPoints=points.split(",");
  if(first) {
    arrPoints[0] = first.x;
    arrPoints[1] = first.y;
  }
  if(last) {
    arrPoints[arrPoints.length - 2] = last.x;
    arrPoints[arrPoints.length - 1] = last.y;
  }
  return arrPoints.join(',')
};

export const removeVertex = (schemaDataStore,diagramCursorStore) => {
  const splittedPoints = getPoints(schemaDataStore,diagramCursorStore.activeElement.props).split(',');
  for(let i=2;i<splittedPoints.length-2;i=i+2){
    const prev = {x:parseInt(splittedPoints[i-2]),y:parseInt(splittedPoints[i-1])};
    const current = {x:parseInt(splittedPoints[i]),y:parseInt(splittedPoints[i+1])};
    const next = {x:parseInt(splittedPoints[i+2]),y:parseInt(splittedPoints[i+3])};
    if((prev.x === current.x && current.x=== next.x)||(prev.y === current.y && current.y=== next.y)){
      diagramCursorStore.removeVertex(i/2 - 1);
    }
  }

};