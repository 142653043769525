import appStore, {UIStore} from "@isf/core-app-store";
import {autorun, computed} from "mobx";
import {getResultComputedFunction} from "../util";
import {uuidv4} from "@isf/core-system-util";
import $ from "@isf/core-object-util/src/object-util";
import {getDataStore, getDeclaredString, getParamsValue, NOT_COMPUTED, NOT_HIDDEN
} from "../expression/utils";
import {UiDisposeStore} from "./ui-dispose-store";

const initialize=(props,id,globalComputedStore,store)=>{
    store._computedStore = new UICommonComputedStore(id,props,globalComputedStore);
    store._hiddenStore = new UICommonHiddenStore(id,props,globalComputedStore);
};

class UICommonStore/* extends UIStore*/{

    constructor(props){
        const id = 'uiButtonStore.'+uuidv4();
        /*super({path:id});*/
        const globalComputedStore = appStore.getStore('globalComputedStore','ui');
        initialize(props,id,globalComputedStore,this)
    }

    _computedStore;

    _hiddenStore;

    @computed
    get computedStore(){
        return this._computedStore;
    }

    @computed
    get hiddenStore(){
        return this._hiddenStore;
    }

    dispose(){
        this._computedStore.dispose();
        this._hiddenStore.dispose();
    }
}

class UICommonComputedStore/* extends UIStore*/extends UiDisposeStore{
    constructor(parentId,props,globalComputedStore){
        super(props);
        const parentArrPath = parentId.split('.');
        parentArrPath.splice(1,0,'computed');
        // super({path:parentArrPath});
        this.localeStore = props.localeStore;
        this.map = $.objectToObservableMap({});
        initializeComputedAutorun(props,this,globalComputedStore)
    }
    get=(path)=>{
        return this.map.get(path)
    }

    set=(path, value)=>{
        this.map.set(path,value)
    }
}

const initializeComputedAutorun =(props,store,globalComputedStore)=>{
    if(props.computed && !NOT_COMPUTED.includes(props.computed)/* props.computed !== 'condition'*/) {
        const {params, expression} = props.computed;
        //const resultComputedFunction = getResultComputedFunction(globalComputedStore,{...props.computed});
        if (params && params.length !== 0) {
            store.addAutorun(autorun(() => {
                let declaredString = "";
                if (params.length !== 0) {
                    declaredString = getDeclaredString(params, store.localeStore, props.data,{refId:props.refId});
                    declaredString !== "let;" && store.set('value', eval(declaredString + expression));
                }
                // const values = getParamsValue(params, store.localeStore, props.data,{refId:props.refId});
                // console.log(values)
                // store.set('value', resultComputedFunction(...values));
            }));
        }else{
            store.set('value', eval(expression));
        }
    }
};

class UICommonHiddenStore/* extends UIStore*/ extends UiDisposeStore{
    constructor(parentId,props,globalComputedStore){
        super(props);
        const parentArrPath = parentId.split('.');
        parentArrPath.splice(1,0,'hidden');
        /*super({path:parentArrPath});*/
        this.localeStore = props.localeStore;
        this.map = $.objectToObservableMap({});
        initializeHiddenAutorun(props,this)
    }

    get=(path)=>{
        return this.map.get(path)
    }

    set=(path, value)=>{
        this.map.set(path,value)
    }
}



const initializeHiddenAutorun =(props,store)=>{
    if(props.hidden === 'true' || props.hidden === 'false'){
        store.set('isHidden', eval(props.hidden))
    }else if (props.hidden && !NOT_HIDDEN.includes(props.hidden)/*props.hidden.expression*/) {
        const hiddenParams = props.hidden && props.hidden.params || [];
        const hiddenExpression = props.hidden && props.hidden.expression || {};
        store.addAutorun(autorun(() => {
            let declaredString = "";
            if (hiddenParams.length !== 0) {
                declaredString = getDeclaredString(hiddenParams, store.localeStore, props.data,{refId:props.refId});
                declaredString !== "let;" && store.set('isHidden', eval(declaredString + hiddenExpression))
            }else{
                store.set('isHidden', eval(hiddenExpression));
            }

        }));
    }
};

const initializeStoreStructure = (params,localeStore) => {
    const {locale} = localeStore;
    params.map(param => {
        let pathArray = param.path ? $.strToArray(param.path) : [];//param.path.split('.');
        const storeName = param.storeName;
        param.isLocalized && pathArray.push(locale);
        if(!['pageUrl','userStore'].includes(storeName)){
            const store = getDataStore(storeName, {});
            if(!store.get(pathArray.slice(0,-1))){
                store.set(pathArray.slice(0,-1),{})
            }
        }
    });
};

export {UICommonStore, initializeStoreStructure}