import React from "react"
import styled from "styled-components"
import {observer} from "mobx-react"

const StyledBPMNDiagram = observer(styled.div`
  width:100%;
  position: relative;
`);

export const BPMNDiagram = observer((props) => {
  return (
    <StyledBPMNDiagram >
      {props.children}
    </StyledBPMNDiagram>
  );
});

export const BPMNDiagramDefaultConfig = () => {
  return {
    uiSchema: {
      tag: 'BPMNDiagram',
      children:[]
    }
  };
};