import React, {Component} from "react";
import {observer} from "mobx-react";
import {injectIntl} from "@isf/core-localization";
import PropTypes from "prop-types";
import classNames from "classnames";
import {getEnvVariable} from "../../../core/system-util/src";
import {RestApi} from "../../../core/rest/src";
import {default as AsyncSelect_} from "react-select/async";
import $ from "@isf/core-object-util";
import {getComponentOptions, getOptionBinding, getOptionsValue, sortOptions} from "../select/select-actions";

const API_DEFAULT_CONF = {
  "schema": getEnvVariable("API_DEFAULT_SCHEMA"),
  "host": getEnvVariable("API_DEFAULT_HOST"),
  "port": getEnvVariable("API_DEFAULT_PORT"),
  "context": "api"
};
const dataApi = new RestApi({...API_DEFAULT_CONF, ...{context: "api"}});

@injectIntl
@observer
class AsyncSelect extends Component {
  /**
   * Stop calling getOptionByValueCode with same value
   * @type {boolean}
   */
  optionByValueCode = undefined;

  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      currentTimerId: null,
      inputValue: null,
      defaultOptions: []
    };
    this._defaultOptions = [];
    this.scriptFunction = undefined;
  }

  componentDidMount() {
    this.getDefaultOptions();
  }

  /**
   * Load option by value.
   */
  async getOptionByValueCode(value) {
    const {  input, localeStore, onChange, intl } = this.props;
    const { requestOptionsStore, optionsStore } = input;

    if (!requestOptionsStore || value === undefined || value === null) {
      return [];
    }

    const optionBinding = getOptionBinding(input, localeStore);
    requestOptionsStore.set('body.paging', { page: 1, limit: 1 });
    requestOptionsStore.set('body.filter.' + optionBinding.value, [{
      value: value,
      operation: 'equals'
    }]);

    if (onChange) {
      await onChange(undefined, "Field");
    }

    const resolveOptions = optionsStore && Array.isArray(optionsStore.state)
      ? optionsStore.state
      : [];
    return $.isArray(resolveOptions)
      ? getComponentOptions(resolveOptions, optionBinding, intl)
      : resolveOptions;
  }

  /**
   * Update selectedOption if value by accessor change
   * @returns {Promise<void>}
   */
  async updateSelectedOption() {
    const { selectedOption } = this.state;
    const { handler, accessor } = this.props;
    const value = handler.get(accessor)
    const optValue = `${value}`;

    const isSameNullSelOpt = value === null && !selectedOption
    const isSameSelOpt = value !== null && selectedOption && selectedOption.value === optValue
    const isSameValueCode = this.optionByValueCode === value;


    if (value === undefined
      || isSameNullSelOpt
      || isSameSelOpt
      || isSameValueCode
    ) {
      return;
    }


    if (value === null && selectedOption) {
      this.setState({ selectedOption: null })
      return;
    }

    this.optionByValueCode = value;
    const resolveOptions = await this.getOptionByValueCode(value);
    this.optionByValueCode = undefined;
    const newValue = handler.get(accessor);
    const newSelOpt = resolveOptions.find((it) => it.value === optValue) || null;
    const isNull = newSelOpt === null && selectedOption === null;
    const isEqual = newSelOpt && selectedOption && newSelOpt.value === selectedOption.value;
    if (newValue === value && !isNull && !isEqual) {
      this.setState({selectedOption: newSelOpt})
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateSelectedOption();
  }

  getOptions = (inputValue, func, isLoad) => {
    const {intl, input, onChange, localeStore} = this.props;
    const { locale } = localeStore || {};
    const {optionsStore, requestOptionsStore, options, optionsBinding} = input;
    const minCharacters = (input.props && input.props.minCharactersStartLoadingOptions) || 0;
    return new Promise((resolve) => {
      if (!isLoad && inputValue.length < minCharacters) return resolve(this.state.defaultOptions || []);
      const timerId = setTimeout(async () => {
        const optionBinding = getOptionBinding(input, localeStore);
        requestOptionsStore && requestOptionsStore.set('body.paging', {
          page: 1,
          limit: ((+input.props.limitSizeOptions) || 10)
        });
        requestOptionsStore && requestOptionsStore.set('body.filter', {});
        if (!isLoad) {
          const searchFromBeginning = input.props && input.props.loadingOptionsWhenFirstCharacter
          const searchValue = searchFromBeginning
            ? `${inputValue}%`
            : `%${inputValue}%`
          requestOptionsStore && requestOptionsStore.set('body.filter.' + optionBinding.name, [{
            value: searchValue,
            operation: 'like'
          }])
        }
        if (onChange) {
          await onChange(undefined, "Field");
        }
        let resolveOptions = optionsStore ? optionsStore.state || null : options || null;
        resolveOptions = $.isArray(resolveOptions)
          ? getComponentOptions(resolveOptions, optionBinding, intl)
          : resolveOptions;
        if (resolveOptions && input.sortOptions !== false) {
          resolveOptions = sortOptions(resolveOptions, locale);
        }
        if (isLoad) {
          this.setState({defaultOptions: resolveOptions})
        }
        resolve($.toJS(resolveOptions))
      }, isLoad ? 0 : 2000);

      if (!this.state.currentTimerId) {
        this.setState({currentTimerId: timerId});
      } else {
        clearTimeout(this.state.currentTimerId);
        this.setState({currentTimerId: timerId});
      }
    })
  };

  getDefaultOptions = async () => {
    await this.getOptions("", undefined, true);
    await this.updateSelectedOption();
  };

  handleOnChange = (event) => {
    const {handler, accessor, input, placeholder, onAfterChange} = this.props;
    let value = null, selectedOption = null;
    if (event && event.value) {
      value = getOptionsValue(event.value, input, placeholder);
      selectedOption = event;
    }
    handler.set(accessor, value);
    this.setState({selectedOption: selectedOption});
    if (onAfterChange) {
      onAfterChange(undefined, "Field");
    }
  };

  loadingMessage = () => {
    const {intl: {formatMessage}} = this.props;
    return formatMessage({id: "ui.autocomplete.loading.message"});
  };

  noOptionsMessage = () => {
    const {intl: {formatMessage}} = this.props;
    return formatMessage({id: "ui.autocomplete.options.no"});
  };

  render() {
    const {handler, accessor, input, placeholder, disabled} = this.props;
    const {props: {isPopUpLabel, labelName}} = input;
    let value = handler.get(accessor);
    const defaultOptions = this.state.defaultOptions;
    // this.state.selectedOption && defaultOptions.push(this.state.selectedOption);
    //const minCharacters = (input.props && input.props.minCharactersStartLoadingOptions) || 0;
    let mutableProps = {};
    if (!isPopUpLabel) mutableProps.placeholder = placeholder;
    if (isPopUpLabel) {
      mutableProps = {
        placeholder: null,
      }
    }

    return (
      <div className="w-100 position-relative">
        <AsyncSelect_
          value={this.state.selectedOption}
          isDisabled={disabled}
          //defaultOptions={(defaultOptions.length > 0 && defaultOptions) || minCharacters === 0}
          defaultOptions={defaultOptions}
          loadOptions={this.getOptions}
          cacheOptions
          onChange={this.handleOnChange}
          inputValue={this.state.inputValue}
          onInputChange={inputValue => this.setState({inputValue})}
          isClearable
          menuPlacement="auto"
          loadingMessage={this.loadingMessage}
          noOptionsMessage={this.noOptionsMessage}
          {...mutableProps}
          className={classNames(
            {
              "autocomplete has-value": isPopUpLabel && (value || this.state.inputValue),
              "autocomplete": isPopUpLabel && !(value || this.state.inputValue)
            })}/>
        {isPopUpLabel && <span className="input-label-focus" data-placeholder={labelName}/>}
      </div>
    );
  }
}

AsyncSelect.propTypes = {
  accessor: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string]).isRequired,
  handler: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
};

export default AsyncSelect;
