export const BoundaryErrorConf = (number, coordinates) => ({
  tag: "Shape",
  props: {
    bounds: {
      lowerRight: {
        x: coordinates.x + 30,
        y: coordinates.y + 30
      },
      upperLeft: {
        x: coordinates.x,
        y: coordinates.y
      }
    },
    resourceId: "boundaryerror" + number,
    childShapes: [],
    stencil: {
      id: "BoundaryErrorEvent"
    },
    dockers: [
      {
        x: 10.0,
        y: 28.0
      }
    ],
    properties: {
      overrideid: "boundaryerror" + number,
      name: "Error",
      cancelactivity: false,
      errorref: "E422",
      executionlisteners: {
        executionListeners: []
      }
    },
    outgoing: []
  },
  children:[]
});