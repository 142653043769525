import React, { Component } from 'react';
import classNames from 'classnames';
import { Popover as PopoverBootstrap } from '@isf/bootstrap';
import PopoverContext from './popover-context';

class Popover extends Component {
  static contextType = PopoverContext;

  constructor(props) {
    super(props);
    if (props.uiStoreType) this.uiStore = new props.uiStoreType(props);
    this.state = {
      popoverOpen: false,
    };
  }

  componentWillUnmount() {
    this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
  }

  toggle = () => this.setState((prevState) => ({ popoverOpen: !prevState.popoverOpen }));

  render() {
    const {
      isOpenPresentation, triggerPresentation, id, trigger, delay, size, placement, disabled, hideArrow, offsetY,
      className, innerClassName, children
    } = this.props;
    const targetIdProp = (this.context && this.context.uuid) ? `Popover${(this.context && this.context.uuid)}` : null;
    const propsDisabled = !this.uiStore && (disabled === true || disabled === 'true');
    const popoverDisabled = (this.uiStore && this.uiStore.disabledStore.get('isDisabled')) || propsDisabled;

    const { popoverOpen } = this.state;

    return (
      <PopoverBootstrap
        id={id}
        target={targetIdProp}
        isOpen={isOpenPresentation || popoverOpen}
        toggle={this.toggle}
        trigger={triggerPresentation || trigger}
        delay={delay}
        placement={placement}
        disabled={popoverDisabled}
        hideArrow={hideArrow}
        modifiers={{offset: {offset: `0, ${offsetY}`} } }
        className={classNames({ [`popover-${size}`]: size }, className)}
        innerClassName={innerClassName}
      >
        {children}
      </PopoverBootstrap>
    )
  };
}

export default Popover;
