import { getEnvVariable } from "@isf/core-system-util";
import { RestApi } from "@isf/core-rest";
import { observable, action, computed } from "mobx";
import { DataStore } from "@isf/core-app-store";

const API_DEFAULT_CONF = {
  "schema": getEnvVariable('API_DEFAULT_SCHEMA'),
  "host": getEnvVariable('API_DEFAULT_HOST'),
  "port": getEnvVariable('API_DEFAULT_PORT'),
  "context": "api"
};

export const adminApi = new RestApi({...API_DEFAULT_CONF, ...{context: 'api'}});

class GroupStore {
  
  @observable id;

  @observable group;
  @observable roles = [];
  @observable allRoles = [];
  @observable accounts = [];
  @observable allAccounts = [];

  constructor() {
    //TODO
    const href = location.href.split('/');
    this.id = href[href.length - 1];
    this.dataStore = new DataStore({
      path: "groupsView.groupDataStore",
      state: {
      },
      observableAsMap: true
    });
  }

  @action
  set(path, value) {
    this.dataStore.set(path, value);
  }

  get(path) {
    return this.dataStore.get(path);
  }

  getId() {
    return this.id;
  }

  async loadGroupRoles() {
    this.roles = await adminApi.request({path: `/umgt/group/${this.id}/roles`});
  }

  async loadRoles() {
    this.allRoles = await adminApi.request({path: `/umgt/role`});
  }

  async loadGroupAccounts() {
    this.accounts = await adminApi.request({path: `/umgt/group/${this.id}/accounts`});
  }

  async loadAccounts() {
    this.allAccounts = await adminApi.request({path: `/umgt/account`});
  }

  async save() {
    const group = this.dataStore.stateJS;
    const response = await adminApi.request({path: `/umgt/group`, options: {
      method: group.id ? 'put' : 'post',
      body: JSON.stringify(group)
    }});
    if(response) {
      location.href = '/umgt/group/' + response.id;
    }
  }

  async load() {
    this.group = await adminApi.request({path: `/umgt/group/${this.id}`});
    this.set('id', this.group.id);
    this.set('name', this.group.name);
    this.set('description', this.group.description);
    this.roles = this.group.roles || [];
    this.loadGroupAccounts();
  }

  async addNewAccount(accounts) {
    const response = await adminApi.request({path: `/umgt/group/${this.id}/accounts`, options: {
      method: 'post',
      body: JSON.stringify(accounts)
    }});
    if(response) {
      this.loadGroupAccounts();
    }
  }

  async addNewRole(roles) {
    const response = await adminApi.request({path: `/umgt/group/${this.id}/roles`, options: {
      method: 'post',
      body: JSON.stringify(roles)
    }});
    if(response) {
      this.loadGroupRoles();
    }
  }

  async deleteGroupRoles(roles) {
    const promises = [];
    roles.forEach(role => {
      promises.push(adminApi.request({path: `/umgt/group/${this.id}/roles/${role.id}`, options: { method: 'delete' }}));
    });
    Promise.all(promises).then(() => { this.loadGroupRoles() });
  }

  async deleteGroupAccounts(accounts) {
    const promises = [];
    accounts.forEach(account => {
      promises.push(adminApi.request({path: `/umgt/group/${this.id}/accounts/${account.id}`, options: { method: 'delete' }}));
    });
    Promise.all(promises).then(() => { this.loadGroupAccounts() });
  }

  //TODO: remove sorting and filtering from client side
  getTableViewData(tableData, tableViewStore) {
    let data = tableData;
    const filterBy = tableViewStore.filterStore.filterBy;
    const sortBy = tableViewStore.tableStore.sortBy;
    const startItem = tableViewStore.paginationStore.startItem;
    let endItem = tableViewStore.paginationStore.endItem;
    if(filterBy) {      
      const accessors = Object.keys(filterBy);      
      const filteredData = data.filter(column => {
        const array = accessors.filter(accessor => {
          return String(column[accessor]).toUpperCase().startsWith(filterBy[accessor]);
        });
        return array.length >= accessors.length;
      });
      data = filteredData;
    }
    if (sortBy) {
      const order = tableViewStore.tableStore.order;
      data = data.sort((a,b)=> {
        const value = a[sortBy]>b[sortBy];
        return value ? order : -order;
      });
    }
    const pageData = [];
    if (startItem!==undefined && endItem!==undefined) {
      if (data.length < endItem) endItem = data.length;
      for (let i = startItem; i < endItem; i++) {
        pageData.push(data[i]);
      }
    }
    return { rows: pageData, totalRows: data ? data.length : 0 };
  }

}
  
  
export { GroupStore };
  