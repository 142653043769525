import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { injectIntl } from "@isf/core-localization";
import { URL_LOGOUT, referer } from "./conf";

/**
 * Link for logout in dropdown component
 */

@injectIntl
@inject("userStore")
@observer
class Logout extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  render() {
    const { /*userStore,*/ intl: { formatMessage } } = this.props;
    // if (!userStore.initialized || !userStore.authenticated) {
    //   return null;
    // }
    return (
      <form method="post" action={URL_LOGOUT} ref={this.formRef} className="w-100 mt-2 px-4 border-top"> {/** make working for IE11/quickfiх*/}
        <div className="w-100 mt-3 mb-2">
          <input type="hidden" name="Referer" value={referer()} />
          <input
            type="submit"
            onClick={(e) => {
              e.stopPropagation();
              this.formRef.current.submit();
            }}
            className="w-100 my-0 btn btn-outline-primary"
            value={formatMessage({id: "ui.header.accounts.signOut"})}
          />
        </div>
      </form>
    );
  };
}

export default Logout;