import React from "react";
import { observer, Provider } from "mobx-react";
import classNames from "classnames";
import { Col } from "reactstrap";
import { ItemGridContext } from "./item-grid";
import {ListContext} from "../list-context/list-context";

@observer
class Item extends React.Component {
   constructor(props) {
      super(props);
      if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
      if (props.uiStoreType)  this.uiStore = new props.uiStoreType(props);
   }

   componentWillUnmount() {
      this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
      this.uiBaseStore && this.uiBaseStore.dispose();
   }

   render() {
      const {children, className, noPaddingInsideItem, withoutBorders} = this.props;
      if (this.uiStore && this.uiStore.hiddenStore.get("isHidden")) return null;
      const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
        && this.uiBaseStore.classNameStore.getClassNames();
      return (
         <>
            <ItemGridContext.Consumer>
               {context => {
                  let {xl = 12, lg = 12, md = 12, sm = 12, xs = 12, heightAlignment = false, gridClassName,
                     noPaddingBetweenItems} = context || {};
                  return (
                     <ListContext.Provider value={this.props.data}>
                        <Col xl={xl} lg={lg} md={md} sm={sm} xs={xs}
                             className={classNames(gridClassName, {"px-3 py-3": !(gridClassName || noPaddingBetweenItems)})}>
                           <div className={classNames(
                                   "grid-item-container bg-light text-break",
                                   className, computedClassNames,
                                   {"h-100": heightAlignment, "p-0": noPaddingInsideItem, "border-0": withoutBorders})}>
                              {children}
                           </div>
                        </Col>
                     </ListContext.Provider>
                  )
               }}
            </ItemGridContext.Consumer>
         </>
      );
   }
}

// const Item = observer((props) => {
//   const {children} = props;
//   return (
//     <>
//       <ItemGridContext.Consumer>
//         {context => {
//           let { xl = 12, lg = 12, md = 12, sm = 12, xs = 12, heightAlignment = false } = context || {};
//           let classNames = "grid-item-container bg-light";
//           classNames += heightAlignment ? " h-100" : "";
//           return (
//             <Provider data={props.data || "no such data"}>
//               <Col xl={xl} lg={lg} md={md} sm={sm} xs={xs} className={/*context.size + */' p-3'} /*style={context.style}*/>
//                 <div className={classNames}>
//                 {children}
//                 </div>
//               </Col>
//             </Provider>
//           )
//         }}
//       </ItemGridContext.Consumer>
//     </>
//   );
// });

export {Item};