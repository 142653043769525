import {Store} from "@isf/core-app-store";
import {observable} from "mobx";
import $ from "@isf/core-object-util";

function stateJS() {
  if (this.state instanceof Store) {
    return this.state.stateJS;
  }

  return $.toJS(this.state);
}

function get(path, pureJS) {
  let value = $.get(this.state, path);
  if (this.state instanceof Store) {
    value = this.state.get(path);
  }

  if (pureJS) {
    value = $.toJS(value, {recurseEverything: true});
  }

  return value;
}

function set(path, value) {
  if (this.state instanceof Store) {
    return this.state.set(path, value);
  }

  return $.set(this.state, path, value);
}

const dataWrapper = (data) => {
  const wrapper = {
    state: data,
    get: get,
    set: set
  };

  Object.defineProperty(wrapper, "stateJS", {
    get: stateJS
  });

  return wrapper;
};

const routerWrapper = (store) => {
  const wrapper = {
    get(path) {
      return store.get(path)
    },
    set(path, value, apiStore) {
      const oldValue = store.get(path);
      store.set(path, value);

      path = $.strToArray(path);
      const firstSegment = path.length > 0 && path[0];

      if(firstSegment === "path") {
        const pathValue = store.get(["path"]);

        if (oldValue !== value) {
          apiStore.setterNoReload(true);//TODO
        }

        const search = store.search || "";
        store.replace("/" + pathValue.join('/') + search);
      }
    }
  };

  return wrapper;
};

const localeWrapper = (store) => {
  const wrapper = {
    get(path) {
      const pathArr = $.strToArray(path);
      const strPath = pathArr.join('.');
      if (strPath === 'locale') {
        return store.status.locale;
      } else if (strPath === 'availableLocales') {
        return store.availableLocales;
      }
    },
    set(path) {}
  };

  return wrapper;
};

export {dataWrapper, routerWrapper, localeWrapper};
