import React, { Component, Fragment } from "react";
import classNames from "classnames";
import DateTimePicker from "react-datetime-picker/dist/entry.nostyle";
import DatePicker from "react-date-picker/dist/entry.nostyle";
import TimePicker from "react-time-picker/dist/entry.nostyle";
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ICON_CROSS } from "@isf/common-resources";
import { injectIntl } from "react-intl";
import { FormFeedback } from "reactstrap";

@injectIntl
@observer
class DateInput extends Component {
  constructor(props) {
    super(props);
    const format = props.input.props.type ? props.input.props.type : 'datetime';
    let emptyValues = null;
    if (format === 'date') {
      const maxDetail = props.input.props.maxDetail;
      if (maxDetail === 'decade') emptyValues = { year: true };
      else if (maxDetail === 'year') emptyValues = { month: true, year: true };
      else emptyValues = { day: true, month: true, year: true };
    }
    else if (format === 'datetime') {
      const isUSFormat = (new Date(0)).toLocaleTimeString().split(' ').length === 2;
      const clockFormat = isUSFormat ? 'hour12' : 'hour24';
      emptyValues = {
        day: true,
        month: true,
        year: true,
        [clockFormat]: true,
        minute: true,
      };
    }
    this.state = {
      focus: false,
      invalid: false,
      emptyValues: emptyValues,
      isEmptyValue: false,
    };
  }

  getValidDay = (day) => {
    if (isNaN(day)) return '00';
    return day < 10 ? `0${day}` : `${day}`;
  }

  getDay = (newDate, isUTC) => {
    let day;
    if (isUTC) day = newDate.getUTCDate();
    else day = newDate.getDate();
    return this.getValidDay(day);
  }

  getValidMonth = (month) => {
    if (isNaN(month)) return '00';
    return month < 10 ? `0${month}` : `${month}`;
  }

  getMonth = (newDate, isUTC) => {
    let month;
    if (isUTC) month = newDate.getUTCMonth() + 1;
    else month = newDate.getMonth() + 1;
    return this.getValidMonth(month);
  }

  getValidYear = (year) => {
    if (isNaN(year) || year < 1000) return '0000';
    return `${year}`;
  }

  getYear = (newDate, isUTC) => {
    let year;
    if (isUTC) year = newDate.getUTCFullYear();
    else year = newDate.getFullYear();
    return this.getValidYear(year);
  }

  getValidHours = (hours) => {
    if (isNaN(hours)) return '00';
    return hours < 10 ? `0${hours}` : `${hours}`;
  }

  getHours = (newDate, isUTC) => {
    let hours;
    if (isUTC) hours = newDate.getUTCHours();
    else hours = newDate.getHours();
    return this.getValidHours(hours);
  }

  getValidMinutes = (minutes) => {
    if (isNaN(minutes)) return '00';
    return minutes < 10 ? `0${minutes}` : `${minutes}`;
  }

  getMinutes = (newDate, isUTC) => {
    let minutes;
    if (isUTC) minutes = newDate.getUTCMinutes();
    else minutes = newDate.getMinutes();
    return this.getValidMinutes(minutes);
  }

  getSeconds = (newDate, isUTC) => {
    let seconds;
    if (isUTC) seconds = newDate.getUTCSeconds();
    else seconds = newDate.getSeconds();
    return seconds < 10 ? `0${seconds}` : `${seconds}`;
  }

  handleOnChange = (newDate) => {
    const { handler, accessor, input } = this.props;
    const format = input.props.type ? input.props.type : 'datetime';
    if (newDate && newDate instanceof Date) {
      switch (format) {
        case 'date': {
          switch (input.props.maxDetail) {
            case 'decade': {
              const year = this.getYear(newDate, false);
              newDate = `${year}`;
              break;
            }
            case 'year': {
              const month = this.getMonth(newDate, false);
              const year = this.getYear(newDate, false);
              newDate = `${year}-${month}`;
              break;
            }
            default: {
              const day = this.getDay(newDate, false);
              const month = this.getMonth(newDate, false);
              const year = this.getYear(newDate, false);
              newDate = `${year}-${month}-${day}`;
              break;
            }
          }
          break;
        }
        case 'time': {
          let hours = this.getHours(newDate, true);
          let minutes = this.getMinutes(newDate, true);
          let seconds = this.getSeconds(newDate, true);
          newDate = `T${hours}:${minutes}:${seconds}.000Z`;
          break;
        }
        case 'datetime':
        default: {
          const day = this.getDay(newDate, true);
          const month = this.getMonth(newDate, true);
          const year = this.getYear(newDate, true);
          let hours = this.getHours(newDate, true);
          let minutes = this.getMinutes(newDate, true);
          let seconds = this.getSeconds(newDate, true);
          newDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
          break;
        }
      }
      this.setEmptyValues(false);
      if (this.state.isEmptyValue) this.setState({ isEmptyValue: false });
      handler.set(accessor, newDate.toString());
    }
    else {
      this.setEmptyValues(true);
      if (this.state.isEmptyValue) this.setState({ isEmptyValue: false });
      handler.set(accessor, null);
    }

    if (this.state.invalid) this.setState({ invalid: false });
  };



  resolveDate = (format, date) => {
    if (date === undefined || date === null) {
      return null;// undefined;
    }
    switch (format) {
      case 'datetime':
      case 'date':
        if (isNaN(Date.parse(date))) {
          return undefined;
        } else {
          return new Date(date);
        }
      case 'time':
        if (!isNaN(Date.parse(date))) {
          return undefined;
        } else {
          return date;
        }
    }
  };

  getDateComponent = (format) => {
    switch (format) {
      case 'date': return DatePicker;
      case 'time': return TimePicker;
      case 'datetime': return DateTimePicker;
    }
    return DatePicker;
  };

  getDateFormat = (format, maxDetail) => {
    const isUSFormat = (new Date(0)).toLocaleTimeString().split(' ').length === 2;
    switch (format) {
      case 'date':
        switch (maxDetail) {
          case 'decade':
            return "yyyy";
          case 'year':
            return isUSFormat ? "M.yyyy" : "MM.yyyy";
          default:
            return isUSFormat ? "M/d/yyyy" : "dd.MM.yyyy";
        }
      case 'time': return isUSFormat ? "h:mm a" : "HH:mm";
      case 'datetime':
      default: return isUSFormat ? "M/d/yyyy h:mm a" : "dd.MM.yyyy HH:mm";
    }
  };

  changeFormatDat(getDate, a) {
    return getDate = new Date(getDate)
  }

  onCalendarOpen = () => {
    const { handler, accessor, input } = this.props;
    const format = input.props.type ? input.props.type : 'datetime';
    if (handler.get(accessor)) {
      this.setEmptyValues(false);
      this.setState({ isEmptyValue: false });
    } else {
      if (format !== 'datetime') {
        this.setEmptyValues(true);
        this.setState({ isEmptyValue: true });
      }
    }
    if (format !== 'datetime') this.setState({ invalid: false });
  };

  onBlur = (e) => {
    if (e && e.target) {
      let { value, min, max, name } = e.target;
      if (!name) return;
      const { handler, accessor} = this.props;

      if (value) {
        const valueNumb = Number.parseInt(value);
        const minNumb = Number.parseInt(min);
        const maxNumb = Number.parseInt(max);

        if ((valueNumb < minNumb || valueNumb > maxNumb)) {
          if (!this.state.invalid) this.setState({ invalid: true });

          if (handler.get(accessor)) {
            this.setEmptyValues(true);
            this.setState({ invalid: false, isEmptyValue: false });
            handler.set(accessor, null);
          }
        }
        this.setEmptyValue(name, false);
        const { emptyValues, isEmptyValue } = this.state;
        let isNewEmptyValue = false;
        for (let [key, value] of Object.entries(emptyValues)) {
          if (key !== name && value) {
            isNewEmptyValue = true;
            break;
          }
        }
        if (isNewEmptyValue !== isEmptyValue) this.setState({ isEmptyValue: isNewEmptyValue });
      } else {
        this.setEmptyValue(name, true);

        if (handler.get(accessor)) {
          this.setEmptyValues(true);
          this.setState({ invalid: false, isEmptyValue: false });
          handler.set(accessor, null);
        } else {
          const { emptyValues, isEmptyValue } = this.state;
          let isNewEmptyValue = false;
          for (let [key, value] of Object.entries(emptyValues)) {
            if (key !== name && !value) {
              isNewEmptyValue = true;
              break;
            }
          }
          if (isNewEmptyValue !== isEmptyValue) this.setState({ isEmptyValue: isNewEmptyValue });
        }
      }
      this.setState({ focus: false });
    }
  };

  setEmptyValues = (value) => {
    Object.keys(this.state.emptyValues).forEach(key => this.setEmptyValue(key, value));
  };

  setEmptyValue = (name, value) => {
    if (this.state.emptyValues && this.state.emptyValues[name] !== value) {
      this.setState(state => ({
        emptyValues: {
          ...state.emptyValues,
          [name]: value,
        }
      }));
    }
  };

  onPaste = (e) => {
    e.preventDefault();
    let { input, handler, accessor, uiStore } = this.props;
    const value = e.clipboardData ? e.clipboardData.getData('text/plain') : null;

    if (value) {
      let separator;
      if (value.includes('.')) separator = '.';
      else if (value.includes('/')) separator = '/';
      if (!separator) return;

      const values = value.split(separator);
      let newDate = null;
      let yearIndex = 2;
      let monthIndex = 1;
      let dayIndex = 0;
      const isUSFormat = (new Date(0)).toLocaleTimeString().split(' ').length === 2;
      if (isUSFormat) {
        monthIndex = 0;
        dayIndex = 1;
      }

      const format = input.props.type ? input.props.type : 'datetime';
      switch (format) {
        case 'date': {
          switch (input.props.maxDetail) {
            case 'decade': {
              if (values.length !== 1) return;
              let year = parseInt(values[yearIndex]);
              year = this.getValidYear(year);

              const newLocalDate = new Date(`${year}-01-01T00:00:00`);
              year = this.getYear(newLocalDate, false);

              if (year === '0000') newDate = null;
              else newDate = `${year}`;
              break;
            }
            case 'year': {
              if (values.length !== 2) return;
              let year = parseInt(values[yearIndex]);
              year = this.getValidYear(year)
              let month = parseInt(values[monthIndex]);
              month = this.getValidMonth(month);

              const newLocalDate = new Date(`${year}-${month}-01T00:00:00`);
              year = this.getYear(newLocalDate, false);
              month = this.getMonth(newLocalDate, false);

              if (month === '00' || year === '0000') newDate = null;
              else newDate = `${year}-${month}`;
              break;
            }
            default: {
              if (values.length !== 3) return;
              let year = parseInt(values[yearIndex]);
              year = this.getValidYear(year)
              let month = parseInt(values[monthIndex]);
              month = this.getValidMonth(month);
              let day = parseInt(values[dayIndex]);
              day = this.getValidDay(day);

              const newLocalDate = new Date(`${year}-${month}-${day}T00:00:00`);
              year = this.getYear(newLocalDate, false);
              month = this.getMonth(newLocalDate, false);
              day = this.getDay(newLocalDate, false);

              if (day === '00' || month === '00' || year === '0000') newDate = null;
              else newDate = `${year}-${month}-${day}`;
              break;
            }
          }
          break;
        }
        case 'datetime':
        default: {
          if (values.length !== 3) return;
          let yearInput = values[yearIndex];
          let yearValue;
          let hours;
          let minutes;
          if (yearInput.length === 4) {
            yearValue = yearInput;
            hours = '00';
            minutes = '00';
          } else {
            let yearWithTime = yearInput.split(' ');
            if (yearWithTime.length !== 2) {
              yearValue = yearInput.slice(0, 5);
              hours = '00';
              minutes = '00';
            } else {
              yearValue = yearWithTime[0];
              let time = yearWithTime[1].split(':');
              hours = parseInt(time[0]);
              hours = this.getValidHours(hours)
              minutes = parseInt(time[1]);
              minutes = this.getValidMinutes(minutes)
            }
          }

          let year = parseInt(yearValue);
          year = this.getValidYear(year)
          let month = parseInt(values[monthIndex]);
          month = this.getValidMonth(month);
          let day = parseInt(values[dayIndex]);
          day = this.getValidDay(day);

          const newLocalDate = new Date(`${year}-${month}-${day}T${hours}:${minutes}:00`);
          let UTCYear = this.getYear(newLocalDate, true);
          let UTCMonth = this.getMonth(newLocalDate, true);
          const UTCDay = this.getDay(newLocalDate, true);
          const UTCHours = this.getHours(newLocalDate, true);
          const UTCMinutes = this.getMinutes(newLocalDate, true);

          if (UTCDay === '00' || UTCMonth === '00' || UTCYear === '0000') newDate = null;
          else newDate = `${UTCYear}-${UTCMonth}-${UTCDay}T${UTCHours}:${UTCMinutes}:00.000Z`;
          break;
        }
      }

      if (!newDate) return;

      const defMinDate = (uiStore && uiStore.restrictionsStore && uiStore.restrictionsStore.get('minDate'))
        || (input.props && input.props.minDate);
      const defMaxDate = (uiStore && uiStore.restrictionsStore && uiStore.restrictionsStore.get('maxDate'))
        || (input.props && input.props.maxDate);
      let minDate = defMinDate && new Date(defMinDate);
      let maxDate = defMaxDate && new Date(defMaxDate);
      if (minDate && new Date(newDate) < minDate) return;
      if (maxDate && new Date(newDate) > maxDate) return;

      this.setEmptyValues(false);
      if (this.state.isEmptyValue) this.setState({ isEmptyValue: false });
      handler.set(accessor, `${newDate}`);
      if (this.state.invalid) this.setState({ invalid: false });
    }
  }

  render() {
    let { input, handler, accessor, intl, disabled = false, uiStore } = this.props;
    const { focus, invalid, isEmptyValue } = this.state;
    const { locale } = intl;

    const isPopUpLabel = input.props.isPopUpLabel, labelName = input.props.labelName, readOnly = input.props.readOnly, maxDetail = input.props.maxDetail;

    const format = input.props.type ? input.props.type : 'datetime';
    const DateInput_ = this.getDateComponent(format);
    let getDate = handler.get(accessor);
    getDate = !!getDate ? new Date(getDate) : null;
    let date = this.resolveDate(format, getDate);
    const defMinDate = (uiStore && uiStore.restrictionsStore && uiStore.restrictionsStore.get('minDate'))
      || (input.props && input.props.minDate);
    const defMaxDate = (uiStore && uiStore.restrictionsStore && uiStore.restrictionsStore.get('maxDate'))
      || (input.props && input.props.maxDate);
    let minDate = defMinDate && new Date(defMinDate);
    let maxDate = defMaxDate && new Date(defMaxDate);
    minDate = this.resolveDate(format, minDate);
    maxDate = this.resolveDate(format, maxDate);
    let classNameDateTime = (input.props && input.props.bsSize) ? `form-control-${input.props.bsSize}` : null;
    return (
      <Fragment>
        <DateInput_ {...input}
                    onCalendarOpen={this.onCalendarOpen}
                    onClockOpen={this.onCalendarOpen}
                    onFocus={() => this.setState({ focus: true })}
                    onBlur={(e) => this.onBlur(e)}
                    className={classNames(
                      "form-control", input.className, classNameDateTime,
                      {[`form-control-${input.bsSize}`]: input.bsSize,
                        "has-value": isPopUpLabel && (date || focus || invalid || isEmptyValue),
                        'border-danger': invalid || (!focus && isEmptyValue),
                      }
                    )}
                    tileClassName={({ date }) => (
                      date.toDateString() === new Date().toDateString()
                        ? "current-date"
                        : null
                      )}
                    format={(isPopUpLabel && !date && !focus && !(invalid || isEmptyValue))
                      ? this.getDateFormat(format, maxDetail).replace(/[\.\/\:]/g, '')
                      : this.getDateFormat(format, maxDetail)}
          // calendarClassName={input.bsSize ? "calendar-api-schema-sm sm" : ""}
                    locale={locale}
                    minDate={minDate || this.resolveDate(format, new Date('1000.01.01'))}
                    maxDate={maxDate || this.resolveDate(format, new Date('9999.12.31'))}
                    maxDetail={maxDetail}
                    dayPlaceholder={(isPopUpLabel && !date && !focus && !(invalid || isEmptyValue)) ? null : "--"}
                    monthPlaceholder={(isPopUpLabel && !date && !focus && !(invalid || isEmptyValue)) ? null : "--"}
                    yearPlaceholder={(isPopUpLabel && !date && !focus && !(invalid || isEmptyValue)) ? null : "----"}
                    hourPlaceholder={(isPopUpLabel && !date && !focus && !(invalid || isEmptyValue)) ? null : "--"}
                    minutePlaceholder={(isPopUpLabel && !date && !focus && !(invalid || isEmptyValue)) ? null : "--"}
                    disableClock={format === "date" ? true : false}
                    calendarIcon={null}
                    clearIcon={!date ? null : clearIcon()}
                    onChange={this.handleOnChange}
                    onPaste={this.onPaste}
                    value={date}
                    disabled={disabled || readOnly}
        />
        { isPopUpLabel && <span className="input-label-focus" data-placeholder={labelName} /> }
        {(invalid || (!focus && isEmptyValue)) && (
          <FormFeedback className="d-block">
            {intl.formatMessage({id: 'ui.dateInput.validation.invalid'})}
          </FormFeedback>
        )}
      </Fragment>
    );
  }
}
const clearIcon = () => {
  return <img
    src={ICON_CROSS}
    className="icon-w-13 h-auto opacity-3 opacity-full-hover"
    alt="" />
};

DateInput.propTypes = {
  inputProps: PropTypes.object,
  handler: PropTypes.object.isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]).isRequired,
};

export default DateInput;