import React, {Fragment} from "react"
import {observer} from "mobx-react"
import {Arrowhead} from "./arrowhead";
import {getPoints} from "./link-actions";

@observer
class Link extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {schemaDataStore} = this.props;
    const resourceId = this.props.resourceId;
    const points = getPoints(schemaDataStore, {...this.props});
    const color = schemaDataStore.isExecuted(resourceId)?"#1d8a3a":"black";
    return (
      <Fragment>
        <polyline
          points={points}
          fill="none"
          stroke={color}
          strokeWidth="1"
          strokeLinejoin={"round"}
          style={{zIndex:0}}/>
        <Arrowhead linkPoints={points} {...this.props} color={color}/>
      </Fragment>
    )
  }
}

export default Link;

export const linkDefaultConfig = () => {
  return {
    uiSchema: {
      tag: 'link',
      props: {
        text: "defaultLinkText",
        upperLeft: {
          x: 0,
          y: 0
        },
        lowerRight: {
          x: 0,
          y: 0
        }
      }
    }
  };
};

