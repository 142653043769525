import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {injectIntl} from "@isf/localization";
import {Col} from "@isf/bootstrap";
import {Message} from "@isf/common";
import {ServiceOperationConfig} from "./components/service-operation-config";
import classNames from "classnames";

@injectIntl
@observer
class ApiSelection extends Component {
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const { handler, accessor, dataContainer, nameButtonOpenModal, modalDisabled, className } = this.props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <ServiceOperationConfig
        opener={{outline: true, color: "primary"}}
        openerContent={<Message message={nameButtonOpenModal && nameButtonOpenModal.message} />}
        handler={handler}
        accessor={accessor}
        dataContainer={dataContainer}
        modalDisabled={modalDisabled}
        className={classNames(className, computedClassNames)}
      />
    )
  }
}

export {ApiSelection};