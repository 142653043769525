import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import { DropdownToggle } from "@isf/bootstrap";
import { resolveStore } from "@isf/react-util";
import { Dropdown } from "./dropdown";
import DropdownStore from "./dropdown-store";

const DropdownOption = ({ option }) => (
  <Fragment>
    {option && option.name}
  </Fragment>
);

const optionByValue = (value, options) => (
  options.find(option => option.value === value)
);

const SelectToggle = observer ( ({ store, options, caret = true, optionTag: DropdownOption}) => {
  const toggleOption = optionByValue(store.value, options);
  return (
    <DropdownToggle tag="span" caret={caret} className="d-flex align-items-center cursor-pointer">
      <DropdownOption option={toggleOption} checked />
    </DropdownToggle>
  )
});

@observer
class DropdownSelect extends Component {

  constructor(props) {
    super(props);
    const { value, options } = props;
    this.store = resolveStore({
      ...props,
      type: DropdownStore,
      state: {
        value: value || options[0].value
      }
    });

    this.toggle = props.toggleTag || (
      ({options, caret}) => (
        <SelectToggle
          store={this.store}
          options={options}
          caret={caret}
          optionTag={props.optionTag || DropdownOption}/>
      ));
  }

  // getToggleTag = () => {
  //   const { value } = this.store;
  //   const { options } = this.props;
  //   const { 
  //     optionTag: DropdownOption,
  //     toggleTag
  //    } = this.props;
  //   const toggleOption = optionByValue(value, options);
  //   if (toggleTag) return toggleTag;
  //   return (
  //     <DropdownToggle tag="span" caret={caret} className="d-flex align-items-center cursor-pointer">
  //       <DropdownOption option={toggleOption} checked />
  //     </DropdownToggle>
  //   )
  // }

  render() {    
    // const toggle = this.getToggleTag;

    return (
      <Dropdown toggleTag={this.toggle} {...this.props} />
    );
  }
}

DropdownSelect.propTypes = {
  ...Dropdown.propTypes
};

DropdownSelect.defaultProps = {
  optionTag: DropdownOption
};

export default DropdownSelect;