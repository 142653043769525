import React,{Fragment} from "react";

import {TreeView, BranchNodeContent, LeafNodeContent} from "@isf/tree";
import {observer} from "mobx-react";
import {Wrapover} from "@isf/wrapover"

const hoverComponent=()=>(
  <Fragment>
    <div style={{fontSize:20}} onClick={()=>console.log("click")}>+</div>
  </Fragment>
);

const DraggableBranchNodeContent = observer((props) => (
  <Wrapover{...props} hoverComponent={hoverComponent()}>
    <BranchNodeContent {...props}/>
  </Wrapover>
));


const DraggableLeafNodeContent = observer((props) => (
    <Wrapover{...props} hoverComponent={hoverComponent()}>
      <LeafNodeContent {...props}/>
    </Wrapover>
  )
);

const TreeEditView = observer((props) => (
    <TreeView
      BranchNodeContent={DraggableBranchNodeContent}
      LeafNodeContent={DraggableLeafNodeContent}
      {...props}/>
  )
);

export {TreeEditView};