import React from "react";
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import classNames from "classnames";

@observer
class SectionContainer extends React.Component{
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {children, tabs, className, uiBaseStore, ...other} = this.props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <div className={classNames(className, computedClassNames)}>
        {children}
      </div>
    )
  }
}

SectionContainer.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

export default SectionContainer;