import { FormattedMessage as FM } from "react-intl";
import React from "react";
import { localeStore } from "@isf/core-localization";

class FormattedMessage extends React.Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const {id, description, defaultMessage, values} = this.props;
    return <FM id={id}
               description={description}
               defaultMessage={defaultMessage || localeStore.getMessageFromLocale(id, "ru")}
               values={values}
    />
  }
}

export default FormattedMessage;