import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";
import { Router } from "react-router-mobx";
import { BrowserRouter, Link, Route, Switch } from "react-router-dom";

import { routerStore } from "@isf/core-app-store";
import { engine, UISchemaView } from "@isf/ui-schema";

import { bpToggleStore } from "@isf/ui-schema";
// import userStore from "user-store";
// import { PageTemplate } from "@isf/common";

import page from "./page";
import { DiagramReadOnlyView, CommonAlert } from "@isf/common";
import { GlobalErrorView } from "../global-error-view/global-error-view";
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import {SignView} from "../global-error-view/sign-view";
import PageLoadingSpinner from "./page-loading-spinner";

@inject("userStore")
@observer
class PageView extends Component {
  render() {
    return (
      <>
        <Router
          component={BrowserRouter}
          routerStore={routerStore.mobxStore}>
          <Fragment>
            <div className="position-relative">
              {page.title}
            </div>
            {page.uiSchema
              ? <UISchemaView
                  scope={page.securityScope}
                  schema={page.schema} 
                  uiSchema={page.uiSchema} 
                  microfrontend={page.microfrontend} />
              : null}
            <DiagramReadOnlyView toggleStore={bpToggleStore} />
          </Fragment>
        </Router>
        <PageLoadingSpinner isLoading={engine.isPageLoading} width={"64px"}/>
        <GlobalErrorView />
        <SignView />
        <CommonAlert stack={true} />
      </>
    );
  }
}

export default PageView;