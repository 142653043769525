import { UIStore } from "@isf/core-app-store";
import {computed, action, observable} from "mobx";

class NavbarStore extends UIStore {

  _navItems = [];

  set navItems(items){
    this._navItems = items;
  }

  get navItems(){
    return this._navItems;
  }

  pushNavItem(navItem) {
    this.navItems.push(navItem);
  }

  removeNavItem(itemId) {
    const index = this.navItems.findIndex(it => it.id === itemId);
    if (~index) {
      this.navItems.splice(index, 1);
    }
  }

  get activeItemId() {
    const itemKeys = this.keys('active');
    return itemKeys.findIndex(name => this.get(['active', name]) === true);
  }

  isActive(itemId) {
    return !!this.get(['active', itemId]);
  }

  wasActivated(itemId) {
    return this.get(['active', itemId]) !== undefined;
  }

  setActive(itemId, value) {
    const itemKeys = this.keys('active');
    itemKeys.map(key => {
      if (this.get(['active', key]) !== 'toggle') {
        this.set(['active', key], false);
      }
    });

    this.set(['active', itemId], value);
  }

  toggleActive(itemId) {
    const path = ['active', itemId];
    const active = this.get(path);
    this.set(path, active ? false : 'toggle');
  }

  setTab(value) {
    value = value === "true" ? true : false
    this.set('tab', value)
  }

  get tab() {
    return !!this.get('tab')
  }
}

Object.defineProperty(NavbarStore, "name", {value: "NavbarStore"});

export { NavbarStore };