import { getEnvVariable } from "@isf/core-system-util";
import { hostnameInfo } from "@isf/common";
import appStore from "@isf/core-app-store";

const getApplicationHostName = () => {
  let hostname = "account";

  if(getEnvVariable("ENV_TYPE") === "DEV.LOCAL" && hostnameInfo.isAdmin) {/*hostnameInfo.isLocalhost*/
    // hostname = "localhost";
    hostname = "admin";

  } else if (hostnameInfo.isAdminHost) {
    hostname = "admin";
  }

  return hostname;
};

const INACTIVE_ACCOUNT_PAGE_CONTEXT_PATH = {
  'LAX': "profile/lax/v1",
  'STR': "profile/strVerification/v1",
}
const getInactivePageContextPath = () => {
  const userStore = appStore.getStore("user", "user");
  return INACTIVE_ACCOUNT_PAGE_CONTEXT_PATH[userStore.authTypeCode];
}

const getInactiveLAXPageContextPath = () => {
  return "profile/laxVerification/v1"
}

const PATH_TO_PAGE_CONTENT_CHILDREN = "children.0.children.1.children.1.children.0.children.0";
const PATH_TO_ACCOUNT_PAGE_CONTENT_CHILDREN = 'children.0.children.2.children.1.children.0.children.0';
const INACTIVE_ACCOUNT_PATH_TO_PAGE_CONTENT_CHILDREN = "children.0";

const getPathToPageContent = () => {
  const userStore = appStore.getStore("user", "user");

  if (userStore.isInactiveAccount || userStore.isLAXInactiveAccount) {
    return INACTIVE_ACCOUNT_PATH_TO_PAGE_CONTENT_CHILDREN;
  }

  return getApplicationHostName() === 'admin'
    ? PATH_TO_PAGE_CONTENT_CHILDREN
    : PATH_TO_ACCOUNT_PAGE_CONTENT_CHILDREN;
};

export {getApplicationHostName, getPathToPageContent, getInactivePageContextPath, getInactiveLAXPageContextPath};
