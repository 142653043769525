import React, { Component, createContext, Fragment } from "react";
import { inject, observer } from "mobx-react";
import classNames from "classnames";

const SidebarContext = createContext(null);

@inject("store")
@observer
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isBackdrop: false,
    };
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    this.updateBackdrop = this.updateBackdrop.bind(this);
  }

  componentDidMount() {
    this.sidebarCheck();
    this.updateBackdrop();
    window.addEventListener('resize', this.updateBackdrop);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
    window.removeEventListener('resize', this.updateBackdrop);
  }

  sidebarCheck = () => {
    const {store} = this.props;
    const {isActive} = store;
    if (document.documentElement.clientWidth <= 535 && isActive) store.setActive(false);
    this.state.loading && this.setState({loading: false});
  }

  updateBackdrop() {
    if (document.documentElement.clientWidth <= 535 && !this.state.isBackdrop) {
      this.setState({isBackdrop: true});
    } else if (document.documentElement.clientWidth > 535 && this.state.isBackdrop) {
      this.setState({isBackdrop: false});
    }
  }

  render() {
    if (this.state.loading) return null;
    const {store, children, className} = this.props;
    const {isActive} = store;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <>
        <div className={classNames(
          "sidebar", className, computedClassNames,
          {
            "sidebar-active": isActive,
            "sidebar-hidden": !isActive
          })}
        >
          <SidebarContext.Provider
            value={{
              sidebarCheck: this.sidebarCheck
            }}
          >
            {children}
          </SidebarContext.Provider>
        </div>
        {isActive && this.state.isBackdrop && (
          <div
            className="sidebar-backdrop-small-device header-margin-sidebar-active"
            onClick={() => store.setActive(!isActive)}
          />
        )}
      </>
    )
  }
}

export default Sidebar;
export { SidebarContext };