import React from 'react';
import {injectIntl} from "@isf/core-localization";
import {observer} from "mobx-react";
import classNames from "classnames";
import {Container, Row, Col} from "@isf/bootstrap";
import {ICON_HAPPY_SMILE, ICON_NEUTRAL_SMILE, ICON_SAD_SMILE} from "@isf/common-resources";
import $ from "@isf/core-object-util";
import {FormFeedback} from "reactstrap";

const RATINGS = [
    {
        icon: ICON_HAPPY_SMILE,
        message: "ui.reportRating.happy",
        mark: 1
    },
    {
        icon: ICON_NEUTRAL_SMILE,
        message: "ui.reportRating.neutral",
        mark: 0
    },
    {
        icon: ICON_SAD_SMILE,
        message: "ui.reportRating.sad",
        mark: -1
    }
];

@injectIntl
@observer
class ReportRating extends React.Component {
    constructor(props) {
        super(props);
        if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
        this.state={hovered:undefined,pressed:undefined};
    };

    componentWillUnmount() {
        this.uiBaseStore && this.uiBaseStore.dispose();
    }

    render() {
        const {intl, id, dataStore, accessor, className} = this.props;
        const error = dataStore && dataStore.getErrorByAccessor && dataStore.getErrorByAccessor(accessor);
        const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
          && this.uiBaseStore.classNameStore.getClassNames();
        return (
            <div/* style={{display: 'flex'}}*/ className={classNames(className, computedClassNames)}>
                    <Row style={!!error ? {border:"1px solid #f14336"}:{}}>
                        {RATINGS.map((el, i) => {
                            const messageId = $.get(el, 'message');
                            const text = intl.messages[messageId] && intl.formatMessage({id: messageId})
                            return (
                                <Col /*sm={"auto"} xs={"auto"} md={"auto"} lg={"auto"} xl={"auto"}*/ key={id + '.smile.' + i} >
                                    <div onMouseOver={(e)=>{{/*if(this.state.hovered !== i)this.setState({hovered:i});*/}e.target.style.cursor='pointer'}}
                                         onMouseDown={(e)=>{this.setState({pressed:i});}}
                                         onDragEnd={(e)=>{this.setState({pressed:undefined});}}
                                         onClick={(e)=>handleClick(dataStore,accessor,$.get(el,'mark'))}
                                         onMouseUp={(e)=>{this.setState({pressed:undefined});}}
                                       /*  onMouseOut={(e)=>this.setState({hovered:undefined})}*/
                                         style={{textAlign:'center'}}>
                                        <div key={id + '.smile.' + i} >
                                            <img src={$.get(el, 'icon')} alt={text} style={getStyleIMG(this.state,i)}/>
                                        </div>
                                        <span className={(isSelected(dataStore,accessor,$.get(el,'mark')) && 'font-weight-bold')||""}>{text}</span>
                                    </div>
                                 </Col>
                            )
                        })}
                    </Row>
                <FormFeedback style={{display: 'block'}}>{$.get(error,'message')?$.get(error,'message'):$.get(error,'description')}</FormFeedback>
            </div>
        )
    }
};

const isSelected = (dataStore, accessor, value)=>{
    if(dataStore && dataStore.get){
        return dataStore.get(accessor)===value;
    }
    return false;
}

const handleClick = (dataStore,accessor="",value) =>{
    if(dataStore && dataStore.set){
        dataStore.set(accessor,value)
    }
};

const getStyleIMG=(state,i)=>{
    const imgStyle = {height: '5rem', width: '5rem', marginLeft:"auto", marginRight:"auto", display:"block"};
    if(state.pressed === i){
        /*return {...imgStyle,boxShadow:"-1px -3px 2px black", borderRadius:'50%'}*/
        return imgStyle
    }else
    //     if(state.hovered === i){
    //     return {...imgStyle,boxShadow:"2px 3px 2px olive", borderRadius:'50%'}
    // }
    // return imgStyle;
        return {...imgStyle,boxShadow:"2px 3px 2px olive", borderRadius:'50%'}

};

export default ReportRating