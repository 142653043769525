import React from "react";
import { inject, observer } from "mobx-react";

import { Button } from "@isf/bootstrap";

import { URL_LOGIN, referer } from "./conf";

const Login = inject("userStore") (observer(({ userStore }) => {
  if (!userStore.initialized || userStore.authenticated) {
    return null;
  }
  return (
    <form action={URL_LOGIN}>
      <input type="hidden" name="Referer" value={referer()} />
      <Button type="submit" color="primary" outline>Войти</Button>
    </form>
  );
}));

export default Login;