import React from "react";
import { CustomInput } from 'reactstrap';
import { observer } from 'mobx-react';

const handleOnChange = (e, props) => {
  const { handler, accessor, input } = props;
  const value = !handler.get(accessor);
  handler.set(accessor, value);

  if (input.props.onChange) {
    input.props.onChange(e);
  }
};

const SwitchInput = observer((props) => {

  const { handler, accessor, className, disabled } = props;
  const { labelName, nullable, isLabelRight, ...inputProps } = props.input.props;
  const value = !!handler.get(accessor);
  return (
    <div className={className}
      // onClick={() => handleOnChange(accessor, handler)}
    >
      <CustomInput
        checked={value}
        disabled={disabled}
        {...inputProps}
        onChange={(e) => {handleOnChange(e, props) }}
      />

    </div >
  );
})

export default SwitchInput;