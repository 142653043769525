import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import classNames from 'classnames';
import {Container as DefaultContainer} from 'reactstrap';
import {LoadSpinner} from '../load-spinner/load-spinner-view';

@observer
class Container extends Component {
    constructor(props) {
        super(props);
        if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
        if (props.uiStoreType) this.uiStore = new props.uiStoreType(props);
    }

    componentWillUnmount() {
        this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
        this.uiBaseStore && this.uiBaseStore.dispose();
    }

    render() {
        const {children, className, uiStoreType, hidden, computedClassName, loadDataAction, languages, localeStore,
            uiBaseStore, ...other} = this.props;
        if (this.uiStore && this.uiStore.commonStore.hiddenStore.get('isHidden')) return null;
        const loading = this.uiStore && this.uiStore.loadStore.isLoading;
        const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
          && this.uiBaseStore.classNameStore.getClassNames();

        if (loading) {
            return (
                <div className="position-relative">
                    {this.uiStore && <LoadSpinner isLoading={loading}/>}
                    <DefaultContainer className={classNames(className, computedClassNames)} {...other}>
                        {children}
                    </DefaultContainer>
                </div>
            );
        } else {
            return (
                <DefaultContainer className={classNames(className, computedClassNames)} {...other}>
                    {children}
                </DefaultContainer>
            )
        }
    }
}

export {Container}