export const CatchTimerEventConf = (number, coordinates) => {
  return (
    {
      tag: "Shape",
      props:
        {
          bounds: {
            lowerRight: {
              x: coordinates.x + 35,
              y: coordinates.y + 35
            },
            upperLeft: {
              x: coordinates.x,
              y: coordinates.y
            }
          },
          resourceId: "timerintermediatecatchevent"+number,
          childShapes: [],
          stencil: {
            id: "CatchTimerEvent"
          },
          properties: {
            overrideid: "timerintermediatecatchevent"+number,
            name: "TimerCatchEvent",
            timerdurationdefinition: "",
            timerdatedefinition: "",
            timercycledefinition: "",
            executionlisteners: {
              executionListeners: []
            }
          },
          outgoing: []
        },
      children: []
    }
  )
};