import React, { Component } from "react";
import { observer } from "mobx-react";
import {
  ModalHeader, ModalBody, ModalFooter, ModalContainer,
  ModalCancelButton, ModalOKButton, TableView, TableViewStore
} from "@isf/common";

import { resolveStore } from "@isf/react-util";
import $ from "@isf/core-object-util";

@observer
class GroupDataModal extends Component {

  tableViewStore;

  constructor(props) {
    super(props);
    this.tableViewStore = resolveStore({ path: 'groupDataTableViewStore', type: TableViewStore });
  }

  toggle = (props) => {
    this.tableViewStore.filterStore.clearFilter();
    const buttonType = props['data-button-type'];
    if (buttonType === 'cancel') {
      return true;
    }
    const selected = this.tableViewStore.tableStore.selectionStore.selected;
    if(selected) {
      const selectedItems = $.toJS(this.tableViewStore.data).filter((element, index) => selected.includes(index));
      this.props.onChange(selectedItems);
      this.tableViewStore.tableStore.selectionStore.clearStore();
    }
    return true;
  }

  render() {
    const { id, groupData, groupStore, toggleStore, header, columns } = this.props;
    const tableViewData = groupStore.getTableViewData(groupData, this.tableViewStore);
    return(
      <ModalContainer
        toggleStore={ toggleStore }
        toggle={ this.toggle }
        size="lg"
      >
        <ModalHeader>
          { header }
        </ModalHeader>
        <ModalBody>
          <TableView
            id={id + "TableView"}
            pageable={true}
            filterable={true}
            store={this.tableViewStore} 
            tableViewData={tableViewData} 
            isClient={true} 
            columns={columns} />
        </ModalBody>
        <ModalFooter>
          <ModalOKButton>
            Добавить
          </ModalOKButton>
          <ModalCancelButton color="secondary">
            Отменить
          </ModalCancelButton>
        </ModalFooter>
      </ModalContainer>
    );
  }
}

export default GroupDataModal;