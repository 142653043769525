import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Input, Pagination, PaginationItem, PaginationLink} from "reactstrap";
import {inject, observer} from "mobx-react";
import {getPageRange, getPageRangeWithDots} from "./pagination-store";
import classNames from "classnames";

const handleOnPageClick = (handler, newPage, onClick, store, data, disabled, e) => {
  if(!disabled) {
    if (store) {
      store.setCurrentPage(newPage);
    } else {
      handler.set('body.paging.page', newPage);
      if (typeof data === 'object') {
        onClick = onClick.bind(this, data, "Pagination");
      }
      onClick && onClick(e, "Pagination");
    }
  }
};

const handleOnInputDataChange = (store, e) => {
  const newRowsPerPage = parseInt(e.target.value);
  store.setRowsPerPage(newRowsPerPage);
};

const PaginationButton = observer((props) => {
  const {label, id, disabled, active, onClick} = props;
  return (
    <PaginationItem disabled={disabled} active={active}>
      <PaginationLink type={"button"} id={id} onClick={onClick}>{label}</PaginationLink>
    </PaginationItem>
  );
});


@observer
class PaginationBar extends Component {
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {back, forward, handler, rowsPerPageOptions, enumerable, size, store, onClick, data, className} = this.props;
    const pages = store?store.pages:(enumerable ? getPages(handler) : []),
      currentPage = store?store.currentPage:(handler && handler.get('body.paging.page') || 1),
      previousPage = store?store.previousPage: (currentPage - 1),
      nextPage = store?store.nextPage:(currentPage + 1),
      hasNext = (enumerable ? nextPage <= getMaxPage(handler) : handler && handler.get('body.paging.hasNext')),
      hasPrevious = enumerable ? previousPage !== 0 : handler && handler.get('body.paging.hasPrevious'),
      rowsPerPage = store? store.rowsPerPage : 5,
      lastPage = store? store.lastPage:null,
      previousDisabled = store?previousPage===0:!hasPrevious,
      nextDisabled = store?lastPage===currentPage:!hasNext;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <div className={classNames("d-inline-flex flex-wrap justify-content-between align-middle", className, computedClassNames)}>
        <Pagination className="mb-2" size={size}>
          <PaginationButton label={back} disabled={previousDisabled} active={false} id={previousPage}
                            onClick={(e) => handleOnPageClick(handler, previousPage, onClick, store, data, previousDisabled, e)}/>
          {
            pages.map((page, index) =>
              <PaginationButton label={page} disabled={page === '...'} active={currentPage === page}
                                id={index + 1} key={index} onClick={(e) => handleOnPageClick(handler, page, onClick, store, data, page === '...', e)}/>
            )
          }
          <PaginationButton label={forward} disabled={nextDisabled} active={false} id={nextPage}
                            onClick={(e) => handleOnPageClick(handler, nextPage, onClick, store, data, nextDisabled, e)}/>
        </Pagination>
        {store &&
        <Input id="rowsPerPageOptions" type="select" className="w-fit-content input-pagination-size mb-2 align-middle"
               onChange={(e) => handleOnInputDataChange(store, e)}
               value={rowsPerPage}>
          {
            rowsPerPageOptions.map((option, index) =>
              <option key={index} value={option}>{option} записей</option>
            )
          }
        </Input>
        }
      </div>
    );
  }
}

const getPages = (handler) => {
  let page = getMaxPage(handler),
    currentPage = handler && handler.get('body.paging.page') || 0;
  const range = getPageRange(page, currentPage, 2);
  const rangeWithDots = getPageRangeWithDots(range);
  return rangeWithDots;
  // const pages = [];
  // while (page !== 0) {
  //   pages.unshift(page);
  //   page--;
  // }
  // return pages;
};

const getMaxPage = (handler) => {
  const total = handler && handler.get('body.paging.total') || 0;
  const limit =handler &&  handler.get('body.paging.limit') || 5;
  return Math.ceil(total / limit);
};

PaginationBar.propTypes = {
  id: PropTypes.string,
};

PaginationBar.defaultProps = {
  rowsPerPageOptions: [5, 10, 15, 20],
  back: "<",
  forward: ">"
};


export {PaginationBar};
