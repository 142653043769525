import React from "react";
import {default as CommonAlert} from "react-s-alert";
import {defaultAlertMessageOptions as defOptions} from "./alert-message-constants";

const AlertMessage = ({tag, props, text}) => {
  const Tag = tag;

  return (
    <Tag {...props}>
      {text}
    </Tag>
  )
};

const getAlertMessage = (messages) => {
  if(!Array.isArray(messages)) return messages;

  return (
    <>
      {messages.map((message, index) => <AlertMessage key={index} {...message}/>)}
    </>
  )
};

const createAlertMessage = (messages, type = "error", options = defOptions) => {
  CommonAlert[type] && CommonAlert[type](getAlertMessage(messages), options);
};

export {createAlertMessage, getAlertMessage, AlertMessage}