import $ from "@isf/core-object-util";
import appStore from "@isf/core-app-store";
import {getDeclaredString} from "../expression/utils";
import {localeStore} from "@isf/core-localization";

export const executeDataTransferAction = (action,context, actionName) =>{
    const refId = context.refId;
    const changedStores = $.get(action,"changedStores") || [];
    for(let config of changedStores){
        const changedStoreName = $.get(config,'storeName');
        const path = $.get(config,'path');
        const changedStore = appStore.getDataStore(refId?refId+"."+changedStoreName:changedStoreName);
        if(changedStore) {
            const params = $.get(config, 'params');
            const expression = $.get(config, 'expression');
            let declaredString = "", value;
            if (params.length !== 0) {
                declaredString = getDeclaredString(params, localeStore, undefined, context);
                value = eval(declaredString + expression);
            } else {
                value = eval(expression);
            }
            if(path){
                changedStore.set(path,value);
            }else{
                changedStore.setState(value)
            }
        }

    }
};