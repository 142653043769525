import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { inject } from "mobx-react";

const handleOnClick = (e, props) => {
  const { toggleStore, outerOnClick } = props;
  toggleStore.toggle(props.children.props);
  outerOnClick && outerOnClick(e);
}

const Toggle = inject("toggleStore") ( props => {
  const { children } = props;
  const addProps = {
    onClick: e => handleOnClick(e, {
      ...props, 
      outerOnClick: props.onClick || children.props.onClick
    })
  };
  return (
    <Fragment>
      {React.cloneElement(children, addProps)}
    </Fragment>
  );
});

Toggle.propTypes = {
  children: PropTypes.node.isRequired
}

export { Toggle };