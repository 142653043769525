import React, { Component } from 'react';
import { uuidv4 } from '@isf/core-system-util';
import PopoverContext from './popover-context';

class Overlay extends  Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: uuidv4(),
    }
  }

  render() {
    const { children } = this.props;
    const { uuid } = this.state;

    return (
      <PopoverContext.Provider value={{uuid}}>
        {children}
      </PopoverContext.Provider>
    );
  };
}

export default Overlay;
