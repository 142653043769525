import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import classNames from 'classnames';
import {ICON_DEFAULT_IMAGE} from '@isf/common-resources';

@observer
class ConfiguredImage extends Component {
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    if (props.uiStoreType) this.uiStore = new props.uiStoreType(props);
    this.imageLoadError = this.imageLoadError.bind(this);
  }

  componentWillUnmount() {
    this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  imageLoadError(e) {
    if (e.target.src !== ICON_DEFAULT_IMAGE) {
      e.target.onerror = null;
      e.target.src = ICON_DEFAULT_IMAGE;
    }
  }

  render() {
    const { src, maxWidth, uiStoreType, children, computed, data, languages, localeStore,
      className, uiBaseStore, computedClassName, ...other } = this.props;
    const computedValue = (this.uiStore && this.uiStore.computedStore.get("value")) || ICON_DEFAULT_IMAGE;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();
    const imgSrc = computed && computed !== 'false'
      ? computedValue
      : src
        ? src
        : ICON_DEFAULT_IMAGE;

    return (
      <img
        src={imgSrc}
        onError={e => this.imageLoadError(e)}
        style={{maxWidth: `${maxWidth}px`}}
        className={classNames("d-block w-100 configured-image", className, computedClassNames)}
        alt="Image"
        {...other}
      />
    )
  }
}

export default ConfiguredImage;