import React, {Component} from 'react';
import {observer} from 'mobx-react';
import classNames from 'classnames';

@observer
class PageTitle extends Component {
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {children, justifyContent, className} = this.props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <span className={classNames("d-flex flex-wrap align-items-center mb-1 page-title", justifyContent, className, computedClassNames)}>
        {children}
      </span>
    )
  }
}

export { PageTitle };