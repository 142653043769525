import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import classNames from "classnames";
import { Button as _Button } from "@isf/bootstrap";
import { Message } from "./message";
import { Link } from "react-router-dom";
import appStore, { routerStore } from "@isf/core-app-store";

//TODO: similar function declared in ui-engine/util, move to common (?)

export const templToPath = (template, split = "/") => {
  const p = routerStore.pathname.split("/");
  const [path, query] = template.split("?");
  const [shortPath, hash] = path.split("#");
  const t = shortPath.split(split);

  for (let i = 0; i < p.length && i < t.length; i++) {
    if (t[i] === "{id}") t[i] = p[i];
  }
  // t[0] = p[1];
  let result = t.join(split);
  query && (result += "?" + query);
  hash && (result += "#" + hash);

  return result;
};

@observer
class Button extends Component {

  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    this.executedActionsStore = appStore.getStore('globalExecuteActionsStore','ui');
    if (props.uiStoreType) this.uiStore = new props.uiStoreType(props);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  componentWillUnmount() {
    this.uiStore && this.uiStore.dispose && this.uiStore.dispose();
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  handleOnClick = (e) => {
    let {onClick} = this.props;
    const { /*handler, dummyStore,dataStore,*/ data } = this.props;
    // if(dummyStore && dataStore){
    //   dataStore.push([],dummyStore.stateJS)
    // }
    // console.log(handler)
    if (typeof data === "object") onClick = onClick.bind(this, data, "Button");
    onClick && onClick(e, "Button");
    // handler && handler.act(this.props);
  };

  isDisabled = (disabledOnAction,actionName) => {
    if(disabledOnAction === undefined || disabledOnAction){
      return this.executedActionsStore.get(['executeActions',actionName])
    }
    return false;
  };

  render() {
    const { handler, text, message, url, children, hidden, dummyStore, dataStore, uiStoreType, className, languages,
            localeStore, data, disabledOnAction, actionName, computedClassName, uiBaseStore, ...other } = this.props;
    if (this.uiStore && this.uiStore.hiddenStore.get("isHidden")) return null;
    const disabled = this.isDisabled(disabledOnAction,actionName);
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();
    const Button = () => {
      return(
        <_Button disabled={disabled} {...other}
                 className={classNames("d-inline-flex align-items-center mr-1", className, computedClassNames)}
                 onClick={this.handleOnClick}>
          {children}
          <span><Message text={text} message={message} /></span>
        </_Button>
      )
    };
    if (url && typeof url === "string") {
      return (
        <Link {...other} to={templToPath(url)}>
          <Button/>
        </Link>
      );
    }
    return (
      <Button/>
    );
  }
}

export default Button;