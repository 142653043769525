import React, { Fragment } from "react";
import { observer } from "mobx-react";
import { Nav } from "@isf/bootstrap";
import { MenuToggle } from "./menu-toggle";

const HeaderLeftItems = observer(({ store, children }) => {
  return (
    <Fragment>
      <MenuToggle store={store} />
      { children.length !== 0
        ? <Nav navbar className="align-items-center h-100 ml-4 header-left-items">
            {children}
          </Nav>
        : null
      }
    </Fragment>
  )
});

export { HeaderLeftItems };