import appStore, { Store, storeExists } from "@isf/core-app-store";
import $ from "@isf/core-object-util";
import cacheStore from "@isf/core-app-store/src/cache-store";
import routerStore from "@isf/core-app-store/src/router-store";

// temporary
let __id = 0;
const newPath = () => { __id ++; return __id}; 

const validateStore = (path, type) => {
  const scope = SCOPE_BY_STORE_NAME[type.name];
  if (typeof scope === "string") {

    const pathToStore = [scope, path];
    if (storeExists(pathToStore)) {
      const existStore = appStore.getStore(pathToStore);

      if (!(existStore instanceof type)) {
        throw new Error(`Store with path ${path} and type ${existStore.constructor.name} already exists`);
      }

      return existStore;
    }
  }
};

export const resolveStore = ({id, store, state, type}) => {
  if (store instanceof Store) {
    return store;
  }
  let existStore, resultState;
  if (typeof store === 'string') {
    const cachedState = cacheStore.getCachedState('/'+(routerStore.get('path').join('/')),store,'ui');
    resultState = cachedState || state || {};
    existStore = validateStore(store, type);
    return existStore || new type({path: [store], state: resultState});
  }
  if (typeof id === 'string') {
    const path = id + '.' + type.name;
    existStore = validateStore(path, type);
    const cachedState = cacheStore.getCachedState('/'+(routerStore.get('path').join('/')),path,'ui');
    resultState = cachedState || state || {};
    if(existStore && resultState !== undefined) {
      existStore.setState(resultState);
    }
    return existStore || new type({path: [path], state:resultState});
  }
  const path = type.name + '.' + newPath();
  existStore = validateStore(path, type);
  const cachedState = cacheStore.getCachedState('/'+(routerStore.get('path').join('/')),path,'ui');
  resultState = cachedState || state || {};
  if(existStore && resultState !== undefined) {
    existStore.setState(resultState);
  }
  return existStore || new type({path:[path], state:resultState});
};

const SCOPE_BY_STORE_NAME = {
  "TableViewStore": undefined,
  "NavbarStore": "ui",
  "ToggleStore": "ui",
  "CardStore": "ui",
  "DropdownStore": "ui",
  "DataStore": "data",
  "ImageStore": "data",
  "LocaleSelectStore": "ui",
  "TableStore": "ui",
  "FilterStore": "ui",
  "TreeStore": "ui",
  "ServicesTableStore": "data",
  "ServicesTableRequestStore": "data"
};