import {RestHandler} from "@isf/core-rest";
import {showErrorMessage} from "./file-signature";

class FileRestHandler extends RestHandler {
  handleResponse = ({request, response, api}, context, responseHandler) => {
    const body = this.readBody(response);

    if(responseHandler)
      return responseHandler(context, response, body);

    else{
      return {context, response, body};
    }

  };

  handleRequestError = async (request, e ) => {
    console.error('Request failed with error:', e, ', request', request);
    showErrorMessage("ui.signFile.action.ERR_CONNECTION_REFUSED");
    return {};
  }
}

export {FileRestHandler};