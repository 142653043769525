class HostnameInfo {
  _isLocalhost;
  _isAdminHost;
  _isAdmin;

  constructor() {
    this.isLocalhost = Boolean(
      window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
    );

    this.isAdminHost = Boolean(
      window.location.host.includes("admin")
    );

    this.isAdmin = false;
  }

  get isAdminHost() {
    return this._isAdminHost;
  }

  set isAdminHost(isAdminHost) {
    this._isAdminHost = isAdminHost
  }

  get isAdmin() {
    return this._isAdmin;
  }

  set isAdmin(isAdmin) {
    this._isAdmin = isAdmin
  }


  get isLocalhost() {
    return this._isLocalhost;
  }

  set isLocalhost(isLocalhost) {
    this._isLocalhost = isLocalhost;
  }
}

const hostnameInfo = new HostnameInfo();

export {hostnameInfo};