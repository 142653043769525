import React, {Component} from 'react';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {Col as _Col} from 'reactstrap';

@observer
class Col extends Component {
    constructor(props) {
        super(props);
        if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    }

    componentWillUnmount() {
        this.uiBaseStore && this.uiBaseStore.dispose();
    }

    render() {
        const {children, className, computedClassName, languages, localeStore, uiBaseStore, ...other} = this.props;
        const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
          && this.uiBaseStore.classNameStore.getClassNames();

        return (
            <_Col className={classNames(className, computedClassNames)} {...other}>
                {children}
            </_Col>
        )
    }
}

export {Col};