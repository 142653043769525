
// TODO: switch off for staging and production
// console.log("Environment", process.env);

/**
 * Prints warning in console if environment variable not found. 
 * @param {*} variable 
 * @param {*} varName 
 */
const verifyVariable = (variable, varName) => {
  if (variable === undefined) {
    console.warn(`Environment variable ${varName} not found`);
  }
}

/**
 * Returns environemnt variable. 
 * For example, if .env.* file has value
 *  REACT_APP_API_URL=https:/a-host.by/api,
 * method call getEnvVariable('API_URL') returns https:/a-host.by/api
 * @param {*} varName 
 * @param {*} scope 
 */
export const getEnvVariable = (varName, scope='REACT_APP') => {
  const variable = process.env[`${scope}_${varName}`];
  verifyVariable(variable, varName);
  return variable;  
}