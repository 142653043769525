import appStore,{UIStore} from "@isf/core-app-store";
import {autorun, computed} from "mobx";
import {storeTemplToPath} from "../util";
import {uuidv4} from "@isf/core-system-util";
import $ from "@isf/core-object-util/src/object-util";
import {getListData, getDeclaredString, NOT_HIDDEN, NOT_COMPUTED} from "../expression/utils";


const initialize=(props,id,store)=>{
   store._computedStore = new UITextComputedStore(id,props);
   store._hiddenStore = new UITextHiddenStore(id,props);
};

class UITextStore extends UIStore{

  constructor(props){
    const id = 'uiTextStore.'+uuidv4();
    super({path:id});
    initialize(props,id,this)
  }

  _computedStore;

  _hiddenStore;

  @computed
  get computedStore(){
    return this._computedStore;
  }

  @computed
  get hiddenStore(){
    return this._hiddenStore;
  }

}

class UITextComputedStore extends UIStore{
  constructor(parentId,props){
    const parentArrPath = parentId.split('.');
    parentArrPath.splice(1,0,'computed');
    super({path:parentArrPath});
    this.localeStore = props.localeStore;
    initializeComputedAutorun(props,this)
  }
}

const initializeComputedAutorun =(props,store)=>{
  if(props.computed && !NOT_COMPUTED.includes(props.computed)/*props.computed!=='condition'*/) {
    const {params, expression} = props.computed;
    if (params && params.length !== 0) {
      autorun(() => {
        let declaredString = "";
        if (params.length !== 0) {
          declaredString = getDeclaredString(params, store.localeStore, props.data, {refId:props.refId});
            declaredString !== "let;" && store.set('value', eval(declaredString + expression));
        }
      });
    }
  }
};

class UITextHiddenStore extends UIStore{
  constructor(parentId,props){
    const parentArrPath = parentId.split('.');
    parentArrPath.splice(1,0,'hidden');
    super({path:parentArrPath});
    this.localeStore = props.localeStore;
    initializeHiddenAutorun(props,this)
  }
}


const initializeHiddenAutorun =(props,store)=>{
    if(props.hidden === 'true' || props.hidden === 'false'){
      store.set('isHidden', eval(props.hidden))
    }else if (props.hidden && !NOT_HIDDEN.includes(props.hidden)/*props.hidden.expression*/) {
      const hiddenParams = props.hidden && props.hidden.params || [];
      const hiddenExpression = props.hidden && props.hidden.expression || {};
      autorun(() => {
        let declaredString = "";
        if (hiddenParams.length !== 0) {
          declaredString = getDeclaredString(hiddenParams, store.localeStore, props.data, {refId:props.refId});
          declaredString !== "let;" && store.set('isHidden', eval(declaredString + hiddenExpression))
        }

      })
    }
};
export {UITextStore}