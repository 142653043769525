import React from 'react';
import classNames from 'classnames';
import PopoverContext from './popover-context';

const OverlayTrigger = (props) => {
  const { className, style, children } = props;

  return (
    <PopoverContext.Consumer>
      {context => (
        <div
          id={`Popover${(context && context.uuid) || ''}`}
          tabIndex="0"
          role="button"
          className={classNames(className)}
          style={style}
        >
          {children}
        </div>
      )}
    </PopoverContext.Consumer>
  );
};

export default OverlayTrigger;
