import { action } from "mobx";
import $ from "@isf/core-object-util";
import { Store } from "@isf/core-app-store";

import { getEnvVariable } from "@isf/core-system-util";
import { RestApi } from "@isf/core-rest";
const API_DEFAULT_CONF = {
  "schema": getEnvVariable('API_DEFAULT_SCHEMA'),
  "host": getEnvVariable('API_DEFAULT_HOST'),
  "port": getEnvVariable('API_DEFAULT_PORT'),
  "context": "api"
};
export const confApi = new RestApi({...API_DEFAULT_CONF, ...{context: 'api/conf'}});

class ServicesStore extends Store {

  constructor({path, state}) {
    super({path, scope: 'data', state: state || []});
  }

  onLoad(services=[]) {
    this.setState(services);  
  }

  @action
  async load() {
  const services = await confApi.request({path: 'configuration/v1'});
   this.onLoad(services);
  }

  addServiceConfig(service) {
    const id = $.get(service, "id");
    const isExist = ~this.state.findIndex(item => $.get(item, "id") === id);

    if (!isExist) {
      this.state.push(service);
    }
  }

  @action
  async loadServiceById(id, listeners) {
    await this.loadShortServicesInfoByProp([id]);
    await this.loadServicesPropByArrayOfId([id], "datasourceBindings");
    await this.loadServicesPropByArrayOfId([id], "modelSchema");
    await this.loadServicesPropByArrayOfId([id], "api");
    const service = this.state.find(el => $.get(el, "id") === id);
    if (service) {
      this.addServiceConfig(service);
      Array.isArray(listeners) && listeners.forEach(listener => listener(service));
      return service;
    }
  }

  @action
  async loadServiceByContextPath(contextPath, listeners) {
    const services = await confApi.call({
      path: `configuration/v1/search`,
      options: {
        method: "post",
        body: `{"filter": {"contextPath": [{"value": "${contextPath}", "operation": "equals"}]}}`
      }
    });

    const data = $.get(services, "data");
    if (Array.isArray(data) && data.length === 1) {
      const id = $.get(data[0], "id");
      return await this.loadServiceById(id, listeners);
    }
  }

  @action
  async loadServicesPropByArrayOfId(idArr, propName) {
    const services = await confApi.call({
      path: `${propName}/v1/search`,
      options: {
        method: "post",
        body: `{"filter": {"configurationId": [{"value": ${JSON.stringify(idArr)}, "operation": "in"}]}}`
      }
    });

    const data = $.get(services, "data");

    Array.isArray(data) && data.forEach(serviceInfo => {

      const id = $.get(serviceInfo, "configurationId");
      const index = this.state.findIndex(el => $.get(el, 'id') === id);

      if(~index) {
        this.set([index, propName], serviceInfo)
      }
    });

    return data;
  }

  @action
  async loadShortServicesInfoByProp(propArr, propName = "id") {
    const services = await confApi.call({
      path: `configuration/v1/search`,
      options: {
        method: "post",
        body: `{"filter": {"${propName}": [{"value": ${JSON.stringify(propArr)}, "operation": "in"}]}}`
      }
    });

    const data = $.get(services, "data");
    Array.isArray(data) && data.forEach(shortInfo => {
      this.addServiceConfig(shortInfo);
    });

    return data;
  }
}

export { ServicesStore };