import React, { Component, Fragment } from "react";
import { observer, Provider } from "mobx-react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { injectIntl } from "react-intl";
import { Message } from "../../form/message";
import { resolveStore } from "@isf/react-util";
import DropdownStore from "./dropdown-store";
import { Dropdown as BootstrapDropdown, DropdownMenu, DropdownToggle } from "@isf/bootstrap";
import { DropdownItem } from "./dropdown-item";

const DropdownOption = ({ option }) => (
  <Fragment>
    {option.name}
  </Fragment>
);

@injectIntl
@observer
class Dropdown extends Component {

  constructor(props) {
    super(props);
    //const { value, options } = props;
    this.store = resolveStore({
      ...props,
      type: DropdownStore,
      state: {
        value: ""/*value || options[0].value*/
      }
    });
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(props) {
    props.stopPropagation();
    this.store.toggleOpen();
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const { isOpen } = this.store;
    const { options, direction, right, title, children, message, className, caret = true, uiBaseStore, ...other
      } = this.props;
    const {
      tag: BootstrapDropdown,
      menuTag: DropdownMenu,
      itemTag: DropdownItem,
      toggleTag: DropdownToggle
    } = this.props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();
    return (
      <BootstrapDropdown isOpen={isOpen} toggle={this.handleToggle} direction={direction}
                         className={classNames(className, computedClassNames, {"dropdown-without-caret": !caret})}>
        <DropdownToggle tag="span" caret={caret} className="d-flex align-items-center cursor-pointer" text={title} message={message} options={options}>
          <Message text={title} message={message} />
        </DropdownToggle>
        <Provider store={this.store}>
          <DropdownMenu right={right} className="m-0 py-0">
            <div className="dropdown-menu-content py-2 overflow-y-auto">
              {isOpen ? children || options.map(option =>
                <DropdownItem
                  key={option.value}
                  option={option}
                  {...other}
                  optionTag={this.props.optionTag} />
              )
                : null
              }
            </div>
          </DropdownMenu>
        </Provider>
      </BootstrapDropdown>
    );
  }
}

Dropdown.propTypes = {
  id: PropTypes.string,
  direction: PropTypes.string,
  right: PropTypes.bool,
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string,
      disabled: PropTypes.bool,
      header: PropTypes.string,
      divider: PropTypes.bool
    })
  ),
  value: PropTypes.string,
  tag: PropTypes.elementType,
  toggleTag: PropTypes.elementType,
  menuTag: PropTypes.elementType,
  itemTag: PropTypes.elementType,
  optionTag: PropTypes.elementType,
  store: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
};

Dropdown.defaultProps = {
  title: "title",
  tag: BootstrapDropdown,
  toggleTag: DropdownToggle,
  menuTag: DropdownMenu,
  itemTag: DropdownItem,
  optionTag: DropdownOption
};

export { Dropdown };