import appStore, {routerStore} from "@isf/core-app-store";
import $ from "@isf/core-object-util";

const stringFormat = (str, localeStore, customValue) => {
  if(typeof str !== "string") return str;
  let startIndex = str.indexOf("{"),
    endIndex = str.indexOf("}", startIndex);

  while(~startIndex && ~endIndex && endIndex > startIndex) {
    const expression = str.substring(startIndex + 1, endIndex);
    const value = getValue(expression, localeStore, customValue);
    str = replaceByIndex(str, value, startIndex, endIndex);

    startIndex = str.indexOf("{");
    endIndex = str.indexOf("}", startIndex);
  }

  return str;
};

const replaceByIndex = (str, replacer, startIndex, endIndex) => {
  return str.substring(0, startIndex) + replacer + str.substring(endIndex + 1);
};

const getStoresByName = (name, localeStore) => {
  let store;

  if (name === "pageUrl") {
    store = routerStore;  //TODO: fix if ref component (example api call action store)

  } else if (name === "userStore") {
    store = appStore.getStore("user", "user");

  } else if (name === "localeStore") {
    store = localeStore || appStore.getStore("localization");

  } else {
    store = appStore.getDataStore(name);
  }

  return store;
};

const getValue = (expression, localeStore, customValue) => {
  let [store, path] = expression.split(":");
  const isCustom = customValue && path in customValue[store];
  if(isCustom){
    return customValue[store][path];
  }

  store = getStoresByName(store, localeStore);
  path = path.split(".");

  if(!store || path.length === 0) return "";


  return path[0] === "state" ? store.get(path.slice(1)) : $.get(store, path);
};

export {stringFormat}