import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Container} from '@isf/bootstrap';
import classNames from 'classnames';

@observer
class PageHeader extends Component {
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {children, className} = this.props;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
      <Container fluid className={classNames("px-0 page-header-container", className, computedClassNames)}>
        {children}
      </Container>
    )
  }
}

export { PageHeader };