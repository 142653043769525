import React, { Fragment } from "react";
import { observer } from "mobx-react";
import deepmerge from "deepmerge";

import Field from "./field";

const FormField = observer( props => {
  const {
    id, label, accessor /*depricated*/, hidden, disabled, handler, layout, options, supportText,
    rows, format, placeholder, FieldLabel, FieldInput, className, style, bs, sortOptions, ...other
  } = props;

  // if(id.endsWith("isModelPathUsed")) {
  //   console.log("hidden", hidden())
  // }
  const newHidden = typeof hidden === "function"
    ? hidden()
    : hidden;
  const newDisabled = typeof disabled === "function"
    ? disabled()
    : disabled;
  // const handler = props.handler || accessor;
  const fieldProps = deepmerge(layout, {
    id,
    hidden: newHidden,
    label: {
      text: label
    },
    input: {
      rows,
      format,
      bs,
      props: {
        ...other,
        format,
        disabled: newDisabled,
        onChange: handler.onChange
      },
      supportText,
      sortOptions,
      options,
      placeholder
    },
    FieldLabel,
    FieldInput,
    className,
    style
  });
  fieldProps.input.props.handler = handler;

  return (
    <Fragment>
      <Field {...fieldProps} {...handler}/>
    </Fragment>
  );
});

export default FormField;