const getResolveObject=(element,tag,map)=> {
  switch (tag) {
    case "BPMNDiagram": {
      let children = [];
      let boundsSVG ;
      for(let i=element.children.length-1;i>=0;i--){
          if(element.children[i].props.stencil.id === "SVG"){
            children = addSVGChildren(children,elementConverter(element.children[i],map));
            boundsSVG = element.children[i].props.bounds;
            break;
          }
          children.push( elementConverter(element.children[i], map))
      }
      const {childShapes,bounds,...other} = element.props;
      return {
        bounds:boundsSVG,
        ...other,
        childShapes:children,

      };
    }

    case "Shape": {
      let children = [];
      for(let i=element.children.length-1;i>=0;i--){
        if(element.children[i].props.stencil.id === "SVG"){
          children = addSVGChildren(children,elementConverter(element.children[i],map));
          break;
        }
        children.push( elementConverter(element.children[i], map))
      }
      const {childShapes,message,...other} = element.props;
      return {
        ...other,
        childShapes:children,

      };
    }

    case "SVG": {
      return element.children.map((child) => {
        return elementConverter(child, map)
      });

    }

    case "Link": {
      const children = element.children.map((child) => {
        return elementConverter(child, map)
      });
      const {childShapes,points,connectTo,connected,defaultConditionConf,...other} = element.props;
      return {
        ...other,
        childShapes:children
      };
    }
  }
};


const addSVGChildren=(children,svgChildren)=>{
  const childrenWithoutSVG = children.reverse();
  svgChildren.map((child)=>{
    childrenWithoutSVG.push(child);
  });
  return childrenWithoutSVG;
};

export const schemaConverterToJSON=(schema,map)=>{
  return elementConverter(schema,map); //JSON.stringify(elementConverter(schema,map))
};

const getResolveTag=(stencilId)=>{
  return stencilId!=="SequenceFlow"?stencilId==="BPMNDiagram"?"BPMNDiagram":(stencilId==="SVG"?"SVG":"Shape"):"Link";
};


const elementConverter=(element,map)=>{
  const tag=getResolveTag(element.props.stencil.id);
  return getResolveObject(element,tag,map);
};