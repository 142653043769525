import appStore from "@isf/core-app-store";
import $ from "@isf/core-object-util/src/object-util";
import {createAlertMessage} from "../../alert/alert-message";
import {localeStore} from "@isf/core-localization";

const getAddFileRequestObj = (contextPath, body) => ({
  path: contextPath + "/file",
  options: {
    method: "post",
    body: body
  },
});

const getRemoveFileRequestObj = (contextPath, id) => ({
  path: contextPath + "/file/" + id,
  options: {
    method: "delete"
  },
});

const getFileAsBase64EncodedRequestObj = (contextPath, id) => ({
  path: contextPath + "/file-base64/" + id,
  options: {
    method: "get",
    headers: {'Accept': 'text/plain', /*'Content-Type': 'application/json'*/}
  },
});

const getSignRequestObj = (body, port, isDetached = false, type = "FLASH_DRIVE") => ({
  path: `http://localhost:${port}/` + (type === "FLASH_DRIVE" ? "sign" : "sign_kta"),
  isApiUrl: false,
  options: {
    method: "post",
    credentials: "omit",
    mode: "cors",
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      "data": body,
      "isDetached": isDetached
    })
  },
});

const getMinifySignRequestObj = (body, type = "FLASH_DRIVE") => ({
  path: "/crypto/" + (type === "FLASH_DRIVE" ? "minifypkcs7" : "sigkta2pkcs7"),
  options: {
    method: "post",
    body: body
  }
});

const getSignerRequestObj = (body) => ({
  path: "/crypto/signerpkcs7",
  options: {
    method: "post",
    body: body
  }
});

const getVerifyCmsRequestObject = (body, port)=>({
  path: `http://localhost:8084/verify_cms`,
  isApiUrl: false,
  options: {
    method: "post",
    credentials: "omit",
    mode: "cors",
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  },
})


const getRequestBody = (files) => {
  const formData = new FormData();
  formData.append("file", files[0]);
  return formData;
};

const fileObj = (file, fileInfo, size, signature) => {
  if (!fileInfo || !fileInfo.id) return;

  return {
    // id: fileInfo.id,
    ...fileInfo,
    name: file["name"],
    type: file["type"],
    // size: size,
    signature: signature
  }
};

function readFile(file, onLoad, setLoading, onError) {
  if (!(file instanceof File) || file.type !== "application/json") {
    setLoading();
    console.error("Invalid type file (not json):", file.type);
    return;
  }
  const reader = new FileReader();
  reader.readAsText(file);

  reader.onload = function () {
    onLoad(reader.result);
  };

  reader.onerror = function() {
    const typeError = "readFile",
      messageError = "Read file error";

    onError && onError(typeError, reader.error, messageError);
    setLoading();
  }
}

async function readOpenApiFile(file, setLoading, formatMessage) {
  if (!(file instanceof File) || file.type !== 'application/json') {
    setLoading();
    return {errors: [{message: formatMessage({id: 'ui.OpenApi.file.type.invalid'})}]};
  }

  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = async () => {
      const DynamicValidation  = await import('../../swagger/src/swagger-parser');
      const response = DynamicValidation && DynamicValidation.apiValidation
        && await DynamicValidation.apiValidation(JSON.parse(reader.result), formatMessage);
      if (response && !response.isValid) {
        // onLoad(null);
        setLoading();
        if (response.errors) return resolve({errors: response.errors});
      } else if (response && response.isValid) resolve({isValid: true, result: reader.result});
    };

    reader.onerror = () => {
      setLoading();
      reject({errors: [{message: formatMessage({id: 'ui.OpenApi.file.read.error'})}]});
    }

    reader.readAsText(file);
  })
}

// TODO: move rest handler to one file
const getErrorMessage = (error, localeStore, status) => {
  const userMessage = status === 403 ? error : $.get(error, 'userMessage');
  if(userMessage){
    const currentLocale = localeStore.locale;
    const message = $.get(userMessage, 'message');
    if(message){
      const value = $.get(message,currentLocale);
      if(value){
        return value;
      }
    }

  }
  return $.get(error, 'message')
};


async function restHandler(context, responseInfo, response) {
  const body = await response;

  if (responseInfo.ok || (context.validateAction && context.validateAction(responseInfo))) {
    if (context.listener) {
      return await context.listener(context, responseInfo, body);
    }
  } else {
    const status = responseInfo.status;
    const errorGlobalStore = appStore.getStore('errorGlobalStore', 'ui');
    if (status === 500) {
      const errorList = $.isArray(body)
        ? body
        : [body];
      errorList.forEach(error => {
        errorGlobalStore.push('errors', {...error, status: status})
      });
    } else {
      if ($.isArray(body)) {
        body.forEach(error => {
          const path = error.path;
          const store = context.handler && context.handler.store;
          if (store && store.addToErrorState) {
            store.addToErrorState(path, error)
          }
        });
      } else {
        const message = status !== 422
          ? getErrorMessage(body, localeStore, status)
          : localeStore.localeMessages['ui.alerts.422'];
        createAlertMessage(message, 'error');
      }
    }
  }
}

const removeListener = (context, responseInfo, body) => {
  return validateRemoveResponse(responseInfo);
};

const validateRemoveResponse = (responseInfo) => {
  if ([200, 404].includes(responseInfo.status)) {
    return true;
  }
};

export {
  readFile, readOpenApiFile, getRequestBody, getAddFileRequestObj,
  getRemoveFileRequestObj, fileObj, restHandler,
  removeListener, validateRemoveResponse,

  getFileAsBase64EncodedRequestObj,
  getSignRequestObj,
  getSignerRequestObj,
  getVerifyCmsRequestObject,
  getMinifySignRequestObj,
};
