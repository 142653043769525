import React, {Component, Fragment} from "react";
import $ from "@isf/core-object-util";
import {observer} from "mobx-react";
import {ICON_FILE_DOWNLOAD, ICON_CHECK} from "@isf/common-resources";
import {default as downloadjs} from "downloadjs"
import {
  getFileAsBase64Encoded,
  verifyCms,
} from "../form/file-input/file-signature";
import appStore from "@isf/core-app-store";
import {resolveStore} from "@isf/react-util";
import {ToggleStore} from "../toggle";
import {LoadSpinner} from "../load-spinner/load-spinner-view";
import {ModalBody, ModalCancelButton, ModalContainer, ModalFooter, ModalHeader} from "../modal";
import {injectIntl} from "react-intl";
import {SignVerifyStore} from "./sign-verify-store";

function downloadFile(it) {
  const sign = $.get(it, "sign");
  if (sign) {
    downloadjs(sign, `sign${new Date().toLocaleDateString()}.p7s`);
  }
}

function openSignModal() {
  const toggleStore = appStore.getStore("globalSignVerify.ToggleStore", "ui");
  toggleStore.set("active", true);
}

async function checkSignature(it, props) {
  const sign = $.get(it, "sign");
    const verifyStore = appStore.getStore("signVerifyStore", "ui");
    if (sign) {
        verifyStore.set("loading", true);
        const {accessor, handler, contextPath} = props
        const store = $.get(handler, "store");
        const config = {props: {accessor, store, contextPath}};
        const fileAsBase64 = await getFileAsBase64Encoded(config);
        const body = {
            data: fileAsBase64,
            cms: sign
        }
        const response = await verifyCms(config, body);
        verifyStore.setDefaultState();
        if (response && response.verify === 'OK') {
            openSignModal();
        }
    }
}

const SignatureComponent = observer(({sign, intl, props, store}) => {
    return (
        <div className={"col-12 mb-3 text-center"}>
                <table className="table">
                    <tr>
                        <td scope="row" className="text-center align-middle"><span>{$.get(sign, "signer")}</span></td>
                      <td scope="row" className="text-center align-middle">
                        <button className="btn"
                                onClick={() => downloadFile(sign)}
                                title={intl.formatMessage(
                                    {id: "ui.field.file.downloadFile"})}>
                          <img className='icon-md cursor-pointer'
                               src={ICON_FILE_DOWNLOAD}
                               alt={intl.formatMessage(
                                   {id: "ui.field.file.downloadFile"})}
                               title={intl.formatMessage(
                                   {id: "ui.field.file.downloadFile"})}
                          /></button>
                      </td>
                        <td scope="row" className="text-center align-middle">
                          <button className="btn btn-sm btn-light btn-outline-secondary"
                                  onClick={() => checkSignature(sign, props)}
                                  title={intl.formatMessage(
                                      {id: "ui.field.file.validate"})}>
                            {intl.formatMessage({id: "ui.field.file.validate"})}
                          </button>
                        </td>
                    </tr>
                </table>
          <Loader intl={intl} loading={store.get("loading")}/>
        </div>
    )
});

const Signature = observer(({store, intl}) => {
    const signature = store.get(["signature"]);
    const accessor = store.get(["accessor"]);
    const handler = store.get(["handler"]);
    const contextPath = store.get(["contextPath"]);
    const verifyStore = appStore.getStore("signVerifyStore", "ui");

    if (!$.isArray(signature)) {
    return null
  }

  return (
      <div className={"row mb-3 mt-2 pb-2 border-bottom overflow-y-auto signature-wrapper"}>
          {
              signature.map((it, index) => <>
                  <SignatureComponent intl={intl} sign={it} key={index} props={{accessor, handler, contextPath}} store={verifyStore}/>
              </>)
          }
      </div>
  )
});


@injectIntl
@observer
class SignVerifyView extends Component {
    constructor(props) {
        super(props);
        this.dataStore = new SignVerifyStore({
            path: 'signVerifyStore'
        });
        this.dataStore.setDefaultState();
        this.props = props;
        this.toggleStore = resolveStore({id: 'globalSignVerify', type: ToggleStore});
    }

  openToggle = () => {
    return true;
  };

  closeModal = () => {
    const {dataStore, toggleStore} = this;
    dataStore.state.loading=false;
    toggleStore && toggleStore.set && toggleStore.set('active', false);
  };

  toggle = (props) => {
    const buttonType = props['data-button-type'];
    if (buttonType === 'cancel' || buttonType === undefined /*&& dataStore.get("cancelButton")*/) {
      this.closeModal();
    }
  };


  render() {
    const {intl} = this.props;
    return (
        <Fragment>
          <ModalContainer
              size={'sm'}
              toggleStore={this.toggleStore}
              toggle={this.toggle}
              openToggle={this.openToggle()}
          >
            <ModalHeader>
              {intl.formatMessage({id: "ui.sign.verify.result"})}
            </ModalHeader>
            <ModalBody>
              <span>{intl.formatMessage({id: "ui.sign.verify.success"})}</span>
            </ModalBody>
            <Footer intl={intl} />
          </ModalContainer>
        </Fragment>
    )
  }
}

const Footer = observer(({intl}) => {
  return (
      <ModalFooter>
        <ModalCancelButton children={intl.formatMessage({id: "ui.sign.verify.modal.close"})} size={"sm"}/>
      </ModalFooter>
  )
});

const Loader = observer(({intl, loading}) => {
    return loading
        ? <div style={{top: 0, left: 0}} className={"w-100 h-100 position-absolute d-flex flex-column justify-content-end"}>
            <LoadSpinner isLoading={loading} />
        </div>
        : null;
});


export {Signature, SignatureComponent, SignVerifyView};
