import React from "react";
import {Input} from "reactstrap";
import {inject, observer} from "mobx-react";
import classNames from "classnames";
import {injectIntl} from "@isf/localization";

const handleOnInputDataChange = (handler, onChange, data, e) => {
  const newRowsPerPage = parseInt(e.target.value);
  if(handler) {
    handler.set('body.paging.limit', newRowsPerPage);
    handler.set('body.paging.page', 1);
  }
  if (onChange)
    if(typeof data === 'object'){
      onChange = onChange.bind(this, data, "PaginationSize");
    }
  onChange && onChange(e, "PaginationSize");
};

@injectIntl
@observer
class PaginationSize extends React.Component{
  constructor(props) {
    super(props);
    if (props.uiBaseStore) this.uiBaseStore = new props.uiBaseStore(props);
  }

  componentWillUnmount() {
    this.uiBaseStore && this.uiBaseStore.dispose();
  }

  render() {
    const {intl, rowsPerPageOptions, handler, onChange, size, data, className} = this.props;
    const dividedRowsPerPageOptions = Array.isArray(rowsPerPageOptions)? rowsPerPageOptions : rowsPerPageOptions?rowsPerPageOptions.split(','):[5, 10, 15, 20];
    const rowsPerPage = (handler && handler.get('body.paging.limit')) || 10;
    const computedClassNames = this.uiBaseStore && this.uiBaseStore.classNameStore
      && this.uiBaseStore.classNameStore.getClassNames();

    return (
        <Input bsSize={size}
               className={classNames("d-inline-block w-fit-content mb-2 cursor-pointer input-pagination-size align-middle", className, computedClassNames)}
               id="rowsPerPageOptions"
               type="select"
               onChange={(e) => handleOnInputDataChange(handler, onChange, data, e)}
               value={rowsPerPage}>
          {
            dividedRowsPerPageOptions.map((option, index) =>
                <option key={index} value={option}>
                  {`${option} ${intl.formatMessage({id: "ui.paginationSize"})}`}
                </option>
            )
          }
        </Input>
    );
  }
}

PaginationSize.propTypes = {};

PaginationSize.defaultProps = {
  rowsPerPageOptions: [5, 10, 15, 20]
};


export {PaginationSize};
